import moment from 'moment';
import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CheckboxProps } from 'semantic-ui-react';
import { PersonModel } from '../../models';
import { RadioButton } from 'Components/shared/RadioButton';
import { CustomDateTimeComponent } from './CalendarComponents';
import type { CalendarEventTimeProps, DatePick } from './types.d';
import { BodyCopy } from 'Components/shared/BodyCopy';
import { Styled } from './index.styles';
import forwardIcon from '../../assets/images/forward.svg';

export const CalendarEventTime = ({
  checkAvailability,
  removeAllPersonsEvents,
  datePick,
  setDatePick,
  showTimePicker,
  setShowTimePicker,
  selectedParticipants,
  setSelectedParticipants,
  selectedParticipantsIds,
  setSelectedParticipantsIds,
  ui,
  testId: parentTestId
}: CalendarEventTimeProps) => {
  const handleEndTimeDate = (type: 'endDate' | 'endTime') => async (date: Date) => {
    const newDatePick = { ...datePick, [type]: moment(date) };
    setDatePick(newDatePick);
    removeAllPersonsEvents();
    const personsWithAvailability = await checkAvailability(
      selectedParticipants as PersonModel[],
      newDatePick
    );

    setSelectedParticipants(personsWithAvailability)
    setSelectedParticipantsIds(selectedParticipantsIds as string[])
  };

  const handleStartDatePick = async (date: Date) => {
    let newDatePick;
    const selectedDate = moment(date);
    const diff = ui.dateDiff(selectedDate, datePick.endDate);
    if (diff > 0) {
      newDatePick = {
        ...datePick,
        startDate: moment(date),
        endDate: moment(date).add(15, 'minute'),
      };

      setDatePick(newDatePick)
    } else {
      newDatePick = { ...datePick, startDate: moment(date) };
      setDatePick(newDatePick)
    }
    removeAllPersonsEvents();
    const personsWithAvailability = await checkAvailability(
      selectedParticipants as PersonModel[],
      newDatePick
    );
    setSelectedParticipants(personsWithAvailability)
    setSelectedParticipantsIds(selectedParticipantsIds as string[])
  };

  const handleStartTimePick = async (date: Date) => {
    let newDatePick;
    const selectedDate = moment(date);
    const diff = ui.dateDiff(selectedDate, datePick.endTime);
    if (diff > 0) {
      newDatePick = {
        ...datePick,
        startTime: moment(date),
        endTime: moment(date).add(15, 'minute'),
      };
      setDatePick(newDatePick)
    } else {
      newDatePick = { ...datePick, startTime: moment(date) };
      setDatePick(newDatePick)
    }
    removeAllPersonsEvents();
    const personsWithAvailability = await checkAvailability(
      selectedParticipants as PersonModel[],
      newDatePick
    );

    setSelectedParticipants(personsWithAvailability)
    setSelectedParticipantsIds(selectedParticipantsIds as string[])
  };

  const handleAllDay = async (e, data: CheckboxProps) => {
    const { startDate, endDate } = datePick;
    if (data.checked) {
      setShowTimePicker(false);
      const startOf = startDate.startOf('day');
      const diff = ui.dateDiff(startDate, endDate);
      let newDatePick: DatePick;
      if (diff > 0) {
        const endOf = startDate.endOf('day');
        newDatePick = {
          startDate: startOf,
          endDate: endOf,
          startTime: startOf,
          endTime: endOf,
        };
      } else {
        const endOf = endDate.endOf('day');
        newDatePick = {
          startDate: startOf,
          endDate: endOf,
          startTime: startOf,
          endTime: endOf,
        };
      }
      const personsWithAvailability = await checkAvailability(
        selectedParticipants as PersonModel[],
        newDatePick,
        true
      );

      setSelectedParticipants(personsWithAvailability);
      setDatePick({
        ...datePick,
        startDate: newDatePick.startDate,
        endDate: newDatePick.endDate,
      })
    } else {
      if (!showTimePicker) {
        setShowTimePicker(true);
        const personsWithAvailability = await checkAvailability(
          selectedParticipants,
          datePick
        );
        setSelectedParticipants(personsWithAvailability);

      }
    }
  };

  const today = useMemo(() => moment(), []);

  return (
    <>
      <div className="event-input">
        <Styled.CalendarEventTimeLabel variant={'h6'}>TIME</Styled.CalendarEventTimeLabel>

        <div className="flex-row flex-align-items-center">
          <div className="time-section">
            <DatePicker
              selected={datePick.startDate.toDate()}
              onChange={handleStartDatePick}
              id="startDate"
              minDate={moment().toDate()}
              wrapperClassName="flex-row"
              dateFormat="MMMM dd"
              customInput={<CustomDateTimeComponent />}
            />
            {showTimePicker && (
              <DatePicker
                selected={datePick.startTime.toDate()}
                onChange={handleStartTimePick}
                showTimeSelect
                minTime={
                  datePick.startDate.format('YYYY:MM:DD').toString() ===
                    today.format('YYYY:MM:DD').toString() &&
                    today.hours() > 6
                    ? today.toDate()
                    : today.clone().startOf('day').toDate()
                }
                maxTime={datePick.startDate.clone().endOf('day').toDate()}
                showTimeSelectOnly
                timeIntervals={15}
                wrapperClassName="time-component flex-row"
                id="startTime"
                timeCaption="Time"
                dateFormat="hh:mm aa"
                customInput={<CustomDateTimeComponent isTime />}
              />
            )}
          </div>
          <img className="forward" src={forwardIcon} />
          <div className="time-section">
            <DatePicker
              minDate={today.toDate()}
              selected={datePick.endDate.toDate()}
              onChange={handleEndTimeDate('endDate')}
              wrapperClassName="flex-row"
              id="endDate"
              dateFormat="MMMM dd"
              customInput={<CustomDateTimeComponent />}
            />
            {showTimePicker && (
              <DatePicker
                selected={datePick.endTime.toDate()}
                onChange={handleEndTimeDate('endTime')}
                showTimeSelect
                minTime={
                  datePick.startDate.format('YYYY:MM:DD').toString() ===
                    today.format('YYYY:MM:DD').toString() &&
                    today.hours() > 6
                    ? datePick.startTime.clone().add(15, 'minute').toDate()
                    : datePick.startTime.clone().startOf('day').toDate()
                }
                maxTime={datePick.startDate.clone().endOf('day').toDate()}
                showTimeSelectOnly
                wrapperClassName="time-component flex-row"
                id="endTime"
                className="time"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="hh:mm aa"
                customInput={<CustomDateTimeComponent isTime />}
              />
            )}
          </div>
        </div>
      </div >
      <div className="all-day flex-row">
        <BodyCopy variant='paragraph'>All day</BodyCopy>
        <RadioButton
          toggle
          onChange={handleAllDay}
          checked={!showTimePicker}
          testid={`${parentTestId}-calendarEventTime-radioButtonAllDay`}
        />
      </div>
    </>
  );
}
