import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { STORE_PERSON, STORE_ROUTER, STORE_UI } from 'Constants/stores';
import AudioVideoSettings from './AudioVideoSettings';
import type { SettingsPanelProps } from './interfaces';
import NotificationsSettings from './NotificationsSettings';
import ProfileSettings from './ProfileSettings';
import SocialAccounts from './SocialAccounts';
import GeneralSettings from './GeneralSettings';
import { ContentArea } from '../../shared/ContentArea';
import { SETTINGS_SUBPATHS } from '../../../constants/routing';

const SettingsPanel: React.FC<SettingsPanelProps> = ({ ui, person }) => (
  <ContentArea>
    <Routes>
      {/** path = /settings/* */}
      <Route path="profile" element={<ProfileSettings />} />
      <Route path="general" element={<GeneralSettings />} />
      {person.personAvaliableFeatures.socialAccounts && (
        <Route path="social-accounts" element={<SocialAccounts />} />
      )}
      <Route path="notifications" element={<NotificationsSettings />} />
      <Route path="audio-video" element={<AudioVideoSettings />} />
      <Route
        path="phone"
        element={<Navigate to={SETTINGS_SUBPATHS.PROFILE} />}
      />
      <Route
        path="voicemails"
        element={<Navigate to={SETTINGS_SUBPATHS.PROFILE} />}
      />
      <Route
        path="*"
        element={<Navigate to={SETTINGS_SUBPATHS.PROFILE} replace />}
      />
    </Routes>
  </ContentArea>
);

export default inject(
  STORE_PERSON,
  STORE_ROUTER,
  STORE_UI
)(observer(SettingsPanel));
