import ChannelHeaderObserver from 'Components/ChannelHeader';
import ContactHeaderObserver from 'Components/ContactHeader';
import ContextActionsObserver from 'Components/ContextActions';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PUSHER,
  STORE_ROUTER,
  STORE_UI,
} from 'Constants/stores';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Header, Loader, Message, Popup } from 'semantic-ui-react';
import { ContextHeaderProps, HeaderRenderProps } from './interfaces';

import { UserAvatar } from 'Components/shared/UserAvatar';
import { resolveConversationPath } from 'Utils/routeNav';
import withRouter from '../../hocs/WithRouter';
import { ChatLayoutHeaderRenderer } from '../../components/ChatLayoutHeader/ChatLayoutHeaderRenderer';
import { isFlagInPermissions } from '../../utils/featureFlags';
import { IS_V2_ENABLED } from '../../constants/featureFlags';

class HeaderRenderer extends React.Component<HeaderRenderProps> {
  render() {
    const { contact, conversation, participant, person, conversationId, ui } =
      this.props;
    const convPbo = conversation.selectConversationById(conversationId);
    if (convPbo === null || convPbo === undefined) {
      return <Loader indeterminate active />;
    }
    return convPbo.case({
      pending: () => <Loader indeterminate active />,
      rejected: (reason) => (
        <Message
          visible
          error
          content={
            'Error loading Conversation :' + reason.response.data.message
          }
        />
      ),
      fulfilled: (resp) => {
        const convo = resp.data;
        if (convo && convo.grouping === 'OneOnOne') {
          const otherParticipantsPbo =
            participant.participantByConvMap.get(conversationId);
          if (otherParticipantsPbo !== undefined) {
            return otherParticipantsPbo.case({
              pending: () => <Loader indeterminate active />,
              rejected: (reason) => (
                <Message
                  visible
                  error
                  content={
                    'Error loading other participants :' +
                    reason.response.data.message
                  }
                />
              ),
              fulfilled: (opResp) => {
                const otherParticipant = opResp.data.results.find(
                  (rs) => rs.personId !== person.loggedInPersonId
                );
                const mySelf = opResp.data.results.find(
                  (rs) => rs.personId === person.loggedInPersonId
                );
                if (otherParticipant !== undefined) {
                  const otherPersonId = otherParticipant.personId;
                  if (
                    otherPersonId !== undefined &&
                    otherPersonId !== null &&
                    otherPersonId > 0
                  ) {
                    const otherPerson = person.selectPersonById(otherPersonId);
                    if (otherPerson !== undefined && otherPerson !== null) {
                      return person.selectPersonById(otherPersonId).case({
                        pending: () => (
                          <Loader indeterminate active size="tiny" />
                        ),
                        rejected: (reason) => (
                          <Message
                            visible
                            error
                            content={`${
                              otherPerson.state === 'rejected'
                                ? 'User No Longer Exists'
                                : reason.response.data.message
                            }`}
                          />
                        ),
                        fulfilled: (personResp) => {
                          conversation.setOwnerOfTheChat(
                            personResp.data?.DisplayName
                          );
                          return (
                            <Header
                              as="h2"
                              className="context-header-display valign-middle"
                            >
                              <Header.Content>
                                <UserAvatar
                                  hideInitials
                                  conversationId={convo.id}
                                  name={personResp.data.DisplayName}
                                  selectUnreadCounts={
                                    ui.selectConversationUnreadCounts
                                  }
                                  presence={ui.selectPersonPresenceStatus(
                                    personResp.data.id
                                  )}
                                />
                                {personResp.data.DisplayName}
                                <Popup
                                  inverted
                                  trigger={
                                    <span className="message-status">
                                      {ui.selectPersonMessageStatus(
                                        personResp.data.id
                                      )?.title || ''}
                                    </span>
                                  }
                                  content={
                                    ui.selectPersonMessageStatus(
                                      personResp.data.id
                                    )?.message || ''
                                  }
                                  position={'top right'}
                                />
                              </Header.Content>
                            </Header>
                          );
                        },
                      });
                    } else {
                      return <Loader indeterminate active size="tiny" />;
                    }
                  } else if (!isEmpty(otherParticipant.phone)) {
                    const phoneNumNoPlus = otherParticipant.phone.startsWith(
                      '+'
                    )
                      ? otherParticipant.phone.substring(1)
                      : otherParticipant.phone;
                    const extrContact = person.getExtrContactByPhoneNumber(
                      otherParticipant.phone
                    );
                    contact.loadContactByPhoneNumberIfMissing(phoneNumNoPlus);
                    if (contact.contactsByPhone.has(phoneNumNoPlus)) {
                      const ctPbo = contact.contactsByPhone.get(phoneNumNoPlus);
                      return ctPbo.case({
                        pending: () => (
                          <Loader indeterminate active size="tiny" />
                        ),
                        rejected: (reason) => (
                          <Message
                            visible
                            error
                            content={
                              'Error loading Contact: ' +
                              reason.response.data.message
                            }
                          />
                        ),
                        fulfilled: (ctModel) => (
                          <ContactHeaderObserver
                            ui={ui}
                            contact={ctModel.data}
                            extrContact={extrContact}
                            otherPtc={otherParticipant.phone}
                          />
                        ),
                      });
                    } else {
                      const extrContact = person.getExtrContactByPhoneNumber(
                        otherParticipant.phone
                      );

                      return (
                        <Header
                          as="h2"
                          className="context-header-display valign-middle"
                        >
                          <Header.Content>
                            {extrContact?.DisplayName() ||
                              otherParticipant.phone}
                          </Header.Content>
                        </Header>
                      );
                    }
                  } else {
                    const errMsg =
                      'HeaderRenderObserver: personId and phone were both missing';
                    return (
                      <Message visible error content={'Error: ' + errMsg} />
                    );
                  }
                } else if (mySelf !== undefined) {
                  return person.selectPersonById(mySelf.personId).case({
                    pending: () => <Loader indeterminate active size="tiny" />,
                    rejected: () => (
                      <Message
                        visible
                        error
                        content={`Error Loading YourSelf:`}
                      />
                    ),
                    fulfilled: (personResp) => (
                      <Header
                        as="h2"
                        className="context-header-display valign-middle"
                      >
                        <Header.Content>
                          {personResp.data.DisplayName}
                        </Header.Content>
                      </Header>
                    ),
                  });
                } else {
                  return (
                    <Message
                      visible
                      error
                      content={'Error: Unable to find other Participant'}
                    />
                  );
                }
              },
            });
          } else {
            return (
              <Message
                visible
                error
                content={'Error loading other Participant'}
              />
            );
          }
        } else if (
          convo &&
          (convo.grouping === 'Channel' || convo.grouping === 'Group')
        ) {
          return (
            <ChannelHeaderObserver
              conversation={convo}
              person={person}
              addProperName={conversation.addProperName}
            />
          );
        }
      },
    });
  }
}
const HeaderRenderObserver = observer(HeaderRenderer);

export class ContextHeader extends React.Component<
  ContextHeaderProps,
  { isV2Enabled: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { isV2Enabled: false };
  }

  toggleEmojiPicker = () => {
    const { ui } = this.props;
    ui.setEmojiPickerState({ open: false, editing: false });
  };
  componentWillMount() {
    const {
      conversation,
      participant,
      params: { conversationId },
    } = this.props;
    conversation.loadConversationByIdIfMissingGet(conversationId);
    participant.loadConversationParticipantsIfMissing(conversationId);
  }

  async componentDidMount() {
    const { person } = this.props;
    const isV2Enabled = await isFlagInPermissions(person.auth0, IS_V2_ENABLED);
    this.setState({ isV2Enabled });
  }

  componentDidUpdate(prevProps: ContextHeaderProps) {
    const {
      conversation,
      participant,
      params: { conversationId },
    } = this.props;
    const prevConvId = prevProps.params.conversationId;
    if (conversationId !== prevConvId) {
      conversation.loadConversationByIdIfMissingGet(prevConvId);
      participant.loadConversationParticipantsIfMissing(prevConvId);
    }
  }

  resolveConversationLinkPath = (path: string) => {
    return resolveConversationPath(this.props.location.pathname, path);
  };
  updateRoutes = (path) => {
    const { navigate, location } = this.props;
    const newPath = resolveConversationPath(location.pathname, path);
    navigate(newPath);
  };
  render() {
    const { isV2Enabled } = this.state;
    const {
      contact,
      conversation,
      participant,
      person,
      phoneCall,
      pusher,
      ui,
      params: { conversationId },
    } = this.props;

    return (
      <div
        id="context-header"
        className="flex-row flex-zero"
        onClick={this.toggleEmojiPicker}
      >
        <div data-private className="flex-grow-shrink" id="header-render-wrap">
          {!isV2Enabled ? (
            <HeaderRenderObserver
              contact={contact}
              ui={ui}
              conversation={conversation}
              person={person}
              participant={participant}
              conversationId={conversationId}
            />
          ) : (
            <ChatLayoutHeaderRenderer
              contact={contact}
              ui={ui}
              conversation={conversation}
              person={person}
              participant={participant}
              conversationId={conversationId}
            />
          )}
        </div>
        <div className="flex-shrink" id="context-actions-wrap">
          <ContextActionsObserver
            conversation={conversation}
            conversationId={conversationId}
            loggedInAccountId={person.loggedInAccountId}
            selectConversationParticipants={
              participant.selectParticipantsByConversationId
            }
            selectOtherConversationParticipants={
              participant.selectOtherParticipants
            }
            getPerson={person.selectPersonById}
            setShowPersonDetails={person.setShowPersonDetails}
            removeChannelMembers={participant.removeParticipantsDelete}
            resolveConversationLinkPath={this.resolveConversationLinkPath}
            updateRoute={this.updateRoutes}
            phoneCall={phoneCall}
            loggedinPerson={person.loggedInPersonId}
            isOnline={pusher.isOnline}
            uiStore={ui}
            getExtrContactByPhoneNumber={person.getExtrContactByPhoneNumber}
            loggedInPersonVideoFeature={person.personAvaliableFeatures.video}
          />
        </div>
      </div>
    );
  }
}

export default inject(
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_ROUTER,
  STORE_PHONE_CALL,
  STORE_UI,
  STORE_PUSHER
)(withRouter(observer(ContextHeader)));
