import InfiniteScroll from 'react-infinite-scroll-component';
import { List } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledList = styled(List)`
  margin-bottom: 0.25rem;
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 190px);
  overflow-x: hidden;
  padding-top: 5px;

  &::-webkit-scrollbar {
    width: 5px;
    padding: 0px;
  }
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  && {
    padding: 0;
  }
`;

export const Styled = {
  List: StyledList,
  InfiniteScroll: StyledInfiniteScroll,
};
