import * as React from 'react';
import type { ModalClearStatusButtonProps } from './interfaces';
import { Styled } from './ModalClearStatusButton.styles';

export const ModalClearStatusButton: React.FC<ModalClearStatusButtonProps> = ({
  onClick,
  content,
  testid,
}) => (
  <Styled.ClearStatusButton
    {...{ onClick }}
    data-testid={`${testid}-buttonClearStatus`}
  >
    {content}
  </Styled.ClearStatusButton>
);
