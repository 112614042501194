import styled, { css } from 'styled-components';
import { Button as SemanticUIButton } from 'semantic-ui-react';
import type { ButtonProps } from './types';

const BUTTON_VARIANTS = {
  primary: css`
    background: ${({ theme }) => theme.colors.primary} !important;
    &:hover {
      background: #14819c !important;
      border: 1px solid #14819c !important;
    }
  `,
  secondary: css`
    color: ${({ theme }) => theme.colors.primary} !important;
    background: ${({ theme }) => theme.colors.base} !important;
    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.secondaryHover} !important;
    }
  `,
};

const SemanticButton = styled(SemanticUIButton)<{
  $variant: ButtonProps['variant'];
  $active: boolean;
}>`
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-width: 260px;

  font-weight: 600 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  text-transform: uppercase;
  color: white !important;

  img {
    margin: -6px 5px 0 0;
  }

  ${({ $variant }) => $variant && BUTTON_VARIANTS[$variant]}
  ${({ $active }) =>
    $active &&
    css`
      background: #14819c !important;
      border: 1px solid #14819c !important;
      cursor: default;
    `}
`;

export const Styled = {
  SemanticButton,
};
