import * as React from 'react';
import { selectProperVideoTooltipMessageForOneOnOnes } from 'Utils/videoConferenceUtils';

import { IconButton } from '../shared/IconButton';
import type { StartVideoCallButtonProps } from './StartVideoCallButton.types';

export const StartVideoCallButton: React.FC<StartVideoCallButtonProps> = ({
  activeConference,
  darkDisabledVidIcon,
  isUserInActiveVideoConference,
  loggedInPersonVideoFeature,
  placeVideoCallConference,
  popupPosition,
  size,
  testid,
}) => (
  <IconButton
    onClick={placeVideoCallConference}
    content={selectProperVideoTooltipMessageForOneOnOnes(
      !!activeConference,
      null
    )}
    disabled={
      darkDisabledVidIcon ||
      isUserInActiveVideoConference ||
      !loggedInPersonVideoFeature.enabled
    }
    icon="video"
    testid={`${testid}-buttonStartVideoCall`}
    {...{ popupPosition, size }}
  />
);
