import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { DialPadProps } from './interfaces';

export class DialPad extends React.Component<DialPadProps, {}> {
  constructor(props) {
    super(props);
  }
  numberPressed = (e) => {
    this.props.numberClick(e.target.value);
    if (this.props.inputRef) {
      this.props.handleCursorPos(
        this.props.inputRef.current.selectionStart + 1
      );
    }
  };

  render() {
    return (
      <Grid.Column className="dialpad-component">
        <Button.Group attached="bottom" className="borderBottom evenHeight">
          <Button
            className="rightBorder evenFlex dialpad-button"
            id="keypad-1"
            value="1"
            onClick={this.numberPressed}
          >
            <Button.Content>1</Button.Content>
            <Button.Content style={{ height: '0.55rem' }}></Button.Content>
          </Button>
          <Button
            className="rightBorder evenFlex dialpad-button"
            id="keypad-2"
            value="2"
            onClick={this.numberPressed}
          >
            <Button.Content>2</Button.Content>
            <Button.Content>ABC</Button.Content>
          </Button>
          <Button
            className="evenFlex dialpad-button"
            id="keypad-3"
            value="3"
            onClick={this.numberPressed}
          >
            <Button.Content>3</Button.Content>
            <Button.Content>DEF</Button.Content>
          </Button>
        </Button.Group>
        <Button.Group attached="bottom" className="borderBottom evenHeight">
          <Button
            className="rightBorder evenFlex dialpad-button"
            id="keypad-4"
            value="4"
            onClick={this.numberPressed}
          >
            <Button.Content>4</Button.Content>
            <Button.Content>GHI</Button.Content>
          </Button>
          <Button
            className="rightBorder evenFlex dialpad-button"
            id="keypad-5"
            value="5"
            onClick={this.numberPressed}
          >
            <Button.Content>5</Button.Content>
            <Button.Content>JKL</Button.Content>
          </Button>
          <Button
            className="evenFlex dialpad-button"
            id="keypad-6"
            value="6"
            onClick={this.numberPressed}
          >
            <Button.Content>6</Button.Content>
            <Button.Content>MNO</Button.Content>
          </Button>
        </Button.Group>
        <Button.Group attached="bottom" className="borderBottom evenHeight">
          <Button
            className="rightBorder evenFlex dialpad-button"
            id="keypad-7"
            value="7"
            onClick={this.numberPressed}
          >
            <Button.Content>7</Button.Content>
            <Button.Content>PQRS</Button.Content>
          </Button>
          <Button
            className="rightBorder evenFlex dialpad-button"
            id="keypad-8"
            value="8"
            onClick={this.numberPressed}
          >
            <Button.Content>8</Button.Content>
            <Button.Content>TUV</Button.Content>
          </Button>
          <Button
            className="evenFlex dialpad-button"
            id="keypad-9"
            value="9"
            onClick={this.numberPressed}
          >
            <Button.Content>9</Button.Content>
            <Button.Content>WXYZ</Button.Content>
          </Button>
        </Button.Group>
        <Button.Group attached="bottom" className="evenHeight">
          <Button
            className="rightBorder evenFlex dialpad-button"
            id="keypad-*"
            value="*"
            onClick={this.numberPressed}
          >
            <Button.Content>
              <span className="star-key">*</span>
            </Button.Content>
            <Button.Content></Button.Content>
          </Button>
          <Button
            className="rightBorder evenFlex dialpad-button"
            id="keypad-0"
            value="0"
            onClick={this.numberPressed}
          >
            <Button.Content>0</Button.Content>
            <Button.Content>+</Button.Content>
          </Button>
          <Button
            className="evenFlex dialpad-button"
            id="keypad-#"
            value="#"
            onClick={this.numberPressed}
          >
            <Button.Content>#</Button.Content>
            <Button.Content></Button.Content>
          </Button>
        </Button.Group>
      </Grid.Column>
    );
  }
}

export default observer(DialPad);
