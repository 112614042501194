import logo from 'Assets/images/outlook-icon.svg';
import {
  AZURE_APP_CLIENT_ID,
  CALENDAR_IDS,
  CALENDAR_SCOPE,
  IS_ELECTRON,
  REDIRECT_URL,
  SHOW_CALENDAR,
} from 'Constants/env';
import { has } from 'lodash';
import { Scope } from 'Models/Calendar';
import React, { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { handleCodeReceived, sendIpcLoginUrl } from 'Utils/ipcRendererEvents';
import type { MicrosoftBusinessProps } from './types';

const MicrosoftBusiness = ({
  person,
  for: propsFor,
  testId,
  hideBelowText,
}: MicrosoftBusinessProps): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const showCalendar =
    SHOW_CALENDAR || CALENDAR_IDS?.includes(person.loggedInPersonId);

  const randomString = (length: number) => {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };

  const scopes = [
    'offline_access',
    'openid',
    'email',
    'profile',
    'User.Read',
    'https://outlook.office365.com/EAS.AccessAsUser.All',
    'https://outlook.office365.com/EWS.AccessAsUser.All',
  ];

  const scope = encodeURIComponent(scopes.filter((a) => a).join(' '));

  const microsoftRedirectionLink = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
  client_id=${AZURE_APP_CLIENT_ID}&
  response_type=code&
  redirect_uri=${encodeURIComponent(REDIRECT_URL)}&
  response_mode=query&
  prompt=select_account&
  scope=${scope}&
  state=${propsFor}&
  nonce=${randomString(6)}`;

  const handleWebRedirection = () =>
    (window.location.href = microsoftRedirectionLink);

  const logIn = (renderProps) => {
    if (IS_ELECTRON && has(window, 'ipcRenderer')) {
      handleCodeReceived((code) => {
        if (loading) {
          return;
        }
        const scopesCode = ['contacts'] as Scope[];
        showCalendar && scopesCode.push(CALENDAR_SCOPE);
        setLoading(true);
        person
          .sendCodeOfProvider(code, 'office365', scopesCode)
          .then((res) => {
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      });
      sendIpcLoginUrl(microsoftRedirectionLink);
    } else {
      handleWebRedirection();
    }
  };
  useEffect(() => {
    const { search } = window?.location;
    if (!IS_ELECTRON) {
      if (search.includes('code=')) {
        setLoading(true);
        const code = search.split('&').find((item) => !!item.includes('code'));
        const scopesCode = ['contacts'] as Scope[];
        showCalendar && scopesCode.push(CALENDAR_SCOPE);
        person
          .sendCodeOfProvider(code.split('code=')[1], 'office365', scopesCode)
          .then((res) => {
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    }
  }, []);

  return (
    <div id="MicrosoftBusiness">
      {loading && (
        <Loader
          className="loading-more-contacts"
          id="MicrosoftBusiness--loader"
        />
      )}
      <div
        key={logo}
        onClick={logIn}
        id={loading ? 'MicrosoftBusiness--loading' : ''}
      >
        <div className="source-box">
          <img src={logo} />
          <span data-testid={`${testId}-microsoftBusiness-buttonSignIn`}>
            Sign in with Outlook Business
          </span>
        </div>
        {!hideBelowText && (
          <div
            className="new-source"
            data-testid={`${testId}-microsoftBusiness-buttonAddNewSource`}
          >
            + Add new source
          </div>
        )}
      </div>
    </div>
  );
};

export default MicrosoftBusiness;
