import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import { Styled } from './index.styles';
import type { IconButtonProps } from './types';

export const IconButton: React.FC<IconButtonProps> = ({
  active = false,
  className,
  compact = false,
  content,
  disabled = false,
  icon,
  onClick,
  popupPosition: position,
  size,
  testid,
  variant = 'text',
}) => (
  <Styled.ButtonSafeArea {...{ className }} $disabled={disabled}>
    {content ? (
      <Popup
        inverted
        {...{ content, position }}
        trigger={
          <div>
            <Styled.Button
              {...{ onClick, disabled, size }}
              $compact={compact}
              $variant={variant}
              $active={active}
              data-testid={testid}
              compact
              basic
            >
              <Styled.Icon {...{ icon, size }} />
            </Styled.Button>
          </div>
        }
      />
    ) : (
      <Styled.Button
        {...{ onClick, disabled, size }}
        $compact={compact}
        $variant={variant}
        $active={active}
        data-testid={testid}
        compact
        basic
      >
        <Styled.Icon {...{ icon, size }} />
      </Styled.Button>
    )}
  </Styled.ButtonSafeArea>
);
