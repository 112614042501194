import React from 'react';
import { Popup } from 'semantic-ui-react';
import { UiStore } from '../../../stores';

const copyIcon = require('../../../assets/images/copy-icon.svg');
const copiedIcon = require('../../../assets/images/copied-icon.svg');
const copyHoverIcon = require('../../../assets/images/copy-icon-hover.svg');
interface CopyComponentState {
  hover: boolean;
}

interface CopyComponentProps {
  ui: UiStore;
  copied: boolean;
  link?: string;
  hovered: boolean;
  handleCopy: () => void;
}
export class CopyComponent extends React.Component<
  CopyComponentProps,
  CopyComponentState
> {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }
  handleHover = (e) => this.setState({ hover: true });

  handleLeave = (e) => this.setState({ hover: false });
  render() {
    const { hovered, copied, link, handleCopy } = this.props;
    const { hover } = this.state;
    const isHovered = hovered || hover;
    const isCopied = copied;
    const textCopy = link
      ? isCopied
        ? 'URL is copied to clipboard'
        : 'Copy URL to clipboard'
      : isCopied
      ? 'Meeting info was copied to clipboard'
      : 'Copy Meeting info to clipboard';
    return (
      <Popup
        inverted
        size="mini"
        trigger={
          <span
            className={`copy-icon pointer ${isCopied ? 'copied-icon' : ''}`}
            onClick={handleCopy}
            onMouseOver={this.handleHover}
            onMouseLeave={this.handleLeave}
          >
            <img
              src={isCopied ? copiedIcon : isHovered ? copyHoverIcon : copyIcon}
            />
          </span>
        }
        content={textCopy}
        position={'right center'}
      />
    );
  }
}
