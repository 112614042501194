import React from 'react';
import type { UserDropdownMenuOptionProps } from './interfaces';
import { Styled } from './UserDropdownMenuOption.styles';

export const UserDropdownMenuOption: React.FC<UserDropdownMenuOptionProps> = ({
  children,
  onClick,
  testid,
  destructive = false,
}) => (
  <div className="flex-grow flex-justify-end flex-align-items-center">
    <Styled.Button
      {...{ onClick }}
      $destructive={destructive}
      data-testid={testid}
      compact
      basic
    >
      {children}
    </Styled.Button>
  </div>
);
