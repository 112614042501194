import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Contact } from 'Models/Contacts';
import * as React from 'react';
import { Loader, Message } from 'semantic-ui-react';
import {
  formatNumberNoPlusIfUS,
  formatNumberWithNationalCode,
} from 'Utils/phoneUtil';
import { ChatListItemTextRendererProps } from './ChatListItemTextRenderer.types';
import { ChatListItemText } from './index';

const ChatListItemTextRendererObserver: React.FC<
  ChatListItemTextRendererProps
> = ({
  conversation,
  participants,
  getExtrContactByPhoneNumber,
  contactsByPhone,
  addProperName,
  getExtrContactFromPboList,
  selectParticipantPersonInfo,
  from,
  loggedInPersonId,
  selectUnreadCounts,
  ui,
  getOrSetExtrContactByPhoneNumber,
}) => {
  if (from === 'group-info') return null;

  let listOfContactsPromises;
  let isUnread = false;
  if (selectUnreadCounts(conversation.id).unreadMessages > 0) {
    isUnread = true;
  }

  if (
    conversation?.grouping === 'Channel' ||
    conversation?.grouping === 'Group'
  ) {
    if (
      (conversation.topic?.includes('Group') || !conversation.topic) &&
      conversation.grouping === 'Group'
    ) {
      runInAction(
        () => (conversation.topic = addProperName(conversation.participants))
      );
      listOfContactsPromises = conversation.participants.map((participant) =>
        getOrSetExtrContactByPhoneNumber(participant.phone)
      );
      return (
        <ChatListItemText
          bold={isUnread}
          title={listOfContactsPromises.map(
            (listOfContactsPromise) =>
              listOfContactsPromise?.case({
                pending: () => <Loader indeterminate active size="tiny" />,
                fulfilled: () => addProperName(conversation.participants),
              }) || conversation.topic
          )}
        />
      );
    }

    return <ChatListItemText bold={isUnread} title={conversation.topic} />;
  }

  if (conversation?.grouping === 'OneOnOne') {
    const otherPtc = participants.find(
      ({ personId }) => personId !== loggedInPersonId
    );
    const mySelfPtc = participants.find(
      ({ personId }) => personId === loggedInPersonId
    );

    if (otherPtc) {
      if (otherPtc.phone) {
        const phoneNumNoPlus = formatNumberNoPlusIfUS(otherPtc.phone);
        const phoneNumWithNationalCode = formatNumberWithNationalCode(
          otherPtc.phone
        );
        if (contactsByPhone.has(phoneNumNoPlus)) {
          const otherPtcContact = contactsByPhone.get(phoneNumNoPlus);
          if (!otherPtcContact)
            return <Loader indeterminate active size="tiny" />;

          return otherPtcContact.case({
            fulfilled: ({ data }) => {
              const extrContact = getExtrContactByPhoneNumber(data.phoneNumber);
              const displayName =
                extrContact?.DisplayName() || data?.DisplayName;

              if (!extrContact) {
                const extrContactPbo =
                  getExtrContactFromPboList(phoneNumNoPlus);

                return extrContactPbo?.case({
                  pending: () => <Loader indeterminate active size="tiny" />,
                  fulfilled: (resp) => {
                    const extrContact =
                      resp.data?.items?.[0] && new Contact(resp.data.items[0]);

                    return (
                      <ChatListItemText
                        bold={isUnread}
                        title={
                          extrContact?.DisplayName() ||
                          phoneNumWithNationalCode ||
                          displayName ||
                          conversation.topic
                        }
                        subtitle="[SMS]"
                      />
                    );
                  },
                });
              }

              return (
                <ChatListItemText
                  bold={isUnread}
                  title={
                    displayName ||
                    phoneNumWithNationalCode ||
                    conversation.topic
                  }
                  subtitle="[SMS]"
                />
              );
            },
            pending: () => <Loader indeterminate active size="tiny" />,
            rejected: () => (
              <ChatListItemText
                bold={isUnread}
                title={phoneNumWithNationalCode}
                subtitle="[SMS]"
              />
            ),
          });
        }

        const extrContactPbo = getExtrContactFromPboList(phoneNumNoPlus);
        if (!extrContactPbo)
          <ChatListItemText bold={isUnread} title={phoneNumWithNationalCode} />;

        return extrContactPbo.case({
          pending: () => <Loader indeterminate active size="tiny" />,
          fulfilled: (resp) => {
            const extrContact =
              resp.data?.items?.[0] && new Contact(resp.data.items[0]);
            return (
              <ChatListItemText
                bold={isUnread}
                title={extrContact?.DisplayName() || phoneNumWithNationalCode}
              />
            );
          },
        });
      }

      if (isFinite(otherPtc.personId)) {
        const otherPtcPerson = selectParticipantPersonInfo(otherPtc.personId);
        if (!otherPtcPerson) return <Loader indeterminate active size="tiny" />;

        return otherPtcPerson.case({
          fulfilled: (response) => {
            // Worst case scenario we don't know the name
            const displayName =
              response?.data?.DisplayName || conversation.topic;
            const emoji = ui.selectPersonMessageStatus(response.data.id);

            return (
              <ChatListItemText
                bold={isUnread}
                title={displayName}
                {...{ emoji }}
              />
            );
          },
          pending: () => <Loader indeterminate active size="tiny" />,
          rejected: () => (
            <div data-private className="display-name color-removed">
              Person {otherPtc.personId}
            </div>
          ),
        });
      }

      return (
        <Message
          visible
          error
          content={
            'Error: Empty personId and phone for Participant ' + otherPtc.id
          }
        />
      );
    }

    if (mySelfPtc && isFinite(mySelfPtc.personId)) {
      const mySelfPtcPerson = selectParticipantPersonInfo(mySelfPtc.personId);
      if (!mySelfPtcPerson) return <Loader indeterminate active size="tiny" />;

      return mySelfPtcPerson.case({
        fulfilled: (response) => {
          // Worst case scenario we don't know the name
          const displayName = response?.data?.DisplayName || conversation.topic;
          const emoji = ui.selectPersonMessageStatus(response.data.id);

          return (
            <ChatListItemText
              bold={isUnread}
              title={displayName}
              {...{ emoji, isOwnChat: true }}
            />
          );
        },
        pending: () => <Loader indeterminate active size="tiny" />,
        rejected: () => (
          <div data-private className="display-name color-removed">
            Person {mySelfPtc.personId}
          </div>
        ),
      });
    }

    return <Message visible error content="Other Participant not found!" />;
  }

  return null;
};

export const ChatListItemTextRenderer = observer(
  ChatListItemTextRendererObserver
);
