import {
  CONVERSATION_TOPIC_MAXLENGTH,
  CONVERSATION_TOPIC_REGEXP,
} from 'Constants/validation';
import { get } from 'lodash';
import { action, computed, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { createViewModel, IViewModel } from 'mobx-utils';
import { ConversationModel } from 'Models/ConversationModel';
import * as React from 'react';
import { Input, Message } from 'semantic-ui-react';
import { ChannelInfoEditProps } from './interface';

/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ChannelInfoEdit
 */
export class ChannelInfoEdit extends React.Component<
  ChannelInfoEditProps,
  { emptyField: string }
> {
  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      emptyField: null,
    };
  }

  @observable
  conversationVm: ConversationModel & IViewModel<ConversationModel>;

  @observable
  hasEnteredTopic: boolean = false;

  channelInfoInputField: any;

  @action
  onTopicChange = (e) => {
    const { conversationVm, clearTopicNameTaken } = this.props;
    conversationVm?.model.setTopicEdit(e.target.value);
    this.hasEnteredTopic = true;
    clearTopicNameTaken();
  };

  componentWillMount() {
    const { setConversationVm } = this.props;
    setConversationVm(createViewModel(this.props.conversation));
  }
  onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Enter key
    if (e.keyCode === 13 && e.ctrlKey) {
      this.commitViewModelChanges(e);
    }
  };
  commitViewModelChanges = (e) => {
    const { conversationVm } = this.props;
    conversationVm.submit();
  };
  componentDidMount() {
    const { conversationVm } = this.props;
    conversationVm?.model.setTopicEdit(conversationVm.topic);
  }
  @computed private get _TopicValid() {
    return (
      get(this.props.conversationVm, 'unsavedTopic.length', 0) > 0 &&
      get(this.props.conversationVm, 'unsavedTopic.length', 0) <=
        CONVERSATION_TOPIC_MAXLENGTH &&
      CONVERSATION_TOPIC_REGEXP.test(
        get(this.props.conversationVm, 'unsavedTopic', '')
      )
    );
  }

  render() {
    const { topicNameTaken, conversationVm } = this.props;
    const topicValue =
      conversationVm?.unsavedTopic ||
      (conversationVm?.unsavedTopic === null && conversationVm?.topic) ||
      '';
    return (
      <div id="channel-info-edit" className="ui form">
        <div
          id="groupName"
          className={`group-modal-topics ${
            this.state.emptyField === 'groupName' ? 'empty-field' : ''
          }`}
        >
          Group Name
        </div>
        <Input
          placeholder="My New Group"
          fluid
          autoFocus
          type="text"
          error={this.hasEnteredTopic && !this._TopicValid}
          value={topicValue}
          onChange={this.onTopicChange}
          onKeyUp={this.onKeyUp}
          ref={(input) => (this.channelInfoInputField = input)}
        />
        <div>
          {(this.hasEnteredTopic && !this._TopicValid) ||
            (topicNameTaken && (
              <div className="invalid-topic">Invalid Group Topic !</div>
            ))}
        </div>
        <Message
          error
          className="error-background-groups"
          visible={this.hasEnteredTopic && !this._TopicValid}
        >
          <Message.Content content="Group Topic must..." />
          <Message.List>
            <Message.Item
              content={`Contain 1 to ${CONVERSATION_TOPIC_MAXLENGTH} characters`}
            />
            <Message.Item content="Start with a letter" />
            <Message.Item content='Contain only letters, numbers, underscores ("_"), and dashes ("-")' />
          </Message.List>
        </Message>
        <Message
          error
          className="error-background-groups"
          visible={topicNameTaken}
        >
          <Message.Header content="Name Already Taken" />
          <Message.Content content="Please choose another name" />
        </Message>
      </div>
    );
  }
}

export default observer(ChannelInfoEdit);
