import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { createRef, useEffect, useState } from 'react';
import { Button, Grid, Input } from 'semantic-ui-react';
import backspace from '../../assets//images/backspace-dialpad.svg';
import { validCodes } from '../../constants/env';
import {
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_UI,
} from '../../constants/stores';
import Dialpad from '../Dialpad';
import { Styled } from './index.styles';
import { ITopBarDialpad } from './interfaces';
const phoneIcon = require('../../assets/images/menu-call.svg');

const TopBarDialpad = (props: ITopBarDialpad) => {
  const {
    ui: {
      openTopbarDialpad,
      setOpenTopbarDialpad,
      removeFromOpenedRightSidebarsOrder,
    },
    person: personStore,
    phoneCall: { ActivePhoneCall },
    isFixed,
  } = props;

  const initialInputValue = () =>
    ActivePhoneCall?.showDialPad ? ActivePhoneCall.keysPressed : '';

  const [inputNumber, setInputNumber] = useState<string>(initialInputValue());
  const inputRef = createRef<HTMLInputElement>();
  const [lastFocusedPosition, setLastFocusedPosition] = useState(
    initialInputValue().length
  );

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    handleCursorPos(lastFocusedPosition);
  }, [inputNumber]);

  const handleCursorPos = (cursorPos?: number) => {
    if (inputRef.current) {
      if (cursorPos) {
        inputRef.current.setSelectionRange(cursorPos, cursorPos);
        inputRef.current.focus();
        setLastFocusedPosition(cursorPos);
      } else {
        inputRef.current.setSelectionRange(
          lastFocusedPosition,
          lastFocusedPosition
        );
        inputRef.current.focus();
      }
    }
  };

  const makeButtonFocused = (number: string) => {
    if (/\d|#|\*|\+/.test(number)) {
      number = number === '+' ? '0' : number;
      const element = document.getElementById(`keypad-${number}`);
      element.classList.add('focused');
      element.focus();
      props.keyPadPush && props.keyPadPush(number);
      const focusedInterval = setTimeout(() => {
        element.classList.remove('focused');
        clearInterval(focusedInterval);
      }, 300);
    }
  };

  const setValuefromDialpad = (value: string) => {
    makeButtonFocused(value);
    setInputNumber(
      inputNumber.slice(0, lastFocusedPosition) +
        value +
        inputNumber.slice(lastFocusedPosition)
    );
    if (document.activeElement?.id?.includes(`keypad-${value}`)) {
      setLastFocusedPosition(lastFocusedPosition + 1);
    }
  };

  const makeCall = () => {
    if (isInputValid()) {
      props.phoneCall.callWithPerson(
        null,
        checkIsBvCode(inputNumber)
          ? inputNumber
          : inputNumber.replace(/\D/g, '')
      );
      props.ui.setOpenTopbarDialpad(false);
    }
  };

  const deleteWithBackspace = () => {
    if (inputRef.current?.selectionStart !== inputRef.current?.selectionEnd) {
      setInputNumber(
        inputNumber.slice(0, inputRef.current?.selectionStart) +
          inputNumber.slice(inputRef.current?.selectionEnd, inputNumber.length)
      );
      return;
    }

    if (lastFocusedPosition === 0) return;

    setInputNumber(
      inputNumber.slice(0, lastFocusedPosition - 1) +
        inputNumber.slice(lastFocusedPosition)
    );
    setLastFocusedPosition(lastFocusedPosition ? lastFocusedPosition - 1 : 0);
  };

  const onFocusLost = (e) => {
    setLastFocusedPosition(inputRef?.current?.selectionStart);
  };

  const shouldChangeInput = (e, newInputValue: string) =>
    !(
      props.keyPadPush && e?.nativeEvent?.inputType === 'deleteContentBackward'
    ) && newInputValue != inputNumber;

  const onInputChange = (e) => {
    const position = inputRef?.current?.selectionStart;
    const number = e.target.value[position - 1];
    const newInputValue = e.target.value
      ?.trim()
      ?.replace(/[^0-9+|\*|\-|\(|\)|\#\+]/g, '');
    if (
      e?.nativeEvent?.inputType !== 'deleteContentBackward' &&
      newInputValue.length - inputNumber.length === 1
    )
      makeButtonFocused(number);
    if (shouldChangeInput(e, newInputValue)) {
      setInputNumber(newInputValue);
      setLastFocusedPosition(position);
    }
  };

  const checkIsBvCode = (inputNumber: string) => {
    return validCodes.some((code) =>
      inputNumber.replace(/\s/g, '').includes(code)
    );
  };

  const closeTopbarDialpad = () => {
    setOpenTopbarDialpad(false);
    removeFromOpenedRightSidebarsOrder('dial-pad');
  };

  const onKeyUp = (e) => {
    if (e?.key === 'Enter' && !ActivePhoneCall?.showDialPad) makeCall();
  };

  const isInputValid = () => {
    const isBroadVoiceCode = checkIsBvCode(inputNumber);
    const phoneNumValid = !personStore.phoneNumInvalid(inputNumber);
    const phoneNumber = isBroadVoiceCode
      ? inputNumber
      : inputNumber.replace(/[^0-9+]/g, '');
    return phoneNumber.length >= 2 && (phoneNumValid || isBroadVoiceCode);
  };
  const largeColumns = 4;
  const smallColumns = !openTopbarDialpad ? 4 : 6;
  const showDialpad = ActivePhoneCall?.showDialPad;

  return (
    <Styled.TopBarDialpadStyled
      $zIndex={props.ui.openedRightSidebarsOrder.get('dial-pad')}
      $isRightSidebarOpened={props.ui.openedRightSidebarsOrder.size > 1}
      $isFixed={isFixed}
      icon="labeled"
      className={cx('topbar-dialpad-wrapper', {
        'softphone--fixed softphone--fixed': isFixed,
      })}
      widescreen={largeColumns}
      computer={largeColumns}
      desktop={largeColumns}
      tablet={smallColumns}
      mobile={smallColumns}
    >
      <div id="topbar-dialpad">
        {!showDialpad && isFixed && (
          <Grid.Row className="close-topbar-dialpad">
            <span onClick={closeTopbarDialpad}>Close</span>
          </Grid.Row>
        )}
        <Grid.Row>
          <Input
            input={{ ref: inputRef }}
            className={inputNumber.length > 0 ? 'large-text' : ''}
            id={'dialpad-input'}
            placeholder={!showDialpad ? 'Type a number to start' : ''}
            value={inputNumber}
            onChange={onInputChange}
            onBlur={onFocusLost}
            onKeyUp={onKeyUp}
          />
          {inputNumber.length > 0 && !showDialpad && (
            <img
              className="small-icon"
              id="backspace-dialpad"
              src={backspace}
              onClick={deleteWithBackspace}
            />
          )}
        </Grid.Row>
        <Grid.Row>
          <Dialpad numberClick={setValuefromDialpad}></Dialpad>
        </Grid.Row>
        <Grid.Row>
          {!showDialpad && (
            <Grid.Column className="call-dialpad">
              <Button
                disabled={!inputNumber.length || !isInputValid()}
                onClick={makeCall}
                circular
                id="phone-call-dialpad"
              >
                <Button.Content visible>
                  <img className="small-icon" src={phoneIcon} />
                </Button.Content>
              </Button>
              <span>Call</span>
            </Grid.Column>
          )}
        </Grid.Row>
      </div>
    </Styled.TopBarDialpadStyled>
  );
};

export default inject(
  STORE_UI,
  STORE_PHONE_CALL,
  STORE_PERSON
)(observer(TopBarDialpad));
