import { IS_ELECTRON } from 'Constants/env';
import { IParticipantPerson } from 'Interfaces/participantPerson';
import * as React from 'react';
import SimpleMarkdown, {
  HtmlOutputRule,
  ParserRule,
  ReactOutputRule,
  Rules,
} from 'simple-markdown';
import { isNullOrUndefined } from 'util';
import { pushToGTMDataLayer } from './analytics';
import { sendIpcOpenVideo } from './ipcRendererEvents';

//           //
// COMPILERS //
//           //

// TODO: @param asHtml If true, return the compiled content as a HTML string
/**
 * Compile a Markdown formatted string to either React (or in the future, HTML, still working on that - RP 2018-11-18)
 *
 * @param source The input string to be compiled
 * @param includeMentions Whether to include rules for processing @Mentions (ex. only in `Channel` mode)
 * @param participantPersons An array of wrapped `ParticipantModel`s, linked with their `PersonModel`s, used for Mentions.
 * @param setLoadingLinkPreview A function bound to the current `MessageModel` Id, which sets a flag indicating it is loading a link preview.
 * @param buildReactLinkPreview An `action` which should load the link preview for the Message model, given the URI.
 * @param linkPreviewExistsOrLoading Whether there is a link preview already loaded, or currently loading
 * @param electronOpenExternal A function that handles clicking a link while running within Electron,
 * opening the OS default browser (rather than a new Electron window)
 * @returns `React.ReactNode` (can be either React, or in the future, HTML)
 */
export function compileChatDisplayMarkdown(
  source: string,
  includeMentions: boolean,
  participantPersons: IParticipantPerson[],
  setLoadingLinkPreview: (loading: boolean) => void,
  buildReactLinkPreview: (nodeTarget: string) => void,
  linkPreviewExistsOrLoading: boolean,
  electronOpenExternal: (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => boolean | void
): React.ReactNode {
  // if message contains no text to display
  if (!source) {
    return null;
  }

  const mdRules = {
    ...SimpleMarkdown.defaultRules,
    underline: buildUnderlineRule(),
    singleBacktick: buildSingleBacktickRule(),
    link: buildLinkAddTargetBlankRule(
      setLoadingLinkPreview,
      buildReactLinkPreview,
      linkPreviewExistsOrLoading,
      electronOpenExternal
    ),
    codeBlock: buildCodeBlockRule(),
    inlineVideoRule: buildInlineVideoRule(electronOpenExternal),
    imageLinkRule: buildImageLinkRule(electronOpenExternal),
    videoLinkRule: buildVideoLinkRule(electronOpenExternal),
    audioLinkRule: buildAudioLinkRule(electronOpenExternal),
    heading: buildHeadingRule(),
    paragraph: buildParagraphRule(),
  } as Rules<ReactOutputRule>;

  // Mentions are only added for Channels, no other types of conversations
  if (includeMentions) {
    const mentionRule = buildDisplayMentionRule(participantPersons);
    const mentionKeywordRule = buildMentionKeywordRule();
    mdRules.mentionRule = mentionRule;
    mdRules.mentionKeywordRule = mentionKeywordRule;
  }

  const mdParse = SimpleMarkdown.parserFor(mdRules);

  // Many rules require content to end in \n\n to be interpreted as a block.
  const blockSource = source + '\n\n';
  const parseTree = mdParse(blockSource, { inline: true });

  // if(!asHtml) {
  const mdOutput = SimpleMarkdown.reactFor(
    SimpleMarkdown.ruleOutput(mdRules, 'react')
  );
  return mdOutput(parseTree);
  // }
  // else{
  //     const mdOutput = SimpleMarkdown.reactFor(SimpleMarkdown.ruleOutput(mdRules, 'html'));
  //     return mdOutput(parseTree);
  // }
}

/**
 * Compile the current Markdown formatted text content of a Message, normalizing it and processing its Mentions into `<div>`s
 *
 * @param source The input string to be compiled
 * @param participantPersons An array of wrapped `ParticipantModel`s, linked with their `PersonModel`s, used for Mentions.
 * @returns HTML string
 */
export function compileEditHtmlWithMentions(
  source: string,
  participantPersons: IParticipantPerson[]
) {
  const mdRules = {
    text: SimpleMarkdown.defaultRules.text,
    newline: SimpleMarkdown.defaultRules.newline,
    mentionRule: buildInputMentionRule(participantPersons),
    mentionKeywordRule: buildInputMentionKeywordRule(),
  } as Rules<HtmlOutputRule>;

  const mdParse = SimpleMarkdown.parserFor(mdRules);
  const parseTree = mdParse(source + '\n\n', { inline: false });

  const mdOutput = SimpleMarkdown.htmlFor(
    SimpleMarkdown.ruleOutput(mdRules, 'html')
  );
  return mdOutput(parseTree);
}

// !!!!!!!!!!!!! //
//               //
// RULE BUILDERS //
//               //
// !!!!!!!!!!!!! //

/**
 * A `SimpleMarkdown` `paragraph` rule
 * @returns A `SimpleMarkdown` `paragraph` rule
 */
export function buildParagraphRule(): SimpleMarkdown.ParserRule &
  SimpleMarkdown.ReactOutputRule &
  SimpleMarkdown.HtmlOutputRule {
  return Object.assign({}, SimpleMarkdown.defaultRules.paragraph, {
    match: SimpleMarkdown.blockRegex(
      /^((?:[^\n]|\n(?! *\n))+?)(?:\n *?)+(?:\n|(?=#))/
    ),
  });
}

/**
 * A `SimpleMarkdown` `heading` rule
 * @returns A `SimpleMarkdown` `heading` rule
 */
export function buildHeadingRule(): SimpleMarkdown.ParserRule &
  SimpleMarkdown.ReactOutputRule &
  SimpleMarkdown.HtmlOutputRule {
  return Object.assign({}, SimpleMarkdown.defaultRules.heading, {
    match: SimpleMarkdown.blockRegex(/^ *(#{1,6}) *([^\n]+?) *#* *(?:\n *)*\n/),
  });
}

/**
 * A `SimpleMarkdown` `mention` rule (for display, NOT input)
 * @param participantPersons An array of wrapped `ParticipantModel`s, linked with their `PersonModel`s, used for Mentions.
 * @returns A `SimpleMarkdown` `mention` rule
 */
export function buildDisplayMentionRule(
  participantPersons: IParticipantPerson[]
) {
  return {
    order: SimpleMarkdown.defaultRules.paragraph.order + 0.5,
    match: (matchSrc) => {
      return RegExp(/^@pr([0-9]\d*)/).exec(matchSrc);
    },
    parse: (capture, parse, state) => {
      return {
        original: capture[0],
        content: parse(capture[1], state),
      };
    },
    react: (node, output, state) => {
      const resolvedPersonId = output(node.content, state).toString();
      const mentionedPtc = !isNullOrUndefined(participantPersons)
        ? participantPersons.find(
            (p) => p.person.id.toString() === resolvedPersonId
          )
        : null;
      if (!isNullOrUndefined(mentionedPtc)) {
        return (
          <span key={state.key} className="mention">
            @{mentionedPtc.person.DisplayName}
          </span>
        );
      } else {
        return (
          <span key={state.key} className="mention unknown">
            @Unknown User
          </span>
        );
      }
    },
    html: (node, output, state) => {
      const resolvedPersonId = output(node.content, state).toString();
      const mentionedPtc = !isNullOrUndefined(participantPersons)
        ? participantPersons.find(
            (p) => p.person.id.toString() === resolvedPersonId
          )
        : null;
      if (!isNullOrUndefined(mentionedPtc)) {
        return `<span data-key="{state.key}" class="mention">@${mentionedPtc.person.DisplayName}</span>`;
      } else {
        return `<span data-key="{state.key}" class="mention unknown">@Unknown User</span>`;
      }
    },
  } as ParserRule & ReactOutputRule & HtmlOutputRule;
}

/**
 * A `SimpleMarkdown` `mention` rule (for input, NOT display)
 * @param participantPersons An array of wrapped `ParticipantModel`s, linked with their `PersonModel`s, used for Mentions.
 * @returns A `SimpleMarkdown` `mention` rule
 */
export function buildInputMentionRule(
  participantPersons: IParticipantPerson[]
) {
  return {
    order: SimpleMarkdown.defaultRules.paragraph.order + 0.5,
    match: (matchSrc) => {
      return RegExp(/^@pr([0-9]\d*)/).exec(matchSrc);
    },
    parse: (capture, parse, state) => {
      return {
        original: capture[0],
        content: parse(capture[1], state),
      };
    },
    react: (node, output, state) => {
      const resolvedPersonId = output(node.content, state).toString();
      const mentionedPtc = participantPersons.find(
        (p) => p.person.id.toString() === resolvedPersonId
      );
      if (mentionedPtc !== undefined) {
        return (
          <>
            &nbsp;
            <div
              key={state.key}
              className="input-mention"
              mention-target={`@pr${resolvedPersonId}`}
            >
              @{mentionedPtc.person.DisplayName}
            </div>
            &nbsp;
          </>
        );
      } else {
        return (
          <>
            &nbsp;
            <div
              key={state.key}
              className="input-mention unknown"
              mention-target={`@pr${resolvedPersonId}`}
            >
              @Unknown User
            </div>
            &nbsp;
          </>
        );
      }
    },
    html: (node, output, state) => {
      const resolvedPersonId = output(node.content, state).toString();
      const mentionedPtc = participantPersons.find(
        (p) => p.person.id.toString() === resolvedPersonId
      );
      if (mentionedPtc !== undefined) {
        // Note the extra surrounding spaces, this creates a gap that we can use to prevent the cursor from ending up inside the mention display text
        // This also happens in `Utils/turndownSvc`
        return `&nbsp;<div class="input-mention" mention-target="@pr${resolvedPersonId}" contenteditable="false">@${mentionedPtc.person.DisplayName}</div>&nbsp;`;
      } else {
        return `&nbsp;<div class="input-mention unknown" mention-target="@pr${resolvedPersonId}" contenteditable="false">@Unknown User</div>&nbsp;`;
      }
    },
  } as ParserRule & ReactOutputRule & HtmlOutputRule;
}

/**
 * A `SimpleMarkdown` `mentionKeyword` rule (for input, NOT display)
 * @returns A `mentionKeyword` `imageLink` rule
 */
export function buildInputMentionKeywordRule() {
  return {
    order: SimpleMarkdown.defaultRules.paragraph.order + 0.5,
    match: (matchSrc) => {
      return RegExp(/^(@here)/).exec(matchSrc);
    },
    parse: (capture) => {
      return {
        content: capture[0],
      };
    },
    react: (node, output, state) => {
      return (
        <>
          &nbsp;
          <div
            key={state.key}
            className="input-mention"
            mention-target={node.content}
          >
            <>{node.content}</>
          </div>
          &nbsp;
        </>
      );
    },
    html: (node) => {
      return `&nbsp;<div class="input-mention" mention-target="${node.content}" contenteditable="false">${node.content}</div>&nbsp;`;
    },
  } as ParserRule & ReactOutputRule & HtmlOutputRule;
}

/**
 * A `SimpleMarkdown` custom `lineBreak` rule for **INPUT**
 * @returns A `SimpleMarkdown` custom `lineBreak` rule
 */
/*export function buildInputLineBreakRule(): SimpleMarkdown.ParserRule & SimpleMarkdown.ReactOutputRule & SimpleMarkdown.HtmlOutputRule {
    return {
        match: SimpleMarkdown.blockRegex(/\n/),
        parse: ignoreCapture,
        order: SimpleMarkdown.defaultRules.text.order - 0.5, // Directly before text
        react: (node, output, state) => {
            return <><br/><br/></>;
        },
        html: (node, output, state) => {
            return '<br><br>';
        }
    };
}*/

/**
 * A `SimpleMarkdown` `mentionKeyword` rule
 * Special click handler used when in Electron environment
 * to open a link in a new tab in the OS default browser (rather than an Electron window).
 * @returns A `SimpleMarkdown` `imageLink` rule
 */
export function buildMentionKeywordRule() {
  return {
    order: SimpleMarkdown.defaultRules.paragraph.order + 0.5,
    match: (matchSrc) => {
      return RegExp(/^(@here)/).exec(matchSrc);
    },
    parse: (capture) => {
      return {
        content: capture[0],
      };
    },
    react: (node, output, state) => {
      return (
        <span key={state.key} className={'mention'}>
          <>{node.content}</>
        </span>
      );
    },
  } as ParserRule & ReactOutputRule;
}

/**
 * A `SimpleMarkdown` `imageLink` rule
 * @param electronOpenExternal Special click handler used when in Electron environment
 * to open a link in a new tab in the OS default browser (rather than an Electron window).
 * @returns A `SimpleMarkdown` `imageLink` rule
 */
export function buildImageLinkRule(
  electronOpenExternal: (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => boolean | void
) {
  return {
    order: 0,
    match: (matchSrc) => {
      return RegExp(/^(https?:\/\/.*\.(?:png|jpg|jpeg|gif).*)/).exec(matchSrc);
    },
    parse: (capture) => {
      return {
        target: capture[0],
      };
    },
    react: (node, output, state) => {
      return (
        <>
          {IS_ELECTRON ? (
            <a
              key={state.key + '_a'}
              data-ext-url={node.target}
              onClick={electronOpenExternal}
            >
              {node.target}
            </a>
          ) : (
            <a key={state.key + '_a'} href={node.target} target="_blank" rel="noreferrer">
              {node.target}
            </a>
          )}
          <br />
          <img
            key={state.key}
            className={'inline-image'}
            src={node.target}
            onError={(e) => {
              e.currentTarget.style.display = 'none';
            }}
          />
        </>
      );
    },
  } as ParserRule & ReactOutputRule;
}

/**
 * A `SimpleMarkdown` `audioLink` rule
 * @param electronOpenExternal Special click handler used when in Electron environment
 * to open a link in a new tab in the OS default browser (rather than an Electron window).
 * @returns A `SimpleMarkdown` `audioLink` rule
 */
export function buildAudioLinkRule(
  electronOpenExternal: (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => boolean | void
) {
  return {
    order: 0,
    match: (matchSrc) => {
      return RegExp(/^(https?:\/\/.*\.(?:mp3|ogg|wav|flac).*)/).exec(matchSrc);
    },
    parse: (capture) => {
      return {
        target: capture[0],
      };
    },
    react: (node, output, state) => {
      return (
        <>
          {IS_ELECTRON ? (
            <a
              key={state.key + '_a'}
              data-ext-url={node.target}
              onClick={electronOpenExternal}
            >
              {node.target}
            </a>
          ) : (
            <a key={state.key + '_a'} href={node.target} target="_blank" rel="noreferrer">
              {node.target}
            </a>
          )}
          <br />
          <audio
            key={state.key}
            className={'inline-audio'}
            controls
            onError={(e) => {
              e.currentTarget.style.display = 'none';
            }}
          >
            <source src={node.target} />
            {'Your browser does not support the audio element.'}
          </audio>
        </>
      );
    },
  } as ParserRule & ReactOutputRule;
}

/**
 * A `SimpleMarkdown` `videoLink` rule
 * @param electronOpenExternal Special click handler used when in Electron environment
 * to open a link in a new tab in the OS default browser (rather than an Electron window).
 * @returns A `SimpleMarkdown` `videoLink` rule
 */
export function buildVideoLinkRule(
  electronOpenExternal: (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => boolean | void
) {
  return {
    order: 0,
    match: (matchSrc) => {
      return RegExp(/^(https?:\/\/.*\.(?:mp4|ogg|webm).*)/).exec(matchSrc);
    },
    parse: (capture) => {
      return {
        target: capture[0],
      };
    },
    react: (node, output, state) => {
      return (
        <>
          {IS_ELECTRON ? (
            <a
              key={state.key + '_a'}
              data-ext-url={node.target}
              onClick={electronOpenExternal}
            >
              {node.target}
            </a>
          ) : (
            <a key={state.key + '_a'} href={node.target} target="_blank" rel="noreferrer">
              {node.target}
            </a>
          )}
          <br />
          <video
            key={state.key}
            className={'inline-video'}
            controls
            onError={(e) => {
              e.currentTarget.style.display = 'none';
            }}
          >
            <source src={node.target} />
            {'Your browser does not support the video element.'}
          </video>
        </>
      );
    },
  } as ParserRule & ReactOutputRule;
}

/**
 * A `SimpleMarkdown` `inlineVideo` rule
 * @param electronOpenExternal Special click handler used when in Electron environment
 * to open a link in a new tab in the OS default browser (rather than an Electron window).
 * @returns A `SimpleMarkdown` `inlineVideo` rule
 */
export function buildInlineVideoRule(
  electronOpenExternal: (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => boolean | void
) {
  return {
    order: SimpleMarkdown.defaultRules.link.order - 0.5,
    match: (matchSrc) => {
      const LINK_HREF_AND_TITLE =
        '\\s*<?((?:[^\\s\\\\]|\\\\.)*?)>?(?:\\s+[\'"]([\\s\\S]*?)[\'"])?\\s*';
      return RegExp('^!VIDEO\\(' + LINK_HREF_AND_TITLE + '\\)').exec(matchSrc);
    },
    parse: (capture) => {
      return {
        target: capture[1],
      };
    },
    react: (node, output, state) => {
      return (
        <>
          {IS_ELECTRON ? (
            <a
              key={state.key + '_a'}
              data-ext-url={node.target}
              onClick={electronOpenExternal}
            >
              {node.target}
            </a>
          ) : (
            <a key={state.key + '_a'} href={node.target} target="_blank" rel="noreferrer">
              {node.target}
            </a>
          )}
          <br />
          <video key={state.key} className={'inline-video'} controls>
            {' '}
            <source src={node.target} />
          </video>
        </>
      );
    },
  } as ParserRule & ReactOutputRule;
}

/**
 * A `SimpleMarkdown` `codeBlock` rule
 *
 * **NOTE:** This is being used to disable the standard codeBlock rule from SimpleMarkdown.
 * There seems to be a bug with certain very fringe use cases.
 * Setting match false doesn't affect the markdown in any way.
 *
 * @returns A `SimpleMarkdown` `codeBlock` rule
 */
export function buildCodeBlockRule() {
  return {
    ...SimpleMarkdown.defaultRules.codeBlock,
    order: SimpleMarkdown.defaultRules.codeBlock.order - 0.5,
    match: () => {
      return false;
    },
    react: (node) => {
      return (
        <pre>
          <>{node.content}</>
        </pre>
      );
    },
    html: (node) => {
      return `<pre>${node.content}</pre>`;
    },
  } as ParserRule & ReactOutputRule & HtmlOutputRule;
}

/**
 * Build a `SimpleMarkdown` `link` rule.
 *
 * @param setLoadingLinkPreview An `action` which should set the `loading` flag true for link preview
 * @param buildReactLinkPreview An `action` which should load the link preview for the Message model, given the URI.
 * @param linkPreviewExistsOrLoading Whether there is a link preview already loaded, or currently loading
 * @param electronOpenExternal Special click handler used when in Electron environment
 * to open a link in a new tab in the OS default browser (rather than an Electron window).
 * @returns A `SimpleMarkdown` rule which should be assigned to the `link` key
 */
export function buildLinkAddTargetBlankRule(
  setLoadingLinkPreview: (loading: boolean) => void,
  buildReactLinkPreview: (nodeTarget: string) => void,
  linkPreviewExistsOrLoading: boolean,
  electronOpenExternal: (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => boolean | void
) {
  return {
    ...SimpleMarkdown.defaultRules.link,
    order: 0,
    match: (matchSrc) => {
      return /^(\[[A-z0-9 _]*]\()?((?:(http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6})(\))?$/.exec(
        matchSrc
      );
    },
    parse: (capture) => ({
      content: capture[2],
      target: capture[2],
      title: capture[3],
    }),
    react: (node, output, state) => {
      if (!linkPreviewExistsOrLoading) {
        setLoadingLinkPreview(true);
        buildReactLinkPreview(node.target);
      }
      let linkTarget: string = node.target;
      if (
        !linkTarget?.startsWith('http://') &&
        !linkTarget?.startsWith('https://')
      ) {
        linkTarget = 'https://' + linkTarget;
      }
      // Open native OS browser in Electron *unless* it's a video link
      if (IS_ELECTRON) {
        if (linkTarget.indexOf(window.location.origin + '/video') === -1) {
          return (
            <a
              key={state.key}
              data-ext-url={linkTarget}
              onClick={electronOpenExternal}
            >
              {linkTarget}
            </a>
          );
        } else {
          return (
            <a
              key={state.key}
              data-ext-url={linkTarget}
              onClick={() => {
                if (!sendIpcOpenVideo(linkTarget)) {
                  console.error(
                    'markdownCompiler.buildLinkAddTargetBlankRule: Electron env ipcRenderer not found'
                  );
                }
              }}
            >
              {linkTarget}
            </a>
          );
        }
      }
      return (
        <a
          key={state.key}
          href={linkTarget}
          target={'_blank'}
          onClick={reportLinkAnchorClickToGTM(linkTarget)} rel="noreferrer"
        >
          {linkTarget}
        </a>
      );
    },
  } as ParserRule & ReactOutputRule;
}

const reportLinkAnchorClickToGTM = (link) => () => {
  window.open(link);
  pushToGTMDataLayer('linkClick');
};

/**
 * Build a `SimpleMarkdown` `singleBacktick` rule.
 * @returns A `SimpleMarkdown` rule which should be assigned to the `singleBacktick` key
 */
export function buildSingleBacktickRule() {
  return {
    order: SimpleMarkdown.defaultRules.em.order - 0.5,
    // First we check whether a string matches
    match: (matchSrc) => {
      return /^(?!``)`([\s\S]+?)`/.exec(matchSrc);
    },
    // Then parse this string into a syntax node
    parse: (capture, parse, state) => {
      return {
        content: parse(capture[1], state),
      };
    },
    react: (node, recurseOutput, state) => {
      return (
        <pre className="inline" key={state.key}>
          {recurseOutput(node.content, state)}
        </pre>
      );
    },
    html: (node, recurseOutput, state) => {
      return `<pre class="inline">${recurseOutput(node.content, state)}</pre>`;
    },
  } as ParserRule & ReactOutputRule & HtmlOutputRule;
}

/**
 * Build a `SimpleMarkdown` `underline` rule.
 * @returns A `SimpleMarkdown` rule which should be assigned to the `underline` key
 */
export function buildUnderlineRule() {
  return {
    order: SimpleMarkdown.defaultRules.em.order - 0.5,
    // First we check whether a string matches
    match: (matchSrc) => {
      return /^__([\s\S]+?)__(?!_)/.exec(matchSrc);
    },
    // Then parse this string into a syntax node
    parse: (capture, parse, state) => {
      return {
        content: parse(capture[1], state),
      };
    },
    react: (node, recurseOutput, state) => {
      return <u key={state.key}>{recurseOutput(node.content, state)}</u>;
    },
    html: (node, recurseOutput, state) => {
      return `<u>${recurseOutput(node.content, state)}</u>`;
    },
  } as ParserRule & ReactOutputRule & HtmlOutputRule;
}
