import * as React from 'react';
import cx from 'classnames';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import { UserAvatar } from '../shared/UserAvatar';
import type { ActivityItemPresenceDisplayProps } from './interfaces';
import groupChatIcon from 'Assets/images/group-chat.svg';
import removedUser from 'Assets/images/removed-user.svg';
import { Styled } from './index.styles';

/**
 * Named export is not an observer
 */
const ActivityItemPresenceDisplay: React.FC<
  ActivityItemPresenceDisplayProps
> = ({
  conversationGrouping,
  conversationId = '0',
  personId,
  personPbo,
  selectPersonPresenceStatus,
  selectUnreadCounts,
  setConversationAndTotalUnreadCount,
  showUnread = false,
  updateMyLastReadMessage,
  showOnlyDirectMentions,
  contactPbo,
  getExtrContactByPhoneNumber,
}) => {
  const unreadCounts = selectUnreadCounts(conversationId);

  const getDisplayIcon = () => {
    if (!showUnread) {
      return (
        <Styled.Avatar>
          <img src={groupChatIcon} alt="group-icon" />
        </Styled.Avatar>
      );
    }

    const unreadNumber = showOnlyDirectMentions
      ? unreadCounts.unreadMentions
      : unreadCounts.unreadMessages;

    if (unreadNumber === 0) {
      return (
        <Styled.Avatar>
          <img src={groupChatIcon} alt="group-icon" />
        </Styled.Avatar>
      );
    }

    const isOver99 = unreadNumber > 99;
    const displayNumber = isOver99 ? (
      <span>
        99<div className="superscript">+</div>
      </span>
    ) : (
      unreadNumber
    );

    return (
      <Styled.Avatar>
        <div className={cx('number-circle', { 'over-99': isOver99 })}>
          {displayNumber}
        </div>
        <img src={groupChatIcon} alt="group-icon" />
      </Styled.Avatar>
    );
  };

  const displayIcon = getDisplayIcon();

  if (personPbo) {
    return personPbo.case({
      pending: () => displayIcon,
      rejected: () =>
        conversationGrouping === 'Channel' && (
          <div className="removed-user">
            R U
            <img src={removedUser} className="presence-badge" />
          </div>
        ),
      fulfilled: (p) => {
        if (personId) {
          if (
            conversationGrouping !== 'Channel' &&
            conversationGrouping !== 'Group'
          ) {
            return (
              <UserAvatar
                name={p.data.DisplayName}
                showUnread={showUnread}
                conversationId={conversationId}
                presence={selectPersonPresenceStatus(personId)}
                selectUnreadCounts={selectUnreadCounts}
                setConversationAndTotalUnreadCount={
                  setConversationAndTotalUnreadCount
                }
                updateMyLastReadMessage={updateMyLastReadMessage}
              />
            );
          } else {
            return (
              <div className="list-item-icon-wrapper">
                {displayIcon}
                {unreadCounts.unreadMentions > 0 &&
                  unreadCounts.unreadMessages > 0 && (
                    <div className="presence-badge mention">@</div>
                  )}
              </div>
            );
          }
        } else {
          return (
            <UserAvatar
              name={p.data.DisplayName}
              selectUnreadCounts={selectUnreadCounts}
              presence={selectPersonPresenceStatus(0)}
              setConversationAndTotalUnreadCount={
                setConversationAndTotalUnreadCount
              }
              updateMyLastReadMessage={updateMyLastReadMessage}
            />
          );
        }
      },
    });
  } else {
    return (
      <div className="phone-user">
        {showUnread && unreadCounts.unreadMessages > 0 && (
          <div
            className={cx('number-circle', {
              'over-99': unreadCounts.unreadMessages > 99,
            })}
          >
            {unreadCounts.unreadMessages <= 99 ? (
              unreadCounts.unreadMessages
            ) : (
              <span>
                99<div className="superscript">+</div>
              </span>
            )}
          </div>
        )}
        {contactPbo ? (
          contactPbo.case({
            pending: () => displayIcon,
            rejected: (reason) => (
              <span>{get(reason, 'response.data.message')}</span>
            ),
            fulfilled: (contact) => {
              const extrContact = getExtrContactByPhoneNumber(
                contact.data.phoneNumber
              );
              const displayName = extrContact
                ? extrContact.DisplayName()
                : get(contact, 'data.DisplayName', '');
              return (
                <UserAvatar
                  image={extrContact?.pictureUrl}
                  name={extrContact && displayName}
                  selectUnreadCounts={selectUnreadCounts}
                  setConversationAndTotalUnreadCount={
                    setConversationAndTotalUnreadCount
                  }
                  updateMyLastReadMessage={updateMyLastReadMessage}
                />
              );
            },
          })
        ) : (
          <UserAvatar
            selectUnreadCounts={selectUnreadCounts}
            setConversationAndTotalUnreadCount={
              setConversationAndTotalUnreadCount
            }
            updateMyLastReadMessage={updateMyLastReadMessage}
          />
        )}
      </div>
    );
  }
};
export default observer(ActivityItemPresenceDisplay);
