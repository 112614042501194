import { observer } from 'mobx-react';
import * as React from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { CallHoldingListItemProps } from './interfaces';
const holdCallIcon = require('../../assets/images/btn-call-hold.svg');
export class CallHoldingListItem extends React.Component<
  CallHoldingListItemProps,
  {}
> {
  activeCall = () => {
    const { phoneStore, phoneCall } = this.props;
    phoneStore.setOtherSessionsOnHold(phoneCall.session);
    phoneStore.makeCallActive(phoneCall.session);
    phoneCall.unHold();
  };
  render() {
    const { phoneCall } = this.props;
    return (
      <li
        className="call-holding-list-item"
        onClick={this.activeCall}
        key={'1'}
      >
        <div className="status-timer-wrapper">
          <div className="status">{'HOLDING...'}</div>
          <div className="call-timer">{phoneCall.timerDisplay}</div>
        </div>
        <div className="text-btn-wrapper">
          <div className="text-wrapper">
            {phoneCall.person !== null ? (
              phoneCall.person.case({
                pending: () => <Loader indeterminate active />,
                rejected: (reason) => (
                  <Message
                    visible
                    error
                    content={
                      'Error Loading Person: ' + reason.response.data.message
                    }
                  />
                ),
                fulfilled: (response) => {
                  return (
                    <>
                      <div className="name">
                        {response.data.firstName} {response.data.lastName}
                      </div>
                      <div className="number">
                        {response.data.lines
                          ? response.data.formatNumber()
                          : ''}
                      </div>
                    </>
                  );
                },
              })
            ) : (
              <>
                <div className="name">
                  {phoneCall.isMerge
                    ? 'Multiple Callers'
                    : phoneCall.callerIdName}
                </div>
                <div className="number">
                  {phoneCall.FormattedNumber
                    ? phoneCall.FormattedNumber
                    : 'Unknown Number'}
                </div>
              </>
            )}
          </div>
          <div className="btn-wrapper">
            <button
              className={`${
                phoneCall.isCallOnHold ? 'active' : ''
              } hold-toggle-btn`}
            >
              <img src={holdCallIcon} />
            </button>
          </div>
        </div>
      </li>
    );
  }
}

export default observer(CallHoldingListItem);
