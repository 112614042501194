import { TIMEZONE_IDENTIFIER } from 'Constants/env';
import { IUnreadMessageAndMentionCountsByConversationId } from 'Interfaces/components';
import { isEmpty } from 'lodash';
import { action, computed, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import * as React from 'react';
import { Dropdown, DropdownProps, List, Loader } from 'semantic-ui-react';
import { ConversationModel } from '../../models';
import ActivityListItem from '../ActivityListItem';
import type { ActivityListFilterProps } from './interfaces';

const DateRange = [
  {
    text: 'Last 24 Hours',
    value: '1',
  },
  {
    text: 'Last 7 Days',
    value: '7',
  },
  {
    text: 'Last 14 Days',
    value: '14',
  },
  {
    text: 'Last 30 Days',
    value: '30',
  },
  {
    text: 'Last 60 Days',
    value: '60',
  },
  {
    text: 'Last 90 Days',
    value: '90',
  },
  {
    text: 'Last 180 Days',
    value: '180',
  },
  {
    text: 'Last Year',
    value: '365',
  },
];

@observer
export class ActivityListFilter extends React.Component<
  ActivityListFilterProps,
  {}
> {
  @observable
  currentTime: string;
  @action
  setCurrentTime = (currentTime: string) => (this.currentTime = currentTime);
  @observable
  subtractedTime: string;
  @action
  setSubtractedTime = (subtractedTime: string) =>
    (this.subtractedTime = subtractedTime);

  @observable
  histConversations: ConversationModel[] = [];
  @action
  setConversationIds = (historyConversations: ConversationModel[]) =>
    (this.histConversations = historyConversations);

  constructor(props: ActivityListFilterProps) {
    super(props);
    makeObservable(this);
  }

  // show 24 hour as default
  runHistory(withinLastDays: number) {
    const { conversation } = this.props;
    const momentInst = moment().tz(TIMEZONE_IDENTIFIER).utc();
    const currentTime = momentInst.format().toString();
    const subtractedTime = momentInst
      .subtract(withinLastDays, 'days')
      .format()
      .toString();
    this.setCurrentTime(currentTime);
    this.setSubtractedTime(subtractedTime);
    conversation
      .loadConversationHistoryBasedOnDateGet(currentTime, subtractedTime)
      .then((x) => this.setConversationIds(x as ConversationModel[]));
  }

  componentDidMount() {
    this.runHistory(7);
  }

  onChange = (e, d: DropdownProps) => {
    this.runHistory(parseInt(d.value as string));
  };

  @computed
  get UnreadCounts() {
    const { conversation, selectUnreadCounts } = this.props;
    const convList = Array.from(conversation.AllConversations);
    const allUnreadCounts =
      convList.reduce<IUnreadMessageAndMentionCountsByConversationId>(
        (prev, next) => {
          prev[next.id] = selectUnreadCounts(next.id);
          return prev;
        },
        {}
      );
    return allUnreadCounts;
  }

  render() {
    const {
      contactsByPhone,
      conversation,
      currentConversationId,
      listUnreadFirst,
      loggedInAccountId,
      loggedInPersonId,
      makeCall,
      participant,
      selectConversationParticipants,
      selectParticipantPersonInfo,
      selectPersonPresenceStatus,
      selectUnreadCounts,
      setConversationAndTotalUnreadCount,
      updateMyLastReadMessage,
      updateRoute,
      personStore,
    } = this.props;

    if (!conversation) {
      return <Loader active indeterminate size="mini" />;
    }

    let convItems: JSX.Element[];
    if (this.histConversations !== null && this.histConversations.length > 0) {
      const convList = this.histConversations;
      convItems = convList
        .slice()
        .sort((a, b) => {
          const aDate = a.lastMessageDate || a.created;
          const bDate = b.lastMessageDate || b.created;
          let sortResult = aDate <= bDate ? 1 : -1; // 1 is B before A, -1 is A before B
          if (listUnreadFirst) {
            const unreadCountsA = this.UnreadCounts[a.id];
            const unreadCountsB = this.UnreadCounts[b.id];
            const aHasUnreads =
              !isEmpty(unreadCountsA) && unreadCountsA.unreadMessages > 0;
            const bHasUnreads =
              !isEmpty(unreadCountsB) && unreadCountsB.unreadMessages > 0;
            if (aHasUnreads && !bHasUnreads) {
              sortResult = -1; // A before B
            } else if (!aHasUnreads && bHasUnreads) {
              sortResult = 1; // B before A
            } // ... Otherwise, leave the sort result alone
          }

          return sortResult;
        })
        .map((conv) => {
          return (
            <ActivityListItem
              contactsByPhone={contactsByPhone}
              conversation={conv}
              conversationId={conv.id}
              isCurrentConversation={currentConversationId === conv.id}
              loggedInAccountId={loggedInAccountId}
              loggedInPersonId={loggedInPersonId}
              makeCall={makeCall}
              key={conv.id}
              from={'history'}
              linkSuffix="history"
              loggedInUserActiveConferenceConversation={
                conversation.LoggedInUserActiveConferenceConversation
              }
              participant={participant}
              participantsPbo={selectConversationParticipants(conv.id)}
              postConferenceByConversationId={
                conversation.postConferenceByConversationId
              }
              selectParticipantPersonInfo={selectParticipantPersonInfo}
              selectPersonPresenceStatus={selectPersonPresenceStatus}
              updateRoute={updateRoute}
              selectUnreadCounts={selectUnreadCounts}
              setConversationAndTotalUnreadCount={
                setConversationAndTotalUnreadCount
              }
              updateMyLastReadMessage={updateMyLastReadMessage}
              personStore={personStore}
              conversationStore={conversation}
              setShowPersonDetails={personStore.setShowPersonDetails}
            />
          );
        });
    }

    return (
      <div id="activity-list-filter" className="flex-column flex-grow-shrink">
        {!conversation ? (
          <Loader id="activity-list-filter-loading" active indeterminate />
        ) : (
          <div
            id="activity-list-items-history-wrap"
            className="flex-column maxheightpercent"
          >
            <div id="history-range-wrap" className="flex-shrink">
              <Dropdown
                className="gray-bg-input"
                onChange={this.onChange}
                selectOnBlur={true}
                placeholder={DateRange[1].text}
                fluid
                selection
                options={DateRange}
              />
            </div>
            {convItems && (
              <div
                id="activity-list-items-history"
                className="flex-grow-shrink overflow-scroll-y activity-list-items-section"
              >
                <List inverted verticalAlign="middle" size="large" selection>
                  {convItems}
                </List>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default observer(ActivityListFilter);
