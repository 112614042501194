import axios from 'axios';
import type { AxiosInstance, AxiosError } from 'axios';
import { AUTH0 } from './constants/env';
import { RootStore } from 'Stores/index';
import { pushToGTMDataLayer } from './utils/analytics';
import { bugsnagClient } from './utils/logUtils';
const autoLogOut = require('./assets/images/communicator-notification-right-50x50-01.ico');

const API: AxiosInstance = axios.create();

export const AUTH0_API: AxiosInstance = axios.create({
  baseURL: `https://${AUTH0.domain}/api/v2/users/`,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

export const PURE_API: AxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

export const setAPIHeader = (key: string, value: string) => {
  API.defaults.headers.common[key] = value;
};
export const getAuthToken = () => {
  return API.defaults.headers.common.Authorization?.toString().split(' ')[1];
};
export const getBearerAuthToken = () => {
  return API.defaults.headers.common.Authorization;
};
export const removeAxiosAuthHeaders = () => {
  delete API.defaults.headers.common.Authorization;
};

const setLogoutIconAsFavicon = () => {
  const link: HTMLLinkElement =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = autoLogOut;
  document.getElementsByTagName('head')[0].appendChild(link);
};
/**
 * Configures `Axios` response interceptors:
 * - Redirect to / on 401 Unauthorized response.
 */
export const configureAxiosInterceptors = (rootStore: RootStore) => {
  // Redirect to / on 401 Unauthorized response.
  API.interceptors.response.use(null, (err: AxiosError) => {
    //unauthenticated
    if (err.response && err.response.status === 401) {
      setLogoutIconAsFavicon();
      pushToGTMDataLayer('logout', {
        logoutType: 'Authorization',
      });
      rootStore.transitionToLogout();
    } else if (err.response && err.response.status >= 400) {
      //client error
      bugsnagClient.notify(err, (event) => {
        event.severity = 'error';
        event.context = 'Axios response interceptor';
        event.addMetadata('custom', { function: 'configureAxiosInterceptors' });
      });
    } else {
      //500 or other error
      console.error(
        'Axios error caught by interceptor, but err.response was undefined, cannot check for 401 response status'
      );
    }
    return Promise.reject(err);
  });
  API.interceptors.request.use(
    async (config) => {
      const token = await rootStore.personStore.getRefreshedToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        await rootStore.personStore.loginWithExistingToken(token, false);
      }

      return config;
    },
    (error) => {
      console.error(
        'Axios error caught by interceptor, but err.response was undefined, cannot check for 401 response status'
      );
      return Promise.reject(error);
    }
  );
};

export default API;
