import styled from 'styled-components';

const Button = styled.span`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  line-height: 15px;
  color: #fff;
  padding-top: 2px;
  margin-left: 12px;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-bottom-color: #fff;
  }
`;

export const Styled = {
  Button,
};
