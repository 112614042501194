import {
  ConversationModel,
  IConversationConferenceModel,
} from 'Models/ConversationModel';
import { isNullOrUndefined } from 'util';

import videoConferenceDisabledIconDark from 'Assets/images/no-video-light.svg';
import videoConferenceDisabledIcon from 'Assets/images/no-video-white.svg';
import activeVideoConferenceDisabledIcon from 'Assets/images/video-active-disabled.svg';
import activeVideoConferenceIcon from 'Assets/images/video-message-active.svg';
import defaultVideoIconInfo from 'Assets/images/video-message-white.svg';
import defaultVideoIcon from 'Assets/icons/video.svg';

/**
 * Creates tooltip message based on certain video conference active states
 * For Group Video Conferences
 */
export const selectProperVideoTooltipMessageForGroups = (
  activeConference: boolean,
  userIsInActiveVideoConference: boolean
) => {
  let tooltipMessage: string = '';

  // no active conference and user is not in one (Start)
  if (!activeConference && !userIsInActiveVideoConference) {
    tooltipMessage = 'Start Group Video Conference';
  }
  // not the active conference but user is in another one (Disabled)
  else if (!activeConference && userIsInActiveVideoConference) {
    tooltipMessage = 'Video Conference Unavailable';
  }
  // active conference, but user is already a participant in another active conference
  else if (activeConference && userIsInActiveVideoConference) {
    tooltipMessage = 'To Join, Please End Other Video Conference';
  }
  // active conference but user is not in it yet (Join)
  else {
    tooltipMessage = 'Join Group Video Conference';
  }

  // returns string message to show in tooltip
  return tooltipMessage;
};

/**
 * Creates tooltip message based on certain video conference active states
 * For OneOnOne Video Conferences
 */
export const selectProperVideoTooltipMessageForOneOnOnes = (
  activeConference: boolean,
  userIsInActiveVideoConference: boolean,
  forceDisable?: boolean
) => {
  let tooltipMessage: string = '';
  // no active conference and user is not in one (Start)
  if (forceDisable) {
    tooltipMessage = 'Video Conference Unavailable';
  } else if (!activeConference && !userIsInActiveVideoConference) {
    tooltipMessage = 'Start Video Conference';
  }
  // not the active conference but user is in another one (Disabled)
  else if (!activeConference && userIsInActiveVideoConference) {
    tooltipMessage = 'Video Conference Unavailable';
  }
  // active conference, but user is already a participant in another active conference
  else if (activeConference && userIsInActiveVideoConference) {
    tooltipMessage = 'To Join, Please End Other Video Conference';
  }
  // active conference but user is not in it yet (Join)
  else {
    tooltipMessage = 'Join Video Conference';
  }

  // returns string message to show in tooltip
  return tooltipMessage;
};

/**
 * Selects video icon based on certain video conference active states
 */
export const selectProperVideoIcon = (
  activeConference: IConversationConferenceModel,
  loggedInUserActiveConferenceConversation: ConversationModel,
  forceDisable?: boolean,
  showActiveVideoConferenceIcon = true,
  from?: 'group-info' | 'new-group'
) => {
  const conferenceIsActive = !isNullOrUndefined(activeConference);
  const userIsInActiveVideoConference = !isNullOrUndefined(
    loggedInUserActiveConferenceConversation
  );
  if ((!conferenceIsActive && userIsInActiveVideoConference) || forceDisable) {
    return videoConferenceDisabledIconDark;
  } else if (
    conferenceIsActive &&
    showActiveVideoConferenceIcon &&
    loggedInUserActiveConferenceConversation === null &&
    activeConference.start
  ) {
    return activeVideoConferenceIcon;
  } else if (
    conferenceIsActive &&
    activeConference.start &&
    userIsInActiveVideoConference &&
    showActiveVideoConferenceIcon
  ) {
    return activeVideoConferenceDisabledIcon;
  } else if (
    (!conferenceIsActive && userIsInActiveVideoConference) ||
    (conferenceIsActive &&
      activeConference.start &&
      userIsInActiveVideoConference &&
      !showActiveVideoConferenceIcon)
  ) {
    return videoConferenceDisabledIcon;
  } else if (from === 'group-info') {
    return defaultVideoIconInfo;
  } else {
    return defaultVideoIcon;
  }
};
