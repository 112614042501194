import moment from 'moment-timezone';
import React from 'react';
import { ConversationStore, UiStore } from '../../../../../../stores';
import { UserAvatar } from '../../../../../shared/UserAvatar';
import { Styled } from './index.styles';
const closeBtn = require('../../../../../../assets/images/close-btn.svg');
export class ChatHistory extends React.Component<
  {
    conversation: ConversationStore;
    ui: UiStore;
    toogleChatHistory: () => void;
  },
  { showChatHistory: boolean }
> {
  render() {
    const {
      toogleChatHistory,
      conversation: { videoHistoryDetails },
      ui: {
        selectConversationUnreadCounts,
        setConversationAndTotalUnreadCount,
        selectPersonPresenceStatus,
        openedRightSidebarsOrder,
      },
    } = this.props;
    return (
      <Styled.VideoHistoryChat
        $zIndex={openedRightSidebarsOrder.get('video-chat-history')}
        className="history-video-chat"
      >
        <div className="chat-close">
          <span>Chat</span>
          <img src={closeBtn} className="pointer" onClick={toogleChatHistory} />
        </div>
        <div className="all-conv-container">
          {videoHistoryDetails?.data?.chatMessages?.length > 0 ? (
            videoHistoryDetails.data.chatMessages.map((message, index) => {
              const time = moment(message.createTime).format('LT');
              return (
                <div
                  className="chat-container"
                  key={message.messageText + message.createTime}
                >
                  <div className="flex-row">
                    <UserAvatar
                      name={message.displayName}
                      selectUnreadCounts={selectConversationUnreadCounts}
                      setConversationAndTotalUnreadCount={
                        setConversationAndTotalUnreadCount
                      }
                      presence={selectPersonPresenceStatus(message.personId)}
                    />
                    <div className="text-container">
                      <span data-private className="person-name">
                        {message.displayName}
                      </span>
                      <span data-private className="text-message">
                        {message.messageText}
                      </span>
                    </div>
                  </div>
                  <div className="date">{time}</div>
                </div>
              );
            })
          ) : (
            <span className="empty-hist-chat">This conversation is empty.</span>
          )}
        </div>
      </Styled.VideoHistoryChat>
    );
  }
}
