import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

// Workaround until we move on from semantic ui
const StyledButton = styled(Button)<{ $destructive: boolean }>`
  &&&&& {
    ${({ $destructive }) =>
      `color: ${$destructive ? '#f95050' : '#666666'} !important;`}
  }
`;

export const Styled = {
  Button: StyledButton,
};
