import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_UI,
} from 'Constants/stores';
import ChannelContainer from 'Containers/Channel';
import { ContextPanelProps } from 'Containers/ContextPanel/interfaces';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import withRouter from '../../hocs/WithRouter';
import AddNewContact from '../AddNewContact';
import AllContacts from '../AllContacts';
import ContactsAddressBook from '../ContactsAddressBook';
import { SourcesAddressBook } from '../SourcesAddressBook';
import { ContentArea } from '../shared/ContentArea';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ADDRESS_BOOK_SUBPATHS } from '../../constants/routing';
import { ErrorBanner } from 'Components/shared/ErrorBanner';
import { TextButton } from 'Components/shared/TextButton';

class AddressBookPanel extends React.Component<ContextPanelProps> {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const {
      allSources: sourceList,
      allContacts: contactList,
      loadingContacts,
      getAllContacts,
      getSourcesIfMissing,
    } = this.props.person;
    if (contactList.length === 0 && !loadingContacts) {
      getAllContacts();
    }
    if (sourceList.length === 0) {
      getSourcesIfMissing();
    }
  }

  componentDidUpdate() {
    this.props.person.newContactErrors.show &&
      document.getElementById('addressBook-container').scrollTo(0, 0);
  }

  handleCloseError = () =>
    this.props.person.setErrorNewContact({
      show: false,
      message: '',
      field: '',
    });

  render() {
    const testid = `${this.props.testid}-addressBookPanel`;
    //need showEditingLeavePopup, allContacts, allSources for rerendering
    const {
      ui,
      person,
      params: { addressbookTabId },
      person: { showEditingLeavePopup, allContacts, allSources, contactFilterValue }, // prettier-ignore
    } = this.props;
    return (
      <ContentArea>
        <ErrorBanner
          show={person.newContactErrors.show}
          action={
            <TextButton
              testid={`${testid}-errorBanner-buttonClose`}
              onClick={this.handleCloseError}
            >
              Close
            </TextButton>
          }
          content={person.newContactErrors.message}
        />
        <Grid.Column
          className="addressBook-container"
          id="addressBook-container"
        >
          <ChannelContainer
            openGroupModal={ui.groupModalOpen}
            {...this.props}
            {...this.props.browserHistory}
          />
          <Routes>
            {/** path = /addressBook/* */}
            <Route path="contact" element={<AddNewContact />} />
            <Route
              path="contacts"
              element={
                person.hasContacts ? <AllContacts /> : <ContactsAddressBook />
              }
            />
            <Route
              path="sources"
              element={
                <SourcesAddressBook {...this.props} titleFor="contacts" />
              }
            />
            <Route
              path="*"
              element={<Navigate to={ADDRESS_BOOK_SUBPATHS.CONTACTS} replace />}
            />
          </Routes>
        </Grid.Column>
      </ContentArea>
    );
  }
}
export default inject(
  STORE_PERSON,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION
)(withRouter(observer(AddressBookPanel)));
