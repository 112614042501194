import ActivityListItems from 'Components/ActivityListItems';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_PUSHER,
  STORE_ROUTER,
  STORE_UI,
} from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import withRouter from '../../hocs/WithRouter';
import { resolveConversationPath } from '../../utils/routeNav';
import { ActivityListActionButtons } from '../ActivityListActionButtons';
import { ActivityListProps } from './interfaces';

export class ActivityList extends React.Component<ActivityListProps, {}> {
  updateRoutes = (path) => {
    const { navigate, location } = this.props;
    const newPath = resolveConversationPath(location.pathname, path);
    navigate(newPath);
  };

  makeCall = (personId?: number, phone?: string) => {
    const { phoneCall } = this.props;
    phoneCall.callWithPerson(personId, phone);
  };

  componentDidMount() {
    this.props.ui.getMutedConv();
  }

  render() {
    const {
      conversation,
      participant,
      person,
      contact,
      ui,
      phoneCall,
      preference,
      pusher,
      router,
      params,
    } = this.props;
    const conversationId =
      params.conversationId || router.currentConversationId();
    return (
      <Grid.Row
        id="activity-list"
        columns={1}
        className="action-panel-child flex-column overflow-scroll-y"
        onClick={ui.setHeaderDropdown(false)}
      >
        <Grid.Column>
          <ActivityListItems
            setShowPersonDetails={person.setShowPersonDetails}
            contactsByPhone={contact.contactsByPhone}
            conversationId={conversationId} // Not used by child, but required by `ISetConversationAndTotalUnreadCounts` (RP 2019-07-31)
            conversationStore={conversation}
            currentConversationId={conversationId}
            isOnline={pusher.isOnline}
            listUnreadFirst={preference.preferences.listUnreadFirst}
            loadPresenceIfMissing={ui.loadPresenceIfMissing}
            loggedInAccountId={person.loggedInAccountId}
            loggedInPersonId={person.loggedInPersonId}
            makeCall={this.makeCall}
            participantStore={participant}
            phoneStore={phoneCall}
            selectConversationParticipants={
              participant.loadConversationParticipantsIfMissing
            }
            selectOtherConversationParticipants={
              participant.selectOtherParticipants
            }
            selectParticipantPersonInfo={person.loadPersonByIdGetIfMissingGet}
            selectPersonPresenceStatus={ui.selectPersonPresenceStatus}
            selectUnreadCounts={ui.selectConversationUnreadCounts}
            setConversationAndTotalUnreadCount={
              ui.setConversationAndTotalUnreadCount
            }
            updateMyLastReadMessage={participant.updateMyLastReadMessage}
            updateRoute={this.updateRoutes}
            personStore={person}
            setMoreActionOptions={ui.setMoreActionOptions}
            activeItem={ui.activeSidebarItem}
          />
        </Grid.Column>
        <ActivityListActionButtons />
      </Grid.Row>
    );
  }
}

export default inject(
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_ROUTER,
  STORE_PHONE_CALL,
  STORE_CONTACT,
  STORE_UI,
  STORE_PUSHER,
  STORE_PREFERENCE
)(withRouter(observer(ActivityList)));
