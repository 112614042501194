import { FAST_EMOJIS } from 'Constants/assets';
import * as React from 'react';
import type { FastEmojiListProps } from './interfaces';

export const FastEmojiList: React.FC<FastEmojiListProps> = ({
  activeEmoji,
  handleFastEmojiPick,
  testid,
}) => (
  <div className="message-status-helper flex-grow-shrink">
    {FAST_EMOJIS.map((item) => (
      <span
        key={item.emoji}
        className={activeEmoji === item.emoji ? 'active-icon-message' : ''}
        onClick={handleFastEmojiPick(item)}
        data-testid={`${testid}-fastEmoji${item.message}`}
      >
        {item.emoji}
      </span>
    ))}
  </div>
);
