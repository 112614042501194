import {
  STORE_CONVERSATION,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_UI,
} from 'Constants/stores';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { DirectoryListItem } from '../DirectoryListItem';
import type { DirectoryProps } from './types';
import { Styled } from './index.styles';
import { InfoMessageBanner } from '../../shared/InfoMessageBanner';
import { FilterBox } from '../../shared/FilterBox';
import { ContentArea } from '../../shared/ContentArea';

const DirectoryObserver: React.FC<DirectoryProps> = ({
  search: {
    directoryPageNumber,
    getDirectorySearch,
    isDirectoryLoading,
    searchValue,
    selectDirectorySearchById,
    setDirectoryPageNumber,
    setDirectorySearchValue,
    isTopBarDirectoryLoading,
  },
  ui,
  conversation,
  phoneCall,
  person,
}) => {
  const testid = 'directory';
  React.useEffect(() => {
    getDirectorySearch('DIRECTORY', '', 20, 1);
    setDirectoryPageNumber(1);
  }, [isTopBarDirectoryLoading]);

  const filter = debounce((q: string) => {
    setDirectorySearchValue(q);
    if (searchValue && directoryPageNumber > 1) {
      setDirectoryPageNumber(1);
    }
    getDirectorySearch('DIRECTORY', q, 20);
  }, 500);

  const handleLoadMoreContacts = () => {
    setDirectoryPageNumber(directoryPageNumber + 1);
    getDirectorySearch('DIRECTORY', searchValue, 20, directoryPageNumber, true);
  };

  const directory = selectDirectorySearchById('DIRECTORY');

  return (
    <ContentArea>
      <FilterBox
        placeholder="Search Directory (min 3 characters)"
        sendInput={filter}
        loading={isDirectoryLoading}
        testid={`${testid}-filterBoxDirectory`}
      />
      <Styled.InfiniteScroll
        dataLength={directoryPageNumber * 20} //This is important field to render the next data
        next={handleLoadMoreContacts}
        hasMore
        loader={<React.Fragment />}
        // below props only if you need pull down functionality
        refreshFunction={handleLoadMoreContacts}
        pullDownToRefresh
        pullDownToRefreshThreshold={2}
        scrollableTarget="directory-list"
      >
        <Styled.List id="directory-list">
          {directory?.data?.results?.map(({ source: { id } }) => (
            <DirectoryListItem
              key={id}
              phoneStore={phoneCall}
              searchItemId={Number(id)}
              uiStore={ui}
              {...{ conversation, person, testid }}
            />
          ))}
          <InfoMessageBanner
            testid={`${testid}-bannerInfoNoResultsFound`}
            show={directory?.query && !directory?.data?.hits}
            content={`No results found for "${directory?.query}"`}
          />
        </Styled.List>
      </Styled.InfiniteScroll>
    </ContentArea>
  );
};

export const Directory = inject(
  STORE_PERSON,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PHONE_CALL
)(observer(DirectoryObserver));
