import {
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_ROUTER,
  STORE_UI,
} from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { PersonStore, RouterStore, UiStore } from '../../stores';

/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ActionPanelIntent
 */
@observer
export class ActionPanelIntent extends React.Component<{
  router?: RouterStore;
  person?: PersonStore;
  ui?: UiStore;
}> {
  handleToogleRecHist = (activeState: 'recent' | 'history') => () => {
    this.props.ui?.setActivityItem(activeState);
  };
  render() {
    const { person, ui } = this.props;
    const personPbo = person.selectPersonById(person.loggedInPersonId);
    if (personPbo === undefined) {
      return <Loader indeterminate active size="mini" />;
    }
    return (
      <Grid id="action-panel-intent" className="flex-zero">
        <Grid.Row className="top-row">
          <Grid.Column width={6} className="text-color no-padding text-center">
            <Button
              className={`no-hpadding ${
                ui.activeSidebarItem === 'recent' ? 'active-text' : ''
              }`}
              compact
              basic
              onClick={this.handleToogleRecHist('recent')}
            >
              RECENT
            </Button>
          </Grid.Column>
          <Grid.Column width={10} className="text-color no-padding">
            <Button
              className={`no-hpadding ${
                ui.activeSidebarItem === 'history' ? 'active-text' : ''
              }`}
              compact
              basic
              onClick={this.handleToogleRecHist('history')}
            >
              HISTORY
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default inject(
  STORE_ROUTER,
  STORE_PERSON,
  STORE_NOTIFICATION,
  STORE_PREFERENCE,
  STORE_PHONE_CALL,
  STORE_UI
)(observer(ActionPanelIntent));
