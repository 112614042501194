import { observable, makeObservable } from 'mobx';
import { PersonConversationOrContactModel, SearchModel } from 'Models/index';

export interface IDirectorySearchModel {
  query: string;
  data?: SearchModel<PersonConversationOrContactModel>;
}

export class DirectorySearchModel implements IDirectorySearchModel {
  @observable
  public query: string;

  @observable
  public data?: SearchModel<PersonConversationOrContactModel>;

  constructor(
    query: string,
    data?: SearchModel<PersonConversationOrContactModel>
  ) {
    makeObservable(this);
    this.query = query;
    this.data = data;
  }
}

export default DirectorySearchModel;
