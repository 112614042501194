import ChannelEditObserver from 'Components/ChannelEdit';
import ChannelMembersObserver from 'Components/ChannelMembers'; // default export wrapped with `@observer`
import ChannelViewObserver from 'Components/ChannelView';
import CloseButton from 'Components/CloseButton';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_UI,
} from 'Constants/stores';
import { isEmpty } from 'lodash';
import { action, computed, observable, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ConversationModel } from 'Models/index';
import * as React from 'react';
import { Loader, Message, Modal, Segment } from 'semantic-ui-react';
import withRouter from '../../hocs/WithRouter';
import { pushToGTMDataLayer } from '../../utils/analytics';
import { resolveConversationPath } from '../../utils/routeNav';
import { ChannelProps } from './interfaces';

const closeBtn = require('../../assets/images/close-btn.svg');

@observer
export class Channel extends React.Component<ChannelProps, {}> {
  /** If `this.activeConversationId` is '0', this will contain the newly created `Conversation` */
  @observable
  newConversation: ConversationModel;
  @action
  setNewConversation = (newConversation: ConversationModel) =>
    (this.newConversation = newConversation);

  constructor(props: ChannelProps) {
    super(props);
    makeObservable(this);
  }

  @computed
  get ActiveConversation() {
    return this.activeConversationId === '0'
      ? this.newConversation
      : (this.props.conversation.conversationByIdMap.has(
          this.activeConversationId
        ) &&
          this.props.conversation.conversationByIdMap
            .get(this.activeConversationId)
            .case({
              fulfilled: (conv) => conv.data,
            })) ||
          undefined;
  }

  /** The currently active `Conversation`. If a new `Conversation` is being created, this will be '0', otherwise, the GUID of the `Conversation` */
  @observable
  activeConversationId: string = '-1';
  @action
  setActiveConversationId = (activeConversationId: string) =>
    (this.activeConversationId = activeConversationId);

  /** Whether this `Channel` container is in Edit mode (displaying `ChannelEdit`), or if false, View mode (displaying `ChannelView`)  */
  @observable
  editing: boolean = false;
  @action
  setEditing = (editing: boolean) => (this.editing = editing);

  validate = (): boolean => {
    // check topic against store to ensure no duplicates
    // validate topic regex
    // validate description length
    return false;
  };

  saveClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { conversation, participant, ui, navigate } = this.props;
    // add to store
    // add participants to Participant store
    if (this.activeConversationId === '0') {
      // TODO: Real `Person` Ids
      const createConvPromise = conversation.createConversationPost(
        this.ActiveConversation?.unsavedTopic,
        this.ActiveConversation?.description,
        this.ActiveConversation?.grouping,
        ...participant.NewConversationParticipantPartials
      );
      return createConvPromise
        .then((resp) => {
          this.setActiveConversationId(resp.data.id as string);
          participant.resetNewConversationPersons();
          navigate(`/chat/conversations/${resp.data.id}/menu`);
          ui.setSelectedTopBarUsers(null);
          return resp;
        })
        .catch((e) => {
          return this.ActiveConversation;
        });
    } else {
      // Update existing Conversation
      const s = this.ActiveConversation;
      const unsavedTopic = s.unsavedTopic !== s.topic ? s.unsavedTopic : '';
      return conversation
        .updateConversationPatch(
          this.ActiveConversation.id,
          unsavedTopic,
          this.ActiveConversation.description
        )
        .then((resp) => {
          if (resp) {
            // Report `Channel` edit here, because `updateConversationPatch` can be used for either `OneOnOne` or `Channel` without distinction
            pushToGTMDataLayer('channelEdit', {
              conversationId: this.activeConversationId,
            });
            ui.setGroupModal(false);
          }
          return resp;
        })
        .catch((ex) => {
          if (ex.response.status === 304) {
            ui.setGroupModal(false);
          }
          return this.ActiveConversation;
        });
    }
  };

  @action
  addChannelMember = (personId: number[]) => {
    const {
      participant,
      params: { channelConversationId, channelMode },
      conversation,
    } = this.props;
    if (
      channelMode === 'new' ||
      conversation.channelInfoDetails.channelMode === 'new'
    ) {
      personId.forEach((p) => participant.addPersonToNewConversation(p));
      return Promise.resolve(true);
    } else {
      return participant.createConversationParticipant(
        channelConversationId ||
          conversation.channelInfoDetails.channelConversationId,
        personId
      );
    }
  };

  componentWillMount() {
    const {
      conversation,
      participant,
      person,
      params: { channelConversationId, channelMode },
    } = this.props;
    if (
      channelMode === 'new' ||
      conversation.channelInfoDetails.channelMode === 'new' ||
      this.props.location.pathname.includes('channel/0/new')
    ) {
      this.setNewConversation(
        ConversationModel.NewChannel(person.loggedInPersonId)
      );
      this.setActiveConversationId('0');
      participant.resetNewConversationPersons();
    } else {
      if (this.props.location.pathname.includes('chat')) {
        conversation.loadConversationByIdIfMissingGet(
          channelConversationId ||
            conversation.channelInfoDetails.channelConversationId
        );
        this.setActiveConversationId(
          channelConversationId ||
            conversation.channelInfoDetails.channelConversationId
        );
        // Load the `Participant`s in this `Conversation` if it is not already loading (or if it hasn't been loaded yet)
        participant.loadConversationParticipantsIfMissing(
          channelConversationId ||
            conversation.channelInfoDetails.channelConversationId
        );
      }
    }

    this.setEditing(channelMode === 'new' || channelMode === 'edit');
  }

  componentDidUpdate(prevProps: ChannelProps) {
    const {
      params: { channelConversationId, channelMode },
    } = prevProps;
    const { conversation, participant, person } = this.props;
    if (channelConversationId !== this.props.params.channelConversationId) {
      if (
        channelMode === 'new' ||
        conversation.channelInfoDetails.channelMode === 'new' ||
        this.props.location.pathname.includes('channel/0/new')
      ) {
        this.setNewConversation(
          ConversationModel.NewChannel(person.loggedInPersonId)
        );
        this.setActiveConversationId('0');
        participant.resetNewConversationPersons();
      } else {
        conversation.loadConversationByIdIfMissingGet(
          channelConversationId ||
            conversation.channelInfoDetails.channelConversationId
        );
        this.setActiveConversationId(
          channelConversationId ||
            conversation.channelInfoDetails.channelConversationId
        );
        // Load the `Participant`s in this `Conversation` if it is not already loading (or if it hasn't been loaded yet)
        participant.loadConversationParticipantsIfMissing(
          channelConversationId ||
            conversation.channelInfoDetails.channelConversationId
        );
      }

      this.setEditing(channelMode === 'new' || channelMode === 'edit');
    }
  }
  makeCall = (personId?: number, phone?: string) => {
    const { conversation, phoneCall } = this.props;

    phoneCall.callWithPerson(personId, phone);
    resolveConversationPath(this.props.location.pathname, 'menu');

    if (personId) {
      conversation.loadOrCreateConversationWithPost(personId, undefined);
    } else {
      conversation.loadOrCreateConversationWithPost(undefined, phone);
    }
  };

  /**
   * Helper for descendants to resolve a pathname, conditionally including the `/conversations/{id}` if it was already present.
   * Use this so you don't have to pass `location` through the descendant tree
   */
  resolveConversationLinkPath = (path: string) => {
    return resolveConversationPath(this.props.location.pathname, path);
  };

  pushHistoryPath = (path: string) => {
    this.props.navigate(path);
  };

  handleCloseButton = (from: 'group-info' | 'new-group', path?) => {
    const { conversation, ui } = this.props;
    if (from === 'group-info') {
      conversation.setChannelInfoDetails('0', 'new');
      conversation.clearNameTaken();
      ui.removeFromOpenedRightSidebarsOrder('sidebar-info');
    } else {
      this.props.navigate(path);
    }
  };

  handleCloseGroupModal = () => {
    const {
      ui: { setGroupModal, setActiveConversationIdPinnMess },
      conversation,
    } = this.props;
    setGroupModal(false);
    setActiveConversationIdPinnMess('');
    conversation.clearNameTaken();
  };

  render() {
    const {
      conversation,
      notification,
      participant,
      from,
      person,
      params,
      router,
      search,
      ui,
      contact,
      openGroupModal,
      location,
    } = this.props;
    const channelMembersSharedProps = {
      addAxiosErrorNotification: notification.addAxiosErrorNotification,
      addNotification: notification.addNotification,
      addWebNotification: notification.addWebNotification,
      closeAllMessageCreatedWebNotifications:
        notification.closeAllMessageCreatedWebNotifications,
      closeWebNotification: notification.closeWebNotification,
      addChannelMember: this.addChannelMember,
      channelCandidates: participant.selectPersonsNotInConversation(
        params.channelConversationId ||
          conversation.channelInfoDetails.channelConversationId
      ),
      conversationId:
        params.channelConversationId ||
        conversation.channelInfoDetails.channelConversationId,
      getPerson: person.selectPersonById,
      makeCall: this.makeCall,
      pushHistoryPath: this.pushHistoryPath,
      removeChannelMembers: participant.removeParticipantsDelete,
      resolveConversationLinkPath: this.resolveConversationLinkPath,
      search: search,
      selectUnreadCounts: ui.selectConversationUnreadCounts,
      setConversationAndTotalUnreadCount: ui.setConversationAndTotalUnreadCount,
      updateMyLastReadMessage: participant.updateMyLastReadMessage,
    };

    if (
      this.activeConversationId !==
      conversation.channelInfoDetails.channelConversationId
    ) {
      this.setActiveConversationId(
        conversation.channelInfoDetails.channelConversationId
      );
    }
    const participantsByConversationId =
      participant.selectParticipantsByConversationId(
        params.channelConversationId ||
          conversation.channelInfoDetails.channelConversationId
      );
    return from === 'group-info' ? (
      <React.Fragment>
        <Segment
          id="channel-edit-view-wrap"
          className="no-vmargin no-hpadding flex-shrink"
          clearing
          basic
        >
          <CloseButton
            onClose={(from: 'group-info' | 'new-group', path: string) => {
              this.handleCloseButton(from, path);
            }}
            pathName={location.pathname}
            from={this.props.from || 'new-group'}
          />
          <ChannelViewObserver
            conversation={this.ActiveConversation}
            conversationId={this.activeConversationId}
            key="channel-view"
            loggedInAccountId={person.loggedInAccountId}
            loggedInUserActiveConferenceConversation={
              conversation.LoggedInUserActiveConferenceConversation
            }
            postConferenceByConversationId={
              conversation.postConferenceByConversationId
            }
            resolveConversationLinkPath={this.resolveConversationLinkPath}
            handleCloseButton={this.handleCloseButton}
            setGroupModal={ui.setGroupModal}
            setChannelInfoDetails={conversation.setChannelInfoDetails}
            setMoreActionOptions={ui.setMoreActionOptions}
            setOpeningGroupModalFrom={ui.setOpeningGroupModalFrom}
            loggedInPersonVideoFeature={person.personAvaliableFeatures.video}
          />
        </Segment>
        {params.channelMode !== 'new' &&
          conversation &&
          participantsByConversationId &&
          participantsByConversationId.case({
            pending: () => <Loader active indeterminate />,
            rejected: (reason) => (
              <Message
                visible
                error
                content={`Failed to load Conversation Participants: ${reason.response.data.message}`}
              />
            ),
            fulfilled: (resp) => (
              <ChannelMembersObserver
                key="channel-members-existing"
                {...channelMembersSharedProps}
                isNew={false}
                loggedInPersonId={person.loggedInPersonId}
                participants={resp.data.results}
                uiStore={ui}
                from={from}
                conversation={conversation}
                setShowPersonDetails={person.setShowPersonDetails}
                contact={contact}
                person={person}
                participantStore={participant}
                router={router}
                setDescription={this.ActiveConversation?.setDescription}
                saveClick={this.saveClick}
                conversationModel={this.ActiveConversation}
              />
            ),
          })}
      </React.Fragment>
    ) : (
      <Modal
        open={openGroupModal}
        onClose={this.handleCloseGroupModal}
        dimmer="blurring"
        className="group-modal"
        closeOnEscape={true}
        closeOnDimmerClick={true}
      >
        <Modal.Header>
          <div className="title">
            <span>
              {isEmpty(participantsByConversationId)
                ? 'Create Group'
                : 'Edit Group'}
            </span>
          </div>
          <img
            className="close-btn"
            src={closeBtn}
            onClick={this.handleCloseGroupModal}
          />
        </Modal.Header>
        <Modal.Content>
          <Segment
            id="channel-edit-view-wrap"
            className="no-vmargin no-hpadding flex-shrink"
            clearing
            basic
          >
            {conversation.channelInfoDetails.channelMode === 'new' ||
            params.channelMode === 'new' ||
            conversation.channelInfoDetails.channelMode === 'edit' ? (
              <ChannelEditObserver
                clearTopicNameTaken={conversation.clearNameTaken.bind(
                  conversation
                )}
                conversation={this.ActiveConversation}
                isNew={params.channelMode === 'new'}
                key="channel-edit"
                saveClick={this.saveClick}
                topicNameIsTaken={conversation.topicNameIsTaken.bind(
                  conversation
                )}
                topicNameTaken={conversation.topicNameTaken}
                conversationVm={conversation.conversationVm}
                setConversationVm={conversation.setConversationVm}
              />
            ) : (
              <ChannelViewObserver
                setMoreActionOptions={ui.setMoreActionOptions}
                conversation={this.ActiveConversation}
                conversationId={this.activeConversationId}
                key="channel-view"
                loggedInAccountId={person.loggedInAccountId}
                loggedInUserActiveConferenceConversation={
                  conversation.LoggedInUserActiveConferenceConversation
                }
                postConferenceByConversationId={
                  conversation.postConferenceByConversationId
                }
                resolveConversationLinkPath={this.resolveConversationLinkPath}
                handleCloseButton={this.handleCloseButton}
                setGroupModal={ui.setGroupModal}
                setChannelInfoDetails={conversation.setChannelInfoDetails}
                setOpeningGroupModalFrom={ui.setOpeningGroupModalFrom}
                loggedInPersonVideoFeature={
                  person.personAvaliableFeatures.video
                }
              />
            )}
          </Segment>
          {conversation.channelInfoDetails.channelMode === 'edit' &&
            conversation &&
            participantsByConversationId &&
            participantsByConversationId.case({
              pending: () => <Loader active indeterminate />,
              rejected: (reason) => (
                <Message
                  visible
                  error
                  content={`Failed to load Conversation Participants: ${reason.response.data.message}`}
                />
              ),
              fulfilled: (resp) => (
                <ChannelMembersObserver
                  conversation={conversation}
                  key="channel-members-existing"
                  {...channelMembersSharedProps}
                  isNew={false}
                  loggedInPersonId={person.loggedInPersonId}
                  participants={resp.data.results}
                  uiStore={ui}
                  from={from}
                  setShowPersonDetails={person.setShowPersonDetails}
                  contact={contact}
                  person={person}
                  participantStore={participant}
                  router={router}
                  setDescription={this.ActiveConversation?.setDescription}
                  saveClick={this.saveClick}
                  conversationModel={this.ActiveConversation}
                />
              ),
            })}
          {(params.channelMode === 'new' ||
            conversation.channelInfoDetails.channelMode === 'new') && (
            <ChannelMembersObserver
              conversation={conversation}
              key="channel-members-new"
              {...channelMembersSharedProps}
              isNew={true}
              loggedInPersonId={person.loggedInPersonId}
              participants={participant.NewConversationParticipantPartials}
              uiStore={ui}
              from={from}
              setShowPersonDetails={person.setShowPersonDetails}
              contact={contact}
              person={person}
              participantStore={participant}
              router={router}
              setDescription={this.ActiveConversation?.setDescription}
              saveClick={this.saveClick}
              conversationModel={this.ActiveConversation}
            />
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export default inject(
  STORE_PHONE_CALL,
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_SEARCH,
  STORE_ROUTER,
  STORE_UI,
  STORE_NOTIFICATION,
  STORE_CONTACT
)(withRouter(observer(Channel)));
