// Utilities for working with text selection ranges on inputs (ex. `ContentEditable`)

import ContentEditable from 'Components/ContentEditable';
import { MENTION_REGEXP } from 'Constants/validation';
import ReactDOM from 'react-dom';
import { isNullOrUndefined } from 'util';
import rangy, { isExistingElementOrTextNode } from './rangySelection';

export type NodeOrText = Node | Text;
/** `RangyRange` methods that can be invoked with a `NodeOrText` if the `target` node was missing (ex. in `trySetRangeStart`) */
export type RangeActionOnNode =
  | 'setStartBefore'
  | 'setStartAfter'
  | 'setEndBefore'
  | 'setEndAfter';

export const normalizeHtml = (str: string): string => {
  return str && str.replace(/&nbsp;|\u202F|\u00A0/g, ' ');
};

/**
 * Given a `Node`, iterate backwards through its children until the last `Node.TEXT_NODE` is found.

 * If the given `Node` is a `Node.TEXT_NODE`, short circuit and return it.
 */
export const findLastTextNode = (node: Node): Node | null => {
  if (node.nodeType === Node.TEXT_NODE) {
    return node;
  }
  const children = node.childNodes;
  for (let i = children.length - 1; i >= 0; i--) {
    const textNode = findLastTextNode(children[i]);
    if (textNode !== null) {
      return textNode;
    }
  }
  return null;
};
export const findTextNode = (
  node: Node,
  cursorPosition: number
): { target: Node; newPosition: number } => {
  const children = node.childNodes;
  for (let i = 0; i < children?.length; i++) {
    if (cursorPosition <= children[i].textContent?.length)
      return { target: children[i], newPosition: cursorPosition };
    cursorPosition -= children[i].textContent?.length;
  }
  return children[children.length - 1]?.nodeName !== 'BR'
    ? {
        target: children[children.length - 1],
        newPosition: children[children.length - 1]?.textContent.length,
      }
    : null;
};

export const findAllTextNodes = (node: Node): Node[] => {
  let children = [];
  node.childNodes.forEach((node) => node.nodeType === 3 && children.push(node));
  return children;
};

/**
 * Place the caret at the end of the element.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element
 */
export const placeCursorAtEndOfElement = (htmlEl: Element) => {
  placeCursorAtSpecificPosition(htmlEl, htmlEl.textContent.length);
};

export const placeCursorAtSpecificPosition = (
  htmlEl: Element,
  position: number
) => {
  const result = findTextNode(htmlEl, position);
  if (result && !isNullOrUndefined(result.target)) {
    if (result.target.nodeValue !== null) {
      const range = rangy.createRange();
      const sel = rangy.getSelection();
      range.setStart(result.target, result.newPosition);
      range.collapse(true);
      sel.setSingleRange(range);
      if (htmlEl instanceof HTMLElement) {
        htmlEl.focus();
      }
    } else if (!result.target?.nodeValue) {
      placeCursorAtSpecificPosition(htmlEl, position + 1);
    }
  }
};

/**
 * Attempt to expand/contract the `Range` if either or both `startTarget`/`endTarget` are not `null`.
 *
 * This can be used to expand the `Rangy.Range` (where applicable), to _after_ `startTarget` and/or _before_ `endTarget`,
 * rather than surrounding the `Node` itself.
 *
 * **NOTE**: If you want to modify the actual browser selection (via `Rangy.Selection`), you must call `selection.setSingleRange(range)` after calling a method that mutates a `Rangy.Range`
 *
 * @param node The `NodeOrText` at the focus or anchor point of `range`
 * @param startTarget The `NodeOrText` to attempt setting the start of `range` before/after
 * @param endTarget The `NodeOrText` to attempt setting the end of `range` before/after
 * @param range The `RangyRange` representing the current `RangySelection`
 * @param includeTarget Causes the `RangyRange` to expand to _before_ **and** _after_ `target`
 * @param rangeStartActionOnNodeIfTargetMissing When calling `trySetRangeStart`, if `target` fails `isExistingElementOrTextNode`,
 * invoke this method on `range` with `node`. Pass `null` to do nothing.
 * @param rangeEndActionOnNodeIfTargetMissing When calling `trySetRangeEnd`, if `target` fails `isExistingElementOrTextNode`,
 * invoke this method on `range` with `node`. Pass `null` to do nothing.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Node
 * @see https://github.com/timdown/rangy/wiki/Rangy-Range
 *
 * @returns A 2-tuple of booleans, the first representing whether the range start was expanded to/around `startTarget`,
 * the second representing the same for `endTarget`
 */
export function tryExpandRangeToTargetElements(
  node: NodeOrText,
  startTarget: NodeOrText,
  endTarget: NodeOrText,
  range: RangyRange,
  includeTarget = false,
  rangeStartActionOnNodeIfTargetMissing?: RangeActionOnNode,
  rangeEndActionOnNodeIfTargetMissing?: RangeActionOnNode
): [boolean, boolean] {
  return [
    trySetRangeStart(
      node,
      startTarget,
      range,
      includeTarget,
      rangeStartActionOnNodeIfTargetMissing
    ),
    trySetRangeEnd(
      node,
      endTarget,
      range,
      includeTarget,
      rangeEndActionOnNodeIfTargetMissing
    ),
  ];
}

/**
 * Given a `Node` and a `Rangy.Range`, attempt to expand the **start** of `Range` if `target` is not `null`.
 *
 * This can be used to expand/contract the `Rangy.Range` (where applicable), to  _after_ `target` (or _before_ via `includeTarget`),
 * rather than surrounding the `Node` itself.
 *
 * **NOTE**: If you want to modify the actual browser selection (via `Rangy.Selection`), you must call `selection.setSingleRange(range)`
 * after calling a method that mutates a `Rangy.Range`
 *
 * @param node The `NodeOrText` at the focus or anchor point of `range`
 * @param target The `NodeOrText` to attempt setting the end of `range` before/after
 * @param range The `RangyRange` representing the current `RangySelection`
 * @param includeTarget Causes the `RangyRange` to expand to _before_ `target`
 * @param rangeActionOnNodeIfTargetMissing If `target` fails `isExistingElementOrTextNode`, invoke this method on `range` with `node`. Omit or pass `null` to do nothing.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Node
 * @see https://github.com/timdown/rangy/wiki/Rangy-Range
 *
 * @returns A boolean representing whether the range was expanded to/around `target`
 * @example trySetRangeStart(anchorNode, anchorNode.nextSibling, range, false, 'setStartAfter');
 */
export function trySetRangeStart(
  node: NodeOrText,
  target: NodeOrText,
  range: RangyRange,
  includeTarget = false,
  rangeActionOnNodeIfTargetMissing?: RangeActionOnNode
) {
  if (isExistingElementOrTextNode(target)) {
    if (includeTarget) {
      if (target.nodeType === Node.TEXT_NODE) {
        range.setStart(target, target.textContent.startsWith(' ') ? 1 : 0); // 1 position after first character, to preserve the "buffer" space
      } else if (target.nodeType === Node.ELEMENT_NODE) {
        range.setStartBefore(target);
      }
    } else {
      if (target.nodeType === Node.TEXT_NODE) {
        range.setStart(target, target.textContent.length - 1);
      } else if (target.nodeType === Node.ELEMENT_NODE) {
        range.setStartBefore(target);
      }
    }
    return true;
  } else {
    if (!isNullOrUndefined(rangeActionOnNodeIfTargetMissing)) {
      range[rangeActionOnNodeIfTargetMissing](node);
    }
    return false;
  }
}

/**
 * Given a `Node` and a `Rangy.Range`, attempt to expand the **end** of `Range` if `target` is not `null`.
 *
 * This can be used to expand/contract the `Rangy.Range` (where applicable), to _before_ `target` (or _after_ via `includeTarget`)
 * rather than surrounding the `Node` itself.
 *
 * **NOTE**: If you want to modify the actual browser selection (via `Rangy.Selection`), you must call `selection.setSingleRange(range)`
 * after calling a method that mutates a `Rangy.Range`
 *
 * @param node The `NodeOrText` at the focus or anchor point of `range`
 * @param target The `NodeOrText` to attempt setting the end of `range` before/after
 * @param range The `RangyRange` representing the current `RangySelection`
 * @param includeTarget Causes the `RangyRange` to expand to _after_ `target`
 * @param rangeActionOnNodeIfTargetMissing If `target` fails `isExistingElementOrTextNode`, invoke this method on `range` with `node`. Omit or pass `null` to do nothing.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Node
 * @see https://github.com/timdown/rangy/wiki/Rangy-Range
 *
 * @returns A boolean representing whether the range was expanded to/around `target`
 * @example trySetRangeEnd(focus, focus.previousSibling, range, false, 'setEndBefore')
 */
export function trySetRangeEnd(
  node: NodeOrText,
  target: NodeOrText,
  range: RangyRange,
  includeTarget = false,
  rangeActionOnNodeIfTargetMissing?: RangeActionOnNode
) {
  if (isExistingElementOrTextNode(target)) {
    if (includeTarget) {
      if (target.nodeType === Node.TEXT_NODE) {
        range.setEnd(
          target,
          Math.max(
            1,
            target.textContent.length -
              (target.textContent.endsWith(' ') ? 2 : 1)
          )
        ); // Preserve the last character if it's "buffer" space
      } else if (target.nodeType === Node.ELEMENT_NODE) {
        range.setEndAfter(target);
      }
    } else {
      if (target.nodeType === Node.TEXT_NODE) {
        range.setEnd(target, target.textContent.length > 0 ? 1 : 0); // if possible, at least 1 position after start, to preserve the "buffer" space
      } else if (target.nodeType === Node.ELEMENT_NODE) {
        range.setEndBefore(target);
      }
    }
    return true;
  } else {
    if (!isNullOrUndefined(rangeActionOnNodeIfTargetMissing)) {
      range[rangeActionOnNodeIfTargetMissing](node);
    }
    return false;
  }
}

/**
 * Given a `Node` and a `Rangy.Range`, attempt to expand the `Range` if `nextSibling` is not `null`.
 *
 * If the previous sibling is `null`, set the end of the `Range` before `node`.
 *
 * **NOTE**: If you want to modify the actual browser selection (via `Rangy.Selection`), you must call `selection.setSingleRange(range)` after calling a method that mutates a `Rangy.Range`
 *
 * @param node The `Node`/`Text` at the focus point of the current selection's `Rangy.Range`
 * @param range {Rangy.Range} The `Rangy.Range` representing the current `Rangy.Selection`
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Node
 * @see https://github.com/timdown/rangy/wiki/Rangy-Range
 * @see https://github.com/timdown/rangy/wiki/Rangy-Range#collapsetopointnode-node-number-offset
 *
 * @returns A boolean representing whether the range was collapsed to before the next sibling
 */
export function collapseRangeToStartOfNextSibling(
  node: NodeOrText,
  range: RangyRange
): boolean {
  if (isExistingElementOrTextNode(node.nextSibling)) {
    if (node.nextSibling.nodeType === Node.TEXT_NODE) {
      range.collapseToPoint(
        node.nextSibling,
        Math.min(1, node.nextSibling.textContent.length)
      );
    } else if (node.nextSibling.nodeType === Node.ELEMENT_NODE) {
      range.collapseToPoint(node.nextSibling, 0);
    }
    return true;
  }
  return false;
}
/**
 * Given a `Node` and a `Rangy.Range`, attempt to collapse the `Range` to the end position if `previousSibling` is not `null`.
 *
 * If the previous sibling is `null`, set the end of the `Range` before `node`.
 *
 * **NOTE**: If you want to modify the actual browser selection (via `Rangy.Selection`), you must call `selection.setSingleRange(range)` after calling a method that mutates a `Rangy.Range`
 *
 * @param node The `Node`/`Text` at the focus point of the current selection's `Rangy.Range`
 * @param range {Rangy.Range} The `Rangy.Range` representing the current `Rangy.Selection`
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Node
 * @see https://github.com/timdown/rangy/wiki/Rangy-Range
 * @see https://github.com/timdown/rangy/wiki/Rangy-Range#collapsetopointnode-node-number-offset
 *
 * @returns A boolean representing whether the range was collapsed to after the previous sibling
 */
export function collapseRangeToEndOfPreviousSibling(
  node: NodeOrText,
  range: RangyRange
): boolean {
  if (isExistingElementOrTextNode(node.previousSibling)) {
    if (node.previousSibling.nodeType === Node.TEXT_NODE) {
      range.collapseToPoint(
        node.previousSibling,
        node.previousSibling.textContent.length
      );
    } else if (node.previousSibling.nodeType === Node.ELEMENT_NODE) {
      range.collapseToPoint(
        node.previousSibling,
        (node.previousSibling as Node).childNodes.length
      );
    }
    return true;
  }
  return false;
}

export function normalizeNonCollapsedRangeAroundMentions(
  selection: RangySelection,
  range: RangyRange,
  isFocusInsideMention: boolean,
  isAnchorInsideMention: boolean
) {
  const { focusNode, anchorNode } = selection;
  if (!selection.isBackwards()) {
    // Forward selection (left-to-right, focus is *after* anchor)
    if (isFocusInsideMention) {
      trySetRangeEnd(
        focusNode,
        focusNode.parentNode.previousSibling,
        range,
        false,
        'setEndBefore'
      );
      selection.setSingleRange(range);
    }
    if (isAnchorInsideMention) {
      trySetRangeStart(
        anchorNode,
        anchorNode.parentNode.nextSibling,
        range,
        false,
        'setStartAfter'
      );
      selection.setSingleRange(range);
    }
  } else {
    // Backwards Selection (right-to-left focusNode is *before* anchor)
    if (isFocusInsideMention) {
      trySetRangeStart(
        focusNode,
        focusNode.parentNode.nextSibling,
        range,
        false,
        'setStartAfter'
      );
      selection.setSingleRange(range);
    }
    if (isAnchorInsideMention) {
      trySetRangeEnd(
        anchorNode,
        anchorNode.parentNode.previousSibling,
        range,
        false,
        'setEndBefore'
      );
      selection.setSingleRange(range);
    }
  }
}

/**
 * Determine if the focus point of the cursor is directly adjacent to (before or after) a Mention
 * @param selection The current `Rangy.Selection`
 * @param range The current `Rangy.Range`
 * @param startContainer The left-most `Element` in the Selection (in LTR languages, vice-versa for RTL)
 * @param endContainer The right-most `Element` in the Selection (in LTR languages, vice-versa for RTL)
 */
export function isCursorAdjacentToMention(
  selection: RangySelection,
  range: RangyRange,
  startContainer: Element,
  endContainer: Element
): Pick<
  ISelectionRange,
  | 'isFocusBeforeMention'
  | 'isFocusAfterMention'
  | 'isAnchorBeforeMention'
  | 'isAnchorAfterMention'
> {
  /**
   * IMPORTANT: If `focusNode` or `anchorNode` are inside a mention, they will NOT have siblings (because they are inside the child Text Node of the `<div>`)
   */
  // Determine whether the cursor *focus* is at the LAST position of a preceding text Node sibling.
  const focusNextSibling = selection.focusNode.nextSibling as Element;
  // If backwards, the focus is *before* the anchor, so reference the "start" (left-most) text container
  const isFocusBeforeMention = isBeforeMention(
    focusNextSibling,
    selection.isBackwards() ? startContainer : endContainer,
    range
  );

  // Determine whether the cursor *focus* is at the 0 or 1 position of a following text Node sibling.
  const focusPrevSibling = selection.focusNode.previousSibling as Element;
  const isFocusAfterMention = isAfterMention(
    focusPrevSibling,
    selection.isBackwards() ? startContainer : endContainer,
    range
  );

  // Determine whether the cursor *anchor* is at the LAST position of a preceding text Node sibling.
  const anchorNextSibling = selection.anchorNode.nextSibling as Element;
  // If backwards, the anchor is *after* the focus, so reference the "end" (right-most) text container
  const isAnchorBeforeMention = isBeforeMention(
    anchorNextSibling,
    selection.isBackwards() ? endContainer : startContainer,
    range
  );

  // Determine whether the cursor *anchor* is at the 0 or 1 position of a following text Node sibling.
  const anchorPrevSibling = selection.anchorNode.previousSibling as Element;
  const isAnchorAfterMention = isAfterMention(
    anchorPrevSibling,
    selection.isBackwards() ? endContainer : startContainer,
    range
  );

  return {
    isFocusBeforeMention,
    isFocusAfterMention,
    isAnchorBeforeMention,
    isAnchorAfterMention,
  };
}

function isBeforeMention(
  nextSibling: Element,
  textContainer: Element,
  range: RangyRange
) {
  return (
    nextSibling !== null &&
    nextSibling.nodeType === Node.ELEMENT_NODE &&
    nextSibling.classList.contains('input-mention') &&
    textContainer.nodeType === Node.TEXT_NODE && // endContainer will always be the right-most (in LTR languages, vice-versa for RTL)
    range.endOffset >= textContainer.textContent.length - 2
  );
}

function isAfterMention(
  prevSibling: Element,
  textContainer: Element,
  range: RangyRange
) {
  return (
    prevSibling !== null &&
    prevSibling.nodeType === Node.ELEMENT_NODE &&
    prevSibling.classList.contains('input-mention') &&
    textContainer.nodeType === Node.TEXT_NODE && // startContainer will always be the left-most (in LTR languages, vice-versa for RTL)
    range.startOffset <= 1
  );
}

/**
 * Get info about both the plain-text **and** HTML start and end offsets of the current selection,
 * as well as whether the selection's focus is within a Mention, and if it is collapsed (start & end are the same)
 */
export const getSelectionRange = (element: Element): ISelectionRange => {
  let start = 0;
  let htmlStart = 0;
  let end = 0;
  let htmlEnd = 0;
  let range: RangyRange = null;
  let selection: RangySelection = null;
  let preCaretRange = null;
  let isFocusInsideMention = false;
  let isAnchorInsideMention = false;
  let isFocusBeforeMention = false;
  let isAnchorBeforeMention = false;
  let isFocusAfterMention = false;
  let isAnchorAfterMention = false;
  if (typeof window.getSelection !== 'undefined') {
    selection = rangy.getSelection();
    if (selection.rangeCount > 0) {
      range = selection.getRangeAt(0);
      const startContainer = range.startContainer as Element;
      const endContainer = range.endContainer as Element;

      const focusParentNode = selection.focusNode.parentNode as Element;
      isFocusInsideMention =
        focusParentNode.classList &&
        focusParentNode.classList.contains('input-mention');

      const anchorParentNode = selection.anchorNode.parentNode as Element;
      isAnchorInsideMention =
        anchorParentNode.classList &&
        anchorParentNode.classList.contains('input-mention');
      // If the cursor is inside a mention, force select the entire mention node
      // Note: Was previously happening only on collapsed
      // TODO: Not needed? Or maybe refactor (RP 2018-11-20)
      if (isFocusInsideMention) {
        collapseRangeToStartOfNextSibling(focusParentNode, range);
        selection.setSingleRange(range);
      }

      ({
        isFocusBeforeMention,
        isFocusAfterMention,
        isAnchorBeforeMention,
        isAnchorAfterMention,
      } = isCursorAdjacentToMention(
        selection,
        range,
        startContainer,
        endContainer
      ));
      preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      // Calculate start
      preCaretRange.setEnd(startContainer, range.startOffset);
      start = preCaretRange.toString().length;
      // Note that the HTML position MAY be different than the raw position (ex. if a Mention has been added).
      // Track HTML start and end separately
      htmlStart = preCaretRange.toHtml().length;
      // Calculate end
      preCaretRange.setEnd(endContainer, range.endOffset);
      end = preCaretRange.toString().length;
      htmlEnd = preCaretRange.toHtml().length;
    }
  }
  return {
    start,
    end,
    htmlStart,
    htmlEnd,
    isFocusInsideMention,
    isAnchorInsideMention,
    isFocusBeforeMention,
    isFocusAfterMention,
    isAnchorBeforeMention,
    isAnchorAfterMention,
    selection,
    range,
  };
};

/**
 * Find the index where a Mention should be inserted, as well as whether a preceding space should be added.
 * Looks for an `@` optionally followed by word characters at the end of the input, otherwise falls back to current location.
 *
 * @export
 * @param messageDraft Text OR HTML formatted input contents
 * @param startIndex Text OR HTML start position of the current document selection
 * @example const { insertIndex, prependWith } = findMentionInsertIndex(messageDraftHtml, htmlStart);
 * @returns The index where a Mention should be inserted, as well as a possible preceding space, if one was not already present directly before `insertIndex`
 */
export function findMentionInsertData(
  messageDraft: string,
  startIndex: number
): IMentionInsertData {
  let insertIndex = startIndex;
  const draftStart = messageDraft.substring(0, startIndex);
  const atMatches = MENTION_REGEXP.exec(draftStart);
  if (atMatches && atMatches.length > 0) {
    insertIndex = draftStart.lastIndexOf(atMatches[atMatches.length - 1]);
  }

  const prependWith =
    insertIndex === 0 || draftStart.substring(0, insertIndex).endsWith(' ')
      ? ''
      : ' ';
  return { insertIndex, prependWith };
}

export function handlePasteAsPlainText(e: React.ClipboardEvent) {
  e.preventDefault();
  let content;
  if (e.clipboardData) {
    content = e.clipboardData.getData('text');
    document.execCommand('insertText', false, content);
  }
}

export function handleDropAsPlainText(e: React.DragEvent) {
  e.preventDefault();
  let content;
  if (e.dataTransfer) {
    content = e.dataTransfer.getData('text');
    document.execCommand('insertText', false, content);
  }
}

/** Attempts to focus the DOM node of the `ContentEditable` */
export function focusContentEditable(contentEditableRef: ContentEditable) {
  if (contentEditableRef) {
    const ceDomNode = ReactDOM.findDOMNode(contentEditableRef) as HTMLElement;
    ceDomNode.focus();
  }
}

export function insertEmoji(e: any, contentEditableRef: ContentEditable) {
  const sym = e.unified.split('-');
  const codesArray = [];

  sym.forEach((el) => codesArray.push('0x' + el));
  const emojiPic = String.fromCodePoint(...codesArray);

  const ceDomNode = ReactDOM.findDOMNode(contentEditableRef);
  // If the ContentEditable is not focused, focus it
  if (document.activeElement !== ceDomNode) {
    focusContentEditable(contentEditableRef);
    const { range, selection } = contentEditableRef.selectionRange;
    const startContainer = range.startContainer as Element;
    const endContainer = range.endContainer as Element;
    range.setStartAndEnd(
      startContainer,
      range.startOffset,
      endContainer,
      range.endOffset
    );
    selection.setSingleRange(range);
  }

  document.execCommand('insertText', false, emojiPic);
}

export interface ISelectionRange {
  start: number;
  end: number;
  htmlStart: number;
  htmlEnd: number;
  /** Whether the **focus** (end) point of the selected Range is inside a Mention `div` */
  isFocusInsideMention: boolean;
  /** Whether the **anchor** (start) point of the selected Range is inside a Mention `div` */
  isAnchorInsideMention: boolean;

  /**
   * Whether the **focus** (end) point of the cursor is at the LAST position of a preceding text Node sibling.
   *
   * Can be used to determine if the focus point of the cursor is directly **before** a Mention,
   * in which case, pressing DELETE will delete the Mention (if the range is also `collapsed`)
   * @see `ContentEditable#onKeyDown`
   */
  isFocusBeforeMention: boolean;
  /**
   * Whether the **anchor** (start) point of the cursor is at the LAST position of a preceding text Node sibling.
   *
   * Can be used to determine if the anchor point of the cursor is directly **before** a Mention,
   * in which case, pressing DELETE will delete the Mention (if the range is also `collapsed`)
   * @see `ContentEditable#onKeyDown`
   */
  isAnchorBeforeMention: boolean;

  /**
   * Whether the **focus** (end) point of the cursor is at the 0 position of a following text Node sibling.
   *
   * Can be used to determine if the focus point of the cursor is directly **after** a Mention,
   * in which case, pressing BACKSPACE will delete the Mention (if the range is also `collapsed`)
   * @see `ContentEditable#onKeyDown`
   */
  isFocusAfterMention: boolean;
  /**
   * Whether the **anchor** (start) point of the cursor is at the 0 position of a following text Node sibling.
   *
   * Can be used to determine if the anchor point of the cursor is directly **after** a Mention,
   * in which case, pressing BACKSPACE will delete the Mention (if the range is also `collapsed`)
   * @see `ContentEditable#onKeyDown`
   */
  isAnchorAfterMention: boolean;

  /** The `Rangy.Selection` currently selected (or `null` if no selection)
   * @see https://github.com/timdown/rangy/wiki/Rangy-Selection
   */
  selection?: RangySelection;
  /**
   * The `Rangy.Range` currently selected (or `null` if no selection)
   * @see https://github.com/timdown/rangy/wiki/Rangy-Range
   */
  range?: RangyRange;
}

/** Indicates where a Mention should be inserted, as well as whether a preceding space should be added */
export interface IMentionInsertData {
  /** Index within the string to insert the Mention */
  insertIndex: number;
  /** A single space character (` `) if the located `insertIndex` is not directly after a space, otherwise an empty string */
  prependWith: string;
}
