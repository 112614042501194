import React from 'react';
import { Input as SemanticInput } from 'semantic-ui-react';
import type { InputProps } from './types';

export const Input: React.FC<InputProps> = ({
  testid,
  ...props
}) => (
  <SemanticInput {...props} data-testid={testid} />
);
