import groupChatIcon from 'Assets/images/group-chat-gray.svg';

import videoIcon from 'Assets/images/video-message-active.svg';
import videoIconEnd from 'Assets/images/video-summary-ended.svg';
import videoIconJoin from 'Assets/images/video-summary-user-joined.svg';
import videoIconLeft from 'Assets/images/video-summary-user-left.svg';

import cx from 'classnames';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import { get } from 'lodash';
import { inject, observer } from 'mobx-react';
import { IPromiseBasedObservable } from 'mobx-utils';
import * as React from 'react';
import 'react-html5video/dist/styles.css';
import { isNullOrUndefined } from 'util';
import { ContactModel, ConversationModel, PersonModel } from '../../models';
import MessageModel from '../../models/MessageModel';

export interface IContextContentItemSystemEventProps {
  messageModel?: MessageModel;
  getPerson(
    personId: number
  ): IPromiseBasedObservable<AxiosResponseT<PersonModel>>;
  getContact(
    phoneNumber: string
  ): IPromiseBasedObservable<AxiosResponseT<ContactModel>>;
  personId?: number;
  conversation: ConversationModel;
}

export class ContextContentItemSystemEvent extends React.Component<
  IContextContentItemSystemEventProps,
  {}
> {
  getGrpParticipant = (participantId: number) => {
    return this.props.getPerson(participantId);
  };

  getParticipantFromPhoneOrPersonId(
    participantId: number,
    phoneNumber: string
  ) {
    if (participantId && participantId > 0) {
      return this.getGrpParticipant(participantId);
    }
    return this.props.getContact(phoneNumber);
  }

  printGroupParticipant = (seAllParticipants) => {
    return seAllParticipants?.map((participant, index) => {
      const person = this.getParticipantFromPhoneOrPersonId(
        participant.personId,
        participant.phone
      );
      return person?.case({
        fulfilled: (response) => {
          return (
            <span
              className="group-chat-participants"
              key={response.data.id + response.data.created?.toString()}
            >
              <strong>
                @{response.data.DisplayName || response.data.phoneNumber}
              </strong>
              {index < seAllParticipants.length - 1 && ', '}
            </span>
          );
        },
      });
    });
  };

  printCreatorOfGrp = (creatorId: number) => {
    const creator = this.getGrpParticipant(creatorId);
    return creator?.case({
      fulfilled: (resp) => {
        return <strong>@{resp.data.DisplayName}</strong>;
      },
    });
  };

  render() {
    const { messageModel, getPerson, conversation } = this.props;

    // Timestamps
    const eventDateTime = messageModel.CreatedMoment.format(
      'MM/DD/YYYY [at] hh:mm A'
    );
    const seCreatorOfGroupId = get(
      this.props.messageModel,
      'systemEvent.reference'
    );
    // Not every system message will show a person's name
    let needPerson = false;
    const sePayload = get(messageModel, 'systemEvent.payload');
    const sePersonId = get(messageModel, 'systemEvent.payload.personId');
    const seEventType = get(messageModel, 'systemEvent.eventType');
    const getPersonId =
      !isNullOrUndefined(sePersonId) &&
      !isNullOrUndefined(getPerson(sePersonId))
        ? getPerson(sePersonId)
        : null;
    // Not every system message will have a white box with drop shadow around it. This is to control the CSS class
    let showWhiteBox = false;
    let icon = videoIcon;
    let text;
    const conferenceDuration =
      !isNullOrUndefined(sePayload) &&
      !isNullOrUndefined(sePayload.conferenceDuration)
        ? sePayload.conferenceDuration
        : '00:00:00.00';
    switch (seEventType) {
      case 'Conference.Participant.Joined':
        text = 'has joined the conference on ' + eventDateTime;
        showWhiteBox = true;
        needPerson = true;
        icon = videoIconJoin;
        break;
      case 'Conference.Participant.Left':
        text = 'has left the conference on ' + eventDateTime;
        showWhiteBox = true;
        needPerson = true;
        icon = videoIconLeft;
        break;
      case 'Conference.Ended':
        icon = videoIconEnd;
        showWhiteBox = true;
        needPerson = false;
        text =
          'Meeting Summary:' +
          ' Date ' +
          messageModel.CreatedMoment.format('MM/DD/YYYY') +
          ' number of participants: ' +
          (!isNullOrUndefined(sePayload) ? sePayload.numberOfParticipants : 0) +
          ' Meeting Length: ' +
          conferenceDuration.substring(0, conferenceDuration.indexOf('.')) +
          ' Recorded: ' +
          (sePayload.conferenceRecorded ? 'Yes' : 'No');
        break;
      case 'Conversation.Created':
        const seAllParticipants = get(
          this.props.messageModel,
          'systemEvent.payload.participants'
        );
        icon = groupChatIcon;
        showWhiteBox = true;
        needPerson = true;
        text = (
          <span className="group-chat-created">
            {!isNullOrUndefined(seCreatorOfGroupId) && (
              <span>
                Group chat created by:{' '}
                {this.printCreatorOfGrp(seCreatorOfGroupId)} - {eventDateTime}{' '}
              </span>
            )}
            {!isNullOrUndefined(seAllParticipants) && (
              <div className="group-participants">
                Participants: {this.printGroupParticipant(seAllParticipants)}
              </div>
            )}
          </span>
        );
        break;
      case 'Conversation.Participants.Added':
        const seAllAddedPersons = get(
          this.props.messageModel,
          'systemEvent.payload.participantsAdded'
        );
        const gramarlyCorrectAdded =
          seAllAddedPersons?.length > 1 ? 'were' : 'was';
        icon = groupChatIcon;
        showWhiteBox = true;
        needPerson = true;
        text = (
          <span className="group-chat-created">
            {!isNullOrUndefined(seCreatorOfGroupId) && (
              <span>
                {this.printGroupParticipant(seAllAddedPersons)}{' '}
                {gramarlyCorrectAdded}
                <span className="person-added">
                  {' '}
                  added to the <b>{conversation?.topic} </b>
                </span>
                {this.props.messageModel?.systemEvent?.reference !== '0' && (
                  <span>
                    by : {this.printCreatorOfGrp(seCreatorOfGroupId)} -{' '}
                    {eventDateTime}
                  </span>
                )}
              </span>
            )}
          </span>
        );
        break;
      case 'Conversation.Participants.Removed':
        const seAllRemovedPersons = get(
          this.props.messageModel,
          'systemEvent.payload.participantsRemoved'
        );
        icon = groupChatIcon;
        showWhiteBox = true;
        needPerson = true;
        text = (
          <span className="group-chat-created">
            {!isNullOrUndefined(seCreatorOfGroupId) && (
              <span>
                {this.printGroupParticipant(seAllRemovedPersons)}
                <span className="person-removed">
                  {' '}
                  left the <b>{conversation?.topic}</b>{' '}
                </span>{' '}
                - {eventDateTime}
              </span>
            )}
          </span>
        );
        break;
      default:
        icon = null;
        showWhiteBox = false;
        needPerson = false;
        text = 'Unknown system event.';
    }

    return (
      <div className={cx('system-event', { 'call-summary': showWhiteBox })}>
        {
          // If the icon is not null, show it
          icon && <img className="icon-disposition" src={icon} />
        }
        <span className="content">
          {
            // If you need to display a person's name in the body text:
            needPerson &&
              getPersonId &&
              !isNullOrUndefined(sePayload) &&
              !isNullOrUndefined(sePersonId) &&
              sePersonId !== 0 &&
              // There is a personId and this system message needs a person. Do the promise:
              getPersonId?.case({
                pending: () => {
                  return <strong>Loading...</strong>;
                },
                rejected: () => {
                  return <span>{'An unknown participant '}</span>;
                },
                fulfilled: (resp) => {
                  return <span>{resp.data.DisplayName + ' '}</span>;
                },
              })
          }
          {needPerson && !isNullOrUndefined(sePersonId) && !getPersonId && (
            // There is no personId but we need a person, so this is an outside participant
            <span>{'An outside participant '}</span>
          )}
          {
            // Display the text generated in the above switch statement
            text
          }
        </span>
      </div>
    );
  }
}
export default observer(ContextContentItemSystemEvent);
