import { max } from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import {
  STORE_PERSON,
  STORE_UI,
  STORE_PREFERENCE,
  STORE_CONVERSATION,
  STORE_PHONE_CALL,
} from '../../../constants/stores';
import { Styled } from './index.styles';
import { SidebarMarginProps } from './interfaces';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

const SidebarMargin = (props: SidebarMarginProps) => {
  const {
    ui: {
      activePinnedConversationId,
      openTopbarDialpad,
      openedRightSidebarsOrder,
      isVideoChatHistoryOpened,
    },
    person: { showPersonDetails },
    preference: { preferences },
    conversation: { channelInfoDetails, listOfIncomingVideoCalls },
    phoneCall: { AnyPhoneConnectionActive, isTenSeconds },
  } = props;

  const [marginWidth, setMarginWidth] = React.useState(0);
  const location = useLocation();
  const isActiveCall = AnyPhoneConnectionActive || isTenSeconds || listOfIncomingVideoCalls.size > 0; //prettier-ignore

  React.useEffect(() => {
    setMarginWidth(marginWidthInPx());
  }, [
    activePinnedConversationId,
    openTopbarDialpad,
    showPersonDetails.id,
    channelInfoDetails?.channelMode,
    isVideoChatHistoryOpened,
    preferences.floatingSoftphone,
    isActiveCall,
    location,
  ]);

  const initialMarginWidths = () => {
    const pinnedSidebarWidth = activePinnedConversationId ? 360 : 0;
    const dialpadWidth =
      openTopbarDialpad && preferences.floatingSoftphone ? 264 : 0;
    const infoSidebarWidth =
      showPersonDetails.id ||
      !['new', 'edit'].includes(channelInfoDetails?.channelMode)
        ? 260
        : 0;
    const videoChatHistoryWidth = isVideoChatHistoryOpened ? 400 : 0;
    return [
      pinnedSidebarWidth,
      dialpadWidth,
      infoSidebarWidth,
      videoChatHistoryWidth,
    ];
  };

  const marginWidthInPx = () => {
    const pathname = location.pathname;
    let [
      pinnedSidebarWidth,
      dialpadWidth,
      infoSidebarWidth,
      videoChatHistoryWidth,
    ] = initialMarginWidths();

    if (pathname.includes('/chat')) {
      videoChatHistoryWidth = 0;
    }
    if (pathname.includes('/calls')) {
      pinnedSidebarWidth = 0;
      if (!['new', 'edit'].includes(channelInfoDetails?.channelMode))
        infoSidebarWidth = 0;
    }
    if (
      pathname.includes('/video-app') ||
      pathname.includes('/directory') ||
      pathname.includes('/addressBook') ||
      pathname.includes('/settings') ||
      pathname.includes('/calendar') ||
      pathname.includes('/fax') ||
      pathname.includes('/voicemail')
    ) {
      pinnedSidebarWidth = infoSidebarWidth = videoChatHistoryWidth = 0;
      if (isActiveCall) dialpadWidth = 0;
      if (isVideoChatHistoryOpened) dialpadWidth = 0;
    }

    return max([
      pinnedSidebarWidth,
      dialpadWidth,
      infoSidebarWidth,
      videoChatHistoryWidth,
    ]);
  };

  const hideMargin =
    (openTopbarDialpad &&
      openedRightSidebarsOrder.size === 1 &&
      isActiveCall) ||
    openedRightSidebarsOrder.size == 0;

  return (
    <Styled.SidebarMarginStyled
      className={cx('sidebar-margin', {
        hidden: hideMargin,
      })}
      $marginWidth={marginWidth}
    />
  );
};

export default inject(
  STORE_UI,
  STORE_PERSON,
  STORE_PREFERENCE,
  STORE_CONVERSATION,
  STORE_PHONE_CALL
)(observer(SidebarMargin));
