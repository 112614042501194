import React from 'react';
import { Popup } from 'semantic-ui-react';

import sipConnected from 'Assets/images/sip-connection-new.svg';
import sipNotConnected from 'Assets/images/sip-not-connected-new.svg';
import type { TopBarSipConnectionStatusProps } from './interfaces';

export const TopBarSipConnectionStatus: React.FC<
  TopBarSipConnectionStatusProps
> = ({ handleSipReconnection, isWebRTCConnected, testid }) => (
  <Popup
    trigger={
      <img
        {...(!isWebRTCConnected && { onClick: handleSipReconnection })}
        src={isWebRTCConnected ? sipConnected : sipNotConnected}
        className="sip-connetion-status"
        data-testid={`${testid}-buttonSipConnectionStatus`}
      />
    }
    content={
      isWebRTCConnected
        ? 'Sip Connected'
        : 'Sip Not Connected, click for reconnection'
    }
    position="left center"
  />
);
