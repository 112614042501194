import { IParticipantPerson } from 'Interfaces/participantPerson';
import { isEmpty } from 'lodash';
import { when } from 'mobx';
import { ITransformer } from 'mobx-utils';
import { ConversationModel } from 'Models/index';
import { isNullOrUndefined } from 'util';
import { compileEditHtmlWithMentions } from './markdownCompiler';

/**
 * Toggle editing the latest `chat` Message owned by the logged-in user.
 *
 * @export
 * @param curConversation
 * @param selectOtherParticipantPersons
 * @param rawContent Raw content of the target `Message` which will be edited.
 * @param messageIdToEdit The `id` of the target `Message` to start editing
 * (should be `chat.text` from the newest chat Message owned by the logged-in user)
 * @param setEditMessageDraftRaw Set the raw content of the Edit Message Draft
 * @param setEditMessageDraftHtml Set the HTML content of the Edit Message Draft
 * @param isEditingMessageId If currently editing a Message, the `id`, otherwise `null`
 * @param setIsEditingMessageId Set the currently editing Message `id`
 * @returns If the content was compiled into the Edit Message Draft, a Promise indicating completion, otherwise a void Promise.
 */
export function toggleEdit(
  curConversation: ConversationModel,
  selectOtherParticipantPersons: ITransformer<string, IParticipantPerson[]>,
  rawContent: string,
  messageIdToEdit: string,
  setEditMessageDraftRaw: (rawContent: string) => void,
  setEditMessageDraftHtml: (htmlContent: string) => void,
  isEditingMessageId: string,
  setIsEditingMessageId: (id: string) => void
): Promise<void> {
  if (!isEmpty(messageIdToEdit) && isNullOrUndefined(isEditingMessageId)) {
    setIsEditingMessageId(messageIdToEdit);
    setEditMessageDraftRaw(rawContent);
    return compileContentIntoEditMessageDraftHtml(
      rawContent,
      curConversation,
      selectOtherParticipantPersons,
      setEditMessageDraftHtml
    );
  } else {
    setIsEditingMessageId(null);
    setEditMessageDraftRaw('');
    setEditMessageDraftHtml('');
    return Promise.resolve();
  }
}

/**
 * Once `selectOtherParticipantPersons` !== null, if the Conversation is a Channel, compile the raw markdown content of a message
 * into HTML, processing Mention `<div>`s.
 *
 * If the Conversation is not a Channel, pass the raw content through to `setEditMessageDraftHtml`
 *
 * @export
 * @param rawContent
 * @param conversationId
 * @param curConversation
 * @param selectParticipantPersons
 * @param setEditMessageDraftHtml
 * @returns
 */
export function compileContentIntoEditMessageDraftHtml(
  rawContent: string,
  curConversation: ConversationModel,
  selectParticipantPersons: ITransformer<string, IParticipantPerson[]>,
  setEditMessageDraftHtml: (htmlContent: string) => void
) {
  return when(
    () =>
      selectParticipantPersons(curConversation.id) !== null &&
      curConversation !== null,
    { timeout: 10000 }
  ).then(() => {
    if (
      curConversation &&
      (curConversation.grouping === 'Channel' ||
        curConversation.grouping === 'Group')
    ) {
      const compiledHtml = compileEditHtmlWithMentions(
        rawContent,
        selectParticipantPersons(curConversation.id)
      );
      setEditMessageDraftHtml(compiledHtml);
    } else {
      setEditMessageDraftHtml(rawContent);
    }
  });
}
