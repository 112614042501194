import React, { forwardRef } from 'react';
import type { CustomDateTimeComponentProps } from './types';
const timeIcon = require('../../assets/images/time-icon.svg');

export const CustomDateTimeComponent = forwardRef(({ value, onClick, isTime }: CustomDateTimeComponentProps, ref) => {
  return (
    <div
      className="flex-row flex-align-items-center"
      onClick={onClick}
      ref={ref as any}
    >
      {isTime ? value : (
        <>
          <img src={timeIcon} />
          <span>{value}</span>
        </>
      )}
    </div>
  );
});
