import { Picker as EmojiMartPicker } from 'emoji-mart';
import * as React from 'react';

import EmojiActive from 'Assets/images/emoji-active.svg';
import EmojiInActive from 'Assets/images/emoji-hover.svg';
import EmojiOnHover from 'Assets/images/emoji-inactive.svg';
import type { EmojiPickerProps } from './interfaces';

export const EmojiPicker: React.FC<EmojiPickerProps> = ({
  onEmojiClick,
  showEmoji,
  handleOnSelect,
  toogleEmojiPicker,
  activeEmoji,
  toggleShowMessageStatusEmojis,
  testid,
}) => (
  <div className="edit-wrapper">
    <div
      id="emoji-message-status"
      className="emoji-wrapper"
      onClick={onEmojiClick}
    >
      {showEmoji ? (
        <span className="emoji-box">
          <EmojiMartPicker
            onSelect={handleOnSelect}
            data-testid={`${testid}-selectorEmojiPicker`}
            autoFocus
          />
        </span>
      ) : (
        activeEmoji && (
          <span
            className="emoji-picked"
            onClick={toogleEmojiPicker}
            data-testid={`${testid}-buttonPickedEmoji`}
          >
            {activeEmoji}
          </span>
        )
      )}
      {(!activeEmoji || showEmoji) && (
        <div
          className="emoji-btn"
          onClick={toggleShowMessageStatusEmojis}
          data-testid={`${testid}-buttonEmptyEmoji`}
        >
          <img
            src={showEmoji ? EmojiActive : EmojiInActive}
            onMouseOver={
              showEmoji
                ? (e) => (e.currentTarget.src = EmojiActive)
                : (e) => (e.currentTarget.src = EmojiOnHover)
            }
            onMouseLeave={
              showEmoji
                ? (e) => (e.currentTarget.src = EmojiActive)
                : (e) => (e.currentTarget.src = EmojiInActive)
            }
          />
        </div>
      )}
    </div>
  </div>
);
