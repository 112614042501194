import * as React from 'react';

import { IconButton } from '../shared/IconButton';
import type { ChatDetailsButtonProps } from './ChatDetailsButton.types';

export const ChatDetailsButton: React.FC<ChatDetailsButtonProps> = ({
  isGroupChat = false,
  onClick,
  popupPosition,
  size,
  testid,
  compact,
}) => (
  <IconButton
    content={isGroupChat ? 'View Group Details' : 'View Contact Details'}
    icon="info"
    testid={`${testid}-buttonChatDetails`}
    {...{ onClick, popupPosition, size, compact }}
  />
);
