import React from 'react';

import { Dropdown } from 'semantic-ui-react';
import type { SettingsDropdownProps } from './interfaces';

export const SettingsDropdown: React.FC<SettingsDropdownProps> = ({
  label,
  options,
  onChange,
  value,
  icon,
  testid,
}) => (
  <div className="settings-select flex-column">
    <span className="sub-headers settings-select--label">{label}</span>
    <div className="flex-align-items-center flex-row">
      <Dropdown
        {...{ options, onChange, value }}
        data-testid={testid}
        className="IOput settings-select--dropdown"
        placeholder="Select an option"
      />
      <div className="flex-row flex-align-items-center">
        <img alt="icon" src={icon} />
      </div>
    </div>
  </div>
);
