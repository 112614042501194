import cx from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { List, ListItemProps, Loader, Popup } from 'semantic-ui-react';
import { resolveConversationPath } from 'Utils/routeNav';
import { UserAvatar } from '../shared/UserAvatar';
import ActivityListItem from '../ActivityListItem';
import { ChannelMemberItemProps } from './interfaces';

import removedUser from 'Assets/images/removed-user.svg';
import withRouter from '../../hocs/WithRouter';
/**
 * List item display of a `Participant` in a `Channel`.
 *
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ChannelMembers
 */
export class ChannelMemberItem extends React.Component<
  ChannelMemberItemProps,
  { hovered: boolean; faded: boolean }
> {
  state = {
    hovered: false,
    faded: false,
  };

  onMouseOver = (e) => this.setState({ hovered: true });
  onMouseOut = (e) => this.setState({ hovered: false });

  private _renderDefaultAvatar = () => (
    <div className="list-item-ih-image flex-zero">
      <div className="removed-user">
        R U
        <img src={removedUser} className="presence-badge" />
      </div>
    </div>
  );

  openInfoDetails = (
    id: number | string,
    type: string,
    from: 'personInfo' | 'groupInfo'
  ) => {
    const { setShowPersonDetails, conversation, uiStore } = this.props;
    if (from === 'personInfo') {
      setShowPersonDetails(id, type);
      conversation.setChannelInfoDetails('0', 'new');
    } else {
      conversation.setChannelInfoDetails(id.toString(), type);
      setShowPersonDetails(null, type);
    }
    uiStore.setActiveConversationIdPinnMess('');
  };

  openChat = (personId) => () => {
    this.props.conversation
      .loadOrCreateConversationWithPost(personId)
      .then((resp) =>
        this.props.navigate(`/chat/conversations/${resp.data.id}/menu`)
      );
  };

  openChatWithNumber = (phone) => () => {
    this.props.conversation
      .loadOrCreateConversationWithPost(0, phone)
      .then((resp) =>
        this.props.navigate(`/chat/conversations/${resp.data.id}/menu`)
      );
  };

  updateRoutes = (path) => {
    const { location, navigate } = this.props;
    const newPath = resolveConversationPath(location.pathname, path);
    navigate(newPath);
  };
  render() {
    const {
      getPerson,
      makeCall,
      participant,
      contact,
      uiStore,
      router,
      from,
      loggedInPersonId,
      conversationId,
      conversation,
      person,
      participantStore,
    } = this.props;
    const { faded, hovered } = this.state;
    const personPbo = participant.personId
      ? getPerson(participant.personId)
      : null;
    const contactPbo =
      participant.personId === 0 || !participant.personId
        ? contact.loadContactByPhoneNumberIfMissing(participant.phone)
        : null;
    const listItemProps: ListItemProps = {
      // stretched: true, (TODO: Prop seems to no longer apply? RP 2019-08-29)
      className: cx('list-item-ih flex-row channel-member-item', {
        faded,
        hovered,
      }),
    };
    if (
      (personPbo && personPbo.state === 'fulfilled') ||
      (contactPbo && contactPbo.state === 'fulfilled')
    ) {
      listItemProps.onMouseOver = this.onMouseOver;
      listItemProps.onMouseOut = this.onMouseOut;
      listItemProps.style = { cursor: 'pointer' };
    }

    const externalContact = contactPbo
      ? person.getExtrContactByPhoneNumber(participant.phone)
      : null;

    return (
      <List.Item {...listItemProps}>
        {personPbo ? (
          personPbo.case({
            pending: () => <Loader active indeterminate size="mini" />,
            rejected: () => (
              <>
                {this._renderDefaultAvatar()}
                <List.Content className="list-item-ih-content flex-grow-shrink">
                  <span className="color-removed">
                    Removed User #{participant.personId}
                  </span>
                </List.Content>
              </>
            ),
            fulfilled: (personResp) => {
              return (
                <>
                  <div className="list-item-ih-image flex-grow">
                    {
                      // After portal side make profile pic work, uncomment this one
                      // personResp.data.DisplayAvatar ?
                      // <Image className='profile-pic' src={`${personResp.data.DisplayAvatar}`} size='mini' />
                      // :
                      <UserAvatar
                        name={personResp.data.DisplayName}
                        selectUnreadCounts={
                          uiStore.selectConversationUnreadCounts
                        }
                        presence={uiStore.selectPersonPresenceStatus(
                          personResp.data.id
                        )}
                      />
                    }
                  </div>
                  <List.Content
                    className="list-item-ih-content flex-grow-shrink status-group-info"
                    id="group-members"
                    onClick={this.openChat(personResp.data.id)}
                  >
                    <span data-private>{personResp.data.DisplayName}</span>
                    <Popup
                      inverted
                      trigger={
                        <span className="message-status">
                          {uiStore.selectPersonMessageStatus(personResp.data.id)
                            ?.title || ''}
                        </span>
                      }
                      content={
                        uiStore.selectPersonMessageStatus(personResp.data.id)
                          ?.message || ''
                      }
                      position={'top right'}
                    />
                  </List.Content>
                  <div
                    className={cx('list-item-ih-hover-buttons', { hovered })}
                  >
                    {from === 'group-info' ? (
                      <ActivityListItem
                        contactsByPhone={contact.contactsByPhone}
                        conversation={conversation?.CurrentConversation}
                        conversationId={conversation ? conversationId : '0'}
                        isCurrentConversation={false}
                        loggedInAccountId={person.loggedInAccountId}
                        loggedInPersonId={loggedInPersonId}
                        makeCall={makeCall}
                        key={conversationId}
                        loadPresenceIfMissing={uiStore.loadPresenceIfMissing}
                        loggedInUserActiveConferenceConversation={
                          conversation.LoggedInUserActiveConferenceConversation
                        }
                        participant={participantStore}
                        participantsPbo={participantStore.selectParticipantsByConversationId(
                          router.currentConversationId()
                        )}
                        postConferenceByConversationId={
                          conversation.postConferenceByConversationId
                        }
                        selectParticipantPersonInfo={
                          person.loadPersonByIdGetIfMissingGet
                        }
                        selectPersonPresenceStatus={
                          uiStore.selectPersonPresenceStatus
                        }
                        updateRoute={this.updateRoutes}
                        selectUnreadCounts={
                          uiStore.selectConversationUnreadCounts
                        }
                        setConversationAndTotalUnreadCount={
                          uiStore.setConversationAndTotalUnreadCount
                        }
                        updateMyLastReadMessage={
                          participantStore.updateMyLastReadMessage
                        }
                        personStore={person}
                        conversationStore={conversation}
                        from={from}
                        handleDetails={this.openInfoDetails}
                        currentPersonId={personResp.data.id}
                        setShowPersonDetails={person.setShowPersonDetails}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              );
            },
          })
        ) : contactPbo && contactPbo.case ? (
          contactPbo.case({
            pending: () => <Loader active indeterminate size="mini" />,
            rejected: () => (
              <>
                {this._renderDefaultAvatar()}
                <List.Content className="list-item-ih-content flex-grow-shrink">
                  <span className="color-removed">
                    Removed User #{participant.phone}
                  </span>
                </List.Content>
              </>
            ),
            fulfilled: (contactResp) => {
              return (
                <>
                  <div className="list-item-ih-image flex-grow">
                    {
                      // After portal side make profile pic work, uncomment this one
                      // personResp.data.DisplayAvatar ?
                      // <Image className='profile-pic' src={`${personResp.data.DisplayAvatar}`} size='mini' />
                      // :
                      <UserAvatar
                        name={null}
                        hideInitials
                        selectUnreadCounts={
                          uiStore.selectConversationUnreadCounts
                        }
                      />
                    }
                  </div>
                  <List.Content
                    className="list-item-ih-content flex-grow-shrink status-group-info"
                    id="group-members"
                    onClick={this.openChatWithNumber(
                      contactResp.data.phoneNumber
                    )}
                  >
                    <span data-private>
                      {externalContact?.DisplayName() ||
                        contactResp.data.DisplayName}
                    </span>
                  </List.Content>
                  <Popup
                    inverted
                    trigger={
                      <span className="message-status">
                        {uiStore.selectPersonMessageStatus(
                          parseFloat(contactResp.data.phoneNumber)
                        )?.title || ''}
                      </span>
                    }
                    content={
                      uiStore.selectPersonMessageStatus(
                        parseFloat(contactResp.data.phoneNumber)
                      )?.message || ''
                    }
                    position={'top right'}
                  />
                  <div
                    className={cx('list-item-ih-hover-buttons', { hovered })}
                  >
                    {from === 'group-info' ? (
                      <ActivityListItem
                        contactsByPhone={contact.contactsByPhone}
                        conversation={conversation?.CurrentConversation}
                        conversationId={conversation ? conversationId : '0'}
                        isCurrentConversation={false}
                        loggedInAccountId={person.loggedInAccountId}
                        loggedInPersonId={loggedInPersonId}
                        makeCall={makeCall}
                        key={conversationId}
                        loadPresenceIfMissing={uiStore.loadPresenceIfMissing}
                        loggedInUserActiveConferenceConversation={
                          conversation.LoggedInUserActiveConferenceConversation
                        }
                        participant={participantStore}
                        participantsPbo={participantStore.selectParticipantsByConversationId(
                          router.currentConversationId()
                        )}
                        postConferenceByConversationId={
                          conversation.postConferenceByConversationId
                        }
                        selectParticipantPersonInfo={
                          person.loadPersonByIdGetIfMissingGet
                        }
                        selectPersonPresenceStatus={
                          uiStore.selectPersonPresenceStatus
                        }
                        updateRoute={this.updateRoutes}
                        selectUnreadCounts={
                          uiStore.selectConversationUnreadCounts
                        }
                        setConversationAndTotalUnreadCount={
                          uiStore.setConversationAndTotalUnreadCount
                        }
                        updateMyLastReadMessage={
                          participantStore.updateMyLastReadMessage
                        }
                        personStore={person}
                        conversationStore={conversation}
                        from={from}
                        handleDetails={this.openInfoDetails}
                        currentPersonId={contactResp.data.phoneNumber}
                        setShowPersonDetails={person.setShowPersonDetails}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              );
            },
          })
        ) : (
          <span className="person-pbo-missing">...</span>
        )}
      </List.Item>
    );
  }
}

export default withRouter(observer(ChannelMemberItem));
