import React from 'react';

import blackLinkIcon from 'Assets/images/source-black-link.svg';
import type { ButtonRemoveSocialAccountProps } from './interfaces';
import { Styled } from './index.styles';

export const ButtonRemoveSocialAccount: React.FC<
  ButtonRemoveSocialAccountProps
> = ({ testid, onClick }) => (
  <Styled.Actions>
    <Styled.ActionRemove {...{ onClick }} data-testid={testid}>
      <Styled.ActionRemoveText>REMOVE</Styled.ActionRemoveText>
      <Styled.IconLink src={blackLinkIcon} />
    </Styled.ActionRemove>
  </Styled.Actions>
);
