import { action, IObservableArray, observable, makeObservable } from 'mobx';
import { ISearchableItem, SearchResult } from '../models/SearchResult';

export class SearchModel<TSource extends ISearchableItem> {
  @observable
  public hits: number = 0;
  @observable
  public results: IObservableArray<SearchResult<TSource>> = observable.array();

  @action
  public clearAllData = () => {
    this.hits = 0;
    this.results.clear();
  };

  constructor() {
    makeObservable(this);
  }
}
export class SearchModelDirectory<TSource> {
  @observable
  public hits: number = 0;
  @observable
  public results: IObservableArray<TSource> = observable.array();
  @observable
  public people: IObservableArray<TSource> = observable.array();
  @action
  public clearAllData = () => {
    this.hits = 0;
    this.results.clear();
    this.people.clear();
  };

  constructor() {
    makeObservable(this);
  }
}
export default SearchModel;
