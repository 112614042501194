import { CALL_STATE } from 'Constants/enums';
import { STORE_CONFIG } from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Loader, Popup } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { selectProperVideoTooltipMessageForOneOnOnes } from 'Utils/videoConferenceUtils';
import { ActivityItemPhoneVideoButtonsProps } from './types';

import { ChatDetailsButton } from './ChatDetailsButton';
import { PinnedMessagesButton } from './PinnedMessagesButton';
import { StartCallButton } from './StartCallButton';
import { MuteNotificationsButton } from './MuteNotificationsButton';
import { StartVideoCallButton } from './StartVideoCallButton';
import { IconButton } from '../shared/IconButton';

const ActivityItemPhoneVideoButtonsObservable: React.FC<
  ActivityItemPhoneVideoButtonsProps
> = ({
  activeConference,
  buttonSize: size,
  compact,
  config,
  conversationId,
  currentPersonId,
  darkDisabledVidIcon,
  from,
  getExtrContactByPhoneNumber,
  grouping,
  listOfCMutedConverstions,
  loadOrCreateConversationWithPost,
  loggedInPersonVideoFeature,
  loggedInUserActiveConferenceConversation,
  makeCall,
  participants,
  personPbo,
  phone,
  phoneCalls,
  popupPosition = 'bottom right',
  postConferenceByConversationId,
  selectIfConvMuted,
  setChannelInfoDetails,
  setShowPersonDetails,
  toggleMuteStatus,
  uiStore: {
    selectPersonMessageStatus,
    setActiveConversationIdPinnMess,
    openedRightSidebarsOrder,
    setOpenedRightSidebarsOrder,
  },
}) => {
  const testid = 'activityItemPhoneVideoButtons';
  const [callState, setCallState] = React.useState<CALL_STATE>(
    CALL_STATE.Available
  );

  React.useEffect(() => {
    phoneCalls.filter(({ isCallConnecting, callUri }) => {
      const updatedCallState = isCallConnecting
        ? CALL_STATE.Connecting
        : CALL_STATE.Connected;

      if (personPbo?.state === 'fulfilled') {
        const elementPR = callUri?.split('@')[0].substring(2);
        if (elementPR === String(personPbo?.value.data.id || 0)) {
          setCallState(updatedCallState);
        }
      }
      if (!personPbo && phone === callUri?.split('@')[0]) {
        setCallState(updatedCallState);
      }
    });
  }, [phoneCalls, personPbo, phone]);

  const placeVideoCallConference = () => {
    if (isNullOrUndefined(currentPersonId)) {
      postConferenceByConversationId(conversationId);
      return;
    }

    loadOrCreateConversationWithPost(currentPersonId).then((resp) => {
      postConferenceByConversationId(resp.data.id.toString());
    });
  };

  const isGroupChat = grouping === 'Channel' || grouping === 'Group';
  const isNewGroup = !['recentFevList', 'group-info', 'history'].includes(from);
  const isSMSConversation = participants.some(({ phone }) => phone);
  const isUserInActiveVideoConference =
    !!loggedInUserActiveConferenceConversation;
  const isVideoEnabled =
    config?.signedInPersonConfig?.state === 'fulfilled' &&
    config?.signedInPersonConfig.value.data.video.enabled;

  /* ------------------ IS NOT A COMMUNICATOR USER ----------------- */
  /* -------------------------------------------------------------- */
  if (!personPbo) {
    const extrContact = getExtrContactByPhoneNumber(phone);
    return (
      <div className="flex-row">
        {isNewGroup && (
          <MuteNotificationsButton
            {...{
              size,
              conversationId,
              currentPersonId,
              listOfCMutedConverstions,
              loadOrCreateConversationWithPost,
              popupPosition,
              selectIfConvMuted,
              toggleMuteStatus,
              testid,
            }}
          />
        )}
        {/*   ----- CALL BUTTON -----  
         Never hidden for non-communicator users (they always have a phone number) */}
        {!isGroupChat && (
          <StartCallButton
            {...{
              compact,
              size,
              popupPosition,
              callState,
              personPbo,
              phone,
              currentPersonId,
              makeCall,
              pushToGTMDataLayer,
              conversationId,
              testid,
            }}
          />
        )}
        {!extrContact && !isSMSConversation && isVideoEnabled && isNewGroup && (
          <StartVideoCallButton
            {...{
              placeVideoCallConference,
              activeConference,
              darkDisabledVidIcon,
              isUserInActiveVideoConference,
              loggedInPersonVideoFeature,
              popupPosition,
              size,
              testid,
              setOpenedRightSidebarsOrder,
              openedRightSidebarsOrder,
            }}
          />
        )}
        {isNewGroup && !isGroupChat && (
          <React.Fragment>
            <PinnedMessagesButton
              {...{
                setChannelInfoDetails,
                setShowPersonDetails,
                setActiveConversationIdPinnMess,
                conversationId,
                popupPosition,
                size,
                testid,
                setOpenedRightSidebarsOrder,
                openedRightSidebarsOrder,
              }}
            />
            <ChatDetailsButton
              onClick={() => {
                setShowPersonDetails(
                  extrContact?.phoneNumbers[0]?.number || phone,
                  extrContact ? 'extrContact' : 'contact'
                );
                setOpenedRightSidebarsOrder('sidebar-info');
              }}
              {...{ popupPosition, size, testid }}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
  /* ------------------ COMMUNICATOR USER OR GROUP ----------------- */
  /* -------------------------------------------------------------- */
  return personPbo.case({
    pending: () => <Loader active inline />,
    rejected: (reason) => <span>{reason?.response.data.message}</span>,
    fulfilled: (p) => {
      return (
        <div className="flex-row flex-align-items-center">
          {currentPersonId && from !== 'group-info' && (
            <Popup
              inverted
              trigger={
                <span className="message-status">
                  {selectPersonMessageStatus(parseInt(currentPersonId))
                    ?.title || ''}
                </span>
              }
              content={
                selectPersonMessageStatus(parseInt(currentPersonId))?.message ||
                ''
              }
              position="top right"
            />
          )}
          {isNewGroup && (
            <MuteNotificationsButton
              {...{
                size,
                conversationId,
                currentPersonId,
                listOfCMutedConverstions,
                loadOrCreateConversationWithPost,
                popupPosition,
                selectIfConvMuted,
                toggleMuteStatus,
                testid,
              }}
            />
          )}
          {!isGroupChat && (
            <StartCallButton
              {...{
                compact,
                size,
                popupPosition,
                callState,
                personPbo,
                phone,
                currentPersonId,
                makeCall,
                pushToGTMDataLayer,
                conversationId,
                testid,
              }}
            />
          )}
          {
            /* ----- VIDEO BUTTON ----- */
            /*   Disabled IF: 
              - In another video conference
              - darkDisabledVidIcon === true
              - Is not a pro user and doesn't have a phone number */
            isNewGroup && isVideoEnabled && !isSMSConversation && (
              <IconButton
                onClick={placeVideoCallConference}
                content={selectProperVideoTooltipMessageForOneOnOnes(
                  !isNullOrUndefined(activeConference),
                  null,
                  null
                )}
                disabled={
                  darkDisabledVidIcon ||
                  !loggedInPersonVideoFeature.enabled ||
                  isUserInActiveVideoConference ||
                  (!p?.data.SupportsChat && !p?.data.phoneNumber) ||
                  !loggedInPersonVideoFeature.enabled
                }
                icon="video"
                {...{ popupPosition, size }}
                testid={`${testid}-buttonStartVideoCall`}
              />
            )
          }
          {isNewGroup && (
            <PinnedMessagesButton
              {...{
                setChannelInfoDetails,
                setShowPersonDetails,
                setActiveConversationIdPinnMess,
                conversationId,
                popupPosition,
                size,
                testid,
                setOpenedRightSidebarsOrder,
                openedRightSidebarsOrder,
              }}
            />
          )}
        </div>
      );
    },
  });
};

export const ActivityItemPhoneVideoButtons = inject(STORE_CONFIG)(
  observer(ActivityItemPhoneVideoButtonsObservable)
);
