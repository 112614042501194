import {
  CALENDAR_IDS,
  FAX_URL,
  KEYCODES,
  SHOW_CALENDAR,
  VOICEMAIL_URL,
} from 'Constants/env';
import {
  STORE_MESSAGE,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_UI,
} from 'Constants/stores';
import EntityProfile from 'Containers/EntityProfile';
import 'emoji-mart/css/emoji-mart.css';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Container, Grid } from 'semantic-ui-react';
import CreateChatWithEmail from '../../components/CreateChatWithEmail';
import { TopBar } from '../../components/TopBar';
import { withErrorHandler } from '../../hocs/ErrorHandlerMixin';
import AddressBookContainer from '../AddressBookContainer';
import { CalendarContainer } from '../CalendarContainer';
import CallsComponent from '../CallsContainer';
import ContextContentLoadingContainer from '../ContextContentLoadingContainer';
import { IframeContainer } from '../IframeContainer';
import SettingsContainer from '../SettingsContainer';
import VideoContainer from '../VideoContainer';
import { IAppProps } from './interfaces';

import iconConnectionError from 'Assets/images/icon-connection-error.svg';
import iconWarning from 'Assets/images/icon-warning.svg';
import ChatPage from '../../components/ChatPage';
import { DirectoryPanel } from '../../components/DirectoryPanel';
import Layout from '../Layout';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from '../../constants/responsiveness';
import { getAuthToken } from '../../api';
const spinner = require('../../assets/images/spinner-1s-200px.gif');

class App extends React.Component<
  IAppProps,
  { error?: any; hideSecondaryMenu: boolean }
> {
  static displayName = 'App';
  state = {
    error: undefined,
    hideSecondaryMenu: false,
  };
  renderDevTool = () => {
    if (process.env.FORCE_MOBX_DEVTOOLS) {
      const DevTools = require('mobx-react-devtools').default;
      return <DevTools position={{ top: 0, left: 200 }} />;
    }
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    console.error(
      'Uncaught Error from React component tree:',
      error,
      errorInfo
    );
  }
  iconImages = [iconWarning, spinner, iconConnectionError];

  componentDidMount() {
    this.checkForRedirect();
    this.iconImages.map((images) => {
      const img = document.createElement('img');
      img.src = images;
    });
    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnMount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => {
    if (window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT)
      this.setState({ hideSecondaryMenu: true });
    else this.setState({ hideSecondaryMenu: false });
  };

  onContainerClick = () => {
    const { message } = this.props;
    if (message.createMessageMentionListOpen) {
      message.setCreateMessageMentionListOpen(false);
    }
    if (message.editMessageMentionListOpen) {
      message.setEditMessageMentionListOpen(false);
    }
  };

  onContainerKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const { message } = this.props;
    if (e.keyCode === KEYCODES.ESCAPE) {
      if (message.createMessageMentionListOpen) {
        message.setCreateMessageMentionListOpen(false);
      }
      if (message.editMessageMentionListOpen) {
        message.setEditMessageMentionListOpen(false);
      }
    }
  };

  checkForRedirect = () => {
    const { searchString } = this.props;
    const { pathname } = window.location;
    if (searchString.includes('code=')) {
      if (
        searchString.includes('state=contacts') &&
        pathname !== '/addressBook/sources'
      ) {
        this.props.browserHistory.push('addressBook/sources' + searchString);
      } else if (
        searchString.includes('state=calendars') &&
        pathname !== '/calendar'
      ) {
        this.props.browserHistory.push('calendar/calendars' + searchString);
      }
    }
  };

  render() {
    //Need this for rerendering purpose
    const {
      ui: { groupModalOpen, activePinnedConversationId , hideSecondaryMenu}, //prettier-ignore
      conversation: { channelInfoDetails }, //prettier-ignore
      conversation,
    } = this.props;
    const showCalendar =
      SHOW_CALENDAR ||
      CALENDAR_IDS?.includes(this.props.person.loggedInPersonId);
    const componentProps = {
      ...this.props.browserHistory,
      ...this.props,
      browserHistory: this.props.browserHistory,
    };
    return (
      <Container
        fluid
        className="app-container"
        onClick={this.onContainerClick}
        onKeyDown={this.onContainerKeyDown}
      >
        <TopBar {...componentProps} {...componentProps.browserHistory} />
        <Grid
          className={`main-grid ${
            this.state.hideSecondaryMenu && 'hide-secondary-menu'
          }`}
        >
          <Routes>
            <Route path="/" element={<Layout {...componentProps} />}>
              <Route
                path="welcome"
                element={<ContextContentLoadingContainer {...componentProps} />}
              />
              <Route path="chat/*" element={<ChatPage {...componentProps} />} />
              <Route
                path="email"
                element={<CreateChatWithEmail {...componentProps} />}
              />
              <Route
                path="calls/*"
                element={
                  <>
                    <CallsComponent {...componentProps} />
                    <EntityProfile {...componentProps} />
                  </>
                }
              ></Route>
              <Route
                path="voicemail"
                element={
                  <IframeContainer
                    {...componentProps}
                    src={`${VOICEMAIL_URL}#${getAuthToken()}`}
                  />
                }
              />
              <Route
                path="video-app/*"
                element={<VideoContainer {...componentProps} />}
              />
              <Route
                path="fax"
                element={
                  <IframeContainer
                    {...componentProps}
                    src={`${FAX_URL}#${getAuthToken()}`}
                  />
                }
              />
              {showCalendar && (
                <Route
                  path="calendar/*"
                  element={<CalendarContainer {...componentProps} />}
                />
              )}
              <Route
                path="addressBook/*"
                element={<AddressBookContainer {...componentProps} />}
              />
              <Route
                path="settings/*"
                element={<SettingsContainer {...componentProps} />}
              />
              <Route
                path="directory/*"
                element={<DirectoryPanel {...componentProps} />}
              />
              {this.renderDevTool()}
              <Route
                path=""
                index
                element={
                  <Navigate to={'/chat' + this.props.searchString} replace />
                }
              />
            </Route>
          </Routes>
        </Grid>
      </Container>
    );
  }
}
export const AppWithErrorHandler = inject(
  STORE_MESSAGE,
  STORE_PERSON,
  STORE_PREFERENCE,
  STORE_UI,
  STORE_PHONE_CALL
)(withErrorHandler(observer(App)));
