import React from 'react';
import { Popup } from 'semantic-ui-react';
import QuestionMark from 'Assets/images/question-mark.svg';
import type { QuestionHelperProps } from './interfaces';
import { Styled } from './index.styles';

export const QuestionHelper: React.FC<QuestionHelperProps> = ({
  content,
  testid,
}) => (
  <Styled.QuestionMarkContainer>
    <Popup
      {...{ content }}
      popper={<div style={{ filter: 'none' }} />}
      inverted
      size="mini"
      trigger={
        <Styled.IconQuestionMark
          data-testid={`${testid}-popupQuestionMark`}
          src={QuestionMark}
        />
      }
      position="bottom left"
    />
  </Styled.QuestionMarkContainer>
);
