const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const k = 1024;

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0 || !bytes) return `0 ${sizes[0]}`;

  const fractionDigits = decimals < 0 ? 0 : decimals;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(fractionDigits)) +
    ' ' +
    sizes[i]
  );
}
