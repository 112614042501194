import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_UI,
} from 'Constants/stores';
import ChannelContainer from 'Containers/Channel';
import { ContextPanelProps } from 'Containers/ContextPanel/interfaces';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import CallsDirectoryList from '../../components/CallsDirectoryList';
import { CALL_LOG_CATEGORY } from '../../constants/enums';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from '../../constants/responsiveness';
import withRouter from '../../hocs/WithRouter';

class CallsPanel extends React.Component<ContextPanelProps> {
  componentDidMount() {
    const { ui } = this.props;
    if (
      ui.isTouchSupported() &&
      window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT
    )
      ui.setHideSecondaryMenu(false);
  }
  render() {
    const { ui } = this.props;
    const typeOfCalls = this.props.params.typeOfCallsId as CALL_LOG_CATEGORY;
    return (
      <React.Fragment>
        <ChannelContainer
          openGroupModal={ui.groupModalOpen}
          {...this.props}
          {...this.props.browserHistory}
        />
        <CallsDirectoryList {...this.props} typeOfCalls={typeOfCalls} />
      </React.Fragment>
    );
  }
}
export default inject(
  STORE_PERSON,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION
)(withRouter(observer(CallsPanel)));
