import React, { useEffect, useState } from 'react';
import { Grid, Popup, Button } from 'semantic-ui-react';
import cx from 'classnames';
import { CALL_STATE } from '../../constants/enums';
import { CallLogRowButtonProps } from './interface';

import smsIcon from 'Assets/images/directory-chat.svg';
import phoneIcon from 'Assets/images/phone-directory.svg';
import menuIcon from 'Assets/images/three-dots-menu.svg';
import infoIcon from 'Assets/images/info-icon-row.svg';

import { isNullOrUndefined } from 'util';
import { selectProperVideoIcon } from '../../utils/videoConferenceUtils';

import { MoreButtonsMenuItem } from '../MoreButtonsMenuItem';
import { formatNumberNoPlusIfUS } from '../../utils/phoneUtil';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { STORE_PREFERENCE } from '../../constants/stores';

const CallLogRowButtons = (props: CallLogRowButtonProps) => {
  const {
    conversation,
    phoneStore,
    loggedInPersonId,
    loggedInPersonVideoFeature,
    callLog,
    lines,
  } = props;

  const [showMoreOptionsModal, setShowMoreOptionsModal] =
    useState<Boolean>(false);
  const [showCallOptionsModal, setShowCallOptionsModal] =
    useState<Boolean>(false);
  const [callState, setcallState] = useState<CALL_STATE>(CALL_STATE.Available);
  const navigate = useNavigate();

  useEffect(() => {
    createCallState();
  }, []);

  const createCallState = () => {
    let callState = CALL_STATE.Available;
    phoneStore.phoneCalls.filter((p) => {
      const elementPR = p.callUri?.split('@')[0].substring(2);
      if (elementPR === callLog.personId?.toString()) {
        callState = p.isCallConnecting
          ? CALL_STATE.Connecting
          : CALL_STATE.Connected;
      }
    });
    setcallState(callState);
  };

  const isLoggedInUser = () => callLog.personId === loggedInPersonId;

  const userIsInActiveVideoConference = () =>
    !isNullOrUndefined(conversation.LoggedInUserActiveConferenceConversation);

  const handleOpenChat = () => {
    conversation
      .loadOrCreateConversationWithPost(
        callLog.personId,
        !callLog.personId && callLog.phoneNumber?.replace(/\D/g, '')
      )
      .then((resp) => {
        navigate(`/chat/conversations/${resp.data.id}/menu`);
      });
  };

  const makeCall = () => {
    const { personId, phoneNumber } = callLog;
    if (!personId && !phoneNumber)
      throw new Error('Either Phone Number or Person Id is required.');
    if (personId) {
      phoneStore.callWithPerson(personId, null);
    } else if (phoneNumber) {
      phoneStore.callWithPerson(null, phoneNumber);
    }
  };

  const makeVideoCall = () => {
    conversation
      .loadOrCreateConversationWithPost(
        callLog.personId,
        !callLog.personId && callLog.phoneNumber
      )
      .then((item) => {
        conversation.postConferenceByConversationId(item.data.id.toString());
      });
  };

  const openInfoDetails = async () => {
    const { uiStore, personStore, conversation } = props;
    uiStore.setLoadingConversationForSidebarInfo(true);

    !callLog.personId
      ? await handleSidebarInfoForNumber()
      : await handleSidebarInfoForPerson();
    uiStore.setLoadingConversationForSidebarInfo(false);
    personStore.setOpenDetailsFromWhere('callLogs');
    conversation.setChannelInfoDetails('0', 'new');
    uiStore.setActiveConversationIdPinnMess('');
    uiStore.setOpenedRightSidebarsOrder('sidebar-info');
    setShowMoreOptionsModal(false);
  };

  const handleSidebarInfoForNumber = async () => {
    const { personStore, conversation } = props;
    const formatedPhoneNum = formatNumberNoPlusIfUS(callLog.phoneNumber);
    personStore.setShowPersonDetails(formatedPhoneNum, 'contact');
    const conv = await conversation.loadOrCreateConversationWithPost(
      null,
      formatedPhoneNum
    );
    let phoneNum = conv.data.participants[0].phone;
    await personStore.getLoadNewContact(phoneNum);
    const extrContact = personStore.getExtrContactByPhoneNumber(phoneNum);
    phoneNum =
      extrContact?.phoneNumbers[0]?.normalizedNumber?.replace(/\D/g, '') ||
      phoneNum ||
      callLog.phoneNumber;
    personStore.setShowPersonDetails(
      phoneNum,
      extrContact ? 'extrContact' : 'contact'
    );
  };

  const handleSidebarInfoForPerson = async () => {
    const { personStore } = props;
    await personStore.loadPersonByIdGetIfMissingGet(callLog.personId);
    personStore.setShowPersonDetails(callLog.personId, 'person');
  };

  const isVideoButtonDisabled = () =>
    !!callLog.phoneNumber ||
    userIsInActiveVideoConference() ||
    !loggedInPersonVideoFeature.enabled;

  const createVideoButtonClassName = () =>
    userIsInActiveVideoConference() || !loggedInPersonVideoFeature.enabled
      ? 'cursor-not-allowed action-buttons left-2 top-3'
      : 'action-buttons left-2 top-3';

  const createVideoImageClassName = () => {
    const activeConference =
      conversation.CurrentConversation &&
      conversation.CurrentConversation.activeConference;
    const conferenceIsActive =
      !isNullOrUndefined(activeConference) && activeConference.sessionId !== '';

    return cx('small-icon', {
      'disabled-video-icon cursor-not-allowed':
        !!callLog.phoneNumber ||
        !loggedInPersonVideoFeature.enabled ||
        (!conferenceIsActive && userIsInActiveVideoConference()),
      'disabled-active-video-icon':
        conferenceIsActive && userIsInActiveVideoConference(),
    });
  };

  const createPhoneCallButtonClassName = () =>
    callState !== CALL_STATE.Available
      ? 'cursor-not-allowed' +
        (callState === CALL_STATE.Connecting
          ? ' call-connecting'
          : ' call-connected')
      : '';

  const createInfoContent = () => (
    <div className="more-action-buttons">
      <MoreButtonsMenuItem
        textContent="Video Call"
        image={selectProperVideoIcon(null, null, null)}
        wrapperClassname={createVideoButtonClassName()}
        buttonId="video-icon-button"
        clickHandler={makeVideoCall}
        buttonDisabled={isVideoButtonDisabled()}
        imageClassname={createVideoImageClassName()}
        setShowMoreOptionsModal={setShowMoreOptionsModal}
      />
      <MoreButtonsMenuItem
        textContent="Contact Info"
        image={infoIcon}
        wrapperClassname="action-buttons left-2 top-3"
        buttonId="info-icon-button"
        clickHandler={openInfoDetails}
        buttonDisabled={false}
        setShowMoreOptionsModal={setShowMoreOptionsModal}
      />
    </div>
  );

  const startCall = () =>
    lines && lines.length > 1
      ? () => setShowCallOptionsModal(!showCallOptionsModal)
      : makeCall();

  const startCallButtonContent = () =>
    callState !== CALL_STATE.Available
      ? 'You are currently on a call with this person'
      : 'Start Call';

  return (
    <Grid.Column width="5" textAlign="right">
      <div className="flex-row flex-justify-end flex-align-items-center">
        {!isLoggedInUser() && (
          <>
            {callLog.phoneNumber ? (
              <Popup
                key="start-call-contact"
                position="right center"
                basic
                keepInViewPort={true}
                className="no-padding more-action-buttons-wrapper call-log-menu-wrapper"
                on="click"
                trigger={
                  <Button
                    onClick={startCall}
                    size="small"
                    compact
                    id="call-options"
                    basic
                    className="flex-row align-items-center"
                    disabled={callState !== CALL_STATE.Available}
                  >
                    {
                      <img
                        id="phone-call"
                        className="icon-17px"
                        src={phoneIcon}
                      />
                    }
                  </Button>
                }
                content={
                  showCallOptionsModal && (
                    <div>
                      {lines.map((line) => (
                        <MoreButtonsMenuItem
                          key={line.number}
                          textContent={`${line.type}: ${line.number}`}
                          buttonId={`${line.type}-call-icon-button`}
                          clickHandler={makeCall}
                          buttonDisabled={false}
                          textClassname="call-menu-item"
                          setShowMoreOptionsModal={setShowMoreOptionsModal}
                        />
                      ))}
                    </div>
                  )
                }
              />
            ) : (
              <div>
                <Popup
                  key="start-call"
                  position="right center"
                  inverted
                  content={startCallButtonContent()}
                  trigger={
                    <div className={createPhoneCallButtonClassName()}>
                      <Button
                        onClick={makeCall}
                        size={'small'}
                        className="flex-row align-items-center"
                        compact
                        basic
                        disabled={callState !== CALL_STATE.Available}
                      >
                        {
                          <img
                            id="phone-call"
                            className="icon-17px"
                            src={phoneIcon}
                          />
                        }
                      </Button>
                    </div>
                  }
                />
              </div>
            )}
            <Popup
              key="start-chat"
              inverted
              position="bottom center"
              content={'Start messaging'}
              trigger={
                <div>
                  <Button
                    onClick={handleOpenChat}
                    size="small"
                    compact
                    basic
                    className="flex-row flex-align-items-center"
                  >
                    <img className="icon-18px chat-icon" src={smsIcon} />
                  </Button>
                </div>
              }
            />
            {!callLog.personId && callLog.phoneNumber ? (
              <Popup
                key="open-info"
                inverted
                position="bottom center"
                content={'View contact details'}
                trigger={
                  <div>
                    <Button
                      onClick={openInfoDetails}
                      size="small"
                      compact
                      basic
                      className="flex-row align-items-center"
                    >
                      <img className={cx('icon-18px')} src={infoIcon} />
                    </Button>
                  </div>
                }
              />
            ) : (
              <Popup
                trigger={
                  <Button
                    onClick={() =>
                      setShowMoreOptionsModal(!showMoreOptionsModal)
                    }
                    size="small"
                    compact
                    basic
                    className="flex-row align-items-center"
                  >
                    <img className={cx('icon-18px')} src={menuIcon} />
                  </Button>
                }
                keepInViewPort={true}
                className="no-padding more-action-buttons-wrapper call-log-menu-wrapper"
                on="click"
                content={showMoreOptionsModal && createInfoContent()}
                position="right center"
                basic
              />
            )}
          </>
        )}
      </div>
    </Grid.Column>
  );
};

export default inject(STORE_PREFERENCE)(observer(CallLogRowButtons));
