import cx from 'classnames';
import { CONVERSATION_GROUPING } from 'Constants/enums';
import { validCodes } from 'Constants/env';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_UI,
} from 'Constants/stores';
import { debounce, isEmpty, isObject, uniqBy } from 'lodash';
import { IObservableArray, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Contact } from 'Models/Contacts';
import React from 'react';
import {
  DropdownItemProps,
  DropdownProps,
  InputProps,
} from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import {
  ConversationModel,
  PersonConversationOrContactModel,
  PersonModel,
  SearchResult,
} from '../../../models';
import DropDownItemTopBar from '../../DropDownItemTopBar';

import topBarCallIcon from 'Assets/images/top-bar-call.svg';
import topBarVideoIcon from 'Assets/images/top-bar-video.svg';
import groupIcon from 'Assets/images/user-group.svg';
import {
  formatNumberNoPlusIfUS,
  formatNumberWithNationalCode,
} from 'Utils/phoneUtil';
import withRouter from '../../../hocs/WithRouter';
import { TopBarDropdownProps, TopBarDropdownState } from './interfaces';
import { Styled } from './index.styles';
import { DialpadIconButton } from '../DialpadIconButton';

let timeout;

class TopBarDropdown extends React.Component<
  TopBarDropdownProps,
  TopBarDropdownState
> {
  constructor(props) {
    super(props);
    this.state = {
      allUsersConversationsContacts: [],
      options: [],
      selectedUsers: [],
      searchInput: '',
      phoneNumber: '',
      isPhoneNumber: false,
      hasMoreData: true,
      favoriteList: [],
      operationType: null,
      selectedKey: '',
      loadingContacts: false,
      activeConversationId: '',
      allFavorites: [],
      allExtrContacts: [],
      showLoader: false,
    };
  }
  async componentDidMount() {
    this.setState({ showLoader: true });
    await this.loadDropDownOptions();
    this.setState({ showLoader: false });
  }

  componentDidUpdate() {
    const {
      conversation: { FavoriteConversations },
      person: { allContactsTopBar },
      ui,
    } = this.props;
    const { allFavorites, allExtrContacts, selectedUsers } = this.state;
    if (
      !isEmpty(FavoriteConversations) &&
      FavoriteConversations.length !== allFavorites.length
    ) {
      this.setState({ allFavorites: FavoriteConversations }, async () =>
        this.loadDropDownOptions(allFavorites?.length > 0 || false)
      );
    }
    if (
      !isEmpty(allContactsTopBar) &&
      allContactsTopBar.length !== allExtrContacts.length
    ) {
      this.setState({ allExtrContacts: allContactsTopBar }, async () =>
        this.loadDropDownOptions(allExtrContacts?.length > 0 || false)
      );
    }
    //after making new group, clear selected users
    if (!ui.selectedTopBarUsers && selectedUsers && selectedUsers?.length > 0) {
      this.setState({ selectedUsers: [] });
    }
  }

  createPersonContactConvOptions = (
    allUsersButNotCurrent:
      | Array<SearchResult<PersonConversationOrContactModel>>
      | PersonModel[]
  ) => {
    const {
      ui,
      router,
      conversation,
      phoneCall,
      person: personStore,
    } = this.props;
    return allUsersButNotCurrent?.map((personSearch) => {
      const person = personSearch.source
        ? personSearch.source
        : (personSearch as PersonModel);
      let nameOrPhoneNum = '';
      let dataForButtons;
      let ignoreName: boolean = false;
      let channelType: CONVERSATION_GROUPING = 'OneOnOne';
      if (person.firstName && person.lastName) {
        nameOrPhoneNum = `${person.firstName} ${person.lastName}`;
        dataForButtons = { personId: person.id.toString(), phoneNumbers: null };
      } else if (person.firstName || person.lastName) {
        nameOrPhoneNum = person.firstName || person.lastName;
        dataForButtons = { personId: person.id.toString(), phoneNumbers: null };
      } else if (person.searchableType === 'SearchableDetailsConversation') {
        const conversationModel: ConversationModel =
          personSearch.source as ConversationModel;
        const adHocGrpName = conversation.changeConvTopic(
          this.createArrayOfPersonIds(conversationModel?.participants)
        );
        nameOrPhoneNum = conversationModel.topic || adHocGrpName;
        ignoreName = true;
        channelType = 'Channel';
      } else {
        nameOrPhoneNum =
          formatNumberWithNationalCode(person.phoneNumber) ||
          person.phoneNumber;
        dataForButtons = { personId: null, phoneNumbers: [person.phoneNumber] };
        ignoreName = true;
      }
      return {
        key: person.id.toString(),
        value: person.id.toString(),
        text: nameOrPhoneNum,
        type: person.searchableType,
        label: (
          <DropDownItemTopBar
            loggedInPersonVideoFeature={
              personStore.personAvaliableFeatures.video
            }
            convType={channelType}
            favorite={
              !isNullOrUndefined(
                this.state.favoriteList.find(
                  (favContact) => favContact?.key === person.id
                )
              )
            }
            ignoreName={ignoreName}
            userInfo={dataForButtons}
            conversation={conversation}
            makeCall={this.makeCall}
            name={nameOrPhoneNum}
            openMessageChat={this.openMessageChat}
            options={this.state.options}
            organization={person.department}
            phoneCall={phoneCall}
            pictureUrl={null}
            placeVideoCallConference={this.placeVideoCallConference}
            role={person.role}
            router={router}
            ui={ui}
          />
        ),
      };
    });
  };

  createExtrContactOptions = (extrContacts: Contact[]) => {
    const {
      ui,
      router,
      phoneCall,
      conversation,
      person: personStore,
    } = this.props;
    return extrContacts
      ? extrContacts.map((extrContact) => {
          return {
            key: extrContact.id.toString(),
            value: extrContact.id.toString(),
            text: extrContact.DisplayName(),
            label: (
              <DropDownItemTopBar
                loggedInPersonVideoFeature={
                  personStore.personAvaliableFeatures.video
                }
                favorite={
                  !isNullOrUndefined(
                    this.state.favoriteList.find(
                      (favContact) => favContact?.key === extrContact.id
                    )
                  )
                }
                ignoreName={false}
                userInfo={{
                  personId: null,
                  phoneNumbers: extrContact?.phoneNumbers.map(
                    (phoneNumber) => phoneNumber.number
                  ),
                  keyValue: extrContact.id.toString(),
                }}
                conversation={conversation}
                makeCall={this.makeCall}
                name={extrContact.DisplayName()}
                openMessageChat={this.openMessageChat}
                options={this.state.options}
                organization={extrContact.organization}
                phoneCall={phoneCall}
                pictureUrl={extrContact.pictureUrl}
                placeVideoCallConference={this.placeVideoCallConference}
                role={extrContact.organizationRole}
                router={router}
                ui={ui}
              />
            ),
          };
        })
      : [];
  };

  loadDropDownOptions = async (
    appendResult?: boolean,
    appendIfNoOptions?: boolean
  ) => {
    const {
      conversation: { FavoriteConversations },
      search,
      person: { allContactsTopBar },
    } = this.props;
    const alreadyLoaded =
      this.state.options.length > 0 ||
      this.state.allExtrContacts.length > 0 ||
      this.state.allFavorites.length > 0 ||
      this.state.allUsersConversationsContacts.length > 0;
    let allPersons = [];
    let allConv = [];
    if (
      !isEmpty(FavoriteConversations) &&
      FavoriteConversations.length !== this.state.allFavorites.length
    ) {
      this.setState({ allFavorites: FavoriteConversations });
    }
    if (
      !isEmpty(allContactsTopBar) &&
      allContactsTopBar.length !== this.state.allExtrContacts.length
    ) {
      this.setState({ allExtrContacts: allContactsTopBar });
    }
    if (alreadyLoaded && appendResult) {
      const allPersonResp = search.selectDirectorySearchById('USERS');
      const allConversationsResp =
        search.selectDirectorySearchById('CONVERSATIONS');
      allPersons = allPersonResp?.data?.results || [];
      allConv = allConversationsResp.data?.results || [];
    } else {
      const allPersonResp = await search.getDirectorySearch('USERS', '', 20, 1);
      const allConversationsResp =
        await this.props.search.getPersonContactSearch(
          'CONVERSATIONS',
          '',
          ['SearchableDetailsConversation', 'SearchableDetailsContact'],
          20,
          search.contactPageNumber,
          appendIfNoOptions
        );
      allPersons = allPersonResp?.data?.people || [];
      allConv = allConversationsResp.data?.results || [];
    }
    const personConvMerged = [...allConv, ...allPersons];
    const personConvOptions =
      this.createPersonContactConvOptions(personConvMerged) || [];
    const extrOptions = this.createExtrContactOptions(allContactsTopBar) || [];
    const favoriteList = FavoriteConversations;
    const favoriteListOptions = !favoriteList
      ? [
          {
            key: '',
            value: 'empty',
            text: 'You dont have any contacts to search',
          },
        ]
      : this.mapFavoritesOptions(favoriteList);
    //for load infinite scroll and initial load
    const options = this.sortFavoriteFirst([
      ...favoriteListOptions,
      ...extrOptions,
      ...personConvOptions,
    ]);
    if (this.state.selectedUsers.length > 0) {
      const optionSelectedUsers = this.state.options.filter((option) =>
        this.state.selectedUsers.find((userId) => option?.key === userId)
      );
      const accountMemberOptions =
        this.createPersonContactConvOptions(allPersons);
      const allOptions = this.sortFavoriteFirst([
        ...optionSelectedUsers,
        ...accountMemberOptions,
      ]);
      this.setState({ options: allOptions });
    } else {
      const allUsersConversationsContacts = uniqBy(
        [
          ...favoriteList,
          ...personConvMerged,
          ...allContactsTopBar,
          ...this.state.allUsersConversationsContacts,
        ],
        this.conditionUniqAllUsers
      );
      this.setState({
        allUsersConversationsContacts: allUsersConversationsContacts,
        options,
      });
    }
    return options;
  };
  openMessageChat = (personId, phone) => {
    personId
      ? this.props.conversation
          .loadOrCreateConversationWithPost(personId)
          .then((resp) =>
            this.props.navigate(`/chat/conversations/${resp.data.id}/menu`)
          )
      : this.props.conversation
          .loadOrCreateConversationWithPost(null, phone)
          .then((resp) =>
            this.props.navigate(`/chat/conversations/${resp.data.id}/menu`)
          );
  };
  makeCall =
    (data: { personId: string; phoneNumbers: string[]; keyValue: string }) =>
    (e) => {
      const { phoneCall, person } = this.props;
      const users = data.personId ? [data.personId] : data.phoneNumbers;
      const numberIsInsideOptions = this.state.options.some(async (option) => {
        try {
          const p = await person.loadPersonByIdGet(option.key);
          return (
            users.includes(option?.key) ||
            (!!p &&
              p.data.lines?.length > 0 &&
              users.includes(p.data.lines[0]?.callerId))
          );
        } catch (err) {
          console.warn(
            `Failed to load Person ${option?.key?.toString()}. ` +
              `This may be caused by a removed Person ` +
              `or unassigned number, which is not an error.`,
            err
          );
          return false;
        }
      });

      if (numberIsInsideOptions) {
        e?.stopPropagation();
        if (data.phoneNumbers?.length > 1) {
          this.setState({ selectedKey: data.keyValue, operationType: 'call' });
          return;
        } else if (data?.phoneNumbers?.length === 1) {
          const phone = data?.phoneNumbers[0];
          phoneCall.callWithPerson(null, phone);
          return;
        }
        const isUnknownContact =
          this.getFromAllUsers(data.personId).package === 'unknown-contact';

        isUnknownContact
          ? this.props.conversation
              .loadOrCreateConversationWithPost(null, data.personId)
              .then((resp) => {
                const contact = resp.data.participants.find(
                  (participant) => participant.personId === 0
                );
                phoneCall.callWithPerson(null, contact.phone);
              })
          : phoneCall.callWithPerson(Number(data.personId), null);
      }
    };

  placeVideoCallConference = (personId) => (e) =>
    this.placeVideoCallConferenceInner(personId, e);

  placeVideoCallConferenceInner = (personId, e?) => {
    const { conversation, person } = this.props;
    if (person.personAvaliableFeatures.video.enabled) {
      e?.stopPropagation();
      conversation.loadOrCreateConversationWithPost(personId).then((resp) => {
        this.props.navigate(`/chat/conversations/${resp.data.id}/menu`);
        conversation.postConferenceByConversationId(resp.data.id.toString());
      });
    }
  };

  createMeetingWIthMultipleUsers = async () => {
    const { conversation, person } = this.props;
    const { activeConversationId } = this.state;
    if (person.personAvaliableFeatures.video.enabled) {
      if (activeConversationId) {
        conversation.postConferenceByConversationId(activeConversationId);
      } else {
        conversation
          .createConversationFromAdHoc(this.state.selectedUsers, '')
          .then((newConversation) => {
            if (newConversation) {
              this.setState({
                activeConversationId: newConversation.id.toString(),
              });
              conversation.postConferenceByConversationId(
                newConversation.id.toString()
              );
            }
          });
      }
    }
  };

  sortConversationsByMessOrCreateDate = (favoriteList) => {
    return favoriteList.sort((a, b) => {
      const aDate = a.lastMessageDate || a.created;
      const bDate = b.lastMessageDate || b.created;
      return aDate <= bDate ? 1 : -1; // 1 is B before A, -1 is A before B
    });
  };

  //too many sources of data, maybe id will already be {personId: xxx},
  //thats the reason for check
  createArrayOfPersonIds = (ids) =>
    ids.map((id) => (isObject(id) ? id : { personId: id }));

  mapFavoritesOptions = (favoriteList: IObservableArray<ConversationModel>) => {
    const {
      participant: { selectParticipantsByConversationId },
      person: {
        loggedInPersonId,
        getExtrContactByPhoneNumber,
        loadPersonByIdGetIfMissingGet,
      },
      contact: { contactsByPhone, loadContactByPhoneNumber },
      conversation,
      phoneCall,
      router,
      ui,
      person: personStore,
    } = this.props;

    const sortedFavConversations =
      this.sortConversationsByMessOrCreateDate(favoriteList);
    const allUsersInFav = [];
    const favoritesOptions: DropdownItemProps[] = sortedFavConversations.map(
      (conv) => {
        if (conv?.grouping === 'Channel' || conv?.grouping === 'Group') {
          const adHocGrpName = conversation.changeConvTopic(
            this.createArrayOfPersonIds(conv?.participants)
          );
          allUsersInFav.push(conv);
          return {
            key: conv.id.toString(),
            value: conv.id.toString(),
            text: conv.topic || adHocGrpName,
            label: (
              <DropDownItemTopBar
                loggedInPersonVideoFeature={
                  personStore.personAvaliableFeatures.video
                }
                favorite={true}
                ignoreName={true}
                userInfo={null}
                conversation={conversation}
                makeCall={this.makeCall}
                name={conv.topic || adHocGrpName}
                openMessageChat={this.openMessageChat}
                options={this.state.options}
                organization={null}
                phoneCall={phoneCall}
                pictureUrl={null}
                placeVideoCallConference={this.placeVideoCallConference}
                role={null}
                router={router}
                ui={ui}
                convType="Channel"
              />
            ),
          };
        }

        const participantsPbo = selectParticipantsByConversationId(conv.id);
        return participantsPbo?.case({
          fulfilled: (resp) => {
            const participants = resp.data.results;
            const otherParticipant = participants?.find(
              (participant) => participant.personId !== loggedInPersonId
            );
            if (otherParticipant) {
              const otherPersonId = otherParticipant.personId;
              if (otherPersonId) {
                const personDataPbo =
                  loadPersonByIdGetIfMissingGet(otherPersonId);
                return personDataPbo.case({
                  fulfilled: (resp) => {
                    const person = resp.data;
                    person && allUsersInFav.push(person);
                    return {
                      key: person.id.toString(),
                      value: person.id.toString(),
                      text: person.DisplayName,
                      label: (
                        <DropDownItemTopBar
                          loggedInPersonVideoFeature={
                            personStore.personAvaliableFeatures.video
                          }
                          favorite={true}
                          ignoreName={false}
                          userInfo={{
                            personId: person.id.toString(),
                            phoneNumbers: null,
                            keyValue: person.id.toString(),
                          }}
                          conversation={conversation}
                          makeCall={this.makeCall}
                          name={person.DisplayName}
                          openMessageChat={this.openMessageChat}
                          options={this.state.options}
                          organization={person.department}
                          phoneCall={phoneCall}
                          pictureUrl={null}
                          placeVideoCallConference={
                            this.placeVideoCallConference
                          }
                          role={person.role}
                          router={router}
                          ui={ui}
                        />
                      ),
                    };
                  },
                });
              } else {
                const otherPersonPhoneNumber = otherParticipant.phone;
                const phoneNumNoPlus = formatNumberNoPlusIfUS(
                  otherPersonPhoneNumber
                );
                const extrContact =
                  otherPersonPhoneNumber &&
                  getExtrContactByPhoneNumber(otherPersonPhoneNumber);
                if (extrContact) {
                  allUsersInFav.push(extrContact);
                  return {
                    key: extrContact.id.toString(),
                    value: extrContact.id.toString(),
                    text: extrContact.DisplayName(),
                    label: (
                      <DropDownItemTopBar
                        loggedInPersonVideoFeature={
                          personStore.personAvaliableFeatures.video
                        }
                        favorite={true}
                        ignoreName={false}
                        userInfo={{
                          personId: null,
                          phoneNumbers: extrContact.phoneNumbers.map(
                            (phoneNumber) => phoneNumber.number
                          ),
                          keyValue: extrContact.id.toString(),
                        }}
                        conversation={conversation}
                        makeCall={this.makeCall}
                        name={extrContact.DisplayName()}
                        openMessageChat={this.openMessageChat}
                        options={this.state.options}
                        organization={extrContact.organization}
                        phoneCall={phoneCall}
                        pictureUrl={extrContact.pictureUrl}
                        placeVideoCallConference={this.placeVideoCallConference}
                        role={extrContact.organizationRole}
                        router={router}
                        ui={ui}
                      />
                    ),
                  };
                } else {
                  const contactPbo = contactsByPhone.has(phoneNumNoPlus)
                    ? contactsByPhone.get(phoneNumNoPlus)
                    : loadContactByPhoneNumber(otherPersonPhoneNumber);
                  return contactPbo.case({
                    fulfilled: (resp) => {
                      const contact = resp.data;
                      contact && allUsersInFav.push(contact);
                      return {
                        key: contact.id.toString(),
                        value: contact.id.toString(),
                        text: contact.DisplayName,
                        label: (
                          <DropDownItemTopBar
                            loggedInPersonVideoFeature={
                              personStore.personAvaliableFeatures.video
                            }
                            favorite={true}
                            ignoreName={true}
                            userInfo={{
                              personId: null,
                              phoneNumbers: [contact.phoneNumber],
                              keyValue: contact.id.toString(),
                            }}
                            conversation={conversation}
                            makeCall={this.makeCall}
                            name={contact.DisplayName}
                            openMessageChat={this.openMessageChat}
                            options={this.state.options}
                            organization={null}
                            phoneCall={phoneCall}
                            pictureUrl={null}
                            placeVideoCallConference={
                              this.placeVideoCallConference
                            }
                            role={null}
                            router={router}
                            ui={ui}
                          />
                        ),
                      };
                    },
                  });
                }
              }
            }
          },
        });
      }
    );
    const allUsersConversationsContacts = uniqBy(
      [...allUsersInFav, ...this.state.allUsersConversationsContacts],
      this.conditionUniqAllUsers
    );
    this.setState({
      allUsersConversationsContacts: allUsersConversationsContacts,
      favoriteList: favoritesOptions,
    });
    return favoritesOptions;
  };

  handleSelectedType = (selectedUser) => {
    if (
      selectedUser?.searchableType === 'SearchableDetailsConversation' ||
      selectedUser?.grouping
    ) {
      return 'Channel';
    } else if (
      selectedUser?.searchableType === 'SearchableDetailsContact' ||
      selectedUser?.package === 'unknown-contact'
    ) {
      return 'Contact';
    } else if (!selectedUser?.hasOwnProperty('package')) {
      return 'ExtrContact';
    } else {
      return 'Person';
    }
  };

  openChatWithPerson = (selectedUser) => {
    const onlyAccountMembers = this.state.allUsersConversationsContacts.filter(
      (user) =>
        user.resultType === 'SearchableDetailsPerson' ||
        user.hasOwnProperty('package')
    );
    const optionsAccountMember = this.state.options.filter((option) =>
      onlyAccountMembers.find(
        (accMember) =>
          accMember.source?.id.toString() === option?.key ||
          accMember?.id?.toString() === option?.key
      )
    );
    this.setState({ options: optionsAccountMember });
    this.openMessageChat(selectedUser.id, null);
    return true;
  };

  conditionUniqAllUsers = (item) => item.id || item.source?.id;

  openChatOrCallBasedOnSelectedUser = (selectedUser) => {
    const {
      participant: { selectParticipantsByConversationId },
      person: { loggedInPersonId, getExtrContactByPhoneNumber },
      contact: { contactsByPhone, loadContactByPhoneNumber },
    } = this.props;
    const selectedType = this.handleSelectedType(selectedUser);
    const startsWithStar = selectedUser.phoneNumber?.charAt(0) === '*';
    if (startsWithStar) {
      return;
    }
    if (selectedType === 'Channel') {
      const participantsPbo = selectParticipantsByConversationId(
        selectedUser.id
      );
      return participantsPbo?.case({
        fulfilled: (resp) => {
          const participants = resp.data.results;
          const otherParticipant = participants?.find(
            (participant) => participant.personId !== loggedInPersonId
          );
          if (otherParticipant) {
            const otherPersonId = otherParticipant.personId;
            if (otherPersonId) {
              this.props.navigate(
                `/chat/conversations/${selectedUser.id}/menu`
              );
              this.refreshData();
              return false;
            } else {
              const otherPersonPhoneNumber = otherParticipant.phone;
              const phoneNumNoPlus = formatNumberNoPlusIfUS(
                otherPersonPhoneNumber
              );
              const extrContact =
                otherPersonPhoneNumber &&
                getExtrContactByPhoneNumber(phoneNumNoPlus);
              if (extrContact) {
                const userInfo = {
                  personId: null,
                  phoneNumbers: selectedUser?.phoneNumbers.map(
                    (phoneNumber) => phoneNumber.number
                  ),
                  keyValue: selectedUser.id.toString(),
                };
                const callCallback = this.makeCall(userInfo);
                callCallback(null);
                this.refreshData();
                return false;
              } else {
                const contactPbo = contactsByPhone.has(otherPersonPhoneNumber)
                  ? contactsByPhone.get(otherPersonPhoneNumber)
                  : loadContactByPhoneNumber(otherPersonPhoneNumber);
                return contactPbo.case({
                  fulfilled: (resp) => {
                    const contact = resp.data;
                    const userInfo = {
                      personId: null,
                      phoneNumbers: [contact.phoneNumber],
                      keyValue: contact.phoneNumber,
                    };
                    const callCallback = this.makeCall(userInfo);
                    callCallback(null);
                    this.refreshData();
                    return false;
                  },
                });
              }
            }
          }
        },
      });
    } else if (selectedType === 'ExtrContact') {
      //for extr contacts
      const userInfo = {
        personId: null,
        phoneNumbers: selectedUser?.phoneNumbers.map(
          (phoneNumber) => phoneNumber.number
        ),
        keyValue: selectedUser.id.toString(),
      };
      const callCallback = this.makeCall(userInfo);
      callCallback(null);
      this.refreshData();
      return false;
    } else if (selectedType === 'Contact') {
      const userInfo = {
        personId: null,
        phoneNumbers: [selectedUser.phoneNumber],
        keyValue: selectedUser.phoneNumber,
      };
      const callCallback = this.makeCall(userInfo);
      callCallback(null);
      this.refreshData();
      return false;
    }
    //person
    else {
      return this.openChatWithPerson(selectedUser);
    }
  };

  openChatBasedOnSelectedUser = (selectedUser) => {
    const selectedType = this.handleSelectedType(selectedUser);
    const startsWithStar = selectedUser.phoneNumber?.charAt(0) === '*';
    if (startsWithStar) {
      return;
    }
    if (selectedType === 'Channel') {
      //in this case selectedUser is Channel
      this.props.navigate(`/chat/conversations/${selectedUser.id}/menu`);
      this.refreshData();
      return false;
    } else if (selectedType === 'ExtrContact') {
      const phoneNumbers =
        selectedUser.phoneNumbers?.length > 0 &&
        selectedUser.phoneNumbers[0]?.number;
      //for extr contacts
      this.openMessageChat(null, phoneNumbers || selectedUser.phoneNumber);
      this.refreshData();
      return false;
    } else if (selectedType === 'Contact') {
      this.openMessageChat(null, selectedUser.phoneNumber);
      this.refreshData();
      return true;
    }
    //person
    else {
      return this.openChatWithPerson(selectedUser);
    }
  };

  isInParticipantList = (conversation, selectedId) =>
    conversation.participants?.find(
      (participant) => participant?.personId?.toString() === selectedId
    );

  getFromAllUsers = (selectedId) => {
    const isUser = this.state.allUsersConversationsContacts.find(
      (item) =>
        item.id?.toString() === selectedId ||
        item.source?.id?.toString() === selectedId
    );
    const isExtr =
      !isUser &&
      this.props.person.allContactsTopBar.find(
        (contact) => contact.id === selectedId
      );
    const userInTheConversation =
      !isUser &&
      !isExtr &&
      this.state.allUsersConversationsContacts.find((item) =>
        this.isInParticipantList(item, selectedId)
      );
    return isUser || isExtr || userInTheConversation;
  };

  handleOnChangeDropdown = (e, d: DropdownProps | any) => {
    const { conversation, ui, search } = this.props;
    const user = this.getFromAllUsers(d.value[d.value.length - 1]);
    const selectedUser = user?.source?.id ? user?.source : user;
    const { length: selectedLength } = d.value;
    const inputElement = document.querySelector('#top-bar-dropdown input');
    e.stopPropagation();
    const shouldAddUser =
      selectedLength === 1
        ? selectedUser && e.key === 'Enter'
          ? this.openChatOrCallBasedOnSelectedUser(selectedUser)
          : this.openChatBasedOnSelectedUser(selectedUser)
        : true;
    if (shouldAddUser) {
      if (e.currentTarget.id !== 'top-bar-dropdown') {
        this.setState({
          selectedUsers: d.value as string[],
          activeConversationId: '',
        });
        ui.setSelectedTopBarUsers(d.value);
        const allOptionsWeHaveSelected = this.state.options.filter((option) =>
          d.value.includes(option?.key)
        );
        const isSearchListEmpty =
          allOptionsWeHaveSelected &&
          allOptionsWeHaveSelected.length === this.state.options.length;

        if (isEmpty(d.value) || isSearchListEmpty) {
          search.setContactPageNumber(1);
          this.setState({ hasMoreData: true });
          this.loadDropDownOptions(false, isSearchListEmpty);
        }

        if (selectedLength > 1) {
          conversation.setChannelInfoDetails(
            conversation.channelInfoDetails.channelConversationId,
            'new',
            !this.numberIsInsideOptions() ? 'person' : 'phone'
          );
          conversation
            .handleAdHocGroups(length, d.value)
            ?.then((conversation) => {
              this.setState({
                activeConversationId: conversation.id.toString(),
              });
            });
        }
      }
    }

    this.setSearchInput('');
    search.setDirectorySearchValue('');
    inputElement && this.mapEventTab(inputElement);
  };

  mapEventTab = (inputElement) => {
    inputElement.focus();
    inputElement.onkeydown = (e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();
        document.getElementById('message-input').click();
      }
    };
  };

  private setSearchInput = (searchInput: string) =>
    this.setState({ searchInput: searchInput });

  handleFocus = async () => {
    const { searchInput, options } = this.state;
    if (!searchInput && options.length < 20) {
      this.setState({ showLoader: true });
      await this.loadDropDownOptions();
      this.setState({ showLoader: false });
    }
    this.props.ui.setHeaderDropdown(false);
    (
      document.querySelector('#top-bar-dropdown .dropdown input') as any
    ).focus();
  };

  refreshData = () => {
    const { search } = this.props;
    //when clicked on contact
    if (this.state.selectedUsers.length === 0) {
      search.setContactPageNumber(1);
      search.setDirectorySearchValue('');
      this.setSearchInput('');
      this.setState({ hasMoreData: true, selectedUsers: [] });
      this.loadDropDownOptions();
    }
  };

  handleScrollDown = () => {
    timeout = setTimeout(() => {
      const input: any = document.querySelector('#top-bar-dropdown input');
      if (input) {
        this.handleFocus();
        input.onkeyup = (e: any) => {
          if (e.keyCode === 13) {
            this.setSearchInput('');
          }
        };
      }
      const element: any = document.querySelector('#top-bar-dropdown .menu');
      if (element) {
        element.onscroll = async (e: any) => {
          const bottom =
            e.target.scrollHeight -
            e.target.scrollTop -
            e.target.clientHeight -
            10;
          if (bottom <= 100 && this.state.hasMoreData) {
            this.handleLoadContacts();
          }
        };
      }
      if (!this.state.hasMoreData) {
        clearTimeout(timeout);
      }
    }, 500);
  };

  handleLoadContacts = async () => {
    const {
      ui: { handleLoadMoreContacts },
    } = this.props;
    //if selected any dont load extrContacts
    if (!this.state.loadingContacts) {
      this.setState({ loadingContacts: true, showLoader: true });
      const isEmpty: boolean = await handleLoadMoreContacts(
        this.state.selectedUsers.length <= 0,
        false
      );
      await this.loadDropDownOptions(true);
      isEmpty
        ? this.setState({
            hasMoreData: false,
            loadingContacts: false,
            showLoader: false,
          })
        : this.setState({ loadingContacts: false, showLoader: false });
    }
  };

  onSearchInputChange = async (e, inputProps: InputProps) => {
    const { searchQuery } = inputProps;
    this.setState({ hasMoreData: true });
    this.setSearchInput(searchQuery);
    await this.filter(e, inputProps);
  };

  enoughOptionsForScroll = (
    newOptions: DropdownItemProps[],
    searchQuery: string
  ) => {
    return (
      newOptions.filter((option) =>
        option.text.toString().includes(searchQuery)
      ).length > 5
    );
  };

  filter = debounce(async (e, inputProps: InputProps) => {
    const { searchQuery } = inputProps;
    const {
      person,
      ui,
      ui: { handleLoadMoreContacts },
      search,
    } = this.props;
    search.setDirectorySearchValue(searchQuery);
    search.setContactPageNumber(1);
    this.setState({ showLoader: true });
    if (!searchQuery) {
      await person.getSearchContactsTopBar(20, 1, '', '');
      await this.loadDropDownOptions();
      this.setState({ showLoader: false });
      return;
    } else {
      const phoneNumber = searchQuery.replace(/[^0-9+]/g, '');
      const startsWithStar = searchQuery?.charAt(0) === '*';
      const query =
        phoneNumber && !startsWithStar && !isNaN(phoneNumber)
          ? phoneNumber
          : searchQuery;
      const extrContacts = await person.getSearchContactsTopBar(
        20,
        1,
        '',
        query || ''
      );
      const respConversations = await ui.searchContacts(e, inputProps, true);
      const respPerson = await search.getDirectorySearch('USERS', query, 20);
      let newListOfUsers = [];
      let newOptionResults: DropdownItemProps[] = [];
      const isAccoutMemberSelected = this.state.selectedUsers.length > 0;
      if (respPerson) {
        const filteredResults: IObservableArray<PersonModel> =
          respPerson?.data?.people || [];
        const allPersonButNoCurrent = filteredResults?.filter(
          (result) => result.id !== person.loggedInPersonId
        );
        const personOptions =
          this.createPersonContactConvOptions(allPersonButNoCurrent) || [];
        newOptionResults = [...newOptionResults, ...personOptions];
        newListOfUsers = [...newListOfUsers, ...allPersonButNoCurrent];
      }
      if (respConversations && !isAccoutMemberSelected) {
        const conversations: IObservableArray<
          SearchResult<PersonConversationOrContactModel>
        > = respConversations?.data?.results || [];
        const convOptions =
          this.createPersonContactConvOptions(conversations) || [];
        newOptionResults = [...newOptionResults, ...convOptions];
        newListOfUsers = [...newListOfUsers, ...conversations];
      }
      if (extrContacts && !isAccoutMemberSelected) {
        newOptionResults = [
          ...newOptionResults,
          ...this.createExtrContactOptions(extrContacts),
        ];
        newListOfUsers = [...newListOfUsers, ...extrContacts];
      }
      if (newOptionResults.length > 0) {
        const options = this.sortFavoriteFirst(newOptionResults);
        const selectedOptions = isAccoutMemberSelected
          ? this.getOptionFromSelected()
          : [];
        const allUsersConversationsContacts = uniqBy(
          [...newListOfUsers, ...this.state.allUsersConversationsContacts],
          this.conditionUniqAllUsers
        );
        const newOptions = [...selectedOptions, ...options];
        this.setState({
          allUsersConversationsContacts: allUsersConversationsContacts,
          options: newOptions,
        });
        if (!this.enoughOptionsForScroll(newOptions, searchQuery)) {
          await search.getDirectorySearch(
            'USERS',
            '',
            20,
            search.contactPageNumber + 1
          );
          await handleLoadMoreContacts(true, false);
          await person.getSearchContactsTopBar(20, 1, '', searchQuery, true);
          this.loadDropDownOptions(true);
        }
      }
      this.bindNumberToOptions(searchQuery);
      this.setState({ showLoader: false });
      return true;
    }
  }, 800);

  checkIsBvCode = (searchQuery: string) =>
    validCodes.some((code) => searchQuery.replace(/\s/g, '').includes(code));

  bindNumberToOptions = (searchQuery: string) => {
    const { ui } = this.props;
    const isBroadVoiceCode = this.checkIsBvCode(searchQuery);
    const phoneNumValid = !this.props.person.phoneNumInvalid(searchQuery);
    const phoneNumber = isBroadVoiceCode
      ? searchQuery
      : searchQuery.replace(/[^0-9+]/g, '');
    const addNumberInFront =
      (isBroadVoiceCode || phoneNumValid) && phoneNumber.length >= 2;
    const optionNumSearch: DropdownItemProps = addNumberInFront
      ? this.createOptionFromSearch(phoneNumber, searchQuery)
      : null;
    if (optionNumSearch) {
      const mergeOptions = [optionNumSearch, ...this.state.options];
      const newUser = {
        id: phoneNumber,
        phoneNumber: phoneNumber,
        name: phoneNumber,
        package: 'unknown-contact',
      };
      const allUsersConversationsContacts = uniqBy(
        [newUser, ...this.state.allUsersConversationsContacts],
        this.conditionUniqAllUsers
      );
      this.setState({
        allUsersConversationsContacts: allUsersConversationsContacts,
        options: [...mergeOptions],
      });
    }
  };
  createOptionFromSearch = (phoneNumber: string, text: string) => {
    const {
      ui,
      conversation,
      router,
      phoneCall,
      person: personStore,
    } = this.props;
    return {
      key: phoneNumber,
      value: phoneNumber,
      text: text,
      label: (
        <DropDownItemTopBar
          loggedInPersonVideoFeature={personStore.personAvaliableFeatures.video}
          convType="OneOnOne"
          favorite={false}
          ignoreName={true}
          userInfo={{
            personId: null,
            phoneNumbers: [phoneNumber],
            keyValue: phoneNumber,
          }}
          conversation={conversation}
          makeCall={this.makeCall}
          name={phoneNumber}
          openMessageChat={this.openMessageChat}
          options={this.state.options}
          organization={null}
          phoneCall={phoneCall}
          pictureUrl={null}
          placeVideoCallConference={this.placeVideoCallConference}
          role={null}
          router={router}
          ui={ui}
        />
      ),
      package: 'unknown-contact',
    };
  };

  getOptionFromSelected = () => {
    return this.state.selectedUsers
      .map((id) => {
        return this.state.options.find(
          (option) => option?.key === id.toString()
        );
      })
      .filter((item) => item.key);
  };

  sortFavoriteFirst = (dropDownOptions: DropdownItemProps[]) => {
    const {
      conversation: { FavoriteConversations },
    } = this.props;
    const inFavoriteConversations = FavoriteConversations.filter((favContact) =>
      this.checkIfIsFavConv(favContact.participants, this.state.favoriteList)
    );
    const latestFavorites = this.mapFavoritesOptions(
      observable.array(inFavoriteConversations)
    );
    const inFavorites = latestFavorites
      .filter((favContact) =>
        dropDownOptions.find((contact) => favContact?.key === contact?.key)
      )
      .sort(this.props.ui.sortUserCriteria);
    const nonFav = dropDownOptions
      .filter(
        (contact) =>
          !inFavorites?.find((favContact) => favContact?.key === contact?.key)
      )
      .sort(this.props.ui.sortUserCriteria);
    return [...inFavorites, ...nonFav];
  };

  checkIfIsFavConv = (participants, options: DropdownItemProps) => {
    const {
      person: { loggedInPersonId },
    } = this.props;
    return participants.filter((participant) =>
      loggedInPersonId !== participant.personId
        ? options.find(
            (option) => option?.key === participant.personId.toString()
          )
        : null
    );
  };

  handleOpen = () => {
    const { search } = this.props;
    search.setContactPageNumber(1);
    search.setDirectorySearchValue('');
    this.setSearchInput('');
    this.setState({ hasMoreData: true });
    this.handleScrollDown();
  };

  numberIsInsideOptions = () => {
    return this.state.options.some(
      (option) =>
        (option?.package === 'unknown-contact' ||
          option?.type === 'SearchableDetailsContact') &&
        this.state.selectedUsers.includes(option?.key)
    );
  };
  handleGroupCreate = () => {
    if (!this.numberIsInsideOptions()) {
      const {
        ui: { setGroupModal, groupModalOpen, setOpeningGroupModalFrom },
        conversation,
      } = this.props;
      setGroupModal(!groupModalOpen);
      conversation.setChannelInfoDetails('0', 'new', 'person');
      setOpeningGroupModalFrom('top-bar');
    }
  };

  removePossibleDuplicates = (list: DropdownItemProps[]) =>
    uniqBy(list, (item) => item.key);

  createFinalOptions = () => {
    const { searchInput, allUsersConversationsContacts } = this.state;
    const filteredOptions = allUsersConversationsContacts.filter(
      (item) =>
        item.source?.name?.includes(searchInput) ||
        item.source?.phoneNumber?.includes(searchInput) ||
        (item.firstName + ' ' + item.lastName).includes(searchInput)
    );

    const options = [
      ...this.createExtrContactOptions(
        filteredOptions.filter((item) => item instanceof Contact)
      ),
      ...this.createPersonContactConvOptions(
        filteredOptions.filter((item) => !(item instanceof Contact))
      ),
      ...this.state.options.filter((option) => option),
    ].filter((opt) => opt.text);
    return this.removePossibleDuplicates(options);
  };

  isVideoButtonDisabled = () => {
    return (
      !this.props.person.personAvaliableFeatures.video.enabled ||
      this.numberIsInsideOptions()
    );
  };

  handleVideoButtonClick = (e) => {
    const { selectedUsers } = this.state;
    if (this.isVideoButtonDisabled()) {
      return;
    }
    selectedUsers.length === 1
      ? this.placeVideoCallConferenceInner(selectedUsers[0])
      : this.createMeetingWIthMultipleUsers();
  };

  render() {
    //needed for invoking ComponentDidUpdate the ones which are grey - not in use
    const {
      person: { personAvaliableFeatures },
      testid,
    } = this.props;
    const { searchInput, selectedUsers } = this.state;
    const finalOptions = this.createFinalOptions();
    return (
      <Styled.TopBarDropdown id="top-bar-dropdown">
        <Styled.TopBarDropdownContainer className="dropdown-wrapper">
          <Styled.Dropdown
            placeholder="Search contact"
            fluid
            multiple
            search
            icon={false}
            searchQuery={searchInput}
            selection
            options={finalOptions}
            onChange={this.handleOnChangeDropdown}
            onClick={this.handleFocus}
            onSearchChange={this.onSearchInputChange}
            onOpen={this.handleOpen}
            value={selectedUsers}
            onClose={this.refreshData}
            loading={this.state.showLoader}
          />
          {selectedUsers?.length > 0 && (
            <div className="input-buttons">
              <span className="call-button">
                {selectedUsers?.length === 1 && (
                  <span
                    className="button"
                    onClick={this.makeCall({
                      personId: selectedUsers[0],
                      phoneNumbers: null,
                      keyValue: selectedUsers[0],
                    })}
                  >
                    <img src={topBarCallIcon} />
                  </span>
                )}
              </span>

              <span className="video-button">
                {selectedUsers?.length > 0 && (
                  <span
                    className={cx('button', {
                      'disabled-button cursor-not-allowed':
                        this.isVideoButtonDisabled(),
                    })}
                    onClick={this.handleVideoButtonClick}
                  >
                    <img src={topBarVideoIcon} />
                  </span>
                )}
              </span>

              <span className="group-button">
                {selectedUsers?.length > 0 && (
                  <span
                    className={cx('button', {
                      'disabled-button cursor-not-allowed':
                        this.numberIsInsideOptions(),
                    })}
                    onClick={this.handleGroupCreate}
                  >
                    <img src={groupIcon} />
                  </span>
                )}
              </span>
            </div>
          )}
          <Styled.SearchIcon icon="search" />
        </Styled.TopBarDropdownContainer>
        <DialpadIconButton {...{ testid }} />
      </Styled.TopBarDropdown>
    );
  }
}

export default inject(
  STORE_ROUTER,
  STORE_CONVERSATION,
  STORE_SEARCH,
  STORE_PERSON,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_UI,
  STORE_PHONE_CALL
)(withRouter(observer(TopBarDropdown)));
