import closeBtn from 'Assets/images/close-btn.svg';
import uploadIcon from 'Assets/images/upload.svg';
import { EMPTY_TIMEUUID, SHOW_FILE_UPLOAD } from 'Constants/env';
import {
  STORE_CONFIG,
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_UI,
} from 'Constants/stores';
import ContextContentContainer from 'Containers/ContextContent';
import ContextHeaderContainer from 'Containers/ContextHeader';
import MessageInputAreaContainer from 'Containers/MessageInputArea';
import { IUnreadMessageAndMentionCountsByConversationId } from 'Interfaces/components';
import { isEmpty, uniqBy } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FileTypeModel } from 'Models/FileType';
import { IMessageDocument } from 'Models/MessageModel';
import * as React from 'react';
import { FileDrop } from 'react-file-drop';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Button, Grid, Modal } from 'semantic-ui-react';
import withRouter from '../../hocs/WithRouter';
import { ConversationModel } from '../../models';
import AdHocGroup from '../AdHocGroup';
import { ContextPanelProps } from './interfaces';
import { ContentArea } from '../../components/shared/ContentArea';

export class ContextPanel extends React.Component<
  ContextPanelProps,
  { shiftPressed: boolean }
> {
  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      shiftPressed: false,
    };
  }
  handleCloseModal = (e) => {
    const { ui } = this.props;
    e.preventDefault();
    e.stopPropagation();
    ui.setEmojiPickerState({ open: false, editing: false });
    ui.setSelectedParticipants(null);
    ui.setHeaderDropdown(false);
    ui.setMoreActionOptions({ conversationId: '', show: false });
  };
  recentHistoryHasData = (): boolean => {
    const { conversation } = this.props;
    return !!conversation.RecentConversations;
  };

  getFirstFromHistory = (): ConversationModel => {
    const {
      conversation,
      preference: {
        preferences: { listUnreadFirst },
      },
    } = this.props;
    return conversation.RecentConversations.slice().sort((a, b) => {
      const aDate = a.lastMessageDate || a.created;
      const bDate = b.lastMessageDate || b.created;
      let sortResult = aDate <= bDate ? 1 : -1; // 1 is B before A, -1 is A before B
      if (listUnreadFirst) {
        const unreadCountsA = this.UnreadCounts[a.id];
        const unreadCountsB = this.UnreadCounts[b.id];
        const aHasUnreads =
          !isEmpty(unreadCountsA) && unreadCountsA.unreadMessages > 0;
        const bHasUnreads =
          !isEmpty(unreadCountsB) && unreadCountsB.unreadMessages > 0;
        if (aHasUnreads && !bHasUnreads) {
          sortResult = -1; // A before B
        } else if (!aHasUnreads && bHasUnreads) {
          sortResult = 1; // B before A
        } // ... Otherwise, leave the sort result alone
      }

      return sortResult;
    })[0];
  };

  @computed
  get UnreadCounts() {
    const { conversation, ui } = this.props;
    return conversation.RecentConversations.reduce<IUnreadMessageAndMentionCountsByConversationId>(
      (prev, next) => {
        prev[next.id] = ui.selectConversationUnreadCounts(next.id);
        return prev;
      },
      {}
    );
  }

  handleShiftDown = (e) => {
    if (e.keyCode === 16 || e.charCode === 16) {
      this.setState({ shiftPressed: true });
    }
  };
  handleShiftUp = (e, state?: boolean) => {
    if (e.keyCode === 16 || e.charCode === 16) {
      this.setState({ shiftPressed: false });
    } else if (state) {
      this.setState({ shiftPressed: false });
    }
  };
  handleOnDragOver = (e) => {
    const {
      ui: { showDropDownBox, setShowDropDownBox },
    } = this.props;
    !showDropDownBox.show &&
      setShowDropDownBox({ show: true, draggedOn: true });
    document.addEventListener('keydown', this.handleShiftDown);
    document.addEventListener('keyup', this.handleShiftUp);
  };

  handleOnDragLeave = () => {
    const {
      ui: { showDropDownBox, setShowDropDownBox },
    } = this.props;
    showDropDownBox.draggedOn &&
      setShowDropDownBox({ show: false, draggedOn: false });
    document.removeEventListener('keydown', this.handleShiftDown);
    document.removeEventListener('keyup', this.handleShiftUp);
    this.setState({ shiftPressed: false });
  };

  handleOnDrop = (files: FileList, event) => {
    const { message, conversation, ui } = this.props;
    const importedFiles = Array.from(files);
    ui.setShowDropDownBox({ show: false, draggedOn: false });
    const mappedFiles: FileTypeModel[] = importedFiles.map(
      (file) => new FileTypeModel(file)
    );
    ui.setDroppedFiles(uniqBy([...ui.droppedFiles, ...mappedFiles], 'name'));

    message.handleUploadToAWS(message, conversation, ui, importedFiles);
    if (this.state.shiftPressed) {
      const messageDocuments: IMessageDocument[] =
        message.createMessageDocuments();
      this.handleShiftUp(event, true);
      setTimeout(
        () =>
          message.createMessagePost(
            conversation.CurrentConversation.id,
            null,
            null,
            conversation.CurrentConversation.grouping,
            messageDocuments
          ),
        1000
      );
    }

    document.removeEventListener('keydown', this.handleShiftDown);
    document.removeEventListener('keyup', this.handleShiftUp);
  };

  handleCloseFileDrop = () => {
    this.props.ui.setShowDropDownBox({ show: false, draggedOn: false });
    document.removeEventListener('keydown', this.handleShiftDown);
    document.removeEventListener('keyup', this.handleShiftUp);
  };
  closeFileDeletePopup = () =>
    this.props.ui.setFileDeletePopup({
      show: false,
      externalId: '',
      messageId: '',
    });

  handleDeleteConfirm = () => {
    const {
      conversation,
      ui: { fileDeleteModal },
    } = this.props;
    this.props.message.editMessage(
      conversation.CurrentConversation.id,
      fileDeleteModal.messageId,
      null
    );
  };

  isRightSidebarOpened = () => {
    const { ui, person, phoneCall } = this.props;
    return (
      !phoneCall.AnyPhoneConnectionActive &&
      !ui.openTopbarDialpad &&
      !person.showPersonDetails?.id &&
      !ui.activePinnedConversationId
    );
  };

  render() {
    const {
      ui,
      person,
      conversation,
      params: { conversationId },
      ui: { wantedUrl, openTopbarDialpad },
    } = this.props;

    const largeColumns = this.isRightSidebarOpened() ? 8 : null;
    const smallColumns = this.isRightSidebarOpened() ? 4 : null;
    const url = window.location.pathname;
    const isDirectoryUrl = url === '/directory';
    const renderContextContent =
      ['/chat', '/chat/menu'].some((path) => url === path) &&
      this.recentHistoryHasData();
    const adHocGroup = url.includes('adHocGroup');
    const firstFromRecentHistroy = this.getFirstFromHistory();
    const RedirectTo =
      wantedUrl && !wantedUrl.includes('login') && wantedUrl !== '/' ? (
        <Navigate to={wantedUrl} replace />
      ) : (
        <Navigate
          replace
          to={`/chat/conversations/${firstFromRecentHistroy?.id}/menu`}
        />
      );
    return (
      !isDirectoryUrl && (
        <Grid.Column
          data-private
          id="context-panel"
          onClick={this.handleCloseModal}
          widescreen={largeColumns}
          computer={largeColumns}
          desktop={largeColumns}
          tablet={smallColumns}
          mobile={smallColumns}
          className={`flex-column maxheightpercent ${
            ui.openTopbarDialpad &&
            ui.activePinnedConversationId &&
            'sidebar-right-margin'
          }`}
        >
          <ContentArea>
            {person.IsLoggedIn ? (
              <>
                {url.includes('conversations') && SHOW_FILE_UPLOAD && (
                  //@ts-ignore
                  <FileDrop
                    className={
                      ui.showDropDownBox.show ? 'z-index-file-target' : ''
                    }
                    onFrameDragEnter={this.handleOnDragOver}
                    onDragLeave={this.handleOnDragLeave}
                    onDrop={this.handleOnDrop}
                    onTargetClick={this.handleCloseFileDrop}
                  >
                    {ui.showDropDownBox.show && (
                      <div
                        className={
                          ui.showDropDownBox.draggedOn ? 'drag-drop-file' : ''
                        }
                      >
                        <img src={uploadIcon} />
                        <span className="receiver">
                          {conversation.ownerOfTheChat}
                        </span>
                        <span>You can add comments before uploading.</span>
                        <span>Hold shift to share immediately.</span>
                      </div>
                    )}
                  </FileDrop>
                )}
                {ui.fileDeleteModal && (
                  <Modal
                    open={ui.fileDeleteModal.show}
                    size="small"
                    id="confirmation-delete-modal"
                    dimmer="blurring"
                    closeOnDimmerClick={true}
                    centered={true}
                    onClose={this.closeFileDeletePopup}
                    className="confirmation-modal"
                  >
                    <img
                      className="close-btn"
                      src={closeBtn}
                      onClick={this.closeFileDeletePopup}
                    />
                    <div className="flex-row">
                      Are you sure you want to delete this file?
                    </div>
                    <div className="flex-row">
                      <Button
                        primary
                        content="CANCEL"
                        onClick={this.closeFileDeletePopup}
                      />
                      <Button
                        secondary
                        content="DELETE"
                        onClick={this.handleDeleteConfirm}
                      />
                    </div>
                  </Modal>
                )}

                {renderContextContent && firstFromRecentHistroy ? (
                  RedirectTo
                ) : adHocGroup && conversation.adHocGroupParticipants ? (
                  <>
                    <AdHocGroup
                      conversation={conversation}
                      person={person}
                      ui={this.props.ui}
                    />
                    <MessageInputAreaContainer {...this.props} />
                  </>
                ) : (
                  <>
                    {conversationId === EMPTY_TIMEUUID && !wantedUrl ? (
                      <Navigate to="/chat/*" />
                    ) : null}
                    <Routes>
                      <Route
                        path="/conversations/:conversationId/*"
                        element={
                          <>
                            <ContextHeaderContainer {...this.props} />
                            <ContextContentContainer {...this.props} />
                            <MessageInputAreaContainer {...this.props} />
                          </>
                        }
                      />
                    </Routes>
                  </>
                )}
              </>
            ) : null}
          </ContentArea>
        </Grid.Column>
      )
    );
  }
}

export default inject(
  STORE_CONTACT,
  STORE_PARTICIPANT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_CONFIG,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_PREFERENCE,
  STORE_UI,
  STORE_MESSAGE,
  STORE_SEARCH
)(withRouter(observer(ContextPanel)));
