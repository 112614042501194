import {
  ACTIVE_CALL,
  SELECTED_MICROPHONE,
  SELECTED_SPEAKER,
} from 'Constants/localstorage';
import { EventEmitter } from 'events';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import * as localforage from 'localforage';
import { isString } from 'lodash';
import {
  action,
  computed,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';
import moment from 'moment-timezone';
import {
  URI,
  Web,
  Invitation,
  InvitationAcceptOptions,
  Inviter,
  InviterInviteOptions,
  Session,
  SessionState,
  UserAgent,
} from 'sip.js';
import { PhoneStore } from 'Stores/PhoneStore';
import { RootStore } from 'Stores/RootStore';
import { isNullOrUndefined } from 'util';
import { stopMediaStream } from '../utils/streamUtils';
import { CALL_LOG_DISPOSITION } from '../constants/enums';
import { CALL_PERSIST, TIMEZONE_IDENTIFIER } from '../constants/env';
import { PersonModel } from '../models';
import { pushToGTMDataLayer } from '../utils/analytics';
import { logError } from '../utils/phoneLogUtils';
import phoneUtil from '../utils/phoneUtil';
import { ContactModel } from './ContactModel';

/**
 *
 * Events
 * - `terminated`: `() => void`
 */
export class PhoneCallModel extends EventEmitter {
  constructor(
    rootStore: RootStore,
    phoneStore: PhoneStore,
    loggedInCallerId: any,
    loggedInAccountId: number,
    loggedInPersonId: number,
    session?: Session,
    phoneUri?: URI,
    person?: IPromiseBasedObservable<AxiosResponseT<PersonModel>>,
    contact?: IPromiseBasedObservable<AxiosResponseT<ContactModel>>,
    remoteAudio?: HTMLAudioElement,
    ringTone?: HTMLAudioElement,
    incomingTone?: HTMLAudioElement,
    secondIncomingTone?: HTMLAudioElement,
    isWarmTransfer?: boolean,
    isMerge?: boolean,
    conferenceId?: string,
    handleAccept?: any,
    callBack?: any
  ) {
    super();
    makeObservable(this);
    this.phoneStore = phoneStore;
    this.rootStore = rootStore;
    this.configSession(session);
    this.setLoggedInUserCallerId(loggedInCallerId);
    this.setLoggedInAccountId(loggedInAccountId);
    this.setLoggedInPersonId(loggedInPersonId);
    this.setPhoneURI(phoneUri);
    this.setPerson(person);
    this.setContact(contact);
    this.setIsCallConnecting(true);
    this.remoteAudio = remoteAudio;
    this.ringtone = ringTone;
    this.incomingTone = incomingTone;
    this.secondIncomingTone = secondIncomingTone;
    this.setWarmTransfer(isWarmTransfer);
    this.setMergeTransfer(isMerge);
    this.setConferenceId(conferenceId);
    this.handleAccept = handleAccept;
    this.callBack = callBack;
  }

  /** Configures the `Session` and assigns it to `this.session` */
  public configSession = (s: Session) => {
    if (s !== null) {
      const inviteOptions: InviterInviteOptions = {
        requestDelegate: {
          onAccept: (resp) => {
            this.handleOnAccept();
            if (this.callBack) {
              this.callBack(this.phoneStore.phoneCalls);
            }
          },
          onReject: (reason) => {
            logError(
              reason,
              'Error on SIP invitation, triggered event onReject',
              'PhoneCallModel',
              'configSession'
            );
          },
          onProgress: (response) => {
            this.startOfCall = moment.utc().format().toString();
            const activeConference =
              !isNullOrUndefined(this.rootStore?.conversationStore) &&
              this.rootStore.uiStore.IsOnVideoConference;
            if (response.message.statusCode === 183 && response.message.body) {
              if (
                this.phoneStore?.phoneCalls?.length <= 1 &&
                !activeConference
              ) {
                this.ringtonePlayPromise = this.ringtone?.play();
                this.ringtone.volume = 1.0;
              } else if (activeConference) {
                this.pauseAndResetPlayPromise(
                  this.ringtone,
                  this.ringtonePlayPromise
                );
                this.ringtone.volume = 0;
              } else {
                this.ringtonePlayPromise = this.ringtone?.play();
                this.ringtone.volume = 0.05;
              }
            } else if (response.message.statusCode === 180) {
              this.ringtonePlayPromise = this.ringtone?.play();
              this.ringtone.volume = 1.0;
            } else {
              this.pauseAndResetPlayPromise(
                this.ringtone,
                this.ringtonePlayPromise
              );
            }
          },
        },
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: false,
          },
        },
      };
      s.stateChange.addListener((newState: SessionState) => {
        switch (newState) {
          case SessionState.Terminated:
            // Uncomment code bellow in case local call logs get enabled

            // if (this.isCallMissed())
            //   this.updateLocalCallLog(CALL_LOG_DISPOSITION.MISSED);
            // if (this.pickUpTime)
            //   this.updateLocalCallLog(CALL_LOG_DISPOSITION.COMPLETED);
            // Optionally persist call by skipping these steps if the flag is true and call is incoming, for testing purposes only.
            if (!CALL_PERSIST || !this.isIncomingCall) {
              if (this.isWarmTransfer) {
                this.phoneStore.warmTransferOld.setTransferMode(true);
              }
              localforage.removeItem(ACTIVE_CALL);
              this.clearKeyPress();
              this.setShowDialPad(false);
              this.muteIconState('microphone');
              this.setIsCallMuted(false);
              this.onHoldIconState('pause circle outline');
              this.setIsCallOnHold(false);
              this.setIsMediaConnecting(false);
              this.setIsCallConnected(false);
              this.setIsCallConnecting(false);
              this.setIncomingCallValue(false);
              this.setIsMediaConnected(false);
              this.pauseAndResetPlayPromise(
                this.ringtone,
                this.ringtonePlayPromise
              );
              this.pauseAndResetPlayPromise(
                this.incomingTone,
                this.incomingTonePlayPromise
              );
              this.pauseAndResetPlayPromise(
                this.secondIncomingTone,
                this.secondIncomingTonePlayPromise
              );
              this.clearPersonAndContact();
              this.resetDisplay();
              this.phoneStore.removeFromPhoneCallsArray(s);
              this.session = null;
              this.emit('terminated');
            } else {
              console.warn(
                `PhoneCallModel session termination skipping cleanup due to CALL_PERSIST! You must refresh the page to remove the incoming call!`
              );
              // Clean up ringtone anyways so it doesn't keep playing while testing
              this.pauseAndResetPlayPromise(
                this.ringtone,
                this.ringtonePlayPromise
              );
              this.pauseAndResetPlayPromise(
                this.incomingTone,
                this.incomingTonePlayPromise
              );
              this.pauseAndResetPlayPromise(
                this.secondIncomingTone,
                this.secondIncomingTonePlayPromise
              );
            }

            if (!CALL_PERSIST) {
              runInAction(() =>
                this.phoneStore.incomingPhoneCalls.remove(this)
              );
            } else {
              console.warn(
                `CALL_PERSIST enabled, call with URI ${this.callUri} will NOT be removed incoming calls! You must refresh the page to remove the incoming call!`
              );
            }
            this.closeCurrentStreams();
            break;
          case SessionState.Established:
            this.handleOnAccept();
            break;
        }
      });

      this.session = s;
      try {
        if (this.session instanceof Inviter && this.session.invite) {
          this.session.invite(inviteOptions);
        }
      } catch (err) {}
    } else {
      logError(null, 'There is NO SESSION', 'PhoneCallModel', 'configSession');
    }
  };
  public ringtonePlayPromise: Promise<void> = null;
  public incomingTonePlayPromise: Promise<void> = null;
  public secondIncomingTonePlayPromise: Promise<void> = null;
  public handleAccept: any;
  public callBack: any;
  public localMediaPlayPromise: Promise<void> = null;
  public remoteMediaPlayPromise: Promise<void> = null;
  /**
   * If available, awaits the `Promise` returned from `HTMLAudioElement.play()`, then pauses and resets the `audioElem`.
   *
   * Optionally, a `newMediaStream` can be provided to replace the `srcObject` of the `HTMLAudioElement`
   *
   * Returns a Promise containing `true` if the `Promise` was available and the `audioElem` was paused, `false` if it was not available and the `audioElem` was paused, or `null` if both were null/undefined.
   */
  public pauseAndResetPlayPromise = (
    audioElem: HTMLAudioElement,
    playPromise: Promise<void>,
    newMediaStream: MediaStream = null
  ): Promise<boolean | null> => {
    if (!isNullOrUndefined(audioElem)) {
      if (playPromise !== null) {
        return playPromise.then(() => {
          audioElem.pause();
          if (newMediaStream !== null) {
            audioElem.srcObject = newMediaStream;
          }
          audioElem.currentTime = 0;
          return true;
        });
      }
      // If promise isn't available, synchronously reset
      audioElem.pause();
      if (newMediaStream !== null) {
        audioElem.srcObject = newMediaStream;
      }
      audioElem.currentTime = 0;
      return Promise.resolve(false);
    }

    return Promise.resolve(null);
  };

  public closeCurrentStreams = () => {
    const noCalls = this.phoneStore.phoneCalls?.length === 0;
    const allTones = [
      this?.ringtone?.srcObject,
      this?.incomingTone?.srcObject,
      this?.secondIncomingTone?.srcObject,
    ].filter(Boolean);
    //@ts-ignore
    noCalls && window.localStream && allTones.push(window.localStream);
    allTones.length > 0 &&
      allTones.forEach((mediaStream: MediaStream) =>
        stopMediaStream(mediaStream)
      );
  };

  // * ---------- OBSERVABLES ---------- *
  @observable
  public isCallConnected: boolean = false;

  @observable
  public phoneNumber: string = '';

  @observable
  public callUri: string = null;

  @observable
  public conferenceId: string = '';

  @observable
  public currentLoginPhoneNumber: string = '000-000-0000';

  @observable
  public currentUserCallerId: string;

  @observable
  public isCallConnecting: boolean;

  @observable
  public timerDisplay: string = '00:00:00';

  @observable
  public startTime: number;

  @observable
  public elapsedTime: number;

  @observable
  public isMediaConnecting: boolean;

  @observable
  public showDialPad: boolean = false;

  @observable
  public loggedInUserAccountId: number;

  @observable
  public isMediaConnected: boolean;

  @observable
  public isIncomingCall: boolean;

  @observable
  public isCallMuted: boolean;

  @observable
  public isCallOnHold: boolean = false;

  @observable
  public isCallCanceled: boolean = false;

  @observable
  public remoteAudio: HTMLAudioElement;

  @observable
  public loggedInPersonId: number;

  public phoneStore: PhoneStore;

  public rootStore: RootStore;

  @observable
  keypadNumber = [];

  @observable
  public session: Session;

  @observable
  public ringtone: HTMLAudioElement;

  @observable
  public startOfCall: string = null;

  @observable
  public pickUpTime: string = null;
  @observable
  public incomingTone: HTMLAudioElement;

  @observable
  public secondIncomingTone: HTMLAudioElement;

  @observable
  public keyPressTone0: HTMLAudioElement;
  @observable
  public keyPressTone1: HTMLAudioElement;
  @observable
  public keyPressTone2: HTMLAudioElement;
  @observable
  public keyPressTone3: HTMLAudioElement;
  @observable
  public keyPressTone4: HTMLAudioElement;
  @observable
  public keyPressTone5: HTMLAudioElement;
  @observable
  public keyPressTone6: HTMLAudioElement;
  @observable
  public keyPressTone7: HTMLAudioElement;
  @observable
  public keyPressTone8: HTMLAudioElement;
  @observable
  public keyPressTone9: HTMLAudioElement;
  @observable
  public keyPressTonePound: HTMLAudioElement;
  @observable
  public keyPressToneStar: HTMLAudioElement;

  @observable
  public person: IPromiseBasedObservable<AxiosResponseT<PersonModel>> = null;
  @observable
  public contact: IPromiseBasedObservable<AxiosResponseT<ContactModel>> = null;

  @observable
  public callerIdName: string;
  @action
  public setCallerIdName = (callerIdName: string) => {
    this.callerIdName = callerIdName;
  };

  @action
  public setContact = (
    contact: IPromiseBasedObservable<AxiosResponseT<ContactModel>>
  ) => {
    if (!isNullOrUndefined(contact)) {
      this.contact = fromPromise(contact);
    }
  };
  @action
  public setPerson = (
    person: IPromiseBasedObservable<AxiosResponseT<PersonModel>>
  ) => {
    if (!isNullOrUndefined(person)) {
      this.person = fromPromise(person);
    }
  };

  @action
  setIncomingCallValue = (call: boolean) => (this.isIncomingCall = call);

  @action
  setLoggedInUserCallerId = (loggedInUserCallerId: string) =>
    (this.currentUserCallerId = loggedInUserCallerId);

  @action
  setLoggedInAccountId = (loggedInAccountId: number) =>
    (this.loggedInUserAccountId = loggedInAccountId);

  @action
  setLoggedInPersonId = (loggedInPersonId: number) =>
    (this.loggedInPersonId = loggedInPersonId);

  @action
  confirmExit(e) {
    const confirmationMessage =
      'You are currently on a call, exiting will disconnect the call. Do you wish to continue?';
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  }
  @action
  startTimer() {
    this.startTime = Date.now();
    this.elapsedTime = 0;
    this.updateTime();
    const interval = setInterval(() => {
      if (this.isCallConnected) {
        this.updateTime();
      } else {
        clearInterval(interval);
      }
    }, 1000);
  }

  @action
  public dialPadNumDtmf(dialDigit: string) {
    switch (dialDigit) {
      case '0':
        this.keyPressTone0.play();
        break;
      case '1':
        this.keyPressTone1.play();
        break;
      case '2':
        this.keyPressTone2.play();
        break;
      case '3':
        this.keyPressTone3.play();
        break;
      case '4':
        this.keyPressTone4.play();
        break;
      case '5':
        this.keyPressTone5.play();
        break;
      case '6':
        this.keyPressTone6.play();
        break;
      case '7':
        this.keyPressTone7.play();
        break;
      case '8':
        this.keyPressTone8.play();
        break;
      case '9':
        this.keyPressTone9.play();
        break;
      case '*':
        this.keyPressToneStar.play();
        break;
      case '#':
        this.keyPressTonePound.play();
        break;
    }
    this.sendDtmf(dialDigit);
  }

  sendDtmf = (dialDigit: string) =>
    this.session.sessionDescriptionHandler.sendDtmf(dialDigit);

  @action
  updateTime() {
    const time = Date.now() - this.startTime;
    this.startTime = Date.now();
    this.elapsedTime += time;
    let tempTime = this.elapsedTime;
    const milliseconds = tempTime % 1000;
    tempTime = Math.floor(tempTime / 1000);
    const seconds = tempTime % 60;
    tempTime = Math.floor(tempTime / 60);
    const minutes = tempTime % 60;
    tempTime = Math.floor(tempTime / 60);
    const hours = tempTime % 60;
    const hourString = hours <= 9 ? '0' + hours : hours.toString();
    const minutesString = minutes <= 9 ? '0' + minutes : minutes.toString();
    const secondsString = seconds <= 9 ? '0' + seconds : seconds.toString();
    this.timerDisplay =
      hourString === '00'
        ? minutesString + ' : ' + secondsString
        : hourString + ' : ' + minutesString + ' : ' + secondsString;
  }

  @action
  resetDisplay() {
    this.timerDisplay = '00:00';
    this.startTime = 0;
    this.elapsedTime = 0;
    this.startOfCall = null;
    this.pickUpTime = null;
  }

  @action
  setIsCallConnected(call: boolean) {
    this.isCallConnected = call;
    const momentInst = moment().tz(TIMEZONE_IDENTIFIER).utc();
    if (call) {
      this.pickUpTime = momentInst.format().toString();
      if (window.addEventListener) {
        (window as any).addEventListener('beforeunload', this.confirmExit);
      }
    } else {
      if ((window as any).removeEventListener) {
        (window as any).removeEventListener('beforeunload', this.confirmExit);
      }
    }
  }

  @action
  public reject() {
    this.pauseAndResetPlayPromise(this.ringtone, this.ringtonePlayPromise);
    this.isCallCanceled = true;
    // Uncomment code bellow in case local call logs get enabled
    // this.updateLocalCallLog(CALL_LOG_DISPOSITION.CANCEL);
    (this.session as Invitation).reject({ statusCode: 603 });
  }

  @action
  public cancel() {
    this.pauseAndResetPlayPromise(this.ringtone, this.ringtonePlayPromise);
    if (this.isCallConnecting) {
      this.clearKeyPress();
      this.setShowDialPad(false);
      this.muteIconState('microphone');
      this.setIsCallMuted(false);
      this.onHoldIconState('pause circle outline');
      this.setIsCallOnHold(false);
      this.setIsMediaConnecting(false);
      this.setIsCallConnected(false);
      this.setIsCallConnecting(false);
      this.setIsMediaConnected(false);
      this.pauseAndResetPlayPromise(this.ringtone, this.ringtonePlayPromise);
      this.clearPersonAndContact();
      this.resetDisplay();
    }
    if (this.session instanceof Inviter) {
      this.isCallCanceled = true;
      // Uncomment code bellow in case local call logs get enabled
      // this.updateLocalCallLog(CALL_LOG_DISPOSITION.CANCEL);
      this.session.cancel();
    }
    this.closeCurrentStreams();
  }

  /**
   * When the Client recieve a Call they can Pickup.
   * We setup Early media `options: ServerContextAcceptOptions`, but not render it until the call is accepted
   * Sesion has Event called `accept(options?: ServerContextAcceptOptions): Session` this is when the audio gets rendered;
   */
  @action
  public pickup = async () => {
    await this.phoneStore.refreshMicrophonePermissionStatus('incoming');
    if (this.phoneStore.browserMicrophonePermissionState === 'denied') {
      return;
    }
    this.pauseAndResetPlayPromise(
      this.incomingTone,
      this.incomingTonePlayPromise
    );
    const options: InvitationAcceptOptions = {
      sessionDescriptionHandlerOptions: {
        // iceCheckingTimeout: 5000, in previous SIP version we had this one, on new version its not supported like this. Remove if +2months and all good 1.4.2022
        constraints: {
          audio: {
            audio: {
              deviceId: this.phoneStore.microphoneId
                ? { exact: this.phoneStore.microphoneId }
                : 'default',
            },
          },
          video: false,
        },
      },
    };

    if (this.session === undefined) {
      return;
    }
    const activeConference =
      !isNullOrUndefined(this.rootStore.conversationStore) &&
      this.rootStore.uiStore.IsOnVideoConference;
    if (activeConference) {
      localforage.setItem<string>(ACTIVE_CALL, this.session.id);
    }
    this.setIsCallConnected(true);
    this.setIncomingCall(true);
    const index = this.phoneStore.incomingPhoneCalls.findIndex(
      (item) => item.session.id === this.session.id
    );
    this.phoneStore.incomingPhoneCalls.splice(index, 1);
    this.phoneStore.setPhoneCalls(this);
    this.phoneStore.setOtherSessionsOnHold(this.session);
    (this.session as Invitation).accept(options);
    this.phoneStore.configureMicrophone(this.phoneStore.microphoneId);
    this.phoneStore.configureSpeaker(this.phoneStore.speakerId);
    this.phoneStore.configureRingTone(this.phoneStore.ringtoneSpeakerId);
    return;
  };

  handleOnAccept() {
    this.setIsCallConnecting(false);
    this.setIsCallConnected(true);
    this.setIsMediaConnected(true);
    this.startTimer();
    this.pauseAndResetPlayPromise(this.ringtone, this.ringtonePlayPromise);
    this.pauseAndResetPlayPromise(
      this.incomingTone,
      this.incomingTonePlayPromise
    );
    this.pauseAndResetPlayPromise(
      this.secondIncomingTone,
      this.secondIncomingTonePlayPromise
    );
    this.setupLocalMedia();
    this.setupRemoteMedia();
    const activeConference =
      !isNullOrUndefined(this.rootStore.conversationStore) &&
      this.rootStore.uiStore.IsOnVideoConference;
    if (activeConference) {
      localforage.setItem<string>(ACTIVE_CALL, this.session.id);
    }
  }

  isCallMissed = () =>
    this.isIncomingCall && !this.isCallCanceled && !this.pickUpTime;

  updateLocalCallLog = (disposition: CALL_LOG_DISPOSITION) => {
    this.rootStore.callLogsStore.updateCallLogBySessionId(
      this.session.id,
      disposition,
      disposition === CALL_LOG_DISPOSITION.COMPLETED ? this : null
    );
  };
  /**
   * Target address should be string to look like  var target = `test2@example.onsip.com`;
   * Making a Blind Transfer session.refer(target)
   */
  @action
  blindTransfer = (phoneNumber: string | number) => {
    pushToGTMDataLayer('transferCall', {
      callTransferType: 'Blind',
    });
    let personIdURI: string = null;
    personIdURI = phoneNumber.toString();
    if (this.session && this.session.state === 'Established') {
      if (
        this.rootStore.configStore.signedInPersonConfig.state === 'fulfilled'
      ) {
        const removeAllButNumbers = ['pr', 'room'].some((strings) =>
          personIdURI.includes(strings)
        )
          ? personIdURI
          : personIdURI.replace(/[^0-9+*]/g, '');
        const url =
          'sip:' +
          removeAllButNumbers +
          '@' +
          this.rootStore.configStore.signedInPersonConfig.value.data.webRtc
            .domain;
        const uri = UserAgent.makeURI(url.replace(/\s/g, ''));
        this.session.refer(uri);
        pushToGTMDataLayer('transferCall', {
          callTransferType: 'Blind',
        });
      } else {
        logError(
          null,
          'The Domain address is not accessible, user not logged in or pending state!',
          'PhoneCallModel',
          'blindTransfer'
        );
      }
    } else {
      logError(
        null,
        'The Call is not Active right now, blind transfer did nothing!',
        'PhoneCallModel',
        'blindTransfer'
      );
    }
  };

  /**
   * Target straight to VoiceMail `Sip:*`
   */

  @action
  forwardToVoiceMail = (extension: string | number) => {
    let personIdURI: string = null;
    personIdURI = extension.toString();
    if (this.rootStore.configStore.signedInPersonConfig.state === 'fulfilled') {
      pushToGTMDataLayer('transferCall', {
        callTransferType: 'VoiceMail',
      });
      const url =
        'sip:*' +
        personIdURI +
        '@' +
        this.rootStore.configStore.signedInPersonConfig.value.data.webRtc
          .domain;
      const uri = UserAgent.makeURI(url.replace(/\s/g, ''));
      this.session.refer(uri);
    }
  };

  /**
   * initiates the call to the third Party for WarmTransfer if the client accepts it.
   * @param phoneNumber is the target Number
   */
  @action
  warmTransfer(phoneNumber: string | number) {
    this.phoneStore.setWarmTransferOld(this);
    pushToGTMDataLayer('transferCall', {
      callTransferType: 'Warm',
    });
    if (isString(phoneNumber) && phoneNumber.indexOf('pr') === 0) {
      this.phoneStore.callWithPerson(
        parseInt(phoneNumber.substring(2)),
        null,
        true
      );
    } else {
      this.phoneStore.callWithPerson(null, phoneNumber.toString(), true);
    }
  }

  @action
  forwardWarmTransfer() {
    this.session.refer(this.phoneStore.warmTransferOld.session);
  }

  /**
   * Formatting the Entered PhoneNumber to what we need. We are using PhoneUtil Library
   */
  @computed
  get FormattedNumber() {
    // short Code Support
    if (!this.phoneNumber) {
      return;
    } else {
      if (
        (this.phoneNumber.length >= 3 && this.phoneNumber.length <= 5) ||
        this.phoneNumber.startsWith('011')
      ) {
        return this.phoneNumber;
      }
      try {
        if (phoneUtil.isPossibleNumberString(this.phoneNumber, 'US')) {
          const parsePhoneNumber = phoneUtil.parse(this.phoneNumber, 'US');
          return phoneUtil.format(parsePhoneNumber, PhoneNumberFormat.NATIONAL);
        } else {
          console.error('Invalid Phone Number', this.phoneNumber);
          return '';
        }
      } catch (err) {
        logError(
          err,
          'Invalid phone number, failed on isPossibleNumberString',
          'PhoneCallModel',
          'FormattedNumber'
        );
      }
    }
  }

  /**
   * Just before the call connects we set it up
   */
  @action
  setIsCallConnecting(call: boolean) {
    this.isCallConnecting = call;
  }

  @action
  setIsMediaConnecting(call: boolean) {
    this.isMediaConnecting = call;
  }

  @action
  setIsMediaConnected(call: boolean) {
    this.isMediaConnected = call;
  }

  @observable
  public keysPressed: string = '';

  @action
  public clearKeyPress = () => (this.keysPressed = '');
  @observable
  public muteIcon: string = 'microphone';

  @observable
  public onHoldIcon: string = 'pause circle outline';

  @observable
  public callQueue = observable.map<PhoneCallModel>();

  public localValueForIncoming: boolean;

  @observable
  public transferMode: boolean = false;

  @action
  setTransferMode = (transferMode: boolean) => {
    console.debug('setTransferMode', transferMode);
    this.transferMode = transferMode;
  };

  @action
  public setIsCallMuted = (isCallMuted: boolean) =>
    (this.isCallMuted = isCallMuted);

  @action
  public setIsCallOnHold = (isCallOnHold: boolean) =>
    (this.isCallOnHold = isCallOnHold);

  @action
  public keysPress = (keyPress: string) =>
    (this.keysPressed = this.keysPressed + keyPress);
  @action
  public muteIconState = (muteIcon: string) => (this.muteIcon = muteIcon);
  @action
  public onHoldIconState = (onHoldIcon: string) =>
    (this.onHoldIcon = onHoldIcon);

  @action
  setShowDialPad = (showDialPad: boolean) => (this.showDialPad = showDialPad);

  @observable
  public isWarmTransfer: boolean = false;

  @action
  public setWarmTransfer = (isWarmTransfer: boolean) =>
    (this.isWarmTransfer = isWarmTransfer);

  @observable
  public isMerge: boolean = false;

  @action
  public setMergeTransfer = (isMerge: boolean) => (this.isMerge = isMerge);

  @action
  public setConferenceId = (conferenceId: string) =>
    (this.conferenceId = conferenceId);

  @action
  setIncomingCall(call: boolean) {
    this.isIncomingCall = call;
    this.localValueForIncoming = call;
  }

  @action
  public toggleDialPad = () => {
    this.setShowDialPad(!this.showDialPad);
  };

  @observable
  onHoldSession: Session = null;

  @action
  setHoldSession = (onHoldSession: Session) =>
    (this.onHoldSession = onHoldSession);

  @observable
  public mediaStream: MediaConstraints;

  @action
  setPhoneNumber(num: string) {
    this.phoneNumber = num;
  }

  @action
  setPhoneURI(num: URI) {
    this.callUri = num?.toString();
  }
  /**
   * Session needs to be Muted
   */

  @action
  public mute() {
    this.isCallMuted = true;
    //@ts-ignore
    const pc = this.session.sessionDescriptionHandler.peerConnection;
    const togglemute = this.isCallMuted;
    if (pc.getSenders) {
      pc.getSenders().forEach(function (sender) {
        if (sender.track) {
          // track.muted
          sender.track.enabled = !togglemute;
        }
      });
    } else {
      pc.getReceivers().forEach(function (receiver) {
        if (receiver.track) {
          receiver.track.enabled = !togglemute;
        }
      });
    }
    pushToGTMDataLayer('callMute');
  }
  /**
   * Session needs to be unMuted
   */
  @action
  public unMute() {
    this.isCallMuted = false;
    //@ts-ignore
    const pc = this.session.sessionDescriptionHandler.peerConnection;
    const togglemute = this.isCallMuted;
    if (pc.getSenders) {
      pc.getSenders().forEach(function (sender) {
        if (sender.track) {
          sender.track.enabled = !togglemute;
        }
      });
    } else {
      pc.getReceivers().forEach(function (receiver) {
        if (receiver.track) {
          receiver.track.enabled = !togglemute;
        }
      });
    }
    localforage.getItem<string>(SELECTED_MICROPHONE).then((mc) => {
      mc && this.phoneStore.configureMicrophone(mc);
    });
    localforage.getItem<string>(SELECTED_SPEAKER).then((sp) => {
      sp && this.phoneStore.configureSpeaker(sp);
    });
  }
  /**
   * Session needs to be hold. SipJS version 0.11.xx requires to mute the user after they Hold
   */
  @action
  public hold() {
    this.isCallOnHold = true;
    localforage.removeItem(ACTIVE_CALL);
    pushToGTMDataLayer('holdCall');
    return this.session.invite({
      sessionDescriptionHandlerModifiers: [Web.holdModifier],
    });
  }
  /**
   * Session needs to be unhold. SipJS version 0.11.xx requires to unmute the user after they unHold
   */
  @action
  public unHold() {
    this.isCallOnHold = false;
    const activeConference =
      !isNullOrUndefined(this.rootStore.conversationStore) &&
      this.rootStore.uiStore.IsOnVideoConference;
    if (activeConference) {
      localforage.setItem<string>(ACTIVE_CALL, this.session.id);
    }
    this.setupRemoteMedia();
    return this.session.invite({
      sessionDescriptionHandlerModifiers: [],
    });
  }
  /**
   * The session needs to be terminated use the hangup
   */
  @action
  public hangUp() {
    this.pauseAndResetPlayPromise(this.ringtone, this.ringtonePlayPromise);
    this.session.bye();
    this.closeCurrentStreams();
  }

  public mediaConstraints: any = {
    audio: true,
    video: false,
  };

  @action
  clearPersonAndContact = () => {
    this.person = null;
    this.contact = null;
  };
  /**
   * setting up remote audio for the client side
   */
  setupLocalMedia = () => {
    //@ts-ignore
    const pc = this.session.sessionDescriptionHandler.peerConnection;
    const localStream = new MediaStream();
    if (this.remoteAudio) {
      pc.getSenders().forEach((sender) => {
        const track = sender.track;
        if (track && track.kind === 'audio') {
          localStream.addTrack(track);
        }
      });
      this.pauseAndResetPlayPromise(
        this.remoteAudio,
        this.localMediaPlayPromise,
        localStream
      ).then((wp) => {
        this.localMediaPlayPromise = this.remoteAudio.play();
      });
    }
    //@ts-ignore
    window.localStream2 = localStream;
  };
  /**
   * setting up remote audio for the destination
   */
  setupRemoteMedia = () => {
    //@ts-ignore
    const pc = this.session.sessionDescriptionHandler.peerConnection;
    let remoteStream = new MediaStream();
    if (pc.getReceivers) {
      pc.getReceivers().map((reciever) => {
        const track = reciever.track;
        if (track) {
          remoteStream.addTrack(track);
        } else {
          remoteStream = (pc as any).getRemoteStreams()[0];
        }
      });
      this.pauseAndResetPlayPromise(
        this.remoteAudio,
        this.remoteMediaPlayPromise,
        remoteStream
      ).then((wp) => {
        this.remoteMediaPlayPromise = this.remoteAudio.play();
      });
    } else {
      console.warn('Reciver doesnt have an Audio');
    }
    //@ts-ignore
    window.remoteStream = remoteStream;
  };
}

export default PhoneCallModel;
