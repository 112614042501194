import * as React from 'react';

import { IconButton } from '../shared/IconButton';
import type { AddToFavoritesButtonProps } from './AddToFavoritesButton.types';

export const AddToFavoritesButton: React.FC<AddToFavoritesButtonProps> = ({
  isFavorite,
  popupPosition,
  size,
  testid,
  conversation,
  conversationId,
}) => (
  <IconButton
    onClick={() =>
      isFavorite
        ? conversation.removeConversationFromFavoritesPatch(conversationId)
        : conversation.addConversationToFavoritesPatch(conversationId)
    }
    content={`${isFavorite ? 'Remove from' : 'Add to'} Favorites`}
    icon={isFavorite ? 'star-filled' : 'star'}
    testid={`${testid}-buttonAddToFavorites`}
    {...{ popupPosition, size }}
  />
);
