import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import localforage from 'localforage';
import { debounce, max, min } from 'lodash';
import {
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_UI,
} from '../../../../constants/stores';
import { SoftphoneProps, SoftphoneLocalConfigProps } from './types';
import TopBarDialpad from '../../../../components/TopBarDialpad';
import CallSidebar from 'Containers/CallSidebar';
import { FloatingWrapper } from '../../../../components/shared/FloatingWrapper';
import SoftphoneAudios from '../SoftphoneAudios';
import { DraggableEvent } from 'react-draggable';
import { DraggableData, Position, ResizableDelta } from 'react-rnd';
import { SOFTPHONE_DIMENSIONS_POSITION } from '../../../../constants/localstorage';

const Softphone = (props: SoftphoneProps) => {
  const [position, setPosition] = useState({
    x: window.innerWidth - 310 - 10,
    y: 80,
  });
  const [dimensions, setDimensions] = useState({
    width: 310,
    height: 550,
  });
  const {
    ui: {
      openTopbarDialpad,
      setOpenTopbarDialpad,
      openedRightSidebarsOrder,
      removeFromOpenedRightSidebarsOrder,
      IsCallSidebarFixed,
    },
    conversation: { listOfIncomingVideoCalls },
    phoneCall: {
      ActivePhoneCall,
      incomingPhoneCalls,
      AnyPhoneConnectionActive,
      isTenSeconds,
    },
    preference: {
      preferences: { floatingSoftphone: isSoftphoneFixed },
    },
  } = props;

  useEffect(() => {
    (async () => {
      const res = await localforage.getItem<SoftphoneLocalConfigProps>(
        SOFTPHONE_DIMENSIONS_POSITION
      );
      if (!res) return;

      const wWidth = window.innerWidth;
      const wHeight = window.innerWidth;
      const {
        dimensions: { width, height },
        position: { x, y },
      } = res;
      let newWidth = width > wWidth ? dimensions.width : width;
      let newHeight = height > wHeight ? dimensions.height : height;
      let newPositionX = position.x;
      let newPositionY = position.y;
      newPositionX = max([x + newWidth > wWidth ? position.x : x, 0]);
      newPositionY = max([y + newHeight > wHeight ? position.y : y, , -70]);
      setPosition({
        x: newPositionX,
        y: newPositionY,
      });
      setDimensions({ height: newHeight, width: newWidth });
    })();

    const handleWindowResize = async () => {
      const { innerWidth, innerHeight } = window;
      const { position, dimensions } =
        await localforage.getItem<SoftphoneLocalConfigProps>(
          SOFTPHONE_DIMENSIONS_POSITION
        );
      let newPositionX = position.x;
      let newPositionY = position.y;

      if (position.x + dimensions.width > innerWidth) {
        newPositionX = innerWidth - dimensions.width;
      }
      if (position.y + dimensions.height > innerHeight) {
        newPositionY = innerHeight - dimensions.height;
      }
      newPositionX = max([newPositionX, 0]);
      newPositionY = max([newPositionY, -70]);
      const bottomEdge = newPositionY + dimensions.height + 70;
      if (bottomEdge > innerHeight) {
        const diff = bottomEdge - innerHeight;
        dimensions.height -= diff;
        setDimensions(dimensions);
      }
      setPosition({
        x: newPositionX,
        y: newPositionY,
      });
      localforage.setItem(SOFTPHONE_DIMENSIONS_POSITION, {
        dimensions,
        position: {
          x: newPositionX,
          y: newPositionY,
        },
      });
    };

    window.addEventListener('resize', debounce(handleWindowResize, 500));

    return () => {
      window.removeEventListener('resize', debounce(handleWindowResize, 500));
    };
  }, []);

  const onDragStop = (e: DraggableEvent, data: DraggableData) => {
    const newPosition = {
      x: data.x,
      y: data.y,
    };
    setPosition(newPosition);
    localforage.setItem(SOFTPHONE_DIMENSIONS_POSITION, {
      dimensions,
      position: newPosition,
    });
  };

  const onResizeStop = (
    e: MouseEvent | TouchEvent,
    dir: string,
    elementRef: HTMLElement,
    delta: ResizableDelta,
    position: Position
  ) => {
    setDimensions((prev) => {
      const newDimensions = {
        width: prev.width + delta.width,
        height: prev.height + delta.height,
      };
      localforage.setItem(SOFTPHONE_DIMENSIONS_POSITION, {
        dimensions: newDimensions,
        position,
      });
      return newDimensions;
    });
  };

  const SoftphoneChildren = useCallback(() => {
    return (
      <Fragment>
        {!ActivePhoneCall &&
          !incomingPhoneCalls.length &&
          openTopbarDialpad && <TopBarDialpad isFixed={isSoftphoneFixed} />}
        <CallSidebar isFixed={IsCallSidebarFixed} />
      </Fragment>
    );
  }, [
    ActivePhoneCall,
    incomingPhoneCalls,
    openTopbarDialpad,
    isSoftphoneFixed,
    IsCallSidebarFixed,
  ]);

  const isActiveCall = AnyPhoneConnectionActive || isTenSeconds || listOfIncomingVideoCalls.size > 0; //prettier-ignore

  const handleOnCloseFloatingWrapper = () => {
    removeFromOpenedRightSidebarsOrder('dial-pad');
    setOpenTopbarDialpad(false);
  };

  return (
    <Fragment>
      {isSoftphoneFixed && IsCallSidebarFixed ? (
        <SoftphoneChildren />
      ) : (
        <FloatingWrapper
          open={isActiveCall || openTopbarDialpad}
          isActiveCall={isActiveCall}
          default={{
            ...position,
            ...dimensions,
          }}
          position={position}
          dimension={dimensions}
          onDragStop={onDragStop}
          onResizeStop={onResizeStop}
          onClose={handleOnCloseFloatingWrapper}
        >
          <SoftphoneChildren />
        </FloatingWrapper>
      )}
      <SoftphoneAudios />
    </Fragment>
  );
};

export default inject(
  STORE_UI,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION,
  STORE_CONVERSATION,
  STORE_PREFERENCE
)(observer(Softphone));
