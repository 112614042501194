import React from 'react';
import type { DirectoryUserInfoProps } from './types';

import { PersonModel } from '../../../../models';
import { Styled } from './index.styles';
import { Heading } from '../../../shared/Heading';
import { BodyCopy } from '../../../shared/BodyCopy';
import { UserAvatar } from '../../../shared/UserAvatar';

export const DirectoryUserInfo: React.FC<DirectoryUserInfoProps> = ({
  selectConversationUnreadCounts,
  selectPersonPresenceStatus,
  data,
}) => {
  const { DisplayName } = PersonModel.FromResponseDto(data);

  return (
    <Styled.UserInfo>
      <UserAvatar
        name={DisplayName}
        selectUnreadCounts={selectConversationUnreadCounts}
        presence={selectPersonPresenceStatus(Number(data.id))}
      />
      <Styled.UserInfoContent>
        <Heading data-private variant="h6" noMargin>
          {DisplayName}
        </Heading>
        <BodyCopy data-private noMargin>
          {data.jobTitle}
          {data.jobTitle && data.department && ', '}
          {data.department}
        </BodyCopy>
      </Styled.UserInfoContent>
    </Styled.UserInfo>
  );
};
