import * as React from 'react';
import { Styled } from './index.styles';
import type { SecondaryMenuOptionButtonProps } from './interfaces';

export const SecondaryMenuOptionButton: React.FC<
  SecondaryMenuOptionButtonProps
> = ({ text, to, show = true, disabled = false, testid }) =>
  show ? (
    <Styled.NavLink
      {...{ to }}
      $disabled={disabled}
      data-testid={`${testid}-button${text}`}
    >
      <Styled.Heading variant="h6" noMargin>
        {text}
      </Styled.Heading>
    </Styled.NavLink>
  ) : null;
