import moment from 'moment-timezone';
import React from 'react';
import type { CalendarHeaderProps } from './types'

export const CalendarHeader = ({ label, date: propsDate }: CalendarHeaderProps) => {
  const text = label.split(' ');
  const today = moment().format('MM DD YYYY');
  const date = moment(propsDate).format('MM DD YYYY');
  const areEqualDates = today === date;

  return (
    <div className="calendar-header">
      <span className="text">{text[1]}</span>
      <span className={`date ${areEqualDates ? 'date-today' : ''}`}>
        {text[0]}
      </span>
    </div>
  );
};
