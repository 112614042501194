import * as React from 'react';

import { IconButton } from '../shared/IconButton';
import type { GroupChatButtonProps } from './GroupChatButton.types';

export const GroupChatButton: React.FC<GroupChatButtonProps> = ({
  groupModalOpen,
  otherPersonId,
  popupPosition,
  setChannelInfoDetails,
  setGroupModal,
  setOpeningGroupModalFrom,
  setSelectedTopBarUsers,
  size,
  testid,
}) => {
  const createGroupChat = () => {
    setGroupModal(!groupModalOpen);
    setChannelInfoDetails('0', 'new', 'person');
    setSelectedTopBarUsers([`${otherPersonId}`]);
    setOpeningGroupModalFrom('top-bar');
  };

  return (
    <IconButton
      content="Start a Group Chat"
      onClick={createGroupChat}
      icon="three-users"
      testid={`${testid}-buttonCreateGroupChat`}
      {...{ size, popupPosition }}
    />
  );
};
