import cx from 'classnames';
import { DEFAULT_AVATAR_URI } from 'Constants/env';
import { get, has, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { IPromiseBasedObservable } from 'mobx-utils';
import { ContactModel } from 'Models/ContactModel';
import * as React from 'react';
import { Feed, Image } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { ParticipantSummary } from '../../interfaces/apiDtos';
import { AxiosResponseT } from '../../interfaces/axiosResponse';
import { PersonModel } from '../../models';
import { Contact } from '../../models/Contacts';
import { UserAvatar } from '../shared/UserAvatar';
import ContextContentItemCallObserver from '../ContextContentItemCall';
import { ContextContentItemChat } from '../ContextContentItemChat';
import ContextContentItemConferenceObserver from '../ContextContentItemConference';
import ContextContentItemMetaObserver from '../ContextContentItemMeta';
import ContextContentItemSystemEventObserver from '../ContextContentItemSystemEvent';
import { IContextContentItemProps } from './interfaces';

const videoAvatar = require('../../assets/images/video-avatar.jpg');

/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ContextContentItem
 */
export class ContextContentItem extends React.Component<
  IContextContentItemProps,
  { highlighted: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      highlighted: false,
    };
  }
  callAction = (e) => {
    e.preventDefault();
  };

  private _renderDefaultAvatar = () => (
    <Feed.Label>
      <div className="ui mini image">
        {this.props.message.isFirstIn5Mins && (
          <Image src={DEFAULT_AVATAR_URI} size="mini" />
        )}
      </div>
    </Feed.Label>
  );

  render() {
    const {
      callWithPerson,
      conversationId,
      curConversation,
      deleteMessage,
      editMessage,
      editMessageDraftHtml,
      editMessageDraftRaw,
      getContact,
      getEmojiPickerState,
      getPerson,
      isEditingMessageId,
      isScrolled,
      loadLinkPreview,
      loggedInPersonId,
      loggedInUserActiveConferenceConversation,
      mentionListOpen,
      message,
      messageGroup,
      messageMentionFilter,
      navigateToConferenceByConfId,
      newestMessageOwnedByUserId,
      resolveConversationLinkPath,
      scroll,
      scrollBottom,
      selectedMentionParticipantId,
      selectFilteredOtherParticipantPersonsInCurrentConversation,
      selectParticipantPersons,
      selectPersonPresenceStatus,
      selectPersonMessageStatus,
      selectUnreadCounts,
      setConversationAndTotalUnreadCount,
      setEditMessageDraftHtml,
      setEditMessageDraftRaw,
      setEmojiPickerState,
      setIsEditingMessageId,
      setMentionListOpen,
      setMessageMentionFilter,
      setSelectedMentionParticipantId,
      stayScrolled,
      updateMyLastReadMessage,
      listOfPinnedMessages,
      conversationStore,
      getFileDownloadLink,
      handleDownloadWithLink,
      setFileDeletePopup,
      copiedConferenceId,
      copyToClipboard,
      setCopiedConferenceId,
      loadContactByPhoneNumberIfMissing,
      getExtrContactByPhoneNumber,
    } = this.props;
    let personPbo: IPromiseBasedObservable<AxiosResponseT<PersonModel>>;
    let contactPbo: IPromiseBasedObservable<AxiosResponseT<ContactModel>>;
    let contact: Contact;
    const messageStatus =
      message && selectPersonMessageStatus(message.personId || 0);
    // Highlight the event div if the message is currently being edited
    const className = isEditingMessageId === message.id ? 'highlighted' : '';
    const isCallType =
      !isEmpty(message.call) || !isEmpty(message.systemEvent)
        ? 'context-content-item-call'
        : '';
    if (
      message.personId !== null &&
      message.personId !== undefined &&
      message.personId > 0
    ) {
      personPbo = getPerson(
        message.systemEvent &&
          message.systemEvent.payload &&
          message.systemEvent.payload.personId
          ? message.systemEvent.payload.personId
          : message.personId
      );
    } else if (message.phone !== null && message.phone !== undefined) {
      contactPbo = getContact(message.phone);
      contact = getExtrContactByPhoneNumber(message.phone);
    }
    const eventTypesWeSupport = [
      'Conversation.Created',
      'Conversation.Participants.Removed',
      'Conversation.Participants.Added',
    ];
    const event = message.systemEvent?.eventType;
    const shouldRender =
      (eventTypesWeSupport.includes(event) || !event) &&
      message?.conference?.provider !== 'Voxeet';

    const isSomeFormOfGroupConversation =
      curConversation?.grouping === 'Channel' ||
      curConversation?.grouping === 'Group';
    const isGroupWithPhoneNumber: boolean =
      (isSomeFormOfGroupConversation &&
        (
          get(
            this.props.curConversation,
            'participants'
          ) as ParticipantSummary[]
        )?.some((x) => x.phone)) ||
      false;
    return shouldRender ? (
      <Feed.Event
        id={message.id}
        className={cx(
          className,
          isCallType,
          message.isFirstIn5Mins ? 'margin-top-2rem padding-top-1rem' : '',
          'position-relative'
        )}
      >
        {!isEmpty(message.sms) && !personPbo && !message.isFirstIn5Mins && (
          <Feed.Label></Feed.Label>
        )}
        {!isNullOrUndefined(message.personId) &&
          personPbo &&
          personPbo.case({
            pending: () => <strong>Loading...</strong>,
            rejected: () => this._renderDefaultAvatar(),
            fulfilled: (resp) => {
              const name =
                resp.data instanceof Contact
                  ? resp.data?.DisplayName()
                  : resp.data?.DisplayName;

              return (
                <Feed.Label>
                  {message.isFirstIn5Mins && (
                    <div className="ui mini image avatar-image">
                      {message.systemEvent &&
                      message.systemEvent.source.indexOf('Voxeet') !== -1 ? (
                        <Image src={videoAvatar} size="mini" />
                      ) : resp.data instanceof Contact &&
                        resp.data?.pictureUrl ? (
                        <img
                          className="contact-prof-img"
                          src={resp.data?.pictureUrl}
                        />
                      ) : (
                        <UserAvatar
                          name={name}
                          conversationId={conversationId}
                          selectUnreadCounts={selectUnreadCounts}
                          presence={selectPersonPresenceStatus(resp.data.id)}
                        />
                      )}
                    </div>
                  )}
                  {message.isFirstIn5Mins &&
                    (!isEmpty(this.props.message.sms) ||
                      (this.props.message.chat &&
                        this.props.message.chat.smsRelay)) && (
                      <div className="sms-icon">
                        {this.props.message.documents?.length != 0 ||
                        isGroupWithPhoneNumber
                          ? 'MMS'
                          : 'SMS'}
                      </div>
                    )}
                </Feed.Label>
              );
            },
          })}
        {message.isFirstIn5Mins &&
          !isNullOrUndefined(message.phone) &&
          contactPbo &&
          has(contactPbo, 'case') &&
          contactPbo.case({
            pending: () => <strong>Loading...</strong>,
            rejected: () => this._renderDefaultAvatar(),
            fulfilled: (resp) => (
              <Feed.Label>
                <div className="ui mini image">
                  {message.isFirstIn5Mins && (
                    <UserAvatar
                      selectUnreadCounts={selectUnreadCounts}
                      presence={selectPersonPresenceStatus(0)}
                      setConversationAndTotalUnreadCount={
                        setConversationAndTotalUnreadCount
                      }
                      updateMyLastReadMessage={updateMyLastReadMessage}
                    />
                  )}
                </div>
                {!isEmpty(this.props.message.sms) && (
                  <div className="sms-icon">
                    {this.props.message.documents?.length != 0 ||
                    isGroupWithPhoneNumber
                      ? 'MMS'
                      : 'SMS'}
                  </div>
                )}
              </Feed.Label>
            ),
          })}

        <div
          className={cx('content', {
            'content-grouped': !(message.isFirstIn5Mins || event),
            'flex-row-reverse': message.conference || message.call,
          })}
        >
          {isEmpty(message.call) &&
            isEmpty(message.systemEvent) &&
            personPbo &&
            personPbo.case({
              pending: () => <strong>Loading...</strong>,
              rejected: () => (
                <ContextContentItemMetaObserver
                  messageModel={message}
                  loggedInPersonId={loggedInPersonId}
                  newestMessage={newestMessageOwnedByUserId}
                  messageStatus={messageStatus}
                  isFirst5Mins={message.isFirstIn5Mins}
                  displayName={`Removed User #${message.personId}${
                    !isEmpty(message.id) &&
                    !messageGroup.selectIsMessageRead(message.id)
                      ? ' *'
                      : ''
                  }`}
                  createdMoment={message.CreatedMoment}
                  messageId={message.id}
                />
              ),
              fulfilled: (resp) => (
                <ContextContentItemMetaObserver
                  messageModel={message}
                  loggedInPersonId={loggedInPersonId}
                  newestMessage={newestMessageOwnedByUserId}
                  messageStatus={messageStatus}
                  isFirst5Mins={message.isFirstIn5Mins}
                  displayName={`${resp.data.firstName} ${resp.data.lastName}${
                    !isEmpty(message.id) &&
                    !messageGroup.selectIsMessageRead(message.id)
                      ? ' *'
                      : ''
                  }`}
                  createdMoment={message.CreatedMoment}
                  messageId={message.id}
                  SupportsChat={resp.data.SupportsChat}
                />
              ),
            })}
          {isEmpty(message.call) &&
            isEmpty(message.systemEvent) &&
            (contactPbo || contact) && (
              <ContextContentItemMetaObserver
                messageModel={message}
                loggedInPersonId={loggedInPersonId}
                newestMessage={newestMessageOwnedByUserId}
                messageStatus={messageStatus}
                isFirst5Mins={message.isFirstIn5Mins}
                displayName={`${contact?.DisplayName() || message.phone}`}
                createdMoment={message.CreatedMoment}
                messageId={message.id}
              />
            )}
          {(!isEmpty(message.chat) ||
            !isEmpty(message.call) ||
            message.conference ||
            (message.documents && isEmpty(message.sms)) ||
            message.isDeleted === true) &&
            personPbo !== undefined && (
              <ContextContentItemChat
                setFileDeletePopup={setFileDeletePopup}
                handleDownloadWithLink={handleDownloadWithLink}
                getFileDownloadLink={getFileDownloadLink}
                conversationStore={conversationStore}
                listOfPinnedMessages={listOfPinnedMessages}
                content={
                  message.chat
                    ? message.chat.text
                    : message.isDeleted && !message.call
                    ? '*(Message Deleted)*'
                    : ''
                }
                conversationId={conversationId}
                curConversation={curConversation}
                deleteMessage={deleteMessage}
                editMessage={editMessage}
                editMessageDraftHtml={editMessageDraftHtml}
                editMessageDraftRaw={editMessageDraftRaw}
                getEmojiPickerState={getEmojiPickerState}
                getPerson={getPerson}
                isEditingMessageId={isEditingMessageId}
                isScrolled={isScrolled}
                loadLinkPreview={loadLinkPreview}
                loggedInPersonId={loggedInPersonId}
                mentionListOpen={mentionListOpen}
                messageMentionFilter={messageMentionFilter}
                messageModel={message}
                newestMessageOwnedByUserId={newestMessageOwnedByUserId}
                resolveConversationLinkPath={resolveConversationLinkPath}
                scroll={scroll}
                scrollBottom={scrollBottom}
                selectedMentionParticipantId={selectedMentionParticipantId}
                selectFilteredOtherParticipantPersonsInCurrentConversation={
                  selectFilteredOtherParticipantPersonsInCurrentConversation
                }
                selectParticipantPersons={selectParticipantPersons}
                selectPersonPresenceStatus={selectPersonPresenceStatus}
                selectUnreadCounts={selectUnreadCounts}
                setConversationAndTotalUnreadCount={
                  setConversationAndTotalUnreadCount
                }
                setEditMessageDraftHtml={setEditMessageDraftHtml}
                setEditMessageDraftRaw={setEditMessageDraftRaw}
                setEmojiPickerState={setEmojiPickerState}
                setIsEditingMessageId={setIsEditingMessageId}
                setMentionListOpen={setMentionListOpen}
                setMessageMentionFilter={setMessageMentionFilter}
                setSelectedMentionParticipantId={
                  setSelectedMentionParticipantId
                }
                stayScrolled={stayScrolled}
                updateMyLastReadMessage={updateMyLastReadMessage}
                copiedConferenceId={copiedConferenceId}
                copyToClipboard={copyToClipboard}
                setCopiedConferenceId={setCopiedConferenceId}
              />
            )}
          {!isEmpty(message.sms) && (
            <ContextContentItemChat
              setFileDeletePopup={setFileDeletePopup}
              handleDownloadWithLink={handleDownloadWithLink}
              getFileDownloadLink={getFileDownloadLink}
              conversationStore={conversationStore}
              listOfPinnedMessages={listOfPinnedMessages}
              content={message.sms.text}
              conversationId={conversationId}
              curConversation={curConversation}
              deleteMessage={deleteMessage}
              editMessage={editMessage}
              editMessageDraftHtml={editMessageDraftHtml}
              editMessageDraftRaw={editMessageDraftRaw}
              getEmojiPickerState={getEmojiPickerState}
              getPerson={getPerson}
              isEditingMessageId={isEditingMessageId}
              isScrolled={isScrolled}
              loadLinkPreview={loadLinkPreview}
              loggedInPersonId={loggedInPersonId}
              mentionListOpen={mentionListOpen}
              messageMentionFilter={messageMentionFilter}
              messageModel={message}
              newestMessageOwnedByUserId={newestMessageOwnedByUserId}
              resolveConversationLinkPath={resolveConversationLinkPath}
              scroll={scroll}
              scrollBottom={scrollBottom}
              selectedMentionParticipantId={selectedMentionParticipantId}
              selectFilteredOtherParticipantPersonsInCurrentConversation={
                selectFilteredOtherParticipantPersonsInCurrentConversation
              }
              selectParticipantPersons={selectParticipantPersons}
              selectPersonPresenceStatus={selectPersonPresenceStatus}
              selectUnreadCounts={selectUnreadCounts}
              setConversationAndTotalUnreadCount={
                setConversationAndTotalUnreadCount
              }
              setEditMessageDraftHtml={setEditMessageDraftHtml}
              setEditMessageDraftRaw={setEditMessageDraftRaw}
              setEmojiPickerState={setEmojiPickerState}
              setIsEditingMessageId={setIsEditingMessageId}
              setMentionListOpen={setMentionListOpen}
              setMessageMentionFilter={setMessageMentionFilter}
              setSelectedMentionParticipantId={setSelectedMentionParticipantId}
              stayScrolled={stayScrolled}
              updateMyLastReadMessage={updateMyLastReadMessage}
              copiedConferenceId={copiedConferenceId}
              copyToClipboard={copyToClipboard}
              setCopiedConferenceId={setCopiedConferenceId}
            />
          )}
          {!message.chat?.text && message.call && message.isDeleted ? (
            <div className="paragraph">
              <em>{'(Message Deleted)'}</em>
            </div>
          ) : (
            <>
              {!isNullOrUndefined(message.systemEvent) &&
                (personPbo !== undefined || contactPbo !== undefined) && (
                  <ContextContentItemSystemEventObserver
                    conversation={curConversation}
                    messageModel={message}
                    getPerson={getPerson}
                    personId={message.personId}
                    getContact={getContact}
                  />
                )}
              {!isNullOrUndefined(message.conference) &&
                (personPbo !== undefined || contactPbo !== undefined) && (
                  <ContextContentItemConferenceObserver
                    removeVideoConferenceFromList={
                      conversationStore.removeVideoConferenceFromList
                    }
                    conversationId={conversationId}
                    event={message.systemEvent?.eventType}
                    conference={message.conference}
                    navigateToConferenceByConfId={navigateToConferenceByConfId}
                    loggedInUserActiveConferenceConversation={
                      loggedInUserActiveConferenceConversation
                    }
                  />
                )}
              {!isEmpty(message.call) &&
                (personPbo !== undefined || contactPbo !== undefined) && (
                  <ContextContentItemCallObserver
                    message={message}
                    callWithPerson={callWithPerson}
                    getPerson={getPerson}
                    getExtrContactByPhoneNumber={getExtrContactByPhoneNumber}
                  />
                )}
            </>
          )}
        </div>
      </Feed.Event>
    ) : (
      <></>
    );
  }
}

export default observer(ContextContentItem);
