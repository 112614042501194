import React from 'react';
import { Styled } from './index.styles';
import type { ChatDividerProps } from './types';
import moment from 'moment';

export const groupMessagesByDate = (messageDate) => {
  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  const currentYearStart = moment().startOf('year');

  if (messageDate.isSame(today, 'day')) {
    return 'Today';
  } else if (messageDate.isSame(yesterday, 'day')) {
    return 'Yesterday';
  } else if (today.diff(messageDate, 'days') < 7) {
    return messageDate.format('dddd');
  } else if (messageDate.isAfter(currentYearStart)) {
    return messageDate.format('MMMM DD');
  }

  return messageDate.format('MMMM D, YYYY');
};

export const ChatDivider = ({ groupDate }: ChatDividerProps) => {
  const dividerName = groupMessagesByDate(moment(groupDate));

  return (
    <Styled.DividerContainer>
      <Styled.DividerLine />
      <Styled.DividerText variant={'paragraph'}>
        {dividerName}
      </Styled.DividerText>
      <Styled.DividerLine />
    </Styled.DividerContainer>
  );
};
