import * as React from 'react';
import type { FilterBoxProps } from './types';
import { Styled } from './index.styles';

export const FilterBox: React.FC<FilterBoxProps> = ({
  loading,
  placeholder,
  sendInput,
  setPageNum,
  testid,
}) => {
  const contactInputField = React.useRef(null);

  React.useEffect(() => {
    contactInputField?.current?.focus();
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.Input
        data-testid={testid}
        data-private
        fluid
        ref={contactInputField}
        onKeyUp={(e: React.ChangeEvent<HTMLInputElement>) => {
          const trimmedValue = e.target.value.trim();
          if (trimmedValue.length > 2) {
            setPageNum?.(1);
            sendInput(e.target.value);
          } else {
            sendInput('');
          }
        }}
        {...{ placeholder, loading }}
      />
    </Styled.Wrapper>
  );
};
