import { NODE_ENV_PRODUCTION, TIMEZONE_IDENTIFIER } from 'Constants/env';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { IPromiseBasedObservable } from 'mobx-utils';
import * as React from 'react';
import { SemanticCOLORS } from 'semantic-ui-react';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { MessageModel, PersonModel } from '../../models';
const moment = require('moment-timezone');

import phoneIncoming from 'Assets/images/call-summary-incoming-call.svg';
import phoneMissed from 'Assets/images/call-summary-missed-call.svg';
import phoneUnknown from 'Assets/images/call-summary-other-call.svg';
import phoneOutgoing from 'Assets/images/call-summary-outgoing-call.svg';
import { Contact } from '../../models/Contacts';

export interface IContextContentItemCallProps {
  message: MessageModel;
  callWithPerson: (
    personId?: number,
    phone?: string,
    isWarmtransfer?: boolean
  ) => void;
  getPerson: (
    personId: number
  ) => IPromiseBasedObservable<AxiosResponseT<PersonModel>>;
  getExtrContactByPhoneNumber: (
    phoneNumber: string
  ) => Contact;
  iconName?: string;
  iconColor?: SemanticCOLORS;

}

/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ContextContentItemCall
 */
export class ContextContentItemCall extends React.Component<
  IContextContentItemCallProps,
  {}
> {
  placeCallToPhoneNumber = (e) => {
    const { message } = this.props;
    if (message.call.direction && message.call.direction === 'Incoming') {
      this.props.callWithPerson(
        message.call.from.personId,
        message.call.from.phone
      );
      pushToGTMDataLayer('placeCall', {
        internalUser: true,
        callDestination: message.call.from.personId || message.call.from.phone,
      });
    } else if (
      message.call.direction &&
      message.call.direction === 'Outgoing'
    ) {
      this.props.callWithPerson(
        message.call.to.personId,
        message.call.to.phone
      );
      pushToGTMDataLayer('placeCall', {
        internalUser: true,
        callDestination: message.call.to.personId || message.call.to.phone,
      });
    }
  };

  render() {
    const { message, getPerson, getExtrContactByPhoneNumber } = this.props;
    const callTime = moment
      .tz(message.call.start, TIMEZONE_IDENTIFIER)
      .format('hh:mm A');
    const callDate = moment
      .tz(message.call.start, TIMEZONE_IDENTIFIER)
      .format('MM/DD/YYYY');
    const callDuration = get(message, 'call.callDuration', '00:00:00');
    const time = callDuration.split(':');
    const roundingUpSeconds = parseFloat(time.pop()).toFixed(0);
    time.push(
      (parseInt(roundingUpSeconds, 10) < 10 ? '0' : '') +
        roundingUpSeconds.toString()
    );
    let dirTxt = '';
    let personPbo: IPromiseBasedObservable<AxiosResponseT<PersonModel>>;
    let externalContact: Contact;
    let personPhoneNumber: string;
    let icon;

    if (message.call.disposition === 'Missed') {
      dirTxt = 'Missed call from: ';
      icon = phoneMissed;
      if (message.call.from.personId) {
        personPbo = getPerson(message.call.from.personId);
      } else if (message.call.from.phone) {
        personPhoneNumber = message.call.from.phone;
      }
    } else if (
      message.call.direction &&
      message.call.direction === 'Incoming'
    ) {
      dirTxt = 'Call from: ';
      icon = phoneIncoming;
      if (message.call.from.personId) {
        personPbo = getPerson(message.call.from.personId);
      } else if (message.call.from.phone) {
        personPhoneNumber = message.call.from.phone;
      }
    } else if (
      message.call.direction &&
      message.call.direction === 'Outgoing'
    ) {
      dirTxt = 'Called: ';
      icon = phoneOutgoing;
      if (message.call.to.personId) {
        personPbo = getPerson(message.call.to.personId);
      } else if (message.call.to.phone) {
        personPhoneNumber = message.call.to.phone;
      }
    } else {
      dirTxt = 'Unknown direction: ';
      icon = phoneUnknown;
    }

    if (personPhoneNumber) {
      externalContact = getExtrContactByPhoneNumber(personPhoneNumber);
    }

    return (
      <div className="call-summary">
        {personPbo &&
          personPbo.case({
            pending: () => {
              return <strong>Loading...</strong>;
            },
            rejected: () => {
              return (
                <span>
                  {<img className="icon-disposition" src={icon} />}
                  <span className="content">
                    {dirTxt}
                    {message.call.to.phone
                      ? message.call.to.phone
                      : 'Unknown User ' +
                        'on ' +
                        callDate +
                        ' at ' +
                        callTime}{' '}
                    {NODE_ENV_PRODUCTION ? '' : `- ${message.id}`}
                  </span>
                </span>
              );
            },
            fulfilled: (resp) => {
              return (
                <span>
                  {<img className="icon-disposition" src={icon} />}
                  <span className="content">
                    {dirTxt}
                    <span
                      className="link"
                      onClick={this.placeCallToPhoneNumber}
                    >
                      {resp.data.DisplayName}
                    </span>
                    {' on ' + callDate + ' at ' + callTime}{' '}
                    {NODE_ENV_PRODUCTION ? '' : `- ${message.id}`}
                  </span>
                </span>
              );
            },
          })}
        {isEmpty(personPbo) && !isEmpty(personPhoneNumber) && (
          <span>
            {<img className="icon-disposition" src={icon} />}
            <span className="content">
              {dirTxt}
              <span className="link" onClick={this.placeCallToPhoneNumber}>
                {externalContact?.DisplayName() || personPhoneNumber}
              </span>{' '}
              {' on ' + callDate + ' at ' + callTime}{' '}
              {NODE_ENV_PRODUCTION ? '' : `- ${message.id}`}
            </span>
          </span>
        )}
        {isEmpty(personPbo) && isEmpty(personPhoneNumber) && (
          <span>
            {<img className="icon-disposition" src={icon} />}
            <span className="content">
              {dirTxt}
              {'Unknown User ' + 'on ' + callDate + ' at ' + callTime}{' '}
              {NODE_ENV_PRODUCTION ? '' : `- ${message.id}`}
            </span>
          </span>
        )}
      </div>
    );
  }
}

export default observer(ContextContentItemCall);
