import { sortBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import moment from 'moment-timezone';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Dropdown, DropdownProps, Icon, Popup } from 'semantic-ui-react';
import { TIMEZONE_IDENTIFIER } from '../../../../constants/env';
import { STORE_CONVERSATION, STORE_ROUTER } from '../../../../constants/stores';
import withRouter from '../../../../hocs/WithRouter';
import { IVideoHistoryProps } from './VideoHistoryDetails/types';

const closeBtn = require('../../../../assets/images/close-btn.svg');
const infoIcon = require('../../../../assets/images/info-gray-icon.svg');
const chevronRightIcon = require('../../../../assets/images/chevron-right-icon.svg');
const chevronRightWhiteIcon = require('../../../../assets/images/chevron-right-white-icon.svg');
const stateOptions = [
  {
    text: 'Last 24 Hours',
    key: 1,
    value: 1,
  },
  {
    text: 'Last 7 Days',
    key: 7,
    value: 7,
  },
  {
    text: 'Last 14 Days',
    key: 14,
    value: 14,
  },
  {
    text: 'Last 30 Days',
    key: 30,
    value: 30,
  },
  {
    text: 'Last 60 Days',
    key: 60,
    value: 60,
  },
  {
    text: 'Last 90 Days',
    key: 90,
    value: 90,
  },
  {
    text: 'Last 180 Days',
    key: 180,
    value: 180,
  },
  {
    text: 'Last Year',
    key: 365,
    value: 365,
  },
];

class VideoHistory extends React.Component<
  IVideoHistoryProps,
  { tableData: any; type: 'ASC' | 'DESC'; activeHoverElement: number }
> {
  constructor(props) {
    super(props);
    this.state = {
      tableData: null,
      type: null,
      activeHoverElement: 0,
    };
  }
  componentDidMount() {
    const { conversation } = this.props;
    conversation.loadVideoHistory(20, 1, false);
  }
  alignThead = (index: number): 'left' | 'right' | 'center' => {
    let alignment;
    switch (index) {
      case 3:
        alignment = 'right';
        break;
      case 5:
        alignment = 'center';
      default:
        alignment = 'left';
    }
    return alignment;
  };
  handleFilter = (e, d: DropdownProps) => {
    const {
      conversation: { videoHistoryList },
    } = this.props;
    const filteredData = videoHistoryList.data.results.filter((item) => {
      const itemDay = moment(item.started);
      const momentInst = moment().tz(TIMEZONE_IDENTIFIER).utc();
      const difference = momentInst.diff(itemDay, 'day', true);
      if (typeof d.value !== 'number') {
        return false;
      }
      return difference < d.value;
    });
    this.setState({ tableData: filteredData });
  };

  redirectToHistoryDetails = (id: number) => () => {
    const { navigate } = this.props;
    navigate(`/video-app/history/${id}`);
  };

  handleSortDates = () => {
    const {
      conversation: { videoHistoryList },
    } = this.props;
    const data = this.state.tableData || videoHistoryList?.data?.results;
    const sorted =
      this.state.type === 'ASC'
        ? sortBy(data, 'started')
        : sortBy(data, 'started').reverse();
    this.setState({
      tableData: sorted,
      type: this.state.type === 'ASC' ? 'DESC' : 'ASC',
    });
  };

  handleLoadMoreContacts = () => {
    const { conversation } = this.props;
    const pageNum = conversation.pageNumberVideoSearch + 1;
    conversation.loadVideoHistory(20, pageNum, true);
  };

  handleHovering = (id: number) => () =>
    this.setState({ activeHoverElement: id });

  handleClearFilter = () => this.setState({ tableData: null });
  render() {
    const {
      conversation: { videoHistoryList, pageNumberVideoSearch },
    } = this.props;
    const headers = ['MEETING TITLE', 'DATE', 'TIME', 'ACTIONS'];
    const historyData = this.state.tableData || videoHistoryList?.data?.results;
    return (
      <div className="history">
        <div className="history-header">
          <span>History</span>
          <div className="history-filter">
            {this.state.tableData && (
              <img src={closeBtn} onClick={this.handleClearFilter} />
            )}
            <Dropdown
              placeholder="Last 7 days"
              search
              selection
              options={stateOptions}
              onChange={this.handleFilter}
            />
          </div>
        </div>
        <div className="history-table" id="">
          <div className="header">
            <div className="flex-row">
              {headers.map((text, index) => (
                <div className="table-th width-25" key={text}>
                  {text}
                  {text === 'DATE' && (
                    <Icon
                      name="chevron down"
                      className="pointer"
                      color="grey"
                      onClick={this.handleSortDates}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <br />
          <InfiniteScroll
            initialScrollY={1}
            dataLength={pageNumberVideoSearch * 20} //This is important field to render the next data
            next={this.handleLoadMoreContacts}
            hasMore={true}
            loader={<></>}
            endMessage={<></>}
            // below props only if you need pull down functionality
            refreshFunction={this.handleLoadMoreContacts}
            pullDownToRefresh
            pullDownToRefreshThreshold={4}
            scrollableTarget="tbody"
          >
            <div id="tbody" onMouseLeave={this.handleHovering(0)}>
              {historyData?.length > 0 ? (
                historyData.map((value, index) => {
                  const day = moment(value.started).format(
                    'dddd, MMMM DD, YYYY'
                  );
                  const timeOnCall = `${moment(value.started).format(
                    'hh:mm A'
                  )} - ${moment(value.completed).format('LT')}`;
                  return (
                    <div
                      key={value.id}
                      onClick={
                        value.isAdmin
                          ? this.redirectToHistoryDetails(value.id)
                          : undefined
                      }
                      onMouseEnter={this.handleHovering(value.id)}
                      className={`flex-row ${
                        value.isAdmin ? 'pointer' : 'disabled'
                      }`}
                    >
                      <div
                        data-private
                        className="width-25 word-break cell-oveflow"
                      >
                        {value.displayName}
                      </div>
                      <div className="width-25">{day}</div>
                      <div className="width-25">{timeOnCall}</div>
                      <div
                        className={`flex-row width-25 flex-align-items-center flex-justify-space-between ${
                          value.isAdmin ? 'right-17' : 'right-13'
                        }`}
                      >
                        {value.hasRecordings && (
                          <div className="flex-row flex-align-items-center ">
                            <span className="history-recorded">
                              <span></span>
                            </span>
                            Recorded
                          </div>
                        )}

                        {this.state.activeHoverElement === value.id &&
                        value.isAdmin ? (
                          <div className="i-holder">
                            <img src={chevronRightWhiteIcon} />
                          </div>
                        ) : (
                          <div className="i-holder-no-wrap">
                            {value.isAdmin ? (
                              <img src={chevronRightIcon} />
                            ) : (
                              <Popup
                                inverted
                                trigger={<img src={infoIcon} />}
                                content="Guests are not allowed to see the meeting details"
                                position={'top right'}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <>History list is empty.</>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

export default inject(
  STORE_CONVERSATION,
  STORE_ROUTER
)(withRouter(observer(VideoHistory)));
