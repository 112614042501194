import styled from 'styled-components';

const Actions = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 10px;
`;

const ActionRemove = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 12px;
  &:hover > span {
    display: inline;
  }
`;

const ActionRemoveText = styled.span`
  display: none;
  color: #333e48;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 10px;
`;

const IconLink = styled.img`
  position: relative;
  top: -3px;
  margin-left: 10px;
  max-width: 13px;
  max-height: 13px;
`;

export const Styled = {
  Actions,
  ActionRemove,
  ActionRemoveText,
  IconLink,
};
