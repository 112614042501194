import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { Button } from '../Button';
import { Heading } from '../Heading';

const SecondaryMenuWrapper = styled(Grid.Column)`
  background-color: ${({ theme }) => theme.colors.base};
  border-right: 1px solid ${({ theme }) => theme.colors.border};

  width: 260px;
  max-width: 260px;
  min-width: 260px;
  max-height: calc(100vh - 70px);
  overflow: hidden;
`;

const SecondaryMenuHeader = styled.div`
  height: 65px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledHeading = styled(Heading)`
  font-weight: bold;
`;
const SecondaryMenuTabs = styled.div`
  width: 100%;
`;

export const Styled = {
  SecondaryMenuWrapper,
  SecondaryMenuHeader,
  Button: StyledButton,
  Heading: StyledHeading,
  SecondaryMenuTabs,
};
