import DialPad from 'Components/Dialpad';
import { CALL_STATE, SEARCHABLE_TYPE } from 'Constants/enums';
import { debounce, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Grid, Icon, Input, Loader, SemanticICONS } from 'semantic-ui-react';
import { PhoneCallModel } from '../../models';
import { SearchStore } from '../../stores';
import { TransferCallDropdown } from '../TransferDirectoryListItem';

const searchTypes: SEARCHABLE_TYPE[] = [
  'SearchableDetailsPerson',
  'SearchableDetailsContact',
];

const keypad0 = require('../../assets/audio/keypad/0.wav');
const keypad1 = require('../../assets/audio/keypad/1.wav');
const keypad2 = require('../../assets/audio/keypad/2.wav');
const keypad3 = require('../../assets/audio/keypad/3.wav');
const keypad4 = require('../../assets/audio/keypad/4.wav');
const keypad5 = require('../../assets/audio/keypad/5.wav');
const keypad6 = require('../../assets/audio/keypad/6.wav');
const keypad7 = require('../../assets/audio/keypad/7.wav');
const keypad8 = require('../../assets/audio/keypad/8.wav');
const keypad9 = require('../../assets/audio/keypad/9.wav');
const keypadAsterisk = require('../../assets/audio/keypad/asterisk.wav');
const keypadPound = require('../../assets/audio/keypad/pound.wav');

interface InputDialpadProps {
  phoneCallModel: PhoneCallModel;
  search: SearchStore;
}
/**
 * Search Input Dialpad Combo component
 *
 * @export
 * @class ActiveCall
 */
export class InputDialpad extends React.Component<InputDialpadProps, {}> {
  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }
  state = {
    currentValue: '',
    cursorPosition: 0,
    phoneNumber: '',
    isCallable: false,
  };

  UNSAFE_shouldComponentUpdate(nextProps, nextState) {
    if (this.state.currentValue !== nextState.currentValue) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps: InputDialpadProps, prevState) {
    if (prevState.currentValue !== this.state.currentValue) {
      this.filter(this.state.currentValue);
      this.handleCursorPos();
    }
  }

  componentDidMount() {
    const { phoneCallModel } = this.props;
    // sync dialpad nums with input
    if (phoneCallModel.keysPressed) {
      const isCallable = phoneCallModel.keysPressed.length >= 2;
      this.setState({
        currentValue: phoneCallModel.keysPressed,
        isCallable: isCallable,
      });
    }
  }

  filter = debounce(
    (q: string) => {
      const { search } = this.props;
      search.getPersonContactSearch('TRANSFER', q, searchTypes, 20);
    },
    500,
    { leading: false }
  );

  keyPadPush = (num: string) => {
    const { phoneCallModel } = this.props;
    const { currentValue } = this.state;
    phoneCallModel.keysPress(num);
    phoneCallModel.dialPadNumDtmf(num);
    let value = num;
    if (this.inputRef) {
      const cursorPositionStart = this.inputRef.current.selectionStart;
      const cursorPositionEnd = this.inputRef.current.selectionEnd;
      if (!isEmpty(currentValue)) {
        const val = currentValue;
        value =
          val.slice(0, cursorPositionStart) +
          num +
          val.slice(cursorPositionEnd);
      }
      this.setState({
        currentValue: value,
        cursorPosition: cursorPositionStart,
      });
      this.isCallable(value);
    }
  };
  makeCall = (personId?: number, phone?: string) => {
    const { phoneCallModel } = this.props;
    phoneCallModel.phoneStore.callWithPerson(personId, phone);
  };

  isCallable = (currentValue) => {
    if (!isEmpty(currentValue)) {
      const phoneNumber = currentValue.replace(/[^0-9+*]/g, '');
      const isInternationalOrShortCode = phoneNumber.length >= 2;
      this.setState({
        phoneNumber: phoneNumber,
        isCallable: isInternationalOrShortCode,
      });
    }
  };

  handleInput = () => {
    const { phoneCallModel } = this.props;
    const cursorPositionStart = this.inputRef.current.selectionStart;
    const lastNum = this.inputRef.current.value.split('').pop();

    this.setState({
      currentValue: this.inputRef.current.value,
      cursorPosition: cursorPositionStart,
    });
    this.isCallable(this.inputRef.current.value);
    //sync input and dialpad
    phoneCallModel.keysPress(lastNum);
  };
  handleCursorPos = (cursorPos?: number) => {
    if (this.inputRef.current) {
      if (cursorPos) {
        this.inputRef.current.setSelectionRange(cursorPos, cursorPos);
        this.inputRef.current.focus();
        this.setState({ cursorPosition: cursorPos });
      } else {
        this.inputRef.current.setSelectionRange(
          this.state.cursorPosition,
          this.state.cursorPosition
        );
        this.inputRef.current.focus();
      }
    }
  };

  render() {
    const { phoneCallModel, search } = this.props;
    const directory = search.selectDirectorySearchById('TRANSFER');
    const callState = CALL_STATE.Available;
    const iconType: SemanticICONS = 'call';
    const mobileNumMarkup = (
      <Icon
        className={
          callState !== CALL_STATE.Available
            ? 'cursor-not-allowed' +
              (callState === CALL_STATE.Connecting
                ? ' call-connecting'
                : ' call-connected')
            : ''
        }
        name={iconType}
        inverted={this.state.isCallable}
        color={
          this.state.isCallable && callState === CALL_STATE.Available
            ? 'green'
            : 'grey'
        }
        circular
        link={this.state.isCallable}
      />
    );
    if (directory) {
      return (
        <>
          <Grid.Column width={16} textAlign="center" verticalAlign="middle">
            <div className="input-dialpad-search">
              {search.isTransferDialpadLoading ? (
                <Loader size="tiny" className="load-icon" />
              ) : (
                <Icon className="search-icon" name="search" color="grey" />
              )}
              <Input
                loading={search.isContactListSearchLoading}
                fluid
                onChange={this.handleInput}
                iconPosition="left"
                placeholder="Enter Name/Number"
                value={this.state.currentValue}
                input={{ ref: this.inputRef }}
                icon={
                  <TransferCallDropdown
                    markup={mobileNumMarkup}
                    phoneCall={phoneCallModel}
                    number={this.state.phoneNumber}
                    vmDisabled={true}
                  />
                }
                type="text"
                className="gray-bg-input"
              />
            </div>
          </Grid.Column>
          {phoneCallModel.showDialPad && (
            <Grid.Column width={16} textAlign="center" verticalAlign="middle">
              <DialPad
                numberClick={this.keyPadPush}
                inputRef={this.inputRef}
                handleCursorPos={this.handleCursorPos}
              />
            </Grid.Column>
          )}
          <audio
            key="KeyPressTone0"
            ref={(elem) => (phoneCallModel.keyPressTone0 = elem)}
            src={keypad0}
            id="KeyPressTone0-audio"
            preload="auto"
          />
          <audio
            key="KeyPressTone1"
            ref={(elem) => (phoneCallModel.keyPressTone1 = elem)}
            src={keypad1}
            id="KeyPressTone1-audio"
            preload="auto"
          />
          <audio
            key="KeyPressTone2"
            ref={(elem) => (phoneCallModel.keyPressTone2 = elem)}
            src={keypad2}
            id="KeyPressTone2-audio"
            preload="auto"
          />
          <audio
            key="KeyPressTone3"
            ref={(elem) => (phoneCallModel.keyPressTone3 = elem)}
            src={keypad3}
            id="KeyPressTone3-audio"
            preload="auto"
          />
          <audio
            key="KeyPressTone4"
            ref={(elem) => (phoneCallModel.keyPressTone4 = elem)}
            src={keypad4}
            id="KeyPressTone4-audio"
            preload="auto"
          />
          <audio
            key="KeyPressTone5"
            ref={(elem) => (phoneCallModel.keyPressTone5 = elem)}
            src={keypad5}
            id="KeyPressTone5-audio"
            preload="auto"
          />
          <audio
            key="KeyPressTone6"
            ref={(elem) => (phoneCallModel.keyPressTone6 = elem)}
            src={keypad6}
            id="KeyPressTone6-audio"
            preload="auto"
          />
          <audio
            key="KeyPressTone7"
            ref={(elem) => (phoneCallModel.keyPressTone7 = elem)}
            src={keypad7}
            id="KeyPressTone7-audio"
            preload="auto"
          />
          <audio
            key="KeyPressTone8"
            ref={(elem) => (phoneCallModel.keyPressTone8 = elem)}
            src={keypad8}
            id="KeyPressTone8-audio"
            preload="auto"
          />
          <audio
            key="KeyPressTone9"
            ref={(elem) => (phoneCallModel.keyPressTone9 = elem)}
            src={keypad9}
            id="KeyPressTone9-audio"
            preload="auto"
          />
          <audio
            key="KeyPressTonePound"
            ref={(elem) => (phoneCallModel.keyPressTonePound = elem)}
            src={keypadPound}
            id="KeyPressTonePound-audio"
            preload="auto"
          />
          <audio
            key="KeyPressToneStar"
            ref={(elem) => (phoneCallModel.keyPressToneStar = elem)}
            src={keypadAsterisk}
            id="KeyPressToneStar-audio"
            preload="auto"
          />
        </>
      );
    }
  }
}

export default observer(InputDialpad);
