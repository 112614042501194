import { bugsnagClient } from './logUtils';

export const logError = (
  error: any,
  errorMessage: string,
  context: 'PhoneStore' | 'PhoneCallModel',
  whereItHappened: string
) => {
  bugsnagClient.notify(errorMessage, (event) => {
    event.severity = 'error';
    event.context = context;
    event.addMetadata('custom', { function: whereItHappened });
    event.request = error;
  });
};
