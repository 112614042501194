import { CALL_STATE } from 'Constants/enums';

import * as React from 'react';

import { IconButton } from '../shared/IconButton';
import type { StartCallButtonProps } from './StartCallButton.types';

export const StartCallButton: React.FC<StartCallButtonProps> = ({
  callState,
  compact,
  conversationId,
  makeCall,
  personPbo,
  phone,
  popupPosition,
  pushToGTMDataLayer,
  size,
  testid,
}) => {
  const placeCall = () => {
    personPbo?.state === 'fulfilled'
      ? makeCall(personPbo?.value.data.id)
      : makeCall(null, phone);

    pushToGTMDataLayer('placeCall', {
      internalUser: true,
      callDestination:
        personPbo?.state === 'fulfilled' ? personPbo?.value.data.id : phone,
      conversationId,
    });
  };

  return (
    <IconButton
      disabled={callState !== CALL_STATE.Available}
      content={
        callState !== CALL_STATE.Available
          ? 'You are currently on a call with this person'
          : 'Start Call'
      }
      onClick={placeCall}
      icon="call"
      testid={`${testid}-buttonStartCall`}
      {...{ size, popupPosition, compact }}
    />
  );
};
