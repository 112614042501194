import { API_ENDPOINTS, NODE_ENV_PRODUCTION } from 'Constants/env';
import { IFavoritesMutedMentionNotification } from 'Interfaces/notifications';
import { isEmpty, keys } from 'lodash';
import { action, observable, makeObservable } from 'mobx';
import { isNullOrUndefined } from 'util';
import { pushToGTMDataLayer } from 'Utils/analytics';
import API from '../api';
import { BaseStore } from './BaseStore';
import { RootStore } from '.';

const defaultPreferences: IPreferencesWithUpdated = {
  listUnreadFirst: true,
  notificationAudio: true,
  floatingSoftphone: false,
  ringVolumePercent: 1.0,
  updated: new Date().toISOString(),
  showCallMessagesInChat: true,
};

const defaultButtonStates: IPreferenceButtonStates = {
  listUnreadFirst: true,
  notificationAudio: true,
  floatingSoftphone: false,
};

const defaultDesktopLocalPreferences: IDesktopLocalPreferences = {
  openOnLogin: false,
};

/**
 * Manage user preferences
 */
export class PreferenceStore extends BaseStore {
  @action
  clearAllData = () => {
    this.setPreferenceData(defaultPreferences);
    this.setButtonStates(defaultButtonStates);
  };

  /**
   * The logged-in user's personal Preferences
   */
  @observable
  preferences: IPreferencesWithUpdated = defaultPreferences;

  /**
   * Preferences that are only controlled by the desktop (Electron) application.
   * These preferences only apply to the machine on which they are set.
   * No API request is made to set them, they are communicated via IPC.
   */
  @observable
  desktopLocalPreferences: IDesktopLocalPreferences = defaultDesktopLocalPreferences;

  /**
   * Whether the switch/slider should be disabled for a Preference toggle,
   * in order to wait for the network response and prevent double clicking.
   */
  @observable
  buttonStates: IPreferenceButtonStates = defaultButtonStates;

  /**
   * Spread the provided `buttonStates` over `this.buttonStates`
   *
   * @private
   */
  @action
  private setButtonStates = (buttonStates: IPreferenceButtonStates) => {
    if (!isEmpty(buttonStates)) {
      this.buttonStates = { ...this.buttonStates, ...buttonStates };
    }
  };

  /**
   * Load Preferences from the back-end. Should be called on login.
   * Also loads `desktopLocalPreferences` data.
   */
  @action
  getExistingPreferenceData = async () => {
    try {
      const resp = await API.get(API_ENDPOINTS.Preference);
      return Promise.resolve(this.setPreferenceData(resp.data));
    } catch (reason) {
      this.rootStore.notificationStore.addAxiosErrorNotification(
        reason,
        'Error loading Preferences'
      );
    }
  };

  /**
   * Spread the provided `desktopLocalPreferences` over `this.desktopLocalPreferences`
   */
  @action
  setDesktopLocalPreferencesData = (
    desktopLocalPreferences: IDesktopLocalPreferences
  ) => {
    if (!isEmpty(desktopLocalPreferences)) {
      return (this.desktopLocalPreferences = {
        ...this.desktopLocalPreferences,
        ...desktopLocalPreferences,
      });
    }
  };

  /**
   * Spread the provided `preferences` over `this.preferences`
   *
   * @private
   */
  @action
  private setPreferenceData = (preferences: IPreferences) => {
    if (!isEmpty(preferences)) {
      return (this.preferences = { ...this.preferences, ...preferences });
    }
  };

  /**
   * Set the provided Preferences on the back-end.
   *
   * **This should not be used for Desktop only local settings (such as `openOnLogin`)!**
   *
   * Omit or provide `null`/`undefined` for Preferences that will not be changed.
   */
  @action
  setPreferencesPatch = async (preferenceUpdates: IPreferences) => {
    const preferencePatchData: IPatchDataItem[] = [];
    keys(preferenceUpdates).forEach((k) => {
      this.setButtonStates({ [k]: false });
      preferencePatchData.push({
        op: 'replace',
        path: `/${k}`,
        value: preferenceUpdates[k],
      });
      // * NOTE: preferenceSet GTM report is ONLY for preferences that are set on the API (rather than local/per-machine)
      pushToGTMDataLayer('preferenceSet', {
        preferenceName: k,
      });
    });

    this.setPreferenceData(preferenceUpdates);
    keys(preferenceUpdates).forEach((k) => {
      this.setButtonStates({ [k]: true });
    });

    try {
      await API.patch(API_ENDPOINTS.Preference, preferencePatchData);
      if (!isNullOrUndefined(preferenceUpdates.showCallMessagesInChat)) {
        await this.rootStore.conversationStore.clearDataForPreferenceChange();
        this.rootStore.participantStore.clearAllData();
        this.rootStore.messageStore.clearAllData();
        this.rootStore.contactStore.clearAllData();
        this.rootStore.uiStore.clearAllData();
        await this.rootStore.conversationStore.reloadAndBackfillAllConversations();
      }
    } catch (reason) {
      if (reason.response.status === 304) {
        if (!NODE_ENV_PRODUCTION) {
          this.rootStore.notificationStore.addNotification(
            'This operation was already modified with same value',
            'Status Updated',
            'success'
          );
        }
        return;
      }
      this.rootStore.notificationStore.addAxiosErrorNotification(
        reason,
        'Error setting Preferences'
      );
    }
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }
}

export interface IPreferences extends IFavoritesMutedMentionNotification {
  favoriteConversationIds?: string[];
  listUnreadFirst?: boolean;
  floatingSoftphone?: boolean;
  notificationAudio?: boolean;
  ringVolumePercent?: number;
  showCallMessagesInChat?: boolean;
}

export interface IPreferencesWithUpdated extends IPreferences {
  updated?: string;
}

/**
 * Preferences that are only controlled by the desktop (Electron) application.
 * These preferences only apply to the machine on which they are set.
 * No API request is made to set them, they are communicated via IPC.
 */
export interface IDesktopLocalPreferences {
  /** (Desktop only local setting): Whether to open Communicator on computer login */
  openOnLogin?: boolean;
}

/**
 * Whether each Preference button/switch/field is enabled for user interaction
 */
export interface IPreferenceButtonStates {
  listUnreadFirst?: boolean;
  notificationAudio?: boolean;
  floatingSoftphone?: boolean;
}

export interface IPatchDataItem {
  value: any;
  path: string;
  op: string;
}

export default PreferenceStore;
