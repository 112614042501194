import { Auth0ContextInterface } from '@auth0/auth0-react';
import { parseJwt } from './jwtUtils';
import { bugsnagClient } from './logUtils';

export const isFlagInPermissions = async (
  auth0: Auth0ContextInterface,
  flag: String
): Promise<boolean> => {
  let accessToken: string = '';

  try {
    accessToken = await auth0.getAccessTokenSilently();
  } catch (error) {
    bugsnagClient.notify(error, (event) => {
      event.severity = 'error';
      event.context = 'isFlagInPermissions';
      event.addMetadata('custom', {
        function: 'getAccessTokenSilently',
      });
    });
    auth0.logout();
  }

  try {
    const payload = parseJwt(accessToken);
    const { permissions } = payload;

    return permissions.includes(flag);
  } catch (e) {
    bugsnagClient.notify(e, (event) => {
      event.severity = 'error';
      event.addMetadata('custom', {
        function: 'isFlagInPermissions',
      });
    });
  }

  return false;
};
