import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { formatNumber } from 'Utils/phoneUtil';

import editIconWhite from 'Assets/images/icon-edit-white.svg';
import user from 'Assets/images/user-details.svg';
import type { ProfileOverviewProps } from './interfaces';
const loader = require('../../../../assets/images/loader.gif');

export const ProfileOverview: React.FC<ProfileOverviewProps> = ({
  profilePictureUrl,
  loadingProfilePictureUrl,
  fileImportEvent,
  username,
  localExtension,
  email,
  inboundNumbers,
  outboundNumbers,
  testid,
}) => (
  <div className="settings-profile">
    <div className="settings-profile--pic">
      <div
        className={cx({
          'settings-profile--pic-image': profilePictureUrl,
          'settings-profile--pic-icon': !profilePictureUrl,
        })}
      >
        {loadingProfilePictureUrl ? (
          <img src={loader} alt="" />
        ) : (
          <img data-private src={profilePictureUrl || user} alt="" />
        )}
      </div>
      {!loadingProfilePictureUrl && (
        <>
          <input
            data-private
            type="file"
            onChange={fileImportEvent}
            className="inputfile"
            id="profilefileinput"
            data-testid={`${testid}-buttonEditProfileImage`}
          />
          <label htmlFor="profilefileinput">
            <img src={editIconWhite} />
          </label>
        </>
      )}
    </div>
    <div className="settings-profile--desc">
      <span className="title">Managed by admin</span>
      {username && (
        <p>
          <span className="bold-text">Username:</span> {username}
        </p>
      )}
      {localExtension && (
        <p data-private>
          <span className="bold-text">Local Extension:</span> {localExtension}
        </p>
      )}
      {email && (
        <p data-private>
          <span className="bold-text">Email:</span> {email}
        </p>
      )}
      {!isEmpty(inboundNumbers) && (
        <div className="flex-row flex-align-items-center">
          <span className="bold-text">Inbound numbers:</span>
          <div data-private className="inbound-numbers">
            {inboundNumbers.map((line) => (
              <span key={line.number}>{formatNumber(line.number)}</span>
            ))}
          </div>
        </div>
      )}
      {!isEmpty(outboundNumbers) && (
        <div className="flex-row flex-align-items-center">
          <span className="bold-text">Outbound numbers:</span>
          <div data-private className="outbound-numbers">
            {outboundNumbers.map((line) => {
              return <span key={line.PhoneDisplay}>{line.PhoneDisplay}</span>;
            })}
          </div>
        </div>
      )}
    </div>
  </div>
);
