import {
  STORE_CONFIG,
  STORE_CONVERSATION,
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_ROUTER,
  STORE_UI,
} from 'Constants/stores';
import { TEST_CONVERSATION_PATH_START } from 'Constants/validation';
import ActionPanelIntentContainer from 'Containers/ActionPanelIntent';
import ActivityListContainer from 'Containers/ActivityList';
import ChannelContainer from 'Containers/Channel';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from '../../constants/responsiveness';
import withRouter from '../../hocs/WithRouter';
import { ActionPanelProps } from './interfaces';

export class ActionPanel extends React.Component<ActionPanelProps> {
  toggleEmojiPicker = () => {
    const { ui } = this.props;
    ui.setEmojiPickerState({ open: false, editing: false });
  };

  render() {
    const { person, phoneCall, conversation, ui } = this.props;
    if (!person.IsLoggedIn) {
      return null;
    }
    const pathPrefix = TEST_CONVERSATION_PATH_START(window.location.pathname)
      ? '/conversations/:conversationId/*'
      : '/';
    const activeCall = phoneCall.AnyPhoneConnectionActive;
    const largeScreen = activeCall || ui.openTopbarDialpad ? 12 : 15;
    const otherScreens = activeCall || ui.openTopbarDialpad ? 8 : largeScreen;
    return (
      <Grid.Column
        widescreen={otherScreens}
        computer={otherScreens}
        desktop={otherScreens}
        tablet={otherScreens}
        mobile={otherScreens}
        id="action-panel"
        className="flex-column"
        onMouseLeave={() =>
          ui.setHideSecondaryMenu(
            window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT
          )
        }
        onClick={this.toggleEmojiPicker}
      >
        <ActionPanelIntentContainer />
        <Routes>
          {/* current path: /chat/* */}
          <Route
            path={`${pathPrefix}channel/:channelConversationId/:channelMode?`}
            element={<ChannelContainer {...this.props} />}
          />
          <Route path="*" element={<ActivityListContainer {...this.props} />} />
        </Routes>
      </Grid.Column>
    );
  }
}

export default inject(
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_CONFIG,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_PREFERENCE,
  STORE_UI,
  STORE_MESSAGE,
  STORE_CONVERSATION
)(withRouter(observer(ActionPanel)));
