import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Button, Grid, Image } from 'semantic-ui-react';
import { IMessageModelConferencePersonId } from '../../models/MessageModel';
import { PersonStore, UiStore } from '../../stores';
import { UserAvatar } from '../shared/UserAvatar';

const videoIcon = require('../../assets/images/top-bar-video.svg');
const closeIcon = require('../../assets/images/close-x.svg');

interface IncomingVideoItemProps {
  removeVideoConferenceFromList: (conferenceId: string) => void;
  key: string;
  conference: IMessageModelConferencePersonId;
  ui: UiStore;
  person: PersonStore;
}

class IcomingVideoItem extends Component<IncomingVideoItemProps> {
  rejectVideoCall = () => {
    const { removeVideoConferenceFromList, conference } = this.props;
    removeVideoConferenceFromList(conference.id);
  };

  acceptVideoCall = () => {
    const { conference, ui, removeVideoConferenceFromList } = this.props;
    ui.navigateVideoConferenceToSession(conference.id, null);
    removeVideoConferenceFromList(conference.id);
  };

  render() {
    const {
      key,
      conference,
      ui: { selectConversationUnreadCounts },
      person: { loadPersonByIdGetIfMissingGet },
    } = this.props;
    const personPbo = loadPersonByIdGetIfMissingGet(conference.personId);
    const person = personPbo.case({
      fulfilled: (resp) => resp.data,
    });
    const image = person?.profilePictureUrl;
    return (
      <div className="call-incoming-list-item call-item connecting" key={key}>
        {person ? (
          <>
            <div className="contact-image-wrapper">
              {image?.startsWith('http') ? (
                <img src={image} className="transit-call-image-border" />
              ) : (
                <UserAvatar
                  name={person.DisplayName}
                  selectUnreadCounts={selectConversationUnreadCounts}
                />
              )}
            </div>
            <div data-private className="contact-info">
              <div className="contact-name">{person.DisplayName}</div>
              <div className="contact-number">{person.phoneNumber}</div>
              <div className="transit-call-status">
                <span>Video Connecting ...</span>
              </div>
              <Grid.Column className="call-buttons width-76" textAlign="center">
                <div className="btn-wrapper">
                  <Button
                    circular
                    className="end-call"
                    onClick={this.rejectVideoCall}
                  >
                    <Button.Content visible>
                      <Image src={closeIcon} />
                    </Button.Content>
                  </Button>
                  <span className="btn-text">Cancel</span>
                </div>
                <div className="btn-wrapper">
                  <Button
                    circular
                    className="answer-call-btn"
                    onClick={this.acceptVideoCall}
                  >
                    <Button.Content visible>
                      <Image src={videoIcon} />
                    </Button.Content>
                  </Button>
                  <span className="btn-text">Answer</span>
                </div>
              </Grid.Column>
            </div>
          </>
        ) : (
          <div>Person is unkown</div>
        )}
      </div>
    );
  }
}
export default observer(IcomingVideoItem);
