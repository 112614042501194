import React from 'react';
import { UserAvatar } from 'Components/shared/UserAvatar';
import { inject, observer } from 'mobx-react';
import { STORE_CONVERSATION, STORE_UI } from 'Constants/stores';
import { AttendeesProps } from './types';
import { Styled } from './index.styles';

const AttendeesObserver: React.FC<AttendeesProps> = ({
  conversation: { videoHistoryDetails },
  ui,
}) => {
  return (
    <Styled.HistoryVideoAttendeesStyled>
      <Styled.SubtitleStyled>
        Attendees{' '}
        <Styled.NumAttendeesStyled>
          {videoHistoryDetails?.data?.participants?.length}
        </Styled.NumAttendeesStyled>
      </Styled.SubtitleStyled>
      {videoHistoryDetails?.data?.participants?.map((participant, index) => (
        <div
          key={participant.personId}
          className={`flex-row flex-align-items-center ${
            index > 0 ? 'margin-top-1rem' : ''
          }`}
        >
          <UserAvatar
            name={participant.displayName}
            selectUnreadCounts={ui.selectConversationUnreadCounts}
            setConversationAndTotalUnreadCount={
              ui.setConversationAndTotalUnreadCount
            }
            presence={null}
          />
          <Styled.PersonNameStyled>
            {participant.displayName}
          </Styled.PersonNameStyled>
        </div>
      ))}
    </Styled.HistoryVideoAttendeesStyled>
  );
};

export const Attendees = inject(
  STORE_CONVERSATION,
  STORE_UI
)(observer(AttendeesObserver));
