import React from 'react';
import { IconDefinition } from '../shared/Icon';
import type { SideBarMenuButtonProps } from './interfaces';
import { Styled } from './SideBarMenuButton.styles';

export const SideBarMenuButton: React.FC<SideBarMenuButtonProps> = ({
  label,
  icon,
  isActive = false,
  isExternal = false,
  to,
  testid,
  className,
}) => {
  return (
    <Styled.Link
      {...{ to, ...(isExternal && { target: '_blank', reloadDocument: true }) }}
      data-testid={`${testid}-button${label}`}
    >
      <Styled.Column $isActive={isActive} className={className}>
        <Styled.Icon size="medium" icon={icon} $isActive={isActive} />
        <Styled.IconFilled
          size="medium"
          icon={`${icon}-filled` as IconDefinition}
          $isActive={isActive}
        />
        <Styled.Label $isActive={isActive}>{label}</Styled.Label>
      </Styled.Column>
    </Styled.Link>
  );
};
