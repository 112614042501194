import { STORE_CONFIG, STORE_UI } from 'Constants/stores';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { ActivityItemPhoneVideoButtons } from '../ActivityItemPhoneVideoButtons';
import { IContextActionsProps } from './interfaces';

import { ChatDetailsButton } from '../ActivityItemPhoneVideoButtons/ChatDetailsButton';
import { GroupChatButton } from '../ActivityItemPhoneVideoButtons/GroupChatButton';
import { PinnedMessagesButton } from '../ActivityItemPhoneVideoButtons/PinnedMessagesButton';
import { AddToFavoritesButton } from '../ActivityItemPhoneVideoButtons/AddToFavoritesButton';

/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ContextActions
 */

const testid = 'contextActions';

export class ContextActions extends React.Component<IContextActionsProps, {}> {
  makeCall = (personId?: number, phone?: string) => {
    const { phoneCall } = this.props;
    phoneCall.callWithPerson(personId, phone);
  };

  toggleMuteStatus = (conversationid: string): boolean => {
    const { uiStore } = this.props;
    const value = uiStore.selectIfConvMuted(conversationid);
    uiStore.setConvMuted(conversationid, !value);
    return !value;
  };

  openInfoDetails = (
    id: number | string,
    type: string,
    from: 'personInfo' | 'groupInfo',
    convId
  ) => {
    const { setShowPersonDetails, conversation, uiStore } = this.props;
    if (from === 'personInfo') {
      setShowPersonDetails(id, type);
      conversation.setChannelInfoDetails(convId, 'new');
    } else {
      conversation.setChannelInfoDetails(id.toString(), type);
    }
    uiStore.setOpenedRightSidebarsOrder('sidebar-info');
  };

  render() {
    const buttonSize = 'small';
    const popupPosition = 'bottom center';

    const {
      conversation,
      conversation: {
        selectConversationById,
        LoggedInUserActiveConferenceConversation,
        postConferenceByConversationId,
        CurrentConversation,
        setChannelInfoDetails,
        FavoriteConversationIds,
      },
      uiStore,
      uiStore: {
        setActiveConversationIdPinnMess,
        selectIfConvMuted,
        listOfMutedConversation,
        setSelectedTopBarUsers,
        groupModalOpen,
        setGroupModal,
        setOpeningGroupModalFrom,
        openedRightSidebarsOrder,
        setOpenedRightSidebarsOrder,
      },
      conversationId,
      loggedInPersonVideoFeature,
      getExtrContactByPhoneNumber,
      phoneCall,
      getPerson,
      setShowPersonDetails,
      loggedInAccountId,
      selectConversationParticipants,
      selectOtherConversationParticipants,
      isOnline,
    } = this.props;
    const convPbo = selectConversationById(conversationId);

    const commonProps = {
      isOnline,
      uiStore,
      conversationId,
      loggedInAccountId,
      LoggedInUserActiveConferenceConversation,
      setShowPersonDetails,
      getExtrContactByPhoneNumber,
      loggedInPersonVideoFeature,
      darkDisabledVidIcon: false,
      makeCall: this.makeCall,
      postConferenceByConversationId,
      activeConference: CurrentConversation?.activeConference,
      setChannelInfoDetails: setChannelInfoDetails,
      toggleMuteStatus: this.toggleMuteStatus,
      phoneCalls: phoneCall.phoneCalls,
      selectIfConvMuted,
      listOfCMutedConverstions: toJS(listOfMutedConversation),
    };

    if (!convPbo) {
      return <Loader active indeterminate size="mini" />;
    }
    // TODO: ContactStore (RP) - Needs support for Contacts
    return convPbo.case({
      pending: () => <Loader indeterminate active />,
      rejected: ({ response }) =>
        !response ? null : (
          <Message
            visible
            error
            content={'Error loading Conversation: ' + response.data.message}
          />
        ),
      fulfilled: (convResp) => {
        const elements = [];
        if (convResp.data.grouping === 'OneOnOne') {
          const otherParticipants =
            selectOtherConversationParticipants(conversationId);
          if (otherParticipants?.length === 1) {
            const otherPersonId = otherParticipants[0].personId;
            if (otherPersonId) {
              // Is an internal communicator user?
              elements.push(
                <React.Fragment>
                  <GroupChatButton
                    {...{
                      otherPersonId: otherPersonId,
                      setSelectedTopBarUsers,
                      size: buttonSize,
                      groupModalOpen,
                      popupPosition,
                      setChannelInfoDetails,
                      setGroupModal,
                      setOpeningGroupModalFrom,
                      testid,
                    }}
                  />
                  <ActivityItemPhoneVideoButtons
                    {...commonProps}
                    {...{ buttonSize, popupPosition }}
                    participants={convResp.data.participants}
                    key={'ctxactsaiphbtn' + convResp.data.id}
                    personPbo={getPerson(otherPersonId)}
                    grouping={convResp.data.grouping}
                  />
                </React.Fragment>
              );
              const otherPerson = getPerson(otherPersonId);
              if (
                !isNullOrUndefined(otherPerson) &&
                otherPerson.state !== 'rejected'
              ) {
                elements.push(
                  otherPerson.case({
                    pending: () => (
                      <Loader key="otherPerson-loader" indeterminate active />
                    ),
                    rejected: (reason) => (
                      <Message
                        key="otherPerson-error"
                        visible
                        error
                        content={
                          'Error loading other Person: ' +
                          reason.response.data.message
                        }
                      />
                    ),
                    fulfilled: () => (
                      <ChatDetailsButton
                        onClick={() =>
                          this.openInfoDetails(
                            otherPersonId,
                            'person',
                            'personInfo',
                            convResp.data.id
                          )
                        }
                        {...{ size: buttonSize, popupPosition, testid }}
                      />
                    ),
                  })
                );
              }
            } else if (otherParticipants[0].phone) {
              // Other user doesn't have a phone number?
              elements.push(
                <ActivityItemPhoneVideoButtons
                  {...commonProps}
                  {...{ buttonSize, popupPosition }}
                  participants={convResp.data.participants}
                  key={'ActivityItemPhoneVideoButtons' + convResp.data.id}
                  phone={otherParticipants[0].phone}
                  grouping={convResp.data.grouping}
                />
              );
            }
          }
        } else {
          const participantsPbo =
            selectConversationParticipants(conversationId);
          elements.push(
            <React.Fragment>
              {participantsPbo?.case({
                pending: () => <Loader indeterminate active />,
                rejected: (reason) =>
                  !reason.response ? null : (
                    <Message
                      visible
                      error
                      content={
                        'Error loading Conversation: ' +
                        reason.response.data.message
                      }
                    />
                  ),
                fulfilled: () => (
                  <React.Fragment>
                    <ActivityItemPhoneVideoButtons
                      {...commonProps}
                      {...{ buttonSize, popupPosition }}
                      key={'ActivityItemPhoneVideoButtons' + convResp.data.id}
                      phone={null}
                      participants={convResp.data.participants}
                      grouping={convResp.data.grouping}
                    />
                    <PinnedMessagesButton
                      {...{
                        setChannelInfoDetails,
                        setShowPersonDetails,
                        setActiveConversationIdPinnMess,
                        conversationId: convResp.data.id,
                        testid,
                        size: buttonSize,
                        popupPosition,
                        openedRightSidebarsOrder,
                        setOpenedRightSidebarsOrder,
                      }}
                    />
                    <ChatDetailsButton
                      isGroupChat
                      onClick={() =>
                        this.openInfoDetails(
                          convResp.data.id,
                          'channel',
                          'groupInfo',
                          convResp.data.id
                        )
                      }
                      {...{ size: buttonSize, popupPosition, testid }}
                    />
                  </React.Fragment>
                ),
              })}
            </React.Fragment>
          );
        }

        return (
          <div id="context-actions">
            {elements}
            <AddToFavoritesButton
              isFavorite={FavoriteConversationIds.includes(convResp.data.id)}
              {...{ conversationId, conversation }}
              {...{ size: buttonSize, testid }}
              popupPosition="bottom left"
            />
          </div>
        );
      },
    });
  }
}

export default inject(STORE_CONFIG, STORE_UI)(observer(ContextActions));
