import { STORE_PERSON } from 'Constants/stores';
import { Directory } from 'Components/DirectoryPanel/Directory';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import type { DirectoryPanelProps } from './types';
import { Styled } from './index.styles';

const DirectoryPanelObserver: React.FC<DirectoryPanelProps> = ({
  person: { IsLoggedIn },
}) => {
  if (!IsLoggedIn) {
    return null;
  }

  return (
    <Styled.GridColumn id="action-panel">
      <Routes>
        {/* current path: /directory/* */}
        <Route path="/" element={<Directory />} />
      </Routes>
    </Styled.GridColumn>
  );
};

export const DirectoryPanel = inject(STORE_PERSON)(
  observer(DirectoryPanelObserver)
);
