import styled from 'styled-components';
import { Heading } from 'Components/shared/Heading';
import { BodyCopy } from 'Components/shared/BodyCopy';

const TextWrapper = styled.div<{ $bold: boolean }>`
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  ${({ $bold }) => $bold && `font-weight: bold;`}
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled(Heading)`
  font-weight: inherit;
`;

const OwnChatHeading = styled(Heading)`
  margin-left: ${({ theme }) => theme.sizes.xxxsmall};
  font-weight: inherit;
`;

const Emoji = styled.div`
  margin-left: ${({ theme }) => theme.sizes.xxsmall};
`;

const StyledBodyCopy = styled(BodyCopy)`
  font-weight: inherit;
`;

export const Styled = {
  TextWrapper,
  TitleWrapper,
  Heading: StyledHeading,
  OwnChatHeading,
  Emoji,
  BodyCopy: StyledBodyCopy,
};
