import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { UserAvatar } from '../shared/UserAvatar';
import PhoneNumbersPopup from '../PhoneNumbersPopup';
import { DropDownPropsTopBar } from './interfaces';

const blueStarIcon = require('../../assets/images/blue-star.svg');
const smsIcon = require('../../assets/images/directory-chat.svg');
const makeCallIcon = require('../../assets/images/phone-directory.svg');
const videoIcon = require('../../assets/images/video-top-bar.svg');

class DropDownItemTopBar extends React.Component<DropDownPropsTopBar> {
  handleOpenChat =
    (data: { personId: string; phoneNumbers: string[]; keyValue: string }) =>
    (e) => {
      const { options, openMessageChat } = this.props;
      e.stopPropagation();
      if (data?.phoneNumbers?.length > 1) {
        const option = options.find((option) => option.key === data.keyValue);
        const indexOfUpdatedItem = options.indexOf(option);
        const newOptionList = options.splice(indexOfUpdatedItem, 1, option);
        this.setState({
          selectedKey: data.keyValue,
          operationType: 'message',
          options: newOptionList,
        });
        return;
      } else if (data?.phoneNumbers?.length === 1) {
        const phone = data?.phoneNumbers[0];
        openMessageChat(null, phone);
        return;
      }
      openMessageChat(data.personId, null);
    };

  render() {
    const {
      ui,
      loggedInPersonVideoFeature,
      router,
      phoneCall,
      conversation,
      pictureUrl,
      ignoreName,
      convType,
      name,
      favorite,
      organization,
      role,
      userInfo,
      makeCall,
      placeVideoCallConference,
    } = this.props;
    const hideMessageIcon =
      userInfo?.phoneNumbers?.length > 0 &&
      (userInfo?.phoneNumbers[0]?.startsWith('*') ||
        userInfo?.phoneNumbers[0]?.startsWith('Tran'));
    return (
      <div className="flex-row flex-align-items-center">
        {pictureUrl ? (
          <img className="contact-prof-img" src={pictureUrl} />
        ) : (
          <UserAvatar
            name={!ignoreName ? name : null}
            selectUnreadCounts={ui.selectConversationUnreadCounts}
            conversationGrouping={convType || 'OneOnOne'}
          />
        )}
        <div className="user-info">
          {name && (
            <div data-private className="name">
              {name}
              {favorite && (
                <img className="favorite-custom-item" src={blueStarIcon} />
              )}
            </div>
          )}
          {(organization || role) && (
            <div data-private className="info">
              {organization && `${organization}, `} {role}
            </div>
          )}
        </div>
        {((userInfo?.phoneNumbers && userInfo?.phoneNumbers.length > 0) ||
          userInfo?.personId) && (
          <div className="aditional-item-buttons">
            {!hideMessageIcon && (
              <Popup
                key="start-chat"
                inverted
                position="bottom center"
                content={'Start messaging'}
                trigger={
                  <Button
                    onClick={this.handleOpenChat(userInfo)}
                    size="small"
                    compact
                    basic
                    id="message-contact"
                    className="flex-row flex-align-items-center"
                  >
                    <img className="icon-18px chat-icon" src={smsIcon} />
                    {userInfo.phoneNumbers?.length > 1 && (
                      <PhoneNumbersPopup
                        router={router}
                        phoneNumbers={userInfo.phoneNumbers}
                        callWithPerson={phoneCall.callWithPerson}
                        loadOrCreateConversationWithPost={
                          conversation.loadOrCreateConversationWithPost
                        }
                        operationType={'message'}
                        classNames="message-phones-popup"
                      />
                    )}
                  </Button>
                }
              />
            )}
            <Popup
              key="start-call"
              inverted
              content={'Start Call'}
              position="bottom center"
              trigger={
                <Button
                  onClick={makeCall(userInfo)}
                  size="small"
                  compact
                  id="call-contact"
                  basic
                  className="flex-row align-items-center"
                >
                  {userInfo.phoneNumbers?.length > 1 && (
                    <PhoneNumbersPopup
                      router={router}
                      phoneNumbers={userInfo.phoneNumbers}
                      callWithPerson={phoneCall.callWithPerson}
                      loadOrCreateConversationWithPost={
                        conversation.loadOrCreateConversationWithPost
                      }
                      operationType={'call'}
                      classNames="call-phones-popup"
                    />
                  )}
                  {<img id="phone-call" src={makeCallIcon} />}
                </Button>
              }
            />
            {userInfo.personId && (
              <Popup
                key="start-video"
                inverted
                content={'Start Video Call'}
                position="bottom center"
                trigger={
                  <Button
                    onClick={placeVideoCallConference(userInfo.personId)}
                    size="small"
                    compact
                    id="video-call-contact"
                    basic
                    className={`flex-row align-items-center ${
                      loggedInPersonVideoFeature?.enabled
                        ? null
                        : 'disabled-button'
                    }`}
                    disabled={!loggedInPersonVideoFeature?.enabled}
                  >
                    {<img id="video-icon" src={videoIcon} />}
                  </Button>
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
export default DropDownItemTopBar;
