import Resizer from 'react-image-file-resizer';
// @ts-expect-error https://github.com/onurzorluer/react-image-file-resizer/issues/68
const resizer: typeof Resizer = Resizer.default || Resizer;

export function resizeImage(file: File, extension: string) {
  return new Promise((resolve) => {
    resizer.imageFileResizer(
      file,
      500,
      500,
      extension,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });
}
