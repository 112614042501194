import { DateTime } from 'luxon';
import React from 'react';
import cx from 'classnames';
import offlineModeIcon from '../../assets/images/offline-mode-call-logs.svg';
import { CallLogMessageViewProps } from './interface';

const CallLogMessageView = (props: CallLogMessageViewProps) => {
  const { lastSyncDate, offlineMode } = props;

  const createLastSyncDate = () => {
    const syncDate = DateTime.fromISO(lastSyncDate).setLocale('en-US');
    const meridiem = syncDate.toFormat('a').toLowerCase();
    const date = syncDate.toFormat('f').replace(',', ' at').toLowerCase();
    return date.includes(meridiem) ? date : date + meridiem;
  };

  return (
    <>
      <div id="call-logs-offline" className={cx('flex-column')}>
        <div className="flex-row">
          <img src={offlineModeIcon} className="offline-mode-icon"></img>
          {offlineMode ? (
            <span>Some information may be missing while you're offline.</span>
          ) : (
            <span>
              It may take a few seconds after a call is ended for it to be
              included in the different Calls lists.
            </span>
          )}
        </div>
        {offlineMode && (
          <div className="flex-row latest-sync">
            <span>Latest sync: </span>
            <span>{createLastSyncDate()}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default CallLogMessageView;
