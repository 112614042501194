import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { Grid } from 'semantic-ui-react';
import {
  STORE_NOTIFICATION,
  STORE_PREFERENCE,
  STORE_UI,
} from '../../constants/stores';
import { ISettingsContainer } from './interfaces';
import SettingsPanel from '../../components/Settings/SettingsPanel';
import { SecondaryMenu } from '../../components/shared/SecondaryMenu';
import { SETTINGS_MENU_OPTIONS } from '../../constants/routing';

const SettingsContainer = (props: ISettingsContainer) => (
  <Grid.Column
    className="settings-container flex-row"
    onClick={() => props.ui.setDeletePopupDisplay(false)}
  >
    {!props.ui.hideSecondaryMenu && (
      <SecondaryMenu text="Settings" options={SETTINGS_MENU_OPTIONS} />
    )}
    <SettingsPanel />
  </Grid.Column>
);

export default inject(
  STORE_UI,
  STORE_PREFERENCE,
  STORE_NOTIFICATION
)(SettingsContainer);
