import { BV_ENV_PRODUCTION } from 'Constants/env';
import { History } from 'history';
import {
  ConfigStore,
  ContactStore,
  ConversationStore,
  MessageStore,
  NotificationStore,
  ParticipantStore,
  PersonStore,
  PhoneStore,
  PreferenceStore,
  PusherStore,
  RouterStore,
  SearchStore,
  UiStore,
} from 'Stores/index';
import CalendarStore from './CalendarStore';
import CallLogsStore from './CallLogsStore';

export class RootStore {
  constructor(browserHistory: History) {
    this.notificationStore = new NotificationStore(this);
    this.routerStore = new RouterStore(browserHistory, this);
    this.personStore = new PersonStore(this);
    this.contactStore = new ContactStore(this);
    this.participantStore = new ParticipantStore(this);
    this.messageStore = new MessageStore(this);
    this.conversationStore = new ConversationStore(this);
    this.searchStore = new SearchStore(this);
    this.phoneStore = new PhoneStore(this);
    this.pusherStore = new PusherStore(this);
    this.uiStore = new UiStore(this);
    this.preferenceStore = new PreferenceStore(this);
    this.configStore = new ConfigStore(this);
    this.calendarStore = new CalendarStore(this);
    this.callLogsStore = new CallLogsStore(this);
    if (!BV_ENV_PRODUCTION) {
      window['rootStore'] = this;
    }
  }
  readonly callLogsStore: CallLogsStore;
  readonly calendarStore: CalendarStore;
  readonly contactStore: ContactStore;
  readonly conversationStore: ConversationStore;
  readonly messageStore: MessageStore;
  readonly notificationStore: NotificationStore;
  readonly participantStore: ParticipantStore;
  readonly personStore: PersonStore;
  readonly phoneStore: PhoneStore;
  readonly pusherStore: PusherStore;
  readonly routerStore: RouterStore;
  readonly searchStore: SearchStore;
  readonly uiStore: UiStore;
  readonly preferenceStore: PreferenceStore;
  readonly configStore: ConfigStore;

  /**
   * Calls `clearAllData` on all stores which implement it.
   *
   * **WARNING:** Order matters, be _very_ careful if you re-order this list for some reason.
   *
   * If you create a new store, it's probably safe to put it at the top of the list, since it isn't as
   * likely that the new store is used by the existing ones.
   */
  clearAllData = () => {
    this.conversationStore
      .clearAllData() // Returns a Promise<void>
      .then(() => {
        this.notificationStore.clearAllData();
        this.messageStore.clearAllData();
        this.contactStore.clearAllData();
        this.participantStore.clearAllData();
        this.phoneStore.clearAllData();
        this.pusherStore.clearAllData();
        this.searchStore.clearAllData();
        this.uiStore.clearAllData();
        this.personStore.clearAllData();
        this.preferenceStore.clearAllData();
        this.configStore.clearAllData();
        this.calendarStore.clearAllData();
        this.callLogsStore.clearAllData();
      });
  };

  transitionToLogout = () => {
    this.personStore.setIsAutoLogout(true);
    this.routerStore.replace('/');
    this.clearAllData();
  };
}
