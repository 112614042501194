import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PUSHER,
  STORE_ROUTER,
  STORE_UI,
} from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Header } from 'semantic-ui-react';
import PrintMessageHeader from '../../components/ContextContentItemsList/PrintMessageHeader';
import { ConversationStore, PersonStore, UiStore } from '../../stores';
export class AdHocGroup extends React.Component<
  { conversation: ConversationStore; person: PersonStore; ui: UiStore },
  {}
> {
  render() {
    const { conversation, person, ui } = this.props;
    const {
      conversation: { adHocGroupParticipants },
    } = this.props;
    const topic = conversation.changeConvTopic(adHocGroupParticipants);
    return (
      <>
        <div id="context-header" className="flex-row flex-zero">
          <Header as="h2" className="context-header-display valign-middle">
            <Header.Content className="channel-header-topic" content={topic} />
          </Header>
        </div>
        <div id="context-content" className="flex-column flex-grow-shrink">
          <PrintMessageHeader
            extrContact={null}
            hasMessages={false}
            persons={conversation.adHocGroupParticipants}
            ui={ui}
            loggedInPerson={person.loggedInPersonId}
          />
        </div>
      </>
    );
  }
}

export default inject(
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_ROUTER,
  STORE_PHONE_CALL,
  STORE_UI,
  STORE_PUSHER
)(observer(AdHocGroup));
