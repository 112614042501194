import { FilterBox } from 'Components/shared/FilterBox';
import {
  STORE_CALL_LOGS,
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_UI,
} from 'Constants/stores';
import { ceil, debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List } from 'semantic-ui-react';
import { pushToGTMDataLayer } from 'Utils/analytics';
import {
  CALL_LOG_CATEGORY,
  CALL_LOG_DIRECTION,
  CALL_LOG_DISPOSITION,
} from '../../constants/enums';
import { CallLogListItem } from '../CallLogListItem';
import {
  CallsDirectoryListProps,
  CallsDirectoryListState,
  ICallLog,
} from './interface';
import { DateTime } from 'luxon';
import CallLogMessageView from '../CallLogMessageView';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import withRouter from '../../hocs/WithRouter';
import { ContentArea } from '../shared/ContentArea';
import { Heading } from '../shared/Heading';

export class CallsDirectoryList extends React.Component<
  CallsDirectoryListProps,
  CallsDirectoryListState
> {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      offlineMode: !window.navigator.onLine,
      cancelToken: axios.CancelToken.source(),
      typeOfCalls: null,
    };
    window.ononline = () => {
      this.setState({ offlineMode: false });
      this.getActionBasedOnType('', 1, null)();
    };
    window.onoffline = () => this.setState({ offlineMode: true });
  }

  getActionBasedOnType = (
    query: string,
    pageNum: number,
    appendResult: boolean
  ) => {
    const {
      params: { typeOfCallsId },
    } = this.props;
    const typeOfCallLogsActions = {
      missed: (cancelToken?: CancelTokenSource) =>
        this.props.callLogsStore.getCallLogs(
          20,
          pageNum,
          CALL_LOG_DIRECTION.INBOUND,
          CALL_LOG_DISPOSITION.MISSED,
          CALL_LOG_CATEGORY.MISSED,
          query,
          appendResult,
          cancelToken
        ),
      incoming: (cancelToken?: CancelTokenSource) =>
        this.props.callLogsStore.getCallLogs(
          20,
          pageNum,
          CALL_LOG_DIRECTION.INBOUND,
          CALL_LOG_DISPOSITION.COMPLETED,
          CALL_LOG_CATEGORY.INCOMING,
          query,
          appendResult,
          cancelToken
        ),
      outgoing: (cancelToken?: CancelTokenSource) =>
        this.props.callLogsStore.getCallLogs(
          20,
          pageNum,
          CALL_LOG_DIRECTION.OUTBOUND,
          null,
          CALL_LOG_CATEGORY.OUTGOING,
          query,
          appendResult,
          cancelToken
        ),
      all: (cancelToken?: CancelTokenSource) =>
        this.props.callLogsStore.getCallLogs(
          20,
          pageNum,
          null,
          null,
          null,
          query,
          appendResult,
          cancelToken
        ),
    };
    return typeOfCallLogsActions[typeOfCallsId || 'all'];
  };

  componentDidMount() {
    this.props.callLogsStore.setCallPageNum(1);
    const action = this.getActionBasedOnType('', 1, false);
    action(this.state.cancelToken);
  }

  componentDidUpdate(prevProps) {
    const scrollableList = document.getElementById('directory-list');
    scrollableList?.addEventListener('scroll', () => {
      if (scrollableList?.scrollTop === 0) scrollableList.scrollTo({ top: 1 });
    });

    if (
      this.props.params.typeOfCallsId !== prevProps.params.typeOfCallsId ||
      this.props.callLogsStore.shouldGetCallLogs
    ) {
      this.props.callLogsStore.shouldGetCallLogs = false;
      this.props.callLogsStore.setCallPageNum(1);
      scrollableList.scrollTo({ top: 1 });

      const action = this.getActionBasedOnType('', 1, false);
      action(this.state.cancelToken);
    }
  }

  componentWillUnmount() {
    this.state.cancelToken.cancel();
    const scrollableList = document.getElementById('directory-list');
    scrollableList?.removeEventListener('scroll', () => {
      if (scrollableList?.scrollTop === 0) scrollableList.scrollTo({ top: 1 });
    });
  }

  filter = debounce((q: string) => {
    const action = this.getActionBasedOnType(q, 1, false);
    action(this.state.cancelToken);
  }, 500);

  handleSearch = (q: string) => {
    this.setState({ searchQuery: q });
    this.filter(q);
  };

  handleLoadCallLogs = () => {
    const {
      callLogsStore: { callPageNum, setCallPageNum },
    } = this.props;
    setCallPageNum(callPageNum + 1);
    const action = this.getActionBasedOnType(this.state.searchQuery, 1, true);
    action(this.state.cancelToken);
  };

  messageUser = (personId: number) => {
    const { conversation, navigate } = this.props;
    conversation.loadOrCreateConversationWithPost(personId).then((resp) => {
      navigate(`/chat/conversations/${resp.data.id}/menu`);
      pushToGTMDataLayer('directoryMessageUser');
    });
  };

  loadConversation = (conversationId: string) => {
    const { navigate } = this.props;
    pushToGTMDataLayer('directorySelectConversation', {
      conversationId,
    });
    navigate(`/chat/conversations/${conversationId}/menu`);
  };

  getPerson = (item: ICallLog) => {
    const { person } = this.props;
    const personO = item.personId;
    if (!personO) return;
    try {
      return person.loadPersonByIdGetIfMissingGet(personO);
    } catch (err) {
      return null;
    }
  };

  getActiveTabTitle = (): string => {
    switch (this.props.typeOfCalls) {
      case CALL_LOG_CATEGORY.MISSED:
        return 'Missed calls';
      case CALL_LOG_CATEGORY.OUTGOING:
        return 'Outgoing calls';
      case CALL_LOG_CATEGORY.INCOMING:
        return 'Incoming calls';
      default:
        return 'All recent calls';
    }
  };

  missedCallEmptyPageText = () => {
    return <div>Way to go! You haven't missed any call!</div>;
  };

  outgoingCallEmptyPageText = () => {
    return (
      <>
        <div>This page is empty because you haven't called anyone yet.</div>
        <div>Go to your contacts list and call someone, anyone!</div>
      </>
    );
  };

  incomingCallEmptyPageText = () => {
    return <div>Bummer... You haven't received any call yet.</div>;
  };

  allCallEmptyPageText = () => {
    return (
      <>
        <div>Hmm... it seems you haven't placed or received any call yet.</div>
        <div>Nothing to show here.</div>
      </>
    );
  };

  emptyPageTextWhileSearching = () => {
    return (
      <>
        <div>Oh no! We couldn't find what you were looking for.</div>
        <div>Check your spelling or try searching for other terms.</div>
      </>
    );
  };

  createEmptyPageText = () => {
    if (this.state.searchQuery.trim().length <= 2) {
      switch (this.props.typeOfCalls) {
        case CALL_LOG_CATEGORY.MISSED:
          return this.missedCallEmptyPageText();
        case CALL_LOG_CATEGORY.OUTGOING:
          return this.outgoingCallEmptyPageText();
        case CALL_LOG_CATEGORY.INCOMING:
          return this.incomingCallEmptyPageText();
        default:
          return this.allCallEmptyPageText();
      }
    }
    return this.emptyPageTextWhileSearching();
  };

  getSearchPlaceholder = () => {
    return this.props.typeOfCalls === CALL_LOG_CATEGORY.MISSED
      ? 'Search missed calls'
      : `Search ${this.props.typeOfCalls || 'all'} call records`;
  };

  createDateGroup = (item: ICallLog) => {
    const today = DateTime.now();
    const date = DateTime.fromISO(item.startedAt);
    const dayDiff = today.diff(date, 'days').days;
    const firstDigit = Math.floor(dayDiff * 10);
    const difference = firstDigit === 0 ? 0 : ceil(dayDiff);
    let dateGroup = '';
    if (difference === 0) {
      dateGroup = 'Today';
    } else if (difference === 1) {
      dateGroup = 'Yesterday';
    } else if (difference > 1 && difference < 7) {
      dateGroup = date.toFormat('cccc');
    } else {
      dateGroup = date.toFormat('MM/dd/yyyy');
    }
    return dateGroup;
  };

  render() {
    const {
      ui,
      callLogsStore: { CallLogs, callLogsLoading, callPageNum, setCallPageNum },
      phoneCall,
      conversation,
      person,
      router,
    } = this.props;
    const { searchQuery } = this.state;
    const displayedSeparators = [];
    return (
      <ContentArea
        id="call-logs"
        inlineTopBanner={
          <CallLogMessageView
            offlineMode={this.state.offlineMode}
            lastSyncDate={this.props.callLogsStore.lastSyncDate}
          />
        }
      >
        <Heading variant="h2" className="header-logs">
          {this.getActiveTabTitle()}
        </Heading>
        <FilterBox
          placeholder={this.getSearchPlaceholder()}
          sendInput={this.handleSearch}
          loading={callLogsLoading}
          setPageNum={setCallPageNum}
          testid="-filterBoxCallDirectory"
        />
        {CallLogs?.length > 0 ? (
          <InfiniteScroll
            initialScrollY={1}
            dataLength={callPageNum * 20} //This is important field to render the next data
            next={this.handleLoadCallLogs}
            hasMore
            loader={<></>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>You have seen it all</b>
              </p>
            }
            // below props only if you need pull down functionality
            refreshFunction={this.handleLoadCallLogs}
            pullDownToRefresh
            pullDownToRefreshThreshold={4}
            scrollableTarget="directory-list"
          >
            <List
              id="directory-list"
              className="overflow-scroll-y-hidden"
              inverted
              verticalAlign="middle"
              size="large"
              selection
            >
              {CallLogs.map((item) => {
                const dateGroup = this.createDateGroup(item);
                const separatorShowed = displayedSeparators.includes(dateGroup);
                if (!separatorShowed) {
                  displayedSeparators.push(dateGroup);
                }
                return (
                  <>
                    {!separatorShowed && dateGroup !== 'Today' && (
                      <div key={dateGroup} className="call-log-separator">
                        {dateGroup}
                      </div>
                    )}
                    <CallLogListItem
                      key={item.id}
                      callLog={item}
                      uiStore={ui}
                      phoneStore={phoneCall}
                      conversation={conversation}
                      postConferenceByConversationId={
                        conversation.postConferenceByConversationId
                      }
                      personStore={person}
                      router={router}
                      loggedInPersonId={0}
                      loggedInPersonVideoFeature={
                        person.personAvaliableFeatures.video
                      }
                      setEditContact={person.setEditContact}
                      setIsAddingContact={person.setIsAddingContact}
                    />
                  </>
                );
              })}
            </List>
          </InfiniteScroll>
        ) : (
          <div className="no-calls-info">{this.createEmptyPageText()}</div>
        )}
      </ContentArea>
    );
  }
}

export default inject(
  STORE_CALL_LOGS,
  STORE_PERSON,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION
)(withRouter(observer(CallsDirectoryList)));
