import { SEARCHABLE_TYPE } from 'Constants/enums';
import { isEmpty } from 'lodash';
import { IContactDto } from 'Models/ContactModel';
import { IConversationModel } from 'Models/ConversationModel';
import { IPersonModel } from 'Models/PersonModel';

import grpIcon from 'Assets/images/group-icon.svg';
import phoneCallIcon from 'Assets/images/phone-call-icon.svg';
/** Common interface for all Models which can be search results. Partially maps to `Broadvoice.Messaging.ISearchableItem`. */
export interface ISearchableItem {
  id: number | string;
  created: string;
  /** Used as a Type Discriminator if present. May not be present on results loaded from API directly. */
  searchableType?: SEARCHABLE_TYPE;
}

export class SearchResult<TSource extends ISearchableItem> {
  public readonly match: string;
  public readonly resultType: SEARCHABLE_TYPE;
  public readonly score: number;
  public readonly source: TSource;

  public key: string;
  public text: string;
  public value: string;
  public label: string;
  public image?: string;
  constructor(source: TSource, match: string, score: number) {
    if (isEmpty(source.searchableType)) {
      throw new Error('source must have a valid searchableType');
    }
    this.source = source;
    this.resultType = source.searchableType;
    this.match = match;
    this.score = score;
    this.key = source.id?.toString();
    this.text = source['DisplayName'];
    this.value = source.id?.toString();
    this.label = source['firstName']
      ? takeCredentials(source['DisplayName'])
      : null;
    this.image = !source['firstName']
      ? source.searchableType === 'SearchableDetailsConversation'
        ? grpIcon
        : phoneCallIcon
      : null;
  }
}

const takeCredentials = (name: string): string => {
  const credentials = name?.split(' ').map((value) => value.charAt(0));
  return credentials?.join(' ');
};
export class SearchableItemTypeGuards {
  static isConversation(si: ISearchableItem): si is IConversationModel {
    return si.searchableType === 'SearchableDetailsConversation';
  }

  static isContact(si: ISearchableItem): si is IContactDto {
    return si.searchableType === 'SearchableDetailsContact';
  }

  static isPerson(si: ISearchableItem): si is IPersonModel {
    return si.searchableType === 'SearchableDetailsPerson';
  }
}

export default SearchResult;
