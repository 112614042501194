import { isEmpty } from 'lodash';
import { IMessageModelCall } from 'Models/MessageModel';

import activePresenceIcon from '../assets/images/active-icon.svg';
import idlePresenceIcon from '../assets/images/idle-icon.svg';
import doNotDisturbIcon from '../assets/images/do-not-disturb.svg';
import invisibleIcon from '../assets/images/invisible.svg';
import PHONE_INCOMING_128 from '../assets/images/phone-incoming-128.png';
import PHONE_OUTGOING_128 from '../assets/images/phone-outgoing-128.png';
import PHONE_MISSED_128 from '../assets/images/phone-missed-128.png';
import PHONE_OTHER_128 from '../assets/images/phone-other-blue-128.png';
export {
  activePresenceIcon,
  idlePresenceIcon,
  doNotDisturbIcon,
  invisibleIcon,
  PHONE_INCOMING_128,
  PHONE_OUTGOING_128,
  PHONE_MISSED_128,
  PHONE_OTHER_128,
};

export const PHONE_INCOMING_128_S3 =
  'https://s3.amazonaws.com/communicator-static/phone-incoming-128.png'; // For electron, must use a publicly available static image for desktop notif (on Windows)

export const FAST_EMOJIS = [
  {
    emoji: '😄',
    message: 'Happy',
  },
  {
    emoji: '😞',
    message: 'Sad',
  },
  {
    emoji: '🍽',
    message: 'Lunch',
  },
  {
    emoji: '🤒',
    message: 'Sick',
  },
];

const buildImageUri = (imgName) => {
  return `${imgName}`;
};

export const GET_CALL_DIRECTION_IMAGE = (msgCall: IMessageModelCall) => {
  if (isEmpty(msgCall)) {
    throw new Error(
      'constants/assets/GET_CALL_DIRECTION_IMAGE requires a populated messageCall'
    );
  }
  if (msgCall.direction === 'Incoming') {
    return msgCall.disposition === 'Missed'
      ? buildImageUri(PHONE_MISSED_128)
      : buildImageUri(PHONE_INCOMING_128);
  } else if (msgCall.direction === 'Outgoing') {
    return buildImageUri(PHONE_OUTGOING_128);
  } else {
    return buildImageUri(PHONE_OTHER_128);
  }
};
