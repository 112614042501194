import { BodyCopy } from 'Components/shared/BodyCopy';
import styled from 'styled-components';

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const DividerLine = styled.hr`
  flex-grow: 1;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

const DividerText = styled(BodyCopy)`
  margin: 0 10px;
  text-align: center;
`;

export const Styled = {
  DividerContainer,
  DividerLine,
  DividerText,
};
