import React, { useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import { MoreButtonsMenuItemProps } from './interface';

export function MoreButtonsMenuItem(props: MoreButtonsMenuItemProps) {
  const {
    wrapperClassname,
    imageClassname,
    clickHandler,
    buttonId,
    image,
    buttonDisabled,
    textContent,
    textClassname,
    setShowMoreOptionsModal,
  } = props;

  useEffect(() => {
    return () => {
      setShowMoreOptionsModal(false);
    };
  }, []);

  return (
    <div className="action-buttons flex-column left-1">
      <div className="flex-row flex-align-items-center flex-justify-center">
        <div className={wrapperClassname}>
          {image && (
            <Button
              onClick={clickHandler}
              id={buttonId}
              size="small"
              className="video-icon-relative"
              compact
              basic
              disabled={buttonDisabled}
            >
              <img className={'small-icon ' + imageClassname} src={image} />
            </Button>
          )}
        </div>
        <span
          style={{ textAlign: 'left' }}
          className={textClassname}
          onClick={clickHandler}
        >
          {textContent}
        </span>
      </div>
    </div>
  );
}
