import * as React from 'react';
import { formatNumberWithNationalCode } from 'Utils/phoneUtil';

import { TextLink } from '../../shared/TextLink';
import type { DirectoryLinkListProps } from '../../DirectoryPanel/DirectoryListItem/DirectoryLinks/types';
import type { IconDefinition } from '../../shared/Icon';
import type { ContactsLinksProps } from './types';
import { observer } from 'mobx-react';

export const ContactsLinksObserver: React.FC<ContactsLinksProps> = ({
  contact,
  phoneCall,
}) => {
  const testid = 'contactsLinks';

  const CONTACTS_LINKS: DirectoryLinkListProps = [
    ...contact.phoneNumbers?.map(({ number }, index) => ({
      testid: `${testid}-linkCall${index}`,
      content: formatNumberWithNationalCode(number),
      icon: 'on-call' as IconDefinition,
      props: { onClick: () => phoneCall.callWithPerson(null, number) },
    })),
    ...contact.emails?.map(({ email }, index) => ({
      testid: `${testid}-linkEmail${index}`,
      content: email,
      icon: 'message' as IconDefinition,
      props: { href: `mailto:${email}` },
    })),
  ];

  return (
    <React.Fragment>
      {CONTACTS_LINKS.map(({ content, icon, testid, props }) =>
        content ? (
          <TextLink key={content} data-private {...{ icon, testid, ...props }}>
            {content}
          </TextLink>
        ) : null
      )}
    </React.Fragment>
  );
};

export const ContactsLinks = observer(ContactsLinksObserver);
