import React from 'react';
import { Image } from 'semantic-ui-react';
import { Styled } from './index.styles';
import { Contact } from '../../models/Contacts';
import { UserAvatar } from '../shared/UserAvatar';
import type { AvatarComponentProps } from './types';
const videoAvatar = require('../../assets/images/video-avatar.jpg');

export const Avatar = ({
  message,
  person,
  conversationId,
  selectUnreadCounts,
  selectPersonPresenceStatus
}: AvatarComponentProps) => {
  return (
    <Styled.AvatarContainer>
      {message.systemEvent?.source?.includes('Voxeet') ? (
        <Image src={videoAvatar} size="mini" />
      ) : person.data instanceof Contact &&
        person.data?.pictureUrl ? (
        <img
          className="contact-prof-img"
          src={person.data?.pictureUrl}
        />
      ) : (
        <UserAvatar
          name={person.data.DisplayName}
          conversationId={conversationId}
          selectUnreadCounts={selectUnreadCounts}
          presence={selectPersonPresenceStatus(person.data.id)}
        />
      )}
    </Styled.AvatarContainer>
  );
};