import React from 'react';

import { Styled } from './index.styles';
import { ContentAreaProps } from './types';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from '../../../constants/responsiveness';
import { inject, observer } from 'mobx-react';
import { STORE_UI } from '../../../constants/stores';

export const ContentAreaObserver: React.FC<ContentAreaProps> = ({
  id,
  children,
  inlineTopBanner,
}) => {
  return (
    <Styled.ContentAreaWrapper {...{ id }}>
      {inlineTopBanner}
      <Styled.ContentArea $inlineTopBanner={!!inlineTopBanner}>
        <Styled.Content>{children}</Styled.Content>
      </Styled.ContentArea>
    </Styled.ContentAreaWrapper>
  );
};

export const ContentArea = inject(STORE_UI)(observer(ContentAreaObserver));
