import styled, { css } from 'styled-components';
import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import type { BodyCopyProps } from './types';

const BODYCOPY_VARIANTS: {
  [prop in BodyCopyProps['variant']]: FlattenInterpolation<
    ThemeProps<DefaultTheme>
  >;
} = {
  paragraph: css`
    font: normal normal normal 13px/18px Open Sans;
  `,
  subtitles: css`
    font: normal normal normal 11px/16px Open Sans;
  `,
};

const BodyCopy = styled.p<{
  $variant: BodyCopyProps['variant'];
  $noMargin: boolean;
  $ellipsis: boolean;
}>`
  max-width: 65ch;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.text};
  ${({ $variant }) => $variant && BODYCOPY_VARIANTS[$variant]}
  ${({ $noMargin }) => $noMargin && 'margin: 0;'}
  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

export const Styled = {
  BodyCopy,
};
