import { action, observable, makeObservable } from 'mobx';

export interface IMessageStatusModel extends IMessageStatus {
  personId: number;
}

export interface IMessageStatus {
  message: string;
  title: string;
}

export class MessageStatusModel implements IMessageStatusModel {
  constructor(personId: number, title: string, message: string) {
    makeObservable(this);
    this.personId = personId;
    this.message = message;
    this.title = title;
  }

  static CreateOffLineStatus = (personId: number) =>
    new MessageStatusModel(personId, null, null);
  static FromResponseDto = (responseDto: IMessageStatusModel) =>
    new MessageStatusModel(
      responseDto.personId,
      responseDto.title,
      responseDto.message
    );

  static FromPusherResponseDto = (responseDto: IMessageStatusModel) =>
    new MessageStatusModel(
      responseDto.personId,
      responseDto.title,
      responseDto.message
    );

  @observable
  public personId: number;

  @observable
  message: string;

  @observable
  title: string;

  @action
  setMessage = (message: string) => (this.message = message);

  @action
  setTitle = (title: string) => (this.title = title);
}

export default MessageStatusModel;
