import React from 'react';

import type { DialpadIconButtonProps } from './types';
import { Styled } from './index.styles';
import { inject, observer } from 'mobx-react';
import { STORE_PHONE_CALL, STORE_UI } from '../../../constants/stores';

export const DialpadIconButtonObserver: React.FC<DialpadIconButtonProps> = ({
  testid,
  phoneCall,
  ui,
}) => {
  const disabled =
    !!phoneCall.ActivePhoneCall || !!phoneCall.incomingPhoneCalls?.length;

  const handleOpenDialpad = () => {
    ui.openedRightSidebarsOrder.has('dial-pad')
      ? ui.removeFromOpenedRightSidebarsOrder('dial-pad')
      : ui.setOpenedRightSidebarsOrder('dial-pad');
    ui.setOpenTopbarDialpad(!ui.openTopbarDialpad);
  };

  return (
    <Styled.IconButton
      content={
        disabled ? "Can't access the dial pad during a call" : 'Dial pad'
      }
      compact
      icon="dialpad"
      onClick={handleOpenDialpad}
      popupPosition="bottom center"
      size="medium"
      testid={`${testid}-buttonDialPad`}
      variant="secondary"
      active={ui.openTopbarDialpad}
      {...{ disabled }}
    />
  );
};

export const DialpadIconButton = inject(
  STORE_PHONE_CALL,
  STORE_UI
)(observer(DialpadIconButtonObserver));
