import ChannelContainer from 'Containers/Channel';
import { inject, observer } from 'mobx-react';
import React from 'react';
import ContextContentLoadingPanel from '../../components/ContextContentLoadingPanel';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PUSHER,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_UI,
} from '../../constants/stores';
import { ContextPanelProps } from '../ContextPanel/interfaces';
import { ContentArea } from '../../components/shared/ContentArea';

class ContextContentLoadingContainer extends React.Component<ContextPanelProps> {
  render() {
    const { ui } = this.props;
    return (
      <div className="flex-grow padding-20 voicemails position-relative">
        <ChannelContainer
          openGroupModal={ui.groupModalOpen}
          {...this.props}
          {...this.props.browserHistory}
        />
        <ContentArea>
          <ContextContentLoadingPanel {...{ ui }} />
        </ContentArea>
      </div>
    );
  }
}
export default inject(
  STORE_PERSON,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION,
  STORE_PUSHER
)(observer(ContextContentLoadingContainer));
