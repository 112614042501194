import * as React from 'react';
import ItemsCarousel from 'react-items-carousel';
import { imageFileTypes } from '../../constants/enums';
import { FileTypeModel } from '../../models/FileType';
import { MessageStore, UiStore } from '../../stores';

const closeBtn = require('../../assets/images/close.svg');
const rightChevronIcon = require('../../assets/images/right-chevron.svg');

export default class UploadedFiles extends React.Component<
  { ui: UiStore; message: MessageStore },
  { activeItemIndex: number }
> {
  constructor(props) {
    super(props);
    this.state = {
      activeItemIndex: 0,
    };
  }
  handleRemoveFile = (name: string) => () => {
    const { ui, message } = this.props;
    ui.setDroppedFiles(ui.droppedFiles.filter((file) => file.name !== name));
    if (ui.droppedFiles.length === 0 && ui.filesUploadingError) {
      ui.setFilesUploadingError(false);
    }

    if (message.fileUploadedS3.has(name)) {
      message.fileUploadedS3.delete(name);
    }
    document.getElementById('context-content').click();
  };
  handleImageOnLoad = async (e, file: FileTypeModel) => {
    file.setHeight(e.currentTarget?.naturalHeight);
    file.setWidth(e.currentTarget?.naturalWidth);
  };
  render() {
    const {
      ui: { droppedFiles },
    } = this.props;
    return (
      <div className="inserted-files-wrapper">
        <ItemsCarousel
          infiniteLoop={false}
          gutter={12}
          activePosition={'center'}
          chevronWidth={80}
          disableSwipe={false}
          alwaysShowChevrons={false}
          numberOfCards={3}
          slidesToScroll={1}
          outsideChevron={true}
          showSlither={true}
          firstAndLastGutter={true}
          activeItemIndex={this.state.activeItemIndex}
          requestToChangeActive={(value) =>
            this.setState({ activeItemIndex: value })
          }
          rightChevron={<img src={rightChevronIcon} />}
          leftChevron={<img className="left-chevron" src={rightChevronIcon} />}
        >
          {droppedFiles.map((file, index) => {
            const isImage = imageFileTypes.includes(file.type);
            return (
              <div
                className={`inserted-file ${isImage ? 'no-padding' : ''}`}
                key={file.name}
              >
                <img
                  src={file.icon}
                  className={
                    isImage
                      ? file.type === 'image/svg+xml'
                        ? 'svg-only'
                        : 'image-only'
                      : ''
                  }
                  onLoad={(e) => this.handleImageOnLoad(e, file)}
                />
                {!isImage && <span>{file.name}</span>}
                <div
                  className="remove-file"
                  onClick={this.handleRemoveFile(file.name)}
                >
                  <img src={closeBtn} />
                </div>
              </div>
            );
          })}
        </ItemsCarousel>
      </div>
    );
  }
}
