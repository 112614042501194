import { DIRECTORY_SEARCH_ID_TYPES, SEARCHABLE_TYPE } from 'Constants/enums';
import { API_ENDPOINTS, NODE_ENV_PRODUCTION } from 'Constants/env';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import {
  action,
  IObservableArray,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import { fromPromise, createTransformer } from 'mobx-utils';
import { FromContactResponseDto, IContactDto } from 'Models/ContactModel';
import {
  ConversationModel,
  IConversationModel,
} from 'Models/ConversationModel';
import {
  DirectorySearchModel,
  PersonConversationOrContactModel,
  PersonModel,
  SearchModel,
  SearchResult,
} from 'Models/index';
import { IPersonModel } from 'Models/PersonModel';
import { SearchModelDirectory } from 'Models/SearchModel';
import { SearchableItemTypeGuards } from 'Models/SearchResult';
import { BaseStore } from 'Stores/BaseStore';
import { RootStore } from 'Stores/RootStore';
import { isNullOrUndefined } from 'util';
import { bugsnagClient } from 'Utils/logUtils';
import API from '../api';

export class SearchStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @observable
  public searchValue: string;

  @action
  setDirectorySearchValue = (input: string) => (this.searchValue = input);

  @observable
  public directoryPageNumber: number = 1;

  @action
  setDirectoryPageNumber = (pageNumber: number) =>
    (this.directoryPageNumber = pageNumber);

  @observable
  public contactPageNumber: number = 1;

  @action
  setContactPageNumber = (pageNumber: number) =>
    (this.contactPageNumber = pageNumber);

  @observable
  public directoryMap: Map<string, DirectorySearchModel> = new Map();

  @action
  setDirectoryMap = (
    directoryId: DIRECTORY_SEARCH_ID_TYPES,
    directorySearch: DirectorySearchModel
  ) => {
    const model = new DirectorySearchModel(
      directorySearch.query,
      directorySearch.data
    );
    this.directoryMap.set(directoryId, model);
  };
  selectDirectorySearchById = createTransformer(
    (directoryId: DIRECTORY_SEARCH_ID_TYPES) => {
      if (this.directoryMap.has(directoryId)) {
        return this.directoryMap.get(directoryId);
      }
      return null;
    }
  );

  @observable
  public isContactListSearchLoading: boolean = false;
  @action
  setIsContactListSearchLoading = (isLoading: boolean) => {
    this.isContactListSearchLoading = isLoading;
  };

  @observable
  public isDirectoryLoading: boolean = false;

  @observable
  public isTopBarDirectoryLoading: boolean = false;

  @observable
  public isConversationDirectoryLoading: boolean = false;

  @action
  setIsDirectoryLoading = (isLoading: boolean) => {
    this.isDirectoryLoading = isLoading;
  };

  @action
  setTopBarDirectoryLoading = (isLoading: boolean) => {
    this.isTopBarDirectoryLoading = isLoading;
  };

  @action
  setConversationDirectoryLoading = (isLoading: boolean) => {
    this.isConversationDirectoryLoading = isLoading;
  };

  @observable
  public isTransferDialpadLoading: boolean = false;
  @action
  setIsTransferDialpadLoading = (isLoading: boolean) => {
    this.isTransferDialpadLoading = isLoading;
  };

  @action
  setIsLoadingByDirectorySearchId = (
    directorySearchId: DIRECTORY_SEARCH_ID_TYPES,
    isLoading: boolean
  ) => {
    if (directorySearchId === 'CONTACTS') {
      this.setIsContactListSearchLoading(isLoading);
    } else if (directorySearchId === 'TRANSFER') {
      this.setIsTransferDialpadLoading(isLoading);
    } else if (directorySearchId === 'USERS') {
      this.setTopBarDirectoryLoading(isLoading);
    } else if (directorySearchId === 'CONVERSATIONS') {
      this.setConversationDirectoryLoading(isLoading);
    } else if (directorySearchId === 'DIRECTORY') {
      this.setIsDirectoryLoading(isLoading);
    } else {
      console.warn(
        `Could not set isLoading for unknown directoryId "${directorySearchId}" - Setting all loading flags to false.`
      );
      this.setIsContactListSearchLoading(false);
      this.setIsTransferDialpadLoading(false);
      this.setIsDirectoryLoading(false);
      this.setTopBarDirectoryLoading(false);
    }
  };

  @action
  clearAllData = () => {
    this.isDirectoryLoading = false;
    this.isTopBarDirectoryLoading = false;
    this.isContactListSearchLoading = false;
    this.isTransferDialpadLoading = false;
    this.directoryMap.clear();
  };

  /** Search for `Person`s and `Contact`s by default, and optionally `Conversation`s (if `includeConversations` is true) */
  @action
  getPersonContactSearch = (
    directorySearchId: DIRECTORY_SEARCH_ID_TYPES,
    query: string,
    searchableTypes: SEARCHABLE_TYPE[] = [
      'SearchableDetailsPerson',
      'SearchableDetailsContact',
    ],
    pageSize: number = 20,
    pageNumber: number = directorySearchId === 'CONTACTS'
      ? this.contactPageNumber
      : this.directoryPageNumber,
    appendResults: boolean = false
  ) => {
    //on user input, searchPageNum is default value
    const searchPageNum = query ? 1 : pageNumber;
    const directoryResults = fromPromise(
      API.get(
        API_ENDPOINTS.Search(query, searchableTypes, pageSize, searchPageNum)
      )
    );
    return directoryResults.then(
      (resp) => {
        this.getPersonContactsSearchSuccess(
          directorySearchId,
          query,
          resp,
          searchableTypes,
          pageSize,
          searchPageNum,
          appendResults
        );
        return resp;
      },
      (err) => {
        this.getDirectorySearchError(directorySearchId, err);
        return err;
      }
    );
  };

  @action
  getDirectorySearch = async (
    directorySearchId: DIRECTORY_SEARCH_ID_TYPES,
    query: string,
    pageSize: number = 20,
    pageNumber: number = directorySearchId === 'CONTACTS'
      ? this.contactPageNumber
      : this.directoryPageNumber,
    appendResults: boolean = false
  ) => {
    //on user input, searchPageNum is default value
    const searchPageNum = query ? 1 : pageNumber;
    if (
      !this.isTopBarDirectoryLoading &&
      !this.isTransferDialpadLoading &&
      !this.isContactListSearchLoading
    ) {
      try {
        this.setIsLoadingByDirectorySearchId(directorySearchId, true);
        const directoryResultsPbo = await fromPromise(
          API.get(
            API_ENDPOINTS.DirectorySearch(
              query,
              'firstName',
              pageSize,
              searchPageNum
            )
          )
        );
        const directoryResults = directoryResultsPbo.data;
        if (directoryResults.people?.length === 0) {
          this.setIsLoadingByDirectorySearchId(directorySearchId, false);
        }
        this.getDirectorySearchSuccess(
          directorySearchId,
          query,
          directoryResultsPbo,
          appendResults
        );
        return directoryResultsPbo;
      } catch (err) {
        this.getDirectorySearchError(directorySearchId, err);
        return err;
      }
    }
  };
  /**
   * Process search results
   * @param directorySearchId Which directory this search is for
   * @param query The search text query
   * @param response Search response
   * @param appendResults (Default: `false`) If `true`, append results to the existing `DirectorySearchModel`, instead of creating new results
   */

  getDirectorySearchSuccess = (
    directorySearchId: DIRECTORY_SEARCH_ID_TYPES,
    query: string,
    response: AxiosResponseT<SearchModelDirectory<IPersonModel>>,
    appendResults: boolean = false
  ) => {
    if (response.status === 200) {
      const hits = response.data.hits;
      let model: SearchModel<PersonModel> = null;
      if (!appendResults) {
        model = new SearchModel<PersonModel>();
        model.hits = hits;
      } else {
        const exSearch = this.selectDirectorySearchById(directorySearchId);
        if (!isNullOrUndefined(exSearch)) {
          // re-use the `hits` value, as it will be the same
          model = exSearch.data as any;
        } else {
          // fallback
          model = new SearchModel<PersonModel>();
          model.hits = hits;
        }
      }
      if (response.data?.people?.length > 0) {
        response.data.people.forEach((item) => {
          runInAction(() => {
            const pers = PersonModel.FromResponseDto(item);
            if (!model.results.some((item) => item.source.id === pers.id)) {
              pers['searchableType'] = 'SearchableDetailsPerson';
              model.results.push(new SearchResult(pers, '', 0.0));
            }
          });
        });
      } else {
        runInAction(() => {
          model.results =
            model.results.length > 0
              ? model.results
              : ([] as IObservableArray<SearchResult<PersonModel>>);
        });
      }
      this.setDirectoryMap(
        directorySearchId,
        new DirectorySearchModel(query, model)
      );
      this.setIsLoadingByDirectorySearchId(directorySearchId, false);
    } else {
      this.setIsLoadingByDirectorySearchId(directorySearchId, false);
      const errMsg = `getPersonContactsSearchSuccess response status was ${response.status}, where 200 was expected. Search results were not processed.`;
      bugsnagClient.notify(errMsg, (event) => {
        event.severity = 'error';
        event.context = 'SearchStore';
        event.addMetadata('custom', {
          function: 'getPersonContactsSearchSuccess',
        });
      });
    }
  };
  getPersonContactsSearchSuccess = (
    directorySearchId: DIRECTORY_SEARCH_ID_TYPES,
    query: string,
    response: AxiosResponseT<
      SearchModel<IPersonModel | IConversationModel | IContactDto>
    >,
    searchableTypes: SEARCHABLE_TYPE[] = [
      'SearchableDetailsPerson',
      'SearchableDetailsContact',
    ],
    pageSize: number = 20,
    pageNumber: number = 1,
    appendResults: boolean = false
  ) => {
    if (response.status === 200) {
      const hits = response.data.hits;
      let model: SearchModel<PersonConversationOrContactModel> = null;
      if (!appendResults) {
        model = new SearchModel<PersonConversationOrContactModel>();
        model.hits = hits;
      } else {
        const exSearch = this.selectDirectorySearchById(directorySearchId);
        if (!isNullOrUndefined(exSearch)) {
          // re-use the `hits` value, as it will be the same
          model = exSearch.data;
        } else {
          // fallback
          model = new SearchModel<PersonConversationOrContactModel>();
          model.hits = hits;
        }
      }

      let hasResultTypeError = false;
      response.data.results.forEach((item) => {
        runInAction(() => {
          if (SearchableItemTypeGuards.isContact(item.source)) {
            const ct = FromContactResponseDto(item.source);
            model.results.push(new SearchResult(ct, item.match, item.score));
          } else if (SearchableItemTypeGuards.isConversation(item.source)) {
            const conv = ConversationModel.FromResponseDto(item.source);
            model.results.push(new SearchResult(conv, item.match, item.score));
          } else if (SearchableItemTypeGuards.isPerson(item.source)) {
            const pers = PersonModel.FromResponseDto(item.source);
            if (!model.results.some((item) => item.source.id === pers.id)) {
              model.results.push(
                new SearchResult(pers, item.match, item.score)
              );
            }
          } else {
            hasResultTypeError = true;
          }
        });
      });

      if (hasResultTypeError) {
        const errMsg =
          'One or more search result(s) have invalid or missing searchableType, this may indicate a problem with the API';
        console.error(errMsg);
        bugsnagClient.notify(errMsg, (event) => {
          event.severity = 'error';
          event.context = 'SearchStore';
          event.addMetadata('custom', {
            function: 'getPersonContactsSearchSuccess',
          });
        });
        if (!NODE_ENV_PRODUCTION) {
          this.rootStore.notificationStore.addNotification(
            errMsg,
            'Invalid Search Results',
            'error',
            'tc',
            10
          );
        }
      }
      this.setDirectoryMap(
        directorySearchId,
        new DirectorySearchModel(query, model)
      );
      this.setIsLoadingByDirectorySearchId(directorySearchId, false);
      return this.directoryMap.get(directorySearchId);
    } else {
      this.setIsLoadingByDirectorySearchId(directorySearchId, false);
      const errMsg = `getPersonContactsSearchSuccess response status was ${response.status}, where 200 was expected. Search results were not processed.`;
      console.error(errMsg);
      bugsnagClient.notify(errMsg, (event) => {
        event.severity = 'error';
        event.context = 'SearchStore';
        event.addMetadata('custom', {
          function: 'getPersonContactsSearchSuccess',
        });
      });
      return false;
    }
  };

  @action
  getDirectorySearchError = (
    directorySearchId: DIRECTORY_SEARCH_ID_TYPES,
    error
  ) => {
    this.setIsLoadingByDirectorySearchId(directorySearchId, false);
    this.rootStore.notificationStore.addAxiosErrorNotification(
      error,
      'Error Searching Directory',
      true,
      'tc',
      10,
      true
    );
    bugsnagClient.notify('Error Searching Directory', (event) => {
      event.severity = 'error';
      event.context = 'SearchStore';
      event.addMetadata('custom', { function: 'getDirectorySearchError' });
    });
  };

  searchPersonBySip = async (sip: string): Promise<PersonModel | null> => {
    try {
      const results = await API.get<PersonModel>(API_ENDPOINTS.SipSearch(sip));
      return results.data;
    } catch (error) {
      if (error?.response?.status !== 404) {
        this.rootStore.notificationStore.addAxiosErrorNotification(
          error,
          'Error Searching Person by SIP',
          true,
          'tc',
          10,
          true
        );
        bugsnagClient.notify('Error Searching Person by SIP', (event) => {
          event.severity = 'error';
          event.context = 'SearchStore';
          event.addMetadata('custom', { function: 'searchPersonBySip' });
        });
      }
      return null;
    }
  };
}

export default SearchStore;
