import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { IMessageModelConference } from 'Models/MessageModel';
import * as React from 'react';
import { isNullOrUndefined } from 'util';
import { ConversationModel } from '../../models';

export interface IContextContentItemConferenceProps {
  conference: IMessageModelConference;
  conversationId: string;
  loggedInUserActiveConferenceConversation: ConversationModel | null;
  navigateToConferenceByConfId: (conferenceId: string) => any;
  event: string;
  removeVideoConferenceFromList: (conferenceId: string) => void;
}

export class ContextContentItemConference extends React.Component<
  IContextContentItemConferenceProps,
  { showAttendees: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      showAttendees: false,
    };
  }
  handleJoinLinkClick = () => {
    const {
      conference,
      navigateToConferenceByConfId,
      removeVideoConferenceFromList,
    } = this.props;
    navigateToConferenceByConfId(conference.id);
    removeVideoConferenceFromList(conference.id);
  };
  handleHover = () =>
    this.setState({ showAttendees: !this.state.showAttendees });

  Attendees = () => {
    const { conference } = this.props;
    const participantsNoFirst = [...conference?.attendees];
    participantsNoFirst.shift();
    return (
      <ul className="attendees">
        {!isEmpty(participantsNoFirst) &&
          participantsNoFirst?.map((participant, index) => (
            <li key={participant.id}>
              {index > 0 ? ', ' : ''}
              {participant.name}
            </li>
          ))}
      </ul>
    );
  };
  ConferenceHeader = () => {
    const { conference } = this.props;
    const userIsInActiveVideoConference = !isNullOrUndefined(
      this.props.loggedInUserActiveConferenceConversation
    );
  const isMeetingForLater = conference?.type === 'AdHocScheduled'
    return conference?.end ? (
      <div className="summary conference-link">
        <span>{`Video Conference: `}</span>

        <p className="chat-ended">ended! &nbsp;</p>
      </div>
    ) : (
      <div className="summary conference-link">
        <span>{`Hi, ${isMeetingForLater ? `I’ve created a meeting for later:` : `I’ve started a Video Conference:`}`}</span>
        <p
          onClick={() =>
            !userIsInActiveVideoConference ? this.handleJoinLinkClick() : false
          }
          className={`join-me-now-link ${
            userIsInActiveVideoConference ? 'cursor-not-allowed' : ''
          }`}
        >
          {
          isMeetingForLater ?
          'Join here!' :
          'Join me now!'
          }
          &nbsp;
        </p>
        {userIsInActiveVideoConference && (
          <span className="cannot-join-message">
            (To join, please end other video conference)
          </span>
        )}
      </div>
    );
  };

  ConferenceFooter = () => {
    const { conference } = this.props;
    return (
      <div className="conference-footer">
        <span> Duration: {conference?.duration} </span>
        <span> Recorded: {conference?.hasRecording ? 'Yes' : 'No'} </span>
      </div>
    );
  };
  render() {
    const { conference } = this.props;
    const firstParticipant = conference?.attendees[0];
    const onlyOneParticipant =
      firstParticipant && conference?.attendees.length === 1;
    const text =
      onlyOneParticipant || isNullOrUndefined(firstParticipant)
        ? ''
        : ` + ${conference?.attendees.length - 1} other `;

    return (
      <div className="system-event call-summary">
        <this.ConferenceHeader />
        <div className="has-joined">
          {firstParticipant?.name}
          <div
            className={onlyOneParticipant ? '' : 'pointer'}
            onMouseLeave={this.handleHover}
            onMouseEnter={this.handleHover}
          >
            {text}
            {this.state.showAttendees && <this.Attendees />}
          </div>
          {firstParticipant && <span>has joined the conference.</span>}
        </div>
        {conference.duration && <this.ConferenceFooter />}
      </div>
    );
  }
}

export default observer(ContextContentItemConference);
