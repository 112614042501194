import cx from 'classnames';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Styled } from './index.styles';
import { Avatar } from './Avatar';
import { BodyCopy } from 'Components/shared/BodyCopy';
import { MessageBubble } from 'Components/shared/MessageBubble';
import type { ChatContentItemProps } from './types';
import ContextContentItemConferenceObserver from '../ContextContentItemConference';
import ContextContentItemSystemEventObserver from '../ContextContentItemSystemEvent';
import ContextContentItemCallObserver from '../ContextContentItemCall';
import moment from 'moment';

export const parseMessageDate = (messageDate) => {
  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  const currentYearStart = moment().startOf('year');

  if (messageDate.isSame(today, 'day')) {
    return messageDate.format('hh:mm A');
  } else if (messageDate.isSame(yesterday, 'day')) {
    return `Yesterday ${messageDate.format('hh:mm A')}`;
  } else if (today.diff(messageDate, 'days') < 7) {
    return messageDate.format('dddd hh:mm A');
  } else if (messageDate.isAfter(currentYearStart)) {
    return messageDate.format('MMM DD hh:mm A');
  }

  return messageDate.format('MM/DD/YYYY hh:mm A');
};

export const ChatContentItem = (props: ChatContentItemProps) => {
  const {
    callWithPerson,
    conversationId,
    curConversation,
    getContact,
    getPerson,
    navigateToConferenceByConfId,
    loggedInUserActiveConferenceConversation,
    conversationStore,
    selectUnreadCounts,
    message,
    selectPersonPresenceStatus,
    getExtrContactByPhoneNumber,
    messageGroup,
    loggedInPersonId,
  } = props;

  const [person, setPerson] = useState(null);
  const [contact, setContact] = useState(null);
  const fetchPerson = async (data) => {
    const result = await getPerson(data);
    setPerson(result);
  };

  const fetchContact = async (data) => {
    const result = await getExtrContactByPhoneNumber(data);
    setContact(result);
  };

  const eventTypesWeSupport = [
    'Conversation.Created',
    'Conversation.Participants.Removed',
    'Conversation.Participants.Added',
  ];

  const event = message.systemEvent?.eventType;
  const shouldRender =
    (eventTypesWeSupport.includes(event) || !event) &&
    message?.conference?.provider !== 'Voxeet';

  useEffect(() => {
    if (
      message.personId !== null &&
      message.personId !== undefined &&
      message.personId > 0
    ) {
      fetchPerson(
        message.systemEvent && message.systemEvent?.payload?.personId
          ? message.systemEvent.payload.personId
          : message.personId
      );
    } else if (message.phone !== null && message.phone !== undefined) {
      fetchContact(message.phone);
    }
  }, [message.personId, message.phone]);

  if (!shouldRender) {
    return null;
  }

  const parsedMessageDate = parseMessageDate(message.CreatedMoment);

  return (
    <Styled.FeedEvent id={message.id}>
      <div
        className={cx('content', {
          'content-grouped': !(message.isFirstIn5Mins || event),
          'flex-row-reverse': message.conference || message.call,
        })}
      >
        {(!isEmpty(message.chat) ||
          !isEmpty(message.call) ||
          message.conference ||
          (message.documents && isEmpty(message.sms)) ||
          message.isDeleted) &&
          person && (
            <MessageBubble
              AvatarComponent={
                <Avatar
                  message={message}
                  person={person}
                  conversationId={conversationId}
                  selectUnreadCounts={selectUnreadCounts}
                  selectPersonPresenceStatus={selectPersonPresenceStatus}
                />
              }
              messageDate={parsedMessageDate}
              isOwnMessage={loggedInPersonId === message.personId}
              content={
                message.chat
                  ? message.chat.text
                  : message.isDeleted && !message.call
                  ? '*(Message Deleted)*'
                  : ''
              }
              displayName={`${person?.data?.firstName} ${
                person?.data?.lastName
              }${
                !isEmpty(message.id) &&
                !messageGroup.selectIsMessageRead(message.id)
                  ? ' *'
                  : ''
              }`}
            />
          )}

        {!isEmpty(message.sms) && contact && (
          <MessageBubble
            AvatarComponent={
              <Styled.PhoneContainer>
                <Styled.PhoneIcon size="small" icon={'phone'} />
              </Styled.PhoneContainer>
            }
            messageDate={parsedMessageDate}
            isOwnMessage={loggedInPersonId === message.personId}
            content={message.sms.text}
            displayName={contact.DisplayName()}
          />
        )}

        {!isEmpty(message.sms) && person && (
          <MessageBubble
            AvatarComponent={
              <Avatar
                message={message}
                person={person}
                conversationId={conversationId}
                selectUnreadCounts={selectUnreadCounts}
                selectPersonPresenceStatus={selectPersonPresenceStatus}
              />
            }
            messageDate={parsedMessageDate}
            isOwnMessage={loggedInPersonId === message.personId}
            content={message.sms.text}
            displayName={`${person.data.firstName} ${person.data.lastName}${
              !isEmpty(message.id) &&
              !messageGroup.selectIsMessageRead(message.id)
                ? ' *'
                : ''
            }`}
          />
        )}

        {!message.chat?.text && message.call && message.isDeleted ? (
          <BodyCopy variant="paragraph">{'(Message Deleted)'}</BodyCopy>
        ) : (
          <>
            {message.systemEvent && (person || contact) && (
              <ContextContentItemSystemEventObserver
                conversation={curConversation}
                messageModel={message}
                getPerson={getPerson}
                personId={message.personId}
                getContact={getContact}
              />
            )}
            {message.conference && (person || contact) && (
              <ContextContentItemConferenceObserver
                removeVideoConferenceFromList={
                  conversationStore.removeVideoConferenceFromList
                }
                conversationId={conversationId}
                event={message.systemEvent?.eventType}
                conference={message.conference}
                navigateToConferenceByConfId={navigateToConferenceByConfId}
                loggedInUserActiveConferenceConversation={
                  loggedInUserActiveConferenceConversation
                }
              />
            )}
            {!isEmpty(message.call) && (person || contact) && (
              <ContextContentItemCallObserver
                message={message}
                callWithPerson={callWithPerson}
                getPerson={getPerson}
                getExtrContactByPhoneNumber={getExtrContactByPhoneNumber}
              />
            )}
          </>
        )}
      </div>
    </Styled.FeedEvent>
  );
};

export default observer(ChatContentItem);
