import cx from 'classnames';
import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import { UserAvatar } from '../shared/UserAvatar';
import { CallLogListItemProps } from './interface';
import { useState } from 'react';
import { Contact } from '../../models/Contacts';
import { DateTime, Duration } from 'luxon';
import { CallLogPersonLine } from '../CallsDirectoryList/interface';
import type { CancelTokenSource } from 'axios';
import {
  CALL_LOG_DIRECTION,
  CALL_LOG_DISPOSITION,
} from '../../constants/enums';
import { isPhoneNumber } from '../../utils/phoneUtil';
import CallLogRowButtons from '../CallLogRowButtons';

export function CallLogListItem(props: CallLogListItemProps) {
  const {
    uiStore,
    conversation,
    phoneStore,
    loggedInPersonId,
    loggedInPersonVideoFeature,
    callLog,
    personStore,
    router,
    setIsAddingContact,
    setEditContact,
  } = props;

  const [contact, setContact] = useState<Contact>(null);
  const [lines, setLines] = useState<CallLogPersonLine[]>([]);

  const displayDuration = () => {
    if (callLog.answeredAt) {
      if (
        callLog.direction === CALL_LOG_DIRECTION.INBOUND &&
        callLog.disposition === CALL_LOG_DISPOSITION.MISSED
      ) {
        return '';
      }
      const durationInMilis =
        DateTime.fromISO(callLog.endedAt).valueOf() -
        DateTime.fromISO(callLog.answeredAt).valueOf();
      let [hours, minutes, seconds] = Duration.fromMillis(durationInMilis)
        .toFormat('h:m:s')
        .split(':')
        .map((item) => parseInt(item));
      let duration = '';
      if (!hours) {
        if (minutes) duration = `${minutes}m `;
        if (seconds) duration += `${seconds}s`;
      } else {
        if (seconds <= 30) minutes++;
        if (minutes === 60) {
          hours++;
          minutes = 0;
        }
        duration = `${hours}h `;
        if (minutes) duration += `${minutes}m`;
      }
      return duration ? `(${duration.trim()})` : '';
    }
    return '';
  };

  const displayStartTime = () => {
    const date = DateTime.fromISO(callLog.startedAt);
    const meridiem = date.toFormat('a').toLowerCase();
    const startDate = date.setLocale('en-US').toFormat('t').toLowerCase();
    return startDate.includes(meridiem)
      ? startDate
      : `${startDate} ${meridiem}`;
  };

  const loadContactIfNotLoaded = (
    phoneNumber: string,
    contactCancelToken: CancelTokenSource
  ) => {
    personStore
      .getLoadNewContact(phoneNumber, contactCancelToken)
      .then(handleResponse, handleCancelError);
  };

  const findContact = (
    personCancelToken: CancelTokenSource,
    contactCancelToken: CancelTokenSource
  ) => {
    if (callLog.phoneNumber) {
      loadContactIfNotLoaded(callLog.phoneNumber, contactCancelToken);
    } else if (callLog.personId) {
      personStore
        .getPersonsById([callLog.personId], personCancelToken)
        .then((resp) => {
          const { phoneNumber, lines, formatNumber } = resp && resp[0];
          const phoneNum =
            phoneNumber || formatNumber
              ? formatNumber()
              : lines.length
              ? lines[0].callerId
              : '';
          loadContactIfNotLoaded(phoneNum, contactCancelToken);
        });
    }
  };

  const handleResponse = (resp) => {
    if (resp?.data?.items?.length > 0) {
      setContact(resp.data.items[0]);
    }
  };

  const handleCancelError = () =>
    console.warn(
      'useEffect cleanup - Request canceled because of component unmounting'
    );

  return (
    <Grid columns="four" className="call-log-item">
      <Grid.Row key={callLog.id} className="directory-items">
        <Grid.Column width="6">
          <div className="flex-row">
            <div className="flex-column">
              <div className="flex-row flex-align-items-center">
                <UserAvatar
                  name={callLog?.fullName}
                  hideInitials={!!callLog.phoneNumber && !callLog.fullName}
                  selectUnreadCounts={uiStore.selectConversationUnreadCounts}
                  presence={uiStore.selectPersonPresenceStatus(
                    callLog.personId || 0
                  )}
                />
                <div className="directory-user-info">
                  <div className="call-log-user-info">
                    <div data-private>
                      <>
                        {callLog.fullName ||
                          callLog.viaName ||
                          contact?.DisplayName ||
                          callLog.phoneNumber ||
                          'Unknown'}
                      </>
                    </div>
                  </div>
                  <div className="call-log-category">
                    <img src={callLog.icon}></img>
                    <div
                      className={cx({
                        'missed-call': callLog.category === 'missed',
                      })}
                    >
                      {callLog.category}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column
          className={cx('padding-5 time-duration', {
            'missed-call': callLog.category === 'missed',
          })}
          width="5"
        >
          {displayStartTime()} {displayDuration()}
        </Grid.Column>
        {(callLog.personId || isPhoneNumber(callLog.phoneNumber)) && (
          <CallLogRowButtons
            lines={lines}
            uiStore={uiStore}
            personStore={personStore}
            routerStore={router}
            callLog={callLog}
            phoneStore={phoneStore}
            conversation={conversation}
            loggedInPersonVideoFeature={loggedInPersonVideoFeature}
            loggedInPersonId={loggedInPersonId}
            setIsAddingContact={setIsAddingContact}
            setEditContact={setEditContact}
          />
        )}
      </Grid.Row>
    </Grid>
  );
}
