import styled from 'styled-components';
import { Icon } from '../Icon';

const TextLink = styled.a<{ onClick?: () => void }>`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  font-size: 14px !important;
  word-break: break-word;

  color: ${({ theme }) => theme.colors.primary} !important;

  &:hover {
    color: ${({ theme }) => theme.colors.linkHover} !important;
  }

  &:active {
    color: ${({ theme }) => theme.colors.linkClick} !important;
  }

  ${({ onClick }) => onClick && `cursor: pointer;`}
`;

const StyledIcon = styled(Icon)<{ onClick?: () => void }>`
  &,
  &:hover,
  &:active {
    color: unset !important;
  }

  ${({ theme }) => `margin-right: ${theme.sizes.xxsmall};`}
`;

export const Styled = {
  TextLink,
  Icon: StyledIcon,
};
