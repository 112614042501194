// tslint:disable-next-line:no-reference
/// <reference path="../../../typings/global.d.ts"/>
import {
  API_ENDPOINTS,
  NODE_ENV_LOCAL_OR_DEVELOPMENT,
  NODE_ENV_PRODUCTION,
  PRESENCE_IDLE_TIME,
} from 'Constants/env';
import { SELECTED_RING_DEVICE, SELECTED_SPEAKER } from 'Constants/localstorage';
import {
  WN_EVENT_PREFIX,
  WN_MESSAGE_CREATED_PREFIX,
} from 'Constants/webNotifications';
import { ConfigurationResponse } from 'Interfaces/apiDtos';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import {
  IConversationNotification,
  IConversationUpdatedNotification,
  IFavoritesMutedMentionNotification,
  IMessageUpdatedNotification,
  IParticipantsUpdatedNotification,
  IPinnedMessageNotification,
  IPresenceUpdated,
} from 'Interfaces/notifications';
import localforage from 'localforage';
import { get, isEmpty, upperFirst } from 'lodash';
import { action, autorun, observable, reaction, runInAction, when, makeObservable } from 'mobx';
import { fromPromise } from 'mobx-utils';
import { Contact } from 'Models/Contacts';
import { IPinnedMessages } from 'Models/PinnedMessageModel';
import moment from 'moment-timezone';
import Pusher, { Channel } from 'pusher-js/with-encryption';
import { BaseStore } from 'Stores/BaseStore';
import { RootStore } from 'Stores/RootStore';
import { isNullOrUndefined } from 'util';
import { sendIpcNewMessage } from 'Utils/ipcRendererEvents';
import { getISOStringFromTimeUUID } from 'Utils/timeUUIDParser';
import { getBearerAuthToken } from '../api';
import { ICallLog } from '../components/CallsDirectoryList/interface';
import { ConversationModel, MessageModel, PresenceModel } from '../models';
import EventModel, {
  ActionCalendarNotification,
  IEvent,
  IEventParticipantRemoved,
} from '../models/Calendar';
import MessageStatusModel, { IMessageStatusModel } from '../models/StatusModel';
import { bugsnagClient } from '../utils/logUtils';

const chatNotificationMp3 = require('../assets/audio/MessageNotification.mp3');
const videoStartNotificationMp3 = require('../assets/audio/VideoStartNotification.mp3');
const videoJoinNotificationMp3 = require('../assets/audio/VideoJoinNotification.mp3');
const videoLeaveNotificationMp3 = require('../assets/audio/VideoLeaveNotification.mp3');
const videoEndedNotificationMp3 = require('../assets/audio/VideoEndedNotification.mp3');

export class PusherStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }
  /** this is for disconnect and reconnects the pusher if any of the observed of the properties changes on any stores */
  mobxHook = autorun((r) => {
    const ps = this.rootStore.personStore;
    const cs = this.rootStore.configStore;
    if (
      ps &&
      ps.IsLoggedIn &&
      cs.signedInPersonConfig &&
      cs.signedInPersonConfig.state === 'fulfilled'
    ) {
      this.clearAllData();
      this.initSubscriptions(cs.signedInPersonConfig.value.data);
    }
  });

  clearAllData = () => {
    if (this.personalChannel !== null && this.personalChannel.subscribed) {
      this.personalChannel.unbind_all();
      this.personalChannel = null;
    }
    if (this.socket !== null && this.socket.connection) {
      this.socket.disconnect();
      this.socket = null;
    }
  };

  @observable
  isOnline: boolean = true;

  @action
  setOnline = (isOnline: boolean) => (this.isOnline = isOnline);

  @observable
  isPersonalChannelSubscribed: boolean = false;

  @action
  setPersonalChannelSubscribed = (isPersonalChannelSubscribed: boolean) =>
    (this.isPersonalChannelSubscribed = isPersonalChannelSubscribed);

  waitUntilPusherConnected = () => {
    return when(
      () => {
        return this.isOnline;
      },
      { timeout: 10000 }
    );
  };

  waitUntilPersonalChannelSubscribed = () => {
    return when(
      () => {
        return this.isPersonalChannelSubscribed;
      },
      { timeout: 10000 }
    );
  };

  @action
  initialPusher = (config: ConfigurationResponse) => {
    this.socket = new Pusher(config.pusher.appKey, {
      cluster: 'mt1',
      auth: {
        headers: {
          Authorization: getBearerAuthToken(),
        },
      },
      authEndpoint: API_ENDPOINTS.PusherAuth,
      activityTimeout: 5000,
      pongTimeout: 1000,
    });
  };

  initSubscriptions = (config: ConfigurationResponse) => {
    this.initialPusher(config);
    this.socket.connection.bind('connecting_in', (delay: number) => {
      this.rootStore.notificationStore.addNotification(
        `Unable to connect to notification service, attempting to reconnect in ${delay} seconds`,
        `Notification Service Reconnecting`,
        'warning'
      );
    });
    // https://pusher.com/docs/client_api_guide/client_connect#connection-status-events
    this.socket.connection.bind('state_change', (states) => {
      console.info(
        `Pusher state_change from ${states.previous} to ${states.current}`
      );
      if (states.current === 'unavailable' || !navigator.onLine) {
        this.setOnline(false);
      }
      if (
        states.previous !== 'connected' &&
        states.current === 'connected' &&
        !this.isOnline &&
        navigator.onLine
      ) {
        this.rootStore.personStore.tryRefreshLocalLogin();
        this.setOnline(true);
        this.rootStore.notificationStore.setIsNetworkErrorDisplayed(false);
      }
    });
    if (!NODE_ENV_PRODUCTION) {
      this.initDebugNotifications();
    }

    this.personalChannel = this.socket.subscribe(
      `private-a=${this.rootStore.personStore.loggedInAccountId}-p=${this.rootStore.personStore.loggedInPersonId}`
    );

    this.accountChannel = this.socket.subscribe(
      `private-a=${this.rootStore.personStore.loggedInAccountId}`
    );

    this.personalChannel.bind('pusher:subscription_succeeded', () => {
      this.setPersonalChannelSubscribed(true);
    });

    const isContactInTheList = (data: any) => {
      const contact = JSON.parse(data) as Contact;
      const allContacts = this.rootStore.personStore.allContacts;
      return allContacts.find((item) => item.id === contact.id);
    };

    /** -- Contacts API -- */
    this.accountChannel.bind('ExternalContactCreated', (data: string) => {
      const isInList = isContactInTheList(data);
      const contact = this.createContact(data);
      if (!isInList && !this.rootStore.personStore.isAddingContact) {
        this.rootStore.personStore.handleAddNewContactSuccess(contact);
      }
    });

    this.accountChannel.bind('ExtarnalContactUpdated', (data: string) => {
      const isInList = isContactInTheList(data);
      const contact = this.createContact(data);
      if (isInList) {
        this.rootStore.personStore.handleUpdateContactSuccess(contact);
      }
    });

    this.accountChannel.bind('ExternalContactDeleted', (data: string) => {
      const isInList = isContactInTheList(data);
      if (isInList) {
        const contact = JSON.parse(data) as Contact;
        this.rootStore.personStore.handleRemoveSuccess(contact.id);
      }
    });

    this.personalChannel.bind('ExternalContactCreated', (data: string) => {
      const isInList = isContactInTheList(data);
      const contact = this.createContact(data);
      if (!isInList && !this.rootStore.personStore.isAddingContact) {
        this.rootStore.personStore.handleAddNewContactSuccess(contact);
      }
    });

    this.personalChannel.bind('ExtarnalContactUpdated', (data: string) => {
      const isInList = isContactInTheList(data);
      const contact = this.createContact(data);
      if (isInList) {
        this.rootStore.personStore.handleUpdateContactSuccess(contact);
      }
    });

    this.personalChannel.bind('ExternalContactDeleted', (data: string) => {
      const isInList = isContactInTheList(data);
      if (isInList) {
        const contact = JSON.parse(data) as Contact;
        this.rootStore.personStore.handleRemoveSuccess(contact.id);
      }
    });

    /** -- Presence -- */
    this.accountChannel.bind('PresenceUpdated', (data: string) => {
      const presDto = JSON.parse(data) as IPresenceUpdated;
      const presUpdate = PresenceModel.FromPusherResponseDto(presDto);
      this.rootStore.uiStore.insertLocalPushPresence(presUpdate);
    });

    this.accountChannel.bind('StatusMessageUpdated', (data: string) => {
      const presDto = JSON.parse(data) as IMessageStatusModel;
      const messStatusUpdate =
        MessageStatusModel.FromPusherResponseDto(presDto);
      this.rootStore.uiStore.insertLocalPushStatusMessage(messStatusUpdate);
    });
    /** -- Conversation -- */

    this.personalChannel.bind('ConversationJoin', (data: string) => {
      const cjnDto = JSON.parse(data) as IConversationUpdatedNotification;
      if (!NODE_ENV_PRODUCTION) {
        this.rootStore.notificationStore.addNotification(
          data,
          'Push: ConversationJoin'
        );
        console.debug('ConversationJoin', cjnDto);
      }
      // Eagerly initialize to guarantee the Unread Count shows up for newly joined Conversations
      this.rootStore.uiStore.conversationUnreadCounts.set(
        cjnDto.conversationId,
        { unreadMessages: 0, unreadMentions: 0 }
      );
      const convPbo =
        this.rootStore.conversationStore.loadConversationByIdIfMissingGet(
          cjnDto.conversationId
        );
      convPbo.then((conv) => {
        this.rootStore.uiStore.setConversationAndTotalUnreadCount(
          conv.data.id,
          conv.data.unreadCount,
          convPbo,
          conv.data.unreadMentionsCount
        );
      });
    });

    this.personalChannel.bind('ConversationUpdated', (data: string) => {
      const cunDto = JSON.parse(data) as IConversationUpdatedNotification;
      if (!NODE_ENV_PRODUCTION) {
        this.rootStore.notificationStore.addNotification(
          data,
          'Push: ConversationUpdated'
        );
        console.debug('ConversationUpdated', cunDto);
      }
      const conv = this.rootStore.conversationStore.selectConversationById(
        cunDto.conversationId
      );
      conv?.then((c) => {
        if (cunDto.activeConference) {
          c.data.setActiveConference({
            id: cunDto.activeConference.id,
            sessionId: cunDto.activeConference.sessionId,
            provider: cunDto.activeConference.provider,
            start: cunDto.activeConference.start,
            adminId: cunDto.activeConference.adminId,
          });
        } else {
          c.data.setActiveConference(null);
        }
      });
      if (conv !== undefined && conv instanceof ConversationModel) {
        conv.setTopic(cunDto.topic);
        conv.setDescription(cunDto.description);
      } else {
        this.rootStore.conversationStore.loadConversationByIdGet(
          cunDto.conversationId
        );
      }
    });

    this.personalChannel.bind('ConversationLeave', (data: string) => {
      const clnDto = JSON.parse(data) as IConversationNotification;
      if (!NODE_ENV_PRODUCTION) {
        this.rootStore.notificationStore.addNotification(
          data,
          'Push: ConversationLeave'
        );
        console.debug('ConversationLeave', clnDto);
      }
      this.rootStore.conversationStore.removeLocalConversationData(
        clnDto.conversationId
      );
    });

    /** -- Message -- */
    this.personalChannel.bind('MessageCreated', async (data) => {
      const mcnDto = JSON.parse(data) as IMessageUpdatedNotification;
      const { conversationId: notifConversationId } = mcnDto;
      if (!NODE_ENV_PRODUCTION) {
        const mcnRedacted = { ...mcnDto, chat: undefined, sms: undefined };
        console.debug('MessageCreated', mcnRedacted);
        this.rootStore.notificationStore.addNotification(
          JSON.stringify(mcnRedacted, null, 4),
          'Push: MessageCreated'
        );
      }
      if (
        mcnDto.personId !== this.rootStore.personStore.loggedInPersonId ||
        mcnDto.systemEvent ||
        mcnDto.conference ||
        mcnDto.call
      ) {
        // show sidebar for video call if type is not AdHocScheduled (video for later)
        if (
          mcnDto.conference &&
          mcnDto.personId !== this.rootStore.personStore.loggedInPersonId &&
          mcnDto.conference.type !== 'AdHocScheduled'
        ) {
          const preseneStatus =
            this.rootStore.uiStore.selectPersonPresenceStatus(
              this.rootStore.personStore.loggedInPersonId
            ).state;
          const showNotification = preseneStatus !== 'DoNotDisturb';
          const audioNotif = new Audio(videoStartNotificationMp3);
          await this.playSound(showNotification, audioNotif);
          this.rootStore.conversationStore.addVideoConferenceToList(
            mcnDto.conference,
            mcnDto.personId
          );
        }
        return this.processReceivedMessage(mcnDto, notifConversationId, false);
      } else {
        this.processReceivedMessageFallback(notifConversationId, mcnDto);
      }
    });

    /** -- Mentioned -- */
    this.personalChannel.bind('Mentioned', (data) => {
      const mcnDto = JSON.parse(data) as IMessageUpdatedNotification;
      const { conversationId: notifConversationId } = mcnDto;
      if (!NODE_ENV_PRODUCTION) {
        const mcnRedacted = { ...mcnDto, chat: undefined, sms: undefined };
        console.debug('Mentioned', mcnRedacted);
        this.rootStore.notificationStore.addNotification(
          JSON.stringify(mcnRedacted, null, 4),
          'Push: Mentioned'
        );
      }

      if (mcnDto.personId !== this.rootStore.personStore.loggedInPersonId) {
        return this.processReceivedMessage(mcnDto, notifConversationId, true);
      } else {
        this.processReceivedMessageFallback(notifConversationId, mcnDto);
      }
    });

    /** -- Read message -- */
    this.personalChannel.bind('ConversationViewStateUpdated', (data) => {
      const mcnDto = JSON.parse(data) as IConversationNotification;
      const { conversationId: notifConversationId } = mcnDto;

      if (
        notifConversationId &&
        this.rootStore.uiStore.selectConversationUnreadCounts(
          notifConversationId
        ).unreadMessages !== 0
      ) {
        if (
          this.rootStore.messageStore.groupedMessagesByConversationMap.has(
            notifConversationId
          )
        ) {
          this.rootStore.uiStore.setMarkedAsReadMessageId(
            notifConversationId,
            null
          );
          const existingMsgGroup =
            this.rootStore.messageStore.groupedMessagesByConversationMap.get(
              notifConversationId
            );
          this.rootStore.participantStore
            .updateMyLastReadMessage(
              notifConversationId,
              existingMsgGroup.NewestMessageId
            )
            .then(() =>
              this.rootStore.uiStore.setConversationAndTotalUnreadCount(
                notifConversationId,
                0,
                null,
                0
              )
            );
        }
      }
    });

    this.personalChannel.bind('CallLogCreated', (data) => {
      const pusherEvent = data as ICallLog;
      pusherEvent &&
        this.rootStore.callLogsStore.eventCreatedSuccesfully(pusherEvent);
    });

    this.personalChannel.bind('CalendarEventCreated', (data) => {
      const pusherEvent = JSON.parse(data) as IEvent;
      pusherEvent &&
        this.rootStore.calendarStore.eventCreatedSuccesfully(pusherEvent);
      sendEventNotification(pusherEvent, 'Created');
    });

    this.personalChannel.bind('CalendarEventParticipantRemoved', (data) => {
      const pusherEvent = JSON.parse(data) as IEventParticipantRemoved;
      const isParticipantLoggedIn =
        pusherEvent.participantId ===
        this.rootStore.personStore.loggedInPersonId;
      const event = this.rootStore.calendarStore.getEventByIdLocaly(
        pusherEvent.eventId
      );
      if (event && isParticipantLoggedIn) {
        this.rootStore.calendarStore.handleRemoveSuccess(pusherEvent.eventId);
        sendEventNotification(event, 'ParticipantRemoved');
      }
    });
    this.personalChannel.bind('CalendarEventUpdated', (data) => {
      const pusherEvent = JSON.parse(data) as IEvent;
      const eventModel = new EventModel(pusherEvent);
      const eventInList = this.rootStore.calendarStore.allEvents.find(
        (event) => event.id === eventModel.id
      );
      if (!eventModel.owner) {
        throw Error('Owner is undefined or null');
      }
      const isOwnerLoggedIn =
        eventModel.owner === this.rootStore.personStore.loggedInEmail;
      this.rootStore.calendarStore.handleEventUpdatedSuccess(eventModel);
      if (isOwnerLoggedIn) {
        return;
      }
      (checkIfTimeChangedOrJustAdded(eventInList, pusherEvent) ||
        isOwnerLoggedIn) &&
        sendEventNotification(pusherEvent, 'Updated');
    });

    this.personalChannel.bind('CalendarEventDeleted', (data) => {
      const pusherEvent = JSON.parse(data) as IEvent;
      const eventInList = this.rootStore.calendarStore.allEvents.find(
        (event) => event.id === pusherEvent.id
      );
      eventInList &&
        this.rootStore.calendarStore.handleRemoveSuccess(pusherEvent.id);
      sendEventNotification(pusherEvent, 'Deleted');
    });

    const checkIfTimeChangedOrJustAdded = (
      oldEvent: IEvent,
      newEvent: IEvent
    ) => {
      const loggedInParticipantInOld = oldEvent?.participants.find(
        (participant) =>
          participant.platformUserId ===
          this.rootStore.personStore.loggedInPersonId
      );
      const loggedInParticipantInNew = newEvent.participants.find(
        (participant) =>
          participant.platformUserId ===
          this.rootStore.personStore.loggedInPersonId
      );
      if (!loggedInParticipantInOld && loggedInParticipantInNew) {
        return true;
      } else if (loggedInParticipantInOld && loggedInParticipantInNew) {
        return (
          oldEvent?.startDate !== newEvent.startDate ||
          oldEvent?.endDate !== newEvent.endDate
        );
      }
      return false;
    };

    const isNotDisturb = () => {
      const preseneStatus = this.rootStore.uiStore.selectPersonPresenceStatus(
        this.rootStore.personStore.loggedInPersonId
      ).state;
      return preseneStatus !== 'DoNotDisturb';
    };

    const calculateDuration = (from: string, to: string) => {
      const fromM = moment(from);
      const toM = moment(to);
      const selectedDuration = moment.duration(moment(toM).diff(fromM));
      if (selectedDuration.asHours() <= 24) {
        return `${fromM.format('llll')} - ${toM.format('h:mm A')}`;
      } else if (selectedDuration.asYears() > 1) {
        return `${fromM.format('llll')} - ${toM.format('llll')}`;
      } else {
        return `${fromM.format('llll')} - ${toM.format('MMM DD, hh:mm')}`;
      }
    };

    const sendEventNotification = (
      event: IEvent,
      actionType: ActionCalendarNotification
    ) => {
      const titleMessages = {
        Updated: 'Event has been updated, will you participate ?',
        Created: 'You have been invited to the following event',
        Deleted: 'Event succesfully deleted',
        ParticipantRemoved: 'You have been removed from the event',
      };
      if (isNotDisturb()) {
        const isLoggedInOwner =
          event.owner === this.rootStore.personStore.loggedInEmail ||
          this.rootStore.personStore.checkExternalSources(event.participants);
        const titleMessage = `${
          isLoggedInOwner
            ? `Event succesfully ${actionType.toLowerCase()}.`
            : titleMessages[actionType]
        }`;
        const message = event.title;
        const duration = calculateDuration(event.startDate, event.endDate);
        const wnTag = `${WN_EVENT_PREFIX}${event.id}:Event`;
        const wnIconUrl = 'https://www.gravatar.com/avatar/?d=identicon&s=128';
        if (
          !this.rootStore.personStore.allSources.find((source) =>
            event.owner.includes(source.email)
          )
        )
          this.rootStore.notificationStore.addWebNotification(
            titleMessage,
            {
              tag: wnTag,
              requireInteraction: false,
              icon: wnIconUrl,
              silent: true,
              timeout: 30000,
              body: `${message}`,
              onClick: (status: 'yes' | 'no') => {
                if (status) {
                  this.rootStore.calendarStore.handleEventRespond(
                    status,
                    event.id
                  );
                  return;
                }
              },
            },
            duration,
            'info',
            false
          );
      }
    };

    this.personalChannel.bind('MessageUpdated', (data) => {
      const munDto = JSON.parse(data) as IMessageUpdatedNotification;
      const utcNowStr = moment.utc().toISOString();
      const localMsg = new MessageModel(
        munDto.messageId,
        utcNowStr,
        null,
        munDto.personId,
        munDto.phone,
        munDto.chat,
        munDto.documents || [],
        munDto.sms,
        munDto.call,
        munDto.conference,
        munDto.systemEvent,
        munDto.isDeleted,
        true
      );
      const munRedacted = { ...munDto, chat: undefined, sms: undefined };
      const eventsWhichShouldPass = [
        'Conversation.Created',
        'Conversation.Updated',
        'Conversation.Participants.Removed',
        'Conversation.Participants.Added',
        'Conference.Created',
        'Conference.Started',
        'Conference.Stopped',
        'Conference.Attendee.Joined',
        'Conference.Attendee.Left',
      ];
      const shouldUpdate =
        munDto.updateEvents &&
        eventsWhichShouldPass.includes(
          munDto.updateEvents[munDto.updateEvents.length - 1].event
        );
      this.rootStore.messageStore.editLocalMessage(
        munDto.conversationId,
        localMsg,
        munDto?.ancestorId
      );
      // TODO: Find a way to ignore these notifications if they are the same `personId` AND the same device (how do we determine)? (RP 11/16/2017)
      if (
        munDto.personId !== this.rootStore.personStore.loggedInPersonId ||
        shouldUpdate
      ) {
        if (
          this.rootStore.conversationStore.selectConversationById(
            munDto.conversationId
          ) !== null
        ) {
          if (!NODE_ENV_PRODUCTION) {
            console.debug('MessageUpdated', munRedacted);
            this.rootStore.notificationStore.addNotification(
              JSON.stringify(
                `Conversation: ${munDto.conversationId}, Message: ${munDto.messageId}`,
                null,
                4
              ),
              'Push: MessageUpdated'
            );
          }
          const grpMsgs =
            this.rootStore.messageStore.groupedMessagesByConversationMap.get(
              munDto.conversationId
            );
          if (grpMsgs !== undefined) {
            const updatedMsg = grpMsgs.AllMessagesDescending.find(
              (m) => m.id === munDto.messageId
            );
            if (!isNullOrUndefined(updatedMsg)) {
              if (!isNullOrUndefined(updatedMsg.sms)) {
                updatedMsg.setSms(munDto.sms);
              } else if (!isNullOrUndefined(updatedMsg.documents)) {
                updatedMsg.setDocuments(munDto.documents);
              } else {
                updatedMsg.setChat(munDto.chat);
              }
            } else {
              console.warn(
                `PusherStore MessageUpdated: Failed to find updatedMsg ${munDto.messageId}`
              );
            }
          }
        } else if (!NODE_ENV_PRODUCTION) {
          console.debug(
            `Ignoring MessageUpdated notification, ${munDto.conversationId} is not currently loaded.`
          );
        }
      } else if (!NODE_ENV_PRODUCTION) {
        console.debug(
          `Ignoring MessageUpdated notification, ${munDto.personId} is the logged-in user.`
        );
      }
    });

    this.personalChannel.bind('MessageRemoved', (data) => {
      const munDto = JSON.parse(data) as IMessageUpdatedNotification;
      const redactedLocalMsg = { ...munDto, chat: undefined, sms: undefined };
      this.rootStore.messageStore.deleteLocalMessage(
        munDto.conversationId,
        munDto.messageId
      );
      // TODO: Find a way to ignore these notifications if they are the same `personId` AND the same device (how do we determine)? (RP 11/16/2017)
      if (munDto.personId !== this.rootStore.personStore.loggedInPersonId) {
        if (
          this.rootStore.conversationStore.selectConversationById(
            munDto.conversationId
          ) !== null
        ) {
          if (!NODE_ENV_PRODUCTION) {
            console.debug('MessageDeleted', redactedLocalMsg);
            this.rootStore.notificationStore.addNotification(
              munDto.messageId,
              'Push: MessageDeleted'
            );
          }
          const grpMsgs =
            this.rootStore.messageStore.groupedMessagesByConversationMap.get(
              munDto.conversationId
            );
          if (grpMsgs !== undefined) {
            const updatedMsg = grpMsgs.AllMessagesDescending.find(
              (m) => m.id === munDto.messageId
            );
            if (!isNullOrUndefined(updatedMsg.sms)) {
              updatedMsg.setSms(munDto.sms);
            } else {
              updatedMsg.setChat(munDto.chat);
              updatedMsg.setDocuments([]);
              const pinnedMessages =
                this.rootStore.uiStore.listOfPinnedMessages.get(
                  munDto.conversationId
                );
              const isPinned = pinnedMessages?.find(
                (item) => item.id === munDto.messageId
              );
              if (isPinned) {
                const filteredPinMess = pinnedMessages.filter(
                  (item) => item.id !== munDto.messageId
                );
                runInAction(() =>
                  this.rootStore.uiStore.listOfPinnedMessages.set(
                    munDto.conversationId,
                    filteredPinMess
                  )
                );
              }
            }
          }
        } else if (!NODE_ENV_PRODUCTION) {
          console.debug(
            `Ignoring MessageDeleted notification, ${munDto.conversationId} is not currently loaded.`
          );
        }
      } else if (!NODE_ENV_PRODUCTION) {
        console.debug(
          `Ignoring MessageDeleted notification, ${munDto.personId} is the logged-in user.`
        );
      }
    });

    /** -- Participant -- */
    this.personalChannel.bind('ParticipantsUpdated', (data: string) => {
      const punDto = JSON.parse(data) as IParticipantsUpdatedNotification;
      if (!NODE_ENV_PRODUCTION) {
        console.debug('ParticipantsUpdated', punDto);
        this.rootStore.notificationStore.addNotification(
          data,
          'Push: ParticipantsUpdated'
        );
      }
      const conv = this.rootStore.conversationStore.selectConversationById(
        punDto.conversationId
      );
      if (conv !== undefined && conv.state === 'fulfilled') {
        this.processUpdatedParticipants(punDto);
      }
    });

    /** -- Remove conversation from search list -- */
    this.personalChannel.bind('ConversationRemovedFromRank', (data: string) => {
      const punDto = JSON.parse(data);
      if (
        this.rootStore.conversationStore.conversationByIdMap.has(
          punDto?.conversationId
        ) ||
        this.rootStore.conversationStore.conversationByIdRecentHist.has(
          punDto?.conversationId
        )
      ) {
        if (
          this.rootStore.conversationStore.FavoriteConversationIds.includes(
            punDto?.conversationId
          )
        ) {
          this.rootStore.conversationStore.removeConversationFromFavoritesPatch(
            punDto?.conversationId
          );
        }
        this.rootStore.conversationStore.removeLocalConversationFromList(
          punDto?.conversationId
        );
        if (!NODE_ENV_PRODUCTION) {
          console.debug('ConversationRemovedFromRank', punDto);
          this.rootStore.notificationStore.addNotification(
            data,
            'Push: ConversationRemovedFromRank'
          );
        }
      }
    });

    //Pinned Messages
    this.personalChannel.bind('MessagePinned', (data: string) => {
      const punDto = JSON.parse(data) as IPinnedMessageNotification;
      if (!NODE_ENV_PRODUCTION) {
        this.rootStore.notificationStore.addNotification(
          data,
          'Push: MessagePinned'
        );
        console.debug('Pinned message created', punDto);
      }
      let callText = punDto.call ? '' : null;
      const callPreText = punDto.call
        ? punDto.call?.direction === 'Incoming'
          ? 'Call from:'
          : 'Called'
        : null;
      if (punDto.call?.to.personId) {
        const personPbo = this.rootStore.personStore.selectPersonById(
          punDto.call.to.personId
        );
        personPbo.case({
          fulfilled: (resp) => (callText = resp.data.DisplayName),
        });
      } else if (punDto.call?.to.phone) {
        callText = punDto.call.to.phone;
      }
      const pinnedMessage: IPinnedMessages = {
        pinnedByPersonId: punDto.pinnedByPersonId,
        pinnedAt: punDto.pinnedAt,
        id: punDto.messageId,
        created: '',
        personId: punDto.personId,
        chat: {
          text: punDto.chat?.text,
        },
        documents: punDto.documents,
        conference: {
          displayName:
            punDto.conference?.displayName &&
            `Conference: ${punDto.conference?.displayName}`,
        },
        call: {
          text: callText && `${callPreText} ${callText}`,
        },
        systemEvent: { eventType: punDto.systemEvent?.eventType },
        conversationId: punDto.conversationId,
        source: punDto.source,
        sms: punDto.sms,
      };
      const messages =
        this.rootStore.uiStore.listOfPinnedMessages.get(
          punDto.conversationId
        ) || [];
      runInAction(() =>
        this.rootStore.uiStore.listOfPinnedMessages.set(punDto.conversationId, [
          ...messages,
          pinnedMessage,
        ])
      );
      document.getElementById('context-panel')?.click();
    });

    this.personalChannel.bind('MessageUnpinned', (data: string) => {
      const punDto = JSON.parse(data) as IPinnedMessageNotification;
      if (!NODE_ENV_PRODUCTION) {
        this.rootStore.notificationStore.addNotification(
          data,
          'Push: MessageUnpinned'
        );
        console.debug('Pinned message deleted', punDto);
      }
      const messages = this.rootStore.uiStore.listOfPinnedMessages.get(
        punDto.conversationId
      );
      const newData = messages.filter(
        (message) => message.id !== punDto.messageId
      );
      runInAction(() =>
        this.rootStore.uiStore.listOfPinnedMessages.set(
          punDto.conversationId,
          newData
        )
      );
      document.getElementById('context-panel')?.click();
    });

    this.personalChannel.bind('PreferencesUpdated', (data: string) => {
      const punDto = JSON.parse(data) as IFavoritesMutedMentionNotification;
      const { setLocalOnlyDirectMentions, muteConversationLocally } =
        this.rootStore.uiStore;
      if (punDto.mutedConversationIds) {
        muteConversationLocally(punDto.mutedConversationIds);
      } else if (punDto.favoriteConversationIds) {
        this.rootStore.conversationStore.loadFavoriteConversationsGet();
      } else if (punDto.directMentionsOnly) {
        setLocalOnlyDirectMentions(punDto.directMentionsOnly);
      }
    });
  };

  createContact = (data) => {
    const parsedContact = JSON.parse(data) as Contact;
    return parsedContact?.sourceAccountId
      ? { ...parsedContact, accountId: parsedContact.sourceAccountId }
      : parsedContact;
  };

  processUpdatedParticipants = (punDto: IParticipantsUpdatedNotification) => {
    if (!isEmpty(punDto.added)) {
      this.rootStore.participantStore.insertLocalPushParticipants(
        punDto.conversationId,
        punDto.added
      );
    }
    if (!isEmpty(punDto.removed)) {
      const filteredRemoved = punDto.removed.filter(
        (p) => p.personId !== this.rootStore.personStore.loggedInPersonId
      );
      if (!isEmpty(filteredRemoved)) {
        this.rootStore.participantStore.removeLocalParticipants(
          punDto.conversationId,
          filteredRemoved
        );
      }
    }
  };

  processAddedParticipants = (
    punDto: IParticipantsUpdatedNotification,
    justCreatedParticipantIds?: string[]
  ) => {
    let finalAdded = punDto.added;
    const jcpi = justCreatedParticipantIds || [];
    const currParticipants =
      this.rootStore.participantStore.selectParticipantsByConversationId(
        punDto.conversationId
      );
    if (
      currParticipants !== undefined &&
      currParticipants.state === 'fulfilled'
    ) {
      const currPartIds =
        currParticipants.value.data.results.map((cp) => cp.id) || [];
      finalAdded = finalAdded.filter(
        (a) => !currPartIds.includes(a.id) && !jcpi.includes(a.id)
      );
    }
    if (!isEmpty(finalAdded)) {
      this.rootStore.participantStore.insertLocalPushParticipants(
        punDto.conversationId,
        finalAdded
      );
    }
  };

  private initDebugNotifications = () => {
    this.socket.connection.bind(
      'state_change',
      (states: { previous: string; current: string }) => {
        let level: 'info' | 'success' | 'warning' | 'error' = 'info';
        switch (states.current) {
          case 'connected':
            level = 'success';
            break;
          case 'unavailable':
          case 'connecting_in':
            level = 'warning';
            break;
          case 'failed':
          case 'disconnected':
            level = 'error';
            break;
        }
        this.rootStore.notificationStore.addNotification(
          `State changed from ${states.previous} -> ${states.current}`,
          `Pusher: ${upperFirst(states.current)}`,
          level
        );
      }
    );
  };

  private socket: Pusher = null;
  private personalChannel: Channel = null;
  private presenceChannel: Channel = null;
  private accountChannel: Channel = null;

  playSound = async (
    showNotification: boolean,
    audioNotif: HTMLMediaElement
  ) => {
    if (showNotification) {
      const sp: string = await localforage.getItem<string>(
        SELECTED_RING_DEVICE
      );
      try {
        // @ts-ignore
        await audioNotif.setSinkId(sp || 'default');
        console.debug(
          `Success, Sound Notification output device attached to element with ${audioNotif.title} as source.`
        );
      } catch (error) {
        console.debug(
          `Failed, Sound Notification output device attached to element with ${audioNotif.title} as source.`
        );
      }

      try {
        await audioNotif.play();
      } catch (error) {
        console.warn('Unable to play incoming tone.', error);
        /* Not allowed error is thrown when play() is considered an autoplay
          and the browser is blocking that until the user interacts with the page.
          This error will be ignored */
        if (error.name !== 'NotAllowedError') {
          bugsnagClient.notify(error, (event) => {
            event.severity = 'error';
            event.context = 'incomingCall';
          });
        }
      }
    }
  };

  @action
  ifNotRecentAdd = (
    conversationId: string,
    convResp: AxiosResponseT<ConversationModel>
  ) => {
    if (
      !this.rootStore.conversationStore.conversationByIdRecentHist.has(
        conversationId
      )
    ) {
      this.rootStore.conversationStore.conversationByIdRecentHist.set(
        conversationId,
        fromPromise.resolve({
          ...convResp,
          data: ConversationModel.FromResponseDto(convResp.data),
        })
      );
    }
  };

  private getMessageBodyFromDto = (mcnDto: IMessageUpdatedNotification) => {
    const chatText = get(mcnDto, 'chat.text');
    const smsText = get(mcnDto, 'sms.text');
    const attachments = get(mcnDto, 'documents');

    if (!chatText && !smsText && !attachments?.length) {
      return '';
    }

    return (
      chatText ||
      smsText ||
      (attachments.length === 1 ? 'shared a file' : 'shared files')
    );
  };

  private processReceivedMessage(
    mcnDto: IMessageUpdatedNotification,
    notifConversationId: string,
    isMention: boolean
  ) {
    if (isNullOrUndefined(mcnDto.created)) {
      mcnDto.created = getISOStringFromTimeUUID(mcnDto.messageId);
    }
    const localMsg = new MessageModel(
      mcnDto.messageId,
      mcnDto.created,
      null,
      mcnDto.personId,
      mcnDto.phone,
      mcnDto.chat,
      mcnDto.documents,
      mcnDto.sms,
      mcnDto.call,
      mcnDto.conference,
      mcnDto.systemEvent,
      mcnDto.isDeleted,
      true
    );
    let convPromise = this.rootStore.conversationStore.selectConversationById(
      notifConversationId
    ) as PromiseLike<AxiosResponseT<ConversationModel>>;
    if (convPromise === null) {
      convPromise =
        this.rootStore.conversationStore.loadConversationByIdIfMissingGet(
          notifConversationId
        );
    }
    return convPromise.then((conv) => {
      const isConversationSMS = conv.data.participants.some((p) => p.phone);
      this.ifNotRecentAdd(mcnDto.conversationId, conv);
      conv.data.setLastMessageDate(localMsg.created);
      conv.data.setLastMessageId(localMsg.id);
      const currentConversationId =
        this.rootStore.routerStore.currentConversationId();
      // In the current Conversation, and it is Focused or has recent Focus/Keyboard/Mouse Activity
      const shouldClearUnreads: boolean =
        currentConversationId === notifConversationId &&
        this.rootStore.uiStore.IsFocused &&
        this.rootStore.uiStore.selectHasRecentActivityWithin(
          PRESENCE_IDLE_TIME
        );
      console.debug('isFromCurrConvWithRecentActivity', shouldClearUnreads);
      const messageMethodOrCount = shouldClearUnreads ? 0 : 'add';
      const mentionMethodOrCount = shouldClearUnreads
        ? 0
        : isMention
        ? 'add'
        : null;
      const activeConference =
        !isNullOrUndefined(this.rootStore.conversationStore) &&
        this.rootStore.uiStore.IsOnVideoConference;
      // The latest `Message` Id (used withing `updateMyLastReadMessage`) is a computed value that will be re-calculated due to the `insertNewLocalOrPushMessage` above.
      return (
        this.rootStore.messageStore.insertNewLocalOrPushMessage(
          notifConversationId,
          localMsg
        ) as any
      )
        .then(() => {
          if (shouldClearUnreads) {
            return this.rootStore.participantStore
              .updateMyLastReadMessage(notifConversationId, localMsg.id)
              .catch((reason) => {
                console.error(
                  `processReceivedMessage: Failed to updateMyLastReadMessage: ${reason}`
                );
              });
          } else {
            console.warn(
              `(${notifConversationId} || Message Id ${localMsg.id}) updateMyLastReadMessage was not sent because it is not a current conversation with recent activity `
            );
            return Promise.resolve(null);
          }
        })
        .then(() =>
          this.rootStore.uiStore.setConversationAndTotalUnreadCount(
            notifConversationId,
            messageMethodOrCount,
            convPromise,
            mentionMethodOrCount
          )
        )
        .then(async () => {
          if (!isEmpty(mcnDto.systemEvent)) {
            return;
          }
          this.rootStore.messageStore.setNewestMessageOwnedByUserId(
            notifConversationId
          );
          // Sends if within Electron renderer
          sendIpcNewMessage();
          // If you are on the current conversation, we don't want to hit the DB to reload it
          const curConversation =
            this.rootStore.routerStore.currentConversationId();
          // Otherwise, guarantee it is loaded (ex. it is new since you initially logged in)
          if (curConversation !== notifConversationId) {
            this.rootStore.conversationStore.loadConversationByIdIfMissingGet(
              notifConversationId
            );
          }
          const { topic, grouping } = conv.data;
          const wnTag = `${WN_MESSAGE_CREATED_PREFIX}${notifConversationId}:${mcnDto.messageId}`;
          let wnIconUrl = 'https://www.gravatar.com/avatar/?d=identicon&s=128';
          // Always try to find the latest message's user gravatar, whether it's a OneOnOne, a group, etc.
          const otherPerson = this.rootStore.personStore.selectPersonValueById(
            mcnDto.personId
          );
          let displayName = topic;
          if (otherPerson !== null && !isEmpty(otherPerson.data.gravatarUrl)) {
            wnIconUrl = otherPerson.data.DisplayAvatar;
          }
          if (grouping === 'OneOnOne') {
            const otherParticipants =
              this.rootStore.participantStore.selectOtherParticipants(
                notifConversationId
              );
            if (otherParticipants !== null && otherParticipants.length === 1) {
              const otherPersonId = otherParticipants[0].personId;
              if (
                otherPersonId !== undefined &&
                otherPersonId !== null &&
                otherPersonId !== 0
              ) {
                if (otherPerson !== null) {
                  displayName =
                    otherPerson.data.firstName +
                    ' ' +
                    otherPerson.data.lastName;
                }
              } else {
                displayName = otherParticipants[0].phone;
              }
            }
          } else {
            displayName =
              topic +
              ' | ' +
              (otherPerson
                ? otherPerson.data.firstName + ' ' + otherPerson.data.lastName
                : mcnDto.phone);
          }
          // Close existing MessageCreated notifications for this Conversation
          let hasExistingWebNotif = false;
          const hasConversationPreviouslyNotified =
            this.rootStore.notificationStore.messageCreatedWebNotifications.has(
              notifConversationId
            );
          if (hasConversationPreviouslyNotified) {
            hasExistingWebNotif =
              this.rootStore.notificationStore.messageCreatedWebNotifications.get(
                notifConversationId
              ).length > 0;
            if (hasExistingWebNotif) {
              this.rootStore.notificationStore.closeAllMessageCreatedWebNotifications(
                notifConversationId
              );
            }
          }
          let titleMessage;
          let titleMessages;
          let message;
          let messages;
          const preseneStatus =
            this.rootStore.uiStore.selectPersonPresenceStatus(
              this.rootStore.personStore.loggedInPersonId
            ).state;
          const showNotification = preseneStatus !== 'DoNotDisturb';
          let messageBodyDescription = this.getMessageBodyFromDto(mcnDto);
          const tmpArr = messageBodyDescription.substring(0, 30).split(' ');
          for (let i = 0; i < tmpArr.length; i++) {
            if (tmpArr[i].startsWith('@pr')) {
              const tempValue = parseInt(tmpArr[i].substring(3, 30));
              if (Number.isInteger(tempValue)) {
                tmpArr[i] =
                  this.rootStore.personStore.selectPersonValueById(
                    tempValue
                  ).data.DisplayName;
              }
            }
          }
          const isConferenceForLater =
            mcnDto?.conference?.type === 'AdHocScheduled';
          messageBodyDescription = tmpArr.join(' ');
          const mutedConv =
            this.rootStore.uiStore.selectIfConvMuted(notifConversationId);
          if (
            (!this.rootStore.uiStore.showOnlyDirectMentions ||
              isConversationSMS) &&
            !mutedConv &&
            mcnDto.personId !== this.rootStore.personStore.loggedInPersonId
          ) {
            if (
              !isEmpty(mcnDto.conference) &&
              mcnDto.conference.adminId !==
                this.rootStore.personStore.loggedInPersonId
            ) {
              titleMessage = 'You’re invited!';
              titleMessages = titleMessage;
              message =
                messages = `${displayName}: Would like you to join a video conference call`;
            } else if (isMention) {
              titleMessage = 'You have a new mention';
              titleMessages = 'You have new mentions';
              message = messages = `${displayName}: mentioned you`;
            } else {
              titleMessage = 'You have a new message';
              titleMessages = 'You have new messages';
              message = messages = `${displayName}: ${messageBodyDescription}`;
            }
            showNotification &&
              !isConferenceForLater &&
              this.rootStore.notificationStore.addWebNotification(
                hasExistingWebNotif ? `${titleMessages}` : `${titleMessage}`,
                {
                  tag: wnTag,
                  requireInteraction: false,
                  icon: wnIconUrl,
                  silent: true,
                  timeout: 30000,
                  body: hasExistingWebNotif ? `${messages} ` : ` ${message} `,
                  onClick: () => {
                    if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
                      console.debug('mcn onClick for ' + notifConversationId);
                    }
                    if (
                      this.rootStore.conversationStore.conversationByIdRecentHist.has(
                        notifConversationId
                      )
                    ) {
                      this.rootStore.uiStore.setMessageIdToScroll(
                        mcnDto.messageId
                      );
                      this.rootStore.routerStore.push(
                        `/chat/conversations/${notifConversationId}/menu`
                      );
                    }
                  },
                },
                '',
                'info',
                false
              );
          } else if (
            mcnDto.personId !== this.rootStore.personStore.loggedInPersonId
          ) {
            if (!mutedConv) {
              if (isMention && grouping !== 'OneOnOne') {
                titleMessage = 'You have a new mention';
                titleMessages = 'You have new mentions';
                message = messages = `${displayName}: mentioned you`;
              } else if (grouping === 'OneOnOne') {
                titleMessage = 'You have a new message';
                titleMessages = 'You have new messages';
                message =
                  messages = `${displayName}: ${messageBodyDescription}`;
              } else {
                return;
              }
              showNotification &&
                !isConferenceForLater &&
                this.rootStore.notificationStore.addWebNotification(
                  hasExistingWebNotif ? `${titleMessages}` : `${titleMessage}`,
                  {
                    tag: wnTag,
                    requireInteraction: false,
                    icon: wnIconUrl,
                    silent: true,
                    timeout: 30000,
                    body: hasExistingWebNotif ? `${messages} ` : ` ${message} `,
                    onClick: () => {
                      if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
                        console.debug('mcn onClick for ' + notifConversationId);
                      }
                      if (
                        this.rootStore.conversationStore.conversationByIdRecentHist.has(
                          notifConversationId
                        )
                      ) {
                        this.rootStore.routerStore.push(
                          `/chat/conversations/${notifConversationId}/menu`
                        );
                      }
                    },
                  },
                  '',
                  'info',
                  false
                );
            } else if (mutedConv) {
              return;
            }
          }
          // Play sound
          let audioNotif: HTMLAudioElement;
          if (
            this.rootStore.preferenceStore.preferences.notificationAudio ===
              true &&
            (!this.rootStore.uiStore.showOnlyDirectMentions ||
              isConversationSMS) &&
            !mutedConv &&
            !activeConference
          ) {
            // Play sound
            if (mcnDto.conference) {
              audioNotif = new Audio(videoStartNotificationMp3);
            } else if (
              mcnDto.systemEvent &&
              mcnDto.systemEvent.eventType === 'Conference.Participant.Joined'
            ) {
              audioNotif = new Audio(videoJoinNotificationMp3);
            } else if (
              mcnDto.systemEvent &&
              mcnDto.systemEvent.eventType === 'Conference.Participant.Left'
            ) {
              audioNotif = new Audio(videoLeaveNotificationMp3);
            } else if (
              mcnDto.systemEvent &&
              mcnDto.systemEvent.eventType === 'Conference.Ended'
            ) {
              audioNotif = new Audio(videoEndedNotificationMp3);
            } else {
              audioNotif = new Audio(chatNotificationMp3);
            }
            await this.playSound(showNotification, audioNotif);
          } else if (
            this.rootStore.preferenceStore.preferences.notificationAudio ===
              true &&
            isMention &&
            this.rootStore.uiStore.showOnlyDirectMentions &&
            !mutedConv
          ) {
            audioNotif = new Audio(chatNotificationMp3);
            showNotification &&
              localforage.getItem<string>(SELECTED_SPEAKER).then((sp) => {
                try {
                  // @ts-ignore
                  audioNotif.setSinkId(sp || 'default').then(() => {
                    audioNotif.play();
                    console.debug(
                      `Success, Sound Notification output device attached to element with ${audioNotif.title} as source.`
                    );
                  });
                } catch (error) {
                  audioNotif.play();
                  console.debug(
                    `Failed, Sound Notification output device attached to element with ${audioNotif.title} as source.`
                  );
                }
              });
          } else if (
            grouping === 'OneOnOne' &&
            this.rootStore.preferenceStore.preferences.notificationAudio ===
              true &&
            this.rootStore.uiStore.showOnlyDirectMentions &&
            !mutedConv
          ) {
            audioNotif = new Audio(chatNotificationMp3);
            showNotification &&
              localforage.getItem<string>(SELECTED_SPEAKER).then((sp) => {
                try {
                  // @ts-ignore
                  audioNotif.setSinkId(sp || 'default').then(() => {
                    audioNotif.play();
                    console.debug(
                      `Success, Sound Notification output device attached to element with ${audioNotif.title} as source.`
                    );
                  });
                } catch (error) {
                  audioNotif.play();
                  console.debug(
                    `Failed, Sound Notification output device attached to element with ${audioNotif.title} as source.`
                  );
                }
              });
          }
          return conv;
        });
    });
  }

  private processReceivedMessageFallback(
    notifConversationId: string,
    mcnDto: IMessageUpdatedNotification
  ) {
    const utcNowStr = moment.utc().toISOString();
    const gmByConvMap =
      this.rootStore.messageStore.groupedMessagesByConversationMap;
    let convPromise = this.rootStore.conversationStore.selectConversationById(
      notifConversationId
    ) as PromiseLike<AxiosResponseT<ConversationModel>>;
    if (convPromise === null) {
      convPromise =
        this.rootStore.conversationStore.loadConversationByIdIfMissingGet(
          notifConversationId
        );
    }
    convPromise?.then((convResp: AxiosResponseT<ConversationModel>) => {
      this.ifNotRecentAdd(mcnDto.conversationId, convResp);
      reaction(
        () =>
          gmByConvMap.has(notifConversationId) &&
          (this.rootStore.conversationStore.conversationByIdMap.has(
            notifConversationId
          ) ||
            this.rootStore.conversationStore.conversationByIdRecentHist.has(
              notifConversationId
            )) &&
          gmByConvMap
            .get(notifConversationId)
            .NewestFiveMessageIds.some((msgId) => msgId === mcnDto.messageId),
        () => {
          if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
            console.debug(
              `reaction ignoring latest push message ${mcnDto.messageId}, it was sent locally and already exists.`
            );
            return true;
          }
        },
        { delay: 10000 }
      );

      reaction(
        () =>
          gmByConvMap
            .get(notifConversationId)
            .NewestFiveMessageIds.some((msgId) => msgId === mcnDto.messageId),
        () => {
          const localMsg = new MessageModel(
            mcnDto.messageId,
            utcNowStr,
            null,
            mcnDto.personId,
            null,
            mcnDto.chat,
            mcnDto.documents,
            mcnDto.sms,
            mcnDto.call,
            mcnDto.conference,
            mcnDto.systemEvent,
            mcnDto.isDeleted,
            true
          );
          const conv =
            this.rootStore.conversationStore.selectConversationById(
              notifConversationId
            );
          convResp.data.setLastMessageDate(utcNowStr);
          convResp.data.setLastMessageId(mcnDto.messageId);
          this.rootStore.messageStore.insertNewLocalOrPushMessage(
            notifConversationId,
            localMsg
          );
          this.rootStore.messageStore.setNewestMessageOwnedByUserId(
            notifConversationId
          );
          this.rootStore.participantStore.updateMyLastReadMessage(
            notifConversationId,
            localMsg.id
          );
          this.rootStore.uiStore.setConversationAndTotalUnreadCount(
            notifConversationId,
            0,
            conv,
            0
          ); // Clear unreads for own message
        }
      );
    });
  }
}
