import styled from 'styled-components';
import { Input } from 'semantic-ui-react';

const Wrapper = styled.div`
  max-width: 600px;
  margin-bottom: 10px !important;
`;

const StyledInput = styled(Input)`
  & input {
    background-color: transparent !important;
    border-radius: 5px;
    height: 48px;
    border-color: rgba(34, 36, 38, 0.35) !important;
    color: #999999 !important;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: #333333 !important;
    }
  }
`;

export const Styled = {
  Wrapper,
  Input: StyledInput,
};
