import { inject, observer } from 'mobx-react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  STORE_CONVERSATION,
  STORE_ROUTER,
  STORE_UI,
} from '../../constants/stores';
import ActionPanel from '../../containers/ActionPanel';
import ContextPanel from '../../containers/ContextPanel';
import EntityProfile from '../../containers/EntityProfile';
import PinnedMessages from '../../containers/PinnedMessages';
import UiBridge from '../../containers/UiBridge';
import GroupInfo from '../GroupInfo';
import { IChatPageProps } from './interfaces';

const ChatPage = (props: IChatPageProps) => {
  const {
    ui: { activePinnedConversationId, hideSecondaryMenu },
    person,
  } = props;

  return (
    <>
      {!hideSecondaryMenu && <ActionPanel {...props} />}
      <ContextPanel {...props} />
      <EntityProfile {...props} {...person} />
      <UiBridge {...props} />
      <GroupInfo />
      {activePinnedConversationId && <PinnedMessages {...props} />}
    </>
  );
};

export default inject(
  STORE_CONVERSATION,
  STORE_UI,
  STORE_ROUTER
)(observer(ChatPage));
