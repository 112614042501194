import React from 'react';
import { inject, observer } from 'mobx-react';
import type { CheckboxProps } from 'semantic-ui-react';
import {
  STORE_NOTIFICATION,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_UI,
} from 'Constants/stores';
import { pushToGTMDataLayer } from 'Utils/analytics';
import type { NotificationsSettingsProps } from './interfaces';
import { Styled } from './index.styles';
import { Heading } from '../../../shared/Heading';
import { Button } from '../../../shared/Button';

const NotificationsSettings: React.FC<NotificationsSettingsProps> = ({
  ui,
  preference,
  notification,
  phoneCall,
}) => {
  const testid = 'notificationSettings';

  React.useEffect(() => {
    ui.getOnlyMentions();
    phoneCall.updateEnableIncomingCalls();
  }, []);

  const onToggleShowOnlyDirectMentions = (_e, data: CheckboxProps) => {
    ui.setShowOnlyDirectMentions(data.checked);
    pushToGTMDataLayer('toggleShowOnlyDirectMentions');
  };

  const onToggleShowIncomingCalls = (_e, data: CheckboxProps) => {
    phoneCall.setEnableIncomingCalls(!!data.checked);
    pushToGTMDataLayer('toggleShowIncomingCalls');
  };

  const onToggleDesktopNotifications = (_e, data: CheckboxProps) => {
    // If the Prompt (at the top of the app) is still showing, dismiss it
    notification.setWebNotificationPromptActionTaken(true);

    data.checked
      ? notification.tryEnableWebNotifications()
      : notification.setWebNotificationsEnabled(false);

    pushToGTMDataLayer('toggleDesktopNotifications');
  };

  const onTestDesktopNotificationClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    notification.addWebNotification(
      'Test!',
      {
        tag: 'test:test-desktop-notif',
        silent: true,
        body: 'This is a test notification. If you see this, desktop notifications are working! You rock! (This notification will be dismissed automatically)',
        icon: 'https://www.gravatar.com/avatar/?d=mm',
        timeout: 3000,
      },
      '',
      false,
      true
    );
  };

  return (
    <div className="settings-notifications">
      <div>
        <Heading variant="h2">Notifications</Heading>
        <Styled.ToggleButton
          label="Enable Only Direct Mentions (Includes @here)"
          checked={ui.showOnlyDirectMentions}
          onChange={onToggleShowOnlyDirectMentions}
          helperText="Enable group notification only if you are explicitly mentioned"
          testid={`${testid}-toggleButtonEnableOnlyDirectMentions`}
        />
        <Styled.ToggleButton
          label="Enable Incoming Calls"
          checked={phoneCall?.enableIncomingCalls}
          onChange={onToggleShowIncomingCalls}
          testid={`${testid}-toggleButtonEnableIncomingCalls`}
        />
        <Styled.ToggleButton
          label="Enable Notification Sounds"
          checked={preference.preferences.notificationAudio}
          onChange={() =>
            preference.setPreferencesPatch({
              notificationAudio: !preference.preferences.notificationAudio,
            })
          }
          testid={`${testid}-toggleButtonEnableNotificationSounds`}
        />
        <Styled.ToggleButton
          label="Enable Desktop Notifications"
          checked={notification.webNotificationsEnabled}
          onChange={onToggleDesktopNotifications}
          helperText="Enable desktop and application notifications when new message arrives"
          testid={`${testid}-toggleButtonEnableDesktopNotifications`}
        />
      </div>
      <Button
        variant="primary"
        disabled={!notification.webNotificationsEnabled}
        content="Test desktop notifications"
        onClick={onTestDesktopNotificationClick}
        testid={`${testid}-buttonTestDesktopNotifications`}
      />
    </div>
  );
};

export default inject(
  STORE_UI,
  STORE_PREFERENCE,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION
)(observer(NotificationsSettings));
