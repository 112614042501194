import { CALL_STATE } from 'Constants/enums';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { IconButton } from '../../../shared/IconButton';
import type { DirectoryActionButtonsProps } from './types';
import { pushToGTMDataLayer } from '../../../../utils/analytics';
import { Styled } from './index.styles';

export const DirectoryActionButtons: React.FC<DirectoryActionButtonsProps> = ({
  loadOrCreateConversationWithPost,
  LoggedInUserActiveConferenceConversation,
  loggedInPersonId,
  loggedInPersonVideoFeature,
  makeCall,
  makeVideoCall,
  phoneCalls,
  userId,
  testid: parentTestid,
}) => {
  const testid = `${parentTestid}-directoryActionButtons`;
  const buttonSize = 'medium';
  const popupPosition = 'bottom center';
  const navigate = useNavigate();
  const [callState, setCallState] = React.useState(CALL_STATE.Available);

  React.useEffect(() => {
    phoneCalls.filter((phoneCall) => {
      const elementPR = phoneCall.callUri?.split('@')[0].substring(2);
      if (Number(elementPR) === userId) {
        setCallState(
          phoneCall.isCallConnecting
            ? CALL_STATE.Connecting
            : CALL_STATE.Connected
        );
      }
    });
  }, [phoneCalls]);

  const handleOpenChat = async () => {
    const { data } = await loadOrCreateConversationWithPost(userId);
    navigate(`/chat/conversations/${data.id}/menu`);
    pushToGTMDataLayer('directoryMessageUser');
  };

  return (
    <Styled.ActionButtonsWrapper>
      <IconButton
        content="Start messaging"
        icon="chat"
        onClick={handleOpenChat}
        testid={`${testid}-buttonMessage`}
        {...{ popupPosition, size: buttonSize }}
      />
      {userId !== loggedInPersonId && (
        <>
          <IconButton
            content={
              callState !== CALL_STATE.Available
                ? 'You are currently on a call with this person'
                : 'Start Call'
            }
            disabled={callState !== CALL_STATE.Available}
            icon="call"
            onClick={() => makeCall('userId')}
            testid={`${testid}-buttonCall`}
            {...{ popupPosition, size: buttonSize }}
          />
          <IconButton
            content="Start Video Conference"
            disabled={
              !!LoggedInUserActiveConferenceConversation ||
              !loggedInPersonVideoFeature.enabled
            }
            icon="video"
            onClick={makeVideoCall}
            testid={`${testid}-buttonVideoCall`}
            {...{ popupPosition, size: buttonSize }}
          />
        </>
      )}
    </Styled.ActionButtonsWrapper>
  );
};
