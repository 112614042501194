import React from 'react';
import { Styled } from './index.styles';
import type { MessageBubbleProps } from './types';

export const MessageBubble = ({
  content,
  displayName,
  AvatarComponent,
  isOwnMessage,
  messageDate,
}: MessageBubbleProps) => {
  if (!content) {
    return null;
  }

  if (isOwnMessage) {
    return (
      <Styled.BoxWithoutAvatar>
        <Styled.NameDateContainer>
          <Styled.Name variant={'paragraph'}>{displayName}</Styled.Name>
          <Styled.Date variant={'subtitles'}>{messageDate}</Styled.Date>
        </Styled.NameDateContainer>

        <Styled.Message variant={'paragraph'}>{content}</Styled.Message>
      </Styled.BoxWithoutAvatar>
    );
  }

  return (
    <Styled.BoxWithAvatar>
      <Styled.ArrowContainer>
        <Styled.Arrow />
        <Styled.ArrowFill />
      </Styled.ArrowContainer>

      {AvatarComponent && (
        <Styled.AvatarContainer>{AvatarComponent}</Styled.AvatarContainer>
      )}

      <Styled.Box>
        <Styled.NameDateContainer>
          <Styled.Name variant={'paragraph'}>{displayName}</Styled.Name>
          <Styled.Date variant={'subtitles'}>{messageDate}</Styled.Date>
        </Styled.NameDateContainer>

        <Styled.Message variant={'paragraph'}>{content}</Styled.Message>
      </Styled.Box>
    </Styled.BoxWithAvatar>
  );
};
