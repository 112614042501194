import React from 'react';
import { Popup } from 'semantic-ui-react';
import { UserAvatar } from '../../shared/UserAvatar';

import arrowDown from 'Assets/images/arrow-down-icon.svg';
import { TopBarProfileStatusButtonProps } from './interfaces';

export const TopBarProfileStatusButton: React.FC<
  TopBarProfileStatusButtonProps
> = ({
  selectConversationUnreadCounts,
  handleToggleHeader,
  presence,
  status,
  data,
  testid,
}) => {
  const { title = '', message = '' } = status;

  return (
    <div
      className="user-name-wrap-inner"
      onClick={handleToggleHeader}
      data-testid={`${testid}-buttonProfileStatus`}
    >
      <UserAvatar
        name={data.DisplayName}
        selectUnreadCounts={selectConversationUnreadCounts}
        presence={presence}
      />
      <Popup
        inverted
        trigger={title && <span className="message-status">{title}</span>}
        content={message}
        position="bottom right"
      />
      <img src={arrowDown} className="down-arrow" />
    </div>
  );
};
