import styled, { css } from 'styled-components';
import type { IconButtonProps } from './types';
import { Button } from '../Button';
import { Icon } from '../Icon';

const ICON_BUTTON_VARIANTS = {
  secondary: css<{ $active: boolean }>`
    ${({ theme, $active }) => css`
      border-radius: 50% !important;
      border: 1px solid ${theme.colors.buttonSecondaryBorder} !important;
      background: ${theme.colors.base} !important;
      padding: ${theme.sizes.xxxsmall} !important;
      &:hover {
        background: ${theme.colors.border} !important;
      }
      &:active {
        background: ${theme.colors.buttonSecondaryClick} !important;
        border: 1px solid ${theme.colors.buttonSecondaryClick} !important;
      }
      ${$active &&
      css`
        background: ${theme.colors.buttonSecondarySelected} !important;
        color: ${theme.colors.base} !important;
      `}
    `}
  `,
};

const ButtonSafeArea = styled.div<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  ${({ $disabled }) => $disabled && `cursor: not-allowed;`}
`;

const StyledButton = styled(Button)<{
  $compact: boolean;
  $variant: IconButtonProps['variant'];
}>`
  display: flex !important;
  padding: 0 10px !important;
  margin: 0 !important;
  ${({ $compact }) => $compact && `padding: 0 5px !important;`}
  &&&& {
    ${({ $variant }) => $variant && ICON_BUTTON_VARIANTS[$variant]}
  }
`;

const StyledIcon = styled(Icon)`
  &,
  &:hover,
  &:active {
    color: unset !important;
  }
`;

export const Styled = {
  ButtonSafeArea,
  Button: StyledButton,
  Icon: StyledIcon,
};
