import styled from 'styled-components';

const StyledUserDropdownMenuHelp = styled.div``;

const StyledUserDropdownMenuHelpLink = styled.a`
  display: flex;
  width: 100%;
  margin-left: 0px;
  font-size: 16px;
  padding: 6px 25px;
  box-shadow: none !important;
`;

export const Styled = {
  UserDropdownMenuHelp: StyledUserDropdownMenuHelp,
  UserDropdownMenuHelpLink: StyledUserDropdownMenuHelpLink,
};
