import { PhoneNumberFormat } from 'google-libphonenumber';
import React from 'react';
import phoneUtil from '../../../../utils/phoneUtil';
import type { DirectoryLinkListProps, DirectoryLinksProps } from './types';

import { TextLink } from '../../../shared/TextLink';

export const DirectoryLinks: React.FC<DirectoryLinksProps> = ({
  data: { mobileNumber, email, extensionNumber, id },
  loggedInPersonId,
  makeCall,
  testid: parentTestId,
}) => {
  const testid = `${parentTestId}-directoryLinks`;

  const formatNumber = (num: string) => {
    if (phoneUtil.isPossibleNumberString(num, 'US')) {
      const parsePhoneNumber = phoneUtil.parse(num, 'US');
      return phoneUtil.format(parsePhoneNumber, PhoneNumberFormat.NATIONAL);
    }
    return num;
  };

  const DIRECTORY_LINKS: DirectoryLinkListProps = [
    {
      testid: `${testid}-linkCall`,
      content: formatNumber(mobileNumber),
      icon: 'on-call',
      props: {
        ...(id !== loggedInPersonId && {
          onClick: () => makeCall('phoneNumber'),
        }),
      },
    },
    {
      testid: `${testid}-linkEmail`,
      content: email,
      icon: 'message',
      props: { href: `mailto:${email}` },
    },
    {
      testid: `${testid}-linkExtension`,
      content: extensionNumber,
      icon: 'phone',
      props: {},
    },
  ];

  return (
    <React.Fragment>
      {DIRECTORY_LINKS.map(({ content, icon, testid, props }) =>
        content ? (
          <TextLink key={content} data-private {...{ icon, testid, ...props }}>
            {content}
          </TextLink>
        ) : null
      )}
    </React.Fragment>
  );
};
