import { action, observable, makeObservable } from 'mobx';

export interface IContact {
  id?: number;
  providerContactId?: string;
  providerAccountId?: string;
  phoneNumbers: any[];
  emails: any;
  firstName: string;
  lastName: string;
  middleName: string;
  organization: string;
  organizationRole: string;
  createdAt?: string;
  updatedAt?: string;
  platformUserId?: string;
  platformAccountId?: number;
  hasProfilePicture?: boolean;
  pictureUrl?: string;
  notes: string;
  isShared?: boolean;
  pictureKey?: string;
  accountId?: number;
  source?: string;
  sourceAccountId?: number;
  selectedSource?: string;
}
export class Contact implements IContact {
  @observable
  id: number;

  @observable
  providerContactId?: string;

  @observable
  providerAccountId?: string;

  @observable
  phoneNumbers: any[];

  @observable
  emails: any;

  @observable
  firstName: string;

  @observable
  lastName: string;

  @observable
  middleName: string;

  @observable
  organization: string;

  @observable
  organizationRole: string;

  @observable
  accountId?: number;

  @observable
  createdAt?: string;

  @observable
  updatedAt?: string;

  @observable
  platformUserId?: string;

  @observable
  platformAccountId?: number;

  @observable
  hasProfilePicture?: boolean;

  @observable
  pictureUrl?: string;

  @observable
  notes: string;

  @observable
  isShared?: boolean;

  @observable
  pictureKey?: string;

  @observable
  source?: string;

  @observable
  sourceAccountId?: number;

  @observable
  selectedSource?: string;

  DisplayName? = () => {
    const firstName = this.firstName
      ? `${this.firstName.charAt(0).toUpperCase()}${this.firstName.slice(1)}`
      : '';
    const lastName = this.lastName
      ? `${this.lastName.charAt(0).toUpperCase()}${this.lastName.slice(1)}`
      : '';
    if (!firstName && !lastName) {
      return `${this.organization}`;
    }
    return `${firstName} ${lastName}`;
  };

  constructor(dto: IContact) {
    makeObservable(this);
    this.id = dto.id;
    this.providerContactId = dto.providerContactId;
    this.providerAccountId = dto.providerAccountId;
    this.phoneNumbers = dto.phoneNumbers;
    this.emails = dto.emails;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.middleName = dto.middleName;
    this.organization = dto.organization;
    this.organizationRole = dto.organizationRole;
    this.createdAt = dto.createdAt;
    this.updatedAt = dto.updatedAt;
    this.platformUserId = dto.platformUserId;
    this.platformAccountId = dto.platformAccountId;
    this.hasProfilePicture = dto.hasProfilePicture;
    this.notes = dto.notes;
    this.isShared = dto.isShared;
    this.pictureKey = dto.pictureKey;
    this.accountId = dto.accountId;
    this.source = dto.source;
    this.pictureUrl = dto.pictureUrl;
  }
  static FromResponseDto = (responseDto: IContact) => {
    return new Contact(responseDto);
  };

  @action
  setPictureUrl? = (url) => (this.pictureUrl = url);
}

export const emptyContact: IContact = {
  id: null,
  providerContactId: null,
  providerAccountId: null,
  phoneNumbers: [],
  emails: [],
  firstName: null,
  lastName: null,
  middleName: null,
  organization: null,
  organizationRole: null,
  createdAt: null,
  updatedAt: null,
  platformUserId: null,
  platformAccountId: null,
  hasProfilePicture: null,
  notes: null,
  isShared: null,
  pictureKey: null,
};
