import cx from 'classnames';
import { CALL_STATE } from 'Constants/enums';
import { SHOW_PERSONAL_VIDEO_LINK } from 'Constants/env';
import { STORE_CONFIG, STORE_ROUTER } from 'Constants/stores';
import { isEmpty, startCase } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Contact } from 'Models/Contacts';
import * as React from 'react';
import { Grid, Input, Popup } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { formatNumber, formatNumberWithNationalCode } from 'Utils/phoneUtil';
import {
  selectProperVideoIcon,
  selectProperVideoTooltipMessageForOneOnOnes,
} from 'Utils/videoConferenceUtils';
import { EntityInfoComponentProps } from './interfaces';

import CopyOff from 'Assets/images/copy-link-inactive.svg';
import phoneIcon from 'Assets/images/menu-call.svg';
import chatIcon from 'Assets/images/sms-icon.svg';
import withRouter from '../../hocs/WithRouter';
/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class EntityProfileInfo
 */

export interface ICopyLinkButtonState {
  hover: boolean;
  showTooltip: boolean;
  extrContacts: Contact[];
}
export class EntityProfileInfo extends React.Component<
  EntityInfoComponentProps,
  ICopyLinkButtonState,
  {}
> {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      showTooltip: false,
      extrContacts: null,
    };
  }

  componentDidMount() {
    this.refreshContactData();
  }
  componentDidUpdate() {
    this.refreshContactData();
  }

  refreshContactData = () => {
    const { contact, extrContacts, getExtrContactByPhoneNumber } = this.props;
    const { extrContacts: extStateContact } = this.state;
    const extrContactsMerged = extrContacts
      ? extrContacts
      : contact
      ? [getExtrContactByPhoneNumber(contact.phoneNumber)].filter(
          (contact) => contact
        )
      : [];
    if (
      (!extStateContact ||
        extStateContact?.length !== extrContactsMerged?.length) &&
      extrContactsMerged?.length > 0
    ) {
      this.setState({ extrContacts: extrContactsMerged });
    }
  };

  callDefaultUserLine = (e) => {
    e.preventDefault();
    const { contact, person, makeCall } = this.props;
    const { extrContacts } = this.state;
    if (person) {
      makeCall(person.id, null);
    } else if (extrContacts) {
      ///if we change current implementation, we have to change this
      //currently we are filtering all contacts by phone number instead of ID
      makeCall(null, extrContacts[0]?.phoneNumbers[0].number);
    } else {
      const primaryLine = contact.phoneNumber;
      if (!isEmpty(primaryLine)) {
        makeCall(null, primaryLine);
      } else {
        throw new Error('Contact Phone Number is Empty');
      }
    }
  };

  setDataLayer = (conversationIdFromUrl: string) => {
    if (window['dataLayer']) {
      window['dataLayer'].push({
        event: 'placeVideoConference',
        conversationId: conversationIdFromUrl,
      });
    }
  };

  placeVideoCallConference = (personId) => () => {
    const {
      channelInfoDetails,
      selectPersonValueById,
      postConferenceByConversationId,
      loadOrCreateConversationWithPost,
    } = this.props;
    // Parsing out ConversationId from URl and doing The Post
    const conversationIdFromUrl =
      selectPersonValueById(personId).data.personalConferenceUrl;
    const isDefault = channelInfoDetails.channelConversationId === '0';
    if (isDefault && conversationIdFromUrl) {
      const conversationId = isDefault
        ? conversationIdFromUrl.split('-').pop()
        : channelInfoDetails.channelConversationId;
      postConferenceByConversationId(conversationId);
      this.setDataLayer(conversationId);
    } else if (personId) {
      loadOrCreateConversationWithPost(personId).then((resp) => {
        const conversationId = resp.data.id.toString();
        postConferenceByConversationId(conversationId);
        this.setDataLayer(conversationId);
      });
    }
  };
  messageUser = () => {
    const { person, messageUser, contact, extrContacts } = this.props;
    if (!isEmpty(person)) {
      messageUser(person.id, null, person.contactType);
    } else if (extrContacts) {
      messageUser(null, extrContacts[0]?.phoneNumbers[0].number, null);
    } else {
      messageUser(null, contact.FormattedPhone, contact.contactType);
    }
  };
  handleFocus = (event) => {
    event.target.select();
  };

  copyLinkAndShowTooltip = () => {
    const { selectPersonValueById, loggedInPersonId } = this.props;
    const ele = document.createElement('input');
    document.body.appendChild(ele);
    ele.setAttribute(
      'value',
      selectPersonValueById(loggedInPersonId).data.personalConferenceUrl
    );
    ele.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
    ele.style.position = 'absolute';
    ele.style.left = '-9999px';
    ele.select();
    document.execCommand('copy');
    document.body.removeChild(ele);
    this.setState({ showTooltip: true });
    setTimeout(() => {
      this.setState({ showTooltip: false });
    }, 5000);
  };

  getContact = (id: number, phoneNums: any[]) => {
    const { extrContacts, allContactByPhone } = this.props;
    const phoneNum = phoneNums && phoneNums[0]?.number;
    const formatedPhoneNum = formatNumberWithNationalCode(phoneNum);
    const contactFromPhoneList =
      phoneNum &&
      allContactByPhone.has(formatedPhoneNum) &&
      allContactByPhone.get(formatedPhoneNum);
    const contactFromAllContact =
      !contactFromPhoneList &&
      extrContacts?.find((contact) => contact.id === id);
    return contactFromPhoneList || contactFromAllContact;
  };
  editExtContacts = (id: number, phoneNumbers: string[]) => (e) => {
    const { setEditContact, navigate } = this.props;
    e.stopPropagation();
    const contact = this.getContact(id, phoneNumbers);
    setEditContact(contact);
    navigate({ pathname: '/addressBook/contact', state: { from: '/chat' } });
  };
  render() {
    const {
      person,
      contact,
      loggedInPersonVideoFeature,
      loggedInAccountId,
      loggedInPersonId,
      loggedInUserActiveConferenceConversation,
      config,
      selectPersonValueById,
      phoneCalls,
      openDetailsFromWhere,
    } = this.props;
    const extrContacts = this.state.extrContacts;
    const conferenceIsActive =
      !isNullOrUndefined(loggedInUserActiveConferenceConversation) &&
      !isNullOrUndefined(
        loggedInUserActiveConferenceConversation.activeConference
      ) &&
      loggedInUserActiveConferenceConversation.activeConference.sessionId !==
        '';
    const userIsInActiveVideoConference = !isNullOrUndefined(
      loggedInUserActiveConferenceConversation
    );
    const commProfile =
      !isNullOrUndefined(person) &&
      person.id?.toString() !== loggedInPersonId?.toString();
    const mobileProfile =
      isNullOrUndefined(person) && !isNullOrUndefined(contact);
    let callState = CALL_STATE.Available;
    const from = 'group-info';
    phoneCalls.filter((p) => {
      if (!isEmpty(person)) {
        const elementPR = p.callUri?.split('@')[0]?.substring(2);
        if (elementPR === person.id.toString()) {
          callState = p.isCallConnecting
            ? CALL_STATE.Connecting
            : CALL_STATE.Connected;
        }
      } else if (
        formatNumber(contact?.phoneNumber) ===
        formatNumber(p.callUri?.split('@')[0])
      ) {
        callState = p.isCallConnecting
          ? CALL_STATE.Connecting
          : CALL_STATE.Connected;
      }
    });
    return (
      <Grid>
        {!isNullOrUndefined(extrContacts) && !isEmpty(extrContacts) && (
          <Grid.Row>
            <Grid.Column data-private className="extr-contact-info">
              {extrContacts[0]?.organizationRole && (
                <div className="org-role">
                  {extrContacts[0]?.organizationRole}
                </div>
              )}
              {extrContacts[0]?.organization && (
                <div className="org">{extrContacts[0]?.organization}</div>
              )}
              <div className="all-phones">
                {startCase(extrContacts[0]?.phoneNumbers[0].type)}
                {': '}
                {formatNumberWithNationalCode(
                  extrContacts[0]?.phoneNumbers[0]?.number
                )}
              </div>
              <div
                onClick={this.editExtContacts(
                  extrContacts[0]?.id,
                  extrContacts[0]?.phoneNumbers
                )}
                className="edit-extr-button"
              >
                Edit Contact
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
        {person && (
          <Grid.Row data-private textAlign="center">
            <Grid.Column>
              {person?.jobTitle && (
                <div className="org-role">{person?.jobTitle}</div>
              )}
              {person?.department && (
                <div className="org">{person?.department}</div>
              )}
              {!isNullOrUndefined(person) &&
                person.lines?.map((line) => (
                  <div key={line.id}>
                    {line.friendlyName}: {line.PhoneDisplay}
                  </div>
                ))}
              {
                // [BC-2511]
                SHOW_PERSONAL_VIDEO_LINK &&
                  !isNullOrUndefined(person) &&
                  person.id === loggedInPersonId &&
                  (loggedInAccountId === 9 || loggedInAccountId === 862) && (
                    <div className="copy-link-section">
                      Video Conference URL:
                      <div>
                        <Input
                          id="share-url"
                          onFocus={this.handleFocus}
                          placeholder="Something went wrong, Please Report"
                          value={
                            selectPersonValueById(loggedInPersonId).data
                              .personalConferenceUrl
                          }
                          readOnly
                        />
                      </div>
                      {this.state.showTooltip && (
                        <div className="bubble-tip copy-link-tip">
                          COPIED!
                          <div className="anchor-popup" />
                        </div>
                      )}
                      <div className="wrapper-share-link-row">
                        <div className="wrapper-share-link-column">
                          <Popup
                            inverted
                            position="bottom left"
                            trigger={
                              <button
                                className={`custom-button ${
                                  conferenceIsActive ? '' : ''
                                }`}
                                onClick={this.placeVideoCallConference}
                                disabled={
                                  !loggedInPersonVideoFeature.enabled ||
                                  (!isNullOrUndefined(person) &&
                                    !person.SupportsChat &&
                                    !person.phoneNumber)
                                }
                              >
                                <img
                                  className={cx('small-icon', {
                                    'disabled-video-icon':
                                      !conferenceIsActive &&
                                      userIsInActiveVideoConference,
                                    'disabled-active-video-icon':
                                      conferenceIsActive &&
                                      userIsInActiveVideoConference,
                                  })}
                                  src={selectProperVideoIcon(
                                    null,
                                    null,
                                    null,
                                    null
                                  )}
                                />
                              </button>
                            }
                          >
                            <div>
                              <div>
                                {selectProperVideoTooltipMessageForOneOnOnes(
                                  null,
                                  null
                                )}
                              </div>
                            </div>
                          </Popup>
                        </div>
                        <div className="wrapper-share-link-column">
                          <Popup
                            inverted
                            position="bottom left"
                            content="Copy Video Conference Link"
                            trigger={
                              <button
                                className="custom-button"
                                onClick={this.copyLinkAndShowTooltip}
                              >
                                <img
                                  className="video-icon"
                                  src={CopyOff}
                                  onMouseOver={(e) =>
                                    (e.currentTarget.src = `${CopyOff}`)
                                  }
                                />
                              </button>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )
              }
            </Grid.Column>
          </Grid.Row>
        )}
        {(commProfile || mobileProfile || extrContacts) && (
          <>
            {person && (
              <Grid.Row textAlign="center">
                <Grid.Column data-private>{person.email}</Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row
              columns={3}
              centered
              id="contact-options"
              className="margin-top-2rem wrapped-contact-options-icons"
            >
              {
                /*
                                    ----- SMS BUTTON -----
                                */
                openDetailsFromWhere !== 'callLogs' &&
                  ((person && person.SupportsChat) || extrContacts ? (
                    <Grid.Column
                      textAlign="center"
                      className="info-sidebar-action-buttons"
                    >
                      <Popup
                        inverted
                        position="bottom left"
                        content="Start Message"
                        trigger={
                          <div className="flex-column flex-wrap flex-justify-center">
                            <button
                              className="custom-button"
                              onClick={this.messageUser}
                              id="chat-info-person"
                            >
                              <img className="chat-icon" src={chatIcon} />
                            </button>
                            <div className="text">Chat</div>
                          </div>
                        }
                      />
                    </Grid.Column>
                  ) : (
                    <Grid.Column
                      textAlign="center"
                      className="info-sidebar-action-buttons"
                    >
                      <Popup
                        inverted
                        position="bottom right"
                        content="SMS User"
                        trigger={
                          <div className="flex-row flex-wrap flex-justify-center">
                            <button
                              className="custom-button"
                              onClick={this.messageUser}
                              id="sms-info-person"
                            >
                              <div className="no-icon-sms">SMS</div>
                            </button>
                            <div className="text">SMS</div>
                          </div>
                        }
                      />
                    </Grid.Column>
                  ))
              }
              {
                /*
                                    ----- VIDEO BUTTON -----
                                    Disabled IF:
                                        - In another video conference
                                        - darkDisabledVidIcon === true
                                        - Is not a pro user and doesn't have a phone number
                                */
                config &&
                  person &&
                  config.signedInPersonConfig?.state === 'fulfilled' &&
                  config.signedInPersonConfig?.value.data.video.enabled &&
                  !extrContacts &&
                  this.props.openDetailsFromWhere !== 'callLogs' && (
                    <Grid.Column
                      textAlign="center"
                      className="info-sidebar-action-buttons"
                    >
                      <Popup
                        inverted
                        position="bottom left"
                        trigger={
                          <div className="flex-column flex-wrap flex-justify-center">
                            <button
                              className={`custom-button`}
                              id="video-call-info-person"
                              onClick={this.placeVideoCallConference(person.id)}
                              disabled={!loggedInPersonVideoFeature.enabled}
                            >
                              <img
                                className={'video-icon'}
                                src={selectProperVideoIcon(
                                  null,
                                  null,
                                  null,
                                  null,
                                  from
                                )}
                              />
                            </button>
                            <div className="text">Video</div>
                          </div>
                        }
                      >
                        <div>
                          <div>
                            {selectProperVideoTooltipMessageForOneOnOnes(
                              null,
                              null
                            )}
                          </div>
                        </div>
                      </Popup>
                    </Grid.Column>
                  )
              }
              {/*
                                    ----- CALL BUTTON -----
                                */}

              {openDetailsFromWhere !== 'callLogs' && (
                <Grid.Column
                  textAlign="center"
                  className="info-sidebar-action-buttons"
                >
                  <Popup
                    inverted
                    position="bottom left"
                    content={
                      callState !== CALL_STATE.Available
                        ? 'You are currently on a call with this person'
                        : 'Start Call'
                    }
                    trigger={
                      <div className="flex-column flex-wrap flex-justify-center">
                        <button
                          id="phone-call-info-person"
                          className={
                            callState !== CALL_STATE.Available
                              ? 'custom-button-already-on-call'
                              : 'custom-button'
                          }
                          onClick={(e) => {
                            if (callState === CALL_STATE.Available) {
                              this.callDefaultUserLine(e);
                            }
                          }}
                        >
                          <img
                            id="phone-call"
                            className="phone-icon"
                            src={phoneIcon}
                          />
                        </button>
                        <div className="text">Call</div>
                      </div>
                    }
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </>
        )}
        {extrContacts?.length > 1 && (
          <div className="overflow-scroll-y same-contacts">
            {extrContacts.map((contact, index) => {
              if (index >= 1) {
                return (
                  <div
                    key={contact.id}
                    className="extr-contact-info margin-top-2rem"
                  >
                    <div>Same phone number on</div>
                    <div className="other-extContact">
                      {contact.DisplayName()}
                    </div>
                    <div
                      className="edit-extr-button"
                      onClick={this.editExtContacts(
                        contact.id,
                        contact.phoneNumbers
                      )}
                    >
                      Edit Contact
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
      </Grid>
    );
  }
}

export default inject(
  STORE_CONFIG,
  STORE_ROUTER
)(withRouter(observer(EntityProfileInfo)));
