import React from 'react';
import { ConfirmationModal } from 'Components/shared/ConfirmationModal';
import type { RemoveEventModalProps } from './types';

export const RemoveEventModal = ({
  calendar,
  isRemoveEventModalOpened,
  eventForRemoval,
  handleRemoveEventModalClose,
  testId: parentTestId
}: RemoveEventModalProps) => {
  const handleCalendarRemoveEvent = async () => {
    const isDeleted = await calendar.deleteEventById(eventForRemoval.id);

    isDeleted && handleRemoveEventModalClose();
  };

  return (
    <ConfirmationModal
      show={isRemoveEventModalOpened}
      onClose={handleRemoveEventModalClose}
      onCancel={handleRemoveEventModalClose}
      onConfirm={handleCalendarRemoveEvent}
      textConfirm='Yes'
      textCancel='No'
      testId={`${parentTestId}-removeEventModal`}
    >
      <div>
        You are about to delete the event <b data-private>{eventForRemoval.title}</b> sync.
      </div>

      <div>Are you sure?</div>
    </ConfirmationModal>
  )
};
