import styled, { css } from 'styled-components';

const ContentAreaWrapper = styled.div`
  max-width: calc(100vw - 70px);
  max-height: calc(100vh - 70px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentArea = styled.div<{ $inlineTopBanner: boolean }>`
  padding: 30px 15px 15px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex: 1;

  @media ${({ theme }) => theme.mediaQueries.onlyPlugin} {
    overflow-x: auto;
  }

  ${({ $inlineTopBanner }) =>
    $inlineTopBanner &&
    css`
      padding-top: 0;
    `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  @media ${({ theme }) => theme.mediaQueries.onlyPlugin} {
    width: 545px;
  }
`;

export const Styled = {
  ContentAreaWrapper,
  ContentArea,
  Content,
};
