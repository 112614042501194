import styled from 'styled-components';
import { BodyCopy } from 'Components/shared/BodyCopy';

const Disclaimer = styled(BodyCopy)`
  text-align: center;
  max-width: 544px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.secondary};
  margin-top: 53px;
`;

export const Styled = { Disclaimer };