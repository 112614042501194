import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { STORE_PHONE_CALL } from '../../constants/stores';
import { PhoneNumProps } from './interfaces';
import withRouter from '../../hocs/WithRouter';

class PhoneNumbersPopup extends React.Component<PhoneNumProps> {
  makeCall = (phoneNumber: number) => (e) => {
    const { phoneCall } = this.props;
    e.stopPropagation();
    phoneCall.callWithPerson(null, phoneNumber.toString());
  };
  handleOpenChat = (phoneNumber: number) => async (e) => {
    e.stopPropagation();
    const resp = await this.props.loadOrCreateConversationWithPost(
      null,
      phoneNumber.toString()
    );
    this.props.navigate(`/chat/conversations/${resp.data.id}/menu`);
  };
  render() {
    const { phoneNumbers, operationType, classNames } = this.props;
    return (
      <div className={`phonenum-popup ${classNames}`}>
        {phoneNumbers?.map((phone) =>
          phone.number ? (
            <div
              key={phone.number + phone.type}
              onClick={
                operationType === 'call'
                  ? this.makeCall(phone.number)
                  : this.handleOpenChat(phone.number)
              }
            >
              {phone.type.charAt(0).toUpperCase() + phone.type.slice(1)}:{' '}
              {phone.number}
            </div>
          ) : (
            <div
              key={phone}
              onClick={
                operationType === 'call'
                  ? this.makeCall(phone)
                  : this.handleOpenChat(phone)
              }
            >
              {phone}
            </div>
          )
        )}
      </div>
    );
  }
}
export default inject(STORE_PHONE_CALL)(
  withRouter(observer(PhoneNumbersPopup))
);
