import React from 'react';
import type { TextLinkProps, TextLinkUnionProps } from './types';
import { Styled } from './index.styles';

export const TextLink: React.FC<TextLinkProps & TextLinkUnionProps> = ({
  icon,
  onClick,
  href,
  children,
  testid,
  ...props
}) => (
  <Styled.TextLink
    data-testid={testid}
    {...(href ? { href, target: '_blank', rel: 'noreferrer' } : { onClick })}
    {...props}
  >
    {icon && <Styled.Icon {...{ icon }} />}
    {children}
  </Styled.TextLink>
);
