import React, { useEffect, useRef, useState } from 'react';
import { STORE_PERSON } from 'Constants/stores';
import { uniqBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Grid } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import type { ProfileSettingsProps, ProfileSettingsState } from './interfaces';

import { ProfileOverview } from './ProfileOverview';
import { FormInput } from '../../../shared/FormInput';
import { Heading } from '../../../shared/Heading';

const initialState: ProfileSettingsState = {
  username: '',
  localExtension: '',
  email: '',
  profilePictureUrl: '',
  loadingProfilePictureUrl: true,
  inboundNumbers: [],
  outboundNumbers: [],
  firstName: {
    value: '',
  },
  lastName: {
    value: '',
  },
  mobileNumber: {
    oldValue: '',
    value: '',
    isEditing: false,
    loading: false,
  },
  timeZone: {
    value: '',
  },
  jobTitle: {
    oldValue: '',
    value: '',
    isEditing: false,
    loading: false,
  },
  department: {
    oldValue: '',
    value: '',
    isEditing: false,
    loading: false,
  },
};

const ProfileSettings: React.FC<ProfileSettingsProps> = ({ person }) => {
  const testid = 'profileSettings';
  const mobileNumberRef = useRef(null);
  const jobTitleRef = useRef(null);
  const departmentRef = useRef(null);
  const [state, setState] = useState<ProfileSettingsState>(initialState);

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    try {
      const userProfile = await person.getUserProfile();
      userProfile &&
        setState({
          ...state,
          username: userProfile.name,
          localExtension: userProfile.extensionNumber,
          email: userProfile.email,
          profilePictureUrl: userProfile.profilePictureUrl,
          loadingProfilePictureUrl: false,
          inboundNumbers: uniqBy(
            userProfile.inboundNumbers,
            (line: any) => line?.callerId
          ),
          outboundNumbers: uniqBy(
            userProfile.lines,
            (line: any) => line?.callerId
          ),
          firstName: {
            value: userProfile.firstName,
          },
          lastName: {
            value: userProfile.lastName,
          },
          mobileNumber: {
            ...state.mobileNumber,
            value: userProfile.mobileNumber,
            oldValue: userProfile.mobileNumber,
          },
          timeZone: {
            value: userProfile.timeZone,
          },
          jobTitle: {
            ...state.jobTitle,
            value: userProfile.jobTitle,
            oldValue: userProfile.jobTitle,
          },
          department: {
            ...state.department,
            value: userProfile.department,
            oldValue: userProfile.department,
          },
        });
    } catch (error) {
      console.warn(error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: {
        ...state[e.target.name],
        value: e.target.value,
      },
    });
  };

  const toggleEditInput = (key: string) => {
    setState({
      ...state,
      [key]: {
        ...state[key],
        isEditing: !state[key].isEditing,
      },
    });
    switch (key) {
      case 'mobileNumber':
        mobileNumberRef.current.lastChild.firstChild.focus();
        break;
      case 'jobTitle':
        jobTitleRef.current.lastChild.firstChild.focus();
        break;
      case 'department':
        departmentRef.current.lastChild.firstChild.focus();
        break;
    }
  };

  const submitFieldChange = async (key: string) => {
    try {
      const results = await person.updateProfile(person.loggedInPersonId, {
        [key]: state[key].value,
      });
      setState({
        ...state,
        [key]: {
          ...state[key],
          value: results ? state[key].value : state[key].oldValue,
          oldValue: state[key].value,
        },
      });
      toggleEditInput(key);
    } catch (error) {
      console.warn(error);
    }
  };

  const cancelFieldChange = (key: string) => {
    setState({
      ...state,
      [key]: {
        ...state[key],
        error: undefined,
        isEditing: !state[key].isEditing,
        value: state[key].oldValue,
      },
    });
  };

  const fileImportEvent = async (e: any): Promise<void> => {
    setState({
      ...state,
      loadingProfilePictureUrl: true,
    });
    const files = e.clipboardData?.files || e.target?.files || [];
    if (!files) {
      return;
    }
    const importedFiles: File[] = Array.from(files);
    const updatedPerson = await person.handleUploadToAWS(person, importedFiles);

    if (isNullOrUndefined(updatedPerson)) {
      return;
    }

    setState({
      ...state,
      profilePictureUrl: updatedPerson[0].data.profilePictureUrl,
      loadingProfilePictureUrl: false,
    });
  };

  return (
    <React.Fragment>
      <Heading variant="h2">Profile Settings</Heading>
      <Grid.Row className="ui grid margin-top-0 margin-bottom-0">
        <Grid.Column width={8}>
          <FormInput
            {...{ testid }}
            onChange={handleInputChange}
            id="firstName"
            label="First Name"
            value={state.firstName.value || ''}
            disabled
          />
          <FormInput
            {...{ testid }}
            onChange={handleInputChange}
            id="lastName"
            label="Last Name"
            value={state.lastName.value || ''}
            disabled
          />
          <FormInput
            {...{ testid }}
            onChange={handleInputChange}
            id="mobileNumber"
            label="Mobile Number"
            value={state.mobileNumber.value || ''}
            disabled={!state.mobileNumber.isEditing}
            editProps={{
              ref: mobileNumberRef,
              onEditClick: () => toggleEditInput('mobileNumber'),
              onSubmitClick: () => submitFieldChange('mobileNumber'),
              onCancelClick: () => cancelFieldChange('mobileNumber'),
            }}
          />
          <FormInput
            {...{ testid }}
            id="timeZone"
            label="Timezone"
            value={state.timeZone.value || ''}
            disabled
          />
          <FormInput
            {...{ testid }}
            onChange={handleInputChange}
            id="jobTitle"
            label="Job Title"
            value={state.jobTitle.value || ''}
            disabled={!state.jobTitle.isEditing}
            editProps={{
              ref: jobTitleRef,
              onEditClick: () => toggleEditInput('jobTitle'),
              onSubmitClick: () => submitFieldChange('jobTitle'),
              onCancelClick: () => cancelFieldChange('jobTitle'),
            }}
          />
          <FormInput
            {...{ testid }}
            onChange={handleInputChange}
            id="department"
            label="Department"
            value={state.department.value || ''}
            disabled={!state.department.isEditing}
            editProps={{
              ref: departmentRef,
              onEditClick: () => toggleEditInput('department'),
              onSubmitClick: () => submitFieldChange('department'),
              onCancelClick: () => cancelFieldChange('department'),
            }}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <ProfileOverview
            {...{
              testid,
              profilePictureUrl: state.profilePictureUrl,
              loadingProfilePictureUrl: state.loadingProfilePictureUrl,
              fileImportEvent,
              username: state.username,
              localExtension: state.localExtension,
              email: state.email,
              inboundNumbers: state.inboundNumbers,
              outboundNumbers: state.outboundNumbers,
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};
export default inject(STORE_PERSON)(observer(ProfileSettings));
