import { imageFileTypes, LOAD_DIRECTION } from 'Constants/enums';
import {
  API_ENDPOINTS,
  EMPTY_TIMEUUID,
  MESSAGE_BACKFILL_RECURSION_LIMIT,
  MESSAGE_LOAD_LIMIT,
  MSG_API_BASE_URI,
  NODE_ENV_LOCAL_OR_DEVELOPMENT,
  NODE_ENV_PRODUCTION,
  TIMEZONE_IDENTIFIER,
} from 'Constants/env';
import { STORE_CHAT_HTML, STORE_CHAT_RAW } from 'Constants/localstorage';
import { MessagesGetRequest } from 'Interfaces/apiDtos';
import { AxiosResponseT, ResultsCollection } from 'Interfaces/axiosResponse';
import localforage from 'localforage';
import { isEmpty } from 'lodash';
import {
  action,
  IObservableArray,
  observable,
  ObservableMap,
  runInAction,
  toJS,
  when,
  makeObservable,
} from 'mobx';
import { fromPromise, createTransformer } from 'mobx-utils';
import type { IPromiseBasedObservable } from 'mobx-utils';
import { GroupedMessagesContainer } from 'Models/GroupedMessagesContainer';
import {
  IMessageDocument,
  IMessageModel,
  IMessageModelChat,
  IMessageModelSMS,
  MessageModel,
} from 'Models/MessageModel';
import moment from 'moment-timezone';
import { RootStore } from 'Stores/RootStore';
import { isNullOrUndefined } from 'util';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { bugsnagClient } from 'Utils/logUtils';
import { resizeImage } from 'Utils/resizeImage';
import { getISOStringFromTimeUUID } from 'Utils/timeUUIDParser';
import API, { PURE_API } from '../api';
import { BaseStore } from './BaseStore';
import ConversationStore from './ConversationStore';
import UiStore from './UiStore';

export class MessageStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @observable public fileUploadedS3 = new Map<string, IMessageDocument>();

  @observable public groupedMessagesByConversationMap = new Map<
    string,
    GroupedMessagesContainer
  >();

  /** Whether resources such as images/link previews are currently loading for the active Conversation */
  @observable public resourceLoadStatus = new ObservableMap<string>();

  /** Raw text of the Message currently being drafted, per Conversation Id */
  @observable public messageDraftRawMap = new ObservableMap<string>();
  /** Display HTML of the Message currently being drafted, per Conversation Id. These values should be generated using a `contenteditable` div's innerHTML */
  @observable public messageDraftHtmlMap = new ObservableMap<string>();

  /** Raw text of the Message currently being edited */
  @observable public editMessageDraftRaw = '';
  /** Display HTML of the Message currently being edited. These values should be generated using a `contenteditable` div's innerHTML */
  @observable public editMessageDraftHtml = '';

  /** Whether the `AtMentionMembers` list is open on the **new** message input area */
  @observable public createMessageMentionListOpen: boolean = false;
  /** When the `AtMentionMembers` list is open on **new** input area, track the highlighted `Participant` id. `null` if none selected. */
  @observable public createMessageMentionSelectedParticipantId: string = null;
  /** Text typed after an `@` symbol in the **new** message input area, used to filter the `ParticipantPerson` list in a Channel when the Mentions list is open.  */
  @observable public createMessageMentionFilter: string = '';

  /** Whether the `AtMentionMembers` list is open on the **edit** message input area */
  @observable public editMessageMentionListOpen: boolean = false;
  /** When the `AtMentionMembers` list is open on **edit** input area, track the highlighted `Participant` id. `null` if none selected.*/
  @observable public editMessageMentionSelectedParticipantId: string = null;
  /** Text typed after an `@` symbol in the **edit** message input area, used to filter the `ParticipantPerson` list in a Channel when the Mentions list is open.  */
  @observable public editMessageMentionFilter: string = '';
  /** Whether older `Message`s are being loaded in an attempt to backfill to the last read `Message` */
  @observable public isBackfillingToReadMessage = new ObservableMap<
    string,
    boolean
  >();

  /** Map containing `Conversation` `id` keys, and a PBO wrapping the latest request to load messages for each `Conversation` */
  @observable public messageByConvStatusMap = new Map<
    string,
    IPromiseBasedObservable<AxiosResponseT<ResultsCollection<MessageModel>>>
  >();

  /** Cache a list of Message `id`s that have been passed as the `SkipId` when requesting more Messages for a Conversation, in order to avoid duplicating requests. */
  @observable private loadMoreMessagesSkipIdsMap = new Map<
    string,
    IObservableArray<string>
  >();

  @observable
  private loadMoreConversationMessagesStatus: IPromiseBasedObservable<
    AxiosResponseT<ResultsCollection<MessageModel>>
  > = null;

  @observable private createMessagePostStatus: IPromiseBasedObservable<
    AxiosResponseT<MessageModel>
  > = null;

  @observable private editMessagePostStatus: IPromiseBasedObservable<
    AxiosResponseT<MessageModel>
  > = null;

  @observable private deleteMessagePostStatus: IPromiseBasedObservable<
    AxiosResponseT<MessageModel>
  > = null;

  /** Select `IPromiseBasedObservable<AxiosResponseT<ResultsCollection<Message>>>` for the given `Conversation` `id` */
  selectLoadMessagesStatusByConversationId = createTransformer(
    (conversationId: string) =>
      this.messageByConvStatusMap.has(conversationId)
        ? this.messageByConvStatusMap.get(conversationId)
        : fromPromise.resolve()
  );

  selectGroupedMessagesForConversation = createTransformer(
    (conversationId: string) => {
      return this.groupedMessagesByConversationMap.has(conversationId)
        ? this.groupedMessagesByConversationMap.get(conversationId)
        : null;
    }
  );

  /**
   * Select `Message.Id`s for a `Conversation` that have already been skipped over as part of a previous request to load more `Message`s.
   * Returns `null` if the `Conversation.Id` isn't in the map.
   */
  selectLoadMoreMessagesSkipIds = createTransformer(
    (conversationId: string) => {
      return this.loadMoreMessagesSkipIdsMap.has(conversationId)
        ? toJS(this.loadMoreMessagesSkipIdsMap.get(conversationId))
        : null;
    }
  );

  /* ----------------- EDIT MESSAGE CODE --------------- */
  /** If editing any `Message` (in the active `Conversation`), this is the `id`, otherwise it will be set to `undefined` */
  @observable
  isEditingMessageId: string;

  /** The `id` of the newest `Message` that was sent by the logged-in `Person` (in the active `Conversation`).  */
  @observable
  newestMessageOwnedByUserId: MessageModel;
  @action
  setNewestMessageOwnedByUserId = (conversationId: string) => {
    if (!this.groupedMessagesByConversationMap.has(conversationId)) {
      this.newestMessageOwnedByUserId = null;
      return;
    }
    const mostRecent = this.groupedMessagesByConversationMap
      .get(conversationId)
      .AllMessagesDescending.find(
        (m) => m.personId === this.rootStore.personStore.loggedInPersonId
      );
    if (
      !isNullOrUndefined(mostRecent) &&
      mostRecent.isDeleted !== true &&
      (mostRecent.sms === undefined || mostRecent.sms === null)
    ) {
      this.newestMessageOwnedByUserId = mostRecent;
    } else {
      this.newestMessageOwnedByUserId = null;
    }
  };

  @action
  setMessageDraftRaw = (conversationId: string, rawContent?: string) => {
    this.messageDraftRawMap.set(conversationId, rawContent);
  };

  getMessageDraftRaw = createTransformer((conversationId: string) => {
    if (this.messageDraftRawMap.has(conversationId)) {
      return this.messageDraftRawMap.get(conversationId);
    } else {
      return '';
    }
  });

  @action
  setMessageDraftHtml = (conversationId: string, htmlContent?: string) => {
    this.messageDraftHtmlMap.set(conversationId, htmlContent);
    this.setLocalStorageForDrafts();
  };
  @action
  public setLocalStorageForDrafts = () => {
    //serialize the map
    localforage.setItem(STORE_CHAT_HTML, toJS(this.messageDraftHtmlMap));
    //serialize the map
    localforage.setItem(STORE_CHAT_RAW, toJS(this.messageDraftRawMap));
  };

  getMessageDraftHtml = createTransformer((conversationId: string) => {
    if (this.messageDraftHtmlMap.has(conversationId)) {
      return this.messageDraftHtmlMap.get(conversationId);
    } else {
      return '';
    }
  });

  @action
  setEditMessageDraftRaw = (rawContent: string) => {
    this.editMessageDraftRaw = rawContent;
  };

  @action
  setEditMessageDraftHtml = (htmlContent: string) => {
    this.editMessageDraftHtml = htmlContent;
  };

  @action
  setIsEditingMessageId = (id: string) => {
    if (id === null) {
      this.isEditingMessageId = undefined;
    } else {
      this.isEditingMessageId = id;
    }
  };

  /**
   * Set whether the `AtMentionMembers` list is open on the **new** message input area.
   *
   * - Automatically deselects any selected Participant via `setCreateMessageMentionSelectedParticipantId` if `false`.
   * - Automatically clears the create message mention filter via `setCreateMessageMentionFilter` if `false`
   */
  @action
  setCreateMessageMentionListOpen = (open: boolean) => {
    this.createMessageMentionListOpen = open;
    if (!open) {
      this.setCreateMessageMentionSelectedParticipantId(null);
      this.setCreateMessageMentionFilter('');
    }
  };
  /**
   * Set whether the `AtMentionMembers` list is open on the **edit** message input area.
   *
   * - Automatically deselects any selected Participant via `setEditMessageMentionSelectedParticipantId` if `false`.
   * - Automatically clears the edit message mention filter via `setEditMessageMentionFilter` if `false`
   */
  @action
  setEditMessageMentionListOpen = (open: boolean) => {
    this.editMessageMentionListOpen = open;
    if (!open) {
      this.setEditMessageMentionSelectedParticipantId(null);
      this.setEditMessageMentionFilter('');
    }
  };

  /**
   * Set the highlighted `Participant` id when the `AtMentionMembers` list is open on **new** input area.
   *
   * Pass `'here'` to select @here
   *
   * Pass `null` if none selected.
   */
  @action
  setCreateMessageMentionSelectedParticipantId = (participantId: string) => {
    this.createMessageMentionSelectedParticipantId = participantId;
  };
  /**
   * Set the highlighted `Participant` id when the `AtMentionMembers` list is open on **edit** input area.
   *
   * Pass `'here'` to select @here
   *
   * Pass `null` if none selected.
   */
  @action
  setEditMessageMentionSelectedParticipantId = (participantId: string) => {
    this.editMessageMentionSelectedParticipantId = participantId;
  };

  /**
   * Set the text used to filter `ParticipantPerson`s in the **new** input area.
   * This text will be passed as `filterVal` to `ParticipantStore#selectFilteredOtherParticipantPersonsInCurrentConversation`.
   *
   * Pass `''` (empty string) to clear the filter.
   */
  @action
  setCreateMessageMentionFilter = (createFilterVal: string) => {
    this.createMessageMentionFilter = createFilterVal;
  };

  /**
   * Set the text used to filter `ParticipantPerson`s in the **edit** input area.
   * This text will be passed as `filterVal` to `ParticipantStore#selectFilteredOtherParticipantPersonsInCurrentConversation`.
   *
   * Pass `''` (empty string) to clear the filter.
   */
  @action
  setEditMessageMentionFilter = (editFilterVal: string) => {
    this.editMessageMentionFilter = editFilterVal;
  };

  /**
   * Set whether older `Message`s are being loaded in an attempt to backfill to the last read `Message` for a `Conversation`.
   * @param conversationId
   * @param isBackfilling
   */
  @action
  setIsBackfillingToReadMessage = (
    conversationId: string,
    isBackfilling: boolean
  ) => {
    this.isBackfillingToReadMessage.set(conversationId, isBackfilling);
  };

  /**
   * Select whether older `Message`s are being loaded in an attempt to backfill to the last read `Message` for a `Conversation`.
   * @param conversationId
   */
  selectIsBackfillingToReadMessage = createTransformer(
    (conversationId: string) => {
      return this.isBackfillingToReadMessage.has(conversationId)
        ? this.isBackfillingToReadMessage.get(conversationId)
        : false;
    }
  );

  @action
  clearAllData = () => {
    this.groupedMessagesByConversationMap.clear();
    this.messageByConvStatusMap.clear();
    this.loadMoreMessagesSkipIdsMap.clear();
    this.createMessagePostStatus = null;
    this.editMessagePostStatus = null;
    this.deleteMessagePostStatus = null;
  };
  @action
  editMessage = (
    conversationId: string,
    messageId: string,
    chat: IMessageModelChat
  ) => {
    const fileData = this.rootStore.uiStore.fileDeleteModal;
    const documents = fileData.externalId
      ? [{ externalId: fileData?.externalId, isDeleted: true }]
      : null;
    this.editMessagePostStatus = fromPromise(
      API.put(
        API_ENDPOINTS.ConversationMessageById(conversationId, messageId),
        { chat, documents }
      )
    );
    this.editMessagePostStatus.then(
      () => {
        // Find the message group and the specific message that is stored, update it's content
        const existingMsgGroup =
          this.groupedMessagesByConversationMap.get(conversationId);
        if (existingMsgGroup !== undefined) {
          const message = existingMsgGroup.findMessageById(messageId);
          if (message !== null) {
            message.setChat(chat);
            message.setUpdated(moment.tz(TIMEZONE_IDENTIFIER).toISOString());
            message.setLinkPreview(null);
            message.setDocument(fileData.externalId);
            if (fileData.show) {
              this.rootStore.uiStore.setFileDeletePopup({
                ...fileData,
                show: false,
              });
            }
          }
        }
      },
      (reason) =>
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error editing Message'
        )
    );
    return this.editMessagePostStatus;
  };
  @action
  deleteMessage = (conversationId: string, messageId: string) => {
    this.deleteMessagePostStatus = fromPromise(
      API.delete(
        API_ENDPOINTS.ConversationMessageById(conversationId, messageId),
        {}
      )
    );

    this.deleteMessagePostStatus.then(
      () => {
        const existingMsgGroup =
          this.groupedMessagesByConversationMap.get(conversationId);
        if (existingMsgGroup !== undefined) {
          const theMsg = existingMsgGroup.findMessageById(messageId);
          theMsg.setChat({ text: '(Message Deleted)' });
          theMsg.setDeleted(true);
          theMsg.setLinkPreview(null);
          theMsg.setDocuments([]);
          this.setNewestMessageOwnedByUserId(conversationId);
        }
      },
      (reason) =>
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error deleting Message.'
        )
    );
    return this.deleteMessagePostStatus;
  };
  /* ------------------------------------------------- */

  /* ------------------ LINK PREVIEW ------------------ */
  @action
  loadLinkPreview = async (
    url: string,
    conversationId: string,
    messageId: string
  ) => {
    try {
      const resp = await API.get(API_ENDPOINTS.LinkPreviewMetaGet(url));
      if (resp) {
        const existingMsgGroup =
          this.groupedMessagesByConversationMap.get(conversationId);
        const theMsg = existingMsgGroup.findMessageById(messageId);
        theMsg.setLinkPreview(resp.data);
        return true;
      }
      return false;
    } catch (reason) {
      if (reason.response.status !== 400) {
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error loading link preview.'
        );
      }
      return false;
    }
  };
  /* -------------------------------------------------- */

  @action
  loadConversationMessages = async (
    conversationId: string,
    messagesGetRequest?: MessagesGetRequest
  ) => {
    if (conversationId === EMPTY_TIMEUUID) {
      console.warn('loadConversationMessages aborted on EMPTY_TIMEUUID');
      return null;
    }
    if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
      console.debug(
        `loadConversationMessages for ${conversationId}`,
        messagesGetRequest
      );
    }
    await this.rootStore.personStore.waitUntilLoggedIn();
    await this.rootStore.pusherStore.waitUntilPusherConnected();
    await this.rootStore.pusherStore.waitUntilPersonalChannelSubscribed();
    // Create `GroupedMessages` entry immediately so it isn't undefined when `<ContextContentItemsList>` mounts/renders
    if (!this.groupedMessagesByConversationMap.has(conversationId)) {
      const grpMsgs = new GroupedMessagesContainer(
        conversationId,
        [],
        this.rootStore.participantStore.selectLoggedInUserParticipantLastReadMessageId
      );
      this.groupedMessagesByConversationMap.set(conversationId, grpMsgs);
    }
    const loadConvMsgsStatus = fromPromise(
      API.get(
        API_ENDPOINTS.ConversationMessages(conversationId, messagesGetRequest)
      )
    );
    loadConvMsgsStatus.then(
      (resp) => {
        this.loadConversationMessagesSuccess(
          conversationId,
          LOAD_DIRECTION.Initial,
          resp
        );
      },
      (reason) =>
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error loading Conversation Messages'
        )
    );
    this.messageByConvStatusMap.set(conversationId, loadConvMsgsStatus);
    return loadConvMsgsStatus;
  };
  /**
   * Load Messages from the currently known newest Message, ex. upon re-connect
   */
  @action
  loadMessagesSinceNewestMessage = async (
    conversationId: string,
    newestMessageId: string
  ) => {
    const {
      rootStore: {
        preferenceStore,
        personStore,
        notificationStore,
        participantStore,
      },
    } = this;
    if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
      console.debug(
        `loadMoreConversationMessages for ${conversationId} || newestMessageId: ${newestMessageId}`
      );
    }
    await personStore.waitUntilLoggedIn();
    await preferenceStore.getExistingPreferenceData();
    // Create `GroupedMessages` entry immediately so it isn't undefined when `<ContextContentItemsList>` mounts/renders
    if (!this.groupedMessagesByConversationMap.has(conversationId)) {
      const grpMsgs = new GroupedMessagesContainer(
        conversationId,
        [],
        participantStore.selectLoggedInUserParticipantLastReadMessageId
      );
      this.groupedMessagesByConversationMap.set(conversationId, grpMsgs);
    }
    const msgsGet: MessagesGetRequest = {
      SkipId: newestMessageId,
      SkipOver: false,
      Limit: MESSAGE_LOAD_LIMIT,
      SortDirection: 'Ascending',
    };
    return when(() => personStore.IsLoggedIn, { timeout: 10000 })
      .then(() => {
        return preferenceStore.getExistingPreferenceData().then((resp) => {
          msgsGet.ShowCallMessagesInChat = resp.showCallMessagesInChat;
          const loadConvMsgsStatus = fromPromise(
            API.get(
              API_ENDPOINTS.ConversationMessagesAscending(
                conversationId,
                msgsGet
              )
            )
          );
          loadConvMsgsStatus.then(
            (resp) =>
              this.loadConversationMessagesSuccess(
                conversationId,
                LOAD_DIRECTION.Initial,
                resp
              ),
            (reason) =>
              notificationStore.addAxiosErrorNotification(
                reason,
                'Error loading Conversation Messages'
              )
          );
          this.messageByConvStatusMap.set(conversationId, loadConvMsgsStatus);
          return loadConvMsgsStatus;
        });
      })
      .catch(() => {
        console.error(
          `loadMessagesSinceNewestMessage failed for ${conversationId} from Message ${newestMessageId}`
        );
      });
  };

  /** Load a single `Conversation` into `this.messageByConvStatusMap` only if the key (`conversationId`) is missing */
  @action
  loadConversationMessagesIfMissingGet = (
    conversationId: string,
    messagesGetRequest?: MessagesGetRequest
  ) => {
    if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
      console.debug(
        `loadConversationMessagesIfMissingGet for ${conversationId}`,
        messagesGetRequest
      );
    }
    if (!this.messageByConvStatusMap.has(conversationId)) {
      return this.loadConversationMessages(conversationId, messagesGetRequest);
    }
    this.setNewestMessageOwnedByUserId(conversationId);
    return this.messageByConvStatusMap.get(conversationId);
  };

  @action
  uploadFileToS3 = async (value: {
    filename: string;
    contentType: string;
    generatePreviewLink: boolean;
    previewContentType?: string;
  }) => {
    const s3RespFile = fromPromise(
      API.post(MSG_API_BASE_URI + 'fileHandle', value)
    );
    s3RespFile.then(
      (resp) => {
        if (!this.fileUploadedS3.has(resp.data.externalId)) {
          this.fileUploadedS3.set(value.filename, { ...resp.data });
        }
      },
      (reason) =>
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error creating file on s3'
        )
    );
    return s3RespFile;
  };

  uploadImportedFile = async (
    file: File,
    fileName: string,
    previewUrl?: boolean
  ) => {
    const fileUrl = this.fileUploadedS3.get(fileName);
    if (!isNullOrUndefined(fileUrl)) {
      const fileNameToHex = this.rootStore.uiStore
        .transFromStringToHex(file.name)
        .toUpperCase();
      const respFile = fromPromise(
        PURE_API.put(previewUrl ? fileUrl.previewUrl : fileUrl.url, file, {
          headers: {
            'Content-Type': file.type,
            'Content-Disposition': `attachment; filename="${fileNameToHex}"`,
          },
        })
      );
      respFile.then(
        () => {},
        (reason) =>
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error creating file on s3'
          )
      );
      return respFile;
    }
  };

  handleUploadToAWS = async (
    message: MessageStore,
    conversation: ConversationStore,
    ui: UiStore,
    files: File[]
  ) => {
    try {
      const allS3UploadedFiles = files.map((file) =>
        message.uploadFileToS3({
          filename: file.name,
          contentType: file.type,
          generatePreviewLink: true,
          previewContentType: file.type,
        })
      );
      ui.setFilesUploading(true);
      await Promise.all(allS3UploadedFiles);
      const updateAWSLinks = files.map(async (file) => {
        const isImage = imageFileTypes.includes(file.type);
        const extension = file.type.split('/')[1];
        if (isImage) {
          const resizedImage = file.type.includes('svg')
            ? file
            : await resizeImage(file, extension);
          await message.uploadImportedFile(
            resizedImage as File,
            file.name,
            true
          );
        }
        return message.uploadImportedFile(file, file.name);
      });
      await Promise.all(updateAWSLinks);
    } catch (error) {
      ui.setFilesUploadingError(true);
      this.rootStore.notificationStore.addAxiosErrorNotification(
        error,
        'Error uploading files to this conversation!'
      );
    } finally {
      ui.setFilesUploading(false);
    }
  };

  @action
  getFileDownloadLink = (fileId: string) => {
    const file = fromPromise<AxiosResponseT<any>>(
      API.get(API_ENDPOINTS.ConversationFile(fileId))
    );
    return file.then((resp) => resp);
  };
  createMessageDocuments = () => {
    const {
      rootStore: { uiStore },
      fileUploadedS3,
    } = this;
    if (fileUploadedS3.size > 0) {
      return Array.from(fileUploadedS3, ([name, value], index) => {
        return {
          ...value,
          previewHeight: uiStore.droppedFiles[index].height,
          previewWidth: uiStore.droppedFiles[index].width,
        } as IMessageDocument;
      });
    }
    return null;
  };

  @action
  loadMoreConversationMessages = async (
    conversationId: string,
    loadDirection: LOAD_DIRECTION,
    skipMessageId: string
  ) => {
    await this.rootStore.preferenceStore.getExistingPreferenceData();
    const msgsGet: MessagesGetRequest = {
      SkipId: skipMessageId,
      SkipOver: false,
      Limit: MESSAGE_LOAD_LIMIT,
      SortDirection:
        loadDirection !== LOAD_DIRECTION.Newer ? 'Descending' : 'Ascending',
      ShowCallMessagesInChat:
        this.rootStore.preferenceStore.preferences.showCallMessagesInChat,
    };
    const skipIdCacheVals = this.loadMoreMessagesSkipIdsMap.get(conversationId);

    // Don't load that which is already loaded, caching you will learn, young padawan.
    if (
      skipIdCacheVals !== undefined &&
      skipIdCacheVals.includes(skipMessageId)
    ) {
      return null;
    }
    if (skipIdCacheVals === undefined) {
      this.loadMoreMessagesSkipIdsMap.set(
        conversationId,
        observable.array([skipMessageId], { deep: false })
      );
    } else if (!skipIdCacheVals.includes(skipMessageId)) {
      skipIdCacheVals.push(skipMessageId);
    }
    if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
      console.debug(
        `loadMoreConversationMessages for ${conversationId}`,
        msgsGet,
        'skipIdCacheVals',
        toJS(skipIdCacheVals)
      );
    }
    const resp = await API.get(
      API_ENDPOINTS.ConversationMessages(conversationId, msgsGet)
    );
    return this.loadConversationMessagesSuccess(
      conversationId,
      loadDirection,
      resp
    );
  };

  @action
  loadConversationMessagesSuccess = async (
    conversationId: string,
    loadDirection: LOAD_DIRECTION,
    response: AxiosResponseT<ResultsCollection<IMessageModel>>
  ) => {
    if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
      console.debug(
        `loadConversationMessagesSuccess for ${conversationId}`,
        response.data
      );
    }
    const responseMsgs = response.data.results.map(
      MessageModel.FromResponseDto
    );
    if (loadDirection === LOAD_DIRECTION.Initial) {
      let grpMsgs: GroupedMessagesContainer;
      if (this.groupedMessagesByConversationMap.has(conversationId)) {
        grpMsgs = this.groupedMessagesByConversationMap.get(conversationId);
        responseMsgs.forEach((el) => {
          !!grpMsgs.findMessageById(el.id) && grpMsgs.deleteMessageById(el.id);
        });
        grpMsgs.pushNewerMessages(...responseMsgs);
      } else {
        console.warn(
          `GroupedMessages entry missing for Conversation ${conversationId} on initial load, creating it now...`
        );
        grpMsgs = new GroupedMessagesContainer(
          conversationId,
          responseMsgs,
          this.rootStore.participantStore.selectLoggedInUserParticipantLastReadMessageId
        );
        this.groupedMessagesByConversationMap.set(conversationId, grpMsgs);
      }
      this.setNewestMessageOwnedByUserId(conversationId);

      // Check if the logged-in user has a `readMessageId` for this Conversation
      try {
        await when(
          () =>
            this.rootStore.participantStore.lastReadByConvMap.has(
              conversationId
            ),
          { timeout: 10000 }
        );
        const readMessageId =
          this.rootStore.participantStore.lastReadByConvMap.get(conversationId);
        if (
          !isEmpty(readMessageId) &&
          !grpMsgs.selectContainsMessage(readMessageId) &&
          readMessageId !== EMPTY_TIMEUUID
        ) {
          if (!NODE_ENV_PRODUCTION) {
            console.debug(
              `MessageStore.loadConversationMessagesSuccess calling backfillMessages: (Conversation ${conversationId}) to readMessageId ${readMessageId}`,
              grpMsgs
            );
          }
          this.setIsBackfillingToReadMessage(conversationId, true);
          const conv =
            await this.rootStore.conversationStore.selectConversationById(
              conversationId
            );
          // Set the recursion limit to at least 3, but it can be higher if there are more Unread Messages past `MESSAGE_LOAD_LIMIT * 3`
          let backfillLimit = MESSAGE_BACKFILL_RECURSION_LIMIT;
          if (
            !isNullOrUndefined(conv) &&
            conv.data.unreadCount > MESSAGE_LOAD_LIMIT
          ) {
            backfillLimit = Math.max(
              Math.ceil(conv.data.unreadCount / MESSAGE_LOAD_LIMIT),
              backfillLimit
            );
          }
          return this.backfillMessages(
            conversationId,
            readMessageId,
            grpMsgs,
            backfillLimit
          ).then(
            (res) => {
              this.setIsBackfillingToReadMessage(conversationId, false);
              return res;
            },
            (err) => {
              bugsnagClient.notify(err, (event) => {
                event.context = 'MessageStore';
                event.severity = 'error';
                event.addMetadata('custom', {
                  function:
                    'loadConversationMessagesSuccess > backfillMessages',
                });
              });
              console.error(
                'loadConversationMessagesSuccess > backfillMessages error',
                err
              );
              return err;
            }
          );
        } else if (isEmpty(readMessageId) || readMessageId === EMPTY_TIMEUUID) {
          console.warn(
            `readMessageId was retrieved from the Map, but the value is Empty for Conversation ${conversationId}`
          );
        }
        return Promise.resolve(response);
      } catch (e) {
        if (!NODE_ENV_PRODUCTION) {
          console.debug(
            `loadConversationMessagesSuccess timed out before acquiring readMessageId for Conversation ${conversationId}. This is expected if the user had not previously read any messages in the Conversation.`
          );
        }
        return Promise.resolve(response);
      }
    } else {
      this.setIsBackfillingToReadMessage(conversationId, false); // Always false when not initial load
      let currGroupedMsgs =
        this.groupedMessagesByConversationMap.get(conversationId);
      // Fallback if not `LOAD_DIRECTION.Initial`, but there is no existing GroupedMessages instance (this should not happen, but will be recovered with a warning)
      if (currGroupedMsgs === undefined) {
        console.warn(
          `loadMoreConversationMessagesSuccess with loadDirection ${loadDirection} - this.groupedMessagesByConversationMap did not contain a GroupedMessages for Conversation ${conversationId}. One will be created, but this should not have happened.`
        );
        currGroupedMsgs = new GroupedMessagesContainer(
          conversationId,
          responseMsgs,
          this.rootStore.participantStore.selectLoggedInUserParticipantLastReadMessageId
        );
        this.groupedMessagesByConversationMap.set(
          conversationId,
          currGroupedMsgs
        );
        return Promise.resolve(response);
      }
      // `SortBy = Descending` (these start as newer -> older, and will be reversed by `GroupedMessages`, so they will go older -> newer)
      if (loadDirection === LOAD_DIRECTION.Older) {
        currGroupedMsgs.unshiftOlderMessages(...responseMsgs);
      } else {
        currGroupedMsgs.pushNewerMessages(...responseMsgs);
      }

      return Promise.resolve(response);
    }
  };

  @action
  backfillMessagesForAllConversations = async () => {
    if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
      console.debug(`backfillMessagesForAllConversations`);
    }
    await this.rootStore.personStore.waitUntilLoggedIn();
    const convIds = this.rootStore.conversationStore.conversationByIdMap.keys();
    const backfillPromises: Array<
      Promise<AxiosResponseT<ResultsCollection<IMessageModel>> | void>
    > = [];
    for (const c of Array.from(convIds)) {
      const lastReadMessageId =
        this.rootStore.participantStore.selectLoggedInUserParticipantLastReadMessageId(
          c
        );
      const grpMsgs = this.groupedMessagesByConversationMap.get(c);
      if (grpMsgs) {
        if (
          lastReadMessageId !== EMPTY_TIMEUUID &&
          !isEmpty(lastReadMessageId)
        ) {
          backfillPromises.push(
            this.backfillMessages(
              c,
              lastReadMessageId,
              grpMsgs,
              MESSAGE_BACKFILL_RECURSION_LIMIT
            )
          );
        }
      }
    }
    return Promise.all(backfillPromises);
  };

  /**
   * Load more Messages, attempting to recursively load until `readMessageId`.
   *
   * This method will return a `Promise` which is:
   * - Resolved with the last `AxiosResponseT<ResultsCollection<IMessageModel>>` response received if backfill was successful and not short-circuited
   * - Resolved with `false` if backfill was short-circuited due to `remainingBatchRequests` being 0
   * - Resolved with `null` if the `readMessageId` was already used as a `SkipId` for a previous request (to prevent duplicate requests)
   * - Rejected with the string "backfillMessages: groupedMessages was null or undefined!"
   * - Rejected with an error from `axios` if the request fails
   *
   * @private
   */
  @action
  private backfillMessages = (
    conversationId: string,
    readMessageId: string,
    groupedMessages: GroupedMessagesContainer,
    remainingBatchRequests: number
  ) => {
    const {
      rootStore: { personStore, preferenceStore, notificationStore },
    } = this;
    if (isNullOrUndefined(groupedMessages)) {
      return Promise.reject(
        'backfillMessages: groupedMessages was null or undefined!'
      );
    }
    if (remainingBatchRequests < 1) {
      console.debug(
        `backfillMessages short-circuited because remainingBatchRequests is 0.`
      );
      return Promise.resolve(false);
    }
    if (!NODE_ENV_PRODUCTION) {
      console.debug(
        `MessageStore.backfillMessages: (Conversation ${conversationId}) to readMessageId ${readMessageId}, remainingBatchRequests: ${remainingBatchRequests}`
      );
      if (!isNullOrUndefined(groupedMessages.OldestMessage)) {
        console.debug(
          `Current oldest Message: ${
            groupedMessages.OldestMessage.id
          } @ ${groupedMessages.OldestMessage.CreatedMoment.toISOString()}`
        );
      }
    }
    const skipIdCacheVals = this.loadMoreMessagesSkipIdsMap.get(conversationId);
    // Don't load that which is already loaded, caching you will learn, young padawan.
    if (
      skipIdCacheVals !== undefined &&
      skipIdCacheVals.includes(groupedMessages.OldestMessageId)
    ) {
      console.debug(
        `backfillMessages: Returning Promise.resolve(null) because skipIdCacheVals included ${groupedMessages.OldestMessageId} (Conversation Id ${conversationId})`
      );
      return Promise.resolve(null);
    }
    if (skipIdCacheVals === undefined) {
      this.loadMoreMessagesSkipIdsMap.set(
        conversationId,
        observable.array([groupedMessages.OldestMessageId], { deep: false })
      );
    } else if (!skipIdCacheVals.includes(groupedMessages.OldestMessageId)) {
      skipIdCacheVals.push(groupedMessages.OldestMessageId);
    }
    const msgsGet: MessagesGetRequest = {
      SkipId: groupedMessages.OldestMessageId,
      SkipOver: false,
      Limit: MESSAGE_LOAD_LIMIT,
      SortDirection: 'Descending',
      ShowCallMessagesInChat:
        preferenceStore.preferences.showCallMessagesInChat,
    };
    return preferenceStore.getExistingPreferenceData().then((resp) => {
      msgsGet.ShowCallMessagesInChat = resp.showCallMessagesInChat;
      return personStore.waitUntilLoggedIn().then(() => {
        return API.get(
          API_ENDPOINTS.ConversationMessages(conversationId, msgsGet)
        ).then(
          (resp: AxiosResponseT<ResultsCollection<IMessageModel>>) => {
            if (!isEmpty(resp.data.results)) {
              if (!NODE_ENV_PRODUCTION) {
                console.debug(
                  `MessageStore.backfillMessages: (Conversation ${conversationId}) resp.data.results is populated, continuing to backfillMessagesSuccess.`,
                  resp.data.results,
                  msgsGet
                );
              }
              return this.backfillMessagesSuccess(
                conversationId,
                readMessageId,
                groupedMessages,
                resp,
                remainingBatchRequests
              );
            }
            return resp;
          },
          (reason) => {
            notificationStore.addAxiosErrorNotification(
              reason,
              'Error backfilling Conversation Messages'
            );
            return reason;
          }
        );
      });
    });
  };

  @action
  private backfillMessagesSuccess = (
    conversationId: string,
    readMessageId: string,
    groupedMessages: GroupedMessagesContainer,
    response: AxiosResponseT<ResultsCollection<IMessageModel>>,
    remainingBatchRequests: number
  ) => {
    const responseMsgs = response.data.results.map(
      MessageModel.FromResponseDto
    );
    groupedMessages.unshiftOlderMessages(...responseMsgs);
    const readMessageIncluded = responseMsgs.some(
      (rm) => rm.id === readMessageId
    );
    if (!NODE_ENV_PRODUCTION) {
      console.debug(
        `backfillMessagesSuccess: readMessageIncluded ${readMessageIncluded} (Conversation ${conversationId})`
      );
    }
    // Continue if the Message still isn't present
    if (!readMessageIncluded && groupedMessages !== null) {
      if (!NODE_ENV_PRODUCTION) {
        console.debug(
          `backfillMessagesSuccess: Continuing because readMessageId ${readMessageId} was not found (Conversation ${conversationId})`
        );
      }
      // Recursion, decrement `remainingBatchRequests` to move towards short-circuit if necessary
      return this.backfillMessages(
        conversationId,
        readMessageId,
        groupedMessages,
        remainingBatchRequests - 1
      );
    } else if (!readMessageIncluded && isEmpty(response.data.nextId)) {
      console.info(
        `backfillMessagesSuccess: Short-circuiting backfill because Message ${readMessageId} was not included in the response, and there is no nextId.`
      );
    }
    return response;
  };

  /**
   * Add a (Chat or SMS) `Message` to a `Conversation`.
   *
   * Phone calls are separately handled via SIP.
   *
   * @param conversationId ...
   * @param [chat] ...
   * @param sms ...
   * @param conversationType ...
   * @param documents ...
   * @returns {IPromiseBasedObservable<AxiosResponseT<ResultsCollection<ParticipantModel>>>}
   */
  @action
  createMessagePost = (
    conversationId: string,
    chat?: IMessageModelChat,
    sms?: IMessageModelSMS,
    conversationType?: string,
    documents?: IMessageDocument[]
  ) => {
    // if any of these condition is not match
    if (
      (!isEmpty(chat) && !isEmpty(sms)) ||
      (isEmpty(chat) && isEmpty(sms)) ||
      (!isEmpty(chat) && isEmpty(chat.text)) ||
      (!isEmpty(sms) && isEmpty(sms.text))
    ) {
      if (isEmpty(documents)) {
        return;
      }
    }
    // if the message is coming in as chat or sms , this message is coming from MessageInputArea
    const msg = new MessageModel(
      null,
      new Date().toISOString(),
      null,
      this.rootStore.personStore.loggedInPersonId,
      null,
      chat,
      documents,
      sms
    );
    const existingMsgGroup =
      this.groupedMessagesByConversationMap.get(conversationId);
    if (documents) {
      this.rootStore.uiStore.setSendMessagePending(true);
    }
    this.createMessagePostStatus = fromPromise(
      API.post(API_ENDPOINTS.ConversationMessages(conversationId), {
        chat,
        documents,
        sms,
      })
    );
    this.createMessagePostStatus.then(
      (createdMsg) => {
        if (this.rootStore.uiStore.selectedTopBarUsers)
          this.rootStore.uiStore.setSelectedTopBarUsers(null);
        if (existingMsgGroup !== undefined) {
          const existingMsg = existingMsgGroup.findMessageById(msg?.id || '');
          !existingMsg && existingMsgGroup.pushMessageDirect(msg);
        } else {
          runInAction(() => {
            this.groupedMessagesByConversationMap.set(
              conversationId,
              new GroupedMessagesContainer(
                conversationId,
                [msg],
                this.rootStore.participantStore.selectLoggedInUserParticipantLastReadMessageId
              )
            );
          });
        }
        msg.setId(createdMsg.data.id);
        msg.setCreated(createdMsg.data.created);
        if (
          createdMsg.data &&
          createdMsg.data.chat &&
          createdMsg.data.chat.smsRelay
        ) {
          msg.setSMSRelay(createdMsg.data.chat.smsRelay);
        }
        if (createdMsg.data.documents) {
          msg.setDocuments(createdMsg.data.documents);
        }
        this.setNewestMessageOwnedByUserId(conversationId);
        const convPbo =
          this.rootStore.conversationStore.selectConversationById(
            conversationId
          );
        convPbo?.then((conv) => {
          conv.data.setLastMessageDate(createdMsg.data.created);
          conv.data.setLastMessageId(createdMsg.data.id);
        });
        this.rootStore.participantStore
          .updateMyLastReadMessage(conversationId, createdMsg.data.id)
          .then(() => {
            // Update the `readMessageId` with the newly assigned `id`
            this.rootStore.uiStore.setConversationAndTotalUnreadCount(
              conversationId,
              0,
              convPbo,
              0
            );
          });
        this.rootStore.uiStore.setDroppedFiles([]);
        this.fileUploadedS3.clear();
        this.rootStore.uiStore.setSendMessagePending(false);
      },
      (reason) => {
        this.rootStore.uiStore.setSendMessagePending(false);
        const code = reason?.response?.data?.code;
        if (['InboundSmsNotEnabled', 'NewSmsServiceError'].includes(code)) {
          this.rootStore.uiStore.setChatErrorSend(conversationId);
          return;
        }
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error sending Message, please try again',
          false
        );
      }
    );
    return this.createMessagePostStatus;
  };

  @action
  insertNewLocalOrPushMessage = (
    conversationId: string,
    message: MessageModel
  ) => {
    const {
      preferenceStore: { preferences },
    } = this.rootStore;
    if (isEmpty(message.created)) {
      message.created = getISOStringFromTimeUUID(message.id);
    }
    if (this.groupedMessagesByConversationMap.has(conversationId)) {
      const existingMsgGroup =
        this.groupedMessagesByConversationMap.get(conversationId);
      const existingMsg = existingMsgGroup.findMessageById(message?.id || '');
      !existingMsg &&
        (preferences.showCallMessagesInChat || !message.call) &&
        existingMsgGroup.pushMessageDirect(message);
      return fromPromise(Promise.resolve(null));
    } else {
      // Just load the messages, if they weren't loaded before, the new one should be there already (we know the user didn't send the message, because the messages for the Conversation weren't loaded)
      return this.loadConversationMessagesIfMissingGet(conversationId, {
        Limit: MESSAGE_LOAD_LIMIT,
        SortDirection: 'Descending',
        ShowCallMessagesInChat: preferences.showCallMessagesInChat,
      });
    }
  };
  @action
  editLocalMessage = (
    conversationId: string,
    message: MessageModel,
    ancestorId: string = '0'
  ) => {
    if (this.groupedMessagesByConversationMap.has(conversationId)) {
      const existingMsgGroup =
        this.groupedMessagesByConversationMap.get(conversationId);
      const existingMsg = existingMsgGroup.findMessageById(message.id);
      const ancestorMsg = existingMsgGroup.findMessageById(ancestorId);
      if (existingMsg) {
        existingMsg.setChat(message.chat);
        existingMsg.setUpdated(moment.tz(TIMEZONE_IDENTIFIER).toISOString());
        pushToGTMDataLayer('editMessage', {
          conversationId,
        });
      }
      if (ancestorMsg) {
        ancestorMsg.setConference(message.conference);
      }
    } else {
      // Just load the messages, if they weren't loaded before, the new one should be there already (we know the user didn't send the message, because the messages for the Conversation weren't loaded)
      return this.loadConversationMessagesIfMissingGet(conversationId, {
        Limit: MESSAGE_LOAD_LIMIT,
        SortDirection: 'Descending',
        ShowCallMessagesInChat:
          this.rootStore.preferenceStore.preferences.showCallMessagesInChat,
      });
    }
  };
  @action
  deleteLocalMessage = (conversationId: string, messageId: string) => {
    if (this.groupedMessagesByConversationMap.has(conversationId)) {
      const existingMsgGroup =
        this.groupedMessagesByConversationMap.get(conversationId);
      const existingMsg = existingMsgGroup.findMessageById(messageId);
      if (existingMsg) {
        existingMsg.setChat({ text: '(Message Deleted)' });
        existingMsg.setDeleted(true);
        pushToGTMDataLayer('deleteMessage', {
          conversationId,
        });
      }
    } else {
      // Just load the messages, if they weren't loaded before, the new one should be there already (we know the user didn't send the message, because the messages for the Conversation weren't loaded)
      return this.loadConversationMessagesIfMissingGet(conversationId, {
        Limit: MESSAGE_LOAD_LIMIT,
        SortDirection: 'Descending',
        ShowCallMessagesInChat:
          this.rootStore.preferenceStore.preferences.showCallMessagesInChat,
      });
    }
  };

  /**
   * Remove the `Message`s and any other data related to a `Conversation` from the store.
   *
   * Intended to handle `ConversationLeave` push notifications, should be called by `ConversationStore.removeLocalConversationData`
   */
  @action
  removeLocalConversationData = (conversationId: string) => {
    this.messageByConvStatusMap.delete(conversationId);
    this.groupedMessagesByConversationMap.delete(conversationId);
    this.loadMoreMessagesSkipIdsMap.delete(conversationId);
  };
}

export default MessageStore;
