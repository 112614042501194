import styled, { css } from 'styled-components';
import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import type { HeadingProps } from './types';

const HEADING_VARIANTS: {
  [prop in HeadingProps['variant']]: FlattenInterpolation<
    ThemeProps<DefaultTheme>
  >;
} = {
  h1: css`
    font: normal normal 300 30px/35px Open Sans;
  `,
  h2: css`
    font: normal normal 300 24px/29px Open Sans;
  `,
  h3: css`
    font: normal normal 300 20px/25px Open Sans;
  `,
  h4: css`
    font: normal normal 300 18px/23px Open Sans;
  `,
  h5: css`
    font: normal normal 300 16px/21px Open Sans;
  `,
  h6: css`
    font: normal normal bold 14px/19px Open Sans;
  `,
};

const Heading = styled.p<{
  $variant: HeadingProps['variant'];
  $noMargin: boolean;
  $ellipsis: boolean;
}>`
  margin: 0 0 10px 0;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.text};
  ${({ $variant }) => $variant && HEADING_VARIANTS[$variant]}
  ${({ $noMargin }) => $noMargin && 'margin: 0;'}
  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

export const Styled = {
  Heading,
};
