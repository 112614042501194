import cx from 'classnames';
import AtMentionMembersObserver from 'Components/AtMentionMembers';
import ContentEditable from 'Components/ContentEditable';
import { MENTION_PREFIX } from 'Constants/enums';
import { SHOW_FILE_UPLOAD } from 'Constants/env';
import {
  STORE_CONVERSATION,
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PUSHER,
  STORE_ROUTER,
  STORE_UI,
} from 'Constants/stores';
import { Picker as EmojiPicker } from 'emoji-mart';
import { get, isEmpty, uniqBy } from 'lodash';
import { action, observable, when, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FileTypeModel } from 'Models/FileType';
import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import { isNullOrUndefined, isNumber } from 'util';
import { pushToGTMDataLayer } from 'Utils/analytics';
import {
  findMentionInsertData,
  focusContentEditable,
  getSelectionRange,
  insertEmoji,
  placeCursorAtEndOfElement,
  placeCursorAtSpecificPosition,
} from 'Utils/inputNodeUtils';
import { keyIsAlphanumeric, keyIsValidFilterInput } from 'Utils/keyboardInput';
import {
  handleAlphanumericWhenMentionListOpen,
  handleAtSymbol,
  handleBackspaceDeleteWhenMentionListOpen,
  handleEnterKey,
  handleEscapeKey,
  handleTabRightArrowSpacebarWhenMentionListOpen,
  handleUpArrowEditOnEmptyInput,
  handleUpDownLeftArrowsWhenMentionListOpen,
} from 'Utils/mentionKeyHandlers';
import { resolveConversationPath } from 'Utils/routeNav';
import { turndownSvc } from 'Utils/turndownSvc';
import { Icon } from '../../components/shared/Icon';
import UploadedFiles from '../../components/UploadedFiles';

import withRouter from '../../hocs/WithRouter';
import { Styled } from './index.styles';
import { MessageInputProps } from './interfaces';

export class MessageInputArea extends React.Component<MessageInputProps> {
  @observable
  controlPressed: boolean = false;

  @action
  setControlPressed = (state: boolean) => (this.controlPressed = state);
  container = React.createRef<HTMLDivElement>();

  @observable
  extraRows: number = 0;

  @observable
  canReceiveSMS: boolean = true;

  @action setExtraRows = (extraRows: number) => (this.extraRows = extraRows);

  @action setCanReceiveSMS = (canReceiveSMS: boolean) =>
    (this.canReceiveSMS = canReceiveSMS);

  @observable
  userSMSErrorMessage: boolean = false;

  @action setUserSMSErrorMessage = (userSMSErrorMessage: boolean) =>
    (this.userSMSErrorMessage = userSMSErrorMessage);

  @observable
  isHover: boolean = false;

  @observable
  isMouseOverEmojiFlag: boolean = false;

  @observable
  isDeadkeyPressed: boolean = false;

  @observable
  previousKeyWasDeadKey: boolean = false;

  @action
  toggleIsMouseOverEmojiFlag = (isMouseOverEmojiFlag: boolean) =>
    (this.isMouseOverEmojiFlag = isMouseOverEmojiFlag);

  @action
  setOnMouseOverEmoji = () => this.toggleIsMouseOverEmojiFlag(true);

  @action
  setOnMouseLeaveEmoji = () => this.toggleIsMouseOverEmojiFlag(false);

  @action setIsHover = (isHover: boolean) => (this.isHover = isHover);

  resolveConversationLinkPath = (path: string) => {
    return resolveConversationPath(this.props.location.pathname, path);
  };

  isConversationWithPhone = () => {
    const { conversation } = this.props;
    const isChatWithNumber =
      conversation.CurrentConversation?.participants.filter(
        (participant) => participant?.phone && participant.personId === 0
      );
    return !isEmpty(isChatWithNumber);
  };

  sendingMessageAllowed = () => {
    const {
      person: { mmsEnabled },
      ui: { filesUploading, droppedFiles },
    } = this.props;
    return (
      !filesUploading &&
      !this.props.ui.filesUploadingError &&
      ((this.isConversationWithPhone() &&
        (mmsEnabled || (!mmsEnabled && droppedFiles.length === 0))) ||
        !this.isConversationWithPhone())
    );
  };
  handleClick = (e) => {
    // If user is not allowed to send the message, do nothing
    if (!this.sendingMessageAllowed()) return;

    // If online, send the message and reset the input
    if (this.sendingMessageAllowed()) {
      const {
        pusher,
        message,
        participant,
        params: { conversationId = '' },
      } = this.props;
      handleEnterKey(
        e,
        pusher.isOnline,
        conversationId,
        message.createMessageMentionListOpen,
        message.createMessageMentionSelectedParticipantId,
        this.insertMentionAtCursor,
        participant.selectOtherParticipantPersons,
        this.sendMessage,
        message.setMessageDraftRaw,
        message.setMessageDraftHtml
      );
      focusContentEditable(this.ceRef);
    }
  };

  handleAdHocGroup = (sendAsSMS: boolean) => {
    const {
      message,
      conversation,
      params: { conversationId = '' },
    } = this.props;
    const participantIds: string[] = conversation.adHocGroupParticipants.map(
      (participant) => participant.id
    );
    const rawMessageContent: string =
      message.getMessageDraftRaw(conversationId);
    conversation.createConversationFromAdHoc(
      participantIds,
      rawMessageContent,
      sendAsSMS
    );
  };

  isSMSConversation = () => {
    const {
      participant,
      params: { conversationId = '' },
    } = this.props;
    const otherParticipants =
      participant.selectOtherParticipants(conversationId);
    return otherParticipants.some((participant) => participant.phone);
  };

  sendMessage = () => {
    const {
      message,
      participant,
      person,
      conversation,
      ui: { droppedFiles },
      params: { conversationId = '' },
    } = this.props;

    let sendAsSMS =
      participant.conversationMessagesSMS.get(conversationId) || false;
    const otherParticipants =
      participant.selectOtherParticipants(conversationId);
    const rawMessageContent = message.getMessageDraftRaw(conversationId);
    if (rawMessageContent?.length > 0 || droppedFiles) {
      // This is an extra check for sending SMS messages, if the other user is not pro
      if (otherParticipants?.length === 1) {
        // Check to see if the other participant is a standard or a pro user.
        // otherPerson is null if it's just a phone number
        const otherPersonId =
          parseInt(otherParticipants[0].id) || otherParticipants[0].personId;
        const otherPerson = otherPersonId
          ? person.selectPersonValueById(otherPersonId)
          : null;
        if (otherPerson !== null) {
          if (!otherPerson.data.SupportsChat) {
            sendAsSMS = true;
          }
        } else {
          sendAsSMS = true;
        }
      }

      // Now send the message as either SMS or Regular
      window.location.pathname.includes('adHocGroup')
        ? this.handleAdHocGroup(sendAsSMS)
        : message.createMessagePost(
            conversationId,
            sendAsSMS
              ? null
              : isEmpty(rawMessageContent)
              ? null
              : { text: rawMessageContent },
            sendAsSMS ? { text: rawMessageContent } : null,
            conversation.CurrentConversation.grouping,
            message.createMessageDocuments()
          );

      if (
        message
          .getMessageDraftHtml(conversationId)
          .includes('class="input-mention"')
      ) {
        pushToGTMDataLayer('mention', {
          conversationId,
        });
      }
      const objDiv = document.querySelector('.context-content-items-list');
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
      message.setMessageDraftRaw(conversationId, '');
    }
  };

  onKeyUp = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Control') {
      this.setControlPressed(false);
    }
    if (e.key === 'Dead') {
      this.isDeadkeyPressed = false;
    }
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {
      conversation,
      message,
      participant,
      pusher,
      ui,
      params: { conversationId = '' },
    } = this.props;
    if (e.key === 'Control') {
      this.setControlPressed(!this.controlPressed);
    } else if (e.key !== 'Enter') {
      this.setControlPressed(false);
    }

    if (this.previousKeyWasDeadKey && !this.isDeadkeyPressed) {
      this.previousKeyWasDeadKey = false;
      return;
    }

    if (e.key === 'Dead') {
      this.isDeadkeyPressed = true;
      this.previousKeyWasDeadKey = true;
    }

    // Enter
    if (e.key === 'Enter') {
      if (this.controlPressed) {
        const value = this.ceRef.lastHtml;
        this.updateMessage(value + '\n\r');
        return;
      }
      if (ui.filesUploading || ui.filesUploadingError) {
        return;
      }
      handleEnterKey(
        e,
        pusher.isOnline,
        conversationId,
        message.createMessageMentionListOpen,
        message.createMessageMentionSelectedParticipantId,
        this.insertMentionAtCursor,
        participant.selectOtherParticipantPersons,
        this.sendMessage,
        message.setMessageDraftRaw,
        message.setMessageDraftHtml
      );
    }

    if (e.key === 'Escape') {
      handleEscapeKey(e, ui.setEmojiPickerState);
    }

    // Up arrow without shift key, while the new message input is empty, and the mention list is not open
    if (
      e.key === 'ArrowUp' &&
      !e.shiftKey &&
      ((conversation.CurrentConversation.grouping !== 'Channel' &&
        conversation.CurrentConversation.grouping !== 'Group') ||
        !message.createMessageMentionListOpen) &&
      isEmpty(message.getMessageDraftRaw(conversationId))
    ) {
      handleUpArrowEditOnEmptyInput(
        e,
        conversation.CurrentConversation,
        participant.selectOtherParticipantPersons,
        message.newestMessageOwnedByUserId,
        message.setEditMessageDraftRaw,
        message.setEditMessageDraftHtml,
        message.isEditingMessageId,
        message.setIsEditingMessageId
      );
    }

    // @ symbol (shift + 2), pop up the mentions list
    if (
      (conversation.CurrentConversation.grouping === 'Channel' ||
        conversation.CurrentConversation.grouping === 'Group') &&
      e.key === '@' &&
      !this.isSMSConversation()
    ) {
      handleAtSymbol(
        e,
        this.ceRef.selectionRange,
        message.getMessageDraftHtml(conversationId),
        message.createMessageMentionListOpen,
        message.setCreateMessageMentionListOpen,
        message.setCreateMessageMentionSelectedParticipantId,
        ui.setEmojiPickerState
      );
    }

    // Handle Up/Down arrows, Backspace, and Delete when the Mention list is open
    if (
      (conversation.CurrentConversation.grouping === 'Channel' ||
        conversation.CurrentConversation.grouping === 'Group') &&
      message.createMessageMentionListOpen
    ) {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft'].includes(e.key)) {
        handleUpDownLeftArrowsWhenMentionListOpen(
          e,
          participant.selectFilteredOtherParticipantPersonsInCurrentConversation,
          message.createMessageMentionSelectedParticipantId,
          message.setCreateMessageMentionSelectedParticipantId,
          message.setCreateMessageMentionListOpen,
          message.createMessageMentionFilter
        );
      } else if (['ArrowRight', 'Tab', ' '].includes(e.key)) {
        handleTabRightArrowSpacebarWhenMentionListOpen(
          e,
          message.createMessageMentionSelectedParticipantId,
          participant.selectOtherParticipantPersons,
          conversationId,
          this.insertMentionAtCursor
        );
      } else if (['Backspace', 'Delete'].includes(e.key)) {
        handleBackspaceDeleteWhenMentionListOpen(
          e,
          this.ceRef.selectionRange,
          message.getMessageDraftHtml(conversationId),
          message.createMessageMentionListOpen,
          message.setCreateMessageMentionListOpen,
          message.createMessageMentionFilter,
          message.setCreateMessageMentionFilter,
          participant.selectFilteredOtherParticipantPersonsInCurrentConversation,
          message.setCreateMessageMentionSelectedParticipantId
        );
      } else if (keyIsAlphanumeric(e)) {
        handleAlphanumericWhenMentionListOpen(
          e,
          message.createMessageMentionListOpen,
          message.createMessageMentionFilter,
          message.setCreateMessageMentionFilter,
          participant.selectFilteredOtherParticipantPersonsInCurrentConversation,
          message.setCreateMessageMentionSelectedParticipantId
        );
      }
      // Any invalid inputs should close the Mention list to avoid auto-complete desync
      else if (!keyIsValidFilterInput(e)) {
        message.setCreateMessageMentionListOpen(false);
      }
    }
  };

  private getSMS = () => {
    const {
      participant,
      person,
      conversation,
      params: { conversationId = '' },
    } = this.props;
    when(
      () =>
        !isEmpty(participant.selectLoggedInUserParticipant(conversationId)) &&
        !isEmpty(conversation.CurrentConversation),
      { timeout: 10000 }
    ).then(() => {
      if (
        this.isConversationWithPhone() &&
        conversation.CurrentConversation &&
        conversation.CurrentConversation.grouping === 'OneOnOne'
      ) {
        const otherParticipants =
          participant.selectOtherParticipants(conversationId);
        // SMS messages can only be sent to OneOnOne Chats that have number Or they are Pro Users
        // Check to see if the other participant is a standard or a pro user.
        // otherPerson is null if it's just a phone number
        if (otherParticipants?.length > 0) {
          if (!otherParticipants[0].personId) {
            this.setErrIfUserCantReceiveSMS(person);
            return;
          }
          const otherPerson = person.selectPersonValueById(
            otherParticipants[0].personId || parseInt(otherParticipants[0].id)
          );
          if (otherPerson !== null) {
            if (!otherPerson.data.SupportsChat) {
              this.setCanReceiveSMS(false);
              this.setUserSMSErrorMessage(false);
            }
          } else {
            this.setErrIfUserCantReceiveSMS(person);
          }
        }
      }
    });
  };

  private setErrIfUserCantReceiveSMS = (person) => {
    const loggedInUser = person.selectPersonValueById(person.loggedInPersonId);
    // Check for when messaging a non-participant (mobile number)
    // Check if logged in user has SMS capability/direct number
    if (!loggedInUser.data.canReceiveSMS) {
      this.setCanReceiveSMS(false);
      this.setUserSMSErrorMessage(true);
    }
  };

  private onHover = () => {
    if (!this.canReceiveSMS) {
      this.setIsHover(true);
    }
  };
  private offHover = () => {
    this.setIsHover(false);
  };

  constructor(props: MessageInputProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const {
      params: { conversationId = '' },
    } = this.props;

    if (
      conversationId === '0' ||
      window.location.pathname === '/chat/adHocGroup'
    ) {
      return;
    }
    this.getSMS();
  }
  componentDidUpdate(prevProps: MessageInputProps) {
    const {
      ui,
      params: { conversationId = '' },
    } = this.props;
    if (
      conversationId === '0' ||
      window.location.pathname === '/chat/adHocGroup'
    ) {
      return;
    }
    const previousConversationId = get(prevProps, 'params.conversationId');
    if (conversationId !== previousConversationId) {
      this.setCanReceiveSMS(true);
      this.getSMS();
    }
    if (isNullOrUndefined(ui.cursorPosition)) {
      placeCursorAtEndOfElement(this.ceRef.htmlEl);
      ui.setCursorPosition(this.ceRef.htmlEl.innerText.length);
    }
  }

  toggleShowEmojis = (e) => {
    const { message, ui } = this.props;
    if (!ui.getEmojiPickerState().open) {
      ui.setEmojiPickerState({ open: true, editing: false });
      message.setCreateMessageMentionListOpen(false);
      this.toggleIsMouseOverEmojiFlag(true);
      if (this.ceRef && this.ceRef.htmlEl) {
        const ceRefSelection = getSelectionRange(this.ceRef.htmlEl);
        // Focus and move to end if there is no selection (unfocused) in the input
        if (ceRefSelection.start === 0 && ceRefSelection.end === 0) {
          focusContentEditable(this.ceRef);
          placeCursorAtEndOfElement(this.ceRef.htmlEl);
        }
      }
    } else {
      ui.setEmojiPickerState({ open: false, editing: false });
    }
    e.preventDefault();
    e.stopPropagation();
  };

  toggleEmojiPickerOnWrapperKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const { ui } = this.props;
    if (e.key === 'Escape') {
      handleEscapeKey(e, ui.setEmojiPickerState);
    }
  };

  insertEmojiWithRef = (emoji) => {
    insertEmoji(emoji, this.ceRef);
  };

  updateMessage = (inputValue: string) => {
    const {
      message,
      params: { conversationId = '' },
    } = this.props;

    if (!isEmpty(inputValue)) {
      inputValue = inputValue.replace(/(<p>|<br>)/gi, '\n');
      inputValue = inputValue.replace(/<\/p>/gi, '');
      inputValue = inputValue.replace(/&amp;/gi, '&');
    }

    let rawResultValue = inputValue.trim();
    if (inputValue.includes('class="input-mention"')) {
      rawResultValue = turndownSvc.turndown(inputValue.replace(/\n/gi, '<br>'));
    }
    rawResultValue = rawResultValue.replace(/&lt;/gi, '<');
    rawResultValue = rawResultValue.replace(/&gt;/gi, '>');

    if (!this.isDeadkeyPressed) {
      message.setMessageDraftRaw(conversationId, rawResultValue);
      message.setMessageDraftHtml(
        conversationId,
        !isEmpty(rawResultValue) ? inputValue : ''
      );
    }
  };

  onCeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.toggleIsMouseOverEmojiFlag(false);
    this.updateMessage(e.target.value);
  };

  ceRef: ContentEditable;
  setCeRef = (ce: ContentEditable) => (this.ceRef = ce);

  handlePropagation = (e) => e.stopPropagation();
  insertMentionAtCursor = (prefix: MENTION_PREFIX, value: string) => {
    const {
      message,
      participant,
      params: { conversationId = '' },
    } = this.props;
    let messageDraftRaw = message.getMessageDraftRaw(conversationId);
    let messageDraftHtml = message.getMessageDraftHtml(conversationId);
    const { start, end, htmlStart, htmlEnd } = this.ceRef.selectionRange;

    const rawInsertData = findMentionInsertData(messageDraftRaw, start);
    messageDraftRaw =
      messageDraftRaw.substring(0, rawInsertData.insertIndex) +
      `${rawInsertData.prependWith}${prefix}${value} ` +
      messageDraftRaw.substring(end);
    message.setMessageDraftRaw(conversationId, messageDraftRaw);

    if (prefix === '@pr') {
      const personId = parseInt(value, 10);
      if (isNumber(personId)) {
        const participantPersons =
          participant.selectParticipantPersons(conversationId); // Can't be null at this point (render function doesn't include Mentions unless it is not null)
        const mentionedPerson = participantPersons.find(
          (pp) => pp.person.id === personId
        );
        const htmlInsertData = findMentionInsertData(
          messageDraftHtml,
          htmlStart
        );
        messageDraftHtml =
          messageDraftHtml.substring(0, htmlInsertData.insertIndex) +
          `${htmlInsertData.prependWith}<div class="input-mention" mention-target="${prefix}${value} " contenteditable="false">@${mentionedPerson.person.firstName} ${mentionedPerson.person.lastName}</div> ` +
          messageDraftHtml.substring(htmlEnd);
        message.setMessageDraftHtml(conversationId, messageDraftHtml);
        message.setCreateMessageMentionListOpen(false);
      }
    } else {
      const htmlInsertData = findMentionInsertData(messageDraftHtml, htmlStart);
      messageDraftHtml =
        messageDraftHtml.substring(0, htmlInsertData.insertIndex) +
        `${htmlInsertData.prependWith}<div class="input-mention" mention-target="${prefix}${value} " contenteditable="false">${prefix}${value}</div> ` +
        messageDraftHtml.substring(htmlEnd);
      message.setMessageDraftHtml(conversationId, messageDraftHtml);
      message.setCreateMessageMentionListOpen(false);
    }
    const startPosition =
      this.ceRef.selectionRange.start + value.length + prefix.length + 1;
    this.ceRef.selectionRange = {
      ...this.ceRef.selectionRange,
      start: startPosition,
      end: startPosition,
    };
  };
  fileImportEvent = async (e: any): Promise<void> => {
    const {
      message,
      conversation,
      ui,
      ui: { setDroppedFiles, droppedFiles },
    } = this.props;
    const files = e.clipboardData?.files || e.target?.files || [];
    if (!files?.length) {
      return;
    }
    const importedFiles: File[] = Array.from(files);
    const mappedFiles: FileTypeModel[] = importedFiles.map(
      (file) => new FileTypeModel(file)
    );
    setDroppedFiles(uniqBy([...droppedFiles, ...mappedFiles], 'name'));
    if (e.target?.id === 'embedpollfileinput') {
      e.target.value = '';
    }
    await message.handleUploadToAWS(message, conversation, ui, importedFiles);
    document.getElementById('context-panel').click();
  };

  onFocusLost = (e) => {
    const { ui, message } = this.props;
    if (!message.isEditingMessageId)
      ui.setCursorPosition(this.ceRef.selectionRange.start);
  };

  onFocus = (e) => {
    const { ui } = this.props;
    placeCursorAtSpecificPosition(this.ceRef.htmlEl, ui.cursorPosition);
  };

  render() {
    const {
      participant,
      pusher,
      person,
      message,
      ui,
      conversation,
      params: { conversationId = '' },
    } = this.props;

    let mentionsBox = null;
    const emojiPickerOpenState =
      ui.getEmojiPickerState().open && !ui.getEmojiPickerState().editing;
    const isChatWithNumber =
      conversation.CurrentConversation?.participants.filter(
        (participant) => participant?.phone && participant.personId === 0
      );
    const showSendError =
      ui.userSmsCantSendError.get(conversationId) === 'false';
    if (
      conversation.CurrentConversation !== null &&
      conversationId !== '0' &&
      conversation.CurrentConversation.id === conversationId &&
      (conversation.CurrentConversation.grouping === 'Channel' ||
        conversation.CurrentConversation.grouping === 'Group') &&
      participant.selectOtherParticipantPersons(conversationId) !== null // Only possible to Mention if the list of others has loaded
    ) {
      mentionsBox = (
        <AtMentionMembersObserver
          conversationId={conversationId}
          insertMentionAtCursor={this.insertMentionAtCursor}
          getEmojiPickerState={ui.getEmojiPickerState}
          key="at-mention-members"
          loggedInPersonId={person.loggedInPersonId}
          mentionListOpen={message.createMessageMentionListOpen}
          messageMentionFilter={message.createMessageMentionFilter}
          onKeyDown={this.onKeyDown}
          resolveConversationLinkPath={this.resolveConversationLinkPath}
          selectedMentionParticipantId={
            message.createMessageMentionSelectedParticipantId
          }
          selectFilteredOtherParticipantPersonsInCurrentConversation={
            participant.selectFilteredOtherParticipantPersonsInCurrentConversation
          }
          selectParticipantPersons={participant.selectParticipantPersons}
          selectPersonPresenceStatus={ui.selectPersonPresenceStatus}
          selectUnreadCounts={ui.selectConversationUnreadCounts}
          setConversationAndTotalUnreadCount={
            ui.setConversationAndTotalUnreadCount
          }
          updateMyLastReadMessage={participant.updateMyLastReadMessage}
          setMentionListOpen={message.setCreateMessageMentionListOpen}
          setMessageMentionFilter={message.setCreateMessageMentionFilter}
          setSelectedMentionParticipantId={
            message.setCreateMessageMentionSelectedParticipantId
          }
          setEmojiPickerState={ui.setEmojiPickerState}
        />
      );
    }

    return (
      <Styled.MessageInputArea
        data-private
        id="message-input"
        onClick={this.handlePropagation}
        key={conversationId}
      >
        {!this.canReceiveSMS && this.isHover && (
          <Styled.MessageInfo
            onMouseEnter={this.onHover}
            onMouseLeave={this.offHover}
          >
            {!this.userSMSErrorMessage
              ? 'The user that you are trying to message does not currently have a number that can receive SMS.'
              : 'To enable SMS, please request a phone number from your account admin.'}
          </Styled.MessageInfo>
        )}
        {showSendError && (
          <Styled.MessageInfo
            onMouseEnter={this.onHover}
            onMouseLeave={this.offHover}
          >
            The user that you are trying to message does not currently have a
            number that can receive SMS.
          </Styled.MessageInfo>
        )}

        <Styled.MessageBar>
          <Styled.InputWrapper
            onMouseEnter={this.onHover}
            onMouseLeave={this.offHover}
            onKeyDown={this.toggleEmojiPickerOnWrapperKeyDown}
          >
            <ContentEditable
              ref={this.setCeRef}
              className={cx('ce-new-message _lr-hide', {
                'ce-disabled': !this.canReceiveSMS,
              })}
              placeholder="Send a Message"
              html={message.getMessageDraftHtml(conversationId)}
              onChange={this.onCeChange}
              onKeyDown={this.onKeyDown}
              onKeyUp={this.onKeyUp}
              disabled={!this.canReceiveSMS}
              onBlur={this.onFocusLost}
              fileImportEvent={this.fileImportEvent}
              onFocus={this.onFocus}
            />
            {mentionsBox}
            {SHOW_FILE_UPLOAD &&
              (isEmpty(isChatWithNumber) || person.mmsEnabled ? (
                <div className="attach-wrapper">
                  <Styled.FileInput
                    type="file"
                    multiple
                    onChange={this.fileImportEvent}
                    id="embedpollfileinput"
                  />

                  <Styled.AttachmentIconContainer htmlFor="embedpollfileinput">
                    <Icon icon="attachment" size="tiny" />
                  </Styled.AttachmentIconContainer>
                </div>
              ) : (
                <Popup
                  trigger={
                    <div className="attach-wrapper disable-attachment">
                      <Icon icon="attachment" size="tiny" />
                    </div>
                  }
                  content="You are unable to send files - MMS feature is not yet available."
                  position="top center"
                />
              ))}
            <div className="emoji-wrapper">
              {emojiPickerOpenState && (
                <span className="emoji-box">
                  <div className="emoji-bottom-triangle" />
                  <EmojiPicker
                    onSelect={this.insertEmojiWithRef}
                    autoFocus={true}
                  />
                </span>
              )}
              {
                <div className="emoji-btn" onClick={this.toggleShowEmojis}>
                  <Icon icon="smile" size="tiny" />
                </div>
              }
            </div>
          </Styled.InputWrapper>
          <Styled.SubmitButton
            className={`${
              ui.filesUploading ||
              ui.filesUploadingError ||
              (this.isConversationWithPhone() &&
                !person.mmsEnabled &&
                ui.droppedFiles.length !== 0)
                ? 'message-button-send-disabled'
                : ''
            }`}
            onClick={this.handleClick}
          >
            <Styled.SendButtonIcon
              size="small"
              icon={ui.sendingMessage ? 'spinner' : 'send'}
              className={cx('blink_me', {
                spinner: ui.sendingMessage,
              })}
            />
          </Styled.SubmitButton>
        </Styled.MessageBar>
        {SHOW_FILE_UPLOAD && <UploadedFiles ui={ui} message={message} />}
        {!pusher.isOnline && (
          <div className="p-notification-bar-offline">
            Sorry, we can't reach our servers, so messages can’t be sent right
            now.
          </div>
        )}
      </Styled.MessageInputArea>
    );
  }
}
export default inject(
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_PARTICIPANT,
  STORE_PUSHER,
  STORE_UI,
  STORE_CONVERSATION,
  STORE_ROUTER
)(withRouter(observer(MessageInputArea)));
