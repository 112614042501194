import closeBtn from 'Assets/images/close-btn.svg';
import copiedIcon from 'Assets/images/copied-icon.svg';
import copyIcon from 'Assets/images/copy.svg';
import editIcon from 'Assets/images/edit-icon.svg';
import EmojiActive from 'Assets/images/emoji-active.svg';
import EmojiOnHover from 'Assets/images/emoji-hover.svg';
import EmojiInActive from 'Assets/images/emoji-inactive.svg';
import bluePinnedIcon from 'Assets/images/pinned-blue.svg';
import pinnedIcon from 'Assets/images/pinned-icon.svg';
import pinnedWhiteIcon from 'Assets/images/pinned-white-icon.svg';
import AtMentionMembersObserver from 'Components/AtMentionMembers';
import ContentEditable from 'Components/ContentEditable';
import { FilesPreview } from 'Components/FilesPreview';
import { LinkPreview } from 'Components/LinkPreview';
import { MENTION_PREFIX } from 'Constants/enums';
import { VIDEO_URL } from 'Constants/env';
import { Picker as EmojiPicker } from 'emoji-mart';
import { get, isEmpty } from 'lodash';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Popup } from 'semantic-ui-react';

import { isNullOrUndefined } from 'util';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { toggleEdit } from 'Utils/editMessage';
import { electronOpenExternal } from 'Utils/electronHelpers';
import {
  findMentionInsertData,
  focusContentEditable,
  getSelectionRange,
  insertEmoji,
  placeCursorAtEndOfElement,
} from 'Utils/inputNodeUtils';
import { keyIsAlphanumeric, keyIsValidFilterInput } from 'Utils/keyboardInput';
import { compileChatDisplayMarkdown } from 'Utils/markdownCompiler';
import {
  handleAlphanumericWhenMentionListOpen,
  handleAtSymbol,
  handleBackspaceDeleteWhenMentionListOpen,
  handleEnterKey,
  handleEscapeKey,
  handleTabRightArrowSpacebarWhenMentionListOpen,
  handleUpDownLeftArrowsWhenMentionListOpen,
} from 'Utils/mentionKeyHandlers';
import { turndownSvc } from 'Utils/turndownSvc';
import { Styled } from './index.styles';
import { IContextContentItemChatProps } from './interfaces';

@observer
export class ContextContentItemChat extends React.Component<IContextContentItemChatProps> {
  @observable
  loadingLinkPreview = false;
  @action
  setLoadingLinkPreview = (b: boolean) => (this.loadingLinkPreview = b);

  buildLinkPreview = (url) => {
    if (this.props.messageModel.id) {
      this.props.loadLinkPreview(
        url,
        this.props.conversationId,
        this.props.messageModel.id
      );
    }
  };

  @observable
  isDeadkeyPressed: boolean = false;

  @observable
  previousKeyWasDeadKey: boolean = false;

  @observable
  isMouseOverEmojiFlag: boolean = false;

  @action
  toggleIsMouseOverEmojiFlag = (isMouseOverEmojiFlag: boolean) =>
    (this.isMouseOverEmojiFlag = isMouseOverEmojiFlag);

  @action
  setOnMouseOverEmoji = () => this.toggleIsMouseOverEmojiFlag(true);

  @action
  setOnMouseLeaveEmoji = () => this.toggleIsMouseOverEmojiFlag(false);

  @observable
  compiledContent: React.ReactNode = null;
  @action
  setCompiledContent = (compiledContent: React.ReactNode) =>
    (this.compiledContent = compiledContent);

  includeMentions = () => {
    const {
      curConversation: { grouping, participants },
    } = this.props;
    return (
      (grouping === 'Channel' || grouping === 'Group') &&
      !participants.some((participant) => participant.phone)
    );
  };

  /** As soon as `selectParticipantPersons` is not `null`, compiles the message content markdown into a `React.ReactNode` */
  compileMarkdown = (content: string, includeMentions: boolean) => {
    const { conversationId, selectParticipantPersons } = this.props;
    const participantPersons = selectParticipantPersons(conversationId);
    this.setCompiledContent(
      compileChatDisplayMarkdown(
        content,
        includeMentions,
        participantPersons,
        this.setLoadingLinkPreview,
        this.buildLinkPreview,
        this.loadingLinkPreview,
        electronOpenExternal
      )
    );
  };

  constructor(props: IContextContentItemChatProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { content, curConversation } = this.props;
    if (curConversation) {
      this.compileMarkdown(content, this.includeMentions());
    }
  }

  componentDidUpdate(prevProps: IContextContentItemChatProps) {
    const {
      content,
      curConversation,
      isEditingMessageId,
      getEmojiPickerState,
    } = this.props;
    if (
      (content &&
        !isNullOrUndefined(curConversation) &&
        prevProps.content &&
        content !== prevProps.content) ||
      (!isNullOrUndefined(curConversation) &&
        (isNullOrUndefined(prevProps.curConversation) ||
          curConversation.grouping !== prevProps.curConversation.grouping))
    ) {
      this.compileMarkdown(content, this.includeMentions());
    }
    if (
      isEditingMessageId &&
      window.getSelection().toString().length === 0 &&
      !getEmojiPickerState().open
    ) {
      if (this.ceRef?.htmlEl) {
        focusContentEditable(this.ceRef);
      }
    }
  }

  insertMentionAtCursor = (prefix: MENTION_PREFIX, value: string) => {
    const {
      conversationId,
      editMessageDraftHtml,
      editMessageDraftRaw,
      setMentionListOpen,
      selectParticipantPersons,
      setEditMessageDraftHtml,
      setEditMessageDraftRaw,
    } = this.props;
    const { start, end, htmlStart, htmlEnd } = this.ceRef.selectionRange;

    const rawInsertData = findMentionInsertData(editMessageDraftRaw, start);
    const emdRaw =
      editMessageDraftRaw.substring(0, rawInsertData.insertIndex) +
      `${rawInsertData.prependWith}${prefix}${value} ` +
      editMessageDraftRaw.substring(end);
    setEditMessageDraftRaw(emdRaw);

    if (prefix === '@pr') {
      const personId = parseInt(value, 10);
      const mentioned = selectParticipantPersons(conversationId).find(
        (p) => p.person.id === personId
      ); // Mentioned Participant/Person
      if (mentioned !== undefined) {
        const htmlInsertData = findMentionInsertData(
          editMessageDraftHtml,
          htmlStart
        );
        const emdHtml =
          editMessageDraftHtml.substring(0, htmlInsertData.insertIndex) +
          `${htmlInsertData.prependWith}&nbsp;<div class="input-mention" mention-target="${prefix}${value}" contenteditable="false">@${mentioned.person.DisplayName}</div> &nbsp;` +
          editMessageDraftHtml.substring(htmlEnd);
        setEditMessageDraftHtml(emdHtml);
        setMentionListOpen(false);
      }
    } else {
      const htmlInsertData = findMentionInsertData(
        editMessageDraftHtml,
        htmlStart
      );
      const emdHtml =
        editMessageDraftHtml.substring(0, htmlInsertData.insertIndex) +
        `${htmlInsertData.prependWith}&nbsp;<div class="input-mention" mention-target="${prefix}${value}" contenteditable="false">${prefix}${value}</div> &nbsp;` +
        editMessageDraftHtml.substring(htmlEnd);
      setEditMessageDraftHtml(emdHtml);
      setMentionListOpen(false);
    }
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { setEditMessageDraftRaw, setEditMessageDraftHtml } = this.props;
    let htmlValue = e.target.value;

    if (!isEmpty(htmlValue)) {
      htmlValue = htmlValue.replace('\n', '<br>');
    }

    const rawResultValue = turndownSvc.turndown(htmlValue);
    if (!this.isDeadkeyPressed) {
      setEditMessageDraftRaw(rawResultValue);
      if (!isEmpty(rawResultValue)) {
        setEditMessageDraftHtml(htmlValue);
      } else {
        setEditMessageDraftHtml('');
      }
    }
  };

  handlePinnedMess = (id: string) => () => {
    const {
      messageModel,
      conversationId,
      conversationStore,
      listOfPinnedMessages,
    } = this.props;
    const pinnedMessage = listOfPinnedMessages.get(conversationId);
    const isPinned = pinnedMessage?.find(
      (item) => item.id === messageModel?.id
    );
    if (isPinned) {
      conversationStore.deletePinnedMessages(conversationId, id);
    } else {
      conversationStore.createPinnedMessages(conversationId, id);
    }
  };

  closeEditingMode = () => {
    const {
      curConversation,
      messageModel,
      setIsEditingMessageId,
      setEditMessageDraftRaw,
      setEditMessageDraftHtml,
      selectParticipantPersons,
    } = this.props;
    setIsEditingMessageId(null);
    toggleEdit(
      curConversation,
      selectParticipantPersons,
      get(messageModel, 'chat.text', ''),
      null,
      setEditMessageDraftRaw,
      setEditMessageDraftHtml,
      null,
      setIsEditingMessageId
    );
  };

  openEditingMode = (e) => {
    const {
      curConversation,
      isEditingMessageId,
      messageModel,
      setIsEditingMessageId,
      setEditMessageDraftRaw,
      setEditMessageDraftHtml,
      selectParticipantPersons,
    } = this.props;
    toggleEdit(
      curConversation,
      selectParticipantPersons,
      get(messageModel, 'chat.text', ''),
      messageModel.id,
      setEditMessageDraftRaw,
      setEditMessageDraftHtml,
      e.target.id === 'cancel-edit' ? isEditingMessageId : null,
      setIsEditingMessageId
    );
  };
  toggleEditMessage = (e) => {
    const { isEditingMessageId } = this.props;
    if (isEditingMessageId) {
      this.closeEditingMode();
    }
    this.openEditingMode(e);

    const event = e.currentTarget.closest('.event');
    if (isNullOrUndefined(isEditingMessageId)) {
      event.classList.add('edit');
    } else {
      event.classList.remove('edit');
      e.currentTarget
        .closest('#context-panel')
        .querySelector('.ce-new-message')
        .focus();
    }
  };

  submitEditMessage = () => {
    const {
      conversationId,
      curConversation,
      editMessage,
      editMessageDraftRaw,
      editMessageDraftHtml,
      messageModel,
    } = this.props;
    if (editMessageDraftRaw !== messageModel.chat.text) {
      if (editMessageDraftHtml.includes('class="input-mention"')) {
        pushToGTMDataLayer('mention', {
          conversationId,
        });
      }
      editMessage(conversationId, messageModel.id, {
        text: editMessageDraftRaw,
      });
      this.compileMarkdown(editMessageDraftRaw, this.includeMentions());
    }
    this.closeEditingMode();
  };

  editOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Dead') {
      this.isDeadkeyPressed = false;
    }
  };

  editOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {
      conversationId,
      curConversation,
      editMessageDraftHtml,
      getEmojiPickerState,
      mentionListOpen,
      messageMentionFilter,
      selectedMentionParticipantId,
      selectFilteredOtherParticipantPersonsInCurrentConversation,
      selectParticipantPersons,
      setEditMessageDraftRaw,
      setEditMessageDraftHtml,
      setEmojiPickerState,
      setMentionListOpen,
      setMessageMentionFilter,
      setSelectedMentionParticipantId,
    } = this.props;

    if (this.previousKeyWasDeadKey && !this.isDeadkeyPressed) {
      this.previousKeyWasDeadKey = false;
      return;
    }

    if (e.key === 'Dead') {
      this.isDeadkeyPressed = true;
      this.previousKeyWasDeadKey = true;
    }

    if (e.key === 'Escape') {
      e.preventDefault();
      if (this.ceRef && document.activeElement === this.ceRef.htmlEl) {
        if (!getEmojiPickerState().open) {
          this.toggleEditMessage(e);
        } else {
          setEmojiPickerState({ open: false, editing: false });
        }
      }
    }

    // Enter
    if (e.key === 'Enter') {
      handleEnterKey(
        e,
        true, // TODO: Pass prop down from PusherStore (RP 2018-11-21)
        conversationId,
        mentionListOpen,
        selectedMentionParticipantId,
        this.insertMentionAtCursor,
        selectParticipantPersons,
        this.submitEditMessage,
        setEditMessageDraftRaw,
        setEditMessageDraftHtml
      );
    }

    // @ symbol (shift + 2), pop up the mentions list
    if (
      (curConversation.grouping === 'Channel' ||
        curConversation.grouping === 'Group') &&
      e.key === '@'
    ) {
      handleAtSymbol(
        e,
        this.ceRef.selectionRange,
        editMessageDraftHtml,
        mentionListOpen,
        setMentionListOpen,
        setSelectedMentionParticipantId,
        setEmojiPickerState
      );
    }

    // Handle Up/Down arrows, Backspace, and Delete when the Mention list is open
    if (
      (curConversation.grouping === 'Channel' ||
        curConversation.grouping === 'Group') &&
      mentionListOpen
    ) {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft'].includes(e.key)) {
        handleUpDownLeftArrowsWhenMentionListOpen(
          e,
          selectFilteredOtherParticipantPersonsInCurrentConversation,
          selectedMentionParticipantId,
          setSelectedMentionParticipantId,
          setMentionListOpen,
          messageMentionFilter
        );
      } else if (['ArrowRight', 'Tab', ' '].includes(e.key)) {
        handleTabRightArrowSpacebarWhenMentionListOpen(
          e,
          selectedMentionParticipantId,
          selectParticipantPersons,
          conversationId,
          this.insertMentionAtCursor
        );
      } else if (['Backspace', 'Delete'].includes(e.key)) {
        handleBackspaceDeleteWhenMentionListOpen(
          e,
          this.ceRef.selectionRange,
          editMessageDraftHtml,
          mentionListOpen,
          setMentionListOpen,
          messageMentionFilter,
          setMessageMentionFilter,
          selectFilteredOtherParticipantPersonsInCurrentConversation,
          setSelectedMentionParticipantId
        );
      } else if (keyIsAlphanumeric(e)) {
        handleAlphanumericWhenMentionListOpen(
          e,
          mentionListOpen,
          messageMentionFilter,
          setMessageMentionFilter,
          selectFilteredOtherParticipantPersonsInCurrentConversation,
          setSelectedMentionParticipantId
        );
      }
      // Any invalid inputs should close the Mention list to avoid auto-complete desync
      else if (!keyIsValidFilterInput(e)) {
        setMentionListOpen(false);
      }
    }
  };
  deleteMessage = () => {
    if (confirm('Are you sure you want to delete this message?')) {
      this.props.deleteMessage(
        this.props.conversationId,
        this.props.messageModel.id
      );
    }
  };

  toggleShowEmojis = (e) => {
    const { getEmojiPickerState, setEmojiPickerState, setMentionListOpen } =
      this.props;
    if (!getEmojiPickerState().open) {
      setEmojiPickerState({ open: true, editing: true });
      setMentionListOpen(false);
      this.toggleIsMouseOverEmojiFlag(true);
      if (this.ceRef && this.ceRef.htmlEl) {
        const ceRefSelection = getSelectionRange(this.ceRef.htmlEl);
        // Focus and move to end if there is no selection (unfocused) in the input
        if (ceRefSelection.start === 0 && ceRefSelection.end === 0) {
          focusContentEditable(this.ceRef);
          placeCursorAtEndOfElement(this.ceRef.htmlEl);
        }
      }
    } else {
      setEmojiPickerState({ open: false, editing: false });
    }
    e.preventDefault();
    e.stopPropagation();
  };

  insertEmojiWithRef = (emoji) => {
    insertEmoji(emoji, this.ceRef);
  };

  toggleEmojiPickerOnWrapperKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const { setEmojiPickerState } = this.props;
    if (e.key === 'Escape') {
      handleEscapeKey(e, setEmojiPickerState);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  @action
  handleDownloadFile = async (externalId: string) => {
    const { getFileDownloadLink, handleDownloadWithLink } = this.props;
    const file = await getFileDownloadLink(externalId);
    if (file) {
      handleDownloadWithLink(file.data.url, file.data.fileName);
    }
    return file;
  };

  handleCopy = () => {
    const { messageModel, copyToClipboard, setCopiedConferenceId } = this.props;
    copyToClipboard([VIDEO_URL + messageModel?.conference?.id]);
    setCopiedConferenceId(messageModel?.conference?.id);
  };

  onEmojiWrapperClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  ceRef: ContentEditable;
  setCeRef = (ce: ContentEditable) => (this.ceRef = ce);

  render() {
    const {
      content,
      conversationId,
      curConversation,
      editMessageDraftHtml,
      getEmojiPickerState,
      isEditingMessageId,
      loggedInPersonId,
      mentionListOpen,
      messageModel,
      messageMentionFilter,
      newestMessageOwnedByUserId,
      resolveConversationLinkPath,
      setEmojiPickerState,
      selectedMentionParticipantId,
      selectFilteredOtherParticipantPersonsInCurrentConversation,
      selectParticipantPersons,
      selectPersonPresenceStatus,
      selectUnreadCounts,
      setMentionListOpen,
      setMessageMentionFilter,
      setSelectedMentionParticipantId,
      setConversationAndTotalUnreadCount,
      updateMyLastReadMessage,
      copiedConferenceId,
      listOfPinnedMessages,
    } = this.props;
    // Control content dynamically based on editing mode
    let hoverMenu;
    let commentBox;
    const newestMessage = newestMessageOwnedByUserId;
    const pinnedMessage = listOfPinnedMessages.get(conversationId);
    // Build the hover menu if this message belongs to the logged in user
    // Right now we only allow users to edit the most recent message if it's not an SMS.
    //onClick={this.deleteMessage}
    if (
      content !== null &&
      !newestMessage?.chat?.smsRelay &&
      messageModel.isDeleted !== true
    ) {
      const isPinned = pinnedMessage?.find(
        (item) => item.id === messageModel.id
      );
      hoverMenu = (
        <div className="pinned-box hover-menu" id={messageModel.id}>
          {messageModel.conference && (
            <Popup
              inverted
              trigger={
                <img
                  src={
                    copiedConferenceId === messageModel?.conference?.id
                      ? copiedIcon
                      : copyIcon
                  }
                  onClick={this.handleCopy}
                />
              }
              content={
                copiedConferenceId === messageModel?.conference?.id
                  ? 'Link Copied'
                  : 'Copy Link'
              }
              position={'top center'}
            />
          )}
          {loggedInPersonId === messageModel.personId &&
            messageModel.id &&
            messageModel.chat && (
              <Popup
                inverted
                trigger={
                  <img src={editIcon} onClick={this.toggleEditMessage} />
                }
                content="Edit message"
                position={'top center'}
              />
            )}
          {isPinned ? (
            <div className="pinned pinned-icon">
              <Popup
                inverted
                trigger={
                  <img
                    src={pinnedWhiteIcon}
                    onClick={this.handlePinnedMess(messageModel.id)}
                  />
                }
                content="Un-pin message"
                position={'top center'}
              />
            </div>
          ) : (
            <Popup
              inverted
              trigger={
                <img
                  className="pinned-icon"
                  src={pinnedIcon}
                  onClick={this.handlePinnedMess(messageModel.id)}
                />
              }
              content="Pin message"
              position={'top center'}
            />
          )}
          {loggedInPersonId === messageModel.personId &&
            messageModel.id &&
            (messageModel.chat ||
              messageModel.call ||
              messageModel.documents?.length > 0) && (
              <Popup
                inverted
                trigger={
                  <img
                    className="delete-icon"
                    src={closeBtn}
                    onClick={this.deleteMessage}
                  />
                }
                content="Remove message"
                position={'top center'}
              />
            )}
        </div>
      );
    }

    // Show edit box if this message is flagged for editing
    if (isEditingMessageId !== null && isEditingMessageId === messageModel.id) {
      // Show mentions box if conditions met
      let mentionsBox;

      if (
        curConversation &&
        (curConversation.grouping === 'Channel' ||
          curConversation.grouping === 'Group')
      ) {
        mentionsBox = (
          <AtMentionMembersObserver
            conversationId={conversationId}
            getEmojiPickerState={getEmojiPickerState}
            insertMentionAtCursor={this.insertMentionAtCursor}
            key="channel-members-existing-edit"
            loggedInPersonId={loggedInPersonId}
            mentionListOpen={mentionListOpen}
            messageMentionFilter={messageMentionFilter}
            onKeyDown={this.editOnKeyDown}
            resolveConversationLinkPath={resolveConversationLinkPath}
            selectedMentionParticipantId={selectedMentionParticipantId}
            selectFilteredOtherParticipantPersonsInCurrentConversation={
              selectFilteredOtherParticipantPersonsInCurrentConversation
            }
            selectParticipantPersons={selectParticipantPersons}
            selectPersonPresenceStatus={selectPersonPresenceStatus}
            selectUnreadCounts={selectUnreadCounts}
            setMentionListOpen={setMentionListOpen}
            setMessageMentionFilter={setMessageMentionFilter}
            setSelectedMentionParticipantId={setSelectedMentionParticipantId}
            setEmojiPickerState={setEmojiPickerState}
            setConversationAndTotalUnreadCount={
              setConversationAndTotalUnreadCount
            }
            updateMyLastReadMessage={updateMyLastReadMessage}
          />
        );
      }

      commentBox = (
        <div className="edit-wrapper">
          <ContentEditable
            ref={this.setCeRef}
            className="edit-box _lr-hide"
            placeholder="Enter Message"
            html={editMessageDraftHtml}
            onChange={this.onChange}
            onKeyDown={this.editOnKeyDown}
            onKeyUp={this.editOnKeyUp}
          />
          {mentionsBox}
          <div
            className="emoji-wrapper"
            onClick={this.onEmojiWrapperClick}
            onKeyDown={this.toggleEmojiPickerOnWrapperKeyDown}
          >
            {getEmojiPickerState().open && getEmojiPickerState().editing && (
              <span className="emoji-box">
                <div className="emoji-bottom-triangle" />
                <EmojiPicker
                  onSelect={this.insertEmojiWithRef}
                  autoFocus={true}
                  onClose={this.toggleShowEmojis}
                />
              </span>
            )}
            {
              <Styled.EmojiWrapper onClick={this.toggleShowEmojis}>
                <img
                  src={
                    getEmojiPickerState().editing ? EmojiActive : EmojiInActive
                  }
                  onMouseOver={
                    getEmojiPickerState().editing
                      ? (e) => (e.currentTarget.src = EmojiActive)
                      : (e) => (e.currentTarget.src = EmojiOnHover)
                  }
                  onMouseLeave={
                    getEmojiPickerState().editing
                      ? (e) => (e.currentTarget.src = EmojiActive)
                      : (e) => (e.currentTarget.src = EmojiInActive)
                  }
                />
              </Styled.EmojiWrapper>
            }
          </div>
          <Button onClick={this.toggleEditMessage} id="cancel-edit">
            Cancel
          </Button>
          <Button primary onClick={this.submitEditMessage}>
            Save Changes
          </Button>
        </div>
      );
    } else {
      let editedText;
      if (
        messageModel.chat !== undefined &&
        messageModel.chat !== null &&
        !messageModel.chat.smsRelay &&
        messageModel.updated &&
        messageModel.isDeleted === undefined
      ) {
        editedText = <span className="edited">(edited)</span>;
      }
      commentBox = (
        <div className="testWhiteSpace dont-break-out _lr-hide text">
          {pinnedMessage?.find((item) => item.id === messageModel.id) && (
            <div className="pinned-flag">
              Pinned <img src={bluePinnedIcon} />
            </div>
          )}
          {hoverMenu}
          {this.compiledContent}
          {messageModel.documents && messageModel.documents.length > 0 && (
            <FilesPreview
              messageModel={messageModel}
              loggedInPersonId={loggedInPersonId}
              handleDownloadFile={this.handleDownloadFile}
              openModalDeleteFile={this.props.setFileDeletePopup}
            />
          )}
          <LinkPreview
            hasLinkPreview={
              messageModel.linkPreview !== null &&
              !isEmpty(messageModel.linkPreview.url)
            }
            messageModel={messageModel}
          />
          {editedText}
        </div>
      );
    }

    return commentBox;
  }
}
