import styled from 'styled-components';
import { Icon } from '../../components/shared/Icon';

const MessageInputArea = styled.div`
  position: relative;
`;

const InputWrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.base};
  margin: ${({ theme }) => theme.sizes.xxsmall} auto 0;
`;

const SubmitButton = styled.div`
  margin-left: ${({ theme }) => theme.sizes.xxxsmall};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  &-disabled {
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.border};
  }
`;

const SendButtonIcon = styled(Icon)`
  margin: 9px;
  color: ${({ theme }) => theme.colors.base}!important;
  &.spinner {
    position: static;
    top: 0px !important;
    margin-right: 0px !important;
  }
`;

const MessageBar = styled.div`
  display: flex;
  align-items: end;
`;

const MessageInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #277ee3;
  font-size: 14px;
  cursor: default;
  min-height: 3em;
  padding: 0.85em 1em 0.6em 1em;
  width: 100%;
  text-align: center;
  color: white !important;
  z-index: 2;
  border-radius: 4px;
`;

const FileInput = styled.input`
  position: relative;
  opacity: 0;
`;

const AttachmentIconContainer = styled.label`
  position: absolute;
`;

export const Styled = {
  AttachmentIconContainer,
  FileInput,
  MessageBar,
  MessageInputArea,
  InputWrapper,
  SubmitButton,
  SendButtonIcon,
  MessageInfo,
};
