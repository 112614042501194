import HistoryListContainer from 'Containers/HistoryList';
import { IUnreadMessageAndMentionCountsByConversationId } from 'Interfaces/components';
import { get, isEmpty } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Grid, List, Loader, Message } from 'semantic-ui-react';
import withRouter from '../../hocs/WithRouter';
import ActivityListItem from '../ActivityListItem';
import {
  ActivityListItemsListRendererProps,
  ActivityListItemsProps,
} from './interfaces';

@observer
export class ActivityListItemsListRenderer extends React.Component<
  ActivityListItemsListRendererProps,
  {}
> {
  constructor(props: ActivityListItemsListRendererProps) {
    super(props);
    makeObservable(this);
  }

  @computed
  get UnreadCounts() {
    const { conversationStore, selectUnreadCounts } = this.props;
    const convList = conversationStore.RecentConversations;

    return convList.reduce<IUnreadMessageAndMentionCountsByConversationId>(
      (prev, next) => {
        prev[next.id] = selectUnreadCounts(next.id);
        return prev;
      },
      {}
    );
  }
  render() {
    const {
      contactsByPhone,
      conversationStore,
      currentConversationId,
      listUnreadFirst,
      loadPresenceIfMissing,
      loggedInAccountId,
      loggedInPersonId,
      makeCall,
      phoneStore,
      selectConversationParticipants,
      selectParticipantPersonInfo,
      selectPersonPresenceStatus,
      selectUnreadCounts,
      setConversationAndTotalUnreadCount,
      updateMyLastReadMessage,
      updateRoute,
      personStore,
      setShowPersonDetails,
    } = this.props;
    const convList = conversationStore.RecentConversations;
    return (
      <Grid.Column>
        <List inverted verticalAlign={'middle'} size="large" selection>
          <div className="items recent recent-mediaq margin-0">
            {convList
              .slice()
              .sort((a, b) => {
                const aDate = a.lastMessageDate || a.created;
                const bDate = b.lastMessageDate || b.created;
                let sortResult = aDate <= bDate ? 1 : -1; // 1 is B before A, -1 is A before B
                if (listUnreadFirst) {
                  const unreadCountsA = this.UnreadCounts[a.id];
                  const unreadCountsB = this.UnreadCounts[b.id];
                  const aHasUnreads =
                    !isEmpty(unreadCountsA) && unreadCountsA.unreadMessages > 0;
                  const bHasUnreads =
                    !isEmpty(unreadCountsB) && unreadCountsB.unreadMessages > 0;
                  if (aHasUnreads && !bHasUnreads) {
                    sortResult = -1; // A before B
                  } else if (!aHasUnreads && bHasUnreads) {
                    sortResult = 1; // B before A
                  } // ... Otherwise, leave the sort result alone
                }

                return sortResult;
              })
              .map((conv) => {
                return (
                  <ActivityListItem
                    from="recentFevList"
                    setShowPersonDetails={setShowPersonDetails}
                    contactsByPhone={contactsByPhone}
                    conversation={conv}
                    conversationId={conv.id}
                    isCurrentConversation={currentConversationId === conv.id}
                    key={conv.id}
                    loadPresenceIfMissing={loadPresenceIfMissing}
                    loggedInAccountId={loggedInAccountId}
                    loggedInPersonId={loggedInPersonId}
                    loggedInUserActiveConferenceConversation={
                      conversationStore.LoggedInUserActiveConferenceConversation
                    }
                    makeCall={makeCall}
                    participantsPbo={selectConversationParticipants(conv.id)}
                    phoneCall={phoneStore}
                    postConferenceByConversationId={
                      conversationStore.postConferenceByConversationId
                    }
                    selectParticipantPersonInfo={selectParticipantPersonInfo}
                    selectPersonPresenceStatus={selectPersonPresenceStatus}
                    selectUnreadCounts={selectUnreadCounts}
                    setConversationAndTotalUnreadCount={
                      setConversationAndTotalUnreadCount
                    }
                    updateMyLastReadMessage={updateMyLastReadMessage}
                    updateRoute={updateRoute}
                    participant={this.props.participantStore}
                    isOnline={this.props.isOnline}
                    personStore={personStore}
                    conversationStore={conversationStore}
                  />
                );
              })}
            {conversationStore.RecentConversations.length === 0 && (
              <div className="empty">
                {'You do not currently have any Recent Conversations.'}
              </div>
            )}
          </div>
        </List>
      </Grid.Column>
    );
  }
}

/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ActivityListItems
 */
export class ActivityListItems extends React.Component<
  ActivityListItemsProps,
  {}
> {
  render() {
    const {
      phoneStore,
      activeItem,
      conversationStore,
      isOnline,
      setMoreActionOptions,
    } = this.props;
    const isDirectory = location.pathname === '/directory';
    return (
      <Grid.Row columns={1} key="aci-row2">
        <Grid.Column>
          {!isDirectory && activeItem === 'history' && <HistoryListContainer />}
        </Grid.Column>
        {activeItem === 'recent' && (
          <Grid.Column>
            {conversationStore.loadConversationsLazyStatus &&
              conversationStore.loadConversationsLazyStatus.case({
                fulfilled: (_) => (
                  <ActivityListItemsListRenderer
                    {...this.props}
                    phoneStore={phoneStore}
                    isOnline={isOnline}
                    setMoreActionOptions={setMoreActionOptions}
                  />
                ),
                pending: () => <Loader active indeterminate inline />,
                rejected: (reason) =>
                  reason.message === 'Network Error' ? null : (
                    <Message visible error>
                      Error: {get(reason, 'response.data.message')}
                    </Message>
                  ),
              })}
          </Grid.Column>
        )}
      </Grid.Row>
    );
  }
}

export default withRouter(observer(ActivityListItems));
