import { HELP_URL } from 'Constants/env';
import React, { useEffect } from 'react';
import type { UserDropdownMenuProps } from './interfaces';
import { UserDropdownMenuOption } from './UserDropdownMenuOption';
import { FeatureFlag } from '../../../containers/FeatureFlag';
import { REFRESH_TOKEN } from '../../../constants/featureFlags';
import { UserDropdownMenuHelp } from './UserDropdownMenuHelp';

export const UserDropdownMenu: React.FC<UserDropdownMenuProps> = ({
  handleClickDropDown,
  handleLogoutClick,
  handleRefreshTokenClick,
  setHeaderDropdown,
  showHeaderDropdown,
  testid,
  toggleShowMessageSettingsModal,
  toggleShowMessageStatusModal,
}) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        !event.target?.closest('.user-dropdown') &&
        !event.target?.closest('.user-name-wrap-inner')
      ) {
        setHeaderDropdown(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [setHeaderDropdown]);

  return (
    <div
      onBlur={() => setHeaderDropdown(false)}
      className={`user-dropdown ${showHeaderDropdown ? 'show' : 'hide'}`}
    >
      <UserDropdownMenuOption
        testid={`${testid}-dropdownOptionSettings`}
        onClick={() => handleClickDropDown(toggleShowMessageSettingsModal)}
      >
        Settings
      </UserDropdownMenuOption>
      <UserDropdownMenuOption
        onClick={() => handleClickDropDown(toggleShowMessageStatusModal)}
        testid={`${testid}-dropdownOptionAvailability`}
      >
        Status & Availability
      </UserDropdownMenuOption>
      {HELP_URL && (
        <UserDropdownMenuHelp testid={`${testid}-dropdownOptionHelp`} />
      )}
      <UserDropdownMenuOption
        testid={`${testid}-dropdownOptionLogout`}
        onClick={handleLogoutClick}
        destructive
      >
        Logout
      </UserDropdownMenuOption>
      <FeatureFlag flag={REFRESH_TOKEN}>
        <UserDropdownMenuOption
          testid={`${testid}-dropdownOptionRefreshToken`}
          onClick={handleRefreshTokenClick}
        >
          Refresh Token
        </UserDropdownMenuOption>
      </FeatureFlag>
    </div>
  );
};
