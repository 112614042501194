import React from 'react';
import { Grid } from 'semantic-ui-react';
import { DirectoryLinks } from '../DirectoryLinks';
import type { DirectoryRowProps } from './types';

import { DirectoryActionButtons } from '../DirectoryActionButtons';
import { DirectoryUserInfo } from '../DirectoryUserInfo';
import { Styled } from './index.styles';

export const DirectoryRow: React.FC<DirectoryRowProps> = ({
  conversation: {
    loadOrCreateConversationWithPost,
    LoggedInUserActiveConferenceConversation,
  },
  data,
  loggedInPersonId,
  loggedInPersonVideoFeature,
  makeCall,
  makeVideoCall,
  phoneStore: { phoneCalls },
  testid: parentTestId,
  uiStore: { selectConversationUnreadCounts, selectPersonPresenceStatus },
}) => {
  const testid = `${parentTestId}-directoryRow`;

  return (
    <Styled.DirectoryRowWrapper key={data.id}>
      <Grid.Column width={6}>
        <DirectoryUserInfo
          {...{
            selectConversationUnreadCounts,
            selectPersonPresenceStatus,
            data,
          }}
        />
      </Grid.Column>
      <Grid.Column width={5}>
        <DirectoryLinks {...{ data, loggedInPersonId, makeCall, testid }} />
      </Grid.Column>
      <Grid.Column width={5}>
        <DirectoryActionButtons
          {...{
            loadOrCreateConversationWithPost,
            LoggedInUserActiveConferenceConversation,
            loggedInPersonId,
            loggedInPersonVideoFeature,
            makeCall,
            makeVideoCall,
            phoneCalls,
            userId: Number(data?.id),
            testid,
          }}
        />
      </Grid.Column>
    </Styled.DirectoryRowWrapper>
  );
};
