import * as React from 'react';

import { IconButton } from '../shared/IconButton';
import type { PinnedMessagesButtonProps } from './PinnedMessagesButton.types';

export const PinnedMessagesButton: React.FC<PinnedMessagesButtonProps> = ({
  conversationId,
  popupPosition,
  setActiveConversationIdPinnMess,
  setOpenedRightSidebarsOrder,
  size,
  testid,
}) => {
  const handlePinnedMessage = () => {
    setActiveConversationIdPinnMess(conversationId);
    setOpenedRightSidebarsOrder('pinned-messages');
  };

  return (
    <IconButton
      onClick={handlePinnedMessage}
      content="View Pinned Messages"
      icon="bookmark"
      testid={`${testid}-buttonPinnedMessages`}
      {...{ popupPosition, size }}
    />
  );
};
