import styled from 'styled-components';
import { Heading } from 'Components/shared/Heading';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const StyledHeading = styled(Heading)`
  font-weight: bold;
`;

const Emoji = styled.span`
  margin-left: ${({ theme }) => theme.sizes.xsmall};
  font-size: ${({ theme }) => theme.sizes.small};
`;

export const Styled = {
  Wrapper,
  Heading: StyledHeading,
  Emoji,
};
