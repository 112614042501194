import type { ConfirmationModalProps } from './types';
import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Button } from 'Components/shared/Button';
import { Styled } from './index.styles';
import { IconButton } from 'Components/shared/IconButton';

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  onClose,
  onCancel,
  onConfirm,
  textConfirm,
  textCancel,
  children,
  testId: parentTestId
}) => (
  <Styled.Modal
    size="small"
    open={show}
    dimmer="blurring"
    closeOnDimmerClick
    centered
    {...{ onClose }}
  >
    <Modal.Content>
      <Styled.TextContainer variant='h4'>
        {children}
      </Styled.TextContainer>

      <Styled.ActionButtonsContainer>
        <Button
          onClick={onConfirm}
          variant="primary"
          content={textConfirm}
          testid={`${parentTestId}-buttonConfirm`}
        />

        <Styled.CancelButton
          onClick={onCancel}
          variant="secondary"
          content={textCancel}
          testid={`${parentTestId}-buttonCancel`}
        />
      </Styled.ActionButtonsContainer>

      <Styled.CloseButtonContainer>
        <IconButton
          icon={'cancel'}
          onClick={onClose}
          size='small'
          testid={`${parentTestId}-buttonClose`}
        />
      </Styled.CloseButtonContainer>
    </Modal.Content>
  </Styled.Modal>
);


