import { withAuth0 } from '@auth0/auth0-react';
import {
  STORE_CONFIG,
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_ROUTER,
  STORE_UI,
} from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import TopBarDropdown from './TopBarDropdown';
import TopBarUser from './TopBarUser';
import type { TopBarProps } from './interfaces';

import { LogoButton } from './LogoButton';
import { Styled } from './index.styles';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from '../../constants/responsiveness';
import { IS_BETA_ENV } from '../../constants/env';

const InjectedTopBar: React.FC<TopBarProps> = ({
  router,
  person,
  ui,
  message,
  notification,
  preference,
  config,
  phoneCall,
  auth0,
}) => {
  const testid = 'topBar';

  return (
    <Grid.Row
      className="app-header"
      onMouseEnter={() =>
        !ui.isTouchSupported() &&
        ui.setHideSecondaryMenu(
          window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT
        )
      }
    >
      <LogoButton {...{ testid }} />
      {IS_BETA_ENV && <Styled.BetaLogoText>BETA</Styled.BetaLogoText>}
      <Grid.Column className="action-button-wrapper">
        <TopBarDropdown {...{ testid }} />
      </Grid.Column>
      <TopBarUser
        {...{
          testid,
          message,
          notification,
          router,
          person,
          preference,
          phoneCall,
          config,
          ui,
          auth0,
        }}
      />
    </Grid.Row>
  );
};

export const TopBar = inject(
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_CONFIG,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_PREFERENCE,
  STORE_UI,
  STORE_MESSAGE
)(observer(withAuth0(InjectedTopBar)));
