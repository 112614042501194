import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Header, Loader } from 'semantic-ui-react';
import { formatNumberWithNationalCode } from 'Utils/phoneUtil';
import { ContactHeaderProps } from './interfaces';

/**
 * Child of `ContextHeader`, displays contextual header information about a `Contact`.
 *
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ContactHeader
 */
export class ContactHeader extends React.Component<ContactHeaderProps> {
  render() {
    const { contact, otherPtc, extrContact } = this.props;
    if (isEmpty(contact)) {
      return <Loader indeterminate active size="tiny" />;
    }
    const getFormatedNumber = formatNumberWithNationalCode(otherPtc);
    return (
      <Header as="h2" className="context-header-display valign-middle">
        {extrContact ? (
          <Header.Content className="extr-contact">
            <div className="name">{extrContact?.DisplayName()}</div>
            <div className="phone">{getFormatedNumber}</div>
            {(extrContact?.organization || extrContact?.organizationRole) && (
              <div className="organization">
                {`${extrContact?.organization ? extrContact?.organization : ''}
                        ${
                          extrContact?.organizationRole
                            ? `( ${extrContact.organizationRole} )`
                            : ''
                        }`}
              </div>
            )}
          </Header.Content>
        ) : (
          <Header.Content>{contact.DisplayName}</Header.Content>
        )}
      </Header>
    );
  }
}

export default observer(ContactHeader);
