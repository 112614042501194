import { ContextContentItemsList } from 'Components/ContextContentItemsList';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_PUSHER,
  STORE_ROUTER,
  STORE_UI,
} from 'Constants/stores';
import { MessagesGetRequest } from 'Interfaces/apiDtos';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import { get, isEmpty } from 'lodash';
import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IPromiseBasedObservable } from 'mobx-utils';
import { ContactModelBase } from 'Models/ContactModel';
import * as React from 'react';
import { isNullOrUndefined } from 'util';
import { resolveConversationPath } from 'Utils/routeNav';
import PrintMessageHeader from '../../components/ContextContentItemsList/PrintMessageHeader';
import withRouter from '../../hocs/WithRouter';
import { PersonModel } from '../../models';
import { ContextContentProps } from './interfaces';

export class ContextContent extends React.Component<ContextContentProps, {}> {
  constructor(props: ContextContentProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const {
      conversation,
      notification,
      ui,
      preference,
      params: { conversationId },
    } = this.props;
    preference.getExistingPreferenceData().then((resp) => {
      this.loadParticipantsAndMessages(conversationId, {
        Limit: 31,
        SortDirection: 'Descending',
        ShowDeleted: true,
        ShowCallMessagesInChat: resp.showCallMessagesInChat,
      });
    });
    notification.closeAllMessageCreatedWebNotifications(conversationId);
    conversation.getPinnedMessages(conversationId);
    const currConvPbo =
      conversation.loadConversationByIdIfMissingGet(conversationId);
    currConvPbo.then((resp) => {
      if (resp && resp.data) {
        // Clear the unread/mention badge counts upon load (but do not actually mark the Messages as read)
        ui.setConversationAndTotalUnreadCount(
          conversationId,
          0,
          currConvPbo,
          0
        );
        window['_currentConvo'] = {
          id: get(resp, 'data.id'),
          grouping: get(resp, 'data.grouping'),
          topic: get(resp, 'data.topic'),
        };
      }
    });
  }

  componentDidCatch(error, info) {
    console.warn('React v18 bug: ', error, info);
  }

  componentDidUpdate(prevProps: ContextContentProps) {
    const {
      conversation,
      message,
      notification,
      participant,
      ui,
      preference,
      params: { conversationId },
    } = this.props;

    const prevConversationId = prevProps.params.conversationId || '';
    const diffConv = conversationId !== prevConversationId;
    if (conversation && (!conversation.CurrentConversation || diffConv)) {
      if (
        !isEmpty(prevConversationId) &&
        conversation.conversationByIdMap.has(prevConversationId)
      ) {
        // Update the previous Conversation's lastReadMessage when a different Conversation is selected
        const prevConvPbo =
          conversation.selectConversationById(prevConversationId);
        prevConvPbo.then((prevConv) => {
          const baseWarnMsg = `ContextContent cDU: Could not update last read Message for previous Conversation ${prevConversationId} when switching to Conversation ${conversationId}`;
          if (!isEmpty(prevConv)) {
            const prevConvMessages =
              message.selectGroupedMessagesForConversation(prevConversationId);
            if (
              !isNullOrUndefined(prevConvMessages) &&
              !isEmpty(prevConvMessages)
            ) {
              ui.setMarkedAsReadMessageId(prevConversationId, null);
              participant
                .updateMyLastReadMessage(
                  prevConversationId,
                  prevConvMessages.NewestMessageId
                )
                .then(() => {
                  ui.setConversationAndTotalUnreadCount(
                    prevConversationId,
                    0,
                    prevConvPbo,
                    0
                  );
                });
            } else {
              console.warn(
                `${baseWarnMsg}: prevConvMessages was null/undefined/empty`
              );
            }
          } else {
            console.warn(baseWarnMsg);
          }
        });
      }
      // Load the Conversation into the `window` for analytics etc.
      if (conversation && conversationId) {
        const currConvPbo =
          conversation.loadConversationByIdIfMissingGet(conversationId);
        currConvPbo.then((resp) => {
          if (resp && resp.data) {
            // Clear the unread/mention badge counts upon load (but do not actually mark the Messages as read)
            ui.setConversationAndTotalUnreadCount(
              conversationId,
              0,
              currConvPbo,
              0
            );
            window['_currentConvo'] = {
              id: get(resp, 'data.id'),
              grouping: get(resp, 'data.grouping'),
              topic: get(resp, 'data.topic'),
            };
          }
        });
      }
    }

    /**
     * This is the primary point in the application where new data is loaded on Conversation change.
     * Ideally in the future, we will move this into the store or something more appropriate.
     */
    if (diffConv) {
      conversation.getPinnedMessages(conversationId);
      this.loadParticipantsAndMessages(conversationId, {
        Limit: 31,
        SortDirection: 'Descending',
        ShowDeleted: true,
        ShowCallMessagesInChat: preference.preferences.showCallMessagesInChat,
      });
      notification.closeAllMessageCreatedWebNotifications(conversationId);
      message.setCreateMessageMentionListOpen(false);
      message.setEditMessageMentionListOpen(false);
    }
  }

  resolveConversationLinkPath = (path: string) => {
    return resolveConversationPath(this.props.location.pathname, path);
  };

  @action
  loadParticipantsAndMessages = (
    conversationId: string,
    messagesGetRequest?: MessagesGetRequest
  ) => {
    (async () => {
      const { message, person, pusher } = this.props;

      await person.waitUntilLoggedIn();
      await pusher.waitUntilPusherConnected();
      await pusher.waitUntilPersonalChannelSubscribed();

      return message.loadConversationMessagesIfMissingGet(
        conversationId,
        messagesGetRequest
      );
    })();
  };

  makeCall = (personId?: number, phone?: string) => {
    const { phoneCall } = this.props;
    phoneCall.callWithPerson(personId, phone);
  };
  toggleEmojiPicker = () => {
    const { ui } = this.props;
    ui.setEmojiPickerState({ open: false, editing: false });
  };

  render() {
    const { message, participant, person, conversation, contact, ui } =
      this.props;
    const conversationId = this.props.params.conversationId;
    const grpMsgContainer =
      message.selectGroupedMessagesForConversation(conversationId);
    const lastReadMessageId =
      participant.selectLoggedInUserParticipantLastReadMessageId(
        conversationId
      );
    const allParticipants =
      participant.selectParticipantsByConversationId(conversationId);
    if (!isNullOrUndefined(grpMsgContainer)) {
      let loggedInUserOwnsNewestMessage = false;
      if (!isNullOrUndefined(grpMsgContainer.NewestMessage)) {
        loggedInUserOwnsNewestMessage =
          grpMsgContainer.NewestMessage.personId === person.loggedInPersonId;
      }
      return (
        <div
          id="context-content"
          className="flex-column flex-grow-shrink"
          onClick={this.toggleEmojiPicker}
        >
          {!isNullOrUndefined(grpMsgContainer) &&
            !isEmpty(grpMsgContainer.SortedGroups) &&
            grpMsgContainer.SortedGroups &&
            conversationId !== '0' ? (
            <ContextContentItemsList
              auth0={person.auth0}
              handleDownloadWithLink={ui.handleDownloadWithLink}
              getFileDownloadLink={message.getFileDownloadLink}
              conversationStore={conversation}
              assignLastScroll={conversation.assignLastScroll}
              callWithPerson={this.makeCall}
              conversationId={conversationId}
              curConversation={conversation.CurrentConversation}
              deleteMessage={message.deleteMessage}
              editMessage={message.editMessage}
              editMessageDraftHtml={message.editMessageDraftHtml}
              editMessageDraftRaw={message.editMessageDraftRaw}
              getCurrentMessages={message.selectGroupedMessagesForConversation}
              getContact={contact.loadContactByPhoneNumberIfMissing}
              getEmojiPickerState={ui.getEmojiPickerState}
              getOrConstructConversationLastScroll={
                conversation.getOrConstructConversationLastScroll
              }
              getPerson={person.selectPersonById}
              isEditingMessageId={message.isEditingMessageId}
              key={conversationId}
              lastReadMessageId={lastReadMessageId}
              loadLinkPreview={message.loadLinkPreview}
              loadMoreConversationMessages={
                message.loadMoreConversationMessages
              }
              loggedInPersonId={person.loggedInPersonId}
              loggedInUserActiveConferenceConversation={
                conversation.LoggedInUserActiveConferenceConversation
              }
              loggedInUserOwnsNewestMessage={loggedInUserOwnsNewestMessage}
              mentionListOpen={message.editMessageMentionListOpen}
              messageMentionFilter={message.editMessageMentionFilter}
              messages={grpMsgContainer}
              messagesWithLinkPreviewHeightCount={
                grpMsgContainer.MessagesWithLinkPreviewHeightCount
              }
              navigateToConferenceByConfId={ui.navigateVideoConferenceToSession}
              newestMessage={grpMsgContainer.NewestMessage}
              newestMessageOwnedByUserId={message.newestMessageOwnedByUserId}
              oldestMessage={grpMsgContainer.OldestMessage}
              resolveConversationLinkPath={this.resolveConversationLinkPath}
              selectedMentionParticipantId={
                message.editMessageMentionSelectedParticipantId
              }
              selectFilteredOtherParticipantPersonsInCurrentConversation={
                participant.selectFilteredOtherParticipantPersonsInCurrentConversation
              } /* Temporarily passing all including self so MD compiler can resolve - RP */
              selectHasRecentActivityWithin={ui.selectHasRecentActivityWithin}
              isBackfillingToReadMessage={message.selectIsBackfillingToReadMessage(
                conversationId
              )}
              selectCanLoadOlderMessages={
                conversation.selectCanLoadOlderMessages
              }
              selectLoadMoreMessagesSkipIds={
                message.selectLoadMoreMessagesSkipIds
              }
              selectMarkedAsReadInfo={ui.selectMarkedAsReadInfo}
              selectEpochMsSinceMarkedAsRead={ui.selectEpochMsSinceMarkedAsRead}
              selectParticipantPersons={participant.selectParticipantPersons}
              selectPersonPresenceStatus={ui.selectPersonPresenceStatus}
              selectUnreadCounts={ui.selectConversationUnreadCounts}
              setEditMessageDraftHtml={message.setEditMessageDraftHtml}
              setEditMessageDraftRaw={message.setEditMessageDraftRaw}
              setIsEditingMessageId={message.setIsEditingMessageId}
              setIsLoadingOlderMessages={conversation.setIsLoadingOlderMessages}
              setEmojiPickerState={ui.setEmojiPickerState}
              setMarkedAsReadInfo={ui.setMarkedAsReadMessageId}
              setMentionListOpen={message.setEditMessageMentionListOpen}
              setMessageMentionFilter={message.setEditMessageMentionFilter}
              setSelectedMentionParticipantId={
                message.setEditMessageMentionSelectedParticipantId
              }
              setConversationAndTotalUnreadCount={
                ui.setConversationAndTotalUnreadCount
              }
              sortedMessageGroups={grpMsgContainer.SortedGroups}
              totalLinkPreviewHeightSum={
                grpMsgContainer.TotalLinkPreviewHeightSum
              }
              totalLinkPreviewImageHeightSum={
                grpMsgContainer.TotalLinkPreviewImageHeightSum
              }
              totalMessageCount={grpMsgContainer.TotalMessageCount}
              totalUnreadCount={grpMsgContainer.TotalUnreadMessageCount}
              updateMyLastReadMessage={participant.updateMyLastReadMessage}
              selectPersonMessageStatus={ui.selectPersonMessageStatus}
              loadPersonByIdGetIfMissingGet={
                person.loadPersonByIdGetIfMissingGet
              }
              selectParticipantsByConversationId={
                participant.selectParticipantsByConversationId
              }
              ui={ui}
              allContacts={person.allContacts}
              getExtrContactByPhoneNumber={person.getExtrContactByPhoneNumber}
              listOfPinnedMessages={ui.listOfPinnedMessages}
              loadContactByPhoneNumberIfMissing={
                contact.loadContactByPhoneNumberIfMissing
              }
            />
          ) : (
            <div>
              {conversation.CurrentConversation?.grouping !== 'Channel' &&
                allParticipants?.case({
                  fulfilled: (resp) => {
                    const personsPBO: Array<
                      IPromiseBasedObservable<
                        AxiosResponseT<PersonModel | ContactModelBase>
                      >
                    > = resp.data.results.map(({ personId, phone }) => {
                      return personId
                        ? person.loadPersonByIdGetIfMissingGet(personId)
                        : contact.loadContactByPhoneNumberIfMissing(phone);
                    });
                    const personsData = personsPBO
                      .filter(Boolean)
                      .map((personPbo) => {
                        return personPbo.state === 'fulfilled'
                          ? personPbo.case({
                              fulfilled: (resp) => resp.data,
                            })
                          : null;
                      })
                      .filter((person) => person);
                    const extrContactResp = resp.data.results.find(
                      (resp) =>
                        resp.personId === undefined && (resp as any)?.phone
                    );
                    const extrContact =
                      extrContactResp &&
                      person.getExtrContactByPhoneNumber(
                        (extrContactResp as any)?.phone
                      );
                    return (
                      <PrintMessageHeader
                        hasMessages={!isEmpty(grpMsgContainer.SortedGroups)}
                        persons={personsData}
                        extrContact={extrContact}
                        extrContactPhone={extrContactResp?.phone}
                        loggedInPerson={person.loggedInPersonId}
                        ui={ui}
                      />
                    );
                  },
                })}
            </div>
          )}
        </div>
      );
    }

    return null;
  }
}

export default inject(
  STORE_PREFERENCE,
  STORE_CONVERSATION,
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_PUSHER,
  STORE_ROUTER,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_UI
)(withRouter(observer(ContextContent)));
