import React from 'react';
import type { ButtonProps } from './types';
import { Styled } from './index.styles';

export const Button: React.FC<ButtonProps> = ({
  testid,
  variant,
  active = false,
  onClick,
  disabled = false,
  ...props
}) => (
  <Styled.SemanticButton
    $variant={variant}
    $active={active}
    data-testid={testid}
    {...(!disabled && { onClick })}
    {...{ disabled, ...props }}
  />
);
