import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { useWindowHeight } from '../../lib/hooks/useWindowHeight';

export type ITheme = typeof theme;

const vh = window.innerHeight * 0.01; // viewport height *  1% = value for a vh unit

export const theme = {
  colors: {
    base: '#ffffff',
    primary: '#0099AD',
    secondary: '#6c7581',
    danger: '#D80028',
    warning: '#F18100',
    success: '#189C66',
    text: '#333333',
    textDarkBackground: '#eeeeee',
    border: '#e6e6e6',
    borderDark: '#808080',
    error: '#FF0000',
    linkHover: '#00899B',
    linkClick: '#007B9B',
    gray: '#999999',
    lightGray: '#F9F9F9',
    secondaryHover: '#38bdcd33',
    buttonSecondaryClick: '#D3D3D3',
    buttonSecondarySelected: '#999999',
    buttonSecondaryBorder: '#B3B3B3',
    darkGray: '#4d4d4d',
    scrollBar: '#cccccc',
  },
  mediaQueries: {
    phone: 'only screen and (min-width: 576px)',
    tabletProtrait: 'only screen and (min-width: 768px)',
    tabletLandscape: 'only screen and (min-width: 1024px)',
    desktop: 'only screen and (min-width: 1280px)',
    bellowPhone: 'only screen and (max-width: 575.98px)',
    onlyPlugin: 'only screen and (max-width: 645px)',
    bellowTabletProtrait: 'only screen and (max-width: 767.98px)',
    bellowTabletLandscape: 'only screen and (max-width: 1023.98px)',
    bellowDesktop: 'only screen and (max-width: 1279.98px)',
  },
  tranisitions: {
    ease: 'all .3s ease-in-out',
  },
  sizes: {
    xxxsmall: '5px',
    xxsmall: '10px',
    xsmall: '15px',
    small: '20px',
    medium: '25px',
  },
  deviceSize: {
    height: `${(vh * 100).toFixed(2)}px`,
  },
} as const;

export const Theme: React.FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  const height = useWindowHeight();
  return (
    <ThemeProvider theme={{ ...theme, deviceSize: { height: `${height}px` } }}>
      {children}
    </ThemeProvider>
  );
};
