import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Header } from 'semantic-ui-react';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { ChannelInfoViewProps } from './interface';

/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ChannelInfoView
 */
export class ChannelInfoView extends React.Component<ChannelInfoViewProps> {
  componentDidMount() {
    const { conversation } = this.props;
    pushToGTMDataLayer('channelInfoView', {
      conversationId: conversation.id,
    });
  }
  render() {
    const { conversation } = this.props;
    return (
      <div data-private className="channel-info-view">
        <Header as="h2" textAlign="center" className="no-vmargin">
          <Header.Content>{conversation.topic}</Header.Content>
          {!isEmpty(conversation.description) && (
            <Header.Subheader>{conversation.description}</Header.Subheader>
          )}
        </Header>
      </div>
    );
  }
}

export default observer(ChannelInfoView);
