import InputDialpad from 'Components/InputDialpad';
import { SEARCHABLE_TYPE } from 'Constants/enums';
import {
  STORE_CONVERSATION,
  STORE_PERSON,
  STORE_SEARCH,
} from 'Constants/stores';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Message } from 'semantic-ui-react';
import TransferDirectoryListItem from '../../components/TransferDirectoryListItem';
import PhoneNumberUtil from '../../utils/phoneUtil';
import phoneUtil from '../../utils/phoneUtil';
import { TransferDirectoryProps } from './interfaces';

const keypadIcon = require('../../assets/images/btn-keypad-gray.svg');
const searchTypes: SEARCHABLE_TYPE[] = [
  'SearchableDetailsPerson',
  'SearchableDetailsContact',
];

export class TransferDirectory extends React.Component<
  TransferDirectoryProps,
  {}
> {
  componentWillMount() {
    const { search } = this.props;
    search.getPersonContactSearch('TRANSFER', '', searchTypes, 100);
  }

  componentWillUnmount() {
    const { phoneCall } = this.props;
    if (phoneCall.rootStore.pusherStore.isOnline) {
      phoneCall.setTransferMode(false);
    }
  }

  isProperPhoneNumber = (number: string) => {
    const phoneNumber = number.replace(/[^0-9+]/g, '');
    return PhoneNumberUtil.isPossibleNumberString(phoneNumber, 'US');
  };

  toggleDialPad = () => {
    const { phoneCall } = this.props;
    phoneCall.toggleDialPad();
  };

  cancelTransfer = () => {
    const { phoneCall } = this.props;
    if (phoneCall.showDialPad) {
      this.toggleDialPad();
    }
    phoneCall.clearKeyPress();
    phoneCall.setTransferMode(false);
    phoneCall.setWarmTransfer(false);
  };

  formatNumber = (num) => {
    if (phoneUtil.isPossibleNumberString(num, 'US')) {
      const parsePhoneNumber = phoneUtil.parse(num, 'US');
      return phoneUtil.format(parsePhoneNumber, PhoneNumberFormat.NATIONAL);
    } else {
      return num;
    }
  };
  render() {
    const {
      conversationId,
      phoneCall,
      search,
      selectPersonPresenceStatus,
      favoriteContactsList,
      selectUnreadCounts,
      setConversationAndTotalUnreadCount,
      updateMyLastReadMessage,
    } = this.props;
    const directory = search.selectDirectorySearchById('TRANSFER');

    if (directory) {
      const validUnknownSearch =
        !isEmpty(directory.query) &&
        this.isProperPhoneNumber(directory.query) &&
        directory.data &&
        directory.data.hits === 0;
      return (
        <>
          <div
            className={
              'search-dialpad-wrapper ' +
              `${phoneCall.showDialPad ? 'visible-dialpad' : 'hidden-dialpad'}`
            }
          >
            <div className="toggle-close-wrapper">
              <img src={keypadIcon} onClick={this.toggleDialPad} />
              <span className="cancel-text" onClick={this.cancelTransfer}>
                CANCEL
              </span>
            </div>
            <InputDialpad search={search} phoneCallModel={phoneCall} />
          </div>
          <div className="transfer-directory-component">
            <span className="title">DIRECTORY</span>
            <div className="wrapper">
              <div className="divider" />
              <ul className="transfer-directory-list negonerem-marginr">
                {validUnknownSearch && (
                  <TransferDirectoryListItem
                    conversationId={conversationId}
                    key={'unknown-number'}
                    unknownUser={true}
                    unknownUserNumber={directory.query}
                    phoneCall={phoneCall}
                    selectPersonPresenceStatus={selectPersonPresenceStatus}
                    selectUnreadCounts={selectUnreadCounts}
                    setConversationAndTotalUnreadCount={
                      setConversationAndTotalUnreadCount
                    }
                    updateMyLastReadMessage={updateMyLastReadMessage}
                    formatNumber={this.formatNumber}
                  />
                )}
                {directory.data && directory.data.hits > 0 ? (
                  directory.data.results.map((item) => (
                    <TransferDirectoryListItem
                      conversationId={conversationId}
                      key={item.source.id}
                      contact={item}
                      phoneCall={phoneCall}
                      favoriteContactsList={favoriteContactsList}
                      selectPersonPresenceStatus={selectPersonPresenceStatus}
                      selectUnreadCounts={selectUnreadCounts}
                      setConversationAndTotalUnreadCount={
                        setConversationAndTotalUnreadCount
                      }
                      updateMyLastReadMessage={updateMyLastReadMessage}
                      formatNumber={this.formatNumber}
                    />
                  ))
                ) : !isEmpty(directory.query) &&
                  !this.isProperPhoneNumber(directory.query) ? (
                  <div>
                    <Message
                      className="no-results-message"
                      visible
                      content={`No results found for "${directory.query}"`}
                    />
                  </div>
                ) : null}
              </ul>
            </div>
          </div>
        </>
      );
    }

    return null;
  }
}

export default inject(
  STORE_PERSON,
  STORE_SEARCH,
  STORE_CONVERSATION
)(observer(TransferDirectory));
