import {
  ACCOUNT_PACKAGE_TYPE,
  CONTACT_TYPE,
  SEARCHABLE_TYPE,
} from 'Constants/enums';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { isEmpty } from 'lodash';
import { computed, observable, makeObservable } from 'mobx';
import { ISearchableItem } from 'Models/SearchResult';
import phoneUtil from '../utils/phoneUtil';

export interface IPersonSignInAccountResponse {
  id: number;
  name: string;
  domain: string;
  timeZone: string;
}

export interface IPersonVideoFeature {
  enabled: boolean;
  expirationDate: string;
}

export interface IPersonSignInLocationResponse {
  id: number;
  name: string;
  timeZone: string;
}

export interface IPersonLineDisplay {
  id: number;
  friendlyName: string;
  callerId: string;
  allowSms?: boolean;
}

export interface IPersonModel extends ISearchableItem {
  locationId: number;
  email: string;
  firstName: string;
  lastName: string;
  timeZone: string;
  package: ACCOUNT_PACKAGE_TYPE;
  canReceiveSMS: boolean;
  mobileNumber: string;
  personalConferenceUrl: string;
  gravatarUrl?: string;
  primaryLineId?: number;
  voicemailInboxId?: number;
  extensionNumber?: string;
  lines?: IPersonLineDisplay[];
  inboundNumbers: any;
  phoneNumber?: string;
  videoTrialEndsAt?: string;
  jobTitle?: string;
  profilePictureUrl?: string;
  department?: string;
  role?: string;
}

export interface IPersonPicture {
  profilePictureUrl: string;
  uploadProfilePictureUrl: string;
}

/**
 * Represents the list of `people` returned by the `/proxy/people` endpoint
 *
 * @export
 * @interface IPeople
 */
export interface IPeople {
  people: IPersonModel[];
}

/**
 * Shape of the response body when `proxy/people/sign_in` is called
 *
 * @export
 * @interface IPersonSignInResponse
 */
export interface IPersonSignInTokenResponse extends IPersonModel {
  account: IPersonSignInAccountResponse;
  location: IPersonSignInLocationResponse;
  token: string;
}

export class PersonLineDisplay implements IPersonLineDisplay {
  @observable
  id: number;

  @observable
  friendlyName: string;

  @observable
  callerId: string;

  @observable
  allowSms?: boolean;

  constructor() {
    makeObservable(this);
  }

  @computed
  get PhoneDisplay() {
    return this.formatNumber();
  }

  private formatNumber = (): string => {
    const phoneNumber = this.callerId;
    if (phoneUtil.isPossibleNumberString(phoneNumber, 'US')) {
      const parsePhoneNumber = phoneUtil.parse(phoneNumber, 'US');
      return phoneUtil.format(parsePhoneNumber, PhoneNumberFormat.NATIONAL);
    } else {
      return phoneNumber;
    }
  };
}

export class PersonModel implements IPersonModel, ISearchableItem {
  @observable
  public id: any;

  /**Contact Information */

  @observable
  public name: string;

  @observable
  public phoneNumber: string;

  @observable
  public contactType: CONTACT_TYPE = 'Person';

  @observable
  public package: ACCOUNT_PACKAGE_TYPE;

  @observable
  public canReceiveSMS: boolean;

  @observable
  public personalConferenceUrl: string;

  @observable
  public emails: string[];

  @observable
  public phoneNumbers: string[];

  @observable
  public videoTrialEndsAt: string;

  @observable
  public companyName: string;

  @observable
  public inboundNumbers: any;
  /** Person Information */
  @observable
  public locationId: number;

  @observable
  public email: string;
  @observable
  public skype: string;

  @observable
  public firstName: string;
  @observable
  public lastName: string;

  @observable
  public timeZone: string;

  @observable
  public mobileNumber: string;

  @observable
  public created: string;

  @observable
  public gravatarUrl?: string;

  @observable
  public primaryLineId?: number;

  @observable
  public voicemailInboxId?: number;

  @observable
  public extensionNumber?: string;

  @observable
  public lines?: PersonLineDisplay[];

  @observable
  public availability: 'Busy' | 'Free';

  @observable
  public jobTitle: string;

  @observable
  public department: string;

  @observable
  public profilePictureUrl: string;

  @observable
  public role: string;
  /** Only present if this is a search result */
  public searchableType?: SEARCHABLE_TYPE;

  @computed
  get DisplayAvatar() {
    let displayAvatar = this.gravatarUrl;
    if (!isEmpty(displayAvatar)) {
      if (displayAvatar !== undefined && displayAvatar.indexOf('?') > 0) {
        displayAvatar = displayAvatar.substring(0, displayAvatar.indexOf('?'));
      }

      displayAvatar += '?d=identicon&s=90';
    } else {
      displayAvatar = 'https://www.gravatar.com/avatar?d=identicon&s=90';
    }
    return displayAvatar;
  }

  @computed
  get DisplayAvatarLg() {
    let displayAvatar = this.gravatarUrl;
    if (!isEmpty(displayAvatar)) {
      if (displayAvatar !== undefined && displayAvatar.indexOf('?') > 0) {
        displayAvatar = displayAvatar.substring(0, displayAvatar.indexOf('?'));
      }

      displayAvatar += '?d=identicon&s=640';
    } else {
      displayAvatar = 'https://www.gravatar.com/avatar?d=identicon&s=640';
    }
    return displayAvatar;
  }
  // needs to be deleted
  @computed
  get DisplayName() {
    let displayName = '';
    switch (this.contactType) {
      case 'Shared':
      case 'Unknown':
      case 'Merged':
        {
          if (this.firstName === undefined && this.lastName === undefined) {
            displayName = this.formatNumber();
          } else {
            displayName = this.getDisplayName();
          }
        }
        break;
      case 'Base':
      case 'Personal':
        {
          displayName = this.formatNumber();
        }
        break;
      case 'Person':
      default:
        {
          displayName = this.getDisplayName();
        }
        break;
    }
    return displayName;
  }
  getDisplayName() {
    return (this.firstName ?? '') + ' ' + (this.lastName ?? '');
  }
  @computed
  get SupportsChat() {
    return (
      this.package === 'Pro' ||
      this.package === 'Xbp_Legacy' ||
      this.package === 'Basic' ||
      this.package === 'Metered'
    );
  }

  @observable
  public formatNumber = (): string => {
    let phoneNumber;
    for (let i = 0; i < this.lines.length; i++) {
      if (this.lines[i].callerId) {
        phoneNumber = this.lines[i].callerId;
      } else {
        phoneNumber = this.primaryLineId.toString();
      }
    }
    if (phoneNumber) {
      if (phoneUtil.isPossibleNumberString(phoneNumber, 'US')) {
        const parsePhoneNumber = phoneUtil.parse(phoneNumber, 'US');
        return phoneUtil.format(parsePhoneNumber, PhoneNumberFormat.NATIONAL);
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  constructor(
    id: number | string,
    locationId: number,
    email: string,
    firstName: string,
    lastName: string,
    timeZone: string,
    mobileNumber: string,
    created: string,
    accountPackageType: ACCOUNT_PACKAGE_TYPE,
    canReceiveSMS: boolean,
    jobTitle: string,
    profilePictureUrl: string,
    role: string,
    department: string,
    personalConferenceUrl: string,
    phoneNumber: string,
    videoTrialEndsAt: string,
    gravatarUrl?: string,
    inboundNumbers?: any,
    primaryLineId?: number,
    voicemailInboxId?: number,
    extensionNumber?: string,
    lines?: PersonLineDisplay[],
    searchableType?: SEARCHABLE_TYPE
  ) {
    makeObservable(this);
    this.id = id;
    this.locationId = locationId;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.timeZone = timeZone;
    this.mobileNumber = mobileNumber;
    this.created = created;
    this.package = accountPackageType;
    this.canReceiveSMS = canReceiveSMS;
    this.jobTitle = jobTitle;
    this.profilePictureUrl = profilePictureUrl;
    this.role = role;
    this.department = department;
    this.personalConferenceUrl = personalConferenceUrl;
    this.phoneNumber = phoneNumber;
    this.videoTrialEndsAt = videoTrialEndsAt;
    this.gravatarUrl = gravatarUrl;
    this.inboundNumbers = inboundNumbers;
    this.primaryLineId = primaryLineId;
    this.voicemailInboxId = voicemailInboxId;
    this.extensionNumber = extensionNumber;
    this.lines = lines;
    this.searchableType = searchableType;
  }
  static FromResponseDto = (responseDto: IPersonModel) => {
    const pm = new PersonModel(
      typeof responseDto.id === 'string'
        ? parseInt(responseDto.id, 10)
        : responseDto.id,
      responseDto.locationId,
      responseDto.email,
      responseDto.firstName,
      responseDto.lastName,
      responseDto.timeZone,
      responseDto.mobileNumber,
      responseDto.created,
      responseDto.package,
      responseDto.canReceiveSMS,
      responseDto.jobTitle,
      responseDto.profilePictureUrl,
      responseDto.role,
      responseDto.department,
      responseDto.personalConferenceUrl,
      responseDto.phoneNumber,
      responseDto.videoTrialEndsAt,
      responseDto.gravatarUrl,
      responseDto.inboundNumbers,
      responseDto.primaryLineId,
      responseDto.voicemailInboxId,
      responseDto.extensionNumber,
      PersonModel.FromLineDisplayContactResponseDto(responseDto.lines),
      responseDto?.searchableType
    );
    return pm;
  };
  static FromLineDisplayContactResponseDto = (
    responseDto: IPersonLineDisplay[]
  ) => {
    const lineDisplay = observable.array<PersonLineDisplay>();

    responseDto?.forEach((response) => {
      const item = new PersonLineDisplay();
      item.allowSms = response.allowSms;
      item.callerId = response.callerId;
      item.friendlyName = response.friendlyName;
      item.id = response.id;
      lineDisplay.push(item);
    });

    return lineDisplay;
  };
}

export default PersonModel;
