import { FeatureFlagProps } from './interfaces';
import { isFlagInPermissions } from '../../utils/featureFlags';
import React, { ReactNode, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { STORE_PERSON } from '../../constants/stores';

export const FeatureFlag = inject(STORE_PERSON)(
  observer(
    ({ person, flag, fallback, children }: FeatureFlagProps): JSX.Element => {
      const [componentToRender, setComponentToRender] =
        useState<ReactNode>(null);

      useEffect(() => {
        const checkFeatureFlag = async () => {
          const enabled: boolean | undefined =
            person?.auth0 && (await isFlagInPermissions(person?.auth0, flag));
          setComponentToRender(enabled ? children : fallback);
        };

        !person?.refreshingToken && checkFeatureFlag();
      }, [person?.refreshingToken]);

      return <>{componentToRender}</>;
    }
  )
);
