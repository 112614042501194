import { observer } from 'mobx-react';
import MessageModel from 'Models/MessageModel';
import * as React from 'react';
import { IS_ELECTRON } from '../../constants/env';
import { electronOpenExternal } from '../../utils/electronHelpers';
const linkIcon = require('../../assets/images/link-icon.png');

export interface ILinkPreviewProps {
  /**
   * Forces re-render when switched to true.
   * This is provided because `messageModel` is by reference and will not trigger
   * observer updates when the `linkPreview` changes.
   */
  hasLinkPreview: boolean;
  messageModel: MessageModel;
}

export const LinkPreview = ({
  hasLinkPreview,
  messageModel,
}: ILinkPreviewProps) => {
  const lpRef = React.useRef<HTMLAnchorElement>();
  const imgRef = React.useRef<HTMLImageElement>();
  const onImgLoad = React.useCallback(() => {
    messageModel.setLinkPreviewImageDimensions({
      imageWidth: imgRef.current.clientWidth,
      imageHeight: imgRef.current.clientHeight,
      width: lpRef.current.clientWidth,
      height: lpRef.current.clientHeight,
    });
  }, []);

  const onImgLoadError = React.useCallback(() => {
    messageModel.setLinkPreviewImageDimensions({
      imageWidth: 0,
      imageHeight: 0,
      width: lpRef.current.clientWidth,
      height: lpRef.current.clientHeight,
      imageLoadFailed: true,
    });
  }, []);

  const openLink = (link: string) => () => {
    window.open(link);
  };
  const validUrl: boolean =
    messageModel?.linkPreview?.url.toLowerCase().includes('https') ||
    messageModel?.linkPreview?.url.toLowerCase().includes('http');
  let shortenUrl;
  if (validUrl) {
    shortenUrl = new URL(messageModel?.linkPreview?.url).hostname.replace(
      'www.',
      ''
    );
  }

  return messageModel.linkPreview !== null && validUrl ? (
    <a
      className="link-preview"
      ref={lpRef}
      data-ext-url={messageModel.linkPreview.url}
      onClick={
        IS_ELECTRON
          ? electronOpenExternal
          : openLink(messageModel.linkPreview.url)
      }
    >
      <img className="link-icon" src={linkIcon} />
      {messageModel.linkPreview.image && (
        <div
          className="image"
          style={{ backgroundImage: `url(${messageModel.linkPreview.image})` }}
        >
          <img
            src={messageModel.linkPreview.image}
            ref={imgRef}
            onLoad={onImgLoad}
            onError={onImgLoadError}
          />
        </div>
      )}
      <div className="box">
        <span className="link">{shortenUrl}</span>
        {messageModel.linkPreview.title && (
          <div className="title">{messageModel.linkPreview.title}</div>
        )}
        {messageModel.linkPreview.description && (
          <div className="description">
            {messageModel.linkPreview.description}
          </div>
        )}
      </div>
    </a>
  ) : null;
};

export default observer(LinkPreview);
