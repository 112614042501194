import { get, isEmpty } from 'lodash';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Contact } from 'Models/Contacts';
import * as React from 'react';
import { List, Loader, Message, Popup } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import {
  formatNumberNoPlusIfUS,
  formatNumberWithNationalCode,
} from '../../utils/phoneUtil';
import { ActivityItemInfoDisplayProps } from './interfaces';

export class ActivityItemInfoDisplay extends React.Component<
  ActivityItemInfoDisplayProps,
  {}
> {
  render() {
    const {
      conversation,
      participants,
      getExtrContactByPhoneNumber,
      contactsByPhone,
      addProperName,
      getExtrContactFromPboList,
      selectParticipantPersonInfo,
      from,
      loggedInPersonId,
      selectUnreadCounts,
      ui,
      getOrSetExtrContactByPhoneNumber,
    } = this.props;
    let boldClass;
    let listOfContactsPromises;
    if (selectUnreadCounts(conversation.id).unreadMessages > 0) {
      boldClass = 'groupBold';
    }
    if (
      (conversation && conversation.grouping === 'Channel') ||
      conversation.grouping === 'Group'
    ) {
      if (
        (conversation.topic?.includes('Group') || conversation.topic == "") &&
        conversation.grouping === 'Group'
      ) {
        runInAction(
          () => (conversation.topic = addProperName(conversation.participants))
        );
        listOfContactsPromises = conversation.participants.map(
          (participant) => {
            return getOrSetExtrContactByPhoneNumber(participant.phone);
          }
        );
        return (
          <div data-private className={`display-name ${boldClass}`}>
            {
              listOfContactsPromises.map((listOfContactsPromise) => {
                if (!listOfContactsPromise) return conversation.topic;
                return listOfContactsPromise.case({
                  pending: () => <Loader indeterminate active size="tiny" />,
                  fulfilled: (resp) => {
                    return addProperName(conversation.participants);
                  },
                })
              })
            }
          </div>
        );
      }
      return (
        <div data-private className={`display-name ${boldClass}`}>
          {conversation.topic}
        </div>
      );
    } else if (conversation && conversation.grouping === 'OneOnOne') {
      const otherPtc = participants.filter(
        (p) => p.personId !== loggedInPersonId
      )[0];
      const mySelfPtc = participants.filter(
        (p) => p.personId === loggedInPersonId
      )[0];
      if (otherPtc !== undefined) {
        if (!isEmpty(otherPtc.phone)) {
          const phoneNumNoPlus = formatNumberNoPlusIfUS(otherPtc.phone);
          const phoneNumWithNationalCode = formatNumberWithNationalCode(
            otherPtc.phone
          );
          if (contactsByPhone.has(phoneNumNoPlus)) {
            const otherPtcContact = contactsByPhone.get(phoneNumNoPlus);
            if (!isEmpty(otherPtcContact)) {
              return (
                <div data-private className={`display-name ${boldClass}`}>
                  {otherPtcContact.case({
                    fulfilled: (response) => {
                      const extrContact = getExtrContactByPhoneNumber(
                        response.data.phoneNumber
                      );
                      let displayName = extrContact
                        ? extrContact.DisplayName()
                        : get(response, 'data.DisplayName', '');
                      if (!extrContact) {
                        const extrContactPbo =
                          getExtrContactFromPboList(phoneNumNoPlus);
                        if (extrContactPbo) {
                          return extrContactPbo.case({
                            pending: () => (
                              <Loader indeterminate active size="tiny" />
                            ),
                            fulfilled: (resp) => {
                              const extrContact =
                                resp.data?.items &&
                                resp.data.items[0] &&
                                new Contact(resp.data.items[0]);
                              if (extrContact) {
                                return (
                                  <div
                                    data-private
                                    className={`display-name ${boldClass}`}
                                  >
                                    {extrContact.DisplayName()}
                                  </div>
                                );
                              }
                              if (!displayName) {
                                // Worst case scenario we don't know the name
                                displayName = conversation.topic;
                              }
                              return (
                                <div
                                  data-private
                                  className={`display-name ${boldClass}`}
                                >
                                  {phoneNumWithNationalCode || displayName}
                                </div>
                              );
                            },
                          });
                        }
                      }
                      return (
                        <div
                          data-private
                          className={`display-name ${boldClass}`}
                        >
                          {displayName ||
                            phoneNumWithNationalCode ||
                            conversation.topic}
                        </div>
                      );
                    },
                    pending: () => <Loader indeterminate active size="tiny" />,
                    rejected: () => (
                      <div data-private className={`display-name ${boldClass}`}>
                        {phoneNumWithNationalCode}
                      </div>
                    ),
                  })}
                </div>
              );
            } else {
              return <Loader indeterminate active size="tiny" />;
            }
          } else {
            const extrContactPbo = getExtrContactFromPboList(phoneNumNoPlus);
            if (extrContactPbo) {
              return extrContactPbo.case({
                pending: () => <Loader indeterminate active size="tiny" />,
                fulfilled: (resp) => {
                  const extrContact =
                    resp.data?.items &&
                    resp.data.items[0] &&
                    new Contact(resp.data.items[0]);
                  return (
                    <div data-private className={`display-name ${boldClass}`}>
                      {extrContact
                        ? extrContact.DisplayName()
                        : phoneNumWithNationalCode}
                    </div>
                  );
                },
              });
            }
            return (
              <div data-private className={`display-name ${boldClass}`}>
                {phoneNumWithNationalCode}
              </div>
            );
          }
        } else if (isFinite(otherPtc.personId)) {
          const otherPtcPerson = selectParticipantPersonInfo(otherPtc.personId);
          if (!isEmpty(otherPtcPerson)) {
            return (
              <div className="aiid-person">
                {otherPtcPerson.case({
                  fulfilled: (response) => {
                    let displayName = get(response, 'data.DisplayName', '');
                    if (!displayName) {
                      // Worst case scenario we don't know the name
                      displayName = conversation.topic;
                    }

                    return (
                      <>
                        <div
                          data-private
                          className={`display-name ${boldClass}`}
                        >
                          {displayName}
                        </div>
                        {from !== 'group-info' && (
                          <List.Content className="flex-shrink">
                            <Popup
                              inverted
                              trigger={
                                <span className="message-status">
                                  {ui.selectPersonMessageStatus(
                                    response.data.id
                                  )?.title || ''}
                                </span>
                              }
                              content={
                                ui.selectPersonMessageStatus(response.data.id)
                                  ?.message || ''
                              }
                              position={'top right'}
                            />
                          </List.Content>
                        )}
                      </>
                    );
                  },
                  pending: () => <Loader indeterminate active size="tiny" />,
                  rejected: () => (
                    <div data-private className="display-name color-removed">
                      Person {otherPtc.personId}
                    </div>
                  ),
                })}
              </div>
            );
          } else {
            return <Loader indeterminate active size="tiny" />;
          }
        } else {
          console.error('Empty personId and phone:', otherPtc);
          return (
            <Message
              visible
              error
              content={
                'Error: Empty personId and phone for Participant ' + otherPtc.id
              }
            />
          );
        }
      } else if (!isNullOrUndefined(mySelfPtc)) {
        if (isFinite(mySelfPtc.personId)) {
          const mySelfPtcPerson = selectParticipantPersonInfo(
            mySelfPtc.personId
          );
          if (!isEmpty(mySelfPtcPerson)) {
            return (
              <div className="aiid-person">
                {mySelfPtcPerson.case({
                  fulfilled: (response) => {
                    let displayName = get(response, 'data.DisplayName', '');
                    if (!displayName) {
                      // Worst case scenario we don't know the name
                      displayName = conversation.topic;
                    }

                    return (
                      <>
                        <div
                          data-private
                          className={`display-name ${boldClass}`}
                        >
                          {displayName}
                        </div>
                        {from !== 'group-info' && (
                          <List.Content className="flex-shrink">
                            <Popup
                              inverted
                              trigger={
                                <span className="message-status">
                                  {ui.selectPersonMessageStatus(
                                    response.data.id
                                  )?.title || ''}
                                </span>
                              }
                              content={
                                ui.selectPersonMessageStatus(response.data.id)
                                  ?.message || ''
                              }
                              position={'top right'}
                            />
                          </List.Content>
                        )}
                        {from !== 'group-info' && (
                          <div className="display-loggedInUser">(You)</div>
                        )}
                      </>
                    );
                  },
                  pending: () => <Loader indeterminate active size="tiny" />,
                  rejected: () => (
                    <div data-private className="display-name color-removed">
                      Person {mySelfPtc.personId}
                    </div>
                  ),
                })}
              </div>
            );
          } else {
            return <Loader indeterminate active size="tiny" />;
          }
        }
      } else {
        const msg = 'Other Participant not found!';
        console.error(msg);
        return <Message visible error content={msg} />;
      }
    } else {
      return null;
    }
  }
}
export default observer(ActivityItemInfoDisplay);
