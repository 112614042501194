import { History } from 'history';
import { AllRouteParams, IConversationRouteParams } from 'Interfaces/routing';
import { isEmpty } from 'lodash';
import {
  RouterStore as BaseRouterStore,
  syncHistoryWithStore,
  SynchronizedHistory,
} from 'mobx-react-router';
import { matchPath } from 'react-router';
import { RootStore } from 'Stores/RootStore';
import { ROUTE_PATHS } from 'Constants/routing';
import { computed } from 'mobx';

export class RouterStore extends BaseRouterStore {
  rootStore: RootStore;
  // @ts-ignore
  history: SynchronizedHistory;
  lastConversationId: string;

  constructor(history: History, rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
    this.history = syncHistoryWithStore(history, this);
  }

  @computed
  get currentPath() {
    // @ts-ignore
    return this.location?.location?.pathname || window.location.pathname;
  }

  matchConversation = () => {
    return matchPath<AllRouteParams>(
      this.currentPath,
      ROUTE_PATHS.conversation
    );
  };

  /**
   * Returns current conversation id -- if in a conversation; otherwise null.
   */
  currentConversationId = () => {
    const match = this.matchConversation();
    if (match) {
      const { conversationId: currentConversationId } =
        match.params as IConversationRouteParams;
      this.lastConversationId = currentConversationId;
      return currentConversationId;
    } else if (!isEmpty(this.lastConversationId)) {
      return this.lastConversationId;
    } else {
      return '';
    }
  };
}

export default RouterStore;
