import cx from 'classnames';
import { UserAvatar } from 'Components/shared/UserAvatar';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { AtMentionMemberItemProps } from './interfaces';

/**
 * List item display of a `Participant` in a `Channel`, who can be Mentioned.
 *
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ChannelMembers
 */
export class AtMentionMemberItem extends React.Component<AtMentionMemberItemProps> {
  /** A map of Presence status keys, with display names as values */
  statusDisplayNames = {
    Active: 'Available',
    Idle: 'Idle',
    Away: 'Away',
    OnCall: 'On Call',
    OffLine: 'Offline',
  };
  onClick = (e: React.MouseEvent) => {
    const { onMentionClick, participantPerson } = this.props;
    e.preventDefault();
    e.stopPropagation();
    onMentionClick(participantPerson.person.id.toString());
    return false;
  };

  componentDidUpdate(prevProps: AtMentionMemberItemProps) {
    if (
      this.props.isSelected &&
      this.props.isSelected !== prevProps.isSelected
    ) {
      const itemDomNode = ReactDOM.findDOMNode(this.itemRef) as HTMLDivElement;
      itemDomNode.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }

  itemRef: React.Component<DropdownItemProps>;
  private registerRef = (di: React.Component<DropdownItemProps>) =>
    (this.itemRef = di);

  render() {
    const {
      isSelected,
      participantPerson,
      selectPersonPresenceStatus,
      selectUnreadCounts,
      setConversationAndTotalUnreadCount,
    } = this.props;
    const presenceStatus = selectPersonPresenceStatus(
      participantPerson.person.id
    );
    const prsIdStr = participantPerson.person.id.toString();
    return (
      <Dropdown.Item
        className={cx('user-mention-row flex-row', { selected: isSelected })}
        onClick={this.onClick}
        id={`ammi-${prsIdStr}`}
        key={prsIdStr}
        ref={this.registerRef}
      >
        <div className="avatar user-mention-avatar">
          <UserAvatar
            name={participantPerson.person.DisplayName}
            selectUnreadCounts={selectUnreadCounts}
            setConversationAndTotalUnreadCount={
              setConversationAndTotalUnreadCount
            }
            presence={selectPersonPresenceStatus(participantPerson.person.id)}
          />
        </div>
        <div className="presence" data-value={participantPerson.person.id}>
          <div
            className="mention-text-wrapper"
            data-value={participantPerson.person.id}
          >
            <div className="name" data-value={participantPerson.person.id}>
              {participantPerson.person.DisplayName}
            </div>
            <span
              className={cx('text', presenceStatus.state)}
              data-value={participantPerson.person.id}
            >
              {this.statusDisplayNames[presenceStatus.state]}
            </span>
          </div>
        </div>
      </Dropdown.Item>
    );
  }
}

export default observer(AtMentionMemberItem);
