import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Button, Grid, Icon, Message } from 'semantic-ui-react';
import { ModalMessageStatus } from '../../ModalMessageStatus';
import type { TopBarProps, TopBarUserProps } from '../interfaces';

import { UserDropdownMenu } from './UserDropdownMenu';
import { TopBarSipConnectionStatus } from './TopBarSipConnectionStatus';
import { TopBarProfileStatusButton } from './TopBarProfileStatusButton';
import withRouter from '../../../hocs/WithRouter';
import {
  IS_BETA_ENV,
  COMMUNICATOR_CURRENT_VERSION_URL,
} from '../../../constants/env';
import { Styled } from './index.styles';
import { IconSvg } from '../../shared/Icon';
import { bugsnagClient } from '../../../utils/logUtils';

class TopBarUser extends React.Component<TopBarProps & TopBarUserProps> {
  constructor(props: TopBarProps & TopBarUserProps) {
    super(props);
    makeObservable(this);
  }

  @observable
  showMessageStatusModal: boolean = false;

  @action
  toggleShowMessageStatusModal = () => {
    this.showMessageStatusModal = !this.showMessageStatusModal;
  };

  @action
  toggleShowMessageSettingsModal = () => {
    this.props.navigate('/settings');
  };

  handleClickDropDown = (toggleFunc: () => void) => {
    const { ui } = this.props;
    toggleFunc();
    ui.setHeaderDropdown(false);
  };
  handleToggleHeader = () =>
    this.props.ui.setHeaderDropdown(!this.props.ui.showHeaderDropdown);

  handleSipReconnection = () => this.props.phoneCall.tryToConnectAgainToSip();

  handleLogoutClick = async () => {
    await this.props.person.logout();
    this.props.auth0.logout({ returnTo: window.location.origin });
  };

  handleRefreshTokenClick = async () => {
    try {
      this.props.person?.setRefreshingToken(true);
      await this.props.auth0.getAccessTokenSilently({
        ignoreCache: true,
      });
    } catch (error) {
      bugsnagClient.notify(error, (event) => {
        event.severity = 'error';
        event.context = 'handleRefreshTokenClick';
        event.addMetadata('custom', {
          function: 'getAccessTokenSilently',
        });
      });
      this.props.auth0.logout();
    }
    this.props.person?.setRefreshingToken(false);
  };

  render() {
    const {
      notification,
      person,
      preference,
      phoneCall,
      ui,
      message,
      testid: parentTestid,
    } = this.props;
    const personPbo = person.selectPersonById(person.loggedInPersonId);
    const testid = `${parentTestid}-topBarUser`;
    const presence = ui.selectPersonPresenceStatus(person.loggedInPersonId);
    const messageStatus = ui.selectPersonMessageStatus(person.loggedInPersonId);

    return (
      <Grid.Column className="profile-menu-wrapper">
        <Grid.Column className="app-header-user">
          <Styled.RightHeaderWrapper>
            {IS_BETA_ENV && (
              <Styled.BetaRef
                href={COMMUNICATOR_CURRENT_VERSION_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Styled.BetaButton>
                  <Styled.BetaIcon>{IconSvg['resync']}</Styled.BetaIcon>
                  <Styled.BetaText>Switch to current version</Styled.BetaText>
                </Styled.BetaButton>
              </Styled.BetaRef>
            )}
            {personPbo?.case({
              pending: () => <strong>Loading...</strong>,
              rejected: () => (
                <Message visible error content="Failed to load Person " />
              ),
              fulfilled: (resp) => (
                <>
                  <TopBarSipConnectionStatus
                    {...{ testid }}
                    handleSipReconnection={this.handleSipReconnection}
                    isWebRTCConnected={phoneCall.isWebRTCConnected}
                  />
                  <TopBarProfileStatusButton
                    {...{ testid }}
                    selectConversationUnreadCounts={
                      ui.selectConversationUnreadCounts
                    }
                    handleToggleHeader={this.handleToggleHeader}
                    data={resp.data}
                    presence={{ ...presence }}
                    status={messageStatus}
                  />
                  <UserDropdownMenu
                    {...{
                      setHeaderDropdown: ui.setHeaderDropdown,
                      showHeaderDropdown: ui.showHeaderDropdown,
                      handleClickDropDown: this.handleClickDropDown,
                      toggleShowMessageSettingsModal:
                        this.toggleShowMessageSettingsModal,
                      toggleShowMessageStatusModal:
                        this.toggleShowMessageStatusModal,
                      handleLogoutClick: this.handleLogoutClick,
                      handleRefreshTokenClick: this.handleRefreshTokenClick,
                      testid,
                    }}
                  />
                </>
              ),
            })}
          </Styled.RightHeaderWrapper>
          <ModalMessageStatus
            personStore={person}
            message={message}
            uiStore={ui}
            open={this.showMessageStatusModal}
            onClose={this.toggleShowMessageStatusModal}
            trigger={
              <Button
                className="no-hpadding"
                onClick={this.toggleShowMessageStatusModal}
                compact
                basic
                inverted
              >
                <Icon
                  className="mdi mdi-settings no-hmargin"
                  fitted
                  size="large"
                />
              </Button>
            }
            size="tiny"
          />
        </Grid.Column>
      </Grid.Column>
    );
  }
}

export default withRouter(observer(TopBarUser));
