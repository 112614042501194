import {
  STORE_CALENDAR,
  STORE_CONVERSATION,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_ROUTER,
  STORE_UI,
} from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import { IEvent } from 'Models/Calendar';
import React, { useEffect, useState } from 'react';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CalendarEvent } from '../CalendarEvent';
import { SelectedEvent } from '../SelectedEvent';
import type { CalendarComponentProps } from './types';
import { RemoveEventModal } from './RemoveEventModal';
import { DnDCalendar } from './DnDCalendar';
import { Styled } from './index.styles';
import { TextButton } from 'Components/shared/TextButton';
import cx from 'classnames';

export const CalendarComponent = inject(
  STORE_CALENDAR,
  STORE_PERSON,
  STORE_CONVERSATION,
  STORE_PHONE_CALL,
  STORE_UI,
  STORE_ROUTER
)(observer(({
  calendar,
  person,
  ui,
  router,
  conversation,
  phoneCall,
  search,
  testId: parentTestId
}: CalendarComponentProps) => {
  const testId = `${parentTestId}-calendarComponent`
  const [eventForRemoval, setEventForRemoval] = useState(null);
  const [isRemoveEventModalOpened, setIsRemoveEventModalOpened] = useState(false);
  const timeOutCloseMessage = () => {
    const timeout = setTimeout(() => {
      closeSuccessMessage();
      clearTimeout(timeout);
    }, 5000);
  };

  const toogleCreateEventModal = (e, showModal?: boolean) => {
    e?.stopPropagation();

    calendar.setSelectedEvent(null);
    calendar.setEventModal(showModal);
    calendar.setSuggestionNull();
  };

  const toogleEventPopup = (event: IEvent, e: any) => {
    const allowedIds = [
      'close',
      'calendar-event',
      'remove',
      'no',
      'yes',
      'join',
    ];

    if (event || allowedIds.includes(e.target.id)) {
      calendar.setSelectedEvent(
        calendar.selectedEvent ? null : event
      );
    }
  };

  const toggleRemoveModal = (eventData: IEvent, e) => {
    const allowedIds = ['close', 'calendar-event', 'remove', 'yes', 'no'];

    if (allowedIds.includes(e.target.id)) {
      setIsRemoveEventModalOpened(true)
      setEventForRemoval(eventData);
    }
  };

  const closeSuccessMessage = () =>
    calendar.setSuccessMessageRespond({
      show: false,
      eventTitle: '',
      message: '',
    });

  const calendarEvents = calendar.allEvents.filter(
    (event) => !event.recurrence && !event.recurrence?.rrule
  );

  useEffect(() => {
    if (calendar.successMessageRespond.show) {
      timeOutCloseMessage();
    }
  }, [calendar.successMessageRespond.show]);

  return (
    <div className={cx('calendar', { flexGrow: 'flex-grow', })}>
      <DnDCalendar
        calendarEvents={calendarEvents}
        calendar={calendar}
        person={person}
        toogleEventPopup={toogleEventPopup}
        toogleCreateEventModal={toogleCreateEventModal}
        testId={testId}
      />

      {calendar.openEventModal && (
        <CalendarEvent
          search={search}
          phoneCall={phoneCall}
          ui={ui}
          person={person}
          router={router}
          conversation={conversation}
          showModal={calendar.openEventModal}
          calendar={calendar}
          toogleCreateEventModal={toogleCreateEventModal}
          testId={testId}
        />
      )}

      {calendar.selectedEvent && !calendar.openEventModal && (
        <SelectedEvent
          setEventModal={calendar.setEventModal}
          loggedInEmail={person.loggedInEmail}
          checkIsOwnerLoggedIn={calendar.checkIsOwnerLoggedIn}
          setSuccessMessageRespond={calendar.setSuccessMessageRespond}
          navigateVideoConferenceToSession={
            ui.navigateVideoConferenceToSession
          }
          handleEventRespond={calendar.handleEventRespond}
          setSelectedEvent={calendar.setSelectedEvent}
          selectedEventData={calendar.selectedEvent}
          toogleEventPopup={toogleEventPopup}
          toggleRemoveModal={toggleRemoveModal}
          testId={testId}
        />
      )}

      {eventForRemoval && (
        <RemoveEventModal
          calendar={calendar}
          isRemoveEventModalOpened={isRemoveEventModalOpened}
          eventForRemoval={eventForRemoval}
          handleRemoveEventModalClose={() => {
            setIsRemoveEventModalOpened(false)
          }}
          testId={testId}
        />
      )}

      {calendar.successMessageRespond.show && (
        <div
          className={`message-respond ${calendar.successMessageRespond.message === 'Accepted'
            ? 'success-message-respond'
            : 'declined-message-respond'
            }`}
        >
          {' '}
          {calendar.successMessageRespond.message} the event{' '}
          {calendar.successMessageRespond.eventTitle}

          <Styled.CloseButtonContainer>
            <TextButton onClick={closeSuccessMessage} testid={`${testId}-buttonClose`}>
              Close
            </TextButton>
          </Styled.CloseButtonContainer>
        </div>
      )}
    </div>
  );
}))
