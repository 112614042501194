import { BulkheadRejectedError, Policy } from 'cockatiel';
import { LOAD_DIRECTION } from 'Constants/enums';
import {
  BV_ENV_LOCAL_OR_DEVELOPMENT,
  NODE_ENV,
  TIMEZONE_IDENTIFIER,
} from 'Constants/env';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import { IManageLastScrollAndBackfill } from 'Interfaces/components';
import _, { get, isEmpty } from 'lodash';
import { action, observable, toJS, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { IPromiseBasedObservable, now } from 'mobx-utils';
import { ContactModelBase } from 'Models/ContactModel';
import { LastScroll } from 'Models/ConversationLastScroll';
import { GroupedMessagesContainer } from 'Models/GroupedMessagesContainer';
import moment from 'moment-timezone';
import * as React from 'react';
import useStayScrolled from 'react-stay-scrolled';
import { Feed, Icon, Modal } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { bugsnagClient } from '../../utils/logUtils';
import { PersonModel } from '../../models';
import ContextContentItemObserver from '../ContextContentItem';
import ChatContentItem from '../ChatContentItem';
import type {
  ContextContentItemsListProps,
  IContextContentItemsGroupProps,
} from './interfaces';
import PrintMessageHeader from './PrintMessageHeader';
import { useParams } from 'react-router-dom';
import { isFlagInPermissions } from '../../utils/featureFlags';
import { IS_V2_ENABLED } from '../../constants/featureFlags';
import { ChatDivider } from './ChatDivider';

const isLocalDev = NODE_ENV === 'local' || NODE_ENV === 'development';

/**
 * **NOT AN OBSERVER.** This is intentional, it is designed to be a dumb component to support React Hooks.
 * @param props
 */
export function ContextContentItemsList(props: ContextContentItemsListProps) {
  const {
    assignLastScroll,
    callWithPerson,
    curConversation,
    deleteMessage,
    editMessage,
    editMessageDraftHtml,
    editMessageDraftRaw,
    getContact,
    getCurrentMessages,
    getEmojiPickerState,
    getOrConstructConversationLastScroll,
    getPerson,
    isBackfillingToReadMessage,
    isEditingMessageId,
    loadLinkPreview,
    loadMoreConversationMessages,
    loggedInPersonId,
    loggedInUserActiveConferenceConversation,
    mentionListOpen,
    messages,
    messagesWithLinkPreviewHeightCount,
    messageMentionFilter,
    navigateToConferenceByConfId,
    newestMessageOwnedByUserId,
    resolveConversationLinkPath,
    selectedMentionParticipantId,
    selectFilteredOtherParticipantPersonsInCurrentConversation,
    selectHasRecentActivityWithin,
    selectCanLoadOlderMessages,
    selectLoadMoreMessagesSkipIds,
    selectMarkedAsReadInfo,
    selectEpochMsSinceMarkedAsRead,
    selectParticipantPersons,
    selectPersonMessageStatus,
    selectPersonPresenceStatus,
    selectUnreadCounts,
    setConversationAndTotalUnreadCount,
    setEditMessageDraftHtml,
    setEditMessageDraftRaw,
    setEmojiPickerState,
    setIsEditingMessageId,
    setIsLoadingOlderMessages,
    setMarkedAsReadInfo,
    setMentionListOpen,
    setMessageMentionFilter,
    setSelectedMentionParticipantId,
    sortedMessageGroups,
    totalLinkPreviewHeightSum,
    totalMessageCount,
    totalUnreadCount,
    updateMyLastReadMessage,
    loadPersonByIdGetIfMissingGet,
    loadContactByPhoneNumberIfMissing,
    listOfPinnedMessages,
    selectParticipantsByConversationId,
    conversationStore,
    getFileDownloadLink,
    handleDownloadWithLink,
    ui,
    auth0,
    getExtrContactByPhoneNumber,
  } = props as ContextContentItemsListProps;
  const conversationId = useParams().conversationId;
  const listRef = React.useRef<HTMLDivElement>();
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [lastMessageCount, setLastMessageCount] =
    React.useState<number>(totalMessageCount);
  const [lastUnreadCount, setLastUnreadCount] =
    React.useState<number>(totalUnreadCount);
  const [lastLinkPreviewHeightSum, setLastLinkPreviewHeightSum] =
    React.useState<number>(totalLinkPreviewHeightSum);
  const [
    lastMessagesWithLinkPreviewHeightCount,
    setLastMessagesWithLinkPreviewHeightCount,
  ] = React.useState<number>(messagesWithLinkPreviewHeightCount);
  const initCLS = toJS(getOrConstructConversationLastScroll(conversationId));
  const [scrolledToBottomOnFirstLoad, setScrolledToBottomOnFirstLoad] =
    React.useState<boolean>(initCLS.scrollHeight !== 0);
  const [userIsScrolling, setUserIsScrolling] = React.useState<boolean>(false);
  const { scroll, scrollBottom, stayScrolled, isScrolled } = useStayScrolled(
    listRef,
    { initialScroll: initCLS.scrollTop || Infinity, inaccuracy: 250 }
  );

  const onScroll = React.useCallback(() => {
    onCCILScrollBulkhead(
      listRef,
      {
        assignLastScroll,
        conversationId,
        getCurrentMessages,
        getOrConstructConversationLastScroll,
        loadMoreConversationMessages,
        isBackfillingToReadMessage,
        selectLoadMoreMessagesSkipIds,
        selectCanLoadOlderMessages,
        setIsLoadingOlderMessages,
        lastUnreadCount,
        scrolledToBottomOnFirstLoad,
        userIsScrolling,
        setUserIsScrolling,
      },
      scroll
    );
  }, [lastUnreadCount, scrolledToBottomOnFirstLoad, userIsScrolling]);
  // Typically you will want to use stayScrolled or scrollBottom inside
  // useLayoutEffect, because it measures and changes DOM attributes (scrollTop) directly
  React.useLayoutEffect(() => {
    const conversationLastScroll = toJS(
      getOrConstructConversationLastScroll(conversationId)
    );
    // if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
    //     console.debug('unread/totalMessage layoutEffect ', JSON.stringify({ initialized, lastUnreadCount, lastMessageCount }), 'totalUnreadCount', totalUnreadCount, 'totalMessageCount', totalMessageCount, `|| conversationLastScroll ${JSON.stringify(conversationLastScroll)} || initialRefScroll ${JSON.stringify(initialRefScroll)}`);
    // }
    // Initialize scroll position on mount
    if (!initialized) {
      if (totalUnreadCount === 0 && conversationLastScroll.scrollHeight === 0) {
        requestAnimationFrame(() => {
          // console.debug('layout initialize scrollBottom');
          scrollBottom();
          assignLastScroll(conversationId, {
            clientHeight: listRef.current.clientHeight,
            scrollHeight: listRef.current.scrollHeight,
            scrollTop: listRef.current.scrollTop,
          });
          setInitialized(true);
        });
      }
      // Already had a `conversationLastScroll` entry
      else if (
        totalUnreadCount === 0 &&
        conversationLastScroll.scrollHeight > 0
      ) {
        requestAnimationFrame(() => {
          // console.debug('layout had lastscroll next scrollTop', conversationLastScroll.scrollTop);
          scroll(conversationLastScroll.scrollTop);
          assignLastScroll(conversationId, {
            clientHeight: listRef.current.clientHeight,
            scrollHeight: listRef.current.scrollHeight,
            scrollTop: listRef.current.scrollTop,
          });
          setInitialized(true);
        });
      } else {
        // console.debug('layout default initialize with unreads');
        setInitialized(true);
      }
    }
    // Handle changes to unread/message count, link preview height sum
    else {
      if (
        totalMessageCount > lastMessageCount ||
        totalUnreadCount < lastUnreadCount
      ) {
        //  && newestMsgId === newestOwnedMsgId
        requestAnimationFrame(() => {
          // console.debug('layout stayScrolled', 'totalMessageCount > lastMessageCount', totalMessageCount > lastMessageCount, '(totalUnreadCount < lastUnreadCount)', (totalUnreadCount < lastUnreadCount));
          stayScrolled();
          assignLastScroll(conversationId, {
            clientHeight: listRef.current.clientHeight,
            scrollHeight: listRef.current.scrollHeight,
            scrollTop: listRef.current.scrollTop,
          });
        });
      }
      // Scroll down by the difference between the previous and current linkPreviewHeightSum
      else if (
        totalLinkPreviewHeightSum > lastLinkPreviewHeightSum &&
        totalUnreadCount === 0
      ) {
        requestAnimationFrame(() => {
          const heightDelta =
            totalLinkPreviewHeightSum - lastLinkPreviewHeightSum;
          const heightCountDelta =
            messagesWithLinkPreviewHeightCount -
            lastMessagesWithLinkPreviewHeightCount;

          // console.debug('layout scroll down by', heightDelta, 'totalLinkPreviewHeightSum > lastLinkPreviewHeightSum', totalLinkPreviewHeightSum, lastLinkPreviewHeightSum);
          scroll(
            conversationLastScroll.scrollTop +
              heightDelta +
              20 * heightCountDelta
          ); // Add 20px for top/bottom margin of each LinkPreview
          assignLastScroll(conversationId, {
            clientHeight: listRef.current.clientHeight,
            scrollHeight: listRef.current.scrollHeight,
            scrollTop: listRef.current.scrollTop,
          });
          setLastLinkPreviewHeightSum(totalLinkPreviewHeightSum);
          setLastMessagesWithLinkPreviewHeightCount(
            messagesWithLinkPreviewHeightCount
          );
        });
      }
    }

    setLastMessageCount(totalMessageCount);
    setLastUnreadCount(totalUnreadCount);
    // otherwise, let the MessageGroup scroll to its unread divider ref
  }, [
    totalUnreadCount,
    totalMessageCount,
    totalLinkPreviewHeightSum,
    messagesWithLinkPreviewHeightCount,
  ]);
  const ccigNodes: React.ReactNode[] = [];
  sortedMessageGroups.forEach((mg, groupIndex) => {
    ccigNodes.push(
      <ContextContentItemsGroup
        auth0={auth0}
        setFileDeletePopup={ui.setFileDeletePopup}
        handleDownloadWithLink={handleDownloadWithLink}
        getFileDownloadLink={getFileDownloadLink}
        listOfPinnedMessages={listOfPinnedMessages}
        group={mg}
        key={mg.groupKey}
        totalUnreadCount={totalUnreadCount}
        callWithPerson={callWithPerson}
        conversationId={conversationId}
        curConversation={curConversation}
        deleteMessage={deleteMessage}
        editMessage={editMessage}
        editMessageDraftHtml={editMessageDraftHtml}
        editMessageDraftRaw={editMessageDraftRaw}
        getContact={getContact}
        getEmojiPickerState={getEmojiPickerState}
        getPerson={getPerson}
        groupIndex={groupIndex}
        isEditingMessageId={isEditingMessageId}
        isScrolled={isScrolled}
        loadLinkPreview={loadLinkPreview}
        loggedInPersonId={loggedInPersonId}
        loggedInUserActiveConferenceConversation={
          loggedInUserActiveConferenceConversation
        }
        mentionListOpen={mentionListOpen}
        messages={messages}
        messageMentionFilter={messageMentionFilter}
        navigateToConferenceByConfId={navigateToConferenceByConfId}
        newestMessageOwnedByUserId={newestMessageOwnedByUserId}
        resolveConversationLinkPath={resolveConversationLinkPath}
        scroll={scroll}
        scrollBottom={scrollBottom}
        selectedMentionParticipantId={selectedMentionParticipantId}
        selectFilteredOtherParticipantPersonsInCurrentConversation={
          selectFilteredOtherParticipantPersonsInCurrentConversation
        }
        selectHasRecentActivityWithin={selectHasRecentActivityWithin}
        isBackfillingToReadMessage={isBackfillingToReadMessage}
        selectMarkedAsReadInfo={selectMarkedAsReadInfo}
        selectEpochMsSinceMarkedAsRead={selectEpochMsSinceMarkedAsRead}
        selectParticipantPersons={selectParticipantPersons}
        selectPersonPresenceStatus={selectPersonPresenceStatus}
        selectUnreadCounts={selectUnreadCounts}
        setConversationAndTotalUnreadCount={setConversationAndTotalUnreadCount}
        setEditMessageDraftHtml={setEditMessageDraftHtml}
        setEditMessageDraftRaw={setEditMessageDraftRaw}
        setEmojiPickerState={setEmojiPickerState}
        setIsEditingMessageId={setIsEditingMessageId}
        setMarkedAsReadInfo={setMarkedAsReadInfo}
        setMentionListOpen={setMentionListOpen}
        setMessageMentionFilter={setMessageMentionFilter}
        setSelectedMentionParticipantId={setSelectedMentionParticipantId}
        stayScrolled={stayScrolled}
        updateMyLastReadMessage={updateMyLastReadMessage}
        selectPersonMessageStatus={selectPersonMessageStatus}
        conversationStore={conversationStore}
        setCopiedConferenceId={ui.setCopiedConferenceId}
        copiedConferenceId={ui.copiedConferenceId}
        copyToClipboard={ui.copyToClipboard}
        getExtrContactByPhoneNumber={getExtrContactByPhoneNumber}
        loadContactByPhoneNumberIfMissing={loadContactByPhoneNumberIfMissing}
        messageIdToScroll={ui.messageIdToScroll}
        setMessageIdToScroll={ui.setMessageIdToScroll}
      />
    );
  });
  // ** REMEMBER: THIS SFC IS NOT AN OBSERVER **
  const allParticipants = selectParticipantsByConversationId(conversationId);

  return (
    <div
      data-private
      className="context-content-items-list paddingTop overflow-scroll-y flex-grow-shrink flex-basis-100pct"
      key="ccilp"
      ref={listRef}
      onScroll={onScroll}
    >
      {curConversation?.grouping !== 'Channel' &&
        curConversation?.grouping !== 'Group' &&
        allParticipants?.case({
          fulfilled: (resp) => {
            const personsPBO: Array<
              IPromiseBasedObservable<
                AxiosResponseT<PersonModel | ContactModelBase>
              >
            > = resp.data.results.map(({ personId, phone }) => {
              const phoneNumber = phone?.replace('+', '');
              return personId
                ? loadPersonByIdGetIfMissingGet(personId)
                : getContact(phoneNumber);
            });
            const personsData = personsPBO
              .map((personPbo) => {
                return personPbo.state === 'fulfilled'
                  ? personPbo.case({
                      fulfilled: (resp) => resp.data,
                    })
                  : null;
              })
              .filter((person) => person);
            const extrContactResp = resp.data.results.find(
              (resp) => !resp.personId && (resp as any)?.phone
            );
            const extrContact = getExtrContactByPhoneNumber(
              (extrContactResp as any)?.phone
            );
            return (
              <PrintMessageHeader
                hasMessages={true}
                persons={personsData}
                extrContact={extrContact}
                loggedInPerson={loggedInPersonId}
                ui={ui}
              />
            );
          },
        })}
      {ccigNodes}
    </div>
  );
}
// ** REMEMBER: THIS SFC IS NOT AN OBSERVER **
export default ContextContentItemsList;

// ** SCROLL HANDLERS **
const scrollBulkhead = Policy.bulkhead(1, 1);
const onCCILScrollBulkhead = (
  ccilRef: React.MutableRefObject<HTMLDivElement>,
  manageLastScrollAndBackfill: IManageLastScrollAndBackfill,
  scroll: (scrollTop: number) => void
) => {
  return scrollBulkhead
    .execute(() => onCCILScroll(ccilRef, manageLastScrollAndBackfill, scroll))
    .then(
      (c) => c,
      (err) => {
        if (err instanceof BulkheadRejectedError) {
          console.warn(
            `onCCILScrollBulkhead overloaded with BulkheadRejectedError, try again.`,
            manageLastScrollAndBackfill
          );
        } else {
          console.error(
            `onCCILScrollBulkhead error (not BulkheadRejectedError)`,
            err,
            manageLastScrollAndBackfill
          );
          bugsnagClient.notify(err, (event) => {
            event.context = 'ContextContentItemsList';
            event.addMetadata('custom', { function: 'onCCILScrollBulkhead' });
          });
        }
        return err;
      }
    );
};

const lMCMBulkhead = Policy.bulkhead(1, 1);
const loadMoreConversationMessagesBulkhead = (
  manageLastScrollAndBackfill: IManageLastScrollAndBackfill,
  conversationId: string,
  msgs: GroupedMessagesContainer
) => {
  return lMCMBulkhead
    .execute(() =>
      manageLastScrollAndBackfill.loadMoreConversationMessages(
        conversationId,
        LOAD_DIRECTION.Older,
        msgs.OldestMessageId
      )
    )
    .then(
      (c) => c,
      (err) => {
        if (err instanceof BulkheadRejectedError) {
          console.warn(
            `loadMoreConversationMessagesBulkhead overloaded with BulkheadRejectedError, try again.`,
            manageLastScrollAndBackfill
          );
        } else {
          console.error(
            `loadMoreConversationMessagesBulkhead error (not BulkheadRejectedError)`,
            err,
            manageLastScrollAndBackfill
          );
          bugsnagClient.notify(err, (event) => {
            event.context = 'ContextContentItemsList';
            event.addMetadata('custom', {
              function: 'loadMoreConversationMessagesBulkhead',
            });
          });
        }
        return err;
      }
    );
};
/**
 * Execution callback for scrolling `ContextContentItemsList`.
 * Returns:
 * - If a possible scroll event occurred _OR_ manageLastScrollAndBackfill.selectCanLoadOlderMessages returned true, a `Promise` resolved with: `boolean` indicating whether the `LastScroll`'s values changed.
 * - Otherwise, a `Promise` resolved with `null`
 * - On failure, a rejected `Promise` containing the `Error`
 */
const onCCILScroll = (
  ccilRef: React.MutableRefObject<HTMLDivElement>,
  manageLastScrollAndBackfill: IManageLastScrollAndBackfill,
  scroll: (scrollTop: number) => void
) => {
  const conversationId = manageLastScrollAndBackfill.conversationId;
  const convLastScroll = toJS(
    manageLastScrollAndBackfill.getOrConstructConversationLastScroll(
      conversationId
    )
  );
  const msgs = manageLastScrollAndBackfill.getCurrentMessages(conversationId);
  const initialRefScroll = {
    scrollTop: ccilRef.current.scrollTop,
    scrollHeight: ccilRef.current.scrollHeight,
    clientHeight: ccilRef.current.clientHeight,
  };

  const scrollHeightDiff =
    convLastScroll.scrollTop - initialRefScroll.scrollTop;
  const lmmSkipIds =
    manageLastScrollAndBackfill.selectLoadMoreMessagesSkipIds(conversationId);
  // Backfill if needed
  if (
    msgs.OldestMessageId !== null &&
    convLastScroll.clientHeight !== 0 &&
    convLastScroll.scrollHeight !== 0 &&
    ccilRef.current.scrollTop <= ccilRef.current.clientHeight / 4 &&
    (lmmSkipIds === null || !lmmSkipIds.includes(msgs.OldestMessageId)) &&
    (ccilRef.current.scrollTop < convLastScroll.scrollTop ||
      (ccilRef.current.scrollTop === 0 && convLastScroll.scrollTop === 0)) &&
    manageLastScrollAndBackfill.selectCanLoadOlderMessages(
      manageLastScrollAndBackfill.conversationId
    )
  ) {
    manageLastScrollAndBackfill.setIsLoadingOlderMessages(conversationId, true);
    // if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
    //     console.debug(`onCCILScroll loadMoreConversationMessages for ${conversationId} || OldestMessageId ${msgs.OldestMessageId} || convLastScroll ${JSON.stringify(convLastScroll)}`);
    // }
    const lmmReq = loadMoreConversationMessagesBulkhead(
      manageLastScrollAndBackfill,
      conversationId,
      msgs
    );
    if (lmmReq) {
      return lmmReq.then(
        (res) => {
          const scrollTopAdjustment =
            ccilRef.current?.scrollHeight - convLastScroll.scrollHeight;
          const adjustedLs: LastScroll = {
            ...convLastScroll,
            scrollTop: convLastScroll.scrollTop + scrollTopAdjustment,
          };
          return new Promise((resolve) => {
            requestAnimationFrame(() => {
              let returnScroll: number;
              if (adjustedLs.scrollTop !== 0) {
                returnScroll = adjustedLs.scrollTop;
              } else {
                returnScroll =
                  get(res, 'data.results.length', 0) > 0
                    ? ccilRef.current?.clientHeight / 3
                    : 0;
                adjustedLs.scrollTop = returnScroll;
              }
              scroll(returnScroll);
              manageLastScrollAndBackfill.setIsLoadingOlderMessages(
                conversationId,
                false
              );
              resolve(
                manageLastScrollAndBackfill.assignLastScroll(
                  conversationId,
                  adjustedLs
                )
              ); // whether the `LastScroll`'s values changed
            });
          });
        },
        (e) => {
          console.error(
            `onCCILScroll Error loading older Messages with error`,
            e
          );
          manageLastScrollAndBackfill.setIsLoadingOlderMessages(
            conversationId,
            false
          ); // reset
          return e;
        }
      );
    } else {
      return Promise.reject(
        new Error('loadMoreConversationMessages result was null or undefined')
      );
    }
  } else if (
    manageLastScrollAndBackfill.selectCanLoadOlderMessages(conversationId)
  ) {
    let scrollTop = initialRefScroll.scrollTop;
    if (scrollHeightDiff > 0)
      manageLastScrollAndBackfill.setUserIsScrolling(true);
    if (
      manageLastScrollAndBackfill.lastUnreadCount === 0 &&
      !manageLastScrollAndBackfill.scrolledToBottomOnFirstLoad &&
      scrollHeightDiff <= 0 &&
      !manageLastScrollAndBackfill.userIsScrolling
    ) {
      scrollTop = initialRefScroll.scrollHeight - initialRefScroll.clientHeight;
      scroll(scrollTop);
    }
    initialRefScroll.scrollTop = scrollTop;
    return Promise.resolve(
      manageLastScrollAndBackfill.assignLastScroll(
        conversationId,
        initialRefScroll
      )
    ); // whether the `LastScroll`'s values changed
  }
  return Promise.resolve(null); // Nothing happened
};

@observer
class ContextContentItemsGroup extends React.Component<
  IContextContentItemsGroupProps,
  { isV2Enabled: boolean }
> {
  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      isV2Enabled: false,
    };
  }

  @observable
  private showDebugMsg: boolean = false;

  @action
  private setShowDebugMsg = (showDebugMsg: boolean) =>
    (this.showDebugMsg = showDebugMsg);
  handleDividerClick = () => {
    if (isLocalDev) {
      this.setShowDebugMsg(true);
    }
  };
  handleModalClose = () => {
    this.setShowDebugMsg(false);
  };

  handleMarkAsReadClick = (from: 'componentDidMount' | 'render') => () => {
    const {
      conversationId,
      group,
      messages,
      setConversationAndTotalUnreadCount,
      setMarkedAsReadInfo,
      updateMyLastReadMessage,
      scrollBottom,
    } = this.props;
    const readMsg = messages.getReadMessage();
    const prevReadOrOldestMsgId =
      (readMsg !== null && readMsg.id) ||
      (messages.OldestMessage !== null && messages.OldestMessage.id) ||
      null;
    const newestMessageId = messages.NewestMessageId;
    const nextReadOrNewestMsgId = newestMessageId || group.NewestId;
    if (!isEmpty(nextReadOrNewestMsgId)) {
      //preventing to hide a NEW message divider from componentDidMount
      from === 'render' &&
        setMarkedAsReadInfo(conversationId, {
          markedEpochMs: now('frame'),
          messageId: prevReadOrOldestMsgId,
        });
      updateMyLastReadMessage(conversationId, nextReadOrNewestMsgId).then(
        () => {
          setConversationAndTotalUnreadCount(conversationId, 0, null, 0).then(
            () => {
              scrollBottom();
            }
          );
        }
      );
    }
  };
  unreadDivRef?: HTMLDivElement;

  private regUnreadDivRef = (el: HTMLDivElement) => (this.unreadDivRef = el);

  /**
   * Scroll smoothly to the Unread Messages div, if it exists.
   *
   * Returns `true` if a scroll was performed, `false` otherwise.
   */
  private tryScrollUnreadDivRefIntoView = () => {
    const { conversationId, group, groupIndex, messages, setMarkedAsReadInfo } =
      this.props;
    const previousGroupHasUnreads =
      groupIndex > 0 && !messages.SortedGroups[groupIndex - 1].AllMessagesRead;
    if (
      !isEmpty(this.unreadDivRef) &&
      !previousGroupHasUnreads &&
      !group.AllMessagesRead
    ) {
      setMarkedAsReadInfo(conversationId, null); // clear out because divider bar will show
      setTimeout(() => {
        if (this.unreadDivRef !== null) {
          this.unreadDivRef.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 50);
      return true;
    }
    return false;
  };

  async componentDidMount() {
    const { conversationId, auth0 } = this.props;
    if (!isEmpty(conversationId)) {
      this.tryScrollUnreadDivRefIntoView();
    }
    this.handleMarkAsReadClick('componentDidMount');

    const isV2Enabled = await isFlagInPermissions(auth0, IS_V2_ENABLED);
    this.setState({ isV2Enabled });
  }

  componentDidUpdate(prevProps: IContextContentItemsGroupProps) {
    const { conversationId, isBackfillingToReadMessage, messageIdToScroll } =
      this.props;
    // When switching to a new Conversation, scroll the unread messages divider into view if necessary
    if (messageIdToScroll !== '' && conversationId !== '') {
      if (
        (prevProps.messageIdToScroll !== messageIdToScroll &&
          conversationId !== prevProps.conversationId) ||
        conversationId === prevProps.conversationId
      ) {
        const messageHtml = document.getElementById(
          this.props.messageIdToScroll
        );
        messageHtml?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }
    }
    if (
      prevProps.conversationId !== conversationId ||
      (!isBackfillingToReadMessage &&
        isBackfillingToReadMessage !== prevProps.isBackfillingToReadMessage)
    ) {
      this.tryScrollUnreadDivRefIntoView();
    }
  }

  render() {
    const {
      callWithPerson,
      conversationId,
      curConversation,
      deleteMessage,
      editMessage,
      editMessageDraftHtml,
      editMessageDraftRaw,
      getContact,
      getEmojiPickerState,
      getPerson,
      group,
      groupIndex,
      isEditingMessageId,
      isScrolled,
      loadLinkPreview,
      loggedInPersonId,
      loggedInUserActiveConferenceConversation,
      mentionListOpen,
      messageMentionFilter,
      messages,
      navigateToConferenceByConfId,
      newestMessageOwnedByUserId,
      resolveConversationLinkPath,
      scroll,
      scrollBottom,
      selectedMentionParticipantId,
      selectFilteredOtherParticipantPersonsInCurrentConversation,
      selectMarkedAsReadInfo,
      selectEpochMsSinceMarkedAsRead,
      selectParticipantPersons,
      selectPersonPresenceStatus,
      selectUnreadCounts,
      setConversationAndTotalUnreadCount,
      setEditMessageDraftHtml,
      setEditMessageDraftRaw,
      setEmojiPickerState,
      setIsEditingMessageId,
      setMentionListOpen,
      setMessageMentionFilter,
      setSelectedMentionParticipantId,
      stayScrolled,
      totalUnreadCount,
      updateMyLastReadMessage,
      selectPersonMessageStatus,
      listOfPinnedMessages,
      conversationStore,
      getFileDownloadLink,
      handleDownloadWithLink,
      setFileDeletePopup,
      setCopiedConferenceId,
      copiedConferenceId,
      copyToClipboard,
      loadContactByPhoneNumberIfMissing,
      getExtrContactByPhoneNumber,
    } = this.props;

    const { isV2Enabled } = this.state;
    const filteredData =
      group.ReadMessages && _.uniqBy(group.ReadMessages, 'id');
    const previousGroupHasUnreads =
      groupIndex > 0 && messages.SortedGroups[groupIndex - 1].AnyMessagesUnread;
    const showUnreadDivider =
      group.AnyMessagesUnread &&
      group.UnreadMessages !== null &&
      // TODO: Not sure if we need this? RP 2019-08-19: && group.ReadMessages !== null
      group.NewestMessage.personId !== loggedInPersonId && // The latest Message isn't from the logged-in user
      !previousGroupHasUnreads &&
      messages.MsSinceNewestMessageCreatedOrAdded > 5000 &&
      (selectEpochMsSinceMarkedAsRead(conversationId) > 5000 ||
        selectEpochMsSinceMarkedAsRead(conversationId) === 0);

    const readMsg = messages.getReadMessage();
    let readOrNewestMsgCreated: moment.Moment;
    if (readMsg !== null) {
      readOrNewestMsgCreated = readMsg.CreatedMoment;
    } else if (messages.NewestMessage !== null) {
      readOrNewestMsgCreated = messages.NewestMessage.CreatedMoment;
    } else {
      readOrNewestMsgCreated = moment.tz(TIMEZONE_IDENTIFIER);
    }
    // if(BV_ENV_LOCAL_OR_DEVELOPMENT){
    //     console.debug(`CCIL readOrOldestMsgCreated for ${conversationId}: ${readOrNewestMsgCreated.toISOString()} (readMessageId ${get(readMsg, 'id', '<empty>')})`)
    // }
    const dividerKey = `divider-${group.groupKey}`;
    const separator = !BV_ENV_LOCAL_OR_DEVELOPMENT ? (
      <ChatDivider groupDate={group.groupKey} />
    ) : (
      <Modal
        key={dividerKey}
        id={dividerKey}
        trigger={<ChatDivider groupDate={group.groupKey} />}
        open={this.showDebugMsg}
        basic
        size="small"
        onClose={this.handleModalClose}
        closeIcon={<Icon name="close" />}
      >
        <Modal.Header>{group.DisplayName}</Modal.Header>
        <Modal.Content className="msg-group-dump-content" scrolling>
          <pre>{group.SerializedJSON}</pre>
        </Modal.Content>
      </Modal>
    );
    return (
      <div
        id={`ccig_${group.groupKey}`}
        key={`ccig-${group.groupKey}`}
        className="context-content-items-group flex-grow-shrink flex-basis-100pct"
      >
        {separator}
        <Feed size="large" key={`feed-${group.groupKey}`}>
          {filteredData &&
            filteredData.map((msg) => (
              <React.Fragment key={`wrap-${msg.id}`}>
                {
                  // Special case: show here if it is the oldest Message, ex. the first Message in a new Conversation
                  messages.MsSinceNewestMessageCreatedOrAdded > 5000 &&
                    !isNullOrUndefined(
                      selectMarkedAsReadInfo(conversationId)
                    ) &&
                    selectMarkedAsReadInfo(conversationId).messageId ===
                      msg.id &&
                    messages.OldestMessageId === msg.id && (
                      <div
                        className="marked-messages-divider flex-row"
                        id={`mmd-${group.groupKey}`}
                        key={`readnmdiv-${group.groupKey}`}
                      >
                        <div className="marked-messages-text flex-shrink">
                          NEW MESSAGES
                        </div>
                        <div className="marked-messages-line flex-grow-shrink" />
                      </div>
                    )
                }

                {!isV2Enabled ? (
                  <ContextContentItemObserver
                    setFileDeletePopup={setFileDeletePopup}
                    handleDownloadWithLink={handleDownloadWithLink}
                    getFileDownloadLink={getFileDownloadLink}
                    conversationStore={conversationStore}
                    listOfPinnedMessages={listOfPinnedMessages}
                    callWithPerson={callWithPerson}
                    conversationId={conversationId}
                    curConversation={curConversation}
                    deleteMessage={deleteMessage}
                    editMessage={editMessage}
                    editMessageDraftHtml={editMessageDraftHtml}
                    editMessageDraftRaw={editMessageDraftRaw}
                    getContact={getContact}
                    getEmojiPickerState={getEmojiPickerState}
                    getPerson={getPerson}
                    isEditingMessageId={isEditingMessageId}
                    isScrolled={isScrolled}
                    key={msg.id}
                    loadContactByPhoneNumberIfMissing={
                      loadContactByPhoneNumberIfMissing
                    }
                    loadLinkPreview={loadLinkPreview}
                    loggedInPersonId={loggedInPersonId}
                    loggedInUserActiveConferenceConversation={
                      loggedInUserActiveConferenceConversation
                    }
                    mentionListOpen={mentionListOpen}
                    message={msg}
                    messageGroup={group}
                    messageMentionFilter={messageMentionFilter}
                    navigateToConferenceByConfId={navigateToConferenceByConfId}
                    newestMessageOwnedByUserId={newestMessageOwnedByUserId}
                    resolveConversationLinkPath={resolveConversationLinkPath}
                    scroll={scroll}
                    scrollBottom={scrollBottom}
                    selectedMentionParticipantId={selectedMentionParticipantId}
                    selectFilteredOtherParticipantPersonsInCurrentConversation={
                      selectFilteredOtherParticipantPersonsInCurrentConversation
                    }
                    selectParticipantPersons={selectParticipantPersons}
                    selectPersonPresenceStatus={selectPersonPresenceStatus}
                    selectUnreadCounts={selectUnreadCounts}
                    setConversationAndTotalUnreadCount={
                      setConversationAndTotalUnreadCount
                    }
                    setEditMessageDraftHtml={setEditMessageDraftHtml}
                    setEditMessageDraftRaw={setEditMessageDraftRaw}
                    setEmojiPickerState={setEmojiPickerState}
                    setIsEditingMessageId={setIsEditingMessageId}
                    setMentionListOpen={setMentionListOpen}
                    setMessageMentionFilter={setMessageMentionFilter}
                    setSelectedMentionParticipantId={
                      setSelectedMentionParticipantId
                    }
                    stayScrolled={stayScrolled}
                    updateMyLastReadMessage={updateMyLastReadMessage}
                    selectPersonMessageStatus={selectPersonMessageStatus}
                    setCopiedConferenceId={setCopiedConferenceId}
                    copiedConferenceId={copiedConferenceId}
                    copyToClipboard={copyToClipboard}
                    getExtrContactByPhoneNumber={getExtrContactByPhoneNumber}
                  />
                ) : (
                  <ChatContentItem
                    setFileDeletePopup={setFileDeletePopup}
                    handleDownloadWithLink={handleDownloadWithLink}
                    getFileDownloadLink={getFileDownloadLink}
                    conversationStore={conversationStore}
                    listOfPinnedMessages={listOfPinnedMessages}
                    callWithPerson={callWithPerson}
                    conversationId={conversationId}
                    curConversation={curConversation}
                    deleteMessage={deleteMessage}
                    editMessage={editMessage}
                    editMessageDraftHtml={editMessageDraftHtml}
                    editMessageDraftRaw={editMessageDraftRaw}
                    getContact={getContact}
                    getEmojiPickerState={getEmojiPickerState}
                    getPerson={getPerson}
                    isEditingMessageId={isEditingMessageId}
                    isScrolled={isScrolled}
                    key={msg.id}
                    loadContactByPhoneNumberIfMissing={
                      loadContactByPhoneNumberIfMissing
                    }
                    loadLinkPreview={loadLinkPreview}
                    loggedInPersonId={loggedInPersonId}
                    loggedInUserActiveConferenceConversation={
                      loggedInUserActiveConferenceConversation
                    }
                    mentionListOpen={mentionListOpen}
                    message={msg}
                    messageGroup={group}
                    messageMentionFilter={messageMentionFilter}
                    navigateToConferenceByConfId={navigateToConferenceByConfId}
                    newestMessageOwnedByUserId={newestMessageOwnedByUserId}
                    resolveConversationLinkPath={resolveConversationLinkPath}
                    scroll={scroll}
                    scrollBottom={scrollBottom}
                    selectedMentionParticipantId={selectedMentionParticipantId}
                    selectFilteredOtherParticipantPersonsInCurrentConversation={
                      selectFilteredOtherParticipantPersonsInCurrentConversation
                    }
                    selectParticipantPersons={selectParticipantPersons}
                    selectPersonPresenceStatus={selectPersonPresenceStatus}
                    selectUnreadCounts={selectUnreadCounts}
                    setConversationAndTotalUnreadCount={
                      setConversationAndTotalUnreadCount
                    }
                    setEditMessageDraftHtml={setEditMessageDraftHtml}
                    setEditMessageDraftRaw={setEditMessageDraftRaw}
                    setEmojiPickerState={setEmojiPickerState}
                    setIsEditingMessageId={setIsEditingMessageId}
                    setMentionListOpen={setMentionListOpen}
                    setMessageMentionFilter={setMessageMentionFilter}
                    setSelectedMentionParticipantId={
                      setSelectedMentionParticipantId
                    }
                    stayScrolled={stayScrolled}
                    updateMyLastReadMessage={updateMyLastReadMessage}
                    selectPersonMessageStatus={selectPersonMessageStatus}
                    setCopiedConferenceId={setCopiedConferenceId}
                    copiedConferenceId={copiedConferenceId}
                    copyToClipboard={copyToClipboard}
                    getExtrContactByPhoneNumber={getExtrContactByPhoneNumber}
                  />
                )}

                {
                  // Special case: hide here if it is the oldest Message, ex. the first Message in a new Conversation
                  messages.MsSinceNewestMessageCreatedOrAdded > 5000 &&
                    !isNullOrUndefined(
                      selectMarkedAsReadInfo(conversationId)
                    ) &&
                    selectMarkedAsReadInfo(conversationId).messageId ===
                      msg.id &&
                    messages.OldestMessageId !== msg.id && (
                      <div
                        className="marked-messages-divider flex-row"
                        id={`mmd-${group.groupKey}`}
                        key={`readnmdiv-${group.groupKey}`}
                      >
                        <div className="marked-messages-text flex-shrink">
                          NEW MESSAGES
                        </div>
                        <div className="marked-messages-line flex-grow-shrink" />
                      </div>
                    )
                }
              </React.Fragment>
            ))}
          {showUnreadDivider ? (
            <div
              key={`unreadnmdiv-${group.groupKey}`}
              id={`unreadnmdiv-${group.groupKey}`}
              className="divider-unread flex-row"
              title={`${totalUnreadCount} Unread Messages`}
              ref={this.regUnreadDivRef}
            >
              <div
                className="divider-unread-left flex-shrink"
                onClick={scrollBottom}
              >
                JUMP
              </div>
              <div className="divider-unread-mid flex-grow-shrink">
                {totalUnreadCount} New Message{totalUnreadCount > 1 ? 's' : ''}
                {` Since ${readOrNewestMsgCreated.format('LT [on] LL')}`}
              </div>
              <div
                className="divider-unread-right flex-shrink"
                onClick={this.handleMarkAsReadClick('render')}
              >
                MARK AS READ
              </div>
            </div>
          ) : (
            <div
              key={`unreadnmdiv-${group.groupKey}`}
              id={`unreadnmdiv-${group.groupKey}`}
              className="flex-row"
              style={{ display: 'none!important', visibility: 'hidden' }}
              ref={this.regUnreadDivRef}
            />
          )}
          {group.AnyMessagesUnread &&
            group.UnreadMessages.filter(
              (msg) => !isNullOrUndefined(msg.id)
            ).map((msg, idx) => (
              <>
                {!isV2Enabled ? (
                  <ContextContentItemObserver
                    {...this.props}
                    key={msg.id || `${group.groupKey}-${idx}`}
                    message={msg}
                    messageGroup={group}
                    conversationStore={conversationStore}
                    loadContactByPhoneNumberIfMissing={
                      loadContactByPhoneNumberIfMissing
                    }
                  />
                ) : (
                  <ChatContentItem
                    {...this.props}
                    key={msg.id || `${group.groupKey}-${idx}`}
                    message={msg}
                    messageGroup={group}
                    conversationStore={conversationStore}
                    loadContactByPhoneNumberIfMissing={
                      loadContactByPhoneNumberIfMissing
                    }
                  />
                )}
              </>
            ))}
        </Feed>
      </div>
    );
  }
}
