/**
 * This function parses a valid JWT and returns its payload.
 * Adds decoding mechanisms to handle unicode encoded tokens.
 * 
 * @param {string} token - A JWT token string
 * e.g.: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
 * @returns {Object} - Decoded JWT payload
 * e.g.: {
      sub: '1234567890',
      name: 'John Doe',
      iat: 1516239022,
    }
 */
export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const hasTokenExpired = (token: string): boolean => {
  const dateNow = new Date();
  const { exp } = parseJwt(token);
  const timeInSeconds = dateNow.getTime() / 1000;
  return timeInSeconds >= exp;
};
