import { inject } from 'mobx-react';
import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  STORE_CALL_LOGS,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_UI,
} from '../../constants/stores';
import withRouter from '../../hocs/WithRouter';
import CallsPanel from '../CallsPanel';
import { ICallsContainerProps, ICallsContainerState } from './interfaces';
import { Grid } from 'semantic-ui-react';
import { SecondaryMenu } from '../../components/shared/SecondaryMenu';
import { CALLS_SUBMENU_OPTIONS } from '../../constants/routing';

class CallsContainer extends React.Component<
  ICallsContainerProps,
  ICallsContainerState
> {
  componentDidMount() {
    this.setState({
      dialpadWasOpenedBefore: this.props.ui.openTopbarDialpad,
    });
    if (window.innerWidth > 1024) this.handleOpenDialpad();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    this.handleCloseDialpad();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = (event) => {
    window.innerWidth > 1024
      ? this.handleOpenDialpad()
      : this.handleCloseDialpad();
  };

  handleCloseModals = () => {
    this.props.ui.setDeletePopupDisplay(false);
  };

  handleOpenDialpad = () => {
    const { phoneCall: phoneStore, ui } = this.props;
    if (!phoneStore.ActivePhoneCall && !phoneStore.incomingPhoneCalls.length) {
      ui.setOpenTopbarDialpad(true);
      ui.setOpenedRightSidebarsOrder('dial-pad');
    }
  };

  handleCloseDialpad = () => {
    const { ui } = this.props;
    if (!this.state?.dialpadWasOpenedBefore) {
      ui.removeFromOpenedRightSidebarsOrder('dial-pad');
      ui.setOpenTopbarDialpad(false);
    }
  };

  handleCloseDeletePopup = () => this.props.ui.setDeletePopupDisplay(false);

  CallsPage = () => (
    <>
      {!this.props.ui.hideSecondaryMenu && (
        <SecondaryMenu
          text="Recent calls"
          options={CALLS_SUBMENU_OPTIONS}
          {...this.props}
        />
      )}
      <CallsPanel {...this.props} {...this.props.router} />
    </>
  );

  render() {
    return (
      <Grid.Column
        className="calls-container flex-row"
        onClick={this.handleCloseModals}
      >
        <Routes>
          <Route path="/:typeOfCallsId" element={<this.CallsPage />} />
          <Route path="*" element={<Navigate to="/calls/all" replace />} />
        </Routes>
      </Grid.Column>
    );
  }
}

export default withRouter(
  inject(
    STORE_UI,
    STORE_CALL_LOGS,
    STORE_PERSON,
    STORE_PHONE_CALL,
    STORE_ROUTER
  )(CallsContainer)
);
