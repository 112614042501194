import {
  CONTACT_TYPE,
  CONTACT_VALUE_TYPE,
  SEARCHABLE_TYPE,
} from 'Constants/enums';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { isEmpty } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { ISearchableItem } from 'Models/SearchResult';
import phoneUtil from '../utils/phoneUtil';

export interface IContactBase extends ISearchableItem {
  name?: string;
  phoneNumber: string;
  contactType: CONTACT_TYPE;
}

export interface IContactExtended extends IContactBase {
  contactBookId: number;
  locationId?: number;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  phoneNumbers?: string[];
  emails?: string[];
  updated?: string;
  contactValues: IContactValue[];
  contactHash?: string;
  note?: string;
}

export interface IContactMerge extends IContactExtended {
  sources?: IContactBase[];
}

export type IContactDto = IContactBase | IContactExtended | IContactMerge;

export interface IContactValue {
  id: number;
  type: CONTACT_VALUE_TYPE;
  value: string;
  created?: string;
  updated?: string;
}

export class ContactModelBase implements IContactBase {
  id: string;
  name?: string;
  phoneNumber: string;
  contactType: CONTACT_TYPE;
  created: string;
  /** Only present if this is a search result */
  searchableType?: SEARCHABLE_TYPE;
  constructor(dto: IContactBase) {
    makeObservable(this);
    this.phoneNumber = dto.phoneNumber;
    this.contactType = dto.contactType;
    this.created = dto.created;
    this.id = dto.id as string;
    this.name = dto.name;
    this.searchableType = dto.searchableType;
  }

  @computed
  get FormattedPhone() {
    const phoneNumber = this.phoneNumber;
    if (phoneUtil.isPossibleNumberString(phoneNumber, 'US')) {
      const parsePhoneNumber = phoneUtil.parse(phoneNumber, 'US');
      return phoneUtil.format(parsePhoneNumber, PhoneNumberFormat.NATIONAL);
    } else {
      return phoneNumber;
    }
  }

  @computed
  get DisplayAvatar() {
    const displayAvatar: string =
      'https://www.gravatar.com/avatar?d=identicon&s=70';
    return displayAvatar;
  }

  @computed
  get DisplayName() {
    return !isEmpty(this.name) ? this.name : this.FormattedPhone;
  }
}
export class ContactModelExtended
  extends ContactModelBase
  implements IContactExtended
{
  contactBookId: number;
  locationId?: number;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  phoneNumbers?: string[];
  emails?: string[];
  updated?: string;
  contactValues: IContactValue[];
  contactHash?: string;
  note?: string;

  constructor(dto: IContactExtended) {
    super(dto);
    this.contactValues = dto.contactValues;
    this.contactBookId = dto.contactBookId;
    this.locationId = dto.locationId;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.companyName = dto.companyName;
    this.phoneNumbers = dto.phoneNumbers;
    this.emails = dto.emails;
    this.updated = dto.updated;
    this.contactHash = dto.contactHash;
    this.note = dto.note;
  }

  get DisplayName() {
    if (!isEmpty(this.firstName) || !isEmpty(this.lastName)) {
      return `${this.firstName || ''} ${this.lastName || ''}`;
    }

    return !isEmpty(this.name) ? this.name : this.FormattedPhone;
  }
}

export class ContactModelMerged
  extends ContactModelExtended
  implements IContactMerge
{
  sources?: IContactBase[];

  constructor(dto: IContactMerge) {
    super(dto);
    this.sources = dto.sources;
  }
}

export type ContactModel =
  | ContactModelBase
  | ContactModelExtended
  | ContactModelMerged;

/**
 * User Defined Type Guards to discriminate polymorphic Contact DTOs from the API.
 *
 * See https://basarat.gitbooks.io/typescript/content/docs/types/typeGuard.html#user-defined-type-guards
 */
export class ContactTypeGuards {
  static isContactBase(dto: IContactDto): dto is IContactBase {
    return dto.contactType === 'Base';
  }
  static isContactExtended(dto: IContactDto): dto is IContactExtended {
    return (
      dto.contactType === 'Personal' ||
      dto.contactType === 'Shared' ||
      dto.contactType === 'Person'
    );
  }
  static isContactMerged(dto: IContactDto): dto is IContactMerge {
    return dto.contactType === 'Merged';
  }
  static isContactExtendedOrMerged(
    dto: IContactDto
  ): dto is IContactExtended | IContactMerge {
    return this.isContactExtended(dto) || this.isContactMerged(dto);
  }
}

export function FromContactResponseDto(dto: IContactDto): ContactModel {
  if (ContactTypeGuards.isContactMerged(dto)) {
    return new ContactModelMerged(dto);
  }
  if (ContactTypeGuards.isContactExtended(dto)) {
    return new ContactModelExtended(dto);
  }
  if (ContactTypeGuards.isContactBase(dto)) {
    return new ContactModelBase(dto);
  }
  // Type Guard failure
  throw new Error(
    'No Type Guard matched for the provided DTO, check ContactModelTypeGuards for requirements.'
  );
}
