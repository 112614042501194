import cx from 'classnames';
import { BV_ENV_LOCAL_OR_DEVELOPMENT } from 'Constants/env';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import * as React from 'react';
import { formatNumber } from 'Utils/phoneUtil';
import { MessageModel, MessageStatusModel } from '../../models/index';
export interface IContextContentItemMetaProps {
  createdMoment: moment.Moment;
  displayName: string;
  SupportsChat?: boolean;
  messageId: string;
  messageStatus: MessageStatusModel;
  isFirst5Mins: boolean;
  newestMessage: MessageModel;
  loggedInPersonId: number;
  messageModel: MessageModel;
}

export class ContextContentItemMeta extends React.Component<
  IContextContentItemMetaProps,
  {}
> {
  render() {
    const {
      createdMoment,
      displayName,
      messageId,
      isFirst5Mins,
      loggedInPersonId,
      messageModel,
    } = this.props;
    const userClassNames: [String] = ['user'];
    if (!this.props.SupportsChat) {
      userClassNames.push('gray');
    }
    const time = moment(createdMoment).format('h:mm A');
    const createdMomentLocalFmt = createdMoment.format('LLL');
    const name = !isNaN(displayName as any)
      ? formatNumber(displayName)
      : displayName;
    const hasEditDeleteOption =
      loggedInPersonId === messageModel.personId &&
      messageModel.id &&
      messageModel.chat;
    let title = createdMomentLocalFmt;
    if (BV_ENV_LOCAL_OR_DEVELOPMENT) {
      title = `${createdMoment.toISOString()} - Id: ${messageId}`;
    }
    return (
      <div className="summary" title={title}>
        {isFirst5Mins && (
          <div className={cx(userClassNames)}>
            <strong>{name}</strong>&nbsp;&nbsp;
          </div>
        )}
        <div
          className={`date ${!isFirst5Mins ? 'toogle-date' : ''} ${
            hasEditDeleteOption ? 'right-105' : 'right-48'
          }`}
        >
          {time}
        </div>
      </div>
    );
  }
}
export default observer(ContextContentItemMeta);
