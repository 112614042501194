import * as React from 'react';
import { Styled } from './index.styles';
import { Popup } from 'semantic-ui-react';
import { BodyCopy } from 'Components/shared/BodyCopy';
import { ChatLayoutHeaderTextProps } from './types';

export const ChatLayoutHeaderText: React.FC<ChatLayoutHeaderTextProps> = ({
  title,
  subtitle = '',
  thirdline = '',
  emoji,
}) => (
  <>
    <Styled.Wrapper>
      <Styled.Heading variant="h5" noMargin>
        {title}
      </Styled.Heading>
      {subtitle && <BodyCopy noMargin>{subtitle}</BodyCopy>}
      {thirdline && <BodyCopy noMargin>{thirdline}</BodyCopy>}
    </Styled.Wrapper>
    {emoji?.title && (
      <Popup
        inverted
        trigger={<Styled.Emoji>{emoji.title}</Styled.Emoji>}
        content={emoji?.message}
        position="top right"
      />
    )}
  </>
);
