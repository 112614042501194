import { BodyCopy } from 'Components/shared/BodyCopy';
import styled from 'styled-components';

const BoxWithAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  position: relative;
  width: fit-content;
  max-width: 64%;
`;

const BoxWithoutAvatar = styled.div`
  align-items: center;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 3px;
  max-width: 64%;
  background-color: ${({ theme }) => theme.colors.lightGray};
  padding: 10px;
  position: relative;
  width: fit-content;
  float: right;

  ::after {
    content: '';
    position: absolute;
    top: 10px;
    right: -5px;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent
      ${({ theme }) => theme.colors.lightGray};
  }
`;

const NameDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Date = styled(BodyCopy)`
  margin-left: 10px;
  white-space: nowrap;
`;

const Box = styled.div`
  flex: 1;
  border: 1px solid #e6e6e6;
  min-height: 55px;
  width: fit-content;
  border-radius: 4px;
  padding: 10px;
  overflow: hidden;
`;

const ArrowContainer = styled.div`
  position: absolute;
  left: 45px;
  top: 20px;
  transform: translateY(-50%);
`;

const Arrow = styled.div`
  position: absolute;
  left: 2px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #e6e6e6;
`;

const ArrowFill = styled.div`
  position: absolute;
  left: 3.5px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid white;
`;

const Name = styled(BodyCopy)`
  font-weight: 700;
  margin-bottom: 5px;
  word-break: break-all;
`;

const Message = styled(BodyCopy)`
  margin-bottom: 5px;
  word-break: break-all;
`;

const AvatarContainer = styled.div`
  align-self: baseline;
  margin-right: 10px;
`;

export const Styled = {
  AvatarContainer,
  BoxWithAvatar,
  BoxWithoutAvatar,
  Box,
  ArrowContainer,
  Arrow,
  ArrowFill,
  Name,
  Message,
  Date,
  NameDateContainer,
};
