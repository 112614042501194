export interface IVideoHistory {
  hits: number;
  results: IVideoHistoryResults[];
}

export interface IVideoHistoryResults {
  id: number;
  isAdmin: boolean;
  joinedAt: string;
  displayName: string;
  started: string;
  completed: string;
  hasRecordings: boolean;
}

export interface IVideoHistoryDetails {
  id: number;
  recordings: Recordings[];
  participants: VideoDetailsParticipants[];
  chatMessages: ChatMessage[];
  isAdmin: boolean;
  joinedAt: string;
  displayName: string;
  started: string;
  completed: string;
  hasRecordings: boolean;
  conferenceId: string;
  recordingStatus: RecordingStatus;
  pin: string;
  dialInInfos: Array<{
    number: string;
  }>;
}

interface VideoDetailsParticipants {
  personId: number;
  displayName: string;
  isAdmin: boolean;
}
interface ChatMessage {
  personId: number;
  displayName: string;
  messageText: string;
  createTime: string;
}

export interface Recordings {
  recordingId: string;
  startTime: string;
  duration: string;
  size: number;
  status: RecordingStatus;
  deletedAt: string;
  deletedByPersonId: number;
}

export enum RecordingStatus {
  NotRecorderd = 1,
  Recorded = 2,
  Processing = 3,
  Available = 4,
  Error = 5,
  Deleted = 6,
  DeletionPending = 7,
}
