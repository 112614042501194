import React from 'react';
import { IS_ELECTRON } from 'Constants/env';
import { inject, observer } from 'mobx-react';
import type { CheckboxProps } from 'semantic-ui-react';
import { STORE_PREFERENCE, STORE_UI } from 'Constants/stores';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { sendIpcSetOpenOnLogin } from 'Utils/ipcRendererEvents';
import { GeneralSettingsProps } from './interfaces';
import { ToggleButton } from '../../../shared/ToggleButton';
import { Styled } from './index.styles';
import { Heading } from '../../../shared/Heading';

const GeneralSettings: React.FC<GeneralSettingsProps> = ({
  preference,
  ui,
}) => {
  const testid = 'generalSettings';

  const onToggleShowCallMessagesInChat = async () => {
    await preference.setPreferencesPatch({
      showCallMessagesInChat: !preference.preferences.showCallMessagesInChat,
    });
  };

  const togglePreference = (preferenceName: string) => {
    if (
      preferenceName == 'floatingSoftphone' &&
      !preference.preferences[preferenceName]
    ) {
      ui.setOpenedRightSidebarsOrder('dial-pad');
    }
    preference.setPreferencesPatch({
      [preferenceName]: !preference.preferences[preferenceName],
    });
  };

  const onToggleOpenOnLogin = (_e, data: CheckboxProps) => {
    sendIpcSetOpenOnLogin(data.checked, preference);
    pushToGTMDataLayer('toggleOpenOnLogin');
  };

  return (
    <div className="settings-general">
      <Heading variant="h2">General Settings</Heading>
      <div className="flex-column flex-justify-space-between settings-general--wrapper">
        <div>
          {IS_ELECTRON && window['ipcRenderer'] !== undefined && (
            <div>
              <h3>Application</h3>
              <div className="flex-column flex-justify-space-between">
                <span className="sub-headers margin-bottom-2rem">
                  Open on Login
                </span>
                <ToggleButton
                  label="Open Communicator when you log in to this computer (applies to desktop app only)"
                  checked={preference.desktopLocalPreferences.openOnLogin}
                  onChange={onToggleOpenOnLogin}
                  testid={`${testid}-toggleButtonOpenOnLogin`}
                />
              </div>
            </div>
          )}
          <Styled.SettingsOptions>
            <h3>Chat options</h3>
            <Styled.ToggleButton
              label="Enable Call History in Chat Conversations (Beta)"
              checked={preference.preferences.showCallMessagesInChat}
              onChange={onToggleShowCallMessagesInChat}
              helperText="Disabling call history hides all calls in chats. For now, only calls made on this device will be visible on the calls tab."
              testid={`${testid}-toggleButtonCallHistoryInChatConversations`}
            />
            <Styled.ToggleButton
              label="List Conversations with Unread Messages First"
              checked={preference.preferences.listUnreadFirst}
              onChange={() => togglePreference('listUnreadFirst')}
              testid={`${testid}-toggleButtonUnreadMessagesFirst`}
            />
          </Styled.SettingsOptions>
          <Styled.SettingsOptions>
            <h3>Softphone</h3>
            <Styled.ToggleButton
              label="Fix softphone on the screen"
              checked={preference.preferences.floatingSoftphone}
              onChange={() => togglePreference('floatingSoftphone')}
              testid={`${testid}-toggleButtonFixSoftPhoneOnScreen`}
            />
          </Styled.SettingsOptions>
        </div>
      </div>
    </div>
  );
};
export default inject(STORE_PREFERENCE, STORE_UI)(observer(GeneralSettings));
