import { debounce, isEmpty, uniqBy } from 'lodash';
import { IEvent, initialCreatedEvent } from 'Models/Calendar';
import { Contact } from 'Models/Contacts';
import { INewConference, IPrivateConversationConference } from 'Models/ConversationModel';
import moment, { Moment } from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  InputOnChangeData,
  InputProps,
  LabelProps,
  TextArea,
  TextAreaProps,
} from 'semantic-ui-react';
import { PersonModel } from '../../models';
import { Input } from 'Components/shared/Input';
import type { CalendarEventProps, DatePick } from './types';
import { Styled } from './index.styles';
import { Heading } from 'Components/shared/Heading'
import fullNameToInitials from '../../utils/fullNameToInitials';
import { UserAvatar } from '../shared/UserAvatar';
import { CalendarEventTime } from './CalendarEventTime';
import { IconButton } from 'Components/shared/IconButton';

const initialEvent = { ...initialCreatedEvent };

export const CalendarEvent = ({
  calendar,
  person,
  toogleCreateEventModal,
  ui,
  conversation,
  testId: parentTestId
}: CalendarEventProps) => {
  const testId = `${parentTestId}-calendarEvent`;
  const [loadingData, setIsLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [createdEvent, setCreatedEvent] = useState(initialEvent);
  const [datePick, setDatePick] = useState({
    endDate: moment().add(15, 'minute'),
    startDate: moment(),
    startTime: moment(),
    endTime: moment().add(15, 'minute'),
  });

  const [showTimePicker, setShowTimePicker] = useState(true);
  const [options, setOptions] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [selectedParticipantsIds, setSelectedParticipantsIds] = useState([]);

  const mapEventToTheFormData = (selectedEvent: IEvent) => {
    setCreatedEvent({ ...selectedEvent });
    setSelectedParticipantsIds(transformToParticipantIds(
      selectedEvent,
      true
    ) as string[]);

    setDatePick({
      startDate: moment(selectedEvent.start),
      endDate: moment(selectedEvent.end),
      startTime: moment(selectedEvent.start),
      endTime: moment(selectedEvent.end),
    })
  }

  const transformToParticipantIds = (selectedEvent: IEvent, toString?: boolean) =>
    selectedEvent.participants
      ?.map((participant) =>
        toString
          ? participant.platformUserId?.toString()
          : participant.platformUserId
      )
      .filter(Boolean);

  const getSelectedParticipants = async () => {
    const selectedParticipantIds = transformToParticipantIds(calendar.selectedEvent);
    const selectedPersons = await person.getPersonsById(selectedParticipantIds as number[]);
    const datePick = {
      startDate: moment(calendar.selectedEvent.start),
      endDate: moment(calendar.selectedEvent.end),
      startTime: moment(calendar.selectedEvent.start),
      endTime: moment(calendar.selectedEvent.end),
    };

    const personsWithAvailability = await checkAvailability(
      selectedPersons as PersonModel[],
      datePick,
      calendar.selectedEvent.allDay
    );

    setSelectedParticipants(personsWithAvailability);
    setShowTimePicker(!calendar.selectedEvent.allDay);

    return personsWithAvailability;
  };

  const loadDropDownOptions = async (reload?: boolean) => {
    const calendarSelectedParticipants =
      calendar.selectedEvent && isEmpty(selectedParticipants)
        ? await getSelectedParticipants()
        : [];

    let data: Array<PersonModel | Contact> = [
      ...calendarSelectedParticipants,
      ...calendar.mergedPeopleExtrContact,
    ];

    if (calendar.mergedPeopleExtrContact?.length === 0 || reload) {
      data = await calendar.getPersonsExtrContacts(
        '',
        ['SearchableDetailsPerson'],
        20,
        1
      );
    }

    if (selectedParticipants) {
      data = [...data, ...selectedParticipants];
    }

    createSetOptions(data);
  };

  const createSetOptions = (
    personsExtrContacts: Array<PersonModel | Contact>,
    saveSelected?: boolean
  ) => {
    let options: DropdownItemProps[];
    if (personsExtrContacts) {
      const emailOption = isEmail(calendar.searchQuery)
        ? createEmailOption(calendar.searchQuery)
        : '';

      const optionsPersonsExtrContacts = personsExtrContacts.map(
        (item: PersonModel | Contact) => {
          if (item instanceof PersonModel) {
            return createPersonOption(item);
          }
          return item ? createExtrContactOptions(item) : null;
        }
      );

      options = emailOption
        ? [emailOption, ...optionsPersonsExtrContacts]
        : optionsPersonsExtrContacts;

      const selectedOptions = saveSelected
        ? uniqBy(
          getSelectedOptions(selectedParticipantsIds),
          'key'
        )
        : [];

      setOptions(saveSelected ? [...selectedOptions, ...options] : options)

      return options;
    }
  };

  const getSelectedOptions = (personsExtrContacts: string[]) =>
    options.filter((option) =>
      personsExtrContacts.includes(option.key.toString())
    );

  const handleScrollDown = () => {
    const hasMoreData = options.length < calendar.totalHits;
    const timeout = setTimeout(() => {
      const element: any = document.querySelector(
        '#eventParticipants > div.visible.menu.transition'
      );

      element.onscroll = (e: any) => {
        const bottom =
          e.target.scrollHeight -
          e.target.scrollTop -
          e.target.clientHeight -
          10;

        if (
          bottom <= 20 &&
          hasMoreData &&
          !calendar.loadingPersonsExtrContacts
        ) {
          handleLoadMoreData();
        }
      };
    }, 500);

    if (!hasMoreData) {
      clearTimeout(timeout);
    }
  };

  const onSearch = (e, inputProps: InputProps) => {
    calendar.setSearchQuery(inputProps.searchQuery);
    setSearchQuery(inputProps.searchQuery);
    filter(e, inputProps);
  };

  const isEmail = (email: string) => ui.validateEmail(email);

  const createEmailOption = (email: string) => {
    return { key: email, value: email, text: email };
  };

  const filter = debounce(async (e, inputProps: InputProps) => {
    const personsExtrContacts: Array<PersonModel | Contact> =
      await calendar.getPersonsExtrContacts(
        inputProps.searchQuery,
        ['SearchableDetailsPerson'],
        20,
        1
      );

    if (personsExtrContacts) {
      createSetOptions(personsExtrContacts, true);
    }

    calendar.setPageNumber(1);
  }, 500);

  const handleLoadMoreData = debounce(async () => {
    if (calendar.hasMoreData && !loadingData) {
      setIsLoadingData(true);

      const number = calendar.pageNumber + 1;
      calendar.setPageNumber(number);
      const personsExtrContacts: Array<PersonModel | Contact> =
        await calendar.getPersonsExtrContacts(
          calendar.searchQuery,
          ['SearchableDetailsPerson'],
          20,
          number,
          true
        );

      setIsLoadingData(false);

      if (personsExtrContacts?.length > 0) {
        createSetOptions(personsExtrContacts);
      }
    }
  }, 500);

  const handleOnChangeDropdown = async (e, d: DropdownProps) => {
    const invitees: string[] = d.value as string[];
    calendar.setPageNumber(1);
    const personExtrContacts = await person.getPersonsById(
      invitees.map((id) => parseFloat(id))
    );
    const personsWithAvailability = await checkAvailability(
      personExtrContacts as PersonModel[],
      null
    );

    setSelectedParticipantsIds(d.value as string[]);
    setSelectedParticipants(personsWithAvailability)

    handleClearSearch();
    handleFocus(true)();
  };

  const createPersonOption = (person: PersonModel) => {
    let nameOrPhoneNum;

    if (person.firstName && person.lastName) {
      nameOrPhoneNum = `${person.firstName} ${person.lastName}`;
    } else if (person.firstName || person.lastName) {
      nameOrPhoneNum = person.firstName || person.lastName;
    }

    return {
      'data-private': true,
      key: person.id.toString(),
      value: person.id.toString(),
      text: nameOrPhoneNum,
    };
  };

  const createExtrContactOptions = (extrContact: Contact) => {
    return {
      'data-private': true,
      key: extrContact.id.toString(),
      value: extrContact.id.toString(),
      text: extrContact.DisplayName(),
    };
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setCreatedEvent({ ...createdEvent, title: data.value });
  }

  const appendTimeToDate = (date: Moment, time: Moment) => {
    return showTimePicker
      ? date.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
        second: 0,
        millisecond: 0,
      })
      : date;
  };

  const createUpdateEvent = async () => {
    const start = appendTimeToDate(datePick.startDate, datePick.startTime);
    const end = appendTimeToDate(datePick.endDate, datePick.endTime);
    const type = calendar.selectedEvent ? 'update' : 'create';
    const isDateChanged =
      calendar.selectedEvent &&
      (start.format('YYYY:MM:DD').toString() !==
        moment(calendar.selectedEvent?.startDate)
          .format('YYYY:MM:DD')
          .toString() ||
        end.format('YYYY:MM:DD').toString() !==
        moment(calendar.selectedEvent?.endDate)
          .format('YYYY:MM:DD')
          .toString());

    const newEvent: IEvent = {
      ...createdEvent,
      startDate: start.toLocaleString(),
      endDate: end.toLocaleString(),
      allDay: !showTimePicker ? true : null,
      visibility: createdEvent.visibility ? createdEvent.visibility : null,
      participants: calendar.mapParticipantsToMatchIEvent(
        calendar.selectedEvent?.owner,
        selectedParticipants,
        isDateChanged
      ),
    };

    const eventWithConference = await createMeeting(newEvent, type);
    const resp =
      type === 'update'
        ? await calendar.updateEvent(eventWithConference || newEvent)
        : await calendar.createEvent(eventWithConference || newEvent);

    if (resp) {
      calendar.setSuggestionNull();
      calendar.setPersonsEventListNull();
      toogleCreateEventModal(null, false);
      type === 'update' && calendar.setSelectedEvent(null);
    }
  };

  const createMeeting = async (event: IEvent, type: 'update' | 'create') => {
    const invitees =
      event?.participants
        .map((participant) => ({ personId: participant.platformUserId }))
        .filter(Boolean) || [];
    const conferenceData: INewConference = {
      displayName: event.title,
      invitees: invitees,
      provider: 'bhive',
      shouldStart: false,
      visibility: 'Private',
      textToCopy: [],
      type: 'AdHocScheduled',
    };
    try {
      const creator = await person.loadPersonByIdGetIfMissingGet(
        person.loggedInPersonId
      );
      const name = creator.data.firstName;
      if (type === 'create') {
        const respConference = await conversation.postNewConference(
          conferenceData
        );
        const { data }: { data: IPrivateConversationConference } =
          respConference ? respConference : { data: null };
        event.conferencing = calendar.mapToConferecingData(
          respConference?.data
        ) as any;
        event.description =
          calendar.createEventDescription(
            data?.displayName,
            data?.id,
            name
          ) + (event.description ? `\n${event.description}` : '');
      } else if (type === 'update' && event.conferencing) {
        const oldTitle = event.description?.substring(
          event.description.indexOf("Video meeting '"),
          event.description.lastIndexOf("'.") + 1
        );
        event.description = event.description.replace(
          oldTitle,
          `Video meeting '${event.title}'.\n`
        );
      }
      return event;
    } catch (err) {
      return null;
    }
  };

  const onDescriptionChange = (
    event: React.FormEvent<HTMLTextAreaElement>,
    data: TextAreaProps
  ) =>
    setCreatedEvent({
      ...createdEvent,
      description: data.value.toLocaleString(),
    },);

  const handleFocus = (getData?: boolean) => () => {
    if (!calendar.searchQuery && getData) {
      loadDropDownOptions(true);
    }
    const dropdownInput: any = document.querySelector(
      '#eventParticipants input'
    );
    if (dropdownInput) {
      dropdownInput.value = '';
      dropdownInput.focus();
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    calendar.setSearchQuery('');
  };

  const removeAllPersonsEvents = () =>
    selectedParticipants.forEach(
      (person) =>
        person?.id && calendar.personEventList.delete(person?.id)
    );

  const checkAvailability = async (
    persons: PersonModel[],
    datePicker: DatePick,
    allDaySelected?: boolean
  ) => {
    const datePic = datePicker || datePick;
    const { startDate, startTime, endDate, endTime } = datePic;
    let allFree = true;
    const personsExtended = await Promise.all(
      persons?.map(async (person) => {
        const start = appendTimeToDate(startDate, startTime);
        const end = appendTimeToDate(endDate, endTime);
        person = await calendar.getPersonAvailability(
          person as PersonModel,
          start,
          end,
          allDaySelected
        );
        if (person?.availability === 'Busy') {
          allFree = false;
        }
        return person;
      })
    );

    allFree && calendar.setSuggestionNull();

    return personsExtended;
  };

  const removeAllDuplicates = (options: DropdownItemProps[]) => {
    const cleanedOptions = uniqBy(options, (participant) => participant?.key);
    cleanedOptions.forEach((option) => {
      const person = selectedParticipants.find(
        (person: PersonModel) => person?.id.toString() === option.key
      );
      option.label = fullNameToInitials(option.text.toString());
      if (person) {
        option.availability = (person as PersonModel).availability;
        option.label = fullNameToInitials(person.DisplayName.toString());
      }
    });
    return cleanedOptions;
  };

  const handleRemoveItem = (defaultProps: LabelProps, item) => (e) => {
    calendar.personEventList.delete(item.key);
    defaultProps.onRemove(e, item);
  };

  const handleSuggestionClick = async () => {
    const newDatePick = {
      endDate: calendar.suggestion.to,
      endTime: calendar.suggestion.to,
      startDate: calendar.suggestion.from,
      startTime: calendar.suggestion.from,
    };

    removeAllPersonsEvents();

    const personsWithAvailability = await checkAvailability(
      selectedParticipants as PersonModel[],
      newDatePick
    );

    setSelectedParticipants(personsWithAvailability)
    setDatePick(newDatePick)
  };

  const closeEventModal = (e) => {
    toogleCreateEventModal(e, false);
  };

  const finalOptions = removeAllDuplicates(options);
  const today = useMemo(() => moment(), []);
  const hideFullDate =
    calendar.suggestion?.from.format('YYYY:MM:DD').toString() ===
    today.format('YYYY:MM:DD').toString() &&
    calendar.suggestion?.to.format('YYYY:MM:DD').toString() ===
    today.format('YYYY:MM:DD').toString();
  const format = hideFullDate ? 'hh:mm a' : 'DD.MM.YYYY hh:mm a';

  useEffect(() => {
    loadDropDownOptions();

    calendar.selectedEvent && mapEventToTheFormData(calendar.selectedEvent);
  }, [calendar.selectedEvent])

  return (
    <div
      className="calendar-event"
      id="calendar-event"
    >
      <div className="screen">
        <div className="top-icons">
          <IconButton
            icon={'cancel'}
            onClick={closeEventModal}
            size='small'
            testid={`${testId}-buttonClose`}
          />
        </div>

        <Heading variant='h2'>Create an Event</Heading>

        <div className="event-input event-title">
          <Styled.CalendarEventTimeLabel variant={'h6'}>EVENT TITLE</Styled.CalendarEventTimeLabel>

          <Input
            data-private
            value={createdEvent.title || ''}
            placeholder="My New Event"
            id="title"
            onChange={handleChangeInput}
            testid={`${testId}-inputEventTitle`}
          />
        </div>

        <CalendarEventTime
          {... {
            checkAvailability,
            removeAllPersonsEvents,
            datePick,
            setDatePick,
            showTimePicker,
            setShowTimePicker,
            selectedParticipants,
            setSelectedParticipants,
            selectedParticipantsIds,
            setSelectedParticipantsIds,
            ui,
            testId
          }}
        />

        <div className="event-input">
          <Styled.CalendarEventTimeLabel variant={'h6'}>INVITE USERS</Styled.CalendarEventTimeLabel>

          <Dropdown
            placeholder={selectedParticipantsIds.length ? '' : 'ex. John Doe'}
            icon={
              isEmpty(createdEvent.participants) && (
                <Styled.SearchIcon icon="search" size="small" />
              )
            }
            className={`invite-group ${selectedParticipantsIds.length > 0
              ? 'search-has-data'
              : 'search-no-data'
              }`}
            id="eventParticipants"
            fluid
            multiple
            search
            selection
            testid={`${testId}-eventParticipantsDropdown`}
            searchQuery={searchQuery}
            value={selectedParticipantsIds}
            options={finalOptions}
            onChange={handleOnChangeDropdown}
            onSearchChange={onSearch}
            onOpen={handleScrollDown}
            onClick={handleFocus(false)}
            onClose={handleClearSearch}
            selectedLabel={3}
            renderLabel={(item, index, defaultProps) => {
              return (
                <div className="selected-users">
                  <UserAvatar
                    name={item.text as any}
                    selectUnreadCounts={
                      ui.selectConversationUnreadCounts
                    }
                    presence={null}
                  />
                  <div className="availability">
                    <span
                      data-private
                      className={`blocks name ${!item.availability ? 'no-availability' : ''
                        }`}
                    >
                      {item.text}
                    </span>
                    {item.availability && (
                      <span
                        className={`blocks ${item.availability?.toLowerCase() || ''
                          }`}
                      >
                        {item.availability}
                      </span>
                    )}

                    <Styled.RemoveUserButtonContainer>
                      <IconButton
                        icon={'cancel'}
                        onClick={handleRemoveItem(defaultProps, item)}
                        size='mini'
                        testid={`${testId}-buttonRemoveUser`}
                      />
                    </Styled.RemoveUserButtonContainer>
                  </div>
                </div>
              );
            }}
          />
        </div>

        {calendar.suggestion && (
          <div className="suggestions" onClick={handleSuggestionClick}>
            Suggested time:{' '}
            <span>{`${calendar.suggestion?.from.format(
              format
            )} - ${calendar.suggestion?.to.format(format)}`}</span>
          </div>
        )}

        <div className="event-input">
          <Styled.CalendarEventTimeLabel id="eventDescription" variant={'h6'}>
            EVENT DESCRIPTION
          </Styled.CalendarEventTimeLabel>

          <TextArea
            data-private
            placeholder="Event Description"
            className="inverted"
            rows={3}
            value={createdEvent.description || ''}
            onChange={onDescriptionChange}
            testid={`${testId}-eventDescriptionTextArea`}
          />
        </div>

        <Styled.SaveButton
          variant={'primary'}
          onClick={createUpdateEvent}
          id="save-event"
          testid={`${testId}-buttonSave`}
          content={'SAVE'}
        />
      </div>
    </div>
  );
}
