import { isEmpty } from 'lodash';
import { action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Header } from 'semantic-ui-react';
import { ChannelHeaderProps } from './interfaces';

/**
 * Child of `ContextHeader`, displays contextual header information about a `Conversation` in `CONVERSATION_TYPE.Channel`.
 *
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ChannelHeader
 */
export class ChannelHeader extends React.Component<ChannelHeaderProps, {}> {
  @action
  changeConvTopic = () => {
    const { conversation, addProperName, person } = this.props;
    const copy = { ...conversation };
    copy.topic =
      '#' +
      addProperName(
        conversation.participants.filter(
          (participant) => participant.personId !== person.loggedInPersonId
        ),
        'channelHeader'
      );
    return copy;
  };

  constructor(props: ChannelHeaderProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { conversation, person } = this.props;
    const conversationCopy =
      person && conversation.grouping === 'Group'
        ? this.changeConvTopic()
        : conversation;
    return (
      <Header as="h2" className="context-header-display valign-middle">
        <Header.Content
          className="channel-header-topic"
          content={conversationCopy.topic}
        />
        {!isEmpty(conversationCopy.description) && (
          <Header.Subheader
            className="channel-header-description"
            content={conversationCopy.description}
          />
        )}
      </Header>
    );
  }
}

export default observer(ChannelHeader);
