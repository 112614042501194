import { IS_ELECTRON, NODE_ENV_LOCAL_OR_DEVELOPMENT } from 'Constants/env';
import {
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_ROUTER,
  STORE_UI,
} from 'Constants/stores';
import { WN_MESSAGE_CREATED_PREFIX } from 'Constants/webNotifications';
import * as localforage from 'localforage';
import { has, isEmpty, throttle } from 'lodash';
import { inject, observer } from 'mobx-react';
import { now } from 'mobx-utils';
import * as React from 'react';
import { isNullOrUndefined } from 'util';
import { UiBridgeProps } from './interfaces';

export class UiBridge extends React.Component<UiBridgeProps> {
  private onWindowFocus = async () => {
    const { ui, router, notification } = this.props;
    ui.setLastFocus(now());
    const conversationId = router.currentConversationId();
    if (!isEmpty(conversationId)) {
      notification.closeWebNotification(
        WN_MESSAGE_CREATED_PREFIX + conversationId
      );
      await ui.setConversationAndTotalUnreadCount(conversationId, 0, null, 0);
    }
  };
  private onWindowBlur = () => {
    this.props.ui.setLastBlur(now());
  };

  private checkAndSetFocus = () => {
    if (document.hasFocus()) {
      this.onWindowFocus();
    } else {
      this.onWindowBlur();
    }
  };

  private onVisibilityChange = () => {
    if (!document.hidden) {
      this.onWindowFocus();
    } else {
      this.onWindowBlur();
    }
  };

  private onMouseActivity = (e: MouseEvent) => {
    this.props.ui.setLastKeyboardMouseActivity(now());
  };
  private onMouseActivityThrottled = throttle(this.onMouseActivity, 200);

  private onKeyboardActivity = (e: KeyboardEvent) => {
    this.props.ui.setLastKeyboardMouseActivity(now());
  };
  private onKeyboardActivityThrottled = throttle(this.onKeyboardActivity, 200);

  /** Triggers an OffLine Presence status update on tab/window close*/
  onBrowserWindowClose = async () => {
    const { message, participant, person, ui, router } = this.props;
    if (person.IsLoggedIn) {
      if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
        console.debug('onBrowserWindowClose reporting OffLine Presence');
      }
      try {
        const voxeetSessionId = await localforage.getItem<string>(
          'voxeetSession'
        );
        ui.updatePresence('OffLine', false, voxeetSessionId);
      } catch (e) {
        console.error(
          'onBrowserWindowClose Could not update presence to OffLine.'
        );
      }

      const currentConversationId = router.currentConversationId();
      if (!isNullOrUndefined(currentConversationId)) {
        try {
          const convGroupedMsgsCtr =
            message.selectGroupedMessagesForConversation(currentConversationId);
          if (!isNullOrUndefined(convGroupedMsgsCtr)) {
            await participant.updateMyLastReadMessage(
              currentConversationId,
              convGroupedMsgsCtr.NewestMessageId
            );
            await ui.setConversationAndTotalUnreadCount(
              currentConversationId,
              0,
              null,
              0
            );
            if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
              console.debug(
                `onBrowserWindowClose updateLastReadMessage for ${currentConversationId} to ${convGroupedMsgsCtr.NewestMessageId}, set unread counts to 0.`
              );
            }
          } else {
            console.warn(
              `onBrowserWindowClose could not retrieve Messages for ${currentConversationId}, and will not update its last read Message.`
            );
          }
        } catch (e) {
          console.error(
            'onBrowserWindowClose Could not update last read Message for',
            currentConversationId
          );
        }
      } else {
        console.info(
          'onBrowserWindowClose was not able to retrieve a current Conversation Id. This is not an error if there was no Conversation selected.'
        );
      }
    } else {
      console.info('onBrowserWindowClose user is not logged in.');
    }
    return null;
  };
  componentDidMount() {
    this.checkAndSetFocus();
    this.onVisibilityChange();
    window.addEventListener('focus', this.onWindowFocus);
    window.addEventListener('blur', this.onWindowBlur);
    document.addEventListener('visibilitychange', this.onVisibilityChange);
    document.addEventListener('mousemove', this.onMouseActivityThrottled);
    document.addEventListener('keypress', this.onKeyboardActivityThrottled);
    // Inside an Electron renderer process
    if (IS_ELECTRON && has(window, 'ipcRenderer')) {
      const ipcRenderer = (window as any).ipcRenderer; // as typeof import('electron').ipcRenderer but this causes a build failure so yeah.
      ipcRenderer.on('window-close', this.onBrowserWindowClose);
    } else {
      window.addEventListener('beforeunload', this.onBrowserWindowClose);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onWindowFocus);
    window.removeEventListener('blur', this.onWindowBlur);
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
    document.removeEventListener('mousemove', this.onMouseActivityThrottled);
    document.removeEventListener('keypress', this.onKeyboardActivityThrottled);
  }

  render() {
    return <div id="ui-bridge-dummy"></div>;
  }
}

export default inject(
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_UI,
  STORE_ROUTER
)(observer(UiBridge));
