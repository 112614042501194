import { VIDEO_URL } from 'Constants/env';
import { STORE_CONVERSATION, STORE_PERSON, STORE_UI } from 'Constants/stores';
import { CONFERENCE_ID_REGEXP } from 'Constants/validation';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Button, Grid, Input, Message, Popup } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { ConversationStore, PersonStore, UiStore } from '../../../../stores';
import RoomPin from '../../../RoomPin';
import { CopyComponent } from '../../CopyComponent';

import closeBtn from 'Assets/images/close-btn.svg';
import editIcon from 'Assets/images/edit-name.svg';
import withRouter from '../../../../hocs/WithRouter';
import { Heading } from '../../../shared/Heading';
import { Styled } from './index.styles';

export class TrialExpDate extends React.Component<
  { person: PersonStore; ui: UiStore },
  { interested: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      interested: false,
    };
  }
  handleInterested = () => {
    this.setState({ interested: true });
    this.props.person.handleVideoTrialInterested();
  };

  render() {
    const { person, ui } = this.props;
    const { interested } = this.state;
    const trialExpDate = ui.getTimeLeft(
      person.personAvaliableFeatures.video.expirationDate
    );
    return (
      <div className="trial-expired-box">
        <div className="title">
          <span>Free trial time left:</span> {trialExpDate}
        </div>
        <div>
          {!interested
            ? 'Let us know if you are interested in using this feature after trial expires.'
            : 'Thank you for letting us know. We will shortly contact you about the next steps.'}
        </div>
        <Button
          id="interested-video"
          disabled={interested}
          content="I AM INTERESTED"
          onClick={this.handleInterested}
        />
      </div>
    );
  }
}

interface MyRoomState {
  inputValue: string;
  showEditingMode: boolean;
  showErrorMessage: boolean;
  copied: boolean;
  hovered: boolean;
  interested: boolean;
  showMorePhoneNumbers: boolean;
  videoPopup: Window;
}
class MyRoom extends React.Component<
  {
    conversation: ConversationStore;
    ui: UiStore;
    person: PersonStore;
  },
  MyRoomState
> {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: null,
      showEditingMode: false,
      showErrorMessage: false,
      copied: false,
      hovered: false,
      interested: false,
      showMorePhoneNumbers: false,
      videoPopup: null,
    };
  }
  componentWillMount() {
    const { conversation } = this.props;
    conversation.postConferencePrivateRoom();
  }

  handleInterested = () => this.setState({ interested: true });
  handleInput = ({ currentTarget }) => {
    const {
      activeConversationError,
      setActiveConversationError,
      setPrivateRoomNameTaken,
      privateRoomNameTaken,
    } = this.props.conversation;
    this.setState({ inputValue: currentTarget.value });
    if (activeConversationError) {
      setActiveConversationError(false);
    }
    if (privateRoomNameTaken) {
      setPrivateRoomNameTaken(false);
    }
  };
  toggleInput = () => {
    const {
      privateRoomData,
      activeConversationError,
      setActiveConversationError,
      setPrivateRoomNameTaken,
      privateRoomNameTaken,
    } = this.props.conversation;
    const { showEditingMode } = this.state;
    this.setState({
      showEditingMode: !showEditingMode,
      inputValue: privateRoomData?.id,
      showErrorMessage: false,
    });
    if (activeConversationError) {
      setActiveConversationError(false);
    }
    if (privateRoomNameTaken) {
      setPrivateRoomNameTaken(false);
    }
  };
  handleOpenVideoPopup = (conferenceId: string) =>
    debounce(() => {
      if (this.state.videoPopup && !this.state.videoPopup.closed) {
        this.state.videoPopup.focus();
      } else {
        const videoPopup = this.props.conversation.openVideoPopup(conferenceId);
        this.setState({ videoPopup: videoPopup });
      }
    }, 500);
  checkIsValid = () => {
    const { inputValue } = this.state;
    const {
      conversation: { privateRoomData },
    } = this.props;
    const data = inputValue || privateRoomData.id;
    const checkLength = data.length > 2 && data.length < 37;
    const isValid = CONFERENCE_ID_REGEXP.test(data) && checkLength;
    this.setState({ showErrorMessage: isValid ? false : true });
    return isValid;
  };
  handleUpdateRoomName = async () => {
    const {
      conversation: { updateConferencePrivateRoom, privateRoomData },
    } = this.props;
    if (this.checkIsValid()) {
      const resp = await updateConferencePrivateRoom(
        this.state.inputValue || privateRoomData?.id
      );
      if (resp?.status === 200) {
        this.setState({ showErrorMessage: false });
        this.toggleInput();
      }
    }
  };

  handleCopy = () => {
    const {
      conversation: { privateRoomData },
      ui,
    } = this.props;
    const dialNumber =
      privateRoomData?.dialInInfos?.length > 0
        ? formatPhoneNumberIntl(privateRoomData?.dialInInfos[0]?.number)
        : '';
    const pin = privateRoomData?.pin?.match(/.{1,3}/g).join(' ') || '';
    ui.copyToClipboard([
      VIDEO_URL + privateRoomData?.id + `\nDial in: ${dialNumber}\nPIN: ${pin}`,
    ]);
    this.setState({ copied: true });
  };

  handleHover = (e) => this.setState({ hovered: true });

  handleLeave = (e) => this.setState({ hovered: false });

  handleShowMorePhoneNumbers = () => {
    this.setState({
      ...this.state,
      showMorePhoneNumbers: !this.state.showMorePhoneNumbers,
    });
  };

  render() {
    const {
      inputValue,
      showEditingMode,
      showErrorMessage,
      hovered,
      copied,
      interested,
    } = this.state;
    const { conversation, ui, person } = this.props;
    const { privateRoomData, privateRoomNameTaken, activeConversationError } =
      conversation;
    const dialNumber =
      privateRoomData?.dialInInfos?.length > 0
        ? formatPhoneNumberIntl(privateRoomData?.dialInInfos[0]?.number)
        : '';
    return (
      <div className="private-room-container">
        <Styled.HeaderWrapper>
          <Heading variant="h1">My Personal Room</Heading>
          {person.personAvaliableFeatures.video.enabled &&
            (!showEditingMode ? (
              <Popup
                position="left center"
                inverted
                trigger={
                  <img
                    src={editIcon}
                    className="edit-btn"
                    onClick={this.toggleInput}
                  />
                }
                content="Edit personal room name"
              />
            ) : (
              <Popup
                inverted
                position="left center"
                trigger={
                  <img
                    className="close-btn"
                    src={closeBtn}
                    onClick={this.toggleInput}
                  />
                }
                content="Close editing mode"
              />
            ))}
        </Styled.HeaderWrapper>
        {!showEditingMode ? (
          <div className="private-room">
            <div className="flex-row">
              <span data-private className="title">
                {privateRoomData?.id || 'Loading ...'}
              </span>
            </div>
            <Button
              disabled={
                !person.personAvaliableFeatures.video.enabled ||
                isNullOrUndefined(privateRoomData?.id)
              }
              id="start-meeting"
              content="START MEETING"
              className="flex-grow-shrink"
              onClick={this.handleOpenVideoPopup(privateRoomData?.id)}
            />
            {person.personAvaliableFeatures.video.enabled &&
              person.personAvaliableFeatures.video.expirationDate && (
                <TrialExpDate ui={ui} person={person} />
              )}
            {person.personAvaliableFeatures.video.enabled ? (
              <div className="info">
                <div
                  className={`connection-info ${copied ? 'copied' : ''}`}
                  onMouseOver={this.handleHover}
                  onMouseLeave={this.handleLeave}
                >
                  <span className="title pointer" onClick={this.handleCopy}>
                    Connection Information
                  </span>
                  <CopyComponent
                    link={
                      VIDEO_URL +
                      privateRoomData?.id +
                      `\nDial in: ${dialNumber}\nPIN: ${privateRoomData?.pin}`
                    }
                    ui={ui}
                    hovered={hovered}
                    copied={copied}
                    handleCopy={this.handleCopy}
                  />
                </div>
                <span data-private className="video-url">
                  {VIDEO_URL + privateRoomData?.id}
                </span>
                <span data-private className="flex-row margin-top-1rem">
                  <b>Dial-in:&nbsp;</b> {dialNumber}
                </span>
                <span data-private className="flex-row margin-top-1rem">
                  <b>PIN: </b> <RoomPin pin={privateRoomData?.pin} />
                </span>
                {privateRoomData?.dialInInfos?.length > 1 && (
                  <div className="flex-column margin-top-1rem">
                    <span
                      className="toggle-more-phones"
                      onClick={this.handleShowMorePhoneNumbers}
                    >
                      {this.state.showMorePhoneNumbers
                        ? 'Less phone numbers'
                        : 'More phone numbers'}
                    </span>
                    {this.state.showMorePhoneNumbers && (
                      <div className="flex-column">
                        {privateRoomData?.dialInInfos
                          ?.slice(1)
                          .map((el, index) => (
                            <div key={`number-${el.number}-${index}`}>
                              <span className="flex-row margin-top-1rem">
                                {el.number}
                              </span>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="trial-box">
                <div className="title">
                  Feature available for Pro Seat Users
                </div>
                <div className="trial-box-body">
                  {!interested
                    ? 'Video Conferencing is available for users with a Pro seat. Let us know if you are interested in using this feature.'
                    : 'Thank you for letting us know. We will shortly contact you about the next steps.'}
                </div>
                <Button
                  id="interested-video"
                  disabled={interested}
                  content="I AM INTERESTED"
                  onClick={this.handleInterested}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="editing-mode">
            <div>Rename your personal room</div>
            <div>
              Your room name can consist only of letters, numbers, dot,
              underscore, dash, and it should be 3-36 characters.
            </div>
            <div className="flex-row">
              <Input
                data-private
                placeholder="Meeting name"
                value={inputValue !== null ? inputValue : privateRoomData?.id}
                onChange={this.handleInput}
              />
              <Button content="SAVE" onClick={this.handleUpdateRoomName} />
            </div>
            {showErrorMessage && (
              <Message error className="error-background-groups">
                <Message.Content content="Personal room must..." />
                <Message.List>
                  <Message.Item content={`Contain 3 to 36 characters`} />
                  <Message.Item content="Start with a letter" />
                  <Message.Item content='Contain only letters, numbers, underscores ("_"), and dashes ("-"), and dots (".")' />
                </Message.List>
              </Message>
            )}
            {privateRoomNameTaken && (
              <Message
                error
                className="error-background-groups name-taken"
                visible={true}
              >
                <Message.Header content="Name Already Taken" />
                <Message.Content content="Please choose another name" />
              </Message>
            )}
            {activeConversationError && (
              <Message
                error
                className="error-background-groups name-taken"
                visible={true}
              >
                <Message.Header content="Personal room is currently in use" />
                <Message.Content content="Please finish your meeting if you wish to change the name" />
              </Message>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default inject(
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PERSON
)(withRouter(observer(MyRoom)));
