import styled from 'styled-components';

const EmojiWrapper = styled.div`
  display: flex;
  width: 15px;
  height: 15px;
  z-index: 2;
  cursor: pointer;
`;

export const Styled = { EmojiWrapper }