import React from 'react';
import { HELP_URL } from '../../../constants/env';
import { UserDropdownMenuHelpProps } from './interfaces';
import { Styled } from './UserDropdownMenuHelp.styles';

export const UserDropdownMenuHelp: React.FC<UserDropdownMenuHelpProps> = ({
  testid,
}) => {
  return (
    <Styled.UserDropdownMenuHelp>
      <Styled.UserDropdownMenuHelpLink
        href={HELP_URL}
        data-testid={testid}
        target="_blank"
        className="ui basic compact button"
      >
        Help
      </Styled.UserDropdownMenuHelpLink>
    </Styled.UserDropdownMenuHelp>
  );
};
