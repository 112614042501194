import React from 'react';
import Iframe from 'react-iframe-click';
import { Styled } from './index.styles';
import type { IframeContainerProps } from './types';
import { ContentArea } from '../../components/shared/ContentArea';

export const IframeContainer: React.FC<IframeContainerProps> = ({ src }) => (
  <Styled.IframeContainer>
    <ContentArea>
      <Iframe
        //@ts-ignore
        src={src}
        width="100%"
        height="99%"
        frameBorder={0}
        onInferredClick={() => {}} /* required by react-iframe-click */
      />
    </ContentArea>
  </Styled.IframeContainer>
);
