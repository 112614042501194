import { inject, observer } from 'mobx-react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import SmallSidebarMenu from '../../components/SmallSidebarMenu';
import Softphone from '../../modules/softphone/components/Softphone';
import Notification from 'Containers/Notification';
import { ILayoutProps } from './interfaces';
import {
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_CONFIG,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_PREFERENCE,
  STORE_UI,
  STORE_MESSAGE,
  STORE_CONVERSATION,
} from '../../constants/stores';
import { Styled } from './index.styles';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from '../../constants/responsiveness';
import SidebarMargin from '../../components/shared/SidebarMargin';

const Layout = (props: ILayoutProps) => {
  const {
    ui: { isTouchSupported, hideSecondaryMenu, setHideSecondaryMenu },
  } = props;

  const renderTouchBackground =
    isTouchSupported() &&
    !hideSecondaryMenu &&
    window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT;

  return (
    <>
      <SmallSidebarMenu />
      <Outlet />
      <SidebarMargin />
      <Softphone />
      <Notification {...props} {...props.browserHistory} />
      <Styled.TouchBackground
        $renderTouchBg={renderTouchBackground}
        onClick={() => setHideSecondaryMenu(true)}
      />
    </>
  );
};

export default inject(
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_CONFIG,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_PREFERENCE,
  STORE_UI,
  STORE_MESSAGE,
  STORE_CONVERSATION
)(observer(Layout));
