import React from 'react';
import { inject, observer } from 'mobx-react';
import { STORE_CONVERSATION, STORE_UI } from 'Constants/stores';
import { VIDEO_URL } from 'Constants/env';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { CopyComponent } from '../../../../CopyComponent';
import RoomPin from '../../../../../RoomPin';
import { ConnectionInfoProps } from './types';
import { Styled } from './index.styles';

const ConnectionInfoObserver: React.FC<ConnectionInfoProps> = ({
  ui,
  conversation: { videoHistoryDetails },
}) => {
  const [copied, setCopied] = React.useState(false);
  const [showMorePhoneNumbers, setShowMorePhoneNumbers] = React.useState(false);
  const dialNumber: string =
    videoHistoryDetails?.data?.dialInInfos?.length > 0
      ? formatPhoneNumberIntl(videoHistoryDetails?.data?.dialInInfos[0]?.number)
      : '';

  const url =
    (VIDEO_URL as string) +
    videoHistoryDetails?.data?.conferenceId +
    `\nDial in: ${dialNumber}\nPIN: ${videoHistoryDetails?.data?.pin}`;

  const handleCopy = () => {
    const dialNumber: string =
      videoHistoryDetails?.data?.dialInInfos?.length > 0
        ? formatPhoneNumberIntl(
            videoHistoryDetails?.data?.dialInInfos[0]?.number
          )
        : '';
    const pin =
      videoHistoryDetails?.data?.pin?.match(/.{1,3}/g).join(' ') || '';
    const textToCopy = [
      `${VIDEO_URL as string}${videoHistoryDetails?.data?.conferenceId}` +
        ` \nDial in: ${dialNumber}\nPIN: ${pin}`,
    ];
    ui.copyToClipboard(textToCopy);
    setCopied(true);
  };

  const toggleShowMorePhoneNumbers = () => {
    setShowMorePhoneNumbers(!showMorePhoneNumbers);
  };

  return (
    <Styled.ConnectionInfoStyled>
      <div>
        <Styled.LinkStyled>Connection Information</Styled.LinkStyled>
        <CopyComponent
          link={url}
          ui={ui}
          hovered={false}
          copied={copied}
          handleCopy={handleCopy}
        />
        <Styled.ConnectionInfoStyled>
          <Styled.VideoUrlStyled data-private>
            {(VIDEO_URL as string) + videoHistoryDetails?.data?.conferenceId}
          </Styled.VideoUrlStyled>

          <Styled.FlexRowSpanStyled data-private>
            <b>Dial-in:&nbsp;</b> {dialNumber}
          </Styled.FlexRowSpanStyled>
          <Styled.FlexRowSpanStyled data-private>
            <b>PIN: </b> <RoomPin pin={videoHistoryDetails?.data?.pin} />
          </Styled.FlexRowSpanStyled>
          {videoHistoryDetails?.data?.dialInInfos?.length > 1 && (
            <Styled.MorePhonesContainerStyled>
              <Styled.ToggleMorePhonesStyled
                onClick={toggleShowMorePhoneNumbers}
              >
                {showMorePhoneNumbers
                  ? 'Less phone numbers'
                  : 'More phone numbers'}
              </Styled.ToggleMorePhonesStyled>
              {showMorePhoneNumbers && (
                <Styled.MorePhonesStyled>
                  {videoHistoryDetails?.data?.dialInInfos
                    ?.slice(1)
                    .map((el, index) => (
                      <Styled.DialInInfoStyled
                        key={`number-${el.number}-${index}`}
                      >
                        <Styled.FlexRowSpanStyled>
                          {el.number}
                        </Styled.FlexRowSpanStyled>
                      </Styled.DialInInfoStyled>
                    ))}
                </Styled.MorePhonesStyled>
              )}
            </Styled.MorePhonesContainerStyled>
          )}
        </Styled.ConnectionInfoStyled>
      </div>
    </Styled.ConnectionInfoStyled>
  );
};

export const ConnectionInfo = inject(
  STORE_UI,
  STORE_CONVERSATION
)(observer(ConnectionInfoObserver));
