import type {
  ICallLog,
  ICallLogsSearch,
} from 'Components/CallsDirectoryList/interface';
import { API_ENDPOINTS } from 'Constants/env';
import {
  action,
  computed,
  observable,
  ObservableMap,
  makeObservable,
  toJS,
} from 'mobx';
import type { IObservableArray } from 'mobx';
import { fromPromise } from 'mobx-utils';
import { BaseStore } from './BaseStore';
import { RootStore } from './RootStore';
import IncomingCallIcon from 'Assets/images/incoming-call.svg';
import MissedCallIcon from 'Assets/images/missed-call.svg';
import OutgoingCallIcon from 'Assets/images/outgoing-call.svg';
import { isNullOrUndefined } from 'util';
import API from '../api';
import {
  CALL_LOG_CATEGORY,
  CALL_LOG_DIRECTION,
  CALL_LOG_DISPOSITION,
} from '../constants/enums';
import { PhoneCallModel } from '../models';
import { DateTime } from 'luxon';
import localforage from 'localforage';
import { RECENT_CALLS } from '../constants/localstorage';
import { uniqBy } from 'lodash';
import type { CancelTokenSource } from 'axios';

export class CallLogsStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    // Uncomment code bellow in case local call logs get enabled
    // localforage.getItem(RECENT_CALLS).then((list) => {
    //   this.setNewestCallLogs(list);
    // });
    makeObservable(this);
  }

  @action
  clearAllData = () => {};

  /**
   * Process search call logs
   * @param limit items per page
   * @param page page num
   * @param state Call log's call state / ENDED, MISSED, REJECTED
   * @param direction Call log's call direction / INCOMING, OUTGOING
   * @param query Call log's from/to normalized number or SIP address.
   * @param appendResults (Default: `false`) If `true`, append results to the existing `DirectorySearchModel`, instead of creating new results
   */
  @observable callLogsLoading: boolean = false;
  @observable shouldGetCallLogs: boolean = false;
  @action setCallLogsLoading = (state: boolean) =>
    (this.callLogsLoading = state);

  @observable callPageNum: number = 1;
  @action setCallPageNum = (pageNum: number) => (this.callPageNum = pageNum);

  @observable callLogs: IObservableArray<ICallLog> = observable.array();

  @observable totalCallLogs: number = 0;

  @observable typeOfCalls: CALL_LOG_CATEGORY = null;

  @action setTypeOfCalls = (value: CALL_LOG_CATEGORY | null) =>
    (this.typeOfCalls = value);

  /** Saved call logs for first page for every category */
  @observable firstPageCallLogs: ObservableMap<string, ICallLog[]> =
    observable.map();

  @action setFirstPageCallLogs = (
    callLogCategory: CALL_LOG_CATEGORY,
    callLogs: ICallLog[]
  ) => {
    this.firstPageCallLogs.set(callLogCategory, callLogs);
  };

  /** Call logs for which pusher event still didn't come*/
  @observable newestCallLogs: ObservableMap<string, ICallLog> =
    observable.map();

  @action setNewestCallLogs = (callLogs: any) => {
    this.newestCallLogs = observable.map(callLogs);
  };

  @action addNewestCallLog = (sessionId: string, callLog: ICallLog) => {
    this.newestCallLogs.set(sessionId, this.mapImageToCallLogs([callLog])[0]);
    localforage.setItem(RECENT_CALLS, toJS(this.newestCallLogs));
  };

  @observable lastSyncDate: string = null;

  @action setLastSyncDate = (value: string) => (this.lastSyncDate = value);

  @observable searchQuery: string = '';

  @action setSearchQuery = (value: string) => (this.searchQuery = value);

  @action updateCallLogBySessionId = (
    sessionId: string,
    disposition: CALL_LOG_DISPOSITION = null,
    callModel?: PhoneCallModel
  ) => {
    if (this.newestCallLogs.has(sessionId)) {
      const callLog = this.newestCallLogs.get(sessionId);
      const data = {
        ...callLog,
        category:
          this.directions[`${callLog.direction}_${disposition}`.toLowerCase()],
        disposition,
        duration: callModel?.elapsedTime || 0,
        answeredAt: callModel?.pickUpTime,
        endedAt:
          DateTime.fromMillis(
            DateTime.fromISO(callModel?.pickUpTime).valueOf() +
              callModel?.elapsedTime
          ).toString() || '',
      };
      this.newestCallLogs.set(sessionId, this.mapImageToCallLogs([data])[0]);
      localforage.setItem(RECENT_CALLS, toJS(this.newestCallLogs));
    }
  };

  @computed
  get CallLogs() {
    const onLine = window.navigator.onLine;
    const recentCalls = this.newestCallLogs.values();
    const currentCatCallLogs =
      this.firstPageCallLogs.get(this.typeOfCalls) || [];
    const firstPageCallLogs = !onLine
      ? Array.from(this.firstPageCallLogs.values()).reduce(
          (acc, curr) => [...acc, ...curr],
          []
        )
      : currentCatCallLogs;

    const callLogs = this.filterCallLogsByCategory([
      ...Array.from(recentCalls).reverse(),
      ...firstPageCallLogs,
      ...(onLine ? this.callLogs : []),
    ]);
    const sortedCallLogs = this.sortCallLogs(uniqBy(callLogs, (cl) => cl?.id));

    return onLine
      ? sortedCallLogs
      : sortedCallLogs.slice(0, this.numOfCallLogsForOfflineMode);
  }

  public numOfCallLogsForOfflineMode = 20;

  public directions = {
    inbound_missed: 'missed',
    inbound_busy: 'incoming',
    inbound_answered: 'incoming',
    inbound_cancel: 'incoming',
    outbound_missed: 'outgoing',
    outbound_busy: 'outgoing',
    outbound_answered: 'outgoing',
    outbound_cancel: 'outgoing',
  };

  @action
  eventCreatedSuccesfully(callLog: ICallLog) {
    this.shouldGetCallLogs = true;
    this.setCallLogsLoading(true);
    this.callLogs.unshift(this.mapImageToCallLogs([callLog])[0]);
    setTimeout(() => this.setCallLogsLoading(false), 300);
  }

  @action
  getCallLogs = async (
    limit: number,
    page: number,
    direction: CALL_LOG_DIRECTION = null,
    disposition: CALL_LOG_DISPOSITION = null,
    typeOfCalls: CALL_LOG_CATEGORY = null,
    query: string = '',
    appendResults: boolean = false,
    cancelToken: CancelTokenSource
  ) => {
    //on user input, searchPageNum is default value
    const searchPageNum = query ? 1 : this.callPageNum;
    this.setTypeOfCalls(typeOfCalls);
    this.setSearchQuery(query?.toLowerCase().trim());
    try {
      this.setCallLogsLoading(true);
      const lastCallLog = this.callLogs.slice(-1)[0];
      const callsLogsPbo = await fromPromise(
        API.get(
          API_ENDPOINTS.CallLogsSearch(
            limit,
            direction,
            disposition,
            query,
            searchPageNum !== 1 ? lastCallLog?.id : null
          ),
          {
            cancelToken: cancelToken.token,
          }
        )
      );
      const callLogsResults = callsLogsPbo.data;

      appendResults = query
        ? this.callLogs.length < callLogsResults.total && searchPageNum !== 1
        : appendResults;
      this.getCallLogsSuccess(callLogsResults, appendResults, searchPageNum);
      this.setCallLogsLoading(false);
      return callLogsResults;
    } catch (err) {
      return err;
    }
  };

  @action
  getCallLogsSuccess = (
    callLogs: ICallLogsSearch,
    appendResult: boolean,
    searchPageNum: number
  ) => {
    const callItemsWithImage = this.mapImageToCallLogs(callLogs.data);
    this.callLogs = appendResult
      ? observable.array([
          ...this.callLogs,
          ...this.removeDuplicates(this.callLogs, callItemsWithImage),
        ])
      : observable.array(callItemsWithImage);
    this.syncFirstPageCallLogs(searchPageNum);
    this.totalCallLogs = callLogs.total;
  };

  filterAndSetFirstPageCallLogs = (
    category: CALL_LOG_CATEGORY,
    callLogs: ICallLog[]
  ) => {
    if (!this.firstPageCallLogs.has(category))
      this.setFirstPageCallLogs(category, [
        ...this.filterCallLogsByCategory(callLogs, category),
      ]);
  };

  syncFirstPageCallLogs = (searchPageNum: number) => {
    if (searchPageNum === 1) {
      if (!this.typeOfCalls) {
        this.filterAndSetFirstPageCallLogs(
          CALL_LOG_CATEGORY.INCOMING,
          this.callLogs
        );
        this.filterAndSetFirstPageCallLogs(
          CALL_LOG_CATEGORY.MISSED,
          this.callLogs
        );
        this.filterAndSetFirstPageCallLogs(
          CALL_LOG_CATEGORY.OUTGOING,
          this.callLogs
        );
      } else {
        this.setFirstPageCallLogs(this.typeOfCalls, this.callLogs);
      }
      this.setLastSyncDate(DateTime.now().toString());
    }
  };

  removeDuplicates = (displayedLogs, fetchedLogs) => {
    return fetchedLogs.filter((log) =>
      isNullOrUndefined(displayedLogs.find((dlog) => dlog.id === log.id))
    );
  };

  mapImageToCallLogs = (callLogs: ICallLog[]) => {
    const images = {
      missed: MissedCallIcon,
      incoming: IncomingCallIcon,
      outgoing: OutgoingCallIcon,
    };
    return (
      callLogs?.map((callLog) => {
        const category =
          this.directions[
            `${callLog.direction}_${callLog.disposition}`.toLowerCase()
          ];
        const icon = images[category];
        return { ...callLog, icon, category };
      }) || []
    );
  };

  sortCallLogs = (callLogs: ICallLog[]) => {
    return callLogs.sort(
      (cl1, cl2) =>
        DateTime.fromISO(cl2.startedAt)
          .diff(DateTime.fromISO(cl1.startedAt))
          .toObject().milliseconds
    );
  };

  filterCallLogsByCategory = (
    callLogs: ICallLog[],
    category?: CALL_LOG_CATEGORY
  ) => {
    const callLogList = !(this.typeOfCalls || category)
      ? callLogs
      : callLogs.filter((cl) => cl.category === this.typeOfCalls || category);
    return callLogList.filter(
      (callLog) =>
        callLog.fullName?.toLowerCase().includes(this.searchQuery) ||
        callLog.phoneNumber?.includes(this.searchQuery)
    );
  };
}
export default CallLogsStore;
