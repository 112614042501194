import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
const phoneNumberUtil = new PhoneNumberUtil();

export const formatNumber = (num: string, country: string = 'US') => {
  if (phoneNumberUtil.isPossibleNumberString(num, country)) {
    const parsePhoneNumber = phoneNumberUtil.parse(num, 'US');
    return phoneNumberUtil.format(parsePhoneNumber, PhoneNumberFormat.NATIONAL);
  } else {
    return num;
  }
};

export const formatNumberWithNationalCode = (phoneNumber) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const countryCode = phoneNumber?.startsWith('+') ? '' : 'US';
  try {
    const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(
      phoneNumber,
      countryCode
    );
    return phoneUtil.isValidNumber(parsedPhoneNumber)
      ? countryCode === 'US'
        ? formatNumber(phoneNumber)
        : phoneUtil
            .format(parsedPhoneNumber, PhoneNumberFormat.INTERNATIONAL)
            .replace(/-/g, ' ')
      : phoneNumber || '';
  } catch (e) {
    return '';
  }
};

export const formatNumberNoPlusIfUS = (phoneNumber: string) => {
  return phoneNumber?.startsWith('+1')
    ? phoneNumber.substring(1)
    : phoneNumber;
};

export const isPhoneNumber = (phoneNumber) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const countryCode = phoneNumber?.startsWith('+') ? '' : 'US';
  try {
    const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(
      phoneNumber,
      countryCode
    );
    return phoneUtil.isValidNumber(parsedPhoneNumber);
  } catch (e) {
    return false;
  }
};

export const isPhoneNumberMatch = (phoneNumber1, phoneNumber2) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const match = phoneUtil.isNumberMatch(phoneNumber1, phoneNumber2);
    return (
      match === PhoneNumberUtil.MatchType.EXACT_MATCH ||
      match === PhoneNumberUtil.MatchType.NSN_MATCH
    );
  } catch (e) {
    return false;
  }
}

export default phoneNumberUtil;
