import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_UI,
} from 'Constants/stores';
import { debounce, uniqBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Contact } from 'Models/Contacts';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Grid,
  Input,
  Loader,
  Popup,
} from 'semantic-ui-react';

import { UserAvatar } from '../shared/UserAvatar';
import PhoneNumbersPopup from '../PhoneNumbersPopup';
import bhiveIcon from 'Assets/images/bhive-logo.svg';
import smsIcon from 'Assets/images/directory-chat.svg';

import makeCallIcon from 'Assets/images/phone-directory.svg';
import { AllContactsProps, AllContactsState } from './interfaces.d';
import withRouter from '../../hocs/WithRouter';
import { Heading } from '../shared/Heading';
import { ContactsLinks } from './ContactsLinks';

const permanentProviders: DropdownItemProps[] = [
  { key: 98, text: 'Display all', value: '' },
  {
    key: 99,
    image: { avatar: true, src: bhiveIcon },
    text: 'Personal contacts',
    value: 'B_HIVE_PERSONAL',
  },
  {
    key: 100,
    image: { avatar: true, src: bhiveIcon },
    text: 'Organization contacts',
    value: 'B_HIVE_SHARED',
  },
];

class AllContacts extends React.Component<AllContactsProps, AllContactsState> {
  constructor(props) {
    super(props);
    this.state = {
      allContacts: null,
      selectedContact: null,
      operationType: null,
      dropdownOption: null,
    };
  }

  componentDidMount() {
    this.mapOptionsToFilter();
  }
  componentDidUpdate() {
    this.mapOptionsToFilter();
  }

  mapOptionsToFilter = () => {
    const { person } = this.props;
    const externalProviders: DropdownItemProps[] = person.allSources
      ?.map((item) => {
        const text = item.email.charAt(0).toUpperCase() + item.email.slice(1);
        const isForContacts = item.scopes.includes('contacts');
        return (
          isForContacts && {
            key: item.id,
            image: { avatar: true, src: item.providerImage },
            text: text,
            value: item.id,
          }
        );
      })
      .filter(Boolean);
    const mergedProvides = [...permanentProviders, ...externalProviders];
    if (mergedProvides.length > this.state.dropdownOption?.length) {
      this.setState({ dropdownOption: mergedProvides });
    } else if (!this.state.dropdownOption) {
      this.setState({ dropdownOption: mergedProvides });
    }
  };
  filter = debounce((q: string) => {
    const {
      person: {
        contactPageNumber,
        contactSearchTerm,
        contactFilterValue,
        setContactPageNumber,
        getSearchListContacts,
      },
    } = this.props;
    let pageNumber = contactPageNumber;
    if (
      (contactSearchTerm || contactFilterValue?.value) &&
      contactPageNumber > 1
    ) {
      setContactPageNumber(1);
      //dont remove pageNumber, because for what ever reason contactPageNumber is not updated, from source pick
      pageNumber = 1;
    }
    getSearchListContacts(
      20,
      pageNumber,
      contactFilterValue?.value.toString() || '',
      contactSearchTerm
    );
  }, 500);
  handleSearch = (e) => {
    const { person } = this.props;
    const { value } = e.target;
    e.preventDefault();
    person.setContactSearchTerm(value);
    this.filter(value);
  };
  makeCall = (contact: Contact) => (e) => {
    const { phoneCall } = this.props;
    e.stopPropagation();
    if (contact.phoneNumbers.length > 1) {
      this.setState({ selectedContact: contact, operationType: 'call' });
    } else if (contact?.phoneNumbers.length === 1) {
      phoneCall.callWithPerson(null, contact.phoneNumbers[0].number);
    }
  };
  handleOpenChat = (contact: Contact) => async (e) => {
    e.stopPropagation();
    if (contact?.phoneNumbers.length > 1) {
      this.setState({ selectedContact: contact, operationType: 'message' });
    } else if (contact?.phoneNumbers.length === 1) {
      const phone = contact?.phoneNumbers[0].number;
      const resp =
        await this.props.conversation.loadOrCreateConversationWithPost(
          null,
          phone
        );
      this.props.navigate(`/chat/conversations/${resp.data.id}/menu`);
    }
  };

  handleLoadMoreContacts = () => {
    const {
      person: {
        loadingInfinite,
        setContactPageNumber,
        getSearchListContacts,
        contactPageNumber,
        contactFilterValue,
        contactSearchTerm,
      },
    } = this.props;
    if (!loadingInfinite) {
      const currentPage = contactPageNumber + 1;
      setContactPageNumber(currentPage);
      getSearchListContacts(
        20,
        currentPage,
        contactFilterValue?.value?.toString() || '',
        contactSearchTerm,
        true
      );
    }
  };

  handleSourcePick = (e, d: DropdownProps) => {
    const { person } = this.props;
    const pickedSource = this.state.dropdownOption.find(
      (item) => item.provider === d.value || item.value === d.value
    );
    person.setContactPageNumber(1);
    if (pickedSource) {
      pickedSource.value
        ? person.setContactFilterValue(pickedSource)
        : person.setContactFilterValue(null);
      this.filter(this.props.person.contactSearchTerm);
    }
  };
  clearSelected = () => this.setState({ selectedContact: null });

  openEditMode = (contact: Contact) => (e) => {
    const { person, navigate } = this.props;
    e.stopPropagation();
    person.setEditContact(contact);
    navigate('/addressBook/contact', { state: { from: '/contacts' } });
  };

  getSourceProvider = (accountId: number) => {
    const { person } = this.props;
    const provider = person.allSources.find(
      (source) => source.id === accountId
    )?.provider;
    return person.changeProviderName(provider || '');
  };

  displayProvider = (contact: Contact) =>
    contact.accountId ? this.getSourceProvider(contact.accountId) : 'B-Hive';

  mapOrgData = (contact: Contact) => {
    if (contact.organization && contact.organizationRole) {
      return `${contact.organization}, ${contact.organizationRole}`;
    } else if (contact.organization) {
      return contact.organization;
    } else if (contact.organizationRole) {
      return contact.organizationRole;
    }
    return '';
  };
  render() {
    const {
      person: {
        allContacts,
        contactSearchTerm,
        contactFilterValue,
        contactPageNumber,
        loadingInfinite,
        loadingContacts,
        totalContacts,
      },
      ui,
      conversation,
      phoneCall,
      router,
    } = this.props;
    const filteredSourceOfData = allContacts.filter((item) => item);
    const currentDataLength = contactPageNumber * 20;
    return (
      <div className="all-contacts" id="contacts" onClick={this.clearSelected}>
        <Heading variant="h2">Contacts</Heading>
        <div className="flex-row top-15">
          <Input
            autoFocus={true}
            className="search-field"
            value={contactSearchTerm || ''}
            placeholder="Search"
            onChange={this.handleSearch}
          />
          <div className="filter-source">
            <div className="filter-text">Filter by source</div>
            <Dropdown
              data-private
              placeholder="Display all"
              fluid
              selection
              options={uniqBy(this.state.dropdownOption, 'key')}
              onChange={this.handleSourcePick}
              value={contactFilterValue?.value}
            />
          </div>
        </div>
        {loadingContacts ? (
          <Loader className="addressbook-loader" active indeterminate />
        ) : (
          <InfiniteScroll
            initialScrollY={1}
            dataLength={currentDataLength} //This is important field to render the next data
            next={this.handleLoadMoreContacts}
            hasMore={!loadingInfinite && totalContacts > currentDataLength}
            loader={<></>}
            endMessage={<></>}
            pullDownToRefreshThreshold={4}
            scrollableTarget="contactList"
          >
            <Grid columns="four" id="contactList" className="list">
              {filteredSourceOfData?.length > 0 ? (
                filteredSourceOfData.map((contact) => {
                  const organizationData = this.mapOrgData(contact);
                  return (
                    <Grid.Row
                      key={contact.id}
                      className="directory-items"
                      onClick={this.openEditMode(contact)}
                    >
                      <Grid.Column width="7">
                        <div className="flex-row">
                          <div className="flex-column">
                            <div className="flex-row flex-align-items-center">
                              {contact.pictureUrl ? (
                                <img
                                  className="contact-prof-img"
                                  src={contact.pictureUrl}
                                />
                              ) : (
                                <UserAvatar
                                  name={
                                    contact.DisplayName().replace(/\s+/, '') ===
                                    ''
                                      ? null
                                      : contact.DisplayName()
                                  }
                                  selectUnreadCounts={
                                    ui.selectConversationUnreadCounts
                                  }
                                  conversationGrouping="OneOnOne"
                                />
                              )}
                              <div className="directory-user-info">
                                <div data-private>
                                  {contact.DisplayName()}
                                  {/* show this only if DisplayAll selected */}
                                  {!contactFilterValue?.value && (
                                    <span className="provider">
                                      ({this.displayProvider(contact)})
                                    </span>
                                  )}
                                </div>
                                <div>{organizationData}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid.Column>
                      <Grid.Column width="6">
                        <ContactsLinks {...{ contact, phoneCall }} />
                      </Grid.Column>
                      <Grid.Column width="3" textAlign="right">
                        <div>
                          {contact.phoneNumbers.length > 0 && (
                            <div className="flex-row flex-align-items-center flex-justify-end position-relative">
                              {this.state.selectedContact &&
                                this.state.selectedContact?.id ===
                                  contact.id && (
                                  <PhoneNumbersPopup
                                    router={router}
                                    phoneNumbers={contact.phoneNumbers}
                                    callWithPerson={phoneCall.callWithPerson}
                                    loadOrCreateConversationWithPost={
                                      conversation.loadOrCreateConversationWithPost
                                    }
                                    operationType={this.state.operationType}
                                  />
                                )}
                              <Popup
                                key="start-chat"
                                inverted
                                position="bottom center"
                                content={'Start messaging'}
                                trigger={
                                  <div>
                                    <Button
                                      onClick={this.handleOpenChat(contact)}
                                      size="small"
                                      compact
                                      basic
                                      className="flex-row flex-align-items-center"
                                    >
                                      <img
                                        className="icon-18px chat-icon"
                                        src={smsIcon}
                                      />
                                    </Button>
                                  </div>
                                }
                              />

                              <Popup
                                key="start-call"
                                inverted
                                content={'Start Call'}
                                position="bottom center"
                                trigger={
                                  <div className={'call-connecting'}>
                                    <Button
                                      onClick={this.makeCall(contact)}
                                      size="small"
                                      compact
                                      id="call-person"
                                      basic
                                      className="flex-row align-items-center"
                                    >
                                      {
                                        <img
                                          id="phone-call"
                                          className="icon-18px"
                                          src={makeCallIcon}
                                        />
                                      }
                                    </Button>
                                  </div>
                                }
                              />
                            </div>
                          )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  );
                })
              ) : (
                <div className="no-contacts">No contacts found</div>
              )}
            </Grid>
            {loadingInfinite && <Loader className="loading-more-contacts" />}
          </InfiniteScroll>
        )}
      </div>
    );
  }
}
export default inject(
  STORE_PERSON,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION
)(withRouter(observer(AllContacts)));
