import { imageFileTypes } from 'Constants/enums';
import { mapIcon } from 'Models/FileType';
import React, { useCallback, useState } from 'react';
import { Grid, Popup } from 'semantic-ui-react';
import { IFilesPreview } from './interfaces';

import deleteIcon from 'Assets/images/delete.svg';
import downArrow from 'Assets/images/down-arrow.svg';
import downloadIcon from 'Assets/images/download-icon.svg';
import { formatBytes } from '../../utils/byteConversionUtilis';
import { IMessageDocument } from '../../models/MessageModel';

export const FilesPreview: React.FC<IFilesPreview> = ({
  messageModel,
  loggedInPersonId,
  handleDownloadFile,
  openModalDeleteFile,
}) => {
  const [colapse, setColapse] = useState(false);
  const files = messageModel.documents;

  const [filesLoaded, setFilesLoadedState] = useState<{
    [key: string]: boolean;
  }>();

  const onFileLoad = useCallback(
    (event, file: IMessageDocument) => {
      setFilesLoadedState((x) => {
        return {
          ...x,
          [file.externalId]: true,
        };
      });
      event.currentTarget.style.height = 'auto';
    },
    [setFilesLoadedState, event]
  );

  const getColumnWidth = () => {
    return (
      document.getElementsByClassName('file-preview-column')[0]
        ?.childNodes[0] as HTMLElement
    )?.clientWidth;
  };

  return (
    <Grid className="margin-top-1rem margin-bottom-0">
      <span
        className={`files-preview-toggle ${colapse ? 'is--toggled' : ''}`}
        onClick={() => {
          setColapse(!colapse);
          setFilesLoadedState(null);
        }}
      >
        {`${files.length} file${files.length > 1 ? 's' : ''}`}{' '}
        {`${formatBytes(
          files?.reduce(
            (accumulated, current) => accumulated + current.byteCount ?? 0,
            0
          )
        )}`}
        <img src={downArrow} />
      </span>
      {!colapse && (
        <Grid.Row columns={2} className="padding-top-0">
          {files.map((file) => {
            const isImage = imageFileTypes.includes(file.contentType);
            const iconOrFileUrl = file.isDeleted
              ? mapIcon(null, 'deleted')
              : isImage
              ? file?.previewUrl || mapIcon(null, 'generic')
              : mapIcon(null, file.contentType);
            return (
              <Grid.Column
                className="file-preview-column"
                key={(file.url || file.externalId) + file.created?.toString()}
              >
                {file.isDeleted ? (
                  <div
                    className="inserted-file is--default-preview"
                    key={file.externalId}
                  >
                    <span className="deleted-file">File Deleted</span>
                  </div>
                ) : (
                  <div key={file.externalId}>
                    <div
                      className={`inserted-file ${
                        isImage && file.hasPreview
                          ? 'is--preview'
                          : 'is--default-preview'
                      }`}
                    >
                      <img
                        src={iconOrFileUrl}
                        className="preview-image"
                        onLoad={(e) => onFileLoad(e, file)}
                        style={{
                          height:
                            (file.previewHeight / file.previewWidth) *
                              getColumnWidth() || 'auto',
                        }}
                      />
                      {(!isImage || (isImage && !file.hasPreview)) && (
                        <span>{file.description ? file.description : ''}</span>
                      )}
                      <Popup
                        content="Download"
                        position="top center"
                        trigger={
                          <div
                            className={`download-box ${
                              messageModel.personId !== loggedInPersonId
                                ? 'right-0'
                                : ''
                            }`}
                            onClick={() => handleDownloadFile(file.externalId)}
                          >
                            <img src={downloadIcon} />
                          </div>
                        }
                      />
                      {messageModel.personId === loggedInPersonId && (
                        <Popup
                          content="Delete"
                          position="top center"
                          trigger={
                            <div
                              className="delete-box right-0"
                              onClick={() =>
                                openModalDeleteFile({
                                  show: true,
                                  externalId: file.externalId,
                                  messageId: messageModel.id,
                                })
                              }
                            >
                              <img src={deleteIcon} />
                            </div>
                          }
                        />
                      )}
                    </div>
                    {filesLoaded?.[file.externalId] && file.description
                      ? file.description
                      : ''}
                  </div>
                )}
              </Grid.Column>
            );
          })}
        </Grid.Row>
      )}
    </Grid>
  );
};
