import { API_ENDPOINTS } from 'Constants/env';
import { AxiosResponseT, ResultsCollection } from 'Interfaces/axiosResponse';
import { action, observable, ObservableMap, makeObservable } from 'mobx';
import {
  fromPromise,
  IPromiseBasedObservable,
  createTransformer,
} from 'mobx-utils';
import {
  ContactModel,
  ContactModelBase,
  ContactTypeGuards,
  FromContactResponseDto,
  IContactDto,
} from 'Models/ContactModel';
import API from '../api';
import { formatNumberNoPlusIfUS } from '../utils/phoneUtil';
import { BaseStore } from './BaseStore';
import { RootStore } from './RootStore';

export class ContactStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  public contactsByPhone: ObservableMap<
    string,
    IPromiseBasedObservable<AxiosResponseT<ContactModel>>
  > = observable.map();

  @observable public loadSingleContactStatus: IPromiseBasedObservable<
    AxiosResponseT<IContactDto>
  > = null;
  @observable public loadContactsStatus: IPromiseBasedObservable<
    AxiosResponseT<ResultsCollection<IContactDto>>
  > = null;

  @action
  public loadContactByPhoneNumber = (
    phoneNumber: string
  ): IPromiseBasedObservable<AxiosResponseT<ContactModel>> => {
    const postRequest = { phoneNumber };
    this.loadSingleContactStatus = fromPromise(
      API.post(API_ENDPOINTS.Contacts, postRequest)
    );
    return fromPromise(
      this.loadSingleContactStatus.then((resp) => {
        return this.loadSingleContactSuccess(resp);
      }, this.loadContactFailure) as any
    ); // Actually IPromiseBasedObservable<AxiosResponseT<ContactModel>>, but due to the `loadContactFailure` promise catch, we can't accurately capture the `PromiseLike<void | IPromiseBasedObservable...` type.
  };

  @action
  loadContactByPhoneNumberIfMissing = (
    phoneNumber: string
  ): IPromiseBasedObservable<AxiosResponseT<ContactModel>> => {
    phoneNumber = formatNumberNoPlusIfUS(phoneNumber);
    if (!this.contactsByPhone.has(phoneNumber)) {
      // If main list is loading, wait...
      if (this.contactsByPhone.has('+' + phoneNumber))
        return this.contactsByPhone.get('+' + phoneNumber);
      return this.loadContactsStatus.case({
        fulfilled: () => {
          // Once the main list is done loading, check if `phoneNumber` matches...
          if (this.contactsByPhone.has(phoneNumber)) {
            return this.contactsByPhone.get(phoneNumber);
          } else {
            // Otherwise, return the PBO to load the Contact
            return this.loadContactByPhoneNumber(phoneNumber);
          }
        },
        rejected: this.loadContactFailure as any,
      }); // Actually IPromiseBasedObservable<AxiosResponseT<ContactModel>>
    }
    return this.contactsByPhone.get(phoneNumber);
  };

  @action
  private loadSingleContactSuccess = (
    response: AxiosResponseT<IContactDto>
  ) => {
    const contactInst = FromContactResponseDto(response.data);
    if (
      ContactTypeGuards.isContactMerged(contactInst) ||
      ContactTypeGuards.isContactExtended(contactInst)
    ) {
      const resolvedMulti = [] as Array<
        IPromiseBasedObservable<AxiosResponseT<ContactModel>>
      >;
      contactInst.phoneNumbers?.forEach((phNum) => {
        const resItem = fromPromise.resolve({ ...response, data: contactInst });
        this.contactsByPhone.set(phNum, resItem);
        resolvedMulti.push(resItem);
      });
      // Return the first entry from a Merged Contact, since they are all the same.
      return resolvedMulti[0];
    } else {
      const resolved = fromPromise.resolve({ ...response, data: contactInst });
      this.contactsByPhone.set(contactInst.phoneNumber.toString(), resolved);
      return resolved as IPromiseBasedObservable<
        AxiosResponseT<ContactModelBase>
      >;
    }
  };

  @action
  private loadContactFailure = (response) => {
    this.rootStore.notificationStore.addAxiosErrorNotification(
      response,
      'Error Loading Contact'
    );
  };

  @action
  loadContacts() {
    this.loadContactsStatus = fromPromise(API.get(API_ENDPOINTS.Contacts));
    return this.loadContactsStatus.then(this.loadContactsSuccess);
  }

  @action
  private loadContactsSuccess = (
    response: AxiosResponseT<ResultsCollection<IContactDto>>
  ) => {
    response.data.results.forEach((item) => {
      const contactInst = FromContactResponseDto(item);
      if (
        ContactTypeGuards.isContactExtended(contactInst) ||
        ContactTypeGuards.isContactMerged(contactInst)
      ) {
        // TODO: If there are multiple Contacts that aren't Merged for some reason, prioritize by type and/or updated (RP 2018-04-18)
        // Currently, this behavior is acceptable, because if there is an existing Base Contact, we want to overwrite it
        contactInst.phoneNumbers.forEach((itm) => {
          this.contactsByPhone.set(
            itm,
            fromPromise.resolve({ ...response, data: contactInst })
          );
        });
      } else {
        // "Base" Contacts are the lowest priority, so don't overwrite a higher level Contact
        if (this.contactsByPhone.has(contactInst.phoneNumber)) {
          const ecPbo = this.contactsByPhone.get(contactInst.phoneNumber);
          if (ecPbo.state === 'fulfilled') {
            const exCt = ecPbo.value.data;
            const lmPrfx = `Base Contact with phoneNumber ${contactInst.phoneNumber} (id: ${contactInst.id})`;
            const lmSufx = `existing ${exCt.contactType} Contact (id: ${exCt.id})`;
            if (ContactTypeGuards.isContactExtendedOrMerged(exCt)) {
              console.debug(`Skipping ${lmPrfx} in favor of ${lmSufx}`);
            } else {
              console.warn(
                `Encountered duplicate ${lmPrfx}, which conflicts with ${lmSufx}`
              );
            }
          }
        } else {
          // This is a TEMPORARY solution. Huy says that we do need to handle contacts that have neither a
          // Communicator account nor a phone number, I.E. Caller ID blocked numbers. That is a much bigger
          // fix though, so right now we just won't show them in history to prevent the app getting stuck
          // on loading.
          if (contactInst.phoneNumber) {
            this.contactsByPhone.set(
              contactInst.phoneNumber,
              fromPromise.resolve({ ...response, data: contactInst })
            );
          }
        }
      }
    });
    return Promise.resolve(this.contactsByPhone);
  };

  selectContactPropertiesValueById = createTransformer((phone: string) => {
    const ct = this.contactsByPhone.has(phone)
      ? this.contactsByPhone.get(phone)
      : null;
    if (ct !== null) {
      if (ct.state === 'fulfilled') {
        return ct.value.data;
      }
    }
    return null;
  });

  @action
  clearAllData = () => {
    this.loadSingleContactStatus = null;
    this.contactsByPhone.clear();
  };
}

export default ContactStore;
